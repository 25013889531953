import React, { useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { Select } from 'element-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Icon from '../../Icon';
import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  allCompanyList: state.commonReducers.allCompanyList,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const ThirdPartyTopupModal = ({ allCompanyList, updateModalAlert, data }) => {
  const { t } = useTranslation();
  const [companyId, setcompanyId] = useState('');
  return (
    <div className="confirmModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex justify-content-center align-items-center'}`}
          xs={{
            size: 4, offset: 4
          }}
        >
          {t('REMINDER')}
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <Icon
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            libName="io"
            value={{
              name: 'IoClose',
              className: 'h4 mb-0 float-right closeIcon',
            }}
          />
        </Col>
      </Row>
      <Row className="modalContent">
        <div className="d-flex align-items-center justify-content-center mt-4">
          <div className="me-3">复制到</div>
          <Select value={companyId} placeholder="请选择" onChange={code => setcompanyId(code)}>
            {
                allCompanyList && allCompanyList.map(el => <Select.Option key={el.companyId} label={`${el.name}[${el.defaultAdmin}]`} value={el.companyId} />)
              }
          </Select>

        </div>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-3 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onConfirmationClick) data.onConfirmationClick(companyId);
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
        {
          data && data.showCancelButton !== false && (
            <Button
              className={`cancelButton my-3 ${'me-3'}`}
              onClick={() => {
                updateModalAlert({
                  visible: false
                });
                if (data && data.onCancelClick) data.onCancelClick();
              }}
            >
              {(data && data.cancelText) || t('CANCEL')}
            </Button>
          )
        }
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyTopupModal);