import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, cashActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  checkSetting: state.cashReducers.checkSetting,
});

const mapDispatchToProps = dispatch => ({
  getCheckSetting: (memberId, callback) => dispatch(cashActions.GetCheckSetting(memberId, callback)),
  saveCheckSetting: (obj, callback) => dispatch(cashActions.SaveCheckSetting(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const AuditSetting = ({ getCheckSetting, checkSetting, data, saveCheckSetting, updateModalAlert }) => {
  const { t } = useTranslation();
  const [_maxValue, setMaxValue] = useState();
  const [_minValue, setMinValue] = useState();
  const [_mobileNumber, setMobileNumber] = useState();

  useEffect(() => {
    if (checkSetting) {
      setMaxValue(checkSetting?.chkDrawMax);
      setMinValue(checkSetting?.chkDrawMin);
      setMobileNumber(checkSetting?.warningPhone);
    }
  }, [checkSetting]);

  useEffect(() => {
    if (data) {
      getCheckSetting(data.orgId || data?.memberId);
    }
  }, [data]);


  const saveButton = () => {
    updateModalAlert({
      type: 'loading',
      visible: true
    });
    const obj = {
      mid: data.orgId || data?.memberId,
      phone: _mobileNumber,
      maxLimit: _maxValue,
      minLimit: _minValue,
    };
    saveCheckSetting(obj);
  };

  const resetDefault = () => {
    getCheckSetting(data.orgId || data?.memberId);
  };

  return (
    <div className="AuditSettingWrapper">
      <div className="mx-0 bg-white my-3  border-1">
        <Row className="bigWrapper m-0 my-3 p-2">
          <table className="agentDataTable p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="tableHeader col-3">提醒手机</td>
                <td>
                  <span>
                    <Input value={_mobileNumber} onChange={({ target: { value: text } }) => setMobileNumber(text)} />
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-3">审核金额最大值</td>
                <td>
                  <span>
                    <Input type="number" value={_maxValue} onChange={({ target: { value: text } }) => setMaxValue(Math.abs(Number(text)))} />
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-3">审核金额最小值</td>
                <td>
                  <span>
                    <Input type="number" value={_minValue} onChange={({ target: { value: text } }) => setMinValue(Math.abs(Number(text)))} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditSetting);