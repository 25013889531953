import React, { useEffect, useState } from 'react';

import { ReactComponent as EmptyIcon } from '../../assets/image/order_empty.svg';
import Skeleton from '../Skeleton';

import './styles.scss';

const DataTable = ({ tableHeaderTitle, tableHeaderStyle, tableData, tableBodyRowProperty, tableBodyRowStyle, tableFooter, onRowClick, isQuerying, tableControl, activeControl, rowSpanHeader, rowSpanCol }) => {
  const [_data, setData] = useState();
  useEffect(() => {
    if (JSON.stringify(_data) !== JSON.stringify(tableData)) {
      setData(tableData);
    }
  }, [tableData, isQuerying]);

  useEffect(() => {
    if (isQuerying) {
      setData(new Array(10).fill(''));
    }
  }, [isQuerying]);

  const renderSpanRow = (rowSpan, ind, property, thisItem, index) => {
    if ((rowSpan && rowSpanCol?.includes(ind) && rowSpan !== 'nil')) {
      return (
        <td rowSpan={rowSpan === 1 || rowSpan === 0 ? '' : (rowSpan || '')} className={tableBodyRowStyle && tableBodyRowStyle[ind]} key={`rowSpan1_${ind.toString()}`}>
          {
          isQuerying ? (
            <Skeleton isAnimated />
          ) : typeof property === 'function' ? property(thisItem, index) : (<span>{thisItem[property]}</span>)
        }
        </td>
      );
    } if (property(thisItem, index) || thisItem[property]) {
      // will not render if is undefined
      return (
        <td className={tableBodyRowStyle && tableBodyRowStyle[ind]} key={`rowSpanMore_${ind.toString()}`}>
          {
          isQuerying ? (
            <Skeleton isAnimated />
          ) : typeof property === 'function' ? property(thisItem, index) : (<span>{thisItem[property]}</span>)
        }
        </td>
      );
    }
  };


  return (
    <div className="agentDataTableWrapper p-0 m-0">
      <table className="agentDataTable p-0 m-0" cellSpacing="0" cellPadding="0" border="0">
        <thead>
          {rowSpanHeader && (
            <tr className="tableHeader">
              {rowSpanHeader.map((ele, index) => (
                <td colSpan={ele?.colSpan || ''} rowSpan={ele?.rowSpan || ''} key={`header_title_${index.toString()}`} className={`${ele?.rowSpan ? 'none' : ''} ${index === 0 ? 'col-2' : ''}`}>
                  <span>{ele.label}</span>
                </td>
              ))}
            </tr>
          )}
          <tr className="tableHeader">
            {tableHeaderTitle.map((ele, index) => (
              Array.isArray(ele)
                ? (
                  <th key={`header_title_${index.toString()}`} className={tableHeaderStyle && tableHeaderStyle[index]}>
                    {ele.map((subEle, subIndex) => (
                      <span key={`sub_header_title_${subIndex.toString()}`} className={tableHeaderStyle && tableHeaderStyle[index] && tableHeaderStyle[index][subIndex]}>
                        {subEle.label}
                      </span>
                    ))}
                  </th>
                )
                : (
                  <th key={`header_title_${index.toString()}`} className={tableHeaderStyle && tableHeaderStyle[index]}>
                    <span className={tableHeaderStyle && tableHeaderStyle[index]}>{ele.label}</span>
                  </th>
                )))}
          </tr>
        </thead>
        <tbody>
          {
            _data && _data.length > 0 ? _data.map((item, index) => (
              <>
                <tr
                  className={`tableBodyRow ${item?.deleteFlag === 1 && 'deletedData'} ${item?.actionStatus === '400' ? 'fail' : item?.actionStatus === '300' && 'success'} `}
                  key={`table_row_${index.toString()}`}
                  onClick={() => onRowClick && onRowClick(item)}
                >
                  {tableBodyRowProperty.map((property, propertyIndex) => (Array.isArray(property)
                    ? (
                      <td className={tableBodyRowStyle && tableBodyRowStyle[propertyIndex]} key={`td_sub_item_${propertyIndex.toString()}`}>
                        {
                          isQuerying ? (
                            <Skeleton isAnimated />
                          ) : (
                            property.map((subProperty, subIndex) => (
                              <span key={`render_row_${subIndex.toString()}`} className={tableBodyRowStyle && tableBodyRowStyle[propertyIndex] && tableBodyRowStyle[propertyIndex][subIndex]}>
                                {typeof subProperty === 'function' ? property(item, index) : item[subProperty] || subProperty}
                              </span>
                            ))
                          )
                        }
                      </td>
                    )
                    : (item?.rowSpan
                      ? renderSpanRow(item?.rowSpan, propertyIndex, property, item, index)
                      : (
                        <td className={tableBodyRowStyle && tableBodyRowStyle[propertyIndex]} key={`noRowSpan_${propertyIndex.toString()}`}>
                          {
                            isQuerying ? (
                              <Skeleton isAnimated />
                            ) : typeof property === 'function' ? property(item, index) : (<span>{item[property]}</span>)
                          }
                        </td>
                      ))))}
                </tr>
                {
                  tableControl && !isQuerying && (
                    <tr
                      className={(activeControl === (item?.orgId || item?.memberId || item?.id || item?.companyId)) ? 'active' : 'collapse'}
                    >
                      <td colSpan={Number(tableHeaderTitle?.length)} key="controlRow">
                        {
                          tableControl && tableControl.map(property => (!Array.isArray(property)
                            ? (
                              <span className="d-inline" key={`table_control_${index.toString()}`}>
                                {property(item, index)}
                              </span>
                            ) : ''
                          ))
                        }
                      </td>
                    </tr>
                  )
                }
              </>
            )) : (
              <tr>
                <td colSpan={tableHeaderTitle.length + (rowSpanHeader?.length || 0)} key="tableNoRecord">
                  <EmptyIcon className="emptyIcon" />
                  <div className="emptyTextWrapper">
                    <span>暂无记录</span>
                  </div>
                </td>
              </tr>
            )
          }
          {
            tableFooter && tableFooter.map(footerRow => (
              <tr>
                {
                  footerRow.map((item, index) => (
                    <td key={`table_footer_${index.toString()}`} colSpan={item?.colspan} className={item.className}>
                      {
                        item.render ? item.render() : <span>{item.value}</span>
                      }
                    </td>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;