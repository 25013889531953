import React, { useEffect, useState } from 'react';

import { Checkbox, DateRangePicker, Input, Select } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Icon, Pagination } from '../..';
import { cashActions, messageActions } from '../../../redux/actions';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  proCode: state.cashReducers.proCode,
});

const mapDispatchToProps = dispatch => ({
  GetProCode: (obj, callback) => dispatch(cashActions.GetProCode(obj, callback)),
  SaveProCode: (obj, callback) => dispatch(cashActions.SaveProCode(obj, callback)),
  GetMessageDetail: (id, callback) => dispatch(messageActions.GetMessageDetail(id, callback)),
  DelProCode: (id, callback) => dispatch(cashActions.DelProCode(id, callback)),
  BatchDelProCodes: (ls, callback) => dispatch(cashActions.BatchDelProCodes(ls, callback)),
});

const Vouncher = ({ SaveProCode, GetProCode, proCode, DelProCode, BatchDelProCodes }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [title, setTitle] = useState('');
  const [remark, setremark] = useState('');
  const [amount, setamount] = useState();
  const [totalCodes, settotalCodes] = useState('');

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [state, setState] = useState(0);
  const [_date, setDate] = useState([moment().subtract(1, 'weeks').startOf('day').toDate(), moment().endOf('day').toDate()]);
  let dateRef;

  const [_list, setList] = useState([]);
  const [_checkAll, setCheckAll] = useState(false);


  useEffect(() => {
    if (_checkAll && proCode) {
      const _ls = proCode.data.map(item => item.id);
      setList([..._ls]);
    } else {
      setList([]);
    }
  }, [_checkAll, proCode]);

  useEffect(() => {
    if (_pageFlag === 0) {
      setIsQuerying(true);
      const _obj = {
        page,
        rows,
        state,
      };
      GetProCode(_obj, setIsQuerying);
    } else {
      setState(0);
      setPage(1);
      setRows(15);
    }
  }, [_pageFlag]);

  const saveVouncher = () => {
    const _obj = {
      title,
      remark,
      amount,
      totalCodes,
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix()
    };
    SaveProCode(_obj, () => setPageFlag(0));
  };

  const deleteMessage = id => {
    const _obj = {
      page: 1,
      rows: 15,
      state,
    };
    DelProCode(id, () => GetProCode(_obj));
  };

  const deleteBatchMessage = () => {
    const _obj = {
      page: 1,
      rows: 15,
      state,
    };
    BatchDelProCodes(_list, () => GetProCode(_obj));
  };

  const onSearchClick = () => {
    setIsQuerying(true);
    setCheckAll(false);
    const _obj = {
      page: 1,
      rows: 15,
      state,
    };
    GetProCode(_obj, setIsQuerying);
    setPage(1);
    setRows(15);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(true);
    setPage(pageNum);
    setRows(rows);
    setCheckAll(false);
    const _obj = {
      page: pageNum,
      rows,
      state,
    };
    GetProCode(_obj, setIsQuerying);
  };

  return (
    <div className="VouncherWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
              <Icon
                libName="md"
                value={{
                  name: 'MdArrowBack',
                  className: 'h5 mb-0 text-white'
                }}
              />
              返回
            </div>
            )}
            {`${t('VOUCHER')} ${_pageFlag === 0 ? '' : '/ 生成优惠码'}`}
          </div>
)}
      />
      {_pageFlag === 0 && (
      <>
        <div className="addNewGameBox my-3">
          <div className="operationBtn add" onClick={() => setPageFlag(1)}>
            <div className="plus_icon"><i className="el-icon-plus" /></div>
            生成
          </div>
          <div className="mx-2">
            状态
            <Select className="ms-2" value={state} placeholder="请选择" onChange={code => setState(code)}>
              {
                     [{
                       value: 0, label: '未使用'
                     }, {
                       value: 1, label: '已使用'
                     }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                }
            </Select>
          </div>
          <div className="operationBtn mx-2 add" onClick={() => onSearchClick()}>查询</div>
          <div className="operationBtn mx-2 " onClick={() => deleteBatchMessage()}>批量删除</div>
        </div>

        <div className="mx-0 bg-white mb-3 px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: (<Checkbox onChange={text => setCheckAll(text)} checked={_checkAll}>全选</Checkbox>),
            }, {
              label: '创建时间',
            }, {
              label: '优惠码',
            }, {
              label: '金额',
            }, {
              label: '主题',
            }, {
              label: '说明',
            }, {
              label: '开始时间',
            }, {
              label: '结束时间',
            }, {
              label: '使用状态',
            }, {
              label: t('OPERATION'),
            }]
          }
            tableData={proCode ? proCode.data : []}
            tableBodyRowProperty={
            [
              data => (
                <Label className="d-flex justify-content-center">
                  <Checkbox
                    onChange={() => {
                      if (_list.includes(data.id)) {
                        setList(_list.filter(item => item !== data.id));
                      } else {
                        setList([..._list, data.id]);
                      }
                    }}
                    checked={_list.includes(data.id)}
                  />
                </Label>
              ),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.createtime).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.proCode}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.proAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.title}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.remark}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.beginTime).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.endTime).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (<Label className={`d-flex justify-content-center ${data.isUsed === 1 && 'text-danger'}`}>{data.isUsed === 0 ? '未使用' : '已使用'}</Label>),
              data => (
                <Label className="d-flex justify-content-center">
                  <div className=" operationBtn cancel " onClick={() => { deleteMessage(data.id); }}>
                    删除
                  </div>
                </Label>
              )
            ]
          }
          />
        </div>
        <div className="d-flex align-items-center justify-content-center w-full relative">
          <Pagination totalRecord={proCode ? (proCode.sum.totalRecord || 0) : 0} onClick={onPaginationClick} isQuery={false} />
        </div>
      </>
      )}
      {_pageFlag === 1 && (
        <>
          <div className="my-3 thirdPartyGamesTable">
            <div className="thirdPartyGamesTitle">生成优惠码</div>
            <div className="thirdPartyGamesRow">
              <div>主题: </div>
              <div>
                <Input
                  className="messageTitleBox"
                  value={title}
                  onChange={text => {
                    setTitle(text);
                  }}
                  placeholder="请输入内容"
                />

              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>说明: </div>
              <div>
                <Input
                  type="textarea"
                  autosize={{
                    minRows: 4, maxRows: 8
                  }}
                  value={remark}
                  onChange={text => {
                    setremark(text);
                  }}
                  placeholder="消息主题，最多100个字符"
                />
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>金额: </div>
              <div>
                <Input
                  className="messageTitleBox"
                  value={amount}
                  onChange={text => {
                    if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                      setamount(text === '' ? 0 : parseInt(text, 10));
                    }
                  }}
                  placeholder="请输入内容"
                />

              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>生效日期: </div>
              <div>
                <DateRangePicker
                  className="betQueryDatepicker"
                  value={_date}
                  isShowTime
                  placeholder={t('START_END_DATE')}
                  rangeSeparator={` ${t('TO')} `}
                  onChange={date => {
                    const _d = moment(date[1]).endOf('day').toDate();
                    setDate([date[0], _d]);
                  }}
                  ref={e => dateRef = e}
                  shortcuts={[{
                    text: t('TODAY'),
                    onClick: () => {
                      setDate([moment().toDate(), moment().toDate()]);
                      dateRef.togglePickerVisible();
                    }
                  }, {
                    text: t('THIS_WEEK'),
                    onClick: () => {
                      setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
                      dateRef.togglePickerVisible();
                    }
                  }, {
                    text: t('PREVIOUS_WEEK'),
                    onClick: () => {
                      setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                      dateRef.togglePickerVisible();
                    }
                  }, {
                    text: t('PREVIOUS_2_WEEK'),
                    onClick: () => {
                      setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                      dateRef.togglePickerVisible();
                    }
                  }]}
                />

              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>生成条数: </div>
              <div>
                <Input
                  className="messageTitleBox"
                  value={totalCodes}
                  onChange={text => {
                    if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                      settotalCodes(text === '' ? 0 : parseInt(text, 10));
                    }
                  }}
                  placeholder="请输入内容"
                />

              </div>
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => saveVouncher()}>确定</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Vouncher);