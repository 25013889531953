import React, { useEffect, useState } from 'react';

import { Input } from 'element-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import Skeleton from '../../Skeleton';
import TopUpIcon from '../../../constants/TopUpIcon';
import { topUpActions } from '../../../redux/actions';
import './styles.scss';

import NoData from '../../../assets/image/nogo.png';

const mapStateToProps = state => ({
  topUpInfo: state.topUpReducers.topUpInfo,
});

const mapDispatchToProps = dispatch => ({
  GetInfo: () => dispatch(topUpActions.GetInfo()),
  TopUpSave: (obj, callback) => dispatch(topUpActions.TopUpSave(obj, callback)),
});

const Deposit = ({ GetInfo, topUpInfo, TopUpSave }) => {
  const [selectedType, setSelectedType] = useState('');

  const [selectedMethod, setSelectedMethod] = useState('');

  const [selectedWallet, setSelectedWallet] = useState('');

  const [acAmount, setamount] = useState();

  const [bankUserName, setbankUserName] = useState('');

  const [bankCode, setbankCode] = useState('');
  const [currentCode, setcurrentCode] = useState('');
  const [thirdPartyID, setthirdPartyID] = useState('');
  const { t } = useTranslation();

  const [currentThirdItem, setCurrentThirdItem] = useState();

  const paymentTypeList = [{
    imageName: TopUpIcon.IBank,
    thirdPayGroup: 100,
  }, {
    imageName: TopUpIcon.AliPay,
    thirdPayGroup: 200,
  }, {
    imageName: TopUpIcon.Crypto,
    thirdPayGroup: 300,
  }, {
    imageName: TopUpIcon.Others,
    thirdPayGroup: 0,
  }, {
    imageName: TopUpIcon.Offline,
    thirdPayGroup: -1,
  }, {
    imageName: TopUpIcon.UnionPay,
    thirdPayGroup: 110,
  }, {
    imageName: TopUpIcon.YunShanFu,
    thirdPayGroup: 120,
  }, {
    imageName: TopUpIcon.ATMCard,
    thirdPayGroup: 130,
  }, {
    imageName: TopUpIcon.Wechat,
    thirdPayGroup: 210,
  }, {
    imageName: TopUpIcon.QQ,
    thirdPayGroup: 220,
  }];

  useEffect(() => {
    GetInfo();
  }, []);

  useEffect(() => {
    if (topUpInfo) {
      if (selectedType === '') {
        const temp = topUpInfo.offlineBanks[0] && topUpInfo.offlineBanks[0]?.bankCode ? topUpInfo.offlineBanks[0].bankCode : '';
        setSelectedMethod(temp);
      } else {
        const temp = topUpInfo.thirdPayGroup[selectedType] && topUpInfo.thirdPayGroup[selectedType][0] ? topUpInfo.thirdPayGroup[selectedType][0].id : '';
        setSelectedMethod(temp);
      }
    }
  }, [selectedType, topUpInfo]);

  useEffect(() => {
    if (topUpInfo && selectedMethod !== '') {
      if (selectedType !== '') {
        const temp = topUpInfo.thirdPayGroup[selectedType].find(item => item.id === selectedMethod);
        if (temp) {
          setCurrentThirdItem(temp);
          setSelectedWallet(temp.payBanks[0].bankCode);
          setbankCode(temp.payBanks[0].bankCode);
          setcurrentCode(temp.currencyCode);
          setthirdPartyID(temp.id);
        }
      } else {
        const temp = topUpInfo.offlineBanks.find(x => x.bankCode === selectedMethod);
        if (temp) {
          setCurrentThirdItem();
          setSelectedWallet('');
          setbankCode(temp.id);
          setcurrentCode(temp.currencyCode);
          setthirdPartyID('');
        }
      }
    }
  }, [selectedMethod]);

  const saveTopUp = () => {
    const _obj = {
      acAmount,
      bankCode,
      bankUserName,
      currentCode,
      thirdPartyID,
    };
    TopUpSave(_obj);
  };


  return (
    <div className="depositWrapper">
      <div className="subTitleWrapper">{t('TOPUP_TYPE')}</div>
      <div className="subContentWrapper">
        {
              topUpInfo && topUpInfo.offlineBanks ? [''].map(() => {
                const TopUpImage = TopUpIcon.Offline;
                return (
                  <div className={`payItem ${selectedType === '' && 'selected'}`} onClick={() => setSelectedType('')}>
                    <TopUpImage className="topupIcon" />
                    <span>线下支付</span>
                  </div>
                );
              })
                : new Array(2).fill(1).map(() => <div className="payItem"><Skeleton isAnimated /></div>)
          }
        {
              topUpInfo && topUpInfo.thirdPayGroup ? Object.entries(topUpInfo.thirdPayGroup).map(([key, item]) => {
                const TopUpImage = paymentTypeList.find(x => x.thirdPayGroup.toString() === key.toString())?.imageName || TopUpIcon.Offline;
                return (
                  <div className={`payItem ${selectedType === item[0].thirdPayGroup && 'selected'}`} onClick={() => setSelectedType(item[0].thirdPayGroup)}>
                    <TopUpImage className="topupIcon" />
                    <span className="ms-1">
                      {item[0].thirdPayGroupName}
                    </span>
                  </div>
                );
              })
                : new Array(2).fill(1).map(() => <div className="payItem"><Skeleton isAnimated /></div>)
          }

      </div>
      {
      topUpInfo && selectedMethod !== ''
        && (
        <>
          <div className="subTitleWrapper">{t('TOPUP_METHOD')}</div>
          <div className="subContentWrapper">
            {
            topUpInfo && topUpInfo.offlineBanks && selectedType === '' && topUpInfo.offlineBanks.map(item => (
              <div className={`payItem ${selectedMethod === item.bankCode && 'selected'}`} onClick={() => { setSelectedMethod(item.bankCode); setbankCode(item.id); }}>
                {item.bankName}
              </div>
            ))
          }
            {
              topUpInfo && topUpInfo.thirdPayGroup && selectedType !== '' && topUpInfo.thirdPayGroup[selectedType].map(item => (
                <div className={`payItem ${selectedMethod === item.id && 'selected'}`} onClick={() => setSelectedMethod(item.id)}>
                  {item.thirdPayName}
                </div>
              ))
          }
          </div>
        </>
        )
        }

      { selectedType !== '' && selectedMethod !== '' && (
      <>
        <div className="subTitleWrapper">{t('SELECT_WALLET')}</div>
        <div className="subContentWrapper">
          {
              topUpInfo && topUpInfo.thirdPayGroup && selectedType !== '' && selectedMethod !== ''
                ? topUpInfo.thirdPayGroup[selectedType].find(item => item.id === selectedMethod) && topUpInfo.thirdPayGroup[selectedType].find(item => item.id === selectedMethod).payBanks.map(item => (
                  <div className={`payItem ${selectedWallet === item.bankCode && 'selected'}`} onClick={() => { setSelectedWallet(item.bankCode); setbankCode(item.bankCode); }}>
                    {item.bankName}
                  </div>
                ))
                : ''
          }
        </div>
      </>
      )}
      {selectedType === '' && selectedMethod !== ''
      && (
      <>
        <div className="subTitleWrapper">{t('REAL_NAME')}</div>
        <div className="subContentWrapper">
          <Input value={bankUserName} onChange={text => setbankUserName(text.trim())} />
        </div>
      </>
      )}

      {selectedMethod !== '' && (
      <>
        <div className="subTitleWrapper">{t('DEPOASIT_AMOUNT')}</div>
        <div className="subContentWrapper NoFlex">
          <Input
            value={acAmount}
            onChange={text => {
              if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                setamount(text === '' || parseInt(text, 10) < 0 ? 0 : parseInt(text, 10));
              }
            }}
          />
          {
            selectedType === ''
              ? <div className="operationBtn mt-3" onClick={() => saveTopUp()}>{t('CONFIRM_TOPUP')}</div>
              : (
                <div className={`operationBtn mt-3 ${currentThirdItem?.payEnable !== 0 ? 'disabled' : ''}`} onClick={() => { if (currentThirdItem?.payEnable === 0)saveTopUp(); }}>
                  {currentThirdItem?.payEnable === 0
                    ? t('CONFIRM_TOPUP')
                    : currentThirdItem?.payEnable === 2
                      ? t('LOCKED_TOPUP')
                      : t('PAUSED_TOPUP')}
                </div>
              )
          }


        </div>
      </>
      )}

      {
        topUpInfo && selectedMethod === '' && (
        <div className="w-100 d-flex justify-content-center py-5">
          <img src={NoData} alt="noData" />
        </div>
        )
      }

    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Deposit);