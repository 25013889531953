import { StatusCodes } from 'http-status-codes';

import { SET_WITHDRAWAL_INFO } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';
import commonActions from '../commonActions';

const setWithdrawalInfo = data => ({
  type: SET_WITHDRAWAL_INFO,
  value: data,
});


const GetInfo = () => dispatch => {
  apiService.get('/Withdraw/GetInfo').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (data.result.bankList.length === 0) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: '您未绑定银行卡！',
            onConfirmationClick: () => dispatch(commonActions.updateSelectedSubMenu('WM_CARD_SETTING')),
            confirmText: '去绑定',
            showCancelButton: false
          }
        }));
      }
      return dispatch(setWithdrawalInfo(data.result));
    }
  }).catch(error => {
    dispatch(setWithdrawalInfo());
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const Save = (obj, callback = () => {}) => dispatch => {
  apiService.post('/Withdraw/Save', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      callback();
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

export default {
  GetInfo,
  Save,
};