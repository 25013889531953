import React from 'react';

import { Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmptyIcon } from '../../../assets/image/order_empty.svg';
import RenderMultipleCells from '../RenderMultipleCells';
import Skeleton from '../../Skeleton';
import './styles.scss';

const ResultTable = ({ rowData, headerCell, bodyCell, _isQuerying = true }) => {
  const { t, i18n } = useTranslation();
  return (
    <Row className="w-100 m-0">
      <table className="resultTable">
        <thead>
          <tr className="resultTableHeader">
            {
          headerCell && headerCell.map((headerItem, headerItemIndex) => (
            <th key={`header_cell_${headerItemIndex.toString()}`}>
              <div className={`${headerItem.wrapperStyle}`}>
                {typeof headerItem.attr === 'function' ? <RenderMultipleCells data={headerItem.attr()} /> : headerItem.attr}
              </div>
            </th>
          ))
        }
          </tr>
        </thead>
        <tbody>
          {
        (rowData && rowData.length > 0) ? rowData.map((item, index) => {
          let emptyTdCell = 0;
          const loadingArr = new Array(headerCell?.length).fill('').map((item, index) => <td className="py-2" key={`table_row_${index.toString()}`}><Skeleton isAnimated /></td>);
          return (!_isQuerying ? (
            <tr className="resultBodyRow" key={`table_row_${index.toString()}`}>
              {bodyCell && bodyCell.map((cellItem, cellItemIndex) => {
                const tempData = typeof cellItem.attr === 'function' ? Array.isArray(cellItem.attr(item)) ? <RenderMultipleCells data={cellItem.attr(item, i18n.language)} /> : cellItem.attr(item) : item[cellItem.attr] || cellItem.attr;
                emptyTdCell = tempData ? 0 : emptyTdCell + 1;
                return tempData !== undefined && (
                  <td key={`table_cell_${cellItemIndex.toString()}`}>
                    <div className={`w-100 h-100 ${cellItem.wrapperStyle}`}>
                      {tempData}
                    </div>
                  </td>
                );
              }).concat(emptyTdCell > 0 && (<td key="table_cell_empty" colSpan={emptyTdCell}>{t('LOTTERY_RESULT_CANCEL')}</td>))}
            </tr>
          ) : (
            <tr className="resultBodyRow" key={`table_row_${index.toString()}`}>
              {loadingArr}
            </tr>
          ));
        })
          : (
            <tr>
              <td colSpan={(headerCell?.length || 0)}>
                <EmptyIcon className="emptyIcon" />
                <div className="emptyTextWrapper">
                  <span>暂无记录</span>
                </div>
              </td>
            </tr>
          )
      }
        </tbody>
      </table>
    </Row>
  );
};

export default ResultTable;