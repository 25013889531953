import { StatusCodes } from 'http-status-codes';
import axios from 'axios';

import { SET_IN_TIME_SHEET_INFO, SET_LIST_ODDS, SET_PRE_AMOUNT } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setInTimeSheetInfo = sheetInfo => ({
  type: SET_IN_TIME_SHEET_INFO,
  value: sheetInfo
});

const setListOdds = listOdds => ({
  type: SET_LIST_ODDS,
  value: listOdds,
});

const updateListOdds = val => dispatch => {
  dispatch(setListOdds(val));
};

const setPreSetAmountList = list => ({
  type: SET_PRE_AMOUNT,
  value: list,
});

const updatePreSetAmountList = list => dispatch => {
  dispatch(setPreSetAmountList(list));
};

const getInTimeSheetInfo = (lotteryCode, callback = () => {}) => dispatch => {
  apiService.get(`/intimesheet/GetIntimesheetInfo/${lotteryCode}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        const { intimesheetDetails } = data.result || {};
        if (intimesheetDetails.currentInstallment.status === 1) {
          callback(intimesheetDetails, lotteryCode);
        } else {
          dispatch(updateListOdds());
        }
        return dispatch(setInTimeSheetInfo(intimesheetDetails));
      }
    }
  }).catch(error => {
    axios.isCancel(error);
  });
};

const getListLianma = (listObj, callback = () => {}) => dispatch => {
  apiService.get('/intimesheet/ListLianma', new URLSearchParams(listObj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return callback(data.result);
      }
    }
  }).catch(error => {
    if (!axios.isCancel(error)) {
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
    }
  });
};

const getListOdds = listObj => dispatch => {
  apiService.get('/intimesheet/Listodds', new URLSearchParams(listObj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(setListOdds(data.result));
      }
    }
  }).catch(error => {
    axios.isCancel(error);
  });
};

const SetBuhuoAmt = (obj, callback = () => {}) => dispatch => {
  apiService.post('/intimesheet/SetBuhuoAmt', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        callback();
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      }
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const Confirmbet = (obj, callback = () => {}) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: false,
  }));
  apiService.post('/intimesheet/Confirmbet', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        if (data.result.succeed === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
        }
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: data.result.msg,
            showCancelButton: false
          }
        }));
      }
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const BuhuoCalc = (obj, callback = () => {}) => dispatch => {
  apiService.get('/intimesheet/BuhuoCalc', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'buhuo',
          data: {
            list: data.result.betData,
            onConfirmationClick: data => dispatch(Confirmbet(data, () => callback()))
          }
        }));
      }
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const TakeBuhuoTicket = (obj, callback = () => {}) => dispatch => {
  apiService.post('/intimesheet/TakeBuhuoTicket', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'buhuo',
          data: {
            list: data.result.betData,
            onConfirmationClick: data => dispatch(Confirmbet(data, () => callback()))
          }
        }));
      }
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetAgentBet = (obj, callback = () => {}) => dispatch => {
  apiService.get('/intimesheet/GetAgentBet', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return callback(data.result);
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false,
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

export default {
  getInTimeSheetInfo,
  getListOdds,
  updateListOdds,
  updatePreSetAmountList,
  SetBuhuoAmt,
  BuhuoCalc,
  Confirmbet,
  TakeBuhuoTicket,
  GetAgentBet,
  getListLianma,
};