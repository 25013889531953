import React, { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { Pagination } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, noticeActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Icon from '../../Icon';

import './styles.scss';

const mapStateToProps = state => ({
  latestAnnouncement: state.noticeReducers.latestAnnouncement,
});

const mapDispatchToProps = dispatch => ({
  getAnnouncement: (page, rows, callback) => dispatch(noticeActions.getAnnouncement(page, rows, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const LatestAnnouncementModal = ({ getAnnouncement, latestAnnouncement, updateModalAlert }) => {
  const { t } = useTranslation();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setIsQueryingDetail(true);
    getAnnouncement(currentPage, 15, () => setIsQueryingDetail(false));
  }, [currentPage]);


  return (
    <div className="latestAnnouncementModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex justify-content-start align-items-center'}`}
        >
          最新公告
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <Icon
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
            libName="io"
            value={{
              name: 'IoClose',
              className: 'h4 mb-0 float-right closeIcon',
            }}
          />
        </Col>
      </Row>
      <div className="modalContent p-2">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={[{
            label: '标题',
          }, {
            label: '内容',
          }, {
            label: '时间',
          }]}
          tableData={latestAnnouncement?.msgList || Array(5).fill({})}
          tableBodyRowStyle={['titleNotice', 'contentNotice', 'dateNotice']}
          tableBodyRowProperty={[
            data => (<Label className="d-flex justify-content-center">{data.title}</Label>),
            data => (<Label className="d-flex justify-content-center">{data.content}</Label>),
            data => (<Label className="d-flex justify-content-center ">{moment.unix(data.date).format('YYYY-MM-DD HH:mm:ss')}</Label>),
          ]}
        />
      </div>
      <div className="d-flex justify-content-center py-2 ">
        <Pagination defaultCurrent={1} current={currentPage} onChange={page => setCurrentPage(page)} defaultPageSize={15} total={latestAnnouncement?.totalRecords ?? 0} />
      </div>

      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
          }}
        >
          {t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LatestAnnouncementModal);