import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { Radio, Select } from 'element-react';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  editWithdrawSettingData: state.systemReducers.editWithdrawSettingData,
});

const mapDispatchToProps = dispatch => ({
  getEditWithdrawSetting: (id, callback) => dispatch(systemActions.getEditWithdrawSetting(id, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveWithdrawSetting: (obj, callback) => dispatch(systemActions.saveWithdrawSetting(obj, callback)),
});

const WithdrawSettingAddEdit = ({
  callback = {},
  isEdit,
  getEditWithdrawSetting,
  data,
  editWithdrawSettingData,
  updateModalAlert,
  saveWithdrawSetting
}) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState({
    maxBanks: 0,
    flowTimes: 0,
    platform: 0,
    status: 0
  });

  useEffect(() => {
    if (isEdit && data?.id) {
      getEditWithdrawSetting({
        id: data.id
      });
    }
  }, [data]);

  useEffect(() => {
    if (editWithdrawSettingData && isEdit) {
      setInfo(editWithdrawSettingData);
    }
  }, [editWithdrawSettingData]);


  const saveButton = () => {
    const updateObj = _info;
    updateModalAlert({
      type: 'loading',
      visible: true,
    });
    saveWithdrawSetting(updateObj, callback);
  };

  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    if (!isEdit) {
      callback();
    } else {
      getEditWithdrawSetting({
        id: data.id
      });
    }
  };

  const status = [
    {
      label: '启用',
      value: 0,
    },
    {
      label: '禁用',
      value: 1,
    }
  ];

  const platforms = [
    {
      label: '后台',
      value: 0,
    }, {
      label: '会员PC版',
      value: 1,
    },
    {
      label: '会员手机版',
      value: 2,
    }
  ];
  return (
    <div className="WithdrawSettingAddEditWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="m-0 my-1 p-0">
          <table className="p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="titleHeader" colSpan={2}>
                  {isEdit === true ? '编辑全局提款' : '新增全局提款'}
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('MIN_SINGLE_WITHDRAW_VALUE')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.min?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'min')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('MAX_SINGLE_WITHDRAW_VALUE')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.max?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'max')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('DAILY_WITHDRAW_LIMIT')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.dayMax?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'dayMax')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('WITHDRAW_FEES_RATE')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.fee?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'fee')}
                    />
                    <Label className="mx-2">%</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('FOC_WITHDRAW_TIME')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.freeTimes?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'freeTimes')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('CHARGE_RATE_FOR_EXCEED_WITHDRAW')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.unFreeTimes?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'unFreeTimes')}
                    />
                    <Label className="mx-2">%</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('DAILY_WITHDRAW_TIME')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.dailyLimit?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'dailyLimit')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('MAX_BIND_BANK_NUMBER')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.maxBanks || 0}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'maxBanks')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('WITHDRAW_TURNOVER_RATE')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.flowTimes?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'flowTimes')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">提款开关状态</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      status.map(d => (
                        <Radio
                          value={_info?.status}
                          checked={_info?.status === Number(d.value)}
                          onChange={() => onInfoValueChange(Number(d.value), 'status')}
                        >
                          {d.label}
                        </Radio>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('CLIENT_PLATFORM_BELONGS')}</td>
                <td>
                  <div>
                    <Select
                      value={_info?.platform || 0}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'platform')}
                    >
                      {
                              platforms.map(d => (
                                <Select.Option key={`setPlatformsSelect_${d.value}`} label={d.label} value={Number(d.value)} />
                              ))
                        }
                    </Select>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('STATUS_REMARK')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.statusInfo || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'statusInfo')}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawSettingAddEdit);