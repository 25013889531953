import { combineReducers } from 'redux';

import agentReducers from './agentReducers';
import appReducers from './appReducers';
import autoRestockSettingReducers from './autoRestockSettingReducers';
import betListReducers from './betListReducers';
import bogusBetReducers from './bogusBetReducers';
import cashReducers from './cashReducers';
import commonReducers from './commonReducers';
import companyReducers from './companyReducers';
import contestReducers from './contestReducers';
import decreaseOddsReducers from './decreaseOddsReducers';
import defaultOddsReducers from './defaultOddsReducers';
import inTimeSheetReducers from './inTimeSheetReducers';
import lotteryResultReducers from './lotteryResultReducers';
import memberReducers from './memberReducers';
import messageReducers from './messageReducers';
import noticeReducers from './noticeReducers';
import oddsManageReducers from './oddsManageReducers';
import oddsSettingReducers from './oddsSettingReducers';
import onlineStatisticsReducers from './onlineStatisticsReducers';
import operationLogReducers from './operationLogReducers';
import reportReducers from './reportReducers';
import systemReducers from './systemReducers';
import thirdPartyGamesReducers from './thirdPartyGamesReducers';
import topUpReducers from './topUpReducers';
import transactionReducers from './transactionReducers';
import whiteListReducers from './whiteListReducers';
import withdrawalReducers from './withdrawalReducers';

const appReducer = combineReducers({
  agentReducers,
  appReducers,
  autoRestockSettingReducers,
  betListReducers,
  bogusBetReducers,
  commonReducers,
  companyReducers,
  contestReducers,
  cashReducers,
  decreaseOddsReducers,
  defaultOddsReducers,
  inTimeSheetReducers,
  lotteryResultReducers,
  memberReducers,
  messageReducers,
  noticeReducers,
  oddsManageReducers,
  oddsSettingReducers,
  operationLogReducers,
  onlineStatisticsReducers,
  reportReducers,
  systemReducers,
  thirdPartyGamesReducers,
  topUpReducers,
  transactionReducers,
  whiteListReducers,
  withdrawalReducers,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;