

import CJ_CHICKEN from '../../assets/image/games/chicken.png';
import CJ_COIN from '../../assets/image/games/coin.png';
import CJ_CRAB from '../../assets/image/games/crab.png';
import CJ_DICE_1 from '../../assets/image/games/dice_1.png';
import CJ_DICE_2 from '../../assets/image/games/dice_2.png';
import CJ_DICE_3 from '../../assets/image/games/dice_3.png';
import CJ_DICE_4 from '../../assets/image/games/dice_4.png';
import CJ_DICE_5 from '../../assets/image/games/dice_5.png';
import CJ_DICE_6 from '../../assets/image/games/dice_6.png';
import CJ_FISH from '../../assets/image/games/fish.png';
import CJ_GOURD from '../../assets/image/games/gourd.png';
import CJ_PRAWN from '../../assets/image/games/prawn.png';

import CJ_APPLE from '../../assets/image/games/apple.png';
import CJ_CHERRY from '../../assets/image/games/cherry.png';
import CJ_COCONUT from '../../assets/image/games/coconut.png';
import CJ_COW from '../../assets/image/games/cow.png';
import CJ_DOG from '../../assets/image/games/dog.png';
import CJ_DURIAN from '../../assets/image/games/durian.png';
import CJ_EGGPLANT from '../../assets/image/games/eggplant.png';
import CJ_GRAPES from '../../assets/image/games/grapes.png';
// import CJ_ICO_HOT from '../../assets/Mobile/image/ico_hot.png';
// import CJ_ICO_NEW from '../../assets/Mobile/image/ico_new.png';
import CJ_LYCHEE from '../../assets/image/games/lychee.png';
import CJ_ORANGE from '../../assets/image/games/orange.png';
import CJ_PEACH from '../../assets/image/games/peach.png';
import CJ_PEAR from '../../assets/image/games/pear.png';
import CJ_PINEAPPLE from '../../assets/image/games/pineapple.png';
import CJ_POMELO from '../../assets/image/games/pomelo.png';
import CJ_PUMPKIN from '../../assets/image/games/pumpkin.png';
import CJ_RADISH from '../../assets/image/games/radish.png';
import CJ_STRAWBERRY from '../../assets/image/games/strawberry.png';
import CJ_TOMATO from '../../assets/image/games/tomato.png';
import CJ_WATERMELON from '../../assets/image/games/watermelon.png';
import CJ_WINTERMELON from '../../assets/image/games/wintermelon.png';

import CJ_SSC_HLSX_BALL0 from '../../assets/image/games/SSC_HLSX_Ball0.png';
import CJ_SSC_HLSX_BALL1 from '../../assets/image/games/SSC_HLSX_Ball1.png';
import CJ_SSC_HLSX_BALL2 from '../../assets/image/games/SSC_HLSX_Ball2.png';
import CJ_SSC_HLSX_BALL3 from '../../assets/image/games/SSC_HLSX_Ball3.png';
import CJ_SSC_HLSX_BALL4 from '../../assets/image/games/SSC_HLSX_Ball4.png';
import CJ_SSC_HLSX_BALL5 from '../../assets/image/games/SSC_HLSX_Ball5.png';
import CJ_SSC_HLSX_BALL6 from '../../assets/image/games/SSC_HLSX_Ball6.png';
import CJ_SSC_HLSX_BALL7 from '../../assets/image/games/SSC_HLSX_Ball7.png';
import CJ_SSC_HLSX_BALL8 from '../../assets/image/games/SSC_HLSX_Ball8.png';
import CJ_SSC_HLSX_BALL9 from '../../assets/image/games/SSC_HLSX_Ball9.png';

export default {
  CJ_CHICKEN,
  CJ_COIN,
  CJ_CRAB,
  CJ_DICE_1,
  CJ_DICE_2,
  CJ_DICE_3,
  CJ_DICE_4,
  CJ_DICE_5,
  CJ_DICE_6,
  CJ_FISH,
  CJ_GOURD,
  CJ_PRAWN,
  CJ_WATERMELON,
  CJ_COCONUT,
  CJ_DURIAN,
  CJ_GRAPES,
  CJ_PEAR,
  CJ_RADISH,
  CJ_LYCHEE,
  CJ_APPLE,
  CJ_PUMPKIN,
  CJ_CHERRY,
  CJ_PEACH,
  CJ_EGGPLANT,
  CJ_POMELO,
  CJ_STRAWBERRY,
  CJ_ORANGE,
  CJ_DOG,
  CJ_PINEAPPLE,
  CJ_TOMATO,
  CJ_WINTERMELON,
  CJ_COW,
  // CJ_ICO_HOT,
  // CJ_ICO_NEW
  CJ_SSC_HLSX_BALL0,
  CJ_SSC_HLSX_BALL1,
  CJ_SSC_HLSX_BALL2,
  CJ_SSC_HLSX_BALL3,
  CJ_SSC_HLSX_BALL4,
  CJ_SSC_HLSX_BALL5,
  CJ_SSC_HLSX_BALL6,
  CJ_SSC_HLSX_BALL7,
  CJ_SSC_HLSX_BALL8,
  CJ_SSC_HLSX_BALL9,
};