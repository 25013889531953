import React, { useEffect, useState } from 'react';

import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';

import { AgentDataTable, Pagination } from '../..';
import { appActions, noticeActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  warningMsg: state.noticeReducers.warningMsg,
  warningMsgHistory: state.noticeReducers.warningMsgHistory,
});

const mapDispatchToProps = dispatch => ({
  GetWarningMsgHistory: (page, rows, callback) => dispatch(noticeActions.GetWarningMsgHistory(page, rows, callback)),
  HandledWarningMsg: (page, rows, callback) => dispatch(noticeActions.HandledWarningMsg(page, rows, callback)),
  GetWarningMsg: (page, rows, callback) => dispatch(noticeActions.GetWarningMsg(page, rows, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const HistoryTask = ({ updateModalAlert, warningMsg, warningMsgHistory, GetWarningMsgHistory, HandledWarningMsg, GetWarningMsg }) => {
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_page, setPage] = useState(0);
  const [_rows, setRows] = useState(15);
  const [menuLabel, setMenuLabel] = useState(1);

  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(true);
    if (menuLabel === 1) {
      GetWarningMsgHistory(pageNum - 1, rows, () => setIsQuerying(false));
    } else {
      GetWarningMsg(pageNum - 1, rows, () => setIsQuerying(false));
    }
    setPage(pageNum - 1);
    setRows(rows);
  };

  useEffect(() => {
    setPage(0);
    setRows(15);
    if (menuLabel === 1) {
      GetWarningMsgHistory(0, 15, () => setIsQuerying(false));
    } else {
      GetWarningMsg(0, 15, () => setIsQuerying(false));
    }
  }, [menuLabel]);

  const handleMsg = id => {
    updateModalAlert({
      type: 'loading',
      visible: true
    });
    HandledWarningMsg(id, _page, _rows);
  };

  return (
    <div className="historyTaskWrapper">
      <div className="menuWrapper pb-2">
        <div className={`menuItem mx-2 py-1  ${menuLabel === 1 && 'selected'}`} onClick={() => setMenuLabel(1)}>历史任务</div>
        <div className={`menuItem mx-2 py-1 ${menuLabel === 2 && 'selected'}`} onClick={() => setMenuLabel(2)}>报警通知</div>
      </div>
      {menuLabel === 2
        ? (
          <>
            <div className="mx-0 bg-white mb-3  border-1">
              <AgentDataTable
                isQuerying={_isQuerying}
                tableHeaderTitle={
            [{
              label: '时间',
            }, {
              label: '报警内容',
            }, {
              label: '操作',
            }]
          }
                tableHeaderStyle={
            ['w-25', 'w-50', 'w-25']
          }
                tableBodyRowStyle={['w-25', 'w-50', 'w-25']}
                tableData={warningMsg ? warningMsg.data : []}
                tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.createDate).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.warningInfor}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.handled === 0 && <div onClick={() => handleMsg(data?.id)} className="operationBtn">完成</div>}</Label>),
            ]
          }
              />
            </div>
            <div className="justify-center w-full relative">
              <Pagination totalRecord={warningMsg ? warningMsg?.sum?.totalRecord : 0} onClick={(pageNum, rows) => onPaginationClick(pageNum, rows)} isQuery={false} />
            </div>
          </>
        )
        : (
          <>
            <div className="mx-0 bg-white mb-3  border-1">
              <AgentDataTable
                isQuerying={_isQuerying}
                tableHeaderTitle={
            [{
              label: '时间',
            }, {
              label: '任务内容',
            }, {
              label: '状态',
            }, {
              label: '完成人'
            }]
          }
                tableHeaderStyle={
            ['w-25', 'w-25', 'w-25', 'w-25']
          }
                tableBodyRowStyle={['w-25', 'w-25', 'w-25', 'w-25']}
                tableData={warningMsgHistory ? warningMsgHistory.data : []}
                tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.createDate).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.warningInfor}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.handled === 1 && '已处理'}</Label>),
              data => (<Label className="d-flex justify-content-center">{data?.operator || '未知'}</Label>),
            ]
          }
              />
            </div>
            <div className="justify-center w-full relative">
              <Pagination totalRecord={warningMsgHistory ? warningMsgHistory.sum?.totalRecord : 0} onClick={(pageNum, rows) => onPaginationClick(pageNum, rows)} isQuery={false} />
            </div>
          </>
        )}

    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTask);