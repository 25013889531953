import React, { useState } from 'react';

import { DateRangePicker, Input, Select } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable } from '../..';
import { reportActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  financialReport: state.reportReducers.financialReport,
});

const mapDispatchToProps = dispatch => ({
  getFinancialReport: (obj, callback) => dispatch(reportActions.getFinancialReport(obj, callback)),
});

const FinancialReport = ({ getFinancialReport, financialReport }) => {
  const { t } = useTranslation();
  const [_date, setDate] = useState([moment().startOf('day').toDate(), moment().toDate()]);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [memberType, setMemType] = useState(2);
  const [loginName, setUserId] = useState('');
  const [_dateFlag, setDateFlag] = useState('1');
  const [_hide, setHide] = useState(false);

  let dateRef;

  const onSearchClick = () => {
    const obj = {
      memberType,
      loginName,
      beginDate: moment(_date[0]).format('YYYY-MM-DD'),
      endDate: moment(_date[1]).format('YYYY-MM-DD')
    };
    setIsQueryingDetail(true);
    getFinancialReport(obj, setIsQueryingDetail);
  };

  const dateSelector = id => {
    setDateFlag(id);
    switch (id) {
      case '1':
        setDate([moment().toDate(), moment().toDate()]);
        break;
      case '2':
        setDate([moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]);
        break;
      case '3':
        setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
        break;
      case '4':
        setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
        break;
      case '5':
        setDate([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
        break;
      case '6':
        setDate([moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()]);
        break;
      default:
        break;
    }
  };

  const cancel = () => {
    setDate([moment().subtract(1, 'weeks').toDate(), moment().toDate()]);
    setMemType(1);
    setUserId('');
    setDateFlag('');
    const obj = {
      memberType: 1, loginName: '', beginDate: moment().add(-7, 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')
    };
    getFinancialReport(obj, setIsQueryingDetail);
  };

  return (
    <div className="financialReportWrapper">
      <div className="financialReportTableWrapper d-flex p-3">
        <div className="userTable w-100">
          <div className="d-flex">
            <div>日期范围</div>
            <div>
              <DateRangePicker
                className="betQueryDatepicker"
                value={_date}
                isShowTime
                placeholder={t('START_END_DATE')}
                rangeSeparator={` ${t('TO')} `}
                onChange={_date => {
                  setDate(_date);
                }}
                ref={e => dateRef = e}
                shortcuts={[{
                  text: t('TODAY'),
                  onClick: () => {
                    setDate([moment().toDate(), moment().toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('THIS_WEEK'),
                  onClick: () => {
                    setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_2_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }]}
              />
              <div onClick={e => dateSelector(e.target.id)} className="d-flex mx-2">
                {
              [{
                label: '今天', value: 1
              }, {
                label: '昨天', value: 2
              }, {
                label: '本星期', value: 3
              }, {
                label: '上星期', value: 4
              }, {
                label: '本月', value: 5
              }, {
                label: '上月', value: 6
              }].map(item => <span id={item.value} className={`mx-1 operationBtn dateSelectBtn ${+_dateFlag === item.value && 'selected'}`}>{item.label}</span>)

                 }
              </div>
              <div className="operationBtn findAndCollapse mx-1" onClick={onSearchClick}>查询</div>
              <div className="operationBtn cancel mx-1" onClick={() => cancel()}>取消</div>
              <div className="flex-grow-1 d-flex justify-content-end pe-4">
                <div className="operationBtn findAndCollapse" onClick={() => setHide(!_hide)}>{!_hide ? '收起' : '展开'}</div>
              </div>

            </div>
          </div>
          {!_hide && (
          <>
            <div className="d-flex">
              <div>用户类型</div>
              <div>
                <Select
                  popper-class="select-popper"
                  value={memberType}
                  onChange={value => setMemType(value)}
                >
                  {
                    [{
                      label: '会员', value: 1
                    }, {
                      label: '代理', value: 2
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
                </Select>

              </div>
            </div>
            <div className="d-flex">
              <div>上级代理</div>
              <div>
                <Input className="mx-1" value={loginName} onChange={text => setUserId(text)} />
              </div>
            </div>
          </>
          )}
        </div>
      </div>
      <div className="financialReportTableWrapper p-3">
        <div className="mx-0 bg-white">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: '日期',
            }, {
              label: '存款',
            }, {
              label: '代理转账',
            }, {
              label: '取款',
            }, {
              label: '红利',
            }, {
              label: '反水',
            }, {
              label: '手续费(存)',
            }, {
              label: '手续费(提)',
            }, {
              label: '有效金额',
            }, {
              label: '会员盈亏',
            }, {
              label: '占成',
            }, {
              label: '总盈亏',
            }]
          }
            tableData={financialReport ? financialReport.reportList.map((item, index) => ({
              ...item, no: index + 1
            })) : []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.no}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.date).format('YYYY-MM-DD')}</Label>),
              data => <Label className="d-flex justify-content-center">{data.deposit}</Label>,
              data => (<Label className="d-flex justify-content-center">{data.transfer}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.withdraw}</Label>),
              data => (<Label className="d-flex justify-content-center  text-primary">{data.promos}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.rebate}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.depositFee}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.withdrawFee}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.winValid}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.winAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.zc}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.winLost}</Label>),
            ]
          }
            tableFooter={[
              [{
                value: '所有总和',
                colspan: 2,
                className: 'textRight pr-1 total tableSum'
              },
              {
                value: (financialReport && financialReport.total.deposit) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (financialReport && financialReport.total.transfer) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (financialReport && financialReport.total.withdraw) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (financialReport && financialReport.total.promos) || 0,
                colspan: 1,
                className: 'tableSum  text-primary'
              },
              {
                value: (financialReport && financialReport.total.rebate) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (financialReport && financialReport.total.depositFee) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (financialReport && financialReport.total.withdrawFee) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (financialReport && financialReport.total.winValid) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (financialReport && financialReport.total.winAmount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (financialReport && financialReport.total.zc) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (financialReport && financialReport.total.winLost) || 0,
                colspan: 1,
                className: 'tableSum'
              }]
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReport);