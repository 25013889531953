import React from 'react';

import { connect } from 'react-redux';

import { Loading } from 'element-react';
import { appActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const LoadingModal = () => (
  <div className="loadingModalWrapper">
    <Loading />
  </div>
);

export default connect(mapStateToProps, mapDispatchToProps)(LoadingModal);