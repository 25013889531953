import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Popover } from 'antd';

import { commonActions, noticeActions } from '../../../redux/actions';
import Icon from '../../Icon';
import bell from '../../../assets/audio/bell.mp3';
import './styles.scss';

const mapStateToProps = state => ({
  notifications: state.noticeReducers.notifications,
  bellMuted: state.noticeReducers.bellMuted,
});

const mapDispatchToProps = dispatch => ({
  updateSelectedSubMenu: val => dispatch(commonActions.updateSelectedSubMenu(val)),
  setBell: val => dispatch(noticeActions.setBell(val)),
});

const CommonHeader = ({ notifications, updateSelectedSubMenu, bellMuted, setBell }) => {
  const [ls, setLs] = useState([]);
  const history = useHistory();
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = () => {
      if (!bellMuted && ls.length > 0) {
        const audio = new Audio(bell);
        audio.load();
        audio.play();
      }
    };
  });

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    const id = setInterval(tick, 10000);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (notifications) {
      let ls = [];
      Object.entries(notifications).forEach(item => {
        if (item[1].length > 0) {
          ls = ls.concat([].concat(item[1].map(x => ({
            ...x, tab: item[0]
          }))));
        }
      });
      setLs(ls);
    }
  }, [notifications]);


  const goToNewPage = item => {
    if (item.tab === 'cashReminder') {
      if (item.type === '100') {
        updateSelectedSubMenu('TM_DEPOSIT_AUDIT');
      }
      if (item.type === '200') {
        updateSelectedSubMenu('TM_WITHDRAWAL_AUDIT');
      }
      if (item.type === '200USDT') {
        updateSelectedSubMenu('TM_USDT_WITHDRAWAL_AUDIT');
      }
      history.push('/agent/transaction-management');
    }
    if (item.tab === 'userFeedback') {
      updateSelectedSubMenu('TM_MESSAGE_CENTER');
      history.push('/agent/transaction-management');
    }
    if (item.tab === 'warning') {
      updateSelectedSubMenu('SS_SYSTEM_NOTICE');
      history.push('/agent/system-settings');
    }
    if (item.tab === 'onlineReminder') {
      updateSelectedSubMenu('');
      history.push('/agent/online-statistics');
    }
    if (item.tab === 'resultError' || item.tab === 'resultTimeout') {
      updateSelectedSubMenu('IM_HISTORY_RESULT_MANAGEMENT');
      history.push('/agent/installment-management');
    }
  };

  const content = (
    <div className="reminderBox">
      <div className="reminderContent">
        <div className="contentScrollArea">
          {
        (ls.length > 0) ? ls.map(item => (
          <div className="reminderRow" onClick={() => goToNewPage(item)}>
            <div>{`${item.name}:`}</div>
            <div>{`${item.count}个`}</div>
          </div>
        ))
          : (
            <div className="pt-5 d-flex justify-content-center align-items-center noDataStyle">
              暂无代办记录！
            </div>
          )
      }
        </div>
      </div>
    </div>
  );

  return (
    <div className="d-flex NotificationWrapper reminderWrapper align-items-center justify-content-end">
      <div className="bellNoti align-items-center p-0 px-2 m-0" onClick={() => setBell(!bellMuted)}>
        <Icon
          libName="fa"
          value={{
            name: `${bellMuted ? 'FaBellSlash' : 'FaBell'}`,
            className: 'ml-2'
          }}
        />
      </div>
      <div className="reminderWrapper">
        <Popover content={content} placement="bottom" title="待办提醒" trigger="click">
          <div className={`bellNotiCircle mx-2 ${(ls && ls?.length > 0) && 'blink'}`}>
            <Icon
              libName="fa"
              value={{
                name: 'FaClipboardList',
                className: 'ml-2'
              }}
            />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonHeader);