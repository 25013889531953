import React from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CommonHeader, Icon } from '../..';
import { thirdPartyGamesActions } from '../../../redux/actions';

import BankSettingAddEdit from '../BankSettingAddEdit';
import EmailAddEdit from '../EmailAddEdit';
import ExRateSettingAddEdit from '../ExRateSettingAddEdit';
import ExternalWebAddEdit from '../ExternalWebAddEdit';
import LotterySetting from '../LotterySetting';
import MemberAutoClassAddEdit from '../MemberAutoClassAddEdit';
import PaymentAccEditAdd from '../PaymentAccEditAdd';
import PaymentOfflineSettingAddEdit from '../PaymentOfflineSettingAddEdit';
import PublishNotices from '../PublishNotices';
import SMSAddEdit from '../SMSAddEdit';
import ThirdPartyPayEdit from '../ThirdPartyPayEdit';
import WithdrawSettingAddEdit from '../WithdrawSettingAddEdit';

import 'element-theme-default';
import './styles.scss';


const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
});

const mapDispatchToProps = dispatch => ({
  SaveThirdPartyGame: (obj, callback) => dispatch(thirdPartyGamesActions.SaveThirdPartyGame(obj, callback)),
});

const SystemSettingSub = ({ data, selectedSubMenu, callback = () => {}, tabName, isEdit }) => {
  const { t } = useTranslation();

  const renderTabItem = () => {
    switch (tabName) {
      case 'lotterySetting': return <LotterySetting key={data?.orgId} data={data} isEdit={isEdit} callback={() => callback()} />;
      case 'publishNotices': return <PublishNotices key={data?.orgId} data={data} isEdit={isEdit} callback={() => callback()} />;
      case 'paymentAccEditAdd': return <PaymentAccEditAdd key={data?.orgId} data={data} isEdit={isEdit} callback={() => callback()} />;
      case 'externalWebAddEdit': return <ExternalWebAddEdit key={data?.id} data={data} isEdit={isEdit} callback={() => callback()} />;
      case 'thirdPartyPayEdit': return <ThirdPartyPayEdit key={data?.id} data={data} isEdit={isEdit} callback={() => callback()} />;
      case 'memberClassification': return <MemberAutoClassAddEdit key={data?.id} data={data} isEdit={isEdit} callback={() => callback()} />;
      case 'paymentOfflineSetting': return <PaymentOfflineSettingAddEdit key={data?.id} data={data} isEdit={isEdit} callback={() => callback()} />;
      case 'smsAddEdit': return <SMSAddEdit key={data?.id} data={data} isEdit={isEdit} callback={() => callback()} />;
      case 'emailAddEdit': return <EmailAddEdit key={data?.id} data={data} isEdit={isEdit} callback={() => callback()} />;
      case 'withdrawSettingAddEdit': return <WithdrawSettingAddEdit key={data?.id} data={data} isEdit={isEdit} callback={() => callback()} />;
      case 'exRateAddEdit': return <ExRateSettingAddEdit key={data?.id} data={data} isEdit={isEdit} callback={() => callback()} />;
      case 'bankSettingAddEdit': return <BankSettingAddEdit key={data?.id} data={data} isEdit={isEdit} callback={() => callback()} />;
      default:
        break;
    }
  };

  const getTitle = () => {
    switch (tabName) {
      case 'lotterySetting': return '彩种设置';
      case 'publishNotices': return isEdit ? '修改公告' : '发布公告';
      case 'paymentAccEditAdd': return isEdit ? '编辑收款账户' : '新增收款账户';
      case 'externalWebAddEdit': return isEdit ? '编辑外补网站' : '新增外补网站';
      case 'thirdPartyPayEdit': return isEdit ? '修改收款第三方设置' : '新增收款第三方设置';
      case 'memberClassification': return isEdit ? '编辑用户自动分类' : '新增用户自动分类';
      case 'paymentOfflineSetting': return isEdit ? '编辑收款线下设置' : '新增收款线下设置';
      case 'smsAddEdit': return isEdit ? '编辑短信管理' : '新增短信管理';
      case 'emailAddEdit': return isEdit ? '编辑邮箱接口' : '新增邮箱接口';
      case 'withdrawSettingAddEdit': return isEdit ? '编辑全局提款' : '新增全局提款';
      case 'exRateAddEdit': return isEdit ? '编辑全局提款设置' : '新增全局提款设置';
      case 'bankSettingAddEdit': return isEdit ? '编辑提款绑定银行' : '新增提款绑定银行';
      default:
        break;
    }
  };

  return (
    <div className="systemSettingSubWrapper p-0">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => callback()}>
              <Icon
                libName="md"
                value={{
                  name: 'MdArrowBack',
                  className: 'h5 mb-0 text-white'
                }}
              />
              返回
            </div>
            {selectedSubMenu && (`${t(selectedSubMenu.slice(3))}`)}
            &nbsp;/&nbsp;
            {getTitle()}
          </div>
        )}
      />
      {renderTabItem()}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemSettingSub);