import React, { useEffect, useState } from 'react';

import { InputNumber, Radio } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, inTimeSheetActions } from '../../redux/actions';
import Add from '../../assets/image/button_add.svg';
import Remove from '../../assets/image/button_remove.svg';
import oddsSettingActions from '../../redux/actions/oddsSettingActions';
import './styles.scss';

const mapStateToProps = state => ({
  presetAmountList: state.inTimeSheetReducers.presetAmountList,
  activeLottery: state.commonReducers.activeLottery,
  inTimeSheetInfo: state.inTimeSheetReducers.inTimeSheetInfo,
  batchOddsList: state.oddsSettingReducers.batchOddsList,
  selectedSubMenu: state.commonReducers.selectedSubMenu,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  QuickSaveOdds: (obj, callback) => dispatch(oddsSettingActions.QuickSaveOdds(obj, callback)),
  SetBuhuoAmt: (obj, callback) => dispatch(inTimeSheetActions.SetBuhuoAmt(obj, callback)),
  BuhuoCalc: (obj, callback) => dispatch(inTimeSheetActions.BuhuoCalc(obj, callback)),
});

const InTimeSheetBottomMenu = ({
  _selectedAmount = 0,
  setAmount = () => {},
  _isAvailable,
  buhuoData,
  amountMode,
  SetBuhuoAmt,
  BuhuoCalc,
  callOdds = () => {},
  selectedSubMenu,
  presetAmountList,
  updateModalAlert,
  activeLottery,
  inTimeSheetInfo,
  QuickSaveOdds,
  batchOddsList
}) => {
  const [buhuoType, setbuhuoType] = useState(0);
  const { t } = useTranslation();
  const [buhuoAmount, setbuhuoAmount] = useState(0);
  const [winLostLimit, setwinLostLimit] = useState(0);

  useEffect(() => {
    setAmount(presetAmountList[0]);
  }, [presetAmountList]);

  const changeOdds = type => {
    if (inTimeSheetInfo && batchOddsList) {
      const oddsChange = () => {
        const keyCode = Object.keys(batchOddsList).join();
        updateModalAlert({
          type: 'loading',
          visible: true
        });
        if (type === 'add') {
          const temp = {
            lotteryType: activeLottery.code,
            downValue: Number(_selectedAmount),
            keyCode
          };
          QuickSaveOdds(temp, callOdds);
        } else {
          const temp = {
            lotteryType: activeLottery.code,
            downValue: -Number(_selectedAmount),
            keyCode
          };
          QuickSaveOdds(temp, callOdds);
        }
      };
      updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          message: '请确认是否要修改/设置',
          onConfirmationClick: () => oddsChange()
        }
      });
    }
  };

  const onClickBuhuoCalc = () => {
    if (activeLottery && inTimeSheetInfo?.currentInstallment) {
      const obj = {
        lotteryType: activeLottery.code,
        installments: inTimeSheetInfo?.currentInstallment?.currentInstallmentNo,
        buhuoCode: 1,
        buhuoAmount,
        buhuoType,
        amountMode,
        settingCode: 'TMA,TMB'
      };
      BuhuoCalc(obj, () => { callOdds(); setbuhuoAmount(0); });
    }
  };

  const onClickBuhuoAmount = () => {
    const obj = {
      lotteryType: activeLottery.code,
      winLostLimit,
      settingCode: buhuoData ? buhuoData.settingCode || buhuoData.midCode : ''
    };
    SetBuhuoAmt(obj, () => callOdds());
  };


  return (
    <div className="InTimeSheetBottomMenuWrapper">
      {(inTimeSheetInfo?.orgLevelValue === 1 || (inTimeSheetInfo?.orgLevelValue === 2 && inTimeSheetInfo?.orgCtrlSetting === 1))
      && (
        <div className="d-flex align-items-center">
          <div>{t('INCREASEMENT')}</div>
          <InputNumber
            className="mx-2 amountInput"
            value={_selectedAmount}
            onChange={text => {
              setAmount(text);
            }}
          />
          <div className="d-flex">
            {
              presetAmountList.map((item, index) => <div key={`bottom_menu_item_${index.toString()}`} onClick={() => setAmount(item)} className={`amountItem ${_selectedAmount === item && 'selected'}`}>{item}</div>)
            }
          </div>
          <div
            className="amountSettingBtn"
            onClick={() => updateModalAlert({
              type: 'presetAmount',
              visible: true
            })}
          >
            {t('SETTING')}
          </div>
          {_isAvailable && (
          <>
            <img src={Add} alt="" className="button_do mx-1" onClick={e => { e.stopPropagation(); changeOdds('add'); }} />
            <img src={Remove} alt="" className="button_do" onClick={e => { e.stopPropagation(); changeOdds('reduce'); }} />
          </>
          )}
        </div>
      )}
      {activeLottery.templateCode === 'HK6'
      && (
      <div className="d-flex align-items-center">
        <InputNumber
          placeholder="快速补货"
          className="mx-2  amountInput"
          value={winLostLimit}
          onChange={text => {
            setwinLostLimit(text);
          }}
        />
        <div className="amountSettingBtn" onClick={() => onClickBuhuoAmount()}>计算补货</div>
      </div>
      )}
      {
        (activeLottery.templateCode === 'HK6' || activeLottery.templateCode === 'F36X7' || activeLottery.templateCode === 'F31X7') && selectedSubMenu === 0
      && (
      <div className="d-flex align-items-center">
        <InputNumber
          placeholder="快速补货"
          className="mx-2 amountInput"
          value={buhuoAmount}
          onChange={text => {
            setbuhuoAmount(text);
          }}
        />
        <Radio.Group value={buhuoType} onChange={e => setbuhuoType(e.target.value)} className="inTimeSheetCheckBoxGroup">
          <Radio value={0}>盈亏金额</Radio>
          <Radio value={1}>占成金额</Radio>
          <Radio value={2}>预计金额</Radio>
        </Radio.Group>
        <div className="amountSettingBtn" onClick={() => onClickBuhuoCalc()}>计算补货</div>
      </div>
      )
      }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InTimeSheetBottomMenu);