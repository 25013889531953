import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { Select } from 'element-react';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  systemSettingInfoData: state.systemReducers.systemSettingInfoData,
  userData: state.memberReducers.userData,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
});

const mapDispatchToProps = dispatch => ({
  getInfo: (userId, callback) => dispatch(systemActions.getInfo(userId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveInfo: (obj, callback) => dispatch(systemActions.saveInfo(obj, callback)),
});

const SystemManage = ({ systemSettingInfoData, getInfo, data, saveInfo, updateModalAlert }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState(systemSettingInfoData);


  useEffect(() => {
    setInfo(systemSettingInfoData);
    if (systemSettingInfoData && JSON.stringify(systemSettingInfoData) !== JSON.stringify(_info)) {
      setInfo(systemSettingInfoData);
    }
  }, [systemSettingInfoData]);

  useEffect(() => {
    getInfo();
  }, [data]);

  const saveButton = () => {
    if (_info?.isChange) {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const obj = _info;
      saveInfo(obj);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '无变动'
        }
      });
    }
  };

  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    newItems.isChange = true;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    getInfo();
  };

  const MaintenanceStatus = [
    {
      label: '关闭',
      value: '1',
    },
    {
      label: '开启',
      value: '0',
    }
  ];

  const MultiGameStatus = [
    {
      label: '允许',
      value: '1',
    },
    {
      label: '不允许',
      value: '0',
    }
  ];

  const AutoBuhuoStatus = [
    {
      label: '允许(自由修改)',
      value: '0',
    },
    {
      label: '允许(只允许未有注单的用户)',
      value: '2',
    }, {
      label: '不允许',
      value: '1',
    }
  ];

  const AutoReduceOdds = [
    {
      label: '按下注总额',
      value: '0',
    }, {
      label: '按公司占成',
      value: '1',
    }
  ];

  const DecimalPointOptions = [
    {
      label: '不留小数点',
      value: '0',
    }, {
      label: '保留 1 位',
      value: '1',
    }, {
      label: '保留 2 位',
      value: '2',
    }, {
      label: '保留 3 位',
      value: '3',
    }, {
      label: '保留 4 位',
      value: '4',
    }
  ];

  const DisplayStatus = [
    {
      label: '显示',
      value: '1',
    },
    {
      label: '不显示',
      value: '0',
    }
  ];


  const YesNoStatus = [
    {
      label: '否',
      value: '0',
    },
    {
      label: '是',
      value: '1',
    }
  ];


  const ReportUnit = [
    {
      label: '日',
      value: 'd',
    }, {
      label: '周',
      value: 'w',
    }, {
      label: '月',
      value: 'm',
    }
  ];

  const PasswordValidPeriod = [
    {
      label: '无限',
      value: '0',
    }, {
      label: '一周',
      value: '7',
    }, {
      label: '两周',
      value: '14',
    }, {
      label: '三周',
      value: '28',
    }, {
      label: '一个月',
      value: '30',
    }, {
      label: '两个月',
      value: '60',
    }, {
      label: '三个月',
      value: '90',
    }, {
      label: '半年',
      value: '180',
    }
  ];

  return (
    <div className="SystemManageWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="bigWrapper m-0 my-1 p-3">
          <table className="agentDataTable p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="tableHeader col-2">系统维护</td>
                <td>
                  <div
                    className="d-flex p-0 rdSearchOptionsGroup"
                  >
                    <Select
                      value={_info?.isMaintenance}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'isMaintenance')}
                    >
                      {
                           MaintenanceStatus.map(d => (
                             <Select.Option key={`setSelectedisMaintenance_${d.value}`} label={d.label} value={Number(d.value)} />
                           ))
                      }
                    </Select>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">开盘中修改自动补货</td>
                <td>
                  <div
                    className="d-flex p-0 rdSearchOptionsGroup"
                  >
                    <Select
                      value={_info?.isAutoBuhuo}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'isAutoBuhuo')}
                    >
                      {
                           AutoBuhuoStatus.map(d => (
                             <Select.Option key={`setAutBuhuo_${d.value}`} label={d.label} value={Number(d.value)} />
                           ))
                      }
                    </Select>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">会员账户历史显示</td>
                <td>
                  <Label>系统最多保留3个月的数据。</Label>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">自动降赔率规则</td>
                <td>
                  <div
                    className="d-flex p-0 rdSearchOptionsGroup"
                  >
                    <Select
                      value={_info?.isAutoReduceOdds || 1}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'isAutoReduceOdds')}
                    >
                      {
                           AutoReduceOdds.map(d => (
                             <Select.Option key={`setAutoReduceOdds_${d.value}`} label={d.label} value={Number(d.value)} />
                           ))
                      }
                    </Select>
                    <Label className="mx-2 p-1">注意：此项请在开盘前设置！</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">预计盈利小数点</td>
                <td>
                  <div
                    className="d-flex p-0 rdSearchOptionsGroup"
                  >
                    <Select
                      value={_info?.profileDecimalPoint}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'profileDecimalPoint')}
                    >
                      {
                            DecimalPointOptions.map(d => (
                              <Select.Option key={`setProfileDecimalPoint_${d.value}`} label={d.label} value={Number(d.value)} />
                            ))
                      }
                    </Select>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">今日输赢报警</td>
                <td>
                  <Label>赢</Label>
                  <Input
                    className="d-inline mx-2"
                    type="number"
                    value={_info?.winLimit}
                    onChange={({ target: { value: text } }) => onInfoValueChange(Number(text), 'winLimit')}
                  />
                  <Label>输</Label>
                  <Input
                    className="d-inline mx-2"
                    type="number"
                    value={_info?.loseLimit}
                    onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'loseLimit')}
                  />
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">连码显示赔率</td>
                <td>
                  <div className="d-flex p-0 rdSearchOptionsGroup">
                    {
                                DisplayStatus.map(d => (
                                  <div key={`rdDisplayStatusGroup_${d.value}`}>
                                    <Input
                                      id={`rdDisplayStatus_${d.value}`}
                                      type="radio"
                                      name="rdDisplayStatus"
                                      value={_info?.showLMOdds}
                                      checked={_info?.showLMOdds === Number(d.value)}
                                      onChange={() => onInfoValueChange(Number(d.value), 'showLMOdds')}
                                    />
                                    <Label
                                      for={`rdDisplayStatus_${d.value}`}
                                      className={_info?.showLMOdds === Number(d.value) ? 'active mx-2' : 'mx-2'}
                                    >
                                      {d?.label}
                                    </Label>
                                  </div>
                                ))
                              }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">系统维护公告</td>
                <td>
                  <span className="textAreaInput">
                    <Input
                      className="d-inline col-1"
                      type="textarea"
                      value={_info?.noticeMsg}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'noticeMsg')}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">限制赔率不能高于默认赔率</td>
                <td>
                  <span>
                    <Select
                      value={_info?.rateLimit}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'rateLimit')}
                    >
                      {
                              YesNoStatus.map(d => (
                                <Select.Option key={`setReportUnit_${d.value}`} label={d.label} value={Number(d.value)} />
                              ))
                        }
                    </Select>
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">代理报表查询限制</td>
                <td>
                  <span className="smallInput">
                    <Input
                      className="d-inline"
                      type="number"
                      defaultValue={_info?.reportValue}
                      onBlur={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'reportValue')}
                    />
                    <Select
                      value={_info?.reportUnit}
                      placeholder="请选择"
                      className="mx-2"
                      onChange={val => onInfoValueChange(val, 'reportUnit')}
                    >
                      {
                              ReportUnit.map(d => (
                                <Select.Option key={`setReportUnit_${d.value}`} label={d.label} value={d.value} />
                              ))
                        }
                    </Select>
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">用户密码有效期</td>
                <td>
                  <Select
                    value={_info?.passwordLifeCycle}
                    placeholder="请选择"
                    onChange={val => onInfoValueChange(Number(val), 'passwordLifeCycle')}
                  >
                    {
                              PasswordValidPeriod.map(d => (
                                <Select.Option key={`setpasswordLifeCycle_${d.value}`} label={d.label} value={Number(d.value)} />
                              ))
                        }
                  </Select>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">统计金额小数点</td>
                <td>
                  <Select
                    value={_info?.sumDecimalPoint}
                    placeholder="请选择"
                    onChange={val => onInfoValueChange(Number(val), 'sumDecimalPoint')}
                  >
                    {
                            DecimalPointOptions.map(d => (
                              <Select.Option key={`setSumDecimalPoint_${d.value}`} label={d.label} value={Number(d.value)} />
                            ))
                      }
                  </Select>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">报表结果小数点</td>
                <td>
                  <Select
                    value={_info?.reportDecimalPoint}
                    placeholder="请选择"
                    onChange={val => onInfoValueChange(Number(val), 'reportDecimalPoint')}
                  >
                    {
                            DecimalPointOptions.map(d => (
                              <Select.Option key={`setReportDecimalPoint_${d.value}`} label={d.label} value={Number(d.value)} />
                            ))
                      }
                  </Select>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">最低注单金额</td>
                <td>
                  <span>
                    <Input
                      className="d-inline"
                      type="number"
                      value={_info?.betMinAmount}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'betMinAmount')}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">会员多盘口</td>
                <td>
                  <div className="d-flex p-0 rdSearchOptionsGroup">
                    {
                                MultiGameStatus.map(d => (
                                  <div key={`rdMultiPlateGroup_${d.value}`}>
                                    <Input
                                      id={`rdMultiPlate_${d.value}`}
                                      type="radio"
                                      name="rdMultiPlate"
                                      value={_info?.multiGameType}
                                      checked={_info?.multiGameType === Number(d.value)}
                                      onChange={() => onInfoValueChange(Number(d.value), 'multiGameType')}
                                    />
                                    <Label
                                      for={`rdMultiPlate_${d.value}`}
                                      className={_info?.multiGameType === Number(d.value) ? 'active mx-2' : 'mx-2'}
                                    >
                                      {d?.label}
                                    </Label>
                                  </div>
                                ))
                              }
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>

      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemManage);