import React, { useEffect, useImperativeHandle, useState } from 'react';

import { Checkbox, DateRangePicker, Input, Radio, Select } from 'element-react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { commonActions, reportActions, thirdPartyGamesActions } from '../../redux/actions';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  lotteryInstallments: state.commonReducers.lotteryInstallments,
  lotteryList: state.commonReducers.lotteryList,
  userInfo: state.memberReducers.userInfo,
  thirdPartyGamesList: state.thirdPartyGamesReducers.thirdPartyGamesList,
});

const mapDispatchToProps = dispatch => ({
  getWinLostReport: (obj, callback) => dispatch(reportActions.getWinLostReport(obj, callback)),
  GetWinLostAgentReport: (obj, callback) => dispatch(thirdPartyGamesActions.GetWinLostAgentReport(obj, callback)),
  GetWinLostMemberReport: (obj, callback) => dispatch(thirdPartyGamesActions.GetWinLostMemberReport(obj, callback)),
  getLotteryInstallments: (lotteryCode, date) => dispatch(commonActions.getLotteryInstallments(lotteryCode, date)),
  getClassificationReport: (obj, callback) => dispatch(reportActions.getClassificationReport(obj, callback)),
  getStatisticReport: (obj, callback) => dispatch(reportActions.getStatisticReport(obj, callback)),
});

const ReportCommonSelection = ({ setSpecialLoginName = () => {}, setgamePage = () => {}, _type, onRef, _isShow = true, _setDate = () => {}, thirdPartyGamesList = [], GetWinLostMemberReport, GetWinLostAgentReport, getStatisticReport, getWinLostReport, getClassificationReport, getLotteryInstallments, lotteryInstallments, lotteryList, userInfo, callback }) => {
  const { t } = useTranslation();
  const [_date, setDate] = useState([moment().startOf('day').toDate(), moment().toDate()]);

  const [_dateFlag, setDateFlag] = useState('1');
  const [_hide, setHide] = useState(false);

  const [memberType, setMemType] = useState(1);
  const [isSettled, setisSettled] = useState(1);
  const [isVisitor, setisVisitor] = useState(0);
  const [memberLoginId, setmemberLoginId] = useState('');
  const [memberMoney, setmemberMoney] = useState('');
  const [winLostAmount, setwinLostAmount] = useState('');
  const [installment, setSelectedInstallments] = useState('');
  const [_installmentsResults, setInstallmentResults] = useState([]);
  const [lotteryType, setlotteryType] = useState(lotteryList.map(item => item.code));
  const [gameCode, setgameCode] = useState(thirdPartyGamesList.map(item => item.gameCode));

  const [parentLoginName, setparentLoginName] = useState(userInfo.loginId || '');

  let dateRef;

  useEffect(() => {
    if (lotteryType.length === 1) {
      getLotteryInstallments(lotteryType[0], moment(_date[0]).unix() || moment().unix());
    } else {
      setInstallmentResults([]);
      setSelectedInstallments('');
    }
  }, [lotteryType]);

  useEffect(() => {
    if (lotteryInstallments) {
      setInstallmentResults(lotteryInstallments);
    }
  }, [lotteryInstallments]);

  const onSearchClick = (level = userInfo.levelValue, userId = userInfo.orgId, isDir = 0, pLoginName, userType, _SelectedlotteryType = '', settingCode = '', isMember = 0) => {
    const obj = {
      level, userId, isDir, installment, winLostAmount, memberMoney, memberType, isSettled, memberLoginId, isVisitor, lotteryType: lotteryType.toString(), beginTime: moment(_date[0]).format('YYYY-MM-DD'), endTime: moment(_date[1]).format('YYYY-MM-DD')
    };
    callback(true);
    if (_type === 'classification') {
      getClassificationReport({
        ...obj, isMember, settingCode, lotteryType: _SelectedlotteryType
      }, data => callback(data));
    } else if (_type === 'inOutSummation') {
      const obj = {
        level, userId, isDir, installment, winLostAmount, memberMoney, isSettled, loginId: memberLoginId, isVisitor, lotteryType: lotteryType.toString(), beginTime: moment(_date[0]).format('YYYY-MM-DD'), endTime: moment(_date[1]).format('YYYY-MM-DD')
      };
      getStatisticReport(obj, data => callback(data));
    } else if (_type === 'thirdParty') {
      const obj = {
        gameCode: gameCode.toString(),
        beginTime: moment(_date[0]).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(_date[1]).format('YYYY-MM-DD HH:mm:ss'),
        parentLoginName: pLoginName || parentLoginName,
        memberName: memberLoginId,
        memberMoney,
        winLostMoney: winLostAmount
      };
      if (pLoginName) {
        setparentLoginName(pLoginName);
      }
      if (userType === 1) {
        GetWinLostMemberReport(obj, data => callback(data));
      } else {
        GetWinLostAgentReport(obj, data => callback(data));
      }
    } else {
      getWinLostReport(obj, data => callback(data));
    }
  };

  useImperativeHandle(onRef, () => ({
    onSearchClick
  }));

  useEffect(() => {
    const obj = {
      level: userInfo.levelValue, userId: userInfo.orgId, isDir: 0, beginTime: moment().format('YYYY-MM-DD'), endTime: moment().format('YYYY-MM-DD')
    };
    if (_type === 'classification') {
      getClassificationReport({
        ...obj, isMember: 2
      }, data => callback(data));
    } else if (_type === 'inOutSummation') {
      const obj = {
        level: userInfo.levelValue, userId: userInfo.orgId, isDir: 0, installment, winLostAmount, memberMoney, isSettled, loginId: memberLoginId, isVisitor, lotteryType: lotteryType.toString(), beginTime: moment(_date[0]).format('YYYY-MM-DD'), endTime: moment(_date[1]).format('YYYY-MM-DD')
      };
      getStatisticReport(obj, data => callback(data));
    } else if (_type === 'thirdParty') {
      const obj = {
        gameCode: gameCode.toString(),
        beginTime: moment(_date[0]).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(_date[1]).format('YYYY-MM-DD HH:mm:ss'),
        parentLoginName: userInfo.loginId,
        memberName: memberLoginId,
        memberMoney,
        winLostMoney: winLostAmount
      };
      GetWinLostAgentReport(obj, data => callback(data));
    } else {
      getWinLostReport(obj, data => callback(data));
    }
  }, []);

  const dateSelector = id => {
    setDateFlag(id);
    switch (id) {
      case '1':
        setDate([moment().toDate(), moment().toDate()]);
        _setDate([moment().toDate(), moment().toDate()]);
        break;
      case '2':
        setDate([moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]);
        _setDate([moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]);
        break;
      case '3':
        setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
        _setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
        break;
      case '4':
        setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
        _setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
        break;
      case '5':
        setDate([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
        _setDate([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
        break;
      case '6':
        setDate([moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()]);
        _setDate([moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()]);
        break;
      default:
        break;
    }
  };

  const chooseLottery = code => {
    setlotteryType([...code]);
  };

  const chooseThirdParty = code => {
    setgameCode([...code]);
  };

  const cancel = () => {
    setDate([moment().startOf('day').toDate(), moment().toDate()]);
    setMemType(1);
    setDateFlag('');
    setmemberLoginId('');
    setisSettled(1);
    setisVisitor(0);
    setmemberMoney('');
    setwinLostAmount('');
    setSelectedInstallments('');
    setInstallmentResults([]);
    setlotteryType([]);
    setgameCode([]);
    const obj = {
      level: userInfo.levelValue, userId: userInfo.orgId, isDir: 0, beginTime: moment().format('YYYY-MM-DD'), endTime: moment().format('YYYY-MM-DD')
    };
    if (_type === 'classification') {
      getClassificationReport({
        ...obj, isMember: 2
      }, data => callback(data));
    } else if (_type === 'inOutSummation') {
      const obj = {
        level: userInfo.levelValue, userId: userInfo.orgId, isDir: 0, installment, winLostAmount, memberMoney, isSettled, loginId: memberLoginId, isVisitor, lotteryType: lotteryType.toString(), beginTime: moment(_date[0]).format('YYYY-MM-DD'), endTime: moment(_date[1]).format('YYYY-MM-DD')
      };
      getStatisticReport(obj, data => callback(data));
    } else if (_type === 'thirdParty') {
      const obj = {
        gameCode: gameCode.toString(),
        beginTime: moment(_date[0]).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(_date[1]).format('YYYY-MM-DD HH:mm:ss'),
        parentLoginName: userInfo.loginId,
        memberName: memberLoginId,
        memberMoney,
        winLostMoney: winLostAmount
      };
      GetWinLostAgentReport(obj, data => callback(data));
    } else {
      getWinLostReport(obj, data => callback(data));
    }
  };

  return (
    <div className={`reportCommonSelectionWrapper ${!_isShow && 'd-none'}`}>
      <div className="reportCommonSelectionTableWrapper d-flex p-3">
        <div className="userTable w-100">
          <div className="d-flex">
            <div>日期范围</div>
            <div>
              <DateRangePicker
                className="betQueryDatepicker"
                value={_date}
                isShowTime
                placeholder={t('START_END_DATE')}
                rangeSeparator={` ${t('TO')} `}
                onChange={_date => {
                  setDate(_date);
                  _setDate(_date);
                }}
                ref={e => dateRef = e}
                shortcuts={[{
                  text: t('TODAY'),
                  onClick: () => {
                    setDate([moment().toDate(), moment().toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('THIS_WEEK'),
                  onClick: () => {
                    setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_2_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }]}
              />
              <div onClick={e => dateSelector(e.target.id)} className="d-flex mx-2">
                {
              [{
                label: '今天', value: 1
              }, {
                label: '昨天', value: 2
              }, {
                label: '本星期', value: 3
              }, {
                label: '上星期', value: 4
              }, {
                label: '本月', value: 5
              }, {
                label: '上月', value: 6
              }].map(item => <span id={item.value} className={`mx-1 operationBtn dateSelectBtn ${+_dateFlag === item.value && 'selected'}`}>{item.label}</span>)

                 }
              </div>
              <div className="flex-grow-1 d-flex justify-content-end pe-4">
                <div className="operationBtn findAndCollapse" onClick={() => setHide(!_hide)}>{!_hide ? '收起' : '展开'}</div>
              </div>

            </div>
          </div>
          {!_hide && (
          <>
            <div className="d-flex">
              <div>彩票种类</div>
              <div className="p-3">
                {_type === 'thirdParty' ? (
                  <>
                    <div className="selectedAndUnselected">
                      <div onClick={() => setgameCode(thirdPartyGamesList.map(item => item.gameCode))}>
                        <i className="el-icon-circle-check" />
                        <span>全部选择</span>
                      </div>
                      <div className="ms-3" onClick={() => setgameCode([])}>全部不选</div>
                    </div>
                    <Checkbox.Group value={gameCode} onChange={code => chooseThirdParty(code)}>
                      {
                    thirdPartyGamesList.map(item => <Checkbox label={item.gameCode}>{item.gameName}</Checkbox>)
                  }
                    </Checkbox.Group>
                  </>
                )
                  : (
                    <>
                      <div className="selectedAndUnselected">
                        <div onClick={() => setlotteryType(lotteryList.map(item => item.code))}>
                          <i className="el-icon-circle-check" />
                          <span>全部选择</span>
                        </div>
                        <div className="ms-3" onClick={() => setlotteryType([])}>全部不选</div>
                      </div>
                      <Checkbox.Group value={lotteryType} onChange={code => chooseLottery(code)}>
                        {
                    lotteryList.map(item => <Checkbox label={item.code}>{item.lotteryName}</Checkbox>)
                  }
                      </Checkbox.Group>
                    </>
                  )}
              </div>
            </div>
            { _type !== 'thirdParty' && (
            <>
              <div className="d-flex">
                <div>期数</div>
                <div>
                  <Select
                    popper-class="select-popper"
                    value={installment}
                    onChange={value => setSelectedInstallments(value)}
                    placeholder="请选择单一彩种"
                  >
                    {
                    _installmentsResults.map(item => (
                      <Select.Option key={`UserBetStatus_${item.installment}`} label={item.desc} value={item.installment} />
                    ))
                }
                  </Select>

                </div>
              </div>
              <div className="d-flex">
                <div>结算状态</div>
                <div className="align-items-center">
                  <Radio value="1" checked={isSettled === 1} onChange={() => setisSettled(1)}>已结算</Radio>
                  <Radio value="0" checked={isSettled === 0} onChange={() => setisSettled(0)}>未结算</Radio>
                </div>
              </div>
              <div className="d-flex">
                <div>会员类型</div>
                <div className="align-items-center">
                  <Radio value="0" checked={isVisitor === 0} onChange={() => setisVisitor(0)}>正式</Radio>
                  <Radio value="1" checked={isVisitor === 1} onChange={() => setisVisitor(1)}>试玩</Radio>
                </div>
              </div>
            </>
            )}
            {(_type !== 'classification' && _type !== 'inOutSummation' && _type !== 'thirdParty')
            && (
            <div className="d-flex">
              <div>用户类型</div>
              <div className="align-items-center">
                <Radio value="1" checked={memberType === 1} onChange={() => setMemType(1)}>会员</Radio>
                <Radio value="2" checked={memberType === 2} onChange={() => setMemType(2)}>代理</Radio>
              </div>
            </div>
            )}

            <div className="d-flex">
              <div>用户名</div>
              <div>
                <Input className="mx-1" value={memberLoginId} onChange={text => setmemberLoginId(text)} />
              </div>
            </div>
            <div className="d-flex">
              <div>会员金额</div>
              <div>
                <Input className="mx-1" value={memberMoney} onChange={text => { if (!Number.isNaN(parseInt(text, 10)) || text === '') { setmemberMoney(text === '' ? '' : parseInt(text, 10)); } }} />
              </div>
            </div>
            <div className="d-flex">
              <div>盈亏金额</div>
              <div>
                <Input className="mx-1" value={winLostAmount} onChange={text => { if (!Number.isNaN(parseInt(text, 10)) || text === '') { setwinLostAmount(text === '' ? '' : parseInt(text, 10)); } }} />
              </div>
            </div>
          </>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div
          className="operationBtn findAndCollapse mx-1"
          onClick={() => {
            setSpecialLoginName();
            setgamePage(true);
            onSearchClick();
          }}
        >
          查询

        </div>
        <div className="operationBtn cancel mx-1" onClick={() => cancel()}>取消</div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportCommonSelection);