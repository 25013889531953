import { StatusCodes } from 'http-status-codes';

import { SET_BET_QUERY_RESULT, SET_BET_ZC_DETAIL } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setBetListInfo = betQueryInfo => ({
  type: SET_BET_QUERY_RESULT,
  value: betQueryInfo
});

const setBetDetail = betDetail => ({
  type: SET_BET_ZC_DETAIL,
  value: betDetail
});


const getBetQueryResult = (obj, callback = () => {}) => dispatch => {
  apiService.get('betList/betQuery', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(setBetListInfo(data.result || {}));
        }
      }
    }).catch(error => {
      callback(false);
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const getBetZCDetail = id => dispatch => {
  apiService.get(`betList/betDetail/${id}`)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setBetDetail(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

export default {
  getBetQueryResult,
  getBetZCDetail,
};