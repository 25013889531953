import React, { useEffect, useState } from 'react';

import { Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, noticeActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Icon from '../../Icon';
import Pagination from '../../Content/Pagination';
import './styles.scss';


const mapStateToProps = state => ({
  noticeSettingData: state.noticeReducers.noticeSettingData,
  userData: state.memberReducers.userData,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
});

const mapDispatchToProps = dispatch => ({
  GetNoticeSetting: (userId, callback) => dispatch(noticeActions.GetNoticeSetting(userId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateLotteryGameStatus: (obj, callback) => dispatch(noticeActions.updateLotteryGameStatus(obj, callback)),
  deleteNotice: (obj, callback) => dispatch(noticeActions.deleteNotice(obj, callback)),
});

const SystemNotice = ({ callback = () => {}, noticeSettingData, GetNoticeSetting, data, updateModalAlert, deleteNotice }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const [_totalRecord, setTotalRecord] = useState(0);
  const [isQueryStatus, setQueryStatus] = useState(true);

  useEffect(() => {
    if (noticeSettingData) {
      setInfo(noticeSettingData?.msgList);
      setTotalRecord(noticeSettingData?.totalRecords);
      setQueryStatus(false);
    }
  }, [noticeSettingData]);

  useEffect(() => {
    setQueryStatus(true);
    GetNoticeSetting(
      _page,
      _rows,
    );
  }, []);

  const onPaginationClick = (pageNum, rows) => {
    setQueryStatus(true);
    // Page number and row number will follow the pagination component instead
    GetNoticeSetting(
      pageNum,
      rows,
    );
    setPage(pageNum);
    setRows(rows);
  };
  const deleteClick = id => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      showCancelButton: true,
      data: {
        message: '确认删除',
        onConfirmationClick: () => deleteNotice(id)
      },
    });
  };

  const tableHeader = [{
    label: t('TIME'),
  }, {
    label: t('NOTICE_TITLE'),
  }, {
    label: t('NOTICE_CONTENT'),
  }, {
    label: t('RECEIVER'),
  }, {
    label: t('SENDER'),
  }, {
    label: t('RECEIVE_METHOD'),
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    data => (
      <div className="d-flex justify-content-center">
        <Label className="d-flex justify-content-center">
          {data?.date && moment.unix(data?.date).format('YYYY-MM-DD HH:mm:ss')}
        </Label>
      </div>
    ),
    data => (<Label className="d-flex justify-content-center">{data?.title}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.content}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.receiver}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.sender}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.type}</Label>),
    data => (
      <div className="d-flex justify-content-center">
        <div className="button handle me-1" onClick={() => callback('publishNotices', data, true)}>{t('EDIT')}</div>
        <div className="button find" onClick={() => deleteClick(data?.id)}>{t('DELETE')}</div>
      </div>
    ),
  ];

  return (
    <div className="SystemAnnoucementWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <div className="p-2 m-0 mb-3 filterWrapper d-flex">
          <div
            className="button add d-flex justify-content-center align-items-center"
            onClick={() => callback('publishNotices', data, false)}
          >
            <Icon
              libName="fa"
              value={{
                name: 'FaPlusCircle',
                className: 'mr-1'
              }}
            />
            <Label className="p-0 px-1">{t('PUBLISH_ANNOUCEMENT')}</Label>
          </div>
        </div>
        <Row className="bigWrapper m-0 my-1 p-3">
          <AgentDataTable
            isQuerying={isQueryStatus}
            tableHeaderTitle={tableHeader}
            tableData={_info || (isQueryStatus ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
            tableBodyRowStyle={['col-1', 'col-1', 'col-5', 'col-1', 'col-1', 'col-1', 'col-1']}
          />
        </Row>
        <Row className=" w-full relative">
          <Pagination totalRecord={_totalRecord || 0} onClick={onPaginationClick} isQuery={false} />
        </Row>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemNotice);