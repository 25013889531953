import React, { useEffect, useState } from 'react';

import { Input, Radio } from 'element-react';
import { connect } from 'react-redux';

import { cashActions, transactionActions, withdrawalActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  memberInfo: state.cashReducers.memberInfo,
});

const mapDispatchToProps = dispatch => ({
  GetCashMemberInfo: () => dispatch(cashActions.GetCashMemberInfo()),
  Save: obj => dispatch(withdrawalActions.Save(obj)),
  SaveUserTransfer: (obj, callback) => dispatch(transactionActions.SaveUserTransfer(obj, callback)),
});

const Transfer = ({ memberInfo, SaveUserTransfer, GetCashMemberInfo }) => {
  const [amount, setamount] = useState(0);
  const [safePass, setSafePass] = useState('');
  const [userType, setuserType] = useState(1);
  const [loginId, setloginId] = useState('');

  useEffect(() => {
    GetCashMemberInfo();
  }, []);

  const onClickSave = () => {
    const _obj = {
      loginId,
      userType,
      amount,
      safePass,
    };
    SaveUserTransfer(_obj, () => {
      setamount();
      setSafePass('');
      setuserType(1);
      setloginId('');
      GetCashMemberInfo();
    });
  };

  const cancel = () => {
    setamount(0);
    setSafePass('');
    setuserType(1);
    setloginId('');
  };

  return (
    <div className="transferWrapper">
      <div className="orderInformationTable">
        <div className="evenRowStyle">
          <div>账户余额</div>
          <div>
            {memberInfo && memberInfo.moneyLimit}
          </div>
        </div>
        <div className="evenRowStyle">
          <div>下线类型</div>
          <div>
            <Radio value={1} checked={userType === 1} onChange={code => setuserType(code)}>会员</Radio>
            <Radio value={2} checked={userType === 2} onChange={code => setuserType(code)}>代理</Radio>
          </div>
        </div>
        <div className="evenRowStyle">
          <div>下线帐号</div>
          <div>
            <Input placeholder="会员登录名" value={loginId} onChange={text => setloginId(text)} />
          </div>
        </div>
        <div className="evenRowStyle">
          <div>金额</div>
          <div>
            <Input
              type="number"
              placeholder="转账金额"
              value={amount}
              onChange={text => {
                setamount(Number(text));
              }}
            />
          </div>
        </div>
        <div className="evenRowStyle">
          <div>提款密码</div>
          <div>
            <Input type="password" placeholder="提款密码" value={safePass} onChange={text => setSafePass(text)} />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center py-3">
        <div className="operationBtn" onClick={() => onClickSave()}>确认</div>
        <div className="operationBtn cancel ms-3" onClick={() => cancel()}>取消</div>
      </div>

    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Transfer);