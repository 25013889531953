import { StatusCodes } from 'http-status-codes';

import { SET_ACTIVE_LOTTERY, SET_ACTIVE_MENU, SET_ALL_COMPANY_LIST, SET_LOTTERY_INSTALLMENTS, SET_LOTTERY_LIST, SET_ORG_USER_COUNT, SET_PARENT_MENU_LIST, SET_SELECTED_SUB_MENU, SET_SELECTED_SUB_SELECTION } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';
// eslint-disable-next-line import/no-cycle
import memberActions from '../memberActions';

const setOrganizationUserCount = data => ({
  type: SET_ORG_USER_COUNT,
  value: data,
});

const setLotteryList = lotteryList => ({
  type: SET_LOTTERY_LIST,
  value: lotteryList,
});

const setCompanyList = list => ({
  type: SET_ALL_COMPANY_LIST,
  value: list,
});

const setParentMenuList = list => ({
  type: SET_PARENT_MENU_LIST,
  value: list,
});

const setActiveMenu = list => ({
  type: SET_ACTIVE_MENU,
  value: list,
});

const setActiveLottery = lottery => ({
  type: SET_ACTIVE_LOTTERY,
  value: lottery
});

const setSelectedSubMenu = val => ({
  type: SET_SELECTED_SUB_MENU,
  value: val
});

const setSelectedSubSelection = val => ({
  type: SET_SELECTED_SUB_SELECTION,
  value: val
});

const setLotteryInstallments = val => ({
  type: SET_LOTTERY_INSTALLMENTS,
  value: val
});

const updateActiveLottery = val => dispatch => {
  dispatch(setActiveLottery(val));
};


const updateSelectedSubMenu = val => dispatch => {
  dispatch(setSelectedSubMenu(val));
};

const updateSelectedSubSelection = val => dispatch => {
  dispatch(setSelectedSubSelection(val));
};

const getAvailableLottery = () => dispatch => {
  apiService.get('/common/GetAvailableLottery').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        if (data.result.length > 0) {
          dispatch(setActiveLottery(data.result[0]));
        }
        return dispatch(setLotteryList(data.result));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const getLotteryInstallments = (lotteryCode, date) => dispatch => {
  apiService.get('/common/GetLotteryInstallments', `lotteryType=${lotteryCode}&date=${date}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(setLotteryInstallments(data.result || {}));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const getCrossAgentLogin = (obj, callback = () => {}) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: true,
    type: 'loading',
  }));
  apiService.post('/common/CrossAgentLogin', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      callback();
      if (data.state === 1) {
        dispatch(getAvailableLottery());
        if (data.result.levelValue !== 0) {
          dispatch(memberActions.getMyInfo());
        }
        return dispatch(memberActions.setUserData(data.result));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const TestWCF = obj => dispatch => {
  apiService.post('/common/TestWCF', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      }
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: data.msg,
          showCancelButton: false
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetCompanyList = (callback = () => {}) => dispatch => {
  apiService.get('/common/GetCompanyList').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        callback();
        return dispatch(setCompanyList(data.result));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const GetParentMenuList = () => dispatch => {
  apiService.get('/common/GetParentMenuList').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(setParentMenuList(data.result));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};
const GetActiveMenu = () => dispatch => {
  apiService.get('/common/GetActiveMenu').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(setActiveMenu(data.result));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const GetOrganizationUserCount = () => dispatch => {
  apiService.get('/common/GetOrganizationUserCount').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(setOrganizationUserCount(data.result));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const GetLanguage = (orgId = '', callback = () => {}) => dispatch => {
  apiService.get('/common/GetLanguage', new URLSearchParams({
    orgId
  })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return callback(data.result.language);
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg,
        showCancelButton: false
      }
    }));
    Promise.reject(error);
  });
};

const SetLanguage = (obj, callback = () => {}) => dispatch => {
  apiService.post('/common/SetLanguage', obj).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        callback();
        return dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      }
    }
  }).catch(error => {
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
    Promise.reject(error);
  });
};

export default {
  getAvailableLottery,
  updateSelectedSubMenu,
  getLotteryInstallments,
  updateActiveLottery,
  updateSelectedSubSelection,
  getCrossAgentLogin,
  TestWCF,
  GetCompanyList,
  GetParentMenuList,
  GetActiveMenu,
  GetOrganizationUserCount,
  SetLanguage,
  GetLanguage,
};