import React, { useEffect, useState } from 'react';

import { Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Loading } from 'element-react';
import { USER_LOGOUT } from '../../redux/actions/actionTypes';
import { store } from '../../redux';

import { agentActions, appActions, commonActions, memberActions } from '../../redux/actions';
import ModalAlert from '../../components/ModalAlert';
import { authWebRoutes } from '../../routes';
import './styles.scss';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  captchaData: state.memberReducers.captchaData,
  mfaSetting: state.memberReducers.mfaSetting,
  activeLottery: state.commonReducers.activeLottery,
  themeColor: state.appReducers.themeColor,
});

const mapDispatchToProps = dispatch => ({
  login: (data, callback) => dispatch(memberActions.login(data, callback)),
  getCaptcha: () => dispatch(memberActions.getCaptcha()),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  setCrossLogin: data => dispatch(agentActions.setCrossLogin(data)),
  getCrossAgentLogin: (data, callback) => dispatch(commonActions.getCrossAgentLogin(data, callback)),
  Register: (obj, callback) => dispatch(memberActions.Register(obj, callback)),
});

const Login = ({ themeColor, Register, getCrossAgentLogin, setCrossLogin, login, userData, activeLottery, history, captchaData, getCaptcha, mfaSetting, updateModalAlert }) => {
  const [_username, setUsername] = useState();
  const [_password, setPassword] = useState();
  const [_mfa, setMfa] = useState('');
  const [_captcha, setCaptcha] = useState();
  const [captchaImage, setCaptchaImage] = useState('');
  const { t } = useTranslation();
  const data = useLocation();
  const [params, setparams] = useState(data?.search === '' ? '' : new URLSearchParams(data.search));

  const [pageType, setPageType] = useState(data?.search === '' ? 'login' : 'loading');

  const [account, setaccount] = useState('');
  const [password, setRegisterpassword] = useState('');
  const [rePassword, setrePassword] = useState('');
  const [code, setcode] = useState('');
  const [affCode, setaffCode] = useState('');

  const onClickLogin = () => {
    const loginData = {
      username: _username,
      password: _password,
      code: _captcha,
      mfacode: _mfa
    };
    login(loginData, data => {
      if (data && data?.checkPassword === true) {
        return updateModalAlert({
          visible: true,
          type: 'changePassword',
        });
      }
    });
  };

  useEffect(() => {
    if (userData && userData?.checkPassword === true) {
      return;
    }
    if (userData && activeLottery && params === '') {
      const item = [].concat(userData.userMenu).find(ele => authWebRoutes.find(x => x.id === ele.id)) || {
        id: ''
      };

      const afterLoginUrl = authWebRoutes.find(x => x.id === item.id)?.path || '';
      updateModalAlert({
        visible: true,
        type: 'latestAnnouncement',
      });
      history.push(`/agent${afterLoginUrl}`);
    }
    if (params !== '') {
      store.dispatch({
        type: USER_LOGOUT
      });
      const uid = params.get('uid');
      const loginId = params.get('loginId');
      const data = params.get('data');
      const obj = {
        uid, loginId, data
      };
      setCrossLogin(obj);
      getCrossAgentLogin(obj, () => setparams(''));
    }
  }, [userData, activeLottery]);

  useEffect(() => {
    if (captchaData && captchaData.base64) {
      setCaptchaImage(`data:image/jpg;base64,${captchaData.base64}`);
    } else {
      getCaptcha();
    }
  }, [captchaData]);

  const onCaptchaClick = () => {
    getCaptcha();
  };

  const onRegister = () => {
    const obj = {
      account,
      password,
      code,
      affCode
    };
    Register(obj, () => { login(account, password); });
  };

  return (
    <div className={`loginRegisterWarpper theme-${themeColor}`}>
      <div className={`loginAndRegister ${themeColor}`}>
        {pageType === 'loading' && <Loading fullscreen text="请稍等，正在跳转中..." />}
        {pageType === 'login'
      && (
      <form className="loginBox" onSubmit={e => { e.preventDefault(); onClickLogin(); }}>
        <div>
          <div className="loginTitle">{t('AGENT_LOGIN')}</div>
          <div className="loginBoxItem">
            <div>{t('ACCOUNT')}</div>
            <Input value={_username} placeholder="请输入您的账号" onChange={({ target: { value: text } }) => setUsername(text)} />
          </div>
          <div className="loginBoxItem">
            <div>{`${t('PASSWORD')}*`}</div>
            <Input type="password" placeholder="请输入您的密码" value={_password} onChange={({ target: { value: text } }) => setPassword(text)} />
          </div>
          <div className="loginBoxItem">
            <div>{`${t('CAPTCHA')}*`}</div>
            <Input placeholder="请输入验证码" value={_captcha} onChange={({ target: { value: text } }) => setCaptcha(text)} />
            <div className="border-0 w-25" onClick={() => onCaptchaClick()}>
              <img id="captchaImg" src={captchaImage} alt="" className="w-100 px-2 py-1" />
            </div>
          </div>
          {mfaSetting && (
          <div className="loginBoxItem">
            <div>二次验证码*</div>
            <Input placeholder="二次验证码" value={_password} onChange={({ target: { value: text } }) => setMfa(text)} />
          </div>
          )}

        </div>
        <div className="loginBoxItem">
          <Button className="loginButton" type="submit">{t('LOGIN')}</Button>
        </div>
        <div className="loginNoAccount">
          {t('NO_ACCOUNT')}
          ？
          <span onClick={() => setPageType('register')}>{t('PLEASE_REGISTER')}</span>
        </div>
      </form>
      )}
        {pageType === 'register'
      && (
      <form className="loginBox" onSubmit={e => { e.preventDefault(); onRegister(); }}>
        <div>
          <div className="loginTitle">{t('AGENT_REGISTER')}</div>
          <div className="loginBoxItem">
            <div>{t('AFF_CODE')}</div>
            <Input value={affCode} placeholder="请输入您的邀请码" onChange={({ target: { value: text } }) => setaffCode(text)} />
          </div>
          <div className="loginBoxItem">
            <div>{t('ACCOUNT')}</div>
            <Input value={account} placeholder="请输入您的账号" onChange={({ target: { value: text } }) => setaccount(text.toLowerCase())} />
          </div>
          <div className="loginBoxItem">
            <div>{`${t('PASSWORD')}*`}</div>
            <Input type="password" placeholder="请输入您的密码" value={password} onChange={({ target: { value: text } }) => setRegisterpassword(text)} />
          </div>
          <div className="loginBoxItem">
            <div>{`${t('CONFIRM_PASSWORD')}*`}</div>
            <Input type="password" placeholder="请输入确认密码" value={rePassword} onChange={({ target: { value: text } }) => setrePassword(text)} />
          </div>
          <div className="loginBoxItem">
            <div>{`${t('CAPTCHA')}*`}</div>
            <Input placeholder="请输入验证码" value={code} onChange={({ target: { value: text } }) => setcode(text)} />
            <div className="border-0 w-25" onClick={() => onCaptchaClick()}>
              <img id="captchaImg" src={captchaImage} alt="" className="w-100 px-2 py-1" />
            </div>
          </div>
        </div>
        <div className="loginBoxItem">
          <Button className="loginButton" type="submit">{t('REGISTER')}</Button>
        </div>
        <div className="loginNoAccount">
          {t('HAVE_ACCOUNT')}
          ？
          <span onClick={() => setPageType('login')}>{t('PLEASE_LOGIN')}</span>
          <div onClick={() => setPageType('login')}>
            {t('BACK_PAGE')}
          </div>
        </div>
      </form>
      )}
        <ModalAlert />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);