import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { commonActions } from '../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  inTimeSheetOdds: state.inTimeSheetReducers.inTimeSheetOdds,
  selectedSubMenu: state.commonReducers.selectedSubMenu,
  activeLottery: state.commonReducers.activeLottery,
});

const mapDispatchToProps = dispatch => ({
  updateSelectedSubMenu: val => dispatch(commonActions.updateSelectedSubMenu(val)),
});

const SubMenuPanel = ({ GameVariable, updateSelectedSubMenu, selectedSubMenu, inTimeSheetOdds, activeLottery }) => {
  const [_MenuOddsListObj, setMenuOddsListObj] = useState();

  useEffect(() => {
    if (inTimeSheetOdds) {
      const obj = {};
      inTimeSheetOdds.subMenuSum.forEach(element => {
        obj[element.menuId] = element;
      });
      setMenuOddsListObj(obj);
    } else {
      setMenuOddsListObj();
    }
  }, [inTimeSheetOdds]);

  return (
    <div className="subMenuPanel ">
      {
        activeLottery && GameVariable[`CJ_${activeLottery.templateCode}`].children.map(item => (
          <div className="menuItem" onClick={() => updateSelectedSubMenu(item.tabId)} key={`subMenuItem_${item.tabId}`}>
            <div className={`menuItemTitle ${item.tabId === selectedSubMenu && 'selected'}`}>{item.label}</div>
            <div className="menuAmount">{_MenuOddsListObj ? _MenuOddsListObj[item.tabId]?.amt : '--'}</div>
          </div>
        ))
      }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubMenuPanel);