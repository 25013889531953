import React, { useEffect, useRef, useState } from 'react';

import { Col, Input, Label } from 'reactstrap';

import { Select } from 'element-react';
import { connect } from 'react-redux';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { useTranslation } from 'react-i18next';

import Icon from '../../Icon';
import IconRefresh from '../../../assets/image/icon_refresh.svg';

import { oddsSettingActions } from '../../../redux/actions';

import Notifications from '../Notifications';
import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  oddsSettingData: state.oddsSettingReducers.oddsSettingData,
});

const mapDispatchToProps = dispatch => ({
  getInfo: (lotteryCode, callback) => dispatch(oddsSettingActions.getInfo(lotteryCode, callback)),
});

const timeoptions = [{
  value: 99,
  label: '99秒'
}, {
  value: 20,
  label: '20秒'
}, {
  value: 15,
  label: '15秒'
}, {
  value: 10,
  label: '10秒'
}];

momentDurationFormatSetup(moment);

const OddsSettingHeader = ({ getInfo, activeLottery, oddsSettingData, data, checkFunction = () => {}, setAvailability = () => {}, setIsQuery = () => {} }) => {
  const { t } = useTranslation();

  const [_timevalue, setTimeValue] = useState(99);
  const [_currentInstallment, setcurrentInstallment] = useState();

  const savedCallback = useRef();
  const countDownRef = useRef();
  const gameInfoRef = useRef();
  const [_countDownTimer, setCountDownTimer] = useState(99);
  useEffect(() => {
    if (_timevalue) {
      setCountDownTimer(_timevalue || 99);
      countDownRef.current = _timevalue || 99;
    }
  }, [_timevalue]);

  useEffect(() => {
    if (data) {
      onRefreshClick();
    }
  }, [data]);

  useEffect(() => {
    if (oddsSettingData) {
      setcurrentInstallment(oddsSettingData?.installment);
      gameInfoRef.current = oddsSettingData?.installment;
    } else {
      setcurrentInstallment();
      gameInfoRef.current = null;
    }
  }, [oddsSettingData]);

  useEffect(() => {
    if (data) {
      if (data?.midCode) {
        getInfo({
          lotteryType: activeLottery?.code,
          midCode: data?.midCode || 'TMA,TMDSDX,TMSB',
          isLianma: data?.isLianma || 0
        });
      } else {
        getInfo({
          lotteryType: activeLottery?.code,
          settingCode: data?.settingCode || '',
          isLianma: data?.isLianma || 0
        });
      }
    }
  }, [activeLottery]);


  const onRefreshClick = () => {
    setIsQuery(true);
    if (data?.midCode) {
      getInfo({
        lotteryType: activeLottery?.code,
        midCode: data?.midCode || 'TMA,TMDSDX,TMSB',
        isLianma: data?.isLianma || 0
      });
    } else {
      getInfo({
        lotteryType: activeLottery?.code,
        settingCode: data?.settingCode || '',
        isLianma: data?.isLianma || 0
      });
    }
  };

  useEffect(() => {
    savedCallback.current = () => {
      if (_currentInstallment && _currentInstallment?.openTimeStamp?.toString() === '0' && _currentInstallment?.state !== 3) {
        onRefreshClick();
        setAvailability(true);
        setCountDownTimer(_timevalue);
      }

      if (_currentInstallment && _currentInstallment?.closeTimeStamp.toString() === '0') {
        setAvailability(false);
      }


      if (_countDownTimer === 0) {
        onRefreshClick();
        setCountDownTimer(_timevalue);
        countDownRef.current = _timevalue;
      } else {
        if (_currentInstallment && (_currentInstallment?.closeTimeStamp - 1).toString() === '0' && _currentInstallment?.openTimeStamp.toString() !== '0') {
          setcurrentInstallment();
        }


        countDownRef.current = _countDownTimer - 1;
        setCountDownTimer(_countDownTimer - 1);
        if (_currentInstallment && _currentInstallment.openTimeStamp.toString() !== '0') {
          gameInfoRef.current = {
            ..._currentInstallment,
            closeTimeStamp: _currentInstallment.closeTimeStamp - 1 < 0 ? 0 : _currentInstallment.closeTimeStamp - 1,
            openTimeStamp: _currentInstallment.openTimeStamp - 1 < 0 ? 0 : _currentInstallment.openTimeStamp - 1,
          };
          setcurrentInstallment({
            ..._currentInstallment,
            closeTimeStamp: _currentInstallment.closeTimeStamp - 1 < 0 ? 0 : _currentInstallment.closeTimeStamp - 1,
            openTimeStamp: _currentInstallment.openTimeStamp - 1 < 0 ? 0 : _currentInstallment.openTimeStamp - 1,
          });
        } else if (oddsSettingData && _currentInstallment?.openTimeStamp.toString() === '0' && _currentInstallment?.closeTimeStamp.toString() === '0') {
          setcurrentInstallment(oddsSettingData?.installment);
          gameInfoRef.current = oddsSettingData?.installment;
        }
      }
    };
  });

  useEffect(() => {
    let id;
    const tick = () => {
      savedCallback.current();
    };
    if (!id) {
      id = setInterval(tick, 1000);
    }
    return () => {
      clearInterval(id);
    };
  }, []);


  return (
    <Col className="oddsSettingHeader">
      <Col className="d-flex align-items-center">
        <div className="bellNoti align-items-center p-0 m-0">
          <Icon
            libName="io"
            value={{
              name: 'IoCalendarOutline',
            }}
          />
        </div>
        <div className="currentInstallment">
          <span className="mx-1">{_currentInstallment && _currentInstallment?.installments}</span>
          {t('INSTALLMENT')}
        </div>
        <div className="timeToClose">
          {
            (_currentInstallment && _currentInstallment.closeTimeStamp >= 0 && _currentInstallment.closeTimeStamp) ? (
              <>
                {t('TIME_TO_CLOSE')}
                {setAvailability(true)}
                :
                <span className="mx-1">
                  {moment.duration((_currentInstallment && _currentInstallment.closeTimeStamp >= 0 && _currentInstallment.closeTimeStamp) || 0, 'second').format('HH:mm:ss', {
                    trim: false
                  })}
                </span>
              </>
            ) : (
              <>
                {t('TIME_TO_OPEN_RESULT')}
                {setAvailability(false)}
                :
                <span className="mx-1">
                  {moment.duration((_currentInstallment && _currentInstallment.openTimeStamp >= 0 && _currentInstallment.openTimeStamp) || 0, 'second').format('HH:mm:ss', {
                    trim: false
                  })}
                </span>
              </>
            )
          }
        </div>
        <div className="todayProfit">
          {`${t('TODAY_PROFIT')} : `}
          {_currentInstallment?.todayWinLost || 0}
        </div>
      </Col>
      <Col className="d-flex p-0 align-items-center justify-content-end">
        <>
          <Input
            id="main_selectAll"
            type="checkbox"
            className="mx-2 p-1 align-items-center hide"
            onClick={() => checkFunction(0)}
          />
          <Label for="main_selectAll" className="refreshButton select mx-1">
            {t('ALL_MORATORIUM')}
          </Label>
          <Input
            id="main_deselectAll"
            type="checkbox"
            className="mx-2 p-1 align-items-center hide"
            onClick={() => checkFunction(1)}
          />
          <Label for="main_deselectAll" className="refreshButton deselect mx-1">
            {t('ALL_OPEN')}
          </Label>
        </>
        <>
          <Select
            className="ms-2"
            value={_timevalue}
            placeholder="请选择"
            onChange={val => setTimeValue(val)}
          >
            {
          timeoptions.map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
        }
          </Select>
          <div className="d-flex align-items-center ms-2">
            <img src={IconRefresh} alt="" className="refreshIcon" onClick={() => {}} />
            <div className="countDownTimer">
              {`${_countDownTimer < 10 ? `0${_countDownTimer}` : _countDownTimer} S`}
            </div>
          </div>
          <div className="refreshButton mx-2" onClick={() => onRefreshClick()}>{t('REFRESH')}</div>
        </>
        <Notifications />
      </Col>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OddsSettingHeader);