import { Amount, BuHuo, DisplayLabel, InTimeSheetTable, OddsControl, Profit } from '../../../../components';

const TEZHENGZX = [
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '特肖',
      tableStyle: 'hk6TeZhengZongXiao',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 12,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'TMSX1',
          displayName: '鼠',
        }, {
          code: 'TMSX2',
          displayName: '牛',
        }, {
          code: 'TMSX3',
          displayName: '虎',
        }, {
          code: 'TMSX4',
          displayName: '兔',
        }, {
          code: 'TMSX5',
          displayName: '龙',
        }, {
          code: 'TMSX6',
          displayName: '蛇',
        }, {
          code: 'TMSX7',
          displayName: '马',
        }, {
          code: 'TMSX8',
          displayName: '羊',
        }, {
          code: 'TMSX9',
          displayName: '猴',
        }, {
          code: 'TMSX10',
          displayName: '鸡',
        }, {
          code: 'TMSX11',
          displayName: '狗',
        }, {
          code: 'TMSX12',
          displayName: '猪',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '正肖',
      tableStyle: 'hk6TeZhengZongXiao',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 12,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'ZENGX13',
          displayName: '鼠',
        }, {
          code: 'ZENGX14',
          displayName: '牛',
        }, {
          code: 'ZENGX15',
          displayName: '虎',
        }, {
          code: 'ZENGX16',
          displayName: '兔',
        }, {
          code: 'ZENGX17',
          displayName: '龙',
        }, {
          code: 'ZENGX18',
          displayName: '蛇',
        }, {
          code: 'ZENGX19',
          displayName: '马',
        }, {
          code: 'ZENGX20',
          displayName: '羊',
        }, {
          code: 'ZENGX21',
          displayName: '猴',
        }, {
          code: 'ZENGX22',
          displayName: '鸡',
        }, {
          code: 'ZENGX23',
          displayName: '狗',
        }, {
          code: 'ZENGX24',
          displayName: '猪',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '总肖',
      tableStyle: 'hk6TeZhengZongXiao',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 12,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'ZX25',
          displayName: '2肖',
        }, {
          code: 'ZX26',
          displayName: '3肖',
        }, {
          code: 'ZX27',
          displayName: '4肖',
        }, {
          code: 'ZX28',
          displayName: '5肖',
        }, {
          code: 'ZX29',
          displayName: '6肖',
        }, {
          code: 'ZX30',
          displayName: '7肖',
        }, {
          code: 'ZXDS31',
          displayName: '总肖单',
        }, {
          code: 'ZXDS32',
          displayName: '总肖双',
        },
      ]
    },
  },
];

export default TEZHENGZX;