import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { Select } from 'element-react';
import { useTranslation } from 'react-i18next';

import { appActions, companyActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  companyInfoData: state.companyReducers.companyInfoData,
});

const mapDispatchToProps = dispatch => ({
  getInfo: () => dispatch(companyActions.getInfo()),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveInfo: (obj, callback) => dispatch(companyActions.saveInfo(obj, callback)),
});

const CompanyInfo = ({ getInfo, companyInfoData, updateModalAlert, saveInfo }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    if (companyInfoData && JSON.stringify(companyInfoData) !== JSON.stringify(_info)) {
      setInfo(companyInfoData);
    }
  }, [companyInfoData]);

  const saveButton = () => {
    if (_info?.isChange) {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const obj = _info;
      saveInfo(obj);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '无变动'
        }
      });
    }
  };


  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    newItems.isChange = true;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    getInfo();
  };

  const getBase64 = file => new Promise(resolve => {
    let baseURL = '';
    // Make new FileReader
    const reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });

  const onPictureChange = e => {
    const file = e.target.files[0];
    getBase64(file)
      .then(result => {
        file.base64 = result;
        onInfoValueChange(result, 'logo');
      });
  };

  const colors = [
    {
      label: '深黄色',
      value: 'darkyellow',
    },
    {
      label: '浅蓝色',
      value: 'blue',
    },
    {
      label: '黄色',
      value: 'yellow',
    }
  ];

  return (
    <div className="CompanyInfoWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="m-0 my-1 p-0">
          <table className="p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="tableHeader col-2">公司资料</td>
                <td>
                  <Input
                    type="text"
                    value={_info?.name}
                    onChange={({ target: { value: text } }) => onInfoValueChange(text, 'name')}
                  />
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">账号</td>
                <td>
                  <div className="d-flex">
                    <Input
                      type="text"
                      value={_info?.accountName}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'accountName')}
                      disabled
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">公司logo 预览</td>
                <td>
                  <img src={_info?.logo} width={100} alt="imgForDisplay" />
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">公司logo (Base64)</td>
                <td>
                  <span className="textAreaInput">
                    <Input
                      className="d-inline col-1"
                      type="textarea"
                      value={_info?.logo}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'logo')}
                      disabled
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2" />
                <td>
                  <div className="displayNone">
                    <Input id="files" type="file" name="file" onChange={e => onPictureChange(e)} />
                  </div>
                  <Label for="files" className="button find">点击上载图片</Label>
                  <br />
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">样式</td>
                <td>
                  <div
                    className="d-flex p-0 smallInput"
                  >
                    <Select
                      value={_info?.style}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(val, 'style')}
                    >
                      {
                           colors.map(d => (
                             <Select.Option key={`setStyle_${d.value}`} label={d.label} value={d.value} />
                           ))
                      }
                    </Select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);