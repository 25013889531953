import { Amount, BuHuo, DisplayLabel, InTimeSheetTable, OddsControl, Profit } from '../../../../components';

const LIANGMIAN = [
  {
    componentType: InTimeSheetTable,
    data: {
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableStyle: 'hk6LiangMian',
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 12,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'TMDSDX50',
          displayName: '特单',
        }, {
          code: 'TMDSDX51',
          displayName: '特双',
        }, {
          code: 'TMDSDX52',
          displayName: '特大',
        }, {
          code: 'TMDSDX53',
          displayName: '特小',
        }, {
          code: 'TMDSDX54',
          displayName: '特合单',
        }, {
          code: 'TMDSDX55',
          displayName: '特合双',
        }, {
          code: 'TMDSDX56',
          displayName: '特合大',
        }, {
          code: 'TMDSDX57',
          displayName: '特合小',
        }, {
          code: 'TMDSDX58',
          displayName: '特尾大',
        }, {
          code: 'TMDSDX59',
          displayName: '特尾小',
        }, {
          code: 'LM63',
          displayName: '特天肖',
        }, {
          code: 'LM64',
          displayName: '特地肖',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableStyle: 'hk6LiangMian',
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 12,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'LM65',
          displayName: '特前肖',
        }, {
          code: 'LM66',
          displayName: '特后肖',
        }, {
          code: 'LM67',
          displayName: '特家肖',
        }, {
          code: 'LM68',
          displayName: '特野肖',
        }, {
          code: 'LM69',
          displayName: '总和单',
        }, {
          code: 'LM70',
          displayName: '总和双',
        }, {
          code: 'LM71',
          displayName: '总和大',
        }, {
          code: 'LM72',
          displayName: '总和小',
        }, {
          code: 'LM73',
          displayName: '特大单',
        }, {
          code: 'LM74',
          displayName: '特大双',
        }, {
          code: 'LM75',
          displayName: '特小单',
        }, {
          code: 'LM76',
          displayName: '特小双',
        },
      ]
    },
  },
];

export default LIANGMIAN;