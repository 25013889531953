import React, { useEffect, useState } from 'react';


import { connect } from 'react-redux';
import oddsSettingActions from '../../redux/actions/oddsSettingActions';

import './styles.scss';

const mapStateToProps = state => ({
  inTimeSheetOdds: state.inTimeSheetReducers.inTimeSheetOdds,
  batchOddsList: state.oddsSettingReducers.batchOddsList,
  selectedSubSelection: state.commonReducers.selectedSubSelection,
});

const mapDispatchToProps = dispatch => ({
  updateBatchOddsList: obj => dispatch(oddsSettingActions.updateBatchOddsList(obj)),
});

const InTimeSheetItem = ({ obj, selectedSubSelection, _OddsListObj, betItemList, betItemStyleList, horizontal, batchOddsList, inTimeSheetOdds, updateBatchOddsList }) => {
  const [_obj, setObj] = useState({});

  useEffect(() => {
    if (selectedSubSelection && selectedSubSelection.codeFunc) {
      const temp = selectedSubSelection.codeFunc(obj);
      setObj(temp);
    } else {
      setObj(obj);
    }
  }, [selectedSubSelection]);


  const onClickSelection = () => {
    if (inTimeSheetOdds && _OddsListObj && _OddsListObj[_obj.code]?.enabled === 1) {
      const _batchOddsList = {
        ...batchOddsList
      };
      if (_batchOddsList[_obj.code]) {
        delete _batchOddsList[_obj.code];
      } else {
        _batchOddsList[_obj.code] = _OddsListObj[_obj.code];
      }
      updateBatchOddsList(Object.keys(_batchOddsList).length === 0 ? undefined : _batchOddsList);
    }
  };

  return (
    <div className={`inTimeSheetItem ${!horizontal && 'flex-column'} ${(batchOddsList && batchOddsList[_obj.code]) && 'selected'} `} onClick={e => { e.stopPropagation(); onClickSelection(); }}>
      {
    betItemList && betItemList.map((Component, index) => <Component key={`bet_item_${index.toString()}`} obj={_obj} _OddsListObj={_OddsListObj} betItemStyle={betItemStyleList[index]} />)
    }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InTimeSheetItem);