import { Amount, BuHuo, DisplayLabel, InTimeSheetTable, OddsControl, Profit } from '../../../../components';

const YIXIAOWS = [
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '一肖',
      tableStyle: 'hk6YiXiaoWS',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 12,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'PTYX1',
          displayName: '鼠',
        }, {
          code: 'PTYX2',
          displayName: '牛',
        }, {
          code: 'PTYX3',
          displayName: '虎',
        }, {
          code: 'PTYX4',
          displayName: '兔',
        }, {
          code: 'PTYX5',
          displayName: '龙',
        }, {
          code: 'PTYX6',
          displayName: '蛇',
        }, {
          code: 'PTYX7',
          displayName: '马',
        }, {
          code: 'PTYX8',
          displayName: '羊',
        }, {
          code: 'PTYX9',
          displayName: '猴',
        }, {
          code: 'PTYX10',
          displayName: '鸡',
        }, {
          code: 'PTYX11',
          displayName: '狗',
        }, {
          code: 'PTYX12',
          displayName: '猪',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '尾数',
      tableStyle: 'hk6YiXiaoWS',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 12,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'PTWS13',
          displayName: '0尾',
        }, {
          code: 'PTWS14',
          displayName: '1尾',
        }, {
          code: 'PTWS15',
          displayName: '2尾',
        }, {
          code: 'PTWS16',
          displayName: '3尾',
        }, {
          code: 'PTWS17',
          displayName: '4尾',
        }, {
          code: 'PTWS18',
          displayName: '5尾',
        }, {
          code: 'PTWS19',
          displayName: '6尾',
        }, {
          code: 'PTWS20',
          displayName: '7尾',
        }, {
          code: 'PTWS21',
          displayName: '8尾',
        }, {
          code: 'PTWS22',
          displayName: '9尾',
        },
      ]
    },
  },
];

export default YIXIAOWS;