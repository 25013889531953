import { Amount, BuHuo, DisplayLabel, InTimeSheetTable, OddsControl, Profit } from '../../../../components';

const D9MSetting = [
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '第七名',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '金额', '盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '第七名总注额',
        code: 'count_B7CH'
      }],
      list: [
        {
          code: 'B7CH7',
          displayName: '1',
          className: 'pk10Square square_1',
        }, {
          code: 'B7CH8',
          displayName: '2',
          className: 'pk10Square square_2',
        }, {
          code: 'B7CH9',
          displayName: '3',
          className: 'pk10Square square_3',
        }, {
          code: 'B7CH10',
          displayName: '4',
          className: 'pk10Square square_4',
        }, {
          code: 'B7CH11',
          displayName: '5',
          className: 'pk10Square square_5',
        }, {
          code: 'B7CH12',
          displayName: '6',
          className: 'pk10Square square_6',
        }, {
          code: 'B7CH13',
          displayName: '7',
          className: 'pk10Square square_7',
        }, {
          code: 'B7CH14',
          displayName: '8',
          className: 'pk10Square square_8',
        }, {
          code: 'B7CH15',
          displayName: '9',
          className: 'pk10Square square_9',
        }, {
          code: 'B7CH16',
          displayName: '10',
          className: 'pk10Square square_10',
        }, {
          code: 'B7LM1',
          displayName: '大',
        }, {
          code: 'B7LM2',
          displayName: '小',
        }, {
          code: 'B7LM3',
          displayName: '单',
        }, {
          code: 'B7LM4',
          displayName: '双',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '第八名',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '金额', '盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '第八名总注额',
        code: 'count_B8CH'
      }],
      list: [
        {
          code: 'B8CH7',
          displayName: '1',
          className: 'pk10Square square_1',
        }, {
          code: 'B8CH8',
          displayName: '2',
          className: 'pk10Square square_2',
        }, {
          code: 'B8CH9',
          displayName: '3',
          className: 'pk10Square square_3',
        }, {
          code: 'B8CH10',
          displayName: '4',
          className: 'pk10Square square_4',
        }, {
          code: 'B8CH11',
          displayName: '5',
          className: 'pk10Square square_5',
        }, {
          code: 'B8CH12',
          displayName: '6',
          className: 'pk10Square square_6',
        }, {
          code: 'B8CH13',
          displayName: '7',
          className: 'pk10Square square_7',
        }, {
          code: 'B8CH14',
          displayName: '8',
          className: 'pk10Square square_8',
        }, {
          code: 'B8CH15',
          displayName: '9',
          className: 'pk10Square square_9',
        }, {
          code: 'B8CH16',
          displayName: '10',
          className: 'pk10Square square_10',
        }, {
          code: 'B8LM1',
          displayName: '大',
        }, {
          code: 'B8LM2',
          displayName: '小',
        }, {
          code: 'B8LM3',
          displayName: '单',
        }, {
          code: 'B8LM4',
          displayName: '双',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '第九名',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '金额', '盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '第九名总注额',
        code: 'count_B9CH'
      }],
      list: [
        {
          code: 'B9CH7',
          displayName: '1',
          className: 'pk10Square square_1',
        }, {
          code: 'B9CH8',
          displayName: '2',
          className: 'pk10Square square_2',
        }, {
          code: 'B9CH9',
          displayName: '3',
          className: 'pk10Square square_3',
        }, {
          code: 'B9CH10',
          displayName: '4',
          className: 'pk10Square square_4',
        }, {
          code: 'B9CH11',
          displayName: '5',
          className: 'pk10Square square_5',
        }, {
          code: 'B9CH12',
          displayName: '6',
          className: 'pk10Square square_6',
        }, {
          code: 'B9CH13',
          displayName: '7',
          className: 'pk10Square square_7',
        }, {
          code: 'B9CH14',
          displayName: '8',
          className: 'pk10Square square_8',
        }, {
          code: 'B9CH15',
          displayName: '9',
          className: 'pk10Square square_9',
        }, {
          code: 'B9CH16',
          displayName: '10',
          className: 'pk10Square square_10',
        }, {
          code: 'B9LM1',
          displayName: '大',
        }, {
          code: 'B9LM2',
          displayName: '小',
        }, {
          code: 'B9LM3',
          displayName: '单',
        }, {
          code: 'B9LM4',
          displayName: '双',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '第十名',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '金额', '盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '第十名总注额',
        code: 'count_B10CH'
      }],
      list: [
        {
          code: 'B10CH7',
          displayName: '1',
          className: 'pk10Square square_1',
        }, {
          code: 'B10CH8',
          displayName: '2',
          className: 'pk10Square square_2',
        }, {
          code: 'B10CH9',
          displayName: '3',
          className: 'pk10Square square_3',
        }, {
          code: 'B10CH10',
          displayName: '4',
          className: 'pk10Square square_4',
        }, {
          code: 'B10CH11',
          displayName: '5',
          className: 'pk10Square square_5',
        }, {
          code: 'B10CH12',
          displayName: '6',
          className: 'pk10Square square_6',
        }, {
          code: 'B10CH13',
          displayName: '7',
          className: 'pk10Square square_7',
        }, {
          code: 'B10CH14',
          displayName: '8',
          className: 'pk10Square square_8',
        }, {
          code: 'B10CH15',
          displayName: '9',
          className: 'pk10Square square_9',
        }, {
          code: 'B10CH16',
          displayName: '10',
          className: 'pk10Square square_10',
        }, {
          code: 'B10LM1',
          displayName: '大',
        }, {
          code: 'B10LM2',
          displayName: '小',
        }, {
          code: 'B10LM3',
          displayName: '单',
        }, {
          code: 'B10LM4',
          displayName: '双',
        },
      ]
    },
  },
];

export default D9MSetting;