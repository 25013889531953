import { StatusCodes } from 'http-status-codes';

import { SET_BELL, SET_EDIT_NOTICE_INFO, SET_NEWS_LIST, SET_NOTICES_SETTING, SET_NOTIFICATION, SET_WARNING_MSG, SET_WARNING_MSG_HISTORY } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setNewsList = newsList => ({
  type: SET_NEWS_LIST,
  value: newsList,
});

const setBell = data => ({
  type: SET_BELL,
  value: data,
});

const setWarningMsg = newsList => ({
  type: SET_WARNING_MSG,
  value: newsList,
});

const setWarningMsgHistory = newsList => ({
  type: SET_WARNING_MSG_HISTORY,
  value: newsList,
});

const setNoticeSetting = data => ({
  type: SET_NOTICES_SETTING,
  value: data,
});

const setEditNotices = data => ({
  type: SET_EDIT_NOTICE_INFO,
  value: data,
});

const setNotification = data => ({
  type: SET_NOTIFICATION,
  value: data,
});


const getAnnouncement = (page = 1, rows = 15, callback = () => {}) => dispatch => {
  apiService.get('/Notices/GetInfo', `page=${page}&rows=${rows}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setNewsList(data.result || []));
    }
  }).catch(error => {
    callback();
    Promise.reject(error);
  });
};

const GetWarningMsgHistory = (page = 0, rows = 15, callback = () => {}) => dispatch => {
  apiService.get('/Notices/GetWarningMsgHistory', `page=${page}&rows=${rows}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setWarningMsgHistory(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const GetWarningMsg = (page = 0, rows = 15, callback = () => {}) => dispatch => {
  apiService.get('/Notices/GetWarningMsg', `page=${page}&rows=${rows}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setWarningMsg(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const HandledWarningMsg = (id, page, row) => dispatch => {
  apiService.post('/Notices/HandledWarningMsg', id).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (data.state === 1) {
        return dispatch(GetWarningMsg(page, row));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    Promise.reject(error);
  });
};

const GetNoticeSetting = (page = 0, rows = 15, callback = () => {}) => dispatch => {
  apiService.get('/Notices/GetNoticeSetting', `page=${page}&rows=${rows}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setNoticeSetting(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const GetEditNoticesInfo = (id = '', callback = () => {}) => dispatch => {
  apiService.get('/Notices/EditInfo', `id=${id}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setEditNotices(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};


const saveNotice = (obj = {}) => dispatch => {
  apiService.post('Notices/SaveNotice', obj).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      if (obj?.id) {
        return dispatch(GetEditNoticesInfo(obj?.id));
      }
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error.response.data.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const deleteNotice = (id = '') => dispatch => {
  apiService.post('Notices/DeleteNotice', id).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
      return dispatch(GetNoticeSetting(1, 15));
    }
    if (status === StatusCodes.BAD_REQUEST) {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error.response.data.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const GetNotification = () => dispatch => {
  apiService.get('/Notices/GetNotification').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(setNotification(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

export default {
  getAnnouncement,
  GetWarningMsgHistory,
  HandledWarningMsg,
  GetWarningMsg,
  GetNoticeSetting,
  GetEditNoticesInfo,
  saveNotice,
  deleteNotice,
  GetNotification,
  setBell,
};