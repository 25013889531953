import React, { useEffect, useState } from 'react';

import { Input, InputNumber, Radio } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Icon, LotteryMenu } from '../../components';
import { appActions, autoRestockSettingActions } from '../../redux/actions';
import { AutoRestockStatus } from '../../constants/SelectionItems';

import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  autoRestockList: state.autoRestockSettingReducers.autoRestockList,
  lotteryList: state.commonReducers.lotteryList,
});

const mapDispatchToProps = dispatch => ({
  getBuhuoListResult: lotteryCode => dispatch(autoRestockSettingActions.getBuhuoListResult(lotteryCode)),
  getDefaultBuhuoList: lotteryCode => dispatch(autoRestockSettingActions.getDefaultBuhuoList(lotteryCode)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveBuhuoList: (lotteryCode, orgId, buhuoList) => dispatch(autoRestockSettingActions.saveBuhuoList(lotteryCode, orgId, buhuoList)),
  saveDefaultBuhuo: (lotteryCode, orgId, buhuoList) => dispatch(autoRestockSettingActions.saveDefaultBuhuo(lotteryCode, orgId, buhuoList)),
});

const AutoRestockSetting = ({
  lotteryList,
  getBuhuoListResult,
  getDefaultBuhuoList,
  autoRestockList,
  saveBuhuoList,
  saveDefaultBuhuo,
  updateModalAlert,
  activeLottery,
  isAdmin
}) => {
  const { t } = useTranslation();
  const [_selectedLottery, setSelectedLottery] = useState(activeLottery?.code || 'HK6');
  const [_selecteLotteryTxt, setSelectedLotteryTxt] = useState();
  const [_firstListItems, setFirstListItems] = useState();
  const [_secondListItems, setSecondListItems] = useState();
  const [_displayMenu, setDisplayMenu] = useState(false);
  const [_selectedOption, setSelectedOption] = useState([]);
  const orgId = autoRestockList?.orgId;
  const [quickStatus, setquickStatus] = useState('');
  let quickText = '';

  useEffect(() => {
    if (lotteryList) {
      isAdmin ? getDefaultBuhuoList(_selectedLottery) : getBuhuoListResult(_selectedLottery);
      setSelectedLotteryTxt(lotteryList.find(item => item.code === _selectedLottery)?.lotteryName);
    }
  }, [lotteryList]);

  useEffect(() => {
    if (autoRestockList && JSON.stringify(_firstListItems) !== JSON.stringify(autoRestockList)) {
      setFirstListItems(autoRestockList?.buhuoList?.slice(0, Math.floor(autoRestockList?.buhuoList?.length / 2)));
      setSecondListItems(autoRestockList?.buhuoList?.slice(Math.floor(autoRestockList?.buhuoList?.length / 2, autoRestockList?.buhuoList?.length)));
      setSelectedOption([...autoRestockList?.buhuoList]);
    }
  }, [autoRestockList]);

  useEffect(() => {
    if (activeLottery) {
      isAdmin ? getDefaultBuhuoList(activeLottery?.code) : getBuhuoListResult(activeLottery?.code);
      setSelectedLotteryTxt(activeLottery?.lotteryName);
      setSelectedLottery(activeLottery?.code);
    }
  }, [activeLottery]);

  const onValueChange = event => {
    const index = Number(_selectedOption.findIndex(item => item.code === event.target.name));
    const newItems = [..._selectedOption];
    newItems[index].autoSetting = Number(event.target.value);
    newItems[index].isChange = true;
    setSelectedOption(newItems);
  };

  const changeMoneyLimit = event => {
    const index = Number(_selectedOption.findIndex(item => item.code === event.target.name));
    const newItems = [..._selectedOption];
    newItems[index].moneyLimit = Number(event.target.value);
    newItems[index].isChange = true;
    setSelectedOption(newItems);
  };

  const onSaveClick = () => {
    const res = _selectedOption.filter(item => item?.isChange === true);
    if (res.length > 0) {
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      isAdmin ? saveDefaultBuhuo(_selectedLottery || 'HK6', orgId, res, onResetClick) : saveBuhuoList(_selectedLottery || 'HK6', orgId, res, onResetClick);
    } else {
      updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          message: '没有任何改动',
          showCancelButton: false
        }
      });
    }
  };

  const onResetClick = () => {
    isAdmin ? getDefaultBuhuoList(_selectedLottery) : getBuhuoListResult(_selectedLottery);
  };

  const updateInput = () => {
    if (quickStatus !== '' || quickText !== '') {
      const toupdate = _selectedOption.map(o => ({
        ...o,
        isChange: true,
        moneyLimit: quickText ?? o.moneyLimit,
        autoSetting: quickStatus ?? o.autoSetting,
      }));
      setSelectedOption(toupdate);
    }
  };

  const onLotterySelect = retvalue => {
    setSelectedLottery(retvalue?.code);
    setSelectedLotteryTxt(retvalue?.lotteryName);
    isAdmin ? getDefaultBuhuoList(retvalue?.code) : getBuhuoListResult(retvalue?.code);
    setDisplayMenu(false);
  };


  return (
    <div className="autoBuhuoSettingWarpper">
      <div className="content">
        <CommonHeader headerName={t('AUTO_RESTOCK_SETTING')} />
        <div className="subMenu p-1 px-2 mb-2 flex">
          <div
            onMouseEnter={() => setDisplayMenu(!_displayMenu)}
            onClick={() => setDisplayMenu(!_displayMenu)}
            className={`menuWrapper d-flex ${_displayMenu ? 'menuactive' : ''}`}
          >
            <div className="menuSelectedText">{_selecteLotteryTxt}</div>
            <div className="float-right">
              <Icon
                libName="fa"
                value={{
                  name: 'FaCaretDown',
                  className: 'float-right'
                }}
              />
            </div>
          </div>

          <div onMouseLeave={() => setDisplayMenu(false)} className={`subselection ${_displayMenu ? '' : 'hide'}`}>
            <LotteryMenu
              lotteryList={lotteryList}
              selectedItem={_selectedLottery}
              onClickItem={onLotterySelect}
            />
          </div>
        </div>
        <div className="mb-2 d-flex">
          <div className="w-50 me-2">
            <AgentDataTable
              tableHeaderTitle={
                [{
                  label: t('TYPE'),
                }, {
                  label: t('BET_AMOUNT'),
                }, {
                  label: t('STATUS'),
                }]
              }
              tableData={_firstListItems || []}
              tableBodyRowProperty={
                [
                  data => (<div className="d-flex justify-content-center">{data.projectName}</div>),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        type="number"
                        id={data.id}
                        name={data.code}
                        value={_selectedOption[Number(_selectedOption.findIndex(item => item.code === data.code))]?.moneyLimit}
                        onChange={changeMoneyLimit}
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      {
                        AutoRestockStatus && AutoRestockStatus.map(item => (
                          <div
                            className="px-3 radiobuttongroup"
                            key={`radiobuttongroup_${data.code}_${item.label}_${item.value}`}
                          >
                            <Radio
                              id={`${data.id}_${item.value}`}
                              name={data.code}
                              value={item.value}
                              checked={_selectedOption[Number(_selectedOption.findIndex(item => item.code === data.code))]?.autoSetting === Number(item.value)}
                              onChange={onValueChange}
                              className="mx-2"
                            >
                              {item.label}
                            </Radio>
                          </div>
                        ))
                      }
                    </div>
                  ),
                ]
              }
              tableBodyRowStyle={
                ['backgroundColored']
              }
              tableHeaderStyle={
                ['col-2']
              }
            />
          </div>
          <div className="w-50">
            <AgentDataTable
              tableHeaderTitle={
                [{
                  label: t('TYPE'),
                }, {
                  label: t('BET_AMOUNT'),
                }, {
                  label: t('STATUS'),
                }]
              }
              tableData={_secondListItems || []}
              tableBodyRowProperty={
                [
                  data => (<div className="d-flex justify-content-center">{data.projectName}</div>),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        type="number"
                        id={data.id}
                        name={data.code}
                        value={_selectedOption[Number(_selectedOption.findIndex(item => item.code === data.code))]?.moneyLimit}
                        onChange={changeMoneyLimit}
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      {
                        AutoRestockStatus && AutoRestockStatus.map(item => (
                          <div
                            className="px-3 radiobuttongroup"
                            key={`radiobuttongroup_${data.code}_${item.label}_${item.value}`}
                          >
                            <Radio
                              id={`${data.id}_${item.value}`}
                              name={data.code}
                              value={item.value}
                              checked={_selectedOption[Number(_selectedOption.findIndex(item => item.code === data.code))]?.autoSetting === Number(item.value)}
                              onChange={onValueChange}
                              className="mx-2"
                            >
                              {item.label}
                            </Radio>
                          </div>
                        ))
                      }
                    </div>
                  ),
                ]
              }
              tableBodyRowStyle={
                ['backgroundColored']
              }
              tableHeaderStyle={
                ['col-2']
              }
            />
          </div>
        </div>
        <div className="d-flex justify-content-center py-3">
          <div className="saveButton mx-2" onClick={onSaveClick}>{t('SAVE')}</div>
          <div className="cancelButton mx-2" onClick={onResetClick}>{t('CANCEL')}</div>
        </div>
      </div>
      <div className="flex align-items-center justify-content-start footer p-2 m-0">
        <div className="d-flex align-items-center justify-content-start">
          <div className="flex align-items-center justify-content-center mx-1">快速设置：</div>
          <InputNumber id="QuickSetNumber" className="mx-1" onChange={text => quickText = text} />
          <Radio.Group onChange={({ target: { value } }) => setquickStatus(value)} value={quickStatus}>
            {AutoRestockStatus && AutoRestockStatus.map(item => (
              <Radio
                id={`QuickSet_${item.value}`}
                name="QuickSet"
                value={item.value}
                className="mx-2"
              >
                {
                  item.label
                }
              </Radio>
            ))}
          </Radio.Group>
          <div className="saveButton mx-2" onClick={updateInput}>{t('CONFIRM')}</div>
          <div className="saveButton mx-1" onClick={onSaveClick}>{t('SAVE')}</div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoRestockSetting);