import React from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CommonHeader, Icon } from '../..';
import AgentEdit from '../AgentEdit';
import AuditSetting from '../AuditSetting';
import BankCard from '../BankCard';
import BetQueryDetail from '../../../views/BetQueryDetail';
import ChangeHisLog from '../ChangeHisLog';
import HandlerLog from '../HandlerLog';
import LoginLog from '../LoginLog';
import LotteryRebate from '../LotteryRebate';
import MemberEdit from '../MemberEdit';
import OddsDiff from '../OddsDiff';
import SubAccountAddEdit from '../SubAccountAddEdit';
import ThirdPartyRebate from '../ThirdPartyRebate';
import TransferFlowsDetail from '../TransferFlowsDetail';

import { thirdPartyGamesActions } from '../../../redux/actions';

import 'element-theme-default';
import './styles.scss';


const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  thirdPartyGamesList: state.thirdPartyGamesReducers.thirdPartyGamesList,
  thirdPartyGamesEdit: state.thirdPartyGamesReducers.thirdPartyGamesEdit,
  selectedSubMenu: state.commonReducers.selectedSubMenu,
});

const mapDispatchToProps = dispatch => ({
  SaveThirdPartyGame: (obj, callback) => dispatch(thirdPartyGamesActions.SaveThirdPartyGame(obj, callback)),
});

const UserManagementMenu = ({ data, selectedSubMenu, callback = () => {}, tabIndex, setTabIndex, fromSub }) => {
  const { t } = useTranslation();
  const menu = (data?.isChild === 1 && fromSub) ? [t('EDIT'), t('LOGIN_LOG'), t('AMENDED_LOG'), t('HANDLED_BY_LOG'), t('AUDIT_SETTING')]
    : (data?.orgId ? (data?.level === 2 ? [t('EDIT'), t('LOTTERY_REBATE'), t('GAMES_REBATE'), t('ODDS_DIFF'), t('LOGIN_LOG'), t('AMENDED_LOG'), t('BET_LIST'), t('CHECK_TRANSFER_FLOW'), t('BANK_CARD')]
      : [t('EDIT'), t('LOTTERY_REBATE'), t('GAMES_REBATE'), t('LOGIN_LOG'), t('AMENDED_LOG'), t('BET_LIST'), t('CHECK_TRANSFER_FLOW'), t('BANK_CARD')])
      : [t('EDIT'), t('LOTTERY_REBATE'), t('LOGIN_LOG'), t('EDIT_LOG'), t('BET_LIST'), t('CHECK_TRANSFER_FLOW'), t('BANK_CARD'), t('DIRECT_WX')]);

  const renderTabItem = () => {
    if (data?.orgId && (data?.isChild !== 1 || !fromSub)) {
      if (data?.level === 2) {
        switch (tabIndex) {
          case 0: return <AgentEdit data={data} callback={callback} />;
          case 1: return <LotteryRebate data={data} />;
          case 2: return <ThirdPartyRebate data={data} />;
          case 3: return <OddsDiff data={data} />;
          case 4: return <LoginLog data={data} />;
          case 5: return <ChangeHisLog data={data} />;
          case 6: return <BetQueryDetail data={data} />;
          case 7: return <TransferFlowsDetail data={data} />;
          case 8: return <BankCard data={data} />;
          default:
            break;
        }
      } else {
        switch (tabIndex) {
          case 0: return <AgentEdit data={data} callback={callback} />;
          case 1: return <LotteryRebate data={data} />;
          case 2: return <ThirdPartyRebate data={data} />;
          case 3: return <LoginLog data={data} />;
          case 4: return <ChangeHisLog data={data} />;
          case 5: return <BetQueryDetail data={data} />;
          case 6: return <TransferFlowsDetail data={data} />;
          case 7: return <BankCard data={data} />;
          default:
            break;
        }
      }
    } else if (data?.memberId) {
      switch (tabIndex) {
        case 0: return <MemberEdit callback={callback} key={data?.memberId} data={data} />;
        case 1: return <LotteryRebate data={data} />;
        case 2: return <LoginLog data={data} />;
        case 3: return <HandlerLog data={data} />;
        case 4: return <BetQueryDetail data={data} />;
        case 5: return <TransferFlowsDetail data={data} />;
        case 6: return <BankCard data={data} />;
        default:
          break;
      }
    } else {
      switch (tabIndex) {
        case 0: return <SubAccountAddEdit key={data?.orgId} data={data} isEdit />;
        case 1: return <LoginLog data={data} />;
        case 2: return <ChangeHisLog data={data} />;
        case 3: return <HandlerLog data={data} />;
        case 4: return <AuditSetting data={data} />;
        default:
          break;
      }
    }
  };

  return (
    <div className="userManagementMenuWrapper p-3">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => callback()}>
              <Icon
                libName="md"
                value={{
                  name: 'MdArrowBack',
                  className: 'h5 mb-0 text-white'
                }}
              />
              返回
            </div>
            {data && selectedSubMenu && (`${t(selectedSubMenu.slice(3))} / ${data.loginId}`)}
          </div>
        )}
      />
      <div className="userManagementMenu">
        {menu.map((item, index) => <div key={`key_${item}`} className={`menu_item ${tabIndex === index && 'selected'}`} onClick={() => setTabIndex(index)}>{item}</div>)}
      </div>
      {renderTabItem()}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementMenu);