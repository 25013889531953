import React from 'react';

import { connect } from 'react-redux';

import Label from '../Label';

import './styles.scss';

const mapStateToProps = state => ({
  inTimeSheetOdds: state.inTimeSheetReducers.inTimeSheetOdds,
});

const DataTableItem = ({ obj, betItemList, betItemStyle }) => {
  const renderBetItem = (item, obj) => {
    switch (item) {
      case 'DisplayText':
        return <Label value={obj.displayName} />;
      case 'OddsControl':
        return (
          <>
            <div className="">+</div>
            <div className="">-</div>
          </>
        );
      case 'Amount':
        return <Label value={obj.displayName} />;
      case 'Profit':
        return <Label value={obj.displayName} />;
      case 'BuHuo':
        return (
          <span className="buhuoWrapper">
            <Label value={obj.displayName} />
            <Label value={obj.displayName} />
          </span>
        );
      default:
        return '';
    }
  };

  return (
    betItemList && betItemList.map((item, index) => (
      <div className={`dataTableItem ${betItemStyle ? betItemStyle[index] : ''}`}>
        {renderBetItem(item, obj)}
      </div>
    ))
  );
};

export default connect(mapStateToProps)(DataTableItem);