import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../..';
import { appActions, transactionActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  transactionManage: state.transactionReducers.transactionManage,
  userInfo: state.memberReducers.userInfo,
});

const mapDispatchToProps = dispatch => ({
  GetTransactionList: (obj, callback) => dispatch(transactionActions.GetTransactionList(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const TransactionHistory = ({ transactionManage, GetTransactionList, userInfo, updateModalAlert }) => {
  const { t } = useTranslation();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const _date = [moment().subtract(1, 'weeks').startOf('day').toDate(), moment().endOf('day').toDate()];

  const userName = userInfo?.loginId;
  const dateType = 2;
  const orderType = 2;

  useEffect(() => {
    setIsQueryingDetail(true);
    const _obj = {
      userName,
      dateType,
      page,
      rows,
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix()
    };
    GetTransactionList(_obj, setIsQueryingDetail);
  }, []);


  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      userName,
      dateType,
      orderType,
      page: pageNum,
      rows,
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix()
    };
    GetTransactionList(_obj, setIsQueryingDetail);
  };

  return (
    <div className="TransactionHistoryWrapper">
      <div className="mx-0 bg-white px-0 border-1 mt-3 d-flex">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: '订单号',
            }, {
              label: '交易时间',
            }, {
              label: '用户帐户【类型】',
            }, {
              label: '交易类型',
            }, {
              label: '交易描述',
            }, {
              label: '交易金额',
            }, {
              label: '手续费',
            }, {
              label: '交易状态',
            }]
          }
          tableData={transactionManage ? transactionManage.list : []}
          tableHeaderStyle={['headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle']}
          tableBodyRowProperty={
            [
              data => (<div className="d-flex justify-content-center">{data.no}</div>),
              data => (
                <div
                  className="d-flex justify-content-center text-primary detailLink"
                  onClick={() => {
                    updateModalAlert({
                      type: 'transactionHistory',
                      visible: true,
                      data: {
                        result: data
                      }
                    });
                  }}
                >
                  {data.orderID}
                </div>
              ),
              data => (<div className="d-flex justify-content-center">{data.createTime}</div>),
              data => (<div className="d-flex justify-content-center">{`${data.mName}[${data.mType === 2 ? t('AGENT') : t('MEMBER')}]`}</div>),
              data => (<div className="d-flex justify-content-center">{data.actionTypeName}</div>),
              data => (<div className="d-flex justify-content-center">{`${data.createRemark}`}</div>),
              data => (<div className="d-flex justify-content-center">{data.actualAmount}</div>),
              data => (<div className="d-flex justify-content-center">{data.actionFee}</div>),
              data => (<div className={data.actionStatus === '300' ? 'successValue' : 'negativeValue'}>{data.actionStatusName}</div>),
            ]
          }
          tableFooter={[[{
            value: `本页:${transactionManage ? (transactionManage.pageSum.pageTotal || 0) : 0}笔`,
            colspan: 6,
            className: 'text-end total tableSum'
          },
          {
            value: (transactionManage ? (transactionManage.pageSum.pageActualAmount || 0) : 0),
            colspan: 1,
            className: 'total tableSum'
          },
          {
            value: (transactionManage ? (transactionManage.pageSum.totalActionFee || 0) : 0),
            colspan: 1,
            className: 'total tableSum'
          },
          {
            value: '',
            colspan: 1,
            className: 'total tableSum'
          }], [{
            value: `全部:${transactionManage ? (transactionManage.sum.totalRecord || 0) : 0}笔`,
            colspan: 6,
            className: 'text-end total tableSum'
          },
          {
            value: (transactionManage ? (transactionManage.sum.totalActualAmount || 0) : 0),
            colspan: 1,
            className: 'total tableSum'
          },
          {
            value: (transactionManage ? (transactionManage.sum.totalActionFee || 0) : 0),
            colspan: 1,
            className: 'total tableSum'
          },
          {
            value: '',
            colspan: 1,
            className: 'total tableSum'
          }]]}
        />
      </div>
      <div className="d-flex justify-content-center w-full relative">
        <Pagination totalRecord={transactionManage ? (transactionManage.sum.totalRecord || 0) : 0} onClick={onPaginationClick} isQuery={false} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory);