import React, { useEffect, useState } from 'react';

import { DateRangePicker, Input } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../..';
import { appActions, reportActions } from '../../../redux/actions';
import BetQueryDetail from '../../../views/BetQueryDetail';
import CommonHeader from '../../Content/CommonHeader';
import Icon from '../../Icon';
import './styles.scss';

const mapStateToProps = state => ({
  memberSummationReport: state.reportReducers.memberSummationReport,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getMemberSummationReport: (obj, callback) => dispatch(reportActions.getMemberSummationReport(obj, callback)),
});

const MemberSummationReport = ({ getMemberSummationReport, memberSummationReport, updateModalAlert }) => {
  const { t } = useTranslation();
  const [_date, setDate] = useState([moment().toDate(), moment().toDate()]);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [effectAmount, setEffectAmount] = useState('');
  const [loginName, setLoginName] = useState('');
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [_flag, setFlag] = useState(false);
  const [lotteryCode, setLotteryCode] = useState();
  const [lotteryName, setLotteryName] = useState();
  const [_specialLoginName, setSpecialLoginName] = useState('');

  let dateRef;

  useEffect(() => {
    const obj = {
      effectAmount,
      loginName,
      beginDate: moment(_date[0]).startOf('D').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(_date[1]).endOf('D').format('YYYY-MM-DD HH:mm:ss'),
      page,
      rows
    };
    getMemberSummationReport(obj, () => setIsQueryingDetail(false));
  }, []);

  const onSearchClick = () => {
    setIsQueryingDetail(true);
    const obj = {
      effectAmount,
      loginName,
      beginDate: moment(_date[0]).startOf('D').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(_date[1]).endOf('D').format('YYYY-MM-DD HH:mm:ss'),
      page: 1,
      rows: 15
    };
    getMemberSummationReport(obj, () => setIsQueryingDetail(false));
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    const obj = {
      effectAmount,
      loginName,
      beginDate: moment(_date[0]).startOf('D').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(_date[1]).endOf('D').format('YYYY-MM-DD HH:mm:ss'),
      page: pageNum,
      rows
    };
    getMemberSummationReport(obj, () => setIsQueryingDetail(false));
    setPage(pageNum);
    setRows(rows);
  };


  return (
    <>
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_flag && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setFlag(false)}>
              <Icon
                libName="md"
                value={{
                  name: 'MdArrowBack',
                  className: 'h5 mb-0 text-white'
                }}
              />
              返回
            </div>
            )}
            会员统计报表
          </div>
)}
      />
      {
        _flag === false
          ? (
            <div className="memberSummationReportWrapper">
              <div className="memberSummationReportTableWrapper">
                <div className="userTable w-100">
                  <div>
                    有效投注大于等于
                  </div>
                  <div className="mx-2">
                    <Input value={effectAmount} onChange={text => { if (!Number.isNaN(parseInt(text, 10)) || text === '') { setEffectAmount(text === '' ? '' : parseInt(text, 10)); } }} />
                  </div>
                  <div>
                    账号
                  </div>
                  <div className="mx-2">
                    <Input value={loginName} onChange={text => setLoginName(text)} />
                  </div>
                  <div>日期范围</div>
                  <div>
                    <DateRangePicker
                      className="betQueryDatepicker"
                      value={_date}
                      isShowTime
                      placeholder={t('START_END_DATE')}
                      rangeSeparator={` ${t('TO')} `}
                      onChange={_date => {
                        setDate(_date);
                      }}
                      ref={e => dateRef = e}
                      shortcuts={[{
                        text: t('TODAY'),
                        onClick: () => {
                          setDate([moment().toDate(), moment().toDate()]);
                          dateRef.togglePickerVisible();
                        }
                      }, {
                        text: t('THIS_WEEK'),
                        onClick: () => {
                          setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
                          dateRef.togglePickerVisible();
                        }
                      }, {
                        text: t('PREVIOUS_WEEK'),
                        onClick: () => {
                          setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                          dateRef.togglePickerVisible();
                        }
                      }, {
                        text: t('PREVIOUS_2_WEEK'),
                        onClick: () => {
                          setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                          dateRef.togglePickerVisible();
                        }
                      }]}
                    />
                  </div>
                  <div className="operationBtn serchAndDetail mx-1" onClick={onSearchClick}>查询</div>

                </div>
              </div>
              <div className="memberSummationReportTableWrapper mt-3">
                <div className="mx-0 bg-white">
                  <AgentDataTable
                    isQuerying={_isQueryingDetail}
                    tableHeaderTitle={
                [{
                  label: '序号',
                }, {
                  label: '会员账号',
                }, {
                  label: '姓名',
                }, {
                  label: '所属代理',
                }, {
                  label: '注单量',
                }, {
                  label: '投注金额',
                }, {
                  label: '有效投注',
                }, {
                  label: '输赢',
                }, {
                  label: '操作',
                }]
              }
                    tableData={memberSummationReport ? memberSummationReport.reportList.map((item, index) => ({
                      ...item, no: index + 1
                    })) : []}
                    tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.no}</Label>),
                  data => <Label className="d-flex justify-content-center">{data.loginName}</Label>,
                  data => (<Label className="d-flex justify-content-center">{data.name}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.agentName}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.betCount}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.betAmount}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.validAmount}</Label>),
                  data => (<Label className={`d-flex justify-content-center ${parseFloat(data.winLost) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.winLost}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center">
                      <div
                        onClick={() => {
                          setSpecialLoginName(data.loginName);
                          updateModalAlert({
                            visible: true,
                            type: 'winLostDetail',
                            data: {
                              beginDate: _date[0],
                              endDate: _date[1],
                              loginName: data.loginName,
                              func: (code, name) => {
                                setLotteryCode(code);
                                setLotteryName(name);
                                setFlag(true);
                                updateModalAlert({
                                  visible: false
                                });
                              },
                            }
                          });
                        }}
                        className="operationBtn serchAndDetail"
                      >
                        详情
                      </div>
                    </Label>
                  ),
                ]
              }
                    tableFooter={[
                      [{
                        value: '本页总和',
                        colspan: 4,
                        className: 'pe-2 total tableSum text-end'
                      },
                      {
                        value: (memberSummationReport && memberSummationReport.total.betCount) || 0,
                        colspan: 1,
                        className: 'tableSum'
                      },
                      {
                        value: (memberSummationReport && memberSummationReport.total.betAmount) || 0,
                        colspan: 1,
                        className: 'tableSum'
                      },
                      {
                        value: (memberSummationReport && memberSummationReport.total.validAmount) || 0,
                        colspan: 1,
                        className: 'tableSum'
                      },
                      {
                        value: (memberSummationReport && memberSummationReport.total.winlost) || 0,
                        colspan: 1,
                        className: 'tableSum'
                      },
                      {
                        value: '',
                        colspan: 1,
                        className: 'tableSum'
                      }],
                      [{
                        value: '所有总和',
                        colspan: 4,
                        className: ' pe-2 total tableSum text-end'
                      },
                      {
                        value: (memberSummationReport && memberSummationReport.total.totalBetCount) || 0,
                        colspan: 1,
                        className: 'tableSum'
                      },
                      {
                        value: (memberSummationReport && memberSummationReport.total.totalBetAmount) || 0,
                        colspan: 1,
                        className: 'tableSum'
                      },
                      {
                        value: (memberSummationReport && memberSummationReport.total.totalValidAmount) || 0,
                        colspan: 1,
                        className: 'tableSum'
                      },
                      {
                        value: (memberSummationReport && memberSummationReport.total.totalWinlost) || 0,
                        colspan: 1,
                        className: 'tableSum'
                      },
                      {
                        value: '',
                        colspan: 1,
                        className: 'tableSum'
                      }]
                    ]}
                  />
                </div>
              </div>
              <div className="justify-center w-full relative">
                <Pagination totalRecord={memberSummationReport ? memberSummationReport.totalRecords : 0} onClick={(pageNum, rows) => onPaginationClick(pageNum, rows)} isQuery={false} />
              </div>
            </div>
          )
          : (lotteryCode && _specialLoginName
            && (
            <BetQueryDetail presetObj={{
              loginName: _specialLoginName, lotteryName, beginDate: _date[0], endDate: _date[1], lotteryCode
            }}
            />
            )
          )
      }
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberSummationReport);