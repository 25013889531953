import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  editNoticeData: state.noticeReducers.editNoticeData,
  userData: state.memberReducers.userData,
  editPayAccData: state.systemReducers.editPayAccData,
});

const mapDispatchToProps = dispatch => ({
  getEditPayAccount: (id, callback) => dispatch(systemActions.getEditPayAccount(id, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveMemberClassification: (obj, callback) => dispatch(systemActions.saveMemberClassification(obj, callback)),
});

const MemberAutoClassAddEdit = ({ callback = {}, isEdit, data, updateModalAlert, saveMemberClassification }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_data, setData] = useState([]);

  useEffect(() => {
    if (!isEdit) {
      setInfo([]);
    } else if (data && JSON.stringify(data) !== JSON.stringify(_info)) {
      setInfo(data);
      setData(data);
    }
  }, [data]);

  const saveButton = () => {
    if (_info?.isChange) {
      const updateObj = _info;
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      saveMemberClassification(updateObj, callback);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '无变动'
        }
      });
    }
  };

  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    newItems.isChange = true;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    if (!isEdit) {
      callback();
    } else {
      setInfo(_data);
    }
  };

  return (
    <div className="ExternalWebAddEditWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="m-0 my-1 p-0">
          <table className="p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="titleHeader" colSpan={2}>
                  {isEdit === true ? '编辑用户自动分类' : '新增用户自动分类'}
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">累计最少充值金额</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.minAmount?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'minAmount')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">累计最多充值金额</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.maxAmount?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'maxAmount')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">单笔最大充值金额</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.singleDepositMax?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'singleDepositMax')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">状态</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      Array.from('1234567').map(d => (
                        <div key={`rdTypeGroup_${d}`}>
                          <Input
                            id={`rdType_${d}`}
                            type="radio"
                            name="rdType"
                            key={`rdType_${data?.id}_${d}`}
                            value={_info?.level}
                            checked={_info?.level === Number(d)}
                            onChange={() => onInfoValueChange(Number(d), 'level')}
                          />
                          <Label
                            for={`rdType_${d}`}
                            className={_info?.level === Number(d) ? 'active mx-1 smallLabel' : 'mx-1 smallLabel'}
                          >
                            {d}
                            类
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberAutoClassAddEdit);