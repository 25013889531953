import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { thirdPartyGamesActions } from '../../../redux/actions';

import CommonHeader from '../../Content/CommonHeader';
import Icon from '../../Icon';
import LotteryReport from './LotteryReport';
import ThirdReport from './ThirdReport';
import './styles.scss';


const mapDispatchToProps = dispatch => ({
  GetInfo: () => dispatch(thirdPartyGamesActions.GetInfo()),
});

const WinLostReport = ({ GetInfo }) => {
  const [_tabFlag, setTabFlag] = useState(0);
  const [_specialLoginName, setSpecialLoginName] = useState();

  useEffect(() => {
    GetInfo();
  }, []);

  return (
    <div className="winLostReportWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_specialLoginName && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setSpecialLoginName()}>
              <Icon
                libName="md"
                value={{
                  name: 'MdArrowBack',
                  className: 'h5 mb-0 text-white'
                }}
              />
              返回
            </div>
            )}
            输赢报表
          </div>
        )}
      />
      <div className="tabWrapper">
        <div className={`${_tabFlag === 0 && 'selected'}`} onClick={() => { setTabFlag(0); setSpecialLoginName(); }}>游戏彩种</div>
        <div className={`${_tabFlag === 1 && 'selected'}`} onClick={() => { setTabFlag(1); setSpecialLoginName(); }}>第三方游戏</div>
      </div>
      {
        _tabFlag === 0
          ? <LotteryReport _specialLoginName={_specialLoginName} setSpecialLoginName={setSpecialLoginName} />
          : <ThirdReport _specialLoginName={_specialLoginName} setSpecialLoginName={setSpecialLoginName} />
      }
    </div>
  );
};

export default connect(null, mapDispatchToProps)(WinLostReport);