import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Input, Radio, Select } from 'element-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Icon } from '../..';
import { agentActions, commonActions, companyActions } from '../../../redux/actions';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  allCompanyList: state.commonReducers.allCompanyList,
  companyEditInfo: state.companyReducers.companyEditInfo,
  companyManageInfo: state.companyReducers.companyManageInfo,
  lotteryList: state.commonReducers.lotteryList,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  DeleteInfo: (id, callback) => dispatch(companyActions.DeleteInfo(id, callback)),
  getCompanyManageInfo: callback => dispatch(companyActions.getCompanyManageInfo(callback)),
  GetCompanyList: callback => dispatch(commonActions.GetCompanyList(callback)),
  getCompanyEditInfo: (id, callback) => dispatch(companyActions.getCompanyEditInfo(id, callback)),
  saveCompanyInfo: (obj, callback) => dispatch(companyActions.saveCompanyInfo(obj, callback)),
  SetStatus: (obj, callback) => dispatch(companyActions.SetStatus(obj, callback)),
  getLoginAs: (obj, callback) => dispatch(agentActions.getLoginAs(obj, callback)),
});

const CompanySetting = ({
  userData,
  getLoginAs,
  getCompanyManageInfo,
  companyManageInfo,
  SetStatus,
  lotteryList,
  saveCompanyInfo,
  GetCompanyList,
  allCompanyList,
  getCompanyEditInfo,
  companyEditInfo,
  DeleteInfo
}) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);
  const [isUpdate, setisUpdate] = useState(false);

  const [_activeId, setActiveId] = useState();

  const [selectedMap, setSelectedMap] = useState(new Map());
  const [totalLottery, setTotalLottery] = useState(new Map());

  useEffect(() => {
    setIsQuerying(true);
    getCompanyManageInfo();
    GetCompanyList(() => setIsQuerying(false));
  }, []);

  const Remove = id => {
    DeleteInfo(id, () => { setIsQuerying(true); GetCompanyList(() => setIsQuerying(false)); });
  };

  const [active, setactive] = useState(0);
  const [apiKey, setapiKey] = useState('');
  const [apiLoginRedirectUri, setapiLoginRedirectUri] = useState('');
  const [apiPrefix, setapiPrefix] = useState('');
  const [defaultAdmin, setdefaultAdmin] = useState('');
  const [enableChatRoom, setenableChatRoom] = useState(0);
  const [enableEarnOdds, setenableEarnOdds] = useState(0);
  const [isExtAPIClient, setIsExtAPIClient] = useState(0);
  const [enableExternalGame, setenableExternalGame] = useState(0);
  const [enableMemberPortal, setenableMemberPortal] = useState(0);
  const [enablePhoneVerification, setenablePhoneVerification] = useState(0);
  const [enableRegAgent, setenableRegAgent] = useState(0);
  const [enableRegIPLimit, setenableRegIPLimit] = useState(0);
  const [enableRegPhoneVerification, setenableRegPhoneVerification] = useState(0);
  const [logo, setlogo] = useState('');
  const [name, setname] = useState('');
  const [templateMemberLanding, settemplateMemberLanding] = useState('Default');
  const [templateMemberPortal, settemplateMemberPortal] = useState('Default');
  const [templateSystemPortal, settemplateSystemPortal] = useState('Default');
  const [url, seturl] = useState('');
  const [urlAgent, seturlAgent] = useState('');
  const [urlCompanyInfo, seturlCompanyInfo] = useState('');
  const [urlDownloadAPK, seturlDownloadAPK] = useState('');
  const [urlDownloadAPKInternal, seturlDownloadAPKInternal] = useState('');
  const [urlDownloadIPA, seturlDownloadIPA] = useState('');
  const [urlDownloadIPAInternal, seturlDownloadIPAInternal] = useState('');

  const [password, setpassword] = useState('');
  const [companyCode, setcompanyCode] = useState('');
  const [style, setstyle] = useState('blue');
  const [copyCompanyOdds, setcopyCompanyOdds] = useState('-1');
  const [validType, setvalidType] = useState(0);
  const [urlDownloadAPPInternal, seturlDownloadAPPInternal] = useState('');
  const [agentAdInfo, setagentAdInfo] = useState('');
  const [urlDownloadAPP, seturlDownloadAPP] = useState('');
  const [updateTemplateMemberLanding, setupdateTemplateMemberLanding] = useState(0);
  const [updateTemplateMemberPortal, setupdateTemplateMemberPortal] = useState(0);
  const [updateTemplateSystemPortal, setupdateTemplateSystemPortal] = useState(0);

  useEffect(() => {
    const { company } = isUpdate ? companyEditInfo || {} : {};
    setactive(company?.active ?? 0);
    setapiKey(company?.apiKey ?? '');
    setapiLoginRedirectUri(company?.apiLoginRedirectUri ?? '');
    setapiPrefix(company?.apiPrefix ?? '');
    setIsExtAPIClient(company?.isExtAPIClient ?? 0);
    setdefaultAdmin(company?.defaultAdmin ?? '');
    setenableChatRoom(company?.enableChatRoom ?? 0);
    setenableEarnOdds(company?.enableEarnOdds ?? 0);
    setenableExternalGame(company?.enableExternalGame ?? 0);
    setenableMemberPortal(company?.enableMemberPortal ?? 0);
    setenablePhoneVerification(company?.enablePhoneVerification ?? 0);
    setenableRegAgent(company?.enableRegAgent ?? 0);
    setenableRegIPLimit(company?.enableRegIPLimit ?? 0);
    setenableRegPhoneVerification(company?.enableRegPhoneVerification ?? 0);
    setlogo(company?.logo ?? '');
    setname(company?.name ?? '');
    settemplateMemberLanding(company?.templateMemberLanding ?? 'Default');
    settemplateMemberPortal(company?.templateMemberPortal ?? 'Default');
    settemplateSystemPortal(company?.templateSystemPortal ?? 'Default');
    seturl(company?.url ?? '');
    seturlAgent(company?.urlAgent ?? '');
    seturlCompanyInfo(company?.urlCompanyInfo ?? '');
    seturlDownloadAPK(company?.urlDownloadAPK ?? '');
    seturlDownloadAPKInternal(company?.urlDownloadAPKInternal ?? '');
    seturlDownloadIPA(company?.urlDownloadIPA ?? '');
    seturlDownloadIPAInternal(company?.urlDownloadIPAInternal ?? '');
    setcompanyCode(company?.companyCode ?? '');
    setstyle(company?.style ?? 'blue');
    setcopyCompanyOdds(company?.copyCompanyOdds ?? '-1');
    setvalidType(company?.validType ?? 0);
    seturlDownloadAPPInternal(company?.urlDownloadAPPInternal ?? '');
    setagentAdInfo(company?.agentAdInfo ?? '');
    seturlDownloadAPP(company?.urlDownloadAPP ?? '');
    setupdateTemplateMemberLanding(company?.updateTemplateMemberLanding ?? 0);
    setupdateTemplateMemberPortal(company?.updateTemplateMemberPortal ?? 0);
    setupdateTemplateSystemPortal(company?.updateTemplateSystemPortal ?? 0);

    if (lotteryList) {
      const mapAll = new Map();
      const mapSelected = new Map();
      const allLs = [].concat(lotteryList);
      if (isUpdate && companyEditInfo?.userList) {
        const ls = [].concat(companyEditInfo.userList);
        for (let i = 0; i < ls.length; i++) {
          const temp = allLs.find(x => (x.code === ls[i]));
          if (temp) {
            if (mapSelected.has(temp.categoryCode)) {
              const tempArr = mapSelected.get(temp.categoryCode);
              mapSelected.set(temp.categoryCode, tempArr.concat(temp));
            } else {
              mapSelected.set(temp.categoryCode, [].concat(temp));
            }
          }
        }
      }

      allLs.forEach(item => {
        if (mapAll.has(item.categoryCode)) {
          const temp = mapAll.get(item.categoryCode);
          mapAll.set(item.categoryCode, temp.concat(item));
        } else {
          mapAll.set(item.categoryCode, [].concat(item));
        }
      });
      setTotalLottery(mapAll);
      setSelectedMap(mapSelected);
    }
  }, [companyEditInfo, isUpdate]);

  const toggleAllSelection = code => {
    const temp = new Map(JSON.parse(
      JSON.stringify(Array.from(selectedMap))
    ));
    if (temp.has(code)) {
      temp.delete(code);
    } else {
      const tempItem = totalLottery.get(code);
      temp.set(code, [].concat(tempItem));
    }
    setSelectedMap(temp);
  };

  const toggleSelection = item => {
    const temp = new Map(JSON.parse(
      JSON.stringify(Array.from(selectedMap))
    ));
    if (temp.has(item.categoryCode)) {
      if (temp.get(item.categoryCode).find(x => x.code === item.code)) {
        temp.set(item.categoryCode, temp.get(item.categoryCode).filter(x => x.code !== item.code));
      } else {
        temp.set(item.categoryCode, temp.get(item.categoryCode).concat(item));
      }
    } else {
      temp.set(item.categoryCode, [].concat(item));
    }
    setSelectedMap(temp);
  };

  const save = validTypeVal => {
    const lotteryTypes = [];
    if (selectedMap.size > 0) {
      selectedMap.forEach(item => {
        item.forEach(sub => {
          lotteryTypes.push(sub.code);
        });
      });
    }
    const obj = {
      id: isUpdate ? companyEditInfo.company.id : '',
      isUpdate,
      name,
      defaultAdmin,
      active,
      password,
      logo,
      companyCode,
      style,
      copyCompanyOdds,
      templateMemberLanding,
      templateMemberPortal,
      templateSystemPortal,
      updateTemplateMemberLanding,
      updateTemplateMemberPortal,
      updateTemplateSystemPortal,
      enableMemberPortal,
      enableRegPhoneVerification,
      enablePhoneVerification,
      enableRegIPLimit,
      enableChatRoom,
      validType: validTypeVal === 0 ? validTypeVal : validType,
      enableEarnOdds,
      enableExternalGame,
      enableRegAgent,
      urlAgent,
      url,
      agentAdInfo,
      urlCompanyInfo,
      urlDownloadIPA,
      urlDownloadAPK,
      urlDownloadAPP,
      urlDownloadIPAInternal,
      urlDownloadAPKInternal,
      urlDownloadAPPInternal,
      isExtAPIClient,
      apiPrefix,
      apiKey,
      apiLoginRedirectUri,
      lotteryTypes
    };
    saveCompanyInfo(obj, () => {
      setIsQuerying(true);
      GetCompanyList(() => { setIsQuerying(false); setPageFlag(0); });
    });
  };

  const control = [
    data => (
      <>
        <Button
          className="operationBtn mx-1"
          onClick={() => getCompanyEditInfo(data.companyId, () => { setisUpdate(true); setPageFlag(2); })}
        >
          修改
        </Button>
        <Button className="operationBtn mx-1" onClick={() => Remove(data.companyId)}>删除</Button>
        <Button className="operationBtn mx-1" onClick={() => openNewWindow(data.defaultAdmin)}>登入</Button>
      </>
    ),
  ];


  const changeState = (username, status) => {
    const obj = {
      username,
      usertype: 'Company',
      status
    };
    SetStatus(obj, () => {
      setIsQuerying(true);
      GetCompanyList(() => { setIsQuerying(false); });
    });
  };

  const openNewWindow = loginId => {
    if (companyManageInfo) {
      const { orgList } = companyManageInfo;
      const item = [].concat(orgList).find(x => x.loginId === loginId);
      const callback = () => {
        window.open(`${window.location.origin}/?loginId=${loginId}&uid=${item.organizationId}&data=${String(Date.now()).substring(0, 6) + item.password}`);
      };
      getLoginAs({
        loginId, uid: item.organizationId, data: `${String(Date.now()).substring(0, 6) + item.password}`
      }, callback);
    }
  };

  const imageUploaded = async e => {
    const convertBase64 = file => new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });

    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setlogo(base64);
  };

  return (
    <div className="CompanySettingWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => { setisUpdate(false); setPageFlag(0); }}>
              <Icon
                libName="md"
                value={{
                  name: 'MdArrowBack',
                  className: 'h5 mb-0 text-white'
                }}
              />
              返回
            </div>
            )}
            {`${t('COMPANY_SETTING')} ${_pageFlag === 0 ? '' : _pageFlag === 1 ? '/ 新增公司' : '/ 编辑公司'}`}
          </div>
        )}
      />
      {_pageFlag === 0 && (
      <>
        <div className="addNewGameBox my-3">
          <div className="operationBtn add" onClick={() => setPageFlag(1)}>
            <div className="plus_icon"><i className="el-icon-plus" /></div>
            新增公司
          </div>
        </div>
        <div className="mx-0 bg-white mb-3 px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableControl={control}
            activeControl={_activeId}
            tableHeaderTitle={
            [{
              label: '公司名称',
            }, {
              label: '公司Logo',
            }, {
              label: '公司账号',
            }, {
              label: '生肖',
            }, {
              label: '是否允许备份',
            }, {
              label: '是否允许看盘',
            }, {
              label: '最低限额',
            }, {
              label: '新增日期',
            }, {
              label: '状态',
            }]
          }
            tableHeaderStyle={
            []
          }
            tableData={allCompanyList || Array(10).fill({})}
            tableBodyRowProperty={
            [
              data => (<div className="d-flex justify-content-center text-primary detailLink" onClick={() => setActiveId(data.companyId)}>{data.name}</div>),
              data => (
                <div className="d-flex justify-content-center align-items-center">
                  {
                    data?.logo
                      ? (
                        <img
                          className="companyLogo"
                          src={data?.logo}
                          alt="logo"
                        />
                      )
                      : ''
                  }
                </div>
              ),
              data => (<div className="d-flex justify-content-center">{data.defaultAdmin}</div>),
              data => (<div className="d-flex justify-content-center">{data.shengXiao}</div>),
              data => (<div className="d-flex justify-content-center">{data.allowBackup === 1 ? '允许' : '不允许'}</div>),
              data => (<div className="d-flex justify-content-center">{data.allowLookup === 1 ? '允许' : '不允许'}</div>),
              data => (<div className="d-flex justify-content-center">{data.minLimit}</div>),
              data => (<div className="d-flex justify-content-center">{data.createDate}</div>),
              data => (
                <div className="d-flex justify-content-center mousePointIn">
                  <div className={`statusBtn ${data.active === 1 ? 'activeBtn' : 'nonActiveBtn'}`}>
                    {data.active === 1 ? '启用' : '停用'}
                  </div>
                  <div className={`shiftBtn ${data.active === 1 ? 'nonActiveBtn' : 'activeBtn'}`} onClick={() => changeState(data.defaultAdmin, data.active === 1 ? 0 : 1)}>
                    {data.active === 1 ? '停用' : '启用'}
                  </div>
                </div>
              )
            ]
          }
          />
        </div>
      </>
      )}
      {_pageFlag !== 0 && (
      <div className="companyEditWrapper">
        <div className="sectionWrapper">
          <div className="tableTitleStyle">公司资料</div>
          <div className="d-flex">
            <div className="tableSection labelCellBackground">
              <div className="rowStyle">
                <div>公司名称</div>
                <div>
                  <Input value={name} onChange={text => setname(text.trim())} />
                </div>
              </div>
              <div className="rowStyle">
                <div>账号</div>
                <div>
                  <Input disabled={isUpdate} value={defaultAdmin} onChange={text => setdefaultAdmin(text.trim())} />
                </div>
              </div>
              <div className="rowStyle">
                <div>帐号状态</div>
                <div>
                  <Radio.Group value={active} onChange={val => setactive(val)}>
                    <Radio value={1}>启用</Radio>
                    <Radio value={0}>停用</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="rowStyle">
                <div>登入密码</div>
                <div>
                  <Input value={password} onChange={text => setpassword(text.trim())} />
                  <span className="ms-2">（默认123456）</span>
                </div>
              </div>
              <div className="rowStyle">
                <div>公司Logo</div>
                <div>
                  {logo
                && <img className="companyLogoPreview me-2" src={logo} alt="" />}
                  <label htmlFor="files" className="operationBtn d-flex align-items-center">
                    上传
                    <input className="uploadBtn" type="file" name="" id="files" onChange={imageUploaded} />
                  </label>
                  {logo
                && <div className="operationBtn cancel ms-2" onClick={() => setlogo('')}>删除</div>}
                </div>
              </div>
              <div className="rowStyle">
                <div>公司Code</div>
                <div>
                  <Input value={companyCode} onChange={text => setcompanyCode(text.trim())} />
                </div>
              </div>
              <div className="rowStyle">
                <div>样式</div>
                <div>
                  <Select value={style} onChange={val => setstyle(val)}>
                    {
               [{
                 label: '蓝色', value: 'blue'
               }, {
                 label: '黄色', value: 'yellow'
               }, {
                 label: '绿色', value: 'green'
               }, {
                 label: '红色', value: 'red'
               }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
             }
                  </Select>
                </div>
              </div>
              <div className="rowStyle">
                <div>退水赔率差</div>
                <div>
                  <Select value={copyCompanyOdds} onChange={val => setcopyCompanyOdds(val)}>
                    {
                      allCompanyList && [{
                        name: '系统默认', companyId: '-1'
                      }].concat(allCompanyList).map(el => <Select.Option key={el.companyId} label={`从${el.name}复制`} value={el.companyId} />)
                    }
                  </Select>
                </div>
              </div>
              <div className="rowStyle">
                <div>首页模版（会员）</div>
                <div>
                  <Input value={templateMemberLanding} onChange={text => settemplateMemberLanding(text.trim())} />
                  <Select className="ms-2" value={updateTemplateMemberLanding} onChange={val => setupdateTemplateMemberLanding(val)}>
                    {
                    [{
                      label: '同时更新模板', value: 1
                    }, {
                      label: '不更新模板', value: 0
                    }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                    }
                  </Select>
                </div>
              </div>
              <div className="rowStyle">
                <div>内页模版（会员）</div>
                <div>
                  <Input value={templateMemberPortal} onChange={text => settemplateMemberPortal(text.trim())} />
                  <Select className="ms-2" value={updateTemplateMemberPortal} onChange={val => setupdateTemplateMemberPortal(val)}>
                    {
                    [{
                      label: '同时更新模板', value: 1
                    }, {
                      label: '不更新模板', value: 0
                    }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                    }
                  </Select>
                </div>
              </div>
              <div className="rowStyle">
                <div>公司模版（后台）</div>
                <div>
                  <Input value={templateSystemPortal} onChange={text => settemplateSystemPortal(text.trim())} />
                  <Select className="ms-2" value={updateTemplateSystemPortal} onChange={val => setupdateTemplateSystemPortal(val)}>
                    {
                        [{
                          label: '同时更新模板', value: 1
                        }, {
                          label: '不更新模板', value: 0
                        }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                    }
                  </Select>
                </div>
              </div>
              <div className="rowStyle">
                <div>是否启用会员中心</div>
                <div>
                  <Radio.Group value={enableMemberPortal} onChange={val => setenableMemberPortal(val)}>
                    <Radio value={0}>启用</Radio>
                    <Radio value={1}>停用</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="rowStyle">
                <div>会员注册是否启用手机验证</div>
                <div>
                  <Radio.Group value={enableRegPhoneVerification} onChange={val => setenableRegPhoneVerification(val)}>
                    <Radio value={0}>启用</Radio>
                    <Radio value={1}>停用</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="rowStyle">
                <div>会员注册是否启用IP限制</div>
                <div>
                  <Radio.Group value={enableRegIPLimit} onChange={val => setenableRegIPLimit(val)}>
                    <Radio value={0}>启用</Radio>
                    <Radio value={1}>停用</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="rowStyle">
                <div>公司网使用手机验证</div>
                <div>
                  <Radio.Group value={enablePhoneVerification} onChange={val => setenablePhoneVerification(val)}>
                    <Radio value={0}>启用</Radio>
                    <Radio value={1}>停用</Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>

            <div className="tableSection border-start-0 labelCellBackground">
              <div className="rowStyle">
                <div>MFA验证</div>
                <div>
                  {
                    validType === 1 ? (
                      <div
                        className="operationBtn reset"
                        onClick={() => save(0)}
                      >
                        重置
                      </div>
                    ) : '未设置'
                }
                </div>
              </div>
              <div className="rowStyle">
                <div>代理赚赔功能</div>
                <div>
                  <Radio.Group value={enableEarnOdds} onChange={val => setenableEarnOdds(val)}>
                    <Radio value={1}>启用</Radio>
                    <Radio value={0}>停用</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="rowStyle">
                <div>是否启用商户接入</div>
                <div>
                  <Select value={isExtAPIClient} onChange={val => setIsExtAPIClient(val)}>
                    {
                        [{
                          label: '启用', value: 1
                        }, {
                          label: '不启用', value: 0
                        }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                    }
                  </Select>
                </div>
              </div>
              <div className="rowStyle">
                <div>是否启用第三方游戏</div>
                <div>
                  <Select value={enableExternalGame} onChange={val => setenableExternalGame(val)}>
                    {
                        [{
                          label: '启用', value: 1
                        }, {
                          label: '不启用', value: 0
                        }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                    }
                  </Select>
                </div>
              </div>
              <div className="rowStyle">
                <div>是否启用代理注册</div>
                <div>
                  <Radio.Group value={enableRegAgent} onChange={val => setenableRegAgent(val)}>
                    <Radio value={1}>启用</Radio>
                    <Radio value={0}>停用</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="rowStyle">
                <div>代理推广链接网址</div>
                <div>
                  <Input
                    placeholder="例如：http://hao123.com(要带http://或https://,不能“/”结尾)"
                    className="w-50"
                    value={urlAgent}
                    onChange={text => seturlAgent(text.trim())}
                  />
                </div>
              </div>
              <div className="rowStyle">
                <div>推广链接网址</div>
                <div>
                  <Input
                    className="w-50"
                    placeholder="例如：http://hao123.com(要带http://或https://,不能“/”结尾)"
                    value={url}
                    onChange={text => seturl(text.trim())}
                  />
                </div>
              </div>
              <div className="rowStyle">
                <div>代理推广信息(选填)</div>
                <div>
                  <Input
                    className="w-50"
                    value={agentAdInfo}
                    onChange={text => setagentAdInfo(text.trim())}
                  />
                </div>
              </div>
              <div className="rowStyle">
                <div>公司资料信息地址</div>
                <div>
                  <Input
                    className="w-50"
                    value={urlCompanyInfo}
                    onChange={text => seturlCompanyInfo(text.trim())}
                  />
                </div>
              </div>
              <div className="rowStyle">
                <div>IPA下载地址</div>
                <div>
                  <Input
                    className="w-50"
                    value={urlDownloadIPA}
                    onChange={text => seturlDownloadIPA(text.trim())}
                  />
                </div>
              </div>
              <div className="rowStyle">
                <div>APK下载地址</div>
                <div>
                  <Input
                    className="w-50"
                    value={urlDownloadAPK}
                    onChange={text => seturlDownloadAPK(text.trim())}
                  />
                </div>
              </div>
              <div className="rowStyle">
                <div>APP下载地址</div>
                <div>
                  <Input
                    className="w-50"
                    value={urlDownloadAPP}
                    onChange={text => seturlDownloadAPP(text.trim())}
                  />
                </div>
              </div>
              <div className="rowStyle">
                <div>IPA下载地址(私有)</div>
                <div>
                  <Input
                    className="w-50"
                    value={urlDownloadIPAInternal}
                    onChange={text => seturlDownloadIPAInternal(text.trim())}
                  />
                </div>
              </div>
              <div className="rowStyle">
                <div>APK下载地址(私有)</div>
                <div>
                  <Input
                    className="w-50"
                    value={urlDownloadAPKInternal}
                    onChange={text => seturlDownloadAPKInternal(text.trim())}
                  />
                </div>
              </div>
              <div className="rowStyle border-bottom">
                <div>APP下载地址(私有)</div>
                <div>
                  <Input
                    className="w-50"
                    value={urlDownloadAPPInternal}
                    onChange={text => seturlDownloadAPPInternal(text.trim())}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
        {isExtAPIClient === 1
          ? (
            <div className="sectionWrapper">
              <div className="tableTitleStyle">商户API调用</div>
              <div className="tableSection labelCellBackground">
                <div className="rowStyle">
                  <div>公司用户前缀</div>
                  <div>
                    <Input
                      className="w-50"
                      value={apiPrefix}
                      onChange={text => setapiPrefix(text.trim())}
                    />
                  </div>
                </div>
                <div className="rowStyle">
                  <div>API识别key</div>
                  <div>
                    <Input
                      className="w-50"
                      value={apiKey}
                      onChange={text => setapiKey(text.trim())}
                    />
                  </div>
                </div>
                <div className="rowStyle">
                  <div>用户登入跳转地址</div>
                  <div>
                    <Input
                      className="w-50"
                      value={apiLoginRedirectUri}
                      onChange={text => setapiLoginRedirectUri(text.trim())}
                    />
                  </div>
                </div>

              </div>
            </div>
          )
          : ''}
        <div className="sectionWrapper">
          <div className="tableTitleStyle">彩种选择</div>
          <div className="tableSection labelCellBackground">
            {Array.from(totalLottery.values()).map(item => (
              <div className="rowStyle">
                <div>
                  <span className="me-3">{item[0].categoryName}</span>
                  <Checkbox onChange={() => toggleAllSelection(item[0].categoryCode)} checked={(selectedMap.size > 0 && selectedMap.has(item[0].categoryCode)) ? (selectedMap.get(item[0].categoryCode).length === totalLottery.get(item[0].categoryCode).length) : false}>所有</Checkbox>
                </div>
                <div>
                  {
                    item.map(ele => (
                      <Checkbox checked={selectedMap.size > 0 ? selectedMap.get(ele.categoryCode)?.find(x => x.code === ele.code) : false} onChange={() => toggleSelection(ele)} className="lotterySelection my-1">
                        { userData?.levelValue === 0 ? ele?.lotteryNoteName : ele?.lotteryName}
                      </Checkbox>
                    ))
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-center py-3">
          <div className="operationBtn" onClick={() => save()}>保存</div>
          <div className="operationBtn ms-3 cancel" onClick={() => { setPageFlag(0); setisUpdate(false); }}>取消</div>
        </div>
      </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySetting);