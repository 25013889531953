import React, { useEffect, useState } from 'react';

import { Input } from 'reactstrap';
import { Select } from 'element-react';
import { useTranslation } from 'react-i18next';

import Icon from '../../Icon';
import { PagesList } from '../../../constants/SelectionItems';
import './styles.scss';

const Pagination = ({ onClick, totalRecord, dropdownList, isQuery, customizePage }) => {
  const { t } = useTranslation();
  const [_currentPage, setCurrentPage] = useState(1);
  const [_addPage, setAddPage] = useState(1);
  const [_dropdownList, setDropdownList] = useState(dropdownList);
  const [_selectedDisplayRow, setSelectedDisplayRow] = useState(15);
  const [_totalPage, setTotalPage] = useState(15);
  const [_pageArray, setPageArray] = useState([]);
  const totalDisplayPageItem = 5;

  useEffect(() => {
    if (isQuery) {
      setCurrentPage(1);
      document.getElementById('inputPageNum').value = 1;
    }
  }, [isQuery]);

  useEffect(() => {
    if (customizePage && customizePage > 0) {
      setCurrentPage(Number(customizePage));
      document.getElementById('inputPageNum').value = Number(customizePage);
    }
  }, [customizePage]);

  useEffect(() => {
    setTotalPage(Math.floor(totalRecord / _selectedDisplayRow) + (totalRecord % _selectedDisplayRow > 0 ? 1 : 0));
    setPageArray(new Array(totalDisplayPageItem).fill(''));
  }, [totalRecord]);

  useEffect(() => {
    setTotalPage(Math.floor(totalRecord / _selectedDisplayRow) + (totalRecord % _selectedDisplayRow > 0 ? 1 : 0));
    if (JSON.stringify(dropdownList) !== JSON.stringify(_dropdownList)) {
      setDropdownList(dropdownList);
    }
  }, [_selectedDisplayRow]);

  useEffect(() => {
    document.getElementById('inputPageNum').value = _currentPage;
    // calculate page number offset
    if (_currentPage === 1) {
      setAddPage(1);
    } else if (_currentPage > 0 && _currentPage <= _totalPage) {
      const test = Math.floor(_currentPage / totalDisplayPageItem);
      if (_currentPage % totalDisplayPageItem > 0) {
        setAddPage((test * totalDisplayPageItem) + 1);
      } else {
        setAddPage((test * totalDisplayPageItem) - (totalDisplayPageItem - 1));
      }
    }
  }, [_currentPage]);

  const onPrevClick = () => {
    if (_currentPage === 1) return;
    const pagecount = _currentPage - 1 <= 1 ? 1 : _currentPage - 1;
    onClick(pagecount, _selectedDisplayRow);
    setCurrentPage(pagecount);
  };

  const onNextClick = () => {
    if (_currentPage >= _totalPage) return;
    const pagecount = _currentPage + 1 >= _totalPage ? _totalPage : _currentPage + 1;
    onClick(pagecount, _selectedDisplayRow);
    setCurrentPage(pagecount);
  };

  const onNextSetClick = () => {
    if (_currentPage >= (_totalPage - totalDisplayPageItem)) return;
    const pagecount = _currentPage + totalDisplayPageItem;
    onClick(pagecount, _selectedDisplayRow);
    setCurrentPage(pagecount);
  };

  const onPrevSetClick = () => {
    if (_currentPage < totalDisplayPageItem) return;
    const pagecount = _currentPage - totalDisplayPageItem;
    onClick(pagecount, _selectedDisplayRow);
    setCurrentPage(pagecount);
  };

  const handlePageNumber = e => {
    if (Number(e.target.value) <= _totalPage && Number(e.target.value) > 0) {
      onClick(Number(e.target.value), _selectedDisplayRow);
      setCurrentPage(Number(e.target.value));
    }
  };

  return (
    <div className="pagination justify-content-center">
      <div className="py-1 px-3 justify-content-center item-center text-center">
        {t('TOTAL')}
        <span className="px-1">{(totalRecord) || 0}</span>
        {t('ENTRIES')}
      </div>
      <div className="selectionWrapper">
        <Select
          popper-class="select-popper"
          value={_selectedDisplayRow}
          onChange={value => {
            onClick(1, value);
            setSelectedDisplayRow(value);
            setCurrentPage(1);
          }}
        >
          {
            PagesList.map(item => (
              <Select.Option key={item.value} label={item.label} value={item.value} />
            ))
          }
        </Select>
      </div>
      <span
        className={`box mx-1 ${_currentPage < totalDisplayPageItem ? 'inactive' : ''}`}
        onClick={onPrevSetClick}
      >
        <Icon
          libName="fa"
          value={{
            name: 'FaAngleDoubleLeft',
            className: 'ml-2'
          }}
        />
      </span>
      <span
        className={`box mx-1 ${_currentPage === 1 ? 'inactive' : ''}`}
        onClick={onPrevClick}
      >
        <Icon
          libName="fa"
          value={{
            name: 'FaAngleLeft',
            className: 'ml-2'
          }}
        />
      </span>
      {
          _pageArray && _pageArray.map((item, index) => (
            <span
              key={`mypagination${item}_${index + _addPage}`}
              onClick={() => {
                onClick(index + _addPage, _selectedDisplayRow);
                setCurrentPage(index + _addPage);
              }}
              className={`box mx-1 ${((index + _addPage) > _totalPage) && 'd-none'} font-bold ${(_currentPage === (index + _addPage)) && 'paginationActive'}`}
            >
              {index + _addPage}
            </span>
          ))
      }
      <span
        className={`box mx-1 ${_currentPage === _totalPage || _totalPage === 0 ? 'inactive' : ''}`}
        onClick={onNextClick}
      >
        <Icon
          libName="fa"
          value={{
            name: 'FaAngleRight',
            className: 'ml-2'
          }}
        />
      </span>
      <span
        className={`box mx-1 ${_currentPage >= (_totalPage - totalDisplayPageItem) ? 'inactive' : ''}`}
        onClick={onNextSetClick}
      >
        <Icon
          libName="fa"
          value={{
            name: 'FaAngleDoubleRight',
            className: 'ml-2'
          }}
        />
      </span>
      <span className="px-3">
        {t('GO_TO')}
        <Input
          title="Current Page"
          className="mx-2 my-0 p-2"
          defaultValue={_currentPage.toString()}
          onBlur={handlePageNumber}
          aria-label="Current Page"
          min="1"
          max={_totalPage}
          placeholder="1"
          id="inputPageNum"
        />
        /
        <span className="px-1">
          {_totalPage === 0 ? 1 : _totalPage}
        </span>
        {t('PAGE')}
      </span>
    </div>
  );
};

export default Pagination;