import React, { useEffect, useState } from 'react';

import { Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Icon from '../../Icon';
import Pagination from '../../Content/Pagination';
import './styles.scss';


const mapStateToProps = state => ({
  externalWebInfoData: state.systemReducers.externalWebInfoData,
});

const mapDispatchToProps = dispatch => ({
  getExternalWeb: (userId, callback) => dispatch(systemActions.getExternalWeb(userId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateExternalWebStatus: (obj, callback) => dispatch(systemActions.updateExternalWebStatus(obj, callback)),
  deleteExternalWeb: (obj, callback) => dispatch(systemActions.deleteExternalWeb(obj, callback)),
});

const ExternalWebManage = ({
  callback = () => {},
  externalWebInfoData,
  getExternalWeb,
  data,
  updateModalAlert,
  deleteExternalWeb,
  updateExternalWebStatus
}) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const [_totalRecord, setTotalRecord] = useState(0);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [_searchText, setSearchText] = useState('');

  useEffect(() => {
    if (externalWebInfoData) {
      setInfo(externalWebInfoData?.webList);
      setTotalRecord(externalWebInfoData?.totalRecords);
      setIsQueryingDetail(false);
    }
  }, [externalWebInfoData]);

  useEffect(() => {
    setIsQueryingDetail(true);
    getExternalWeb({
      webName: _searchText,
      page: _page,
      rows: _rows,
    }, setIsQueryingDetail);
  }, []);

  const findClick = () => {
    setIsQueryingDetail(true);
    // Page number and {row number will follow the pagination component instead
    getExternalWeb({
      webName: _searchText,
      page: 1,
      rows: _rows,
    }, setIsQueryingDetail);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    // Page number and {row number will follow the pagination component instead
    getExternalWeb({
      webName: _searchText,
      page: pageNum,
      rows: _rows,
    }, setIsQueryingDetail);
    setPage(pageNum);
    setRows(rows);
  };
  const deleteClick = id => {
    if (!id) {
      id = Array
        .from(document.querySelectorAll('[name^="main_"]:checked'))
        .filter(checkbox => checkbox.checked)
        .map(checkbox => checkbox.value);
    } else {
      id = [id];
    }
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      showCancelButton: true,
      data: {
        message: '确认删除',
        onConfirmationClick: () => deleteExternalWeb(id)
      },
    });
  };

  const setStatus = id => {
    if (!id) {
      id = Array
        .from(document.querySelectorAll('[name^="main_"]:checked'))
        .filter(checkbox => checkbox.checked)
        .map(checkbox => checkbox.value);
    } else {
      id = [id];
    }
    updateExternalWebStatus(id);
  };

  const tableHeader = [{
    label: (
      <>
        <Input
          id="main_selectAll"
          type="checkbox"
          className="mx-2 p-1 align-items-center"
          onClick={() => {
            const cb = document.querySelector('#main_selectAll');
            if (cb) {
              const aInputs = document.querySelectorAll("input[type='checkbox']");
              for (let i = 0, n = aInputs?.length || 0; i < n; i++) {
                aInputs[i].checked = cb?.checked;
              }
            }
          }}
        />
        {t('SELECT_ALL')}
      </>),
  }, {
    label: t('WEB_NAME'),
  }, {
    label: t('WEB_ADDRESS'),
  }, {
    label: t('REMARK'),
  }, {
    label: t('CREATE_DATE'),
  }, {
    label: t('STATUS'),
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    data => (
      <>
        <Input
          id={`main_${data.id}`}
          type="checkbox"
          key={`main_${data.id}`}
          className="mx-2 px-1 align-items-center"
          name={`main_${data.id}`}
          value={data.id}
        />
      </>
    ),
    data => (<Label className="d-flex justify-content-center">{data?.name}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.address}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.remark}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.date && moment.unix(data?.date).format('YYYY-MM-DD HH:mm:ss')}</Label>),
    data => (data?.status === 1 ? <Label className="negativeValue d-flex justify-content-center">{t('INACTIVE')}</Label> : <Label className="d-flex justify-content-center installment">{t('ACTIVE')}</Label>),
    data => (
      <div className="d-flex justify-content-center">
        <div className="button green mx-1" onClick={() => callback('externalWebAddEdit', data, true)}>{t('EDIT')}</div>
        <div className="button handle mx-1" onClick={() => setStatus(data?.id)}>{t('TOGGLE_STATUS')}</div>
        <div className="button find mx-1" onClick={() => deleteClick(data?.id)}>{t('DELETE')}</div>
      </div>
    ),
  ];

  return (
    <div className="ExternalWebManageWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <div className="p-0 m-0 mb-2">
          <div className="p-1 mb-2 filterWrapper d-flex align-items-center w-100">
            <div className="d-flex align-items-center">
              <div
                className="button add justify-content-center"
                onClick={() => callback('externalWebAddEdit', data, false)}
              >
                <Icon
                  libName="fa"
                  value={{
                    name: 'FaPlusCircle',
                    className: 'mb-1 mr-1'
                  }}
                />
                <Label className="p-0 px-1">新增外补网站</Label>
              </div>
              <Label value={t('EXTERNAL_WEB_NAME')} className="px-3 marginLeft5">{t('EXTERNAL_WEB_NAME')}</Label>
              <Input type="text" className="mx-2 justify-content-center" value={_searchText} onChange={({ target: { value: text } }) => setSearchText(text)} />
              <div className="button find mx-3" onClick={() => findClick()}>{t('FIND')}</div>
            </div>
            <div className="align-items-center justify-content-end">
              <div className="button find mx-1 right" onClick={() => deleteClick()}>{t('DELETE')}</div>
              <div className="button handle mx-1 right" onClick={() => setStatus()}>{t('TOGGLE_STATUS')}</div>
            </div>
          </div>
        </div>
        <Row className="bigWrapper m-0 my-1 p-3">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeader}
            tableData={_info || (_isQueryingDetail ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
            tableBodyRowStyle={[]}
          />
        </Row>
        <Row className="justify-center w-full relative">
          <Pagination totalRecord={_totalRecord || 0} onClick={onPaginationClick} isQuery={false} />
        </Row>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalWebManage);