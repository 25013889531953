import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import InTimeSheetItem from '../InTimeSheetItem';
import './styles.scss';

const mapStateToProps = state => ({
  inTimeSheetOdds: state.inTimeSheetReducers.inTimeSheetOdds,
});

const InTimeSheetTable = ({ inTimeSheetOdds, betItemList, betItemStyleList, tableStyle, tableTitle, tableTitleStyle, tableHeadertitle, tableHeadertitleStyle, fillEmpty, horizontal, summary, mainColumnLength, mainRowLength, list }) => {
  const [_OddsListObj, setOddsListObj] = useState();
  const [_MenuOddsListObj, setMenuOddsListObj] = useState();

  useEffect(() => {
    if (inTimeSheetOdds) {
      const obj = {};
      inTimeSheetOdds.listOdds.forEach(element => {
        obj[element.keyCode] = element;
      });
      setOddsListObj(obj);
    } else {
      setOddsListObj();
    }
  }, [inTimeSheetOdds]);

  useEffect(() => {
    if (inTimeSheetOdds) {
      const obj = {};
      inTimeSheetOdds.subMenuSum.forEach(element => {
        obj[element.menuId] = element;
      });
      setMenuOddsListObj(obj);
    } else {
      setMenuOddsListObj();
    }
  }, [inTimeSheetOdds]);

  const renderTableBody = () => {
    let count = 0;
    let perColContent = [];
    const content = [];
    const headerTitle = tableHeadertitle && (
      <div className="d-flex border-top">
        {Array(mainColumnLength).fill('').map(() => (
          <div className="d-flex flex-grow-1 headerTitle">
            {tableHeadertitle.map((title, index) => <div key={`table_header_${title}`} className={`${tableHeadertitleStyle[index]}`}>{title}</div>)}
          </div>
        ))}
      </div>
    );
    for (let i = 0; i < mainColumnLength; i++) {
      for (let j = 0; j < mainRowLength; j++) {
        if (list[count]) {
          if (Array.isArray(list[count])) {
            const Component = betItemList[0];
            const ele = (
              <div className="d-flex">
                <Component obj={list[count][0]} betItemStyle={betItemStyleList[0]} />
                <div className="flex-grow-1">
                  {
                  list[count].map(item => <InTimeSheetItem key={`item_${j.toString()}_${i.toString()}`} _OddsListObj={_OddsListObj} obj={item} horizontal={horizontal} betItemList={betItemList.slice(1)} betItemStyleList={betItemStyleList.slice(1)} />)
                  }
                </div>
              </div>
            );
            perColContent.push(ele);
          } else {
            perColContent.push(<InTimeSheetItem key={`item_${j.toString()}_${i.toString()}`} _OddsListObj={_OddsListObj} obj={list[count]} horizontal={horizontal} betItemList={betItemList} betItemStyleList={betItemStyleList} />);
          }
        } else if (fillEmpty && !list[count]) {
          perColContent.push(<div />);
        }
        count += 1;
      }
      content.push(<div key={`col_content_${i}`}>{perColContent}</div>);
      perColContent = [];
    }

    return (
      <>
        {headerTitle}
        {content}
        {summary && (
        <>
          {summary.map((item, index) => (
            <div key={`summaryItem_${index.toString()}`} className="border-top py-2 text-center d-flex align-items-center justify-content-center">
              {`${item.displayName} :`}
              {(inTimeSheetOdds && _MenuOddsListObj)
              && <span className="text-danger px-3">{`${item.code === 'maxLoss' || item.code === 'maxProfit' ? inTimeSheetOdds[item.code] : _MenuOddsListObj[item.code]?.amt}`}</span>}
            </div>
          ))}
        </>
        )}
      </>
    );
  };
  return (
    <div className={`inTimeSheetTable ${tableStyle}`}>
      {tableTitle && <div className={`${tableTitleStyle} tableTitle`}>{tableTitle}</div>}
      {renderTableBody()}
    </div>
  );
};

export default connect(mapStateToProps)(InTimeSheetTable);