import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { DateRangePicker, Select } from 'element-react';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, LotteryMenu, Pagination } from '../../components';
import { CommonSearchBy, UserBetStatus, UserGameType } from '../../constants/SelectionItems';
import { appActions, betListActions, commonActions, systemActions } from '../../redux/actions';
import Notifications from '../../components/Content/Notifications';

import Icon from '../../components/Icon';

import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  betQueryResult: state.betListReducers.betQueryResult,
  lotteryList: state.commonReducers.lotteryList,
  lotteryInstallments: state.commonReducers.lotteryInstallments,
});

const mapDispatchToProps = dispatch => ({
  getBetQueryResult: (obj, callback) => dispatch(betListActions.getBetQueryResult(obj, callback)),
  getLotteryInstallments: (lotteryCode, date) => dispatch(commonActions.getLotteryInstallments(lotteryCode, date)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  cancelBettingByInstallNo: (lotteryType, installments, status, onClick) => dispatch(systemActions.cancelBettingByInstallNo(lotteryType, installments, status, onClick)),
  cancelBetting: (lotteryType, installments, status, ids, onClick) => dispatch(systemActions.cancelBetting(lotteryType, installments, status, ids, onClick)),
});

const BetRevoke = ({ betQueryResult, getBetQueryResult, lotteryList, getLotteryInstallments, lotteryInstallments, updateModalAlert, cancelBettingByInstallNo, cancelBetting }) => {
  const { t } = useTranslation();
  const [_searchItem, setSearchItem] = useState('');
  const [_date, setDate] = useState([moment().subtract(1, 'weeks').toDate(), moment().toDate()]);
  const [_detailItem, setDetailItem] = useState();
  const [_installmentsResults, setInstallmentResults] = useState([]);
  const [_detailItemSum, setDetailItemSum] = useState();
  const [_selectedLottery, setSelectedLottery] = useState('');
  const [_selecteLotteryTxt, setSelectedLotteryTxt] = useState('全部');
  const [_displayMenu, setDisplayMenu] = useState(false);
  const [_selectedBetStatus, setSelectedBetStatus] = useState('3');
  const [_selectedInstallments, setSelectedInstallments] = useState('-1');
  const [_selectedBetType, setSelectedBetType] = useState('0');
  const [_selectedGameType, setSelectedGameType] = useState('0');
  const [_selectedSearchBy, setSelectedSearchBy] = useState('username');
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const ids = [];
  let dateRef;

  useEffect(() => {
    // Default search when first load
    if (lotteryList) {
      getBetQueryResult({
        lotteryType: _selectedLottery,
        startDate: moment().add(-7, 'days').unix(),
        endDate: moment().unix(),
        searchBy: _selectedSearchBy,
        page: _page,
        rows: _rows,
        status: _selectedBetStatus,
        value: _searchItem,
        isTest: _selectedGameType,
        installments: _selectedInstallments === '-1' ? '' : _selectedInstallments,
        memberId: '',
        userType: ''
      }, setIsQueryingDetail);
      setSelectedLotteryTxt(lotteryList.find(item => item.code === _selectedLottery)?.lotteryName);
    }
  }, [lotteryList]);

  useEffect(() => {
    setInstallmentResults(lotteryInstallments);
  }, [lotteryInstallments]);

  useEffect(() => {
    if (betQueryResult && JSON.stringify(_detailItem) !== JSON.stringify(betQueryResult)) {
      setDetailItem(betQueryResult.data);
      setDetailItemSum(betQueryResult.sum);
      setIsQueryingDetail(false);
    }
  }, [betQueryResult]);

  const onLotterySelect = retvalue => {
    getLotteryInstallments(retvalue?.code, moment(_date[0]).unix() || moment().add(-7, 'days').unix());
    setSelectedLottery(retvalue?.code);
    setSelectedLotteryTxt(retvalue?.lotteryName);
    setSelectedInstallments('-1');
    setDisplayMenu(false);
  };

  const onSearchClick = () => {
    setIsQueryingDetail(true);
    getBetQueryResult({
      lotteryType: _selectedLottery || '',
      startDate: (moment(_date[0]).unix() || moment().add(-7, 'days').unix()),
      endDate: (moment(_date[1]).unix() || moment().unix()),
      searchBy: _selectedSearchBy,
      page: 1,
      rows: _rows,
      status: _selectedBetStatus,
      value: _searchItem,
      isTest: _selectedGameType,
      installments: _selectedInstallments === '-1' ? '' : _selectedInstallments,
      memberId: '',
      userType: ''
    }, setIsQueryingDetail);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    // Page number and row number will follow the pagination component instead
    getBetQueryResult({
      lotteryType: _selectedLottery,
      startDate: (moment(_date[0]).unix() || moment().add(-7, 'days').unix()),
      endDate: (moment(_date[1]).unix() || moment().unix()),
      searchBy: _selectedSearchBy,
      page: pageNum,
      rows,
      status: _selectedBetStatus,
      value: _searchItem,
      isTest: _selectedGameType,
      installments: _selectedInstallments === '-1' ? '' : _selectedInstallments,
      memberId: '',
      userType: ''
    }, setIsQueryingDetail);
    setPage(pageNum);
    setRows(rows);
  };

  const toggleCheckBoxes = event => {
    const checkboxes = document.getElementsByName('chkBetRevoke');
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = event.target.checked;
    }
  };

  const onClickByIds = status => {
    if (_selectedInstallments === '-1' || !_selectedLottery) {
      const mymsg = _selectedInstallments === '-1' ? '请选择期数' : '请选择彩种';
      updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          message: mymsg,
          showCancelButton: false
        }
      });
    } else {
      const checkboxes = document.getElementsByName('chkBetRevoke');
      for (let i = 0, n = checkboxes.length; i < n; i++) {
        if (checkboxes[i].checked) {
          ids.push(checkboxes[i].id);
        }
      }
      if (ids.length > 0) {
        updateModalAlert({
          type: 'loading',
          visible: true,
        });
        cancelBetting(_selectedLottery, _selectedInstallments, status, ids, onSearchClick);
      } else {
        updateModalAlert({
          type: 'confirmation',
          visible: true,
          data: {
            message: '请选择',
            showCancelButton: false
          }
        });
      }
    }
  };

  const onClickById = (status, id, lotteryType, installments) => {
    updateModalAlert({
      type: 'loading',
      visible: true,
    });
    cancelBetting(lotteryType, installments, status, [id], onSearchClick);
  };

  const onClickByInstallNo = status => {
    if (_selectedInstallments === '-1' || !_selectedLottery) {
      const mymsg = _selectedInstallments === '-1' ? '请选择期数' : '请选择彩种';
      updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          message: mymsg,
          showCancelButton: false
        }
      });
    } else {
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      cancelBettingByInstallNo(_selectedLottery, _selectedInstallments, status, onSearchClick);
    }
  };

  return (
    <Col className="betRevokeWrapper">
      <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
        <Label value={t('LOTTERY')} className="px-2">{t('LOTTERY')}</Label>
        <div className="flex">
          <div
            onMouseEnter={() => setDisplayMenu(!_displayMenu)}
            onClick={() => setDisplayMenu(!_displayMenu)}
            className={`menuWrapper d-flex ${_displayMenu ? 'menuactive' : ''}`}
          >
            <Label className="menuSelectedText">{_selecteLotteryTxt || '全部'}</Label>
            <div className="float-right">
              <Icon
                libName="fa"
                value={{
                  name: 'FaCaretDown',
                  className: 'float-right'
                }}
              />
            </div>
          </div>

          <div onMouseLeave={() => setDisplayMenu(false)} className={`subselection ${_displayMenu ? '' : 'hide'}`}>
            <LotteryMenu
              lotteryList={lotteryList}
              selectedItem={_selectedLottery}
              onClickItem={onLotterySelect}
            />
          </div>
        </div>
        <Label value={t('INSTALLMENTS')} className="px-2">{t('INSTALLMENTS')}</Label>
        <Select
          popper-class="select-popper"
          value={_selectedInstallments}
          onChange={value => (setSelectedInstallments(value))}
        >
          <Select.Option key="installment_-1" label={t('ALL')} value="-1" />
          {
            _installmentsResults && _installmentsResults?.map(item => (
              <Select.Option key={item.desc} label={item.installment} value={item.installment} />
            ))
          }
        </Select>
        <Label value={t('BET_TYPE')} className="px-2">{t('BET_TYPE')}</Label>
        <Select
          popper-class="select-popper"
          value={_selectedBetType}
          onChange={value => (setSelectedBetType(value))}
        >
          <Select.Option key="betType_-1" label={t('ALL')} value="-1" />
          <Select.Option key="betType_0" label="会员下注" value="0" />
        </Select>
        <Label value={t('CATEGORY')} className="px-2">{t('CATEGORY')}</Label>
        <Select
          popper-class="select-popper"
          value={_selectedGameType}
          onChange={value => (setSelectedGameType(value))}
        >
          <Select.Option key="UserGameType_-1" label={t('ALL')} value="-1" />
          {
            UserGameType.map(item => (
              <Select.Option key={`UserGameType_${item.value}`} label={item.label} value={item.value} />
            ))
          }
        </Select>
        <Label value={t('STATUS')} className="px-2">{t('STATUS')}</Label>
        <Select
          popper-class="select-popper"
          value={_selectedBetStatus}
          onChange={value => (setSelectedBetStatus(value))}
        >
          <Select.Option key="UserBetStatus_-1" label={t('ALL')} value="-1" />
          {
            UserBetStatus.map(item => (
              <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
            ))
          }
        </Select>
        <Label value={t('SEARCH')} className="px-2">{t('SEARCH')}</Label>
        <Select
          popper-class="select-popper"
          value={_selectedSearchBy}
          onChange={value => {
            setSelectedSearchBy(value);
            if (value === '-1') {
              setSearchItem('');
            }
          }}
        >
          <Select.Option key="CommonSearchBy_-1" label={t('ALL')} value="-1" />
          {
            CommonSearchBy.map(item => (
              <Select.Option key={`CommonSearchBy${item.value}`} label={item.label} value={item.value} />
            ))
          }
        </Select>
        <Input className="mx-1" value={_searchItem} onChange={({ target: { value: text } }) => setSearchItem(text)} />
        <Label type="daterange" value={t('DATE')} className="px-1">{t('DATE')}</Label>
        <DateRangePicker
          className="betQueryDatepicker mx-1"
          value={_date}
          isShowTime
          placeholder={t('START_END_DATE')}
          rangeSeparator={` ${t('TO')} `}
          onChange={_date => {
            setDate(_date);
          }}
          ref={e => dateRef = e}
          shortcuts={[{
            text: t('TODAY'),
            onClick: () => {
              setDate([moment().toDate(), moment().toDate()]);
              dateRef.togglePickerVisible();
            }
          }, {
            text: t('THIS_WEEK'),
            onClick: () => {
              setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
              dateRef.togglePickerVisible();
            }
          }, {
            text: t('PREVIOUS_WEEK'),
            onClick: () => {
              setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
              dateRef.togglePickerVisible();
            }
          }, {
            text: t('PREVIOUS_2_WEEK'),
            onClick: () => {
              setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
              dateRef.togglePickerVisible();
            }
          }]}
        />
        <Button className="searchButton mx-1" onClick={onSearchClick}>{t('FIND')}</Button>
        <Button className="searchButton mx-1" onClick={() => onClickByIds(1)}>{t('CANCEL')}</Button>
        <Button className="searchButton mx-1" onClick={() => onClickByIds(0)}>{t('REVERT')}</Button>
        <Select
          popper-class="select-popper"
          placeholder="按期数"
          onChange={value => (onClickByInstallNo(value))}
          className="dropdownred mx-1"
        >
          <Select.Option label={t('CANCEL')} value="1" />
          <Select.Option label={t('REVERT')} value="0" />
        </Select>
        <Notifications />
      </Col>
      <Row className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={
            [{
              label: (
                <div className="d-flex">
                  <Input id="chkBetRevokeCheckAll" type="checkbox" onClick={toggleCheckBoxes} className="mx-1 d-flex justify-content-center" />
                  <Label for="chkBetRevokeCheckAll">全选</Label>
                </div>
              ),
            }, {
              label: t('BET_NUMBER'),
            }, {
              label: t('BET_TIME'),
            }, {
              label: t('LOTTERY'),
            }, {
              label: t('ACCOUNT_NAME'),
            }, {
              label: t('BET_CONTENT'),
            }, {
              label: t('BET') + t('BEFORE') + t('REMAINED_AMOUNT'),
            }, {
              label: t('BET') + t('AMOUNT'),
            }, {
              label: t('BET') + t('AFTER') + t('REMAINED_AMOUNT'),
            }, {
              label: `${t('REBATE')}%/${t('AMOUNT')}`,
            }, {
              label: t('BET_RESULT'),
            }, {
              label: t('THIS_LVL_PERCENTAGE'),
            }, {
              label: t('THIS_LVL_RESULT'),
            }, {
              label: t('REMARK'),
            }, {
              label: t('STATUS'),
            }, {
              label: t('OPERATION'),
            }]
          }
          tableData={_detailItem || []}
          tableBodyRowProperty={
            [
              data => (<Input type="checkbox" id={data.id} name="chkBetRevoke" className="mx-1 justify-content-center" />),
              data => (
                <>
                  <Label
                    className="lotteryName url"
                    onClick={() => {
                      updateModalAlert({
                        type: 'betDetails',
                        visible: true,
                        data: {
                          id: data?.id,
                        }
                      });
                    }}
                  >
                    {data.oddNo}
                  </Label>
                  <Label>{`IP: ${data.ip}`}</Label>
                </>
              ),
              data => (
                <>
                  <Label className="px-1">{moment.unix(data.created).format('YYYY-MM-DD HH:mm:ss')}</Label>
                  <Label>{t(`DAY_OF_WEEK_${moment.unix(data.created || 0).weekday()}`)}</Label>
                </>
              ),
              data => (
                <>
                  <Label className="lotteryName">{data.lotteryName}</Label>
                  <Label className="installment">{t('DI')}</Label>
                  <Label className="installment">{data.installments}</Label>
                  <Label className="installment">{t('INSTALLMENTS')}</Label>
                </>
              ),
              data => (
                <>
                  <Label className="lotteryName">{data.orgName}</Label>
                  <Label className="px-1">
                    {data.oddsType}
                  </Label>
                  {t('PLATE')}
                </>
              ),
              data => (
                <>
                  <Label className="content">{`${data.midType}`}</Label>
                  <Label className="content">{`【${data.displayname}】`}</Label>
                  <Label className="mx-1">@</Label>
                  <Label className="odds">{data.odds}</Label>
                </>
              ),
              data => (<Label className="d-flex justify-content-center">{data.beforeAmount}</Label>),
              data => (<Label className="d-flex justify-content-end">{data.deleteFlag === 0 ? data.betAmount : 0}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.afterAmount}</Label>),
              data => (
                <>
                  <Label>{`${data.returnValue || 0}%/${data?.memReturn || 0}`}</Label>
                </>
              ),
              data => (<Label className={data.deleteFlag === 1 ? 'positiveValue' : (data.memAmount >= 0 ? 'positiveValue' : 'negativeValue')}>{data.deleteFlag === 1 ? '未结算' : data.memAmount}</Label>),
              data => (<Label>{`${data.zc}%`}</Label>),
              data => (<Label className={data.totalResult >= 0 ? 'positiveValue' : 'negativeValue'}>{data.totalResult}</Label>),
              data => (<Label>{data.remark || ''}</Label>),
              data => (<Label className={data.deleteFlag === 0 ? 'installment' : 'negativeValue'}>{data.deleteFlag === 0 ? t('NORMAL') : t('CANCEL')}</Label>),
              data => (
                <Label
                  className="lotteryName url"
                  onClick={() => onClickById((data.deleteFlag === 0 ? 1 : 0), data.id, data.lotteryType, data.installments)}
                >
                  {data.deleteFlag === 0 ? t('CANCEL') : t('REVERT')}
                </Label>
              ),
            ]
          }
          tableFooter={[[{
            value: `${t('CURRENT_PAGE_TOTAL')}: ${(betQueryResult && betQueryResult?.sum?.thisBetCount) || 0}${t('TIMES')}`,
            colspan: 7,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.thisBetAmt) || 0,
            colspan: 1,
            className: 'textRight tableSum'
          },
          {
            colspan: 2,
            className: 'tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.thisMemAmt) || 0,
            colspan: 1,
            className: 'tableSum'
          }, {
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.thisResult) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: 3,
            className: 'tableSum'
          }], [{
            value: `${t('TOTAL_COUNT')}: ${(betQueryResult && betQueryResult?.sum?.totalRecord) || 0}${t('TIMES')}`,
            colspan: 7,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.totalBetAmt) || 0,
            colspan: 1,
            className: 'textRight tableSum'
          },
          {
            colspan: 2,
            className: 'tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.totalMemAmt) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (_detailItemSum && _detailItemSum.totalResult) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: 3,
            className: 'tableSum'
          }]]}
          tableHeaderStyle={
            ['smallCol']
          }
        />
      </Row>
      <Row className="justify-center w-full relative">
        <Pagination totalRecord={(_detailItemSum && _detailItemSum?.totalRecord) || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BetRevoke);