import { StatusCodes } from 'http-status-codes';

import { SET_AGENT_REBATE, SET_BANKS, SET_BUSINESS, SET_CHECK_SETTING, SET_MEMBER_INFO, SET_PRO_CODE, SET_THIRD_TOPUP_INFO, SET_TRANSFER_FLOW_DETAIL } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setBanks = data => ({
  type: SET_BANKS,
  value: data
});

const setThirdTopupInfo = data => ({
  type: SET_THIRD_TOPUP_INFO,
  value: data
});

const setTransferFlowsDetail = data => ({
  type: SET_TRANSFER_FLOW_DETAIL,
  value: data
});

const setCheckSetting = data => ({
  type: SET_CHECK_SETTING,
  value: data
});

const setBussiness = data => ({
  type: SET_BUSINESS,
  value: data
});

const setProCode = data => ({
  type: SET_PRO_CODE,
  value: data
});

const setAgentRebate = data => ({
  type: SET_AGENT_REBATE,
  value: data
});

const setMemberInfo = data => ({
  type: SET_MEMBER_INFO,
  value: data
});


const GetBankCards = (obj, callback = () => {}) => dispatch => {
  apiService.get('Cash/GetBankCards', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(setBanks(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};


const DelBankCard = (id, obj) => dispatch => {
  apiService.post(`Cash/DelBankCard/${id}`)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          return dispatch(GetBankCards({
            memberId: obj.orgId, page: 1, rows: 15
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};


const GetTransferFlowsDetail = (obj, callback = () => {}) => dispatch => {
  apiService.get('Cash/GetTransferFlowsDetail', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(setTransferFlowsDetail(data.result || {}));
        }
      }
    }).catch(error => {
      callback(false);
      dispatch(setTransferFlowsDetail());
      if (error?.response?.data) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
    });
};

const GetCheckSetting = (memberId, callback = () => {}) => dispatch => {
  apiService.get(`Cash/GetCheckSetting/${memberId}`)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(setCheckSetting(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const SaveCheckSetting = (obj = {}, callback = () => {}) => dispatch => {
  apiService.post('Cash/SaveCheckSetting', {
    ...obj
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          dispatch(appActions.updateModalAlert({
            visible: false
          }));
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
          return dispatch(GetCheckSetting(obj.mid));
        }
      }
    }).catch(error => {
      callback(false);
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const GetBusiness = (loginIds, type, callback = () => {}) => dispatch => {
  apiService.get('Cash/GetBusiness', new URLSearchParams({
    loginIds, type
  }))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return callback(data.result || []);
        }
      }
    }).catch(error => {
      callback([]);
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const SaveBusiness = (obj, callback = () => {}) => dispatch => {
  apiService.post('Cash/SaveBusiness', obj)
    .then(res => {
      dispatch(appActions.updateModalAlert({
        visible: false,
      }));
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
          return dispatch(GetBusiness(obj.loginIds, obj.type));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false,
      }));
      callback(false);
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const GetProCode = (obj, callback = () => {}) => dispatch => {
  apiService.get('Cash/GetProCode', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(setProCode(data.result || {}));
        }
      }
    }).catch(error => {
      callback(false);
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      dispatch(setBussiness([]));
      Promise.reject(error);
    });
};

const SaveProCode = (obj, callback = () => {}) => dispatch => {
  apiService.post('Cash/SaveProCode', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
      }
    }).catch(error => {
      callback(false);
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};


const DelProCode = (id, callback = () => {}) => dispatch => {
  apiService.post(`Cash/DelProCode/${id}`)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
      }
    }).catch(error => {
      callback(false);
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const BatchDelProCodes = (ls, callback = () => {}) => dispatch => {
  apiService.post('Cash/BatchDelProCodes', ls)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
      }
    }).catch(error => {
      callback(false);
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const GetAgentCommission = (obj, callback = () => {}) => dispatch => {
  apiService.get('Cash/GetAgentCommission', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(setAgentRebate(data.result || {}));
        }
      }
    }).catch(error => {
      callback(false);
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      dispatch(setAgentRebate());
      Promise.reject(error);
    });
};

const SaveSingleAgentComm = (obj, callback = () => {}) => dispatch => {
  apiService.post('Cash/SaveSingleAgentComm', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
              onConfirmationClick: callback
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const SaveMultiAgentComm = (obj, callback = () => {}) => dispatch => {
  apiService.post('Cash/SaveMultiAgentComm', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const SaveUserBankCard = (obj, callback = () => {}) => dispatch => {
  apiService.post('Cash/SaveUserBankCard', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const GetCashMemberInfo = () => dispatch => {
  apiService.get('Cash/GetCashMemberInfo')
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setMemberInfo(data.result.data || {}));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      dispatch(setMemberInfo());
      Promise.reject(error);
    });
};

const SetWithdrawPW = (obj, callback = () => {}) => dispatch => {
  apiService.post('Cash/SetWithdrawPW', obj)
    .then(res => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalMessage(error.response.data.msg));
      Promise.reject(error);
    });
};

const SendBindPhoneCode = (obj, callback = () => {}) => dispatch => {
  apiService.post(`Cash/SendBindPhoneCode/${obj}`)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalMessage(data.msg));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalMessage(error.response.data.msg));
      Promise.reject(error);
    });
};

const CheckBindPhoneCode = (obj, callback = () => {}) => dispatch => {
  apiService.post(`Cash/CheckBindPhoneCode/${obj}`)
    .then(res => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalMessage(error.response.data.msg));
      Promise.reject(error);
    });
};


const SendPhoneVerificationCode = (callback = () => {}) => dispatch => {
  apiService.get('Cash/SendPhoneVerificationCode')
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalMessage(data.msg));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalMessage(error.response.data.msg));
      Promise.reject(error);
    });
};

const CheckPhoneRemoveCode = (obj, callback = () => {}) => dispatch => {
  apiService.post(`Cash/CheckPhoneRemoveCode/${obj}`)
    .then(res => {
      const { data, status } = res || {};
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalMessage(error.response.data.msg));
      Promise.reject(error);
    });
};

const SendEmailVerificationCode = (obj, callback = () => {}) => dispatch => {
  apiService.post(`Cash/SendEmailVerificationCode/${obj}`)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalMessage(error.response.data.msg));
      Promise.reject(error);
    });
};

const CheckEmailVerificationCode = (obj, callback = () => {}) => dispatch => {
  apiService.post(`Cash/CheckEmailVerificationCode/${obj}`)
    .then(res => {
      const { data, status } = res || {};
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalMessage(error.response.data.msg));
      Promise.reject(error);
    });
};


const SendEmailRemoveCode = (callback = () => {}) => dispatch => {
  apiService.get('Cash/SendEmailRemoveCode')
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalMessage(data.msg));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalMessage(error.response.data.msg));
      Promise.reject(error);
    });
};

const CheckEmailRemoveCode = (obj, callback = () => {}) => dispatch => {
  apiService.post(`Cash/CheckEmailRemoveCode/${obj}`)
    .then(res => {
      const { data, status } = res || {};
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalMessage(error.response.data.msg));
      Promise.reject(error);
    });
};

const SaveCashMemberSetting = (obj, callback = () => {}) => dispatch => {
  apiService.post('Cash/SaveCashMemberSetting', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const GetThirdTopupInfo = (obj, callback = () => {}) => dispatch => {
  apiService.get('Cash/GetThirdTopupInfo', new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(setThirdTopupInfo(data.result));
        }
      }
    }).catch(error => {
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      dispatch(setThirdTopupInfo());
      Promise.reject(error);
    });
};

const CopyThirdTopupInfo = (obj, callback = () => {}) => dispatch => {
  dispatch(appActions.updateModalAlert({
    visible: false
  }));
  apiService.post('Cash/CopyThirdTopupInfo', obj)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg
            }
          }));
        }
      }
    }).catch(error => {
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      Promise.reject(error);
    });
};

const CancelOrder = (TransferID, callback = () => {}) => dispatch => {
  apiService.post(`Cash/CancelOrder/${TransferID}`)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback();
          return dispatch(appActions.updateModalAlert({
            visible: true,
            type: 'success',
            data: {
              message: data.msg,
            }
          }));
        }
      }
    }).catch(error => {
      if (error.response.data.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg,
            showCancelButton: false
          }
        }));
      }
      Promise.reject(error);
    });
};

export default {
  GetBankCards,
  DelBankCard,
  GetTransferFlowsDetail,
  GetCheckSetting,
  SaveCheckSetting,
  GetBusiness,
  SaveBusiness,
  GetProCode,
  SaveProCode,
  DelProCode,
  BatchDelProCodes,
  GetAgentCommission,
  SaveSingleAgentComm,
  SaveMultiAgentComm,
  SaveUserBankCard,
  GetCashMemberInfo,
  SetWithdrawPW,
  SendBindPhoneCode,
  CheckBindPhoneCode,
  SendPhoneVerificationCode,
  CheckPhoneRemoveCode,
  SendEmailVerificationCode,
  CheckEmailVerificationCode,
  SendEmailRemoveCode,
  CheckEmailRemoveCode,
  SaveCashMemberSetting,
  GetThirdTopupInfo,
  CopyThirdTopupInfo,
  CancelOrder
};