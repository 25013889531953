import React from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CommonHeader } from '../../components';

import CompanyOddsDiff from '../../components/OddsManage/CompanyOddsDiff';
import CurrentOddsSetting from '../../components/OddsManage/CurrentOddsSetting';
import DecreaseOddsSetting from '../../components/OddsManage/DecreaseOddsSetting';
import DefaultOddsSetting from '../../components/OddsManage/DefaultOddsSetting';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
  userData: state.memberReducers.userData,
});


const ReportManagement = ({ selectedSubMenu }) => {
  const { t } = useTranslation();

  const renderItems = () => {
    switch (selectedSubMenu) {
      case 'OM_CURRENT_ODDS': return (
        <>
          <CurrentOddsSetting key="OM_CURRENT_ODDS" />
        </>
      );
      case 'OM_DEFAULT_ODDS': return (
        <>
          <DefaultOddsSetting key="OM_DEFAULT_ODDS" />
        </>
      );
      case 'OM_DECREASE_ODDS_SETTING': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <DecreaseOddsSetting key="OM_DECREASE_ODDS_SETTING" />
        </>
      );
      case 'OM_COMPANY_RETURN': return (
        <>
          <CommonHeader
            headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
          />
          <CompanyOddsDiff key="OM_COMPANY_RETURN" />
        </>
      );
      default:
        break;
    }
  };

  return (
    <div className="oddsManageWrapper p-3">
      {renderItems()}
    </div>
  );
};

export default connect(mapStateToProps, null)(ReportManagement);