import React, { useEffect, useState } from 'react';

import { Button, Col, Row, Table } from 'reactstrap';
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, topUpActions } from '../../../redux/actions';
import Icon from '../../Icon';
import Label from '../../Label';
import { ReactComponent as Tick } from '../../../assets/image/icon_tick.svg';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  checkStatus: transferId => dispatch(topUpActions.checkStatus(transferId)),
  verifyTopUp: transferId => dispatch(topUpActions.verify(transferId)),
  cancelTopUp: transferId => dispatch(topUpActions.cancel(transferId)),
});

const TopUpPanelModal = ({ updateModalAlert, checkStatus, depositResult, verifyTopUp, cancelTopUp }) => {
  const { t } = useTranslation();
  const [_depositResult, setDepositResult] = useState();

  useEffect(() => {
    if (JSON.stringify(depositResult) !== JSON.stringify(_depositResult)) {
      setDepositResult(depositResult.result);
    }
  }, [depositResult]);

  useEffect(() => {
    const tick = () => {
      if (_depositResult && _depositResult.transferID) {
        checkStatus(_depositResult.transferID);
      }
    };
    const id = setInterval(tick, 5000);
    return () => {
      clearInterval(id);
    };
  }, [_depositResult]);

  const onCancelTopUpClick = () => {
    if (_depositResult && _depositResult.transferID) {
      cancelTopUp(_depositResult.transferID);
    }
    updateModalAlert({
      visible: false,
    });
  };

  const onCompleteTopUpClick = () => {
    if (_depositResult && _depositResult.dirHost) {
      if (_depositResult.bankCode && (_depositResult.bankCode === 'USDT_ERC_20' || _depositResult.bankCode === 'USDT_TRC_20')) {
        updateModalAlert({
          visible: false
        });
      }
      const link = _depositResult.transferID ? `${_depositResult.dirHost}/cash/goToPay?transferID=${_depositResult.transferID}` : _depositResult.dirHost;
      return window.open(link, '_blank');
    }
    return updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: t('ARE_YOU_SURE_YOUR_TOP_UP_HAS_SUCCEED'),
        onConfirmationClick: () => {
          updateModalAlert({
            visible: false,
          });
          verifyTopUp(_depositResult.transferID);
        },
        onCancelClick: () => {
          updateModalAlert({
            visible: true,
            type: 'topup',
            data: depositResult
          });
        }
      }
    });
  };

  return (
    <div className="topUpPanelWrapperWeb">
      <Row className="modalHeader">
        <Col
          className="d-flex align-items-center"
        >
          {t('ORDER_ID_GENERATED')}
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <Icon
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
            libName="io"
            value={{
              name: 'IoClose',
              className: 'h4 mb-0 float-right closeIcon',
            }}
          />
        </Col>
      </Row>
      <Row className="modalContent">
        <Row className="mx-0">
          <Col className="successMessage py-2 my-3">
            <Label value={depositResult && depositResult.msg} />
          </Col>
        </Row>
        {
          _depositResult && _depositResult.bankCode && (_depositResult.bankCode.includes('QrCode') || _depositResult.bankCode.includes('USDT')) && (
            <div className="mb-3 mx-0 text-center">
              <QRCode value={_depositResult.bankAddress} />
            </div>
          )
        }
        {
          _depositResult && !_depositResult.bankCode && (
            <Row className="my-3 mx-0">
              <Tick className="successTick" />
            </Row>
          )
        }
        {_depositResult && _depositResult.orderID && !_depositResult.bankCode && (
        <Row className="my-2 orderIdWrapper mx-0">
          <Label className="text-center" value={`${t('ORDER_ID')}: ${_depositResult && _depositResult.orderID}`} />
        </Row>
        )}
        <Row className="mx-0">
          <Table className="detailTable ">
            <tbody>
              {
                _depositResult && _depositResult.bankCode && (
                  <>
                    {
                      _depositResult && _depositResult.bankCode && !_depositResult.bankCode.includes('QrCode') && (
                        <tr>
                          <td className="label">
                            <Label value={`${_depositResult.bankCode === 'BOUSDTTRC20' ? t('WALLET_NAME') : t('TOP_UP_ISSUEING_BANK')}`} />
                          </td>
                          <td className="value">
                            <Label value={_depositResult && _depositResult.bankName} />
                          </td>
                        </tr>
                      )
                    }
                    <tr>
                      <td className="label">
                        <Label value={`${_depositResult.bankCode === 'BOUSDTTRC20' ? t('WALLET_ADDRESS') : t('TOP_UP_BANK_NUM')}`} />
                      </td>
                      <td className="value">
                        <Label value={_depositResult && (_depositResult.bankCode === 'BOUSDTTRC20' ? _depositResult.bankAddress : _depositResult.bankNumber)} />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <Label value={_depositResult && _depositResult.bankCode && _depositResult.bankCode.includes('QrCode') ? t('SCAN_ENDPOINT') : t('TOP_UP_NAME')} />
                      </td>
                      <td className="value">
                        <Label value={_depositResult && _depositResult.bankUName} />
                      </td>
                    </tr>
                    {
                      _depositResult && _depositResult.bankCode && !_depositResult.bankCode.includes('QrCode') && _depositResult.bankCode !== 'BOUSDTTRC20' && (
                        <tr>
                          <td className="label">
                            <Label value={t('BANK_CARD_ADDRESS')} />
                          </td>
                          <td className="value">
                            <Label value={_depositResult && _depositResult.bankAddress} />
                          </td>
                        </tr>
                      )
                    }
                    {
                      _depositResult.bankCode !== 'BOUSDTTRC20' && (
                        <tr>
                          <td className="label">
                            <Label value={t('ACCOUNT_KEYCODE')} />
                          </td>
                          <td className="value">
                            <Label value={_depositResult && _depositResult.acKeyCode} />
                          </td>
                        </tr>
                      )
                    }
                    {
                      _depositResult.bankCode === 'BOUSDTTRC20' && (
                        <tr>
                          <td className="label">
                            <Label value={t('TRANSACTION_QUANTITY')} />
                          </td>
                          <td className="value">
                            <Label value={`${_depositResult && _depositResult.actionAmountExt} (${t('EXCHANGE_RATE')}:${_depositResult && _depositResult.actionAmountExtConvertValue}, ${t('PRESET_AMOUNT')}:${_depositResult && _depositResult.actionAmount})`} />
                          </td>
                        </tr>
                      )
                    }
                  </>
                )
              }
            </tbody>
          </Table>
          {_depositResult && _depositResult?.acKeyCode
          && (
          <Row className="attentionMessage mx-0">
            <div className="p-0 d-flex justify-content-center align-items-center w-25">
              <Label value={`${t('ATTENTION')}:`} />
            </div>
            <div className="p-0 text-center d-flex justify-content-center align-items-center w-75">
              <Label className="attentionText" value={`${t('TOP_UP_ATTENTION_TEXT')}`} />
            </div>
          </Row>
          )}
        </Row>
        <Row className="mx-0">
          <Col className="reminderMessage py-2 my-3">
            <Label value={t('TOP_UP_REMINDER')} />
          </Col>
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button className="completeTopUpButton my-3 me-3" onClick={onCompleteTopUpClick}>{t('COMPLETE_TOP_UP')}</Button>
        {_depositResult && (
        <Button className="cancelButton my-3" onClick={onCancelTopUpClick}>{t('CANCEL_TOP_UP_ORDER')}</Button>
        )}
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TopUpPanelModal);