import React, { useEffect, useState } from 'react';

import { Input, Select } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Icon, Pagination } from '../..';
import { contestActions } from '../../../redux/actions';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  gift: state.contestReducers.gift,
});

const mapDispatchToProps = dispatch => ({
  GetGift: (obj, callback) => dispatch(contestActions.GetGift(obj, callback)),
  SaveEditGift: (obj, callback) => dispatch(contestActions.SaveEditGift(obj, callback)),
  SaveNewGift: (obj, callback) => dispatch(contestActions.SaveNewGift(obj, callback)),
  DelGift: (id, callback) => dispatch(contestActions.DelGift(id, callback)),
  EnableGift: (id, callback) => dispatch(contestActions.EnableGift(id, callback)),
});

const GiftSetting = ({ gift, GetGift, SaveEditGift, SaveNewGift, EnableGift, DelGift }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [giftEnable, setgiftEnable] = useState(-1);
  const [giftType, setgiftType] = useState(-1);
  const [orderNo, setorderNo] = useState();
  const [giftLevel, setgiftLevel] = useState(-1);

  const [_detail, setDetail] = useState();

  const [giftTitle, setgiftTitle] = useState('');
  const [giftDesc, setgiftDesc] = useState('');
  const [applyEnable, setapplyEnable] = useState();
  const [imgSrc, setimgSrc] = useState();

  useEffect(() => {
    setIsQuerying(true);
    const _obj = {
      page,
      rows,
      giftEnable,
      giftType,
      giftLevel
    };
    GetGift(_obj, setIsQuerying);
  }, []);

  const saveAndEdit = () => {
    if (_pageFlag === 1) {
      const obj = {
        giftTitle,
        giftType,
        giftLevel,
        giftDesc,
        imgSrc,
        applyEnable,
        orderNo
      };
      const callback = () => {
        GetGift();
        setPageFlag(0);
      };
      SaveNewGift(obj, callback);
    }
    if (_pageFlag === 2) {
      const obj = {
        giftTitle,
        giftType,
        giftLevel,
        giftDesc,
        imgSrc,
        applyEnable,
        giftId: _detail.id,
        orderNo
      };
      const callback = () => {
        setPageFlag(0);
        GetGift();
      };
      SaveEditGift(obj, callback);
    }
  };

  const Remove = id => {
    const _obj = {
      page: 1,
      rows: 15,
      giftEnable,
      giftType,
      giftLevel
    };
    DelGift(id, () => { setIsQuerying(true); GetGift(_obj, setIsQuerying); });
  };

  const Enable = id => {
    const _obj = {
      page: 1,
      rows: 15,
      giftEnable,
      giftType,
      giftLevel
    };
    EnableGift(id, () => { setIsQuerying(true); GetGift(_obj, setIsQuerying); });
  };

  const onSearchClick = () => {
    setIsQuerying(true);
    const _obj = {
      page: 1,
      rows: 15,
      giftEnable,
      giftType,
      giftLevel
    };
    GetGift(_obj, setIsQuerying);
    setPage(1);
    setRows(15);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(true);
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      page: pageNum,
      rows,
      giftEnable,
      giftType,
      giftLevel
    };
    GetGift(_obj, setIsQuerying);
  };

  useEffect(() => {
    if (_pageFlag === 2 && _detail) {
      setgiftTitle(_detail?.giftTitle || giftTitle);
      setgiftDesc(_detail?.giftDesc || giftDesc);
      setgiftType(_detail?.giftType);
      setorderNo(_detail?.orderNo);
      setgiftLevel(_detail?.giftLevel || giftLevel);
      setapplyEnable(_detail?.applyEnable);
      setimgSrc(_detail?.imgSrc);
    } if (_pageFlag === 1) {
      setgiftTitle('');
      setgiftDesc('');
      setgiftType(1);
      setgiftLevel(1);
      setapplyEnable(1);
      setimgSrc('');
      setorderNo();
    }
    if (_pageFlag === 0) {
      setgiftTitle('');
      setgiftDesc('');
      setimgSrc('');
    }
  }, [_pageFlag, _detail]);

  return (
    <div className="GiftSettingWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
              <Icon
                libName="md"
                value={{
                  name: 'MdArrowBack',
                  className: 'h5 mb-0 text-white'
                }}
              />
              返回
            </div>
            )}
            {`${t('GIFT_SETTING')} ${_pageFlag === 0 ? '' : _pageFlag === 1 ? '/ 新增礼品' : '/ 编辑礼品'}`}
          </div>
)}
      />
      {_pageFlag === 0 && (
      <>
        <div className="addNewGameBox my-3">
          <div className="operationBtn add" onClick={() => setPageFlag(1)}>
            <div className="plus_icon"><i className="el-icon-plus" /></div>
            新增礼品
          </div>
          <div className="mx-2">
            允许兑换
            <Select className="ms-2" value={giftEnable} placeholder="请选择" onChange={code => setgiftEnable(code)}>
              {
                     [{
                       value: -1, label: '全部'
                     }, {
                       value: 0, label: '允许兑换'
                     }, {
                       value: 1, label: '暂停兑换'
                     }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                }
            </Select>
          </div>
          <div className="mx-2">
            类型
            <Select className="ms-2" value={giftType} placeholder="请选择" onChange={code => setgiftType(code)}>
              {
                     [{
                       value: -1, label: '全部'
                     }, {
                       value: 0, label: '日礼品'
                     }, {
                       value: 1, label: '周礼品'
                     }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                }
            </Select>
          </div>
          <div className="mx-2">
            等级
            <Select className="ms-2" value={giftLevel} placeholder="请选择" onChange={code => setgiftLevel(code)}>
              {
                     [{
                       value: -1, label: '全部'
                     }, {
                       value: 1, label: '第一名'
                     }, {
                       value: 2, label: '第二名'
                     }, {
                       value: 3, label: '第三名'
                     }, {
                       value: 4, label: '第四名'
                     }, {
                       value: 5, label: '第五名'
                     }, {
                       value: 6, label: '第六名'
                     }, {
                       value: 7, label: '第七名'
                     }, {
                       value: 8, label: '第八名'
                     }, {
                       value: 9, label: '第九名'
                     }, {
                       value: 10, label: '第十名'
                     }, {
                       value: 999, label: '其他'
                     }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                }
            </Select>
          </div>
          <div className="operationBtn mx-2 add" onClick={() => onSearchClick()}>查询</div>

        </div>

        <div className="mx-0 bg-white mb-3 px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: '排序',
            }, {
              label: '礼品名称',
            }, {
              label: '类型',
            }, {
              label: '等级',
            }, {
              label: '允许兑换',
            }, {
              label: t('OPERATION'),
            }]
          }
            tableHeaderStyle={
            ['smallCol']
          }
            tableData={gift ? gift.data.map((item, index) => ({
              ...item, index: index + 1
            })) : []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.index}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.orderNo}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.giftTitle}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.giftTypeName}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.giftLevel}</Label>),
              data => (<Label className={`d-flex justify-content-center ${data.applyEnable === 1 && 'text-danger'}`}>{data.applyEnable === 0 ? '启用兑换' : '暂停兑换'}</Label>),
              data => (
                <Label className="d-flex justify-content-center align-items-center">
                  <div className="operationBtn mx-2" onClick={() => { setDetail(data); setPageFlag(2); }}>编辑</div>
                  <div className="operationBtn stopAndStart mx-2" onClick={() => Enable(data.id)}>{data.applyEnable === 1 ? '启用兑换' : '暂停兑换'}</div>
                  <div className="operationBtn cancel mx-2" onClick={() => { Remove(data.id); }}>删除</div>
                </Label>
              )
            ]
          }
          />
          <div className="d-flex align-items-center justify-content-center w-full relative">
            <Pagination totalRecord={gift ? (gift.sum.totalRecord || 0) : 0} onClick={onPaginationClick} isQuery={false} />
          </div>
        </div>
      </>
      )}
      {_pageFlag !== 0 && (
        <>
          <div className="my-3 thirdPartyGamesTable">
            <div className="thirdPartyGamesTitle">{_pageFlag === 1 ? '新增礼品' : '编辑礼品'}</div>
            <div className="thirdPartyGamesRow">
              <div>礼品名称: </div>
              <div><Input value={giftTitle} onChange={text => setgiftTitle(text.trim())} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>详细说明: </div>
              <div>
                <Input
                  type="textarea"
                  autosize={{
                    minRows: 2, maxRows: 4
                  }}
                  value={giftDesc}
                  onChange={text => setgiftDesc(text.trim())}
                  placeholder="请输入内容"
                />

              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>礼品类型: </div>
              <div>
                <Select value={giftType} placeholder="请选择" onChange={code => setgiftType(code)}>
                  {
                [{
                  value: 0, label: '日礼品'
                }, {
                  value: 1, label: '周礼品'
                }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
              }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>礼品等级: </div>
              <div>
                <Select value={giftLevel} placeholder="请选择" onChange={code => setgiftLevel(code)}>
                  {
                     [{
                       value: -1, label: '全部'
                     }, {
                       value: 1, label: '第一名'
                     }, {
                       value: 2, label: '第二名'
                     }, {
                       value: 3, label: '第三名'
                     }, {
                       value: 4, label: '第四名'
                     }, {
                       value: 5, label: '第五名'
                     }, {
                       value: 6, label: '第六名'
                     }, {
                       value: 7, label: '第七名'
                     }, {
                       value: 8, label: '第八名'
                     }, {
                       value: 9, label: '第九名'
                     }, {
                       value: 10, label: '第十名'
                     }, {
                       value: 999, label: '其他'
                     }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>是否允许兑换: </div>
              <div>
                <Select value={applyEnable} placeholder="请选择" onChange={code => setapplyEnable(code)}>
                  {
                    [{
                      value: 0, label: '允许兑换'
                    }, {
                      value: 1, label: '暂停兑换'
                    }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                  }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>排序号码: </div>
              <div>
                <Input
                  value={orderNo}
                  onChange={text => {
                    if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                      setorderNo(text === '' ? 0 : parseInt(text, 10));
                    }
                  }}
                  placeholder="请输入内容"
                />
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>图片编码: </div>
              <div>
                <Input
                  type="textarea"
                  autosize={{
                    minRows: 4, maxRows: 8
                  }}
                  value={imgSrc}
                  onChange={text => {
                    setimgSrc(text);
                  }}
                  placeholder="请输入内容"
                />
              </div>
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => saveAndEdit()}>保存</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftSetting);