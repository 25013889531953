import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { Radio, Select, Tabs } from 'element-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AgentDataTable from '../../AgentDataTable';
import mainGames from '../../../constants/QuickSetVariable/Games';

import { agentActions, appActions } from '../../../redux/actions';
import Skeleton from '../../Skeleton';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  rebateSetting: state.agentReducers.rebateSetting,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getRebateSetting: (userType, orgId) => dispatch(agentActions.getRebateSetting(userType, orgId)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveRebateSetting: obj => dispatch(agentActions.saveRebateSetting(obj)),
  resetData: () => dispatch(agentActions.resetData()),
});

const LotteryRebate = ({ getRebateSetting, data, rebateSetting, updateModalAlert, saveRebateSetting, resetData }) => {
  const { t } = useTranslation();
  const [_platetInput, setPlateInput] = useState('');
  const [_selectedLotType, setSelectedLotType] = useState(2);
  const [_header, setHeader] = useState([]);
  const [_quickSetData, setQuickSetData] = useState([]);
  const [_uniqueString, setUniqueString] = useState([]);
  const [_content, setContent] = useState([]);
  const [_selectedSearchOption, setSearchOption] = useState(1);
  const [_selectedTab, setSelectedTab] = useState('');
  const [_allLotList, setAllLotList] = useState([]);
  const [_lotList, setLotList] = useState([]);
  const [_activeLotteryCode, setActiveLotteryCode] = useState();
  const [_tempData, setTempData] = useState([]);
  const [_tempQuickSetContent, setQuickSetContent] = useState([]);
  const [_userGames, setUserGames] = useState([]);
  const [_selectedMemberGame, setSelectedMemberGame] = useState();
  let bufferData = [];
  let quickSetBufferData = [];

  useEffect(() => {
    // Default search when first load
    getRebateSetting(data?.orgId ? 2 : 1, data?.orgId || data?.memberId);
  }, []);

  useEffect(() => {
    let isMounted = true;
    fetchData();
    return () => {
      resetData();
      isMounted = false;
    };
    function fetchData() {
      if (rebateSetting && rebateSetting?.memberGame && isMounted) {
        bufferData = [...rebateSetting?.listSetting.filter(x => x?.templateCode)];
        setTempData([...rebateSetting?.listSetting.filter(x => x?.templateCode)]);
        setAllLotList(rebateSetting?.lotteryList);
        const tempHead = [];
        const tempContent = [];
        const tempQuickSetContent = [];
        tempHead.push(fixHeader[0]);
        tempQuickSetContent.push(rebateQuickSetData[0]);
        tempContent.push(editDataContent[0]);
        // add according to user's games
        setUserGames(rebateSetting?.memberGame);
        Array.from(rebateSetting?.memberGame).forEach(d => {
          tempHead.push({
            label: `${d + t('PLATE')}(%)`
          });
          tempQuickSetContent.push(rebateQuickSetData[getIndex(d) || 0]);
          tempContent.push(editDataContent[getIndex(d) || 0]);
        });
        // only level 1 and higher level can use
        if (Number(rebateSetting?.userLevelValue) <= 1) {
          tempContent.push(editDataContent[5]);
          tempContent.push(editDataContent[6]);
          tempQuickSetContent.push(rebateQuickSetData[5]);
          tempQuickSetContent.push(rebateQuickSetData[6]);
          tempHead.push(fixHeader[1]);
          tempHead.push(fixHeader[2]);
        }
        tempQuickSetContent.push(rebateQuickSetData[7]);
        tempHead.push(fixHeader[3]);
        setHeader(tempHead);
        setQuickSetContent(tempQuickSetContent);
        setContent(tempContent);
        // to calculate the higest value data among the list
        const tempData = [];
        mainGames.Games.forEach(each => {
          const games = each.value === 2 ? mainGames.Games.filter(x => x.code.includes('HK6'))[0] : mainGames.Games.filter(x => !x.code.includes('HK6'))[0];
          games.data.forEach(d => {
            const filterList = (rebateSetting?.listSetting.filter(x => d?.subCode?.includes(x?.code)
              && (each.value === 2 ? (x?.templateCode ? x?.templateCode.includes('HK6') : false) : (x?.templateCode ? !x?.templateCode.includes('HK6') : false))));
            tempData.push({
              projectName: d.label,
              aReturn: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.aReturn < b.aReturn) ? 1 : -1))[0]?.aReturn,
              bReturn: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.bReturn < b.bReturn) ? 1 : -1))[0]?.bReturn,
              cReturn: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.cReturn < b.cReturn) ? 1 : -1))[0]?.cReturn,
              dReturn: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.dReturn < b.dReturn) ? 1 : -1))[0]?.dReturn,
              projectLimit: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.projectLimit < b.projectLimit) ? 1 : -1))[0]?.projectLimit,
              itemLimit: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.itemLimit < b.itemLimit) ? 1 : -1))[0]?.itemLimit,
              subCode: d.subCode,
              templates: games.code,
              ind: tempData.length,
              lotType: each.value,
            });
            if (filterList.length === 0 && each.value === 2 && each.value !== 3) {
              setSelectedLotType(1);
            }
          });
        });
        setQuickSetData(tempData);
        quickSetBufferData = tempData;
        loadData(_selectedLotType);
      }
    }
  }, [rebateSetting]);

  useEffect(() => {
    if (_quickSetData && _tempData) {
      loadData(_selectedLotType);
    }
  }, [_selectedLotType]);

  useEffect(() => {
    setActiveLotteryCode(_lotList?.filter(x => x.templateCode === _selectedTab)[0]?.code);
  }, [_selectedTab]);

  useEffect(() => {
    if (bufferData) {
      bufferData = _tempData;
    }
  }, [_tempData]);

  useEffect(() => {
    if (quickSetBufferData) {
      quickSetBufferData = _quickSetData;
    }
  }, [_quickSetData]);

  const loadData = lotType => {
    setUniqueString(mainGames.Games.filter(x => x.value === _selectedLotType)[0].name);
    setSelectedTab(mainGames.Games.filter(x => x.value === _selectedLotType)[0].code[0]);
    if (rebateSetting || _allLotList) {
      const tempList = rebateSetting?.lotteryList || _allLotList;
      let list = [];
      if (lotType === 2) {
        list = tempList.filter(x => x.templateCode === 'HK6' && x.templateCode !== 'F31X7' && x.templateCode !== 'F36X7');
      } else if (lotType === 3) {
        list = tempList.filter(x => x.templateCode === 'F31X7' || x.templateCode === 'F36X7');
      } else if (lotType === 1) {
        list = tempList.filter(x => (x?.templateCode ? (x?.templateCode !== 'HK6' && x.templateCode !== 'F31X7' && x.templateCode !== 'F36X7') : true));
      }
      setLotList(list);
      setActiveLotteryCode(list[0]?.code);
    }
  };

  const onValueChange = (event, data, field, maxValue) => {
    let newItems = [];
    newItems = [...bufferData];
    const cb = document.querySelector(`#chkConsolidate_${data.templateCode}`);
    const valToChange = Number(event.target.value) > maxValue ? maxValue : Number(event.target.value);
    if (bufferData && valToChange && cb.checked && newItems) {
      bufferData.forEach((curr, myInd) => {
        if (curr.code === data.code && curr.templateCode === data.templateCode) {
          newItems[myInd][field] = valToChange > curr.parentSetting[field] ? curr.parentSetting[field] : valToChange;
          newItems[myInd].isChange = valToChange < curr.parentSetting[field];
          newItems = addHighLightField(newItems, field, myInd);
        }
      });
      setTempData(newItems);
    } else if (bufferData && newItems && valToChange) {
      const index = Number(bufferData?.findIndex(item => item.id === data.id));
      if (index !== -1) {
        newItems[index][field] = valToChange;
        newItems = addHighLightField(newItems, field, index);
        newItems[index].isChange = true;
        setTempData(newItems);
      }
    }
  };

  const editQS = (e, index, field) => {
    let newItems = [];
    newItems = [...quickSetBufferData];
    const valToChange = Number(e.target.value);
    if (index !== -1 && newItems.length > 0) {
      newItems[index][field] = valToChange;
      newItems = addHighLightField(newItems, field, index);
      setQuickSetData(newItems);
    }
  };

  const editAllDataFromQS = (subCode, index, templateCode) => {
    const newProjects = [...bufferData];
    newProjects?.forEach((curr, myInd) => {
      if (subCode?.includes(curr.code) && templateCode.includes(curr.templateCode)) {
        newProjects[myInd].aReturn = Number(quickSetBufferData[index].aReturn) > curr.parentSetting.aReturn ? curr.parentSetting.aReturn : Number(quickSetBufferData[index].aReturn);
        newProjects[myInd].bReturn = Number(quickSetBufferData[index].bReturn) > curr.parentSetting.bReturn ? curr.parentSetting.bReturn : Number(quickSetBufferData[index].bReturn);
        newProjects[myInd].cReturn = Number(quickSetBufferData[index].cReturn) > curr.parentSetting.cReturn ? curr.parentSetting.cReturn : Number(quickSetBufferData[index].cReturn);
        newProjects[myInd].dReturn = Number(quickSetBufferData[index].dReturn) > curr.parentSetting.dReturn ? curr.parentSetting.dReturn : Number(quickSetBufferData[index].dReturn);
        newProjects[myInd].itemLimit = Number(quickSetBufferData[index].itemLimit) > curr.parentSetting.itemLimit ? curr.parentSetting.itemLimit : Number(quickSetBufferData[index].itemLimit);
        newProjects[myInd].projectLimit = Number(quickSetBufferData[index].projectLimit) > curr.parentSetting.projectLimit ? curr.parentSetting.projectLimit : Number(quickSetBufferData[index].projectLimit);
        newProjects[myInd].isChangeA = Number(quickSetBufferData[index].aReturn) < curr.parentSetting.aReturn;
        newProjects[myInd].isChangeB = Number(quickSetBufferData[index].bReturn) < curr.parentSetting.bReturn;
        newProjects[myInd].isChangeC = Number(quickSetBufferData[index].cReturn) < curr.parentSetting.cReturn;
        newProjects[myInd].isChangeD = Number(quickSetBufferData[index].dReturn) < curr.parentSetting.dReturn;
        newProjects[myInd].isChangeF = Number(quickSetBufferData[index].itemLimit) < curr.parentSetting.itemLimit;
        newProjects[myInd].isChangeE = Number(quickSetBufferData[index].projectLimit) < curr.parentSetting.projectLimit;
        newProjects[myInd].isChange = true;
      }
    });
    setTempData(newProjects);
  };

  const saveButton = () => {
    const res = _tempData.filter(item => item?.isChange === true && item?.templateCode);
    if (res.length > 0) {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const obj = {
        orgId: data?.orgId,
        settingList: res,
        memberType: data?.orgId ? 2 : 1,
        simultaneousSave: _selectedSearchOption,
        lotteryType: _selectedLotType
      };
      saveRebateSetting(obj);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '没有变更'
        }
      });
    }
  };

  const resetDefault = () => {
    getRebateSetting(data?.orgId ? 2 : 1, data?.orgId);
  };

  const setAllPlate = () => {
    if (_selectedMemberGame) {
      const newProjects = [..._tempData];
      const field = `${_selectedMemberGame?.toLowerCase()}Return`;
      _tempData.forEach((curr, myInd) => {
        const after = Number(curr.parentSetting[field]) - Math.abs(Number(_platetInput));
        if (_selectedLotType === 2 ? curr.templateCode === 'HK6' : curr.templateCode !== 'HK6') {
          if (after > 0) {
            newProjects[myInd][field] = after;
            newProjects[myInd].isChange = true;
            addHighLightField(newProjects, field, myInd);
          }
        }
      });
      setTempData(newProjects);
    }
  };

  const fixHeader = [{
    label: '项目名称'
  },
  {
    label: '注单限额'
  },
  {
    label: '单期限额'
  },
  {
    label: t('EDIT')
  }];

  const rdSearchOptions = [{
    label: '不增加',
    value: 1,
  },
  {
    label: '只代理',
    value: 2,
  },
  {
    label: '代理和会员',
    value: 3,
  }];

  function addHighLightField(item, field, index) {
    switch (field) {
      case 'aReturn':
        item[index].isChangeA = true;
        break;
      case 'bReturn':
        item[index].isChangeB = true;
        break;
      case 'cReturn':
        item[index].isChangeC = true;
        break;
      case 'dReturn':
        item[index].isChangeD = true;
        break;
      case 'projectLimit':
        item[index].isChangeE = true;
        break;
      case 'itemLimit':
        item[index].isChangeF = true;
        break;
      default: break;
    }
    return item;
  }

  function getIndex(game) {
    switch (game) {
      case 'A':
        return 1;
      case 'B':
        return 2;
      case 'C':
        return 3;
      case 'D':
        return 4;
      default: break;
    }
  }

  const rebateQuickSetData = [
    data => (<Label className="d-flex justify-content-center">{data?.projectName}</Label>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeA && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'aReturn')} value={data?.aReturn} /></div>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeB && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'bReturn')} value={data?.bReturn} /></div>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeC && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'cReturn')} value={data?.cReturn} /></div>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeD && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'dReturn')} value={data?.dReturn} /></div>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeF && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'itemLimit')} value={data?.itemLimit} /></div>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeE && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'projectLimit')} value={data?.projectLimit} /></div>),
    data => (<Button className="buttonControl edit" onClick={() => editAllDataFromQS(data?.subCode, data?.ind, data?.templates)}>{t('EDIT')}</Button>),
  ];

  const editDataContent = [
    data => (<Label className="d-flex justify-content-center">{data.projectName}</Label>),
    data => (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isChangeA && 'isChange'}`} name={data?.code} onChange={e => onValueChange(e, data, 'aReturn', data?.parentSetting && data?.parentSetting.aReturn)} type="Number" value={data?.aReturn} />
        {data?.parentSetting && (
          <Label>{`(${data?.parentSetting.aReturn})`}</Label>
        )}
      </div>
    ),
    data => (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isChangeB && 'isChange'}`} name={data?.code} onChange={e => onValueChange(e, data, 'bReturn', data?.parentSetting && data?.parentSetting.bReturn)} type="Number" value={data?.bReturn} />
        {data?.parentSetting && (
          <Label>{`(${data?.parentSetting.bReturn})`}</Label>
        )}
      </div>
    ),
    data => (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isChangeC && 'isChange'}`} name={data?.code} onChange={e => onValueChange(e, data, 'cReturn', data?.parentSetting && data?.parentSetting.cReturn)} type="Number" value={data?.cReturn} />
        {data?.parentSetting && (
          <Label>{`(${data?.parentSetting.cReturn})`}</Label>
        )}
      </div>
    ),
    data => (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isChangeD && 'isChange'}`} name={data?.code} onChange={e => onValueChange(e, data, 'dReturn', data?.parentSetting && data?.parentSetting.dReturn)} type="Number" value={data?.dReturn} />
        {data?.parentSetting && (
          <Label>{`(${data?.parentSetting.dReturn})`}</Label>
        )}
      </div>
    ),
    data => (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isChangeF && 'isChange'}`} name={data?.code} onChange={e => onValueChange(e, data, 'itemLimit', data?.parentSetting && data?.parentSetting.itemLimit)} type="Number" value={data?.itemLimit} />
        {data?.parentSetting && (
          <Label>{`(${data?.parentSetting.itemLimit})`}</Label>
        )}
      </div>
    ),
    data => (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isChangeE && 'isChange'}`} name={data?.code} onChange={e => onValueChange(e, data, 'projectLimit', data?.parentSetting && data?.parentSetting.projectLimit)} type="Number" value={data?.projectLimit} />
        {data?.parentSetting && (
          <Label>{`(${data?.parentSetting.projectLimit})`}</Label>
        )}
      </div>
    )
  ];

  return (
    <Col className="LotteryRebateWrapper p-0 m-0">
      <Row className="m-0 p-0 my-2">
        <Col className="col-2 px-1 m-0 lotteryMenu">
          {
            _allLotList.length <= 0 ? (
              <Skeleton isAnimated />
            ) : (
              <div
                className="px-2 d-flex radiobuttongroup"
              >
                {
                  _allLotList && mainGames.Games.map(item => _allLotList && _allLotList.filter(x => item.code.includes(x.templateCode)).length > 0 && (
                    <>
                      <Radio
                        key={`games_${item?.displayName}`}
                        value={item?.value}
                        type="radio"
                        name="rdGames"
                        checked={Number(_selectedLotType) === Number(item.value)}
                        onChange={value => setSelectedLotType(value)}
                      >
                        {item?.displayName}
                      </Radio>
                    </>
                  ))
                }
              </div>
            )
          }
        </Col>
        <Col className="p-1 m-0 filterWrapper">
          <div className="d-flex">
            <Label className="justify-content-center p-1">赚取退水：</Label>
            <Select
              value={_selectedMemberGame}
              placeholder="请选择"
              onChange={item => setSelectedMemberGame(item)}
            >
              {
                _userGames && Array.from(_userGames).map(d => (
                  <Select.Option key={`setSelectedMemberGame_${d}`} label={`${d}${t('PLATE')}`} value={d} />
                ))
              }
            </Select>
            <Input className="mx-2" type="number" value={_platetInput} onChange={({ target: { value: text } }) => setPlateInput(text)} />
            <Button className="button generate mx-1" onClick={() => setAllPlate()}>{t('CONFIRM')}</Button>
            <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
            <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
            <Label className="justify-content-center marginLeft5 p-1">保存时下线是否等量增加：</Label>
            <div
              className="d-flex px-2 py-1 rdSearchOptionsGroup"
            >
              {
                rdSearchOptions.map(item => (
                  <div key={`rdSearchOptionsGroup_${item.value}`}>
                    <Input
                      id={`rdSearchOptions_${item.value}`}
                      type="radio"
                      name="rdSearchOptions"
                      value={item.value}
                      checked={_selectedSearchOption === item.value}
                      onChange={() => setSearchOption(item.value)}
                      className="mx-2"
                    />
                    <Label
                      for={`rdSearchOptions_${item.value}`}
                      className={_selectedSearchOption === item.value ? 'active' : ''}
                    >
                      {item.label}
                    </Label>
                  </div>
                ))
              }
            </div>
          </div>
        </Col>
      </Row>
      <Row className="bigWrapper m-0 my-2 p-2">
        {
          _allLotList.length <= 0 ? (
            <AgentDataTable
              isQuerying
              tableHeaderTitle={fixHeader || []}
              tableData={[1, 2, 3, 4, 5]}
              tableBodyRowProperty={[1, 2, 3, 4]}
              tableBodyRowStyle={
                ['backgroundGrey']
              }
            />
          ) : (
            <AgentDataTable
              isQuerying={_allLotList.length <= 0}
              tableHeaderTitle={_header || []}
              tableData={_quickSetData.filter(y => y?.lotType === _selectedLotType) || []}
              tableBodyRowProperty={_tempQuickSetContent || []}
              tableBodyRowStyle={
                ['backgroundGrey']
              }
              tableHeaderStyle={
                ['col-2']
              }
            />
          )
        }
      </Row>
      <Row className="m-0 p-0">
        <Tabs className="m-0 p-0" activeName={_selectedTab} onTabClick={tab => setSelectedTab(tab.props.name || '')}>
          {
            _uniqueString && _uniqueString.map(item => _lotList.filter(y => y?.templateCode === item.value).length > 0 && (
              <Tabs.Pane key={`maintype_${item.label}_${item.value}`} label={item.label} name={item.value}>
                <Row className="">
                  <Col className="d-flex mx-2 px-1">
                    {_lotList.filter(x => x.templateCode === item.value).map((x, index) => (
                      <Button
                        type="button"
                        key={`buttonSubCode_${x.code}_${x.lotteryName}`}
                        className={`px-3 py-1 subLotteryBtn ${_activeLotteryCode === x.code ? 'active' : ''} ${Number(_lotList.filter(y => y.templateCode === item.value).length) === 1 ? 'onlyOne' : (index === 0 ? 'first' : (index + 1 === Number(_lotList.filter(y => y.templateCode === item.value).length) ? 'last' : ''))}`}
                        onClick={() => setActiveLotteryCode(x.code)}
                      >
                        {x.lotteryName}
                      </Button>
                    ))}
                    <Input
                      id={`chkConsolidate_${item.value}`}
                      type="checkbox"
                      className="m-2 p-2"
                    />
                    <Label
                      for={`chkConsolidate_${item.value}`}
                      className="py-2"
                    >
                      统一修改
                    </Label>
                  </Col>
                </Row>
                {
                  _tempData && _tempData.filter(x => _selectedTab === x.templateCode && x.lotteryType === _activeLotteryCode) && _selectedTab === item.value && (
                    <Row className="bigWrapper m-0 my-2 p-0">
                      <Col className="p-2">
                        <AgentDataTable
                          tableHeaderTitle={_header && _header?.slice(0, _header.length - 1)}
                          tableData={_tempData.filter(x => _selectedTab === x.templateCode && x.lotteryType === _activeLotteryCode)?.slice(0, Math.floor(_tempData.filter(x => _selectedTab === x.templateCode && x.lotteryType === _activeLotteryCode)?.length / 2)) || []}
                          tableBodyRowProperty={_content}
                          tableBodyRowStyle={
                            ['backgroundGrey']
                          }
                          tableHeaderStyle={
                            ['col-2']
                          }
                        />
                      </Col>
                      <Col className="p-2">
                        <AgentDataTable
                          tableHeaderTitle={_header && _header?.slice(0, _header.length - 1)}
                          tableData={_tempData.filter(x => _selectedTab === x.templateCode && x.lotteryType === _activeLotteryCode)?.slice(Math.floor(_tempData.filter(x => _selectedTab === x.templateCode && x.lotteryType === _activeLotteryCode)?.length / 2, _tempData.filter(x => _selectedTab === x.templateCode && x.lotteryType === _activeLotteryCode)?.length)) || []}
                          tableBodyRowProperty={_content}
                          tableBodyRowStyle={
                            ['backgroundGrey']
                          }
                          tableHeaderStyle={
                            ['col-2']
                          }
                        />
                      </Col>
                    </Row>
                  )
                }
              </Tabs.Pane>
            ))
          }
          <Tabs.Pane className="none" label="" name="" />
        </Tabs>
      </Row>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotteryRebate);