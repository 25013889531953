

import { ReactComponent as ATMCard } from '../../assets/image/TopUpIcons/pay_atmcard.svg';
import { ReactComponent as AliPay } from '../../assets/image/TopUpIcons/pay_alipay.svg';
import { ReactComponent as Crypto } from '../../assets/image/TopUpIcons/pay_crypto.svg';
import { ReactComponent as IBank } from '../../assets/image/TopUpIcons/pay_ibank.svg';
import { ReactComponent as Offline } from '../../assets/image/TopUpIcons/pay_offline.svg';
import { ReactComponent as Others } from '../../assets/image/TopUpIcons/pay_others.svg';
import { ReactComponent as QQ } from '../../assets/image/TopUpIcons/pay_qq.svg';
import { ReactComponent as UnionPay } from '../../assets/image/TopUpIcons/pay_unionpay.svg';
import { ReactComponent as Wechat } from '../../assets/image/TopUpIcons/pay_wechat.svg';
import { ReactComponent as YunShanFu } from '../../assets/image/TopUpIcons/pay_yunshanfu.svg';

export default {
  ATMCard,
  AliPay,
  Crypto,
  IBank,
  Offline,
  Others,
  QQ,
  UnionPay,
  Wechat,
  YunShanFu,
};