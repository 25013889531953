import React, { useEffect, useState } from 'react';

import { Input } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Icon, Pagination } from '../..';
import { whiteListActions } from '../../../redux/actions';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  company: state.whiteListReducers.company,
  editWhite: state.whiteListReducers.editWhite,
  subAccountList: state.whiteListReducers.subAccountList,
  superUser: state.whiteListReducers.superUser,
});
const mapDispatchToProps = dispatch => ({
  getInfo: (obj, callback) => dispatch(whiteListActions.getInfo(obj, callback)),
  SaveInfo: (obj, callback) => dispatch(whiteListActions.SaveInfo(obj, callback)),
  getEditInfo: (obj, callback) => dispatch(whiteListActions.EditInfo(obj, callback)),
  getSubAccountList: (loginId, callback) => dispatch(whiteListActions.getSubAccountList(loginId, callback)),
  getSuperUser: callback => dispatch(whiteListActions.getSuperUser(callback)),
});

const WhitelistManagement = ({ company, getInfo, SaveInfo, editWhite, getEditInfo, getSubAccountList, subAccountList, superUser, getSuperUser }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);

  const [listType, setListType] = useState(1);
  const [loginId, setloginId] = useState('');
  const [agentId, setagentId] = useState('');
  const [ip, setip] = useState('');

  useEffect(() => {
    setIsQuerying(true);
    setPage(1);
    setRows(15);
    setloginId('');
    if (listType === 1) {
      const _obj = {
        page: 1,
        rows: 15,
        loginId
      };
      getInfo(_obj, () => setIsQuerying(false));
    }
    if (listType === 2) {
      const _obj = {
        page: 1,
        rows: 15,
        loginId,
        agentId
      };
      getSubAccountList(_obj, () => setIsQuerying(false));
    }
    if (listType === 0) {
      getSuperUser(() => setIsQuerying(false));
    }
  }, [listType]);

  const saveAndEdit = () => {
    if (_pageFlag === 2) {
      const obj = {
        id: editWhite.id,
        ip
      };
      const callback = () => {
        setPageFlag(0);
        if (listType === 1) {
          const _obj = {
            page,
            rows,
            loginId
          };
          getInfo(_obj, () => setIsQuerying(false));
        }
        if (listType === 2) {
          const _obj = {
            page,
            rows,
            loginId,
            agentId
          };
          getSubAccountList(_obj, () => setIsQuerying(false));
        }
        if (listType === 0) {
          getSuperUser(() => setIsQuerying(false));
        }
      };
      SaveInfo(obj, callback);
    }
  };


  const onSearchClick = () => {
    setIsQuerying(true);
    if (listType === 1) {
      const _obj = {
        page: 1,
        rows: 15,
        loginId
      };
      getInfo(_obj, () => setIsQuerying(false));
    }
    if (listType === 2) {
      const _obj = {
        page: 1,
        rows: 15,
        loginId,
        agentId
      };
      getSubAccountList(_obj, () => setIsQuerying(false));
    }
    setPage(1);
    setRows(15);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(true);
    setPage(pageNum);
    setRows(rows);
    if (listType === 1) {
      const _obj = {
        page: pageNum,
        rows,
        loginId
      };
      getInfo(_obj, () => setIsQuerying(false));
    }
    if (listType === 2) {
      const _obj = {
        page,
        rows,
        loginId,
        agentId
      };
      getSubAccountList(_obj, () => setIsQuerying(false));
    }
  };

  useEffect(() => {
    if (_pageFlag === 0) {
      setip('');
    }
    if (_pageFlag === 2 && editWhite) {
      setip(editWhite?.ip || '');
    }
  }, [_pageFlag, editWhite]);

  return (
    <div className="WhitelistManagementWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0
            && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
              <Icon
                libName="md"
                value={{
                  name: 'MdArrowBack',
                  className: 'h5 mb-0 text-white'
                }}
              />
              返回
            </div>
            )}
            {`${t('WHITE_LIST_MANAGEMENT')} ${_pageFlag === 0 ? '' : '/ 编辑'}`}
          </div>
)}
      />
      {_pageFlag === 0 && (
      <>
        <div className="whiteListMenu">
          <div className={`menu_item ${(listType === 1 || listType === 2) && 'selected'}`} onClick={() => setListType(1)}>公司、子账号</div>
          <div className={`menu_item ${(listType === 0) && 'selected'}`} onClick={() => setListType(0)}>超级用户</div>
        </div>
        <div className="tableWrapper">
          {
        listType !== 0
        && (
        <div className="addNewGameBox mb-3">
          <div className="mx-2">
            {listType === 1 ? '公司账号' : '子账号'}
            <Input className="ms-2" value={loginId} onChange={text => setloginId(text)} />
          </div>
          <div className="operationBtn mx-2 add" onClick={() => onSearchClick()}>查询</div>
        </div>
        )
      }
          {listType === 1
        && (
        <div className="mx-0 bg-white px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: '公司名称',
            }, {
              label: '公司账号',
            }, {
              label: '新增日期',
            }, {
              label: t('OPERATION'),
            }]
          }
            tableHeaderStyle={
            []
          }
            tableData={company ? company.whiteList : []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.name}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.loginId}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.date).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (
                <Label className="d-flex justify-content-center align-items-center">
                  <div className="operationBtn mx-2" onClick={() => { setListType(2); setagentId(data.loginId); }}>子账号</div>
                  <div
                    className="operationBtn mx-2"
                    onClick={() => {
                      getEditInfo({
                        loginId: data.loginId
                      }, () => setPageFlag(2));
                    }}
                  >
                    IP白名单
                  </div>
                </Label>
              )
            ]
          }
          />
        </div>
        )}
          {listType === 2
        && (
        <div className="mx-0 bg-white px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: '上级账号',
            }, {
              label: '名称',
            }, {
              label: '账号',
            }, {
              label: t('OPERATION'),
            }]
          }
            tableHeaderStyle={
            []
          }
            tableData={subAccountList ? subAccountList.subList : []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.uplineId}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.name}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.loginId}</Label>),
              data => (
                <Label className="d-flex justify-content-center align-items-center">
                  <div
                    className="operationBtn mx-2"
                    onClick={() => {
                      getEditInfo({
                        orgId: data.organizationId
                      }, () => setPageFlag(2));
                    }}
                  >
                    IP白名单
                  </div>
                </Label>
              )
            ]
          }
          />
        </div>
        )}
          {listType === 0
        && (
        <div className="mx-0 bg-white px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: '账号',
            }, {
              label: '新增日期',
            }, {
              label: t('OPERATION'),
            }]
          }
            tableHeaderStyle={
            []
          }
            tableData={superUser ? superUser.userList : []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.loginId}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.date).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (
                <Label className="d-flex justify-content-center align-items-center">
                  <div
                    className="operationBtn mx-2"
                    onClick={() => {
                      getEditInfo({
                        orgId: data.id
                      }, () => setPageFlag(2));
                    }}
                  >
                    IP白名单
                  </div>
                </Label>
              )
            ]
          }
          />
        </div>
        )}
        </div>
        <div className="d-flex align-items-center justify-content-center w-full relative">
          <Pagination totalRecord={(superUser || subAccountList || company) ? (listType === 0 ? superUser?.totalRecords || 0 : listType === 2 ? subAccountList?.totalRecords || 0 : company?.totalRecords || 0) : 0} onClick={onPaginationClick} isQuery={false} />
        </div>
      </>
      )}
      {_pageFlag !== 0 && (
        <>
          <div className="my-3 thirdPartyGamesTable">
            <div className="thirdPartyGamesTitle">{_pageFlag === 1 ? '新增地址' : '编辑地址'}</div>
            <div className="thirdPartyGamesRow">
              <div>名称</div>
              <div><Input disabled value={editWhite ? editWhite.name : ''} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>账号</div>
              <div><Input disabled value={editWhite ? editWhite.loginId : ''} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>IP</div>
              <div>
                <Input
                  type="textarea"
                  autosize={{
                    minRows: 4, maxRows: 8
                  }}
                  value={ip}
                  onChange={text => {
                    setip(text.trim());
                  }}
                  placeholder="多个IP用逗号隔开(留空则不作IP白名单设置)"
                />
              </div>
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => saveAndEdit()}>保存</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WhitelistManagement);