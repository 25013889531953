import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { Select } from 'element-react';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Icon, LotteryMenu } from '../..';
import { appActions, decreaseOddsActions } from '../../../redux/actions';

import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  decreaseOddsData: state.decreaseOddsReducers.decreaseOddsData,
  lotteryList: state.commonReducers.lotteryList,
  lotteryInstallments: state.commonReducers.lotteryInstallments,
});

const mapDispatchToProps = dispatch => ({
  getInfo: lotteryCode => dispatch(decreaseOddsActions.getInfo(lotteryCode)),
  getDefaultInfo: lotteryCode => dispatch(decreaseOddsActions.getDefaultInfo(lotteryCode)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveSetting: (obj, callback) => dispatch(decreaseOddsActions.saveSetting(obj, callback)),
  saveDefaultSetting: (obj, callback) => dispatch(decreaseOddsActions.saveDefaultSetting(obj, callback)),
});

const DecreaseOddsSetting = ({ lotteryList, getInfo, decreaseOddsData, saveSetting, updateModalAlert, isAdmin, getDefaultInfo, saveDefaultSetting, activeLottery }) => {
  const { t } = useTranslation();
  const [_selectedLottery, setSelectedLottery] = useState('HK6');
  const [_selecteLotteryTxt, setSelectedLotteryTxt] = useState();
  const [_firstListItems, setFirstListItems] = useState();
  const [_secondListItems, setSecondListItems] = useState();
  const [_displayMenu, setDisplayMenu] = useState(false);
  const [_info, setInfo] = useState([]);
  const [_selectedQS, setSelectedQS] = useState('');
  const [_qsText, setQsText] = useState('');
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);

  useEffect(() => {
    setIsQueryingDetail(true);
    // Default search when first load
    if (lotteryList) {
      isAdmin ? getDefaultInfo({
        lotteryType: lotteryList[0].code || _selectedLottery || ''
      }) : getInfo({
        lotteryType: lotteryList[0].code || _selectedLottery || ''
      });
      setSelectedLotteryTxt(lotteryList[0].lotteryName);
    }
  }, [lotteryList]);

  useEffect(() => {
    if (decreaseOddsData && JSON.stringify(_firstListItems) !== JSON.stringify(decreaseOddsData)) {
      setFirstListItems(decreaseOddsData?.slice(0, Math.floor(decreaseOddsData?.length / 2)));
      setSecondListItems(decreaseOddsData?.slice(Math.floor(decreaseOddsData?.length / 2, decreaseOddsData?.length)));
      setInfo([...decreaseOddsData]);
      setIsQueryingDetail(false);
    }
  }, [decreaseOddsData]);

  useEffect(() => {
    if (activeLottery) {
      onLotterySelect(activeLottery);
    }
  }, [activeLottery]);

  const onValueChange = (event, field) => {
    const index = Number(_info.findIndex(item => item.id === event.target.id));
    const newItems = [..._info];
    if (field === 'enabled') {
      newItems[index][field] = newItems[index][field] === 0 ? 1 : 0;
    } else {
      newItems[index][field] = Number(event.target.value);
    }
    newItems[index].isChange = true;
    setInfo(newItems);
  };

  const onSaveClick = () => {
    const res = _info.filter(item => item?.isChange === true);
    if (res.length > 0) {
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      isAdmin ? saveDefaultSetting(res) : saveSetting(res);
    } else {
      updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          message: '没有任何改动',
          showCancelButton: false
        }
      });
    }
  };

  const onResetClick = () => {
    isAdmin ? getDefaultInfo({
      lotteryType: _selectedLottery || ''
    }) : getInfo({
      lotteryType: _selectedLottery || ''
    });
    setQsText('');
    setSelectedQS('');
    const cb = document.querySelector('#main_selectAll');
    if (cb) {
      cb.checked = false;
    }
  };

  const updateInput = myfield => {
    if (myfield && _qsText) {
      const newProjects = [..._info];
      _info?.forEach((curr, index) => {
        newProjects[index][myfield] = _qsText;
        newProjects[index].isChange = true;
      });
      setInfo(newProjects);
    }
  };

  const onLotterySelect = retvalue => {
    setIsQueryingDetail(true);
    setSelectedLottery(retvalue?.code);
    setSelectedLotteryTxt(retvalue?.lotteryName);
    isAdmin ? getDefaultInfo({
      lotteryType: retvalue?.code || ''
    }) : getInfo({
      lotteryType: retvalue?.code || ''
    });
    setQsText('');
    setSelectedQS('');
    setDisplayMenu(false);
    const cb = document.querySelector('#main_selectAll');
    if (cb) {
      cb.checked = false;
    }
  };

  const QuickSetField = [
    {
      label: t('ACCUMULATED_VALUE'),
      value: 'moneyLimit',
    },
    {
      label: t('DECREASE_ODDS_VALUE'),
      value: 'downValue',
    }, {
      label: t('LOWEST_ODDS'),
      value: 'minOdds',
    }, {
      label: t('HIGHEST_QUANTITY'),
      value: 'maxQuantity',
    }
  ];

  return (
    <Col className="DecreaseOddsSettingWarpper">
      <Col className="content p-0">
        <Col className="subMenu p-1 px-2 mb-2 flex">
          <div
            onMouseEnter={() => setDisplayMenu(!_displayMenu)}
            onClick={() => setDisplayMenu(!_displayMenu)}
            className={`menuWrapper d-flex ${_displayMenu ? 'menuactive' : ''}`}
          >
            <Label className="menuSelectedText">{_selecteLotteryTxt}</Label>
            <div className="float-right">
              <Icon
                libName="fa"
                value={{
                  name: 'FaCaretDown',
                  className: 'float-right'
                }}
              />
            </div>
          </div>

          <div onMouseLeave={() => setDisplayMenu(false)} className={`subselection ${_displayMenu ? '' : 'hide'}`}>
            <LotteryMenu
              lotteryList={lotteryList}
              selectedItem={_selectedLottery}
              onClickItem={onLotterySelect}
            />
          </div>
        </Col>
        <Row className="mb-2">
          <Col>
            <AgentDataTable
              isQuerying={_isQueryingDetail}
              tableHeaderTitle={
                [{
                  label: t('TYPE'),
                }, {
                  label: t('ACCUMULATED_VALUE'),
                }, {
                  label: t('DECREASE_ODDS_VALUE'),
                }, {
                  label: t('LOWEST_ODDS'),
                }, {
                  label: t('HIGHEST_QUANTITY'),
                }, {
                  label: t('AUTO_ON_OFF'),
                }]
              }
              tableData={_isQueryingDetail ? [1, 2, 3] : (_firstListItems || [])}
              tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.projectName}</Label>),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        id={data.id}
                        name={data.code}
                        value={_info[Number(_info.findIndex(item => item.code === data.code && item?.id === data?.id))]?.moneyLimit}
                        type="Number"
                        onChange={e => onValueChange(e, 'moneyLimit')}
                        className=""
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        id={data.id}
                        name={data.code}
                        value={_info[Number(_info.findIndex(item => item.code === data.code && item?.id === data?.id))]?.downValue}
                        type="Number"
                        onChange={e => onValueChange(e, 'downValue')}
                        className=""
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        id={data.id}
                        name={data.code}
                        value={_info[Number(_info.findIndex(item => item.code === data.code && item?.id === data?.id))]?.minOdds}
                        type="Number"
                        onChange={e => onValueChange(e, 'minOdds')}
                        className=""
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        id={data.id}
                        name={data.code}
                        value={_info[Number(_info.findIndex(item => item.code === data.code && item?.id === data?.id))]?.maxQuantity}
                        type="Number"
                        onChange={e => onValueChange(e, 'maxQuantity')}
                        className=""
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        id={data.id}
                        type="checkbox"
                        key={`main_${data.id}`}
                        className="mx-2 px-1 align-items-center"
                        name={`main_${data.id}`}
                        value={data.id}
                        checked={_info[Number(_info.findIndex(item => item.code === data.code && item?.id === data?.id))]?.enabled}
                        onChange={e => onValueChange(e, 'enabled')}
                      />
                    </div>
                  ),
                ]
              }
              tableBodyRowStyle={
                ['backgroundColored']
              }
              tableHeaderStyle={
                ['col-2']
              }
            />
          </Col>
          <Col>
            <AgentDataTable
              isQuerying={_isQueryingDetail}
              tableHeaderTitle={
                [{
                  label: t('TYPE'),
                }, {
                  label: t('ACCUMULATED_VALUE'),
                }, {
                  label: t('DECREASE_ODDS_VALUE'),
                }, {
                  label: t('LOWEST_ODDS'),
                }, {
                  label: t('HIGHEST_QUANTITY'),
                }, {
                  label: t('AUTO_ON_OFF'),
                }]
              }
              tableData={_isQueryingDetail ? [1, 2, 3] : (_secondListItems || [])}
              tableBodyRowProperty={
                [
                  data => (<Label className="d-flex justify-content-center">{data.projectName}</Label>),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        id={data.id}
                        name={data.code}
                        value={_info[Number(_info.findIndex(item => item.code === data.code && item?.id === data?.id))]?.moneyLimit}
                        type="Number"
                        onChange={e => onValueChange(e, 'moneyLimit')}
                        className=""
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        id={data.id}
                        name={data.code}
                        value={_info[Number(_info.findIndex(item => item.code === data.code && item?.id === data?.id))]?.downValue}
                        type="Number"
                        onChange={e => onValueChange(e, 'downValue')}
                        className=""
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        id={data.id}
                        name={data.code}
                        value={_info[Number(_info.findIndex(item => item.code === data.code && item?.id === data?.id))]?.minOdds}
                        type="Number"
                        onChange={e => onValueChange(e, 'minOdds')}
                        className=""
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        id={data.id}
                        name={data.code}
                        value={_info[Number(_info.findIndex(item => item.code === data.code && item?.id === data?.id))]?.maxQuantity}
                        type="Number"
                        onChange={e => onValueChange(e, 'maxQuantity')}
                        className=""
                      />
                    </div>
                  ),
                  data => (
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        id={data.id}
                        type="checkbox"
                        key={`main_${data.id}`}
                        className="mx-2 px-1 align-items-center"
                        name={`main_${data.id}`}
                        value={data.id}
                        checked={_info[Number(_info.findIndex(item => item.code === data.code && item?.id === data?.id))]?.enabled}
                        onChange={e => onValueChange(e, 'enabled')}
                      />
                    </div>
                  ),
                ]
              }
              tableBodyRowStyle={
                ['backgroundColored']
              }
              tableHeaderStyle={
                ['col-2']
              }
            />
          </Col>
        </Row>
        <Row className="mb-5 border-top">
          <Col className="d-flex justify-content-center py-3">
            <Button className="saveButton mx-2" onClick={onSaveClick}>{t('SAVE')}</Button>
            <Button className="cancelButton mx-2" onClick={onResetClick}>{t('CANCEL')}</Button>
          </Col>
        </Row>
      </Col>
      <Col className="flex align-items-center justify-content-center footer p-0 m-0">
        <Col className="d-flex align-items-center justify-content-start p-2">
          <Label className="flex align-items-center justify-content-center mx-1">快速设置：</Label>
          <Select
            value={_selectedQS}
            placeholder="请选择"
            key="quicksetKey"
            className="align-items-center justify-content-center mx-1"
            onChange={val => setSelectedQS(val)}
          >
            {
                              QuickSetField.map(d => (
                                <Select.Option key={`setquicksetunit_${d.value}`} label={d.label} value={d.value} />
                              ))
                        }
          </Select>
          <Input type="number" id="QuickSetNumber" value={_qsText} className="align-items-center justify-content-center mx-1" onChange={({ target: { value: text } }) => setQsText(text)} />
          <Button className="saveButton mx-2" onClick={() => updateInput(_selectedQS)}>{t('CONFIRM')}</Button>
          <Button className="saveButton mx-1" onClick={onSaveClick}>{t('SAVE')}</Button>
          <>
            <Input
              id="main_selectAll"
              type="checkbox"
              className="mx-2 p-1 align-items-center"
              onClick={() => {
                const cb = document.querySelector('#main_selectAll');
                if (cb) {
                  const aInputs = document.querySelectorAll("input[type='checkbox']");
                  for (let i = 0, n = aInputs?.length || 0; i < n; i++) {
                    aInputs[i].checked = cb?.checked;
                  }
                  const newItems = _info.map(obj => ({
                    ...obj, enabled: Number(cb?.checked), isChange: true
                  }));
                  setInfo(newItems);
                }
              }}
            />
            {t('SELECT_ALL')}
          </>
        </Col>
      </Col>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DecreaseOddsSetting);