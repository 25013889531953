/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CommonHeader } from '../../components';
import HistoryDrawManagement from '../../components/PanKouManagement/HistoryDrawManagement';
import InstallmentManagement from '../../components/PanKouManagement/InstallmentManagement';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
  userInfo: state.memberReducers.userInfo,
});

const WalletManagement = ({ selectedSubMenu }) => {
  const { t } = useTranslation();
  return (

    <div className="installmentManagementWrapper p-3">
      {selectedSubMenu === 'IM_INSTALLMENTS_MANAGEMENT' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <InstallmentManagement />
      </>
      )}
      {selectedSubMenu === 'IM_HISTORY_RESULT_MANAGEMENT' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <HistoryDrawManagement />
      </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, null)(WalletManagement);