import React, { useEffect, useState } from 'react';

import { Input, Select } from 'antd';
import { connect } from 'react-redux';

import { systemActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  sysConfigInfo: state.systemReducers.sysConfigInfo
});

const mapDispatchToProps = dispatch => ({
  GetSysConfigInfo: () => dispatch(systemActions.GetSysConfigInfo()),
  SaveSysConfigInfo: (obj, callback) => dispatch(systemActions.SaveSysConfigInfo(obj, callback)),
});

const SystemSetting = ({ GetSysConfigInfo, sysConfigInfo, SaveSysConfigInfo }) => {
  const [ui, setUi] = useState('lgbg01');
  const [domain, setDomain] = useState('');
  const [zodiac, setZodiac] = useState('牛');

  useEffect(() => {
    GetSysConfigInfo();
  }, []);

  useEffect(() => {
    if (sysConfigInfo) {
      setUi(sysConfigInfo.ui);
      setDomain(sysConfigInfo.domain);
      setZodiac(sysConfigInfo.zodiac);
    }
  }, [sysConfigInfo]);

  const saveInfo = () => {
    const obj = {
      ui,
      domain,
      zodiac
    };
    SaveSysConfigInfo(obj, () => GetSysConfigInfo());
  };

  return (
    <div className="SystemSettingWrapper mx-0 bg-white px-0">
      <div className="thirdPartyGamesTable">
        <div className="thirdPartyGamesTitle">系统资料</div>
        <div className="thirdPartyGamesRow">
          <div>登陆界面</div>
          <div>
            <Select value={ui} placeholder="请选择" onChange={code => setUi(code)}>
              {
                [{
                  value: 'lgbg01', label: '正常'
                }, {
                  value: 'lgbg02', label: '中秋'
                }, {
                  value: 'lgbg03', label: '春节'
                }].map(el => (
                  <Select.Option key={el.value} value={el.value}>
                    {
                      el.label
                    }
                  </Select.Option>
                ))
              }
            </Select>
          </div>
        </div>
        <div className="thirdPartyGamesRow">
          <div>管理员登陆域名</div>
          <div><Input className="domain" value={domain} onChange={e => setDomain(e.target.value.trim())} placeholder="请输入内容" /></div>
        </div>
        <div className="thirdPartyGamesRow">
          <div>生肖</div>
          <div>
            <Select value={zodiac} placeholder="请选择" onChange={code => setZodiac(code)}>
              {
                [{
                  value: '鼠', label: '鼠'
                }, {
                  value: '牛', label: '牛'
                }, {
                  value: '虎', label: '虎'
                }, {
                  value: '兔', label: '兔'
                }, {
                  value: '龙', label: '龙'
                }, {
                  value: '蛇', label: '蛇'
                }, {
                  value: '马', label: '马'
                }, {
                  value: '羊', label: '羊'
                }, {
                  value: '猴', label: '猴'
                }, {
                  value: '鸡', label: '鸡'
                }, {
                  value: '狗', label: '狗'
                }, {
                  value: '猪', label: '猪'
                }].map(el => (
                  <Select.Option key={el.value} value={el.value}>
                    {
                      el.label
                    }
                  </Select.Option>
                ))
              }
            </Select>
          </div>
        </div>
      </div>
      <div className="confirmRow d-flex">
        <div className="confirmButton confirm" onClick={() => saveInfo()}>
          确定
        </div>
        <div className="confirmButton reset" onClick={() => { setUi('lgbg01'); setDomain(''); setZodiac('牛'); }}>
          重置
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemSetting);