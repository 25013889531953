import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';

import { AgentDataTable } from '../..';
import BetQueryDetail from '../../../views/BetQueryDetail';
import CommonHeader from '../../Content/CommonHeader';
import Icon from '../../Icon';
import ReportCommonSelection from '../../ReportCommonSelection';
import { reportActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  classificationReport: state.reportReducers.classificationReport,
});

const mapDispatchToProps = dispatch => ({
  getDepositWithdrawTotalStatistic: (obj, callback) => dispatch(reportActions.getDepositWithdrawTotalStatistic(obj, callback)),
});

const ClassificationReport = ({ classificationReport }) => {
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [list, setList] = useState();
  const [total, setTotal] = useState();

  const [_date, setDate] = useState([moment().startOf('day').toDate(), moment().toDate()]);

  const ChildRef = useRef();

  const [_specialLoginName, setSpecialLoginName] = useState();

  const [gamePage, setgamePage] = useState(true);

  const [lotteryCode, setlotterycode] = useState('');
  const [lotteryName, setlotteryName] = useState('全部');

  useEffect(() => {
    if (classificationReport) {
      setList(classificationReport.reportList);
      setTotal(classificationReport.total);
    }
  }, [classificationReport]);

  return (
    <div className="classificationReportWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_specialLoginName && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setSpecialLoginName()}>
              <Icon
                libName="md"
                value={{
                  name: 'MdArrowBack',
                  className: 'h5 mb-0 text-white'
                }}
              />
              返回
            </div>
            )}
            分类报表
          </div>
        )}
      />
      <ReportCommonSelection setSpecialLoginName={setSpecialLoginName} setgamePage={setgamePage} _isShow={!_specialLoginName} _setDate={setDate} callback={data => setIsQueryingDetail(data)} _type="classification" onRef={ChildRef} />
      <>
        {
        gamePage
          ? (
            <div className={`classificationReportTableWrapper p-3 ${_specialLoginName && 'd-none'}`}>
              <div className="mx-0 bg-white">
                <div className="headerTitle d-flex justify-content-center py-2 w-100">合计</div>
                <AgentDataTable
                  isQuerying={_isQueryingDetail}
                  tableHeaderTitle={
            [{
              label: '彩种',
            }, {
              label: '下注种类',
            }, {
              label: '笔数',
            }, {
              label: '下注金额',
            }, {
              label: '有效金额',
            }, {
              label: '会员输赢',
            }, {
              label: (
                <div className="combineTitleCell">
                  <div className="h-50">总公司 输赢</div>
                  <div className="d-flex h-50">
                    <div>应收下线</div>
                    <div>实占金额</div>
                    <div>实占结果</div>
                    <div>实占退水</div>
                    <div>赚水</div>
                    <div>赚赔</div>
                  </div>
                </div>
              ),
            },
            {
              label: '上交货量',
            }, {
              label: '上级交收',
            }, {
              label: '盈亏结果',
            }]
          }
                  tableHeaderStyle={['', '', '', '', '', '', 'p-0', '', '', '']}
                  tableData={list || []}
                  tableBodyRowStyle={['', '', '', '', '', '', 'p-0', '', '', '']}
                  tableBodyRowProperty={
            [
              data => (<div className="d-flex justify-content-center">{data.lotteryName}</div>),
              data => (
                <div
                  className="d-flex justify-content-center numberBlue detailLink"
                  onClick={() => {
                    setgamePage(false);
                    setlotterycode(data.lotteryType);
                    setlotteryName(data.lotteryName);
                    ChildRef.current.onSearchClick(2, data.orgId, 1, '', '', data.lotteryType, data.settingCode, 1);
                  }}
                >
                  {data.orgName}
                </div>
              ),
              data => (<div className="d-flex justify-content-center">{data.count}</div>),
              data => (<div className="d-flex justify-content-center text-primary">{data.amount}</div>),
              data => (<div className="d-flex justify-content-center">{data.validAmount}</div>),
              data => (<div className="d-flex justify-content-center">{data.totalWinLost}</div>),
              data => (
                <div className="d-flex justify-content-center combineBodyRowCell">
                  <div className={`${parseFloat(data.receiveable) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.receiveable}</div>
                  <div>{data.zcAmount}</div>
                  <div>{data.zcWinLost}</div>
                  <div>{data.zcRebate}</div>
                  <div>{data.earnAmount}</div>
                  <div>{data.earnOdds}</div>
                </div>
              ),
              data => (<div className={`d-flex justify-content-center ${parseFloat(data.totalResult) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.totalResult}</div>),
              data => (<div className="d-flex justify-content-center">{data.uplineAmount}</div>),
              data => (<div className={`d-flex justify-content-center ${parseFloat(data.uplineResult) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.uplineResult}</div>),
            ]
          }
                  tableFooter={[
                    [{
                      value: `合计：${list ? list.length : 0}行`,
                      colspan: 2,
                      className: 'pe-1 total tableSum'
                    },
                    {
                      value: (total && total.count) || 0,
                      colspan: 1,
                      className: 'tableSum'
                    },
                    {
                      value: (total && total.amount) || 0,
                      colspan: 1,
                      className: 'tableSum'
                    },
                    {
                      value: (total && total.validAmount) || 0,
                      colspan: 1,
                      className: 'tableSum'
                    },
                    {
                      value: (total && total.totalWinLost) || 0,
                      colspan: 1,
                      className: 'tableSum'
                    },
                    {
                      render: () => {
                        if (total) {
                          return (
                            <div className="d-flex justify-content-center combineBodyRowCell">
                              <div className={`${parseFloat(total.receiveable) < 0 ? 'numberRed' : 'numberBlue'}`}>{total.receiveable}</div>
                              <div>{total.zcAmount}</div>
                              <div>{total.zcWinLost}</div>
                              <div>{total.zcRebate}</div>
                              <div>{total.earnAmount}</div>
                              <div>{total.earnOdds}</div>
                            </div>
                          );
                        }

                        return 0;
                      },
                      colspan: 1,
                      className: 'tableSum noPadding'
                    },
                    {
                      value: (total && total.totalResult) || 0,
                      colspan: 1,
                      className: `tableSum ${parseFloat(total?.totalResult || 0) < 0 ? 'numberRed' : 'numberBlue'}`
                    },
                    {
                      value: (total && total.uplineAmount) || 0,
                      colspan: 1,
                      className: 'tableSum'
                    }, {
                      value: (total && total.uplineResult) || 0,
                      colspan: 1,
                      className: `tableSum ${parseFloat(total?.uplineResult || 0) < 0 ? 'numberRed' : 'numberBlue'}`
                    }]
                  ]}
                />
              </div>
            </div>
          )
          : (
            <div className={`classificationReportTableWrapper p-3 ${_specialLoginName && 'd-none'}`}>
              <div className="mx-0 bg-white">
                <div className="headerTitle d-flex justify-content-center py-2 w-100">合计</div>
                <AgentDataTable
                  isQuerying={_isQueryingDetail}
                  tableHeaderTitle={
              [{
                label: '账号',
              }, {
                label: '名称',
              }, {
                label: '笔数',
              }, {
                label: '下注金额',
              }, {
                label: '有效金额',
              }, {
                label: (
                  <div className="combineTitleCell">
                    <div className="h-50">会员输赢</div>
                    <div className="d-flex h-50">
                      <div>输赢</div>
                      <div>退水</div>
                      <div>盈亏结果</div>
                    </div>
                  </div>
                ),
              }, {
                label: (
                  <div className="combineTitleCell">
                    <div className="h-50">总公司 输赢</div>
                    <div className="d-flex h-50">
                      <div>应收下线</div>
                      <div>占成</div>
                      <div>实占金额</div>
                      <div>实占结果</div>
                      <div>实占退水</div>
                      <div>赚水</div>
                      <div>赚赔</div>
                      <div>盈亏结果</div>
                    </div>
                  </div>
                ),
              }, {
                label: '上交货量',
              }, {
                label: '上级交收',
              }]
            }
                  tableHeaderStyle={['', '', '', '', '', '', '', 'p-0', 'p-0', '', '']}
                  tableData={list || []}
                  tableBodyRowStyle={['', '', '', '', '', '', '', 'p-0', 'p-0', '', '']}
                  tableBodyRowProperty={
              [
                data => (
                  <div
                    className="d-flex justify-content-center numberBlue detailLink"
                    onClick={() => {
                      if (data.level === 11) {
                        setSpecialLoginName(data.loginName);
                      }
                    }}
                  >
                    {data.orgName}
                  </div>
                ),
                data => (<div className="d-flex justify-content-center">{data.loginName}</div>),
                data => (<div className="d-flex justify-content-center">{data.count}</div>),
                data => (<div className="d-flex justify-content-center numberBlue">{data.amount}</div>),
                data => (<div className="d-flex justify-content-center">{data.validAmount}</div>),
                data => (
                  <div className="d-flex justify-content-center combineBodyRowCell">
                    <div>{data.winLost}</div>
                    <div>{data.rebate}</div>
                    <div>{data.totalWinLost}</div>
                  </div>
                ),
                data => (
                  <div className="d-flex justify-content-center combineBodyRowCell">
                    <div className={`${parseFloat(data.receiveable) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.receiveable}</div>
                    <div>{data.zc}</div>
                    <div>{data.zcAmount}</div>
                    <div>{data.zcWinLost}</div>
                    <div>{data.zcRebate}</div>
                    <div>{data.earnAmount}</div>
                    <div>{data.earnOdds}</div>
                    <div className={`${parseFloat(data.totalResult) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.totalResult}</div>
                  </div>
                ),
                data => (<div className="d-flex justify-content-center">{data.uplineAmount}</div>),
                data => (<div className="d-flex justify-content-center numberBlue">{data.uplineResult}</div>),
              ]
            }
                  tableFooter={[
                    [{
                      value: `合计：${list ? list.length : 0}行`,
                      colspan: 2,
                      className: 'pe-1 total tableSum'
                    },
                    {
                      value: (total && total.count) || 0,
                      colspan: 1,
                      className: 'tableSum'
                    },
                    {
                      value: (total && total.amount) || 0,
                      colspan: 1,
                      className: 'tableSum'
                    },
                    {
                      value: (total && total.validAmount) || 0,
                      colspan: 1,
                      className: 'tableSum'
                    },
                    {
                      render: () => {
                        if (total) {
                          return (
                            <div className="d-flex justify-content-center combineBodyRowCell">
                              <div>{total.winLostAmount}</div>
                              <div>{total.rebate}</div>
                              <div>{total.totalWinLost}</div>
                            </div>
                          );
                        }

                        return 0;
                      },
                      colspan: 1,
                      className: 'tableSum noPadding'
                    },
                    {
                      render: () => {
                        if (total) {
                          return (
                            <div className="d-flex justify-content-center combineBodyRowCell">
                              <div className={`${parseFloat(total.receiveable) < 0 ? 'numberRed' : 'numberBlue'}`}>{total.receiveable}</div>
                              <div>{total.amount}</div>
                              <div>{total.zcAmount}</div>
                              <div>{total.zcWinLost}</div>
                              <div>{total.zcRebate}</div>
                              <div>{total.earnAmount}</div>
                              <div>{total.earnOdds}</div>
                              <div className={`${parseFloat(total.totalResult) < 0 ? 'numberRed' : 'numberBlue'}`}>{total.totalResult}</div>
                            </div>
                          );
                        }

                        return 0;
                      },
                      colspan: 1,
                      className: 'tableSum noPadding'
                    },
                    {
                      value: (total && total.uplineAmount) || 0,
                      colspan: 1,
                      className: 'tableSum'
                    },
                    {
                      value: (total && total.uplineResult) || 0,
                      colspan: 1,
                      className: 'tableSum numberBlue'
                    }]
                  ]}
                />
              </div>
            </div>
          )
        }
      </>
      {
        _specialLoginName
        && (
        <div className="mt-2">
          <BetQueryDetail presetObj={{
            loginName: _specialLoginName, lotteryName, beginDate: _date[0], endDate: _date[1], lotteryCode
          }}
          />
        </div>
        )
      }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationReport);