import React, { useEffect, useState } from 'react';

import { DateRangePicker, Select } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable } from '../..';
import Pagination from '../../Content/Pagination';
import { reportActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  depositWithdrawReport: state.reportReducers.depositWithdrawReport,
});

const mapDispatchToProps = dispatch => ({
  getDepositWithdrawReport: (obj, callback) => dispatch(reportActions.getDepositWithdrawReport(obj, callback)),
});

const DepositWithdrawReport = ({ getDepositWithdrawReport, depositWithdrawReport }) => {
  const { t } = useTranslation();
  const [_date, setDate] = useState([moment().startOf('day').toDate(), moment().toDate()]);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [memberType, setMemType] = useState(1);
  const [_dateFlag, setDateFlag] = useState('1');
  const [_hide, setHide] = useState(false);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);

  let dateRef;

  const onPaginationClick = (pageNum, rows) => {
    const obj = {
      memberType,
      beginDate: moment(_date[0]).format('YYYY-MM-DD'),
      endDate: moment(_date[1]).format('YYYY-MM-DD'),
      page: pageNum,
      rows
    };
    getDepositWithdrawReport(obj, setIsQueryingDetail);
    setPage(pageNum);
    setRows(rows);
  };

  useEffect(() => {
    const obj = {
      memberType,
      beginDate: moment(_date[0]).format('YYYY-MM-DD'),
      endDate: moment(_date[1]).format('YYYY-MM-DD'),
      page,
      rows
    };
    getDepositWithdrawReport(obj, setIsQueryingDetail);
  }, []);

  const onSearchClick = () => {
    const obj = {
      memberType,
      beginDate: moment(_date[0]).format('YYYY-MM-DD'),
      endDate: moment(_date[1]).format('YYYY-MM-DD'),
      page: 1,
      rows: 15
    };
    setIsQueryingDetail(true);
    getDepositWithdrawReport(obj, setIsQueryingDetail);
    setPage(1);
    setRows(15);
  };

  const dateSelector = id => {
    setDateFlag(id);
    switch (id) {
      case '1':
        setDate([moment().toDate(), moment().toDate()]);
        break;
      case '2':
        setDate([moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]);
        break;
      case '3':
        setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
        break;
      case '4':
        setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
        break;
      case '5':
        setDate([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
        break;
      case '6':
        setDate([moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()]);
        break;
      default:
        break;
    }
  };

  const cancel = () => {
    setDate([moment().subtract(1, 'weeks').toDate(), moment().toDate()]);
    setMemType(1);
    setDateFlag('');
    const obj = {
      memberType: 1, beginDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')
    };
    getDepositWithdrawReport(obj, setIsQueryingDetail);
  };

  return (
    <div className="depositWithdrawReportWrapper">
      <div className="depositWithdrawReportTableWrapper d-flex p-3">
        <div className="userTable w-100">
          <div className="d-flex">
            <div>日期范围</div>
            <div>
              <DateRangePicker
                className="betQueryDatepicker"
                value={_date}
                isShowTime
                placeholder={t('START_END_DATE')}
                rangeSeparator={` ${t('TO')} `}
                onChange={_date => {
                  setDate(_date);
                }}
                ref={e => dateRef = e}
                shortcuts={[{
                  text: t('TODAY'),
                  onClick: () => {
                    setDate([moment().toDate(), moment().toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('THIS_WEEK'),
                  onClick: () => {
                    setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_2_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }]}
              />
              <div onClick={e => dateSelector(e.target.id)} className="d-flex mx-2">
                {
              [{
                label: '今天', value: 1
              }, {
                label: '昨天', value: 2
              }, {
                label: '本星期', value: 3
              }, {
                label: '上星期', value: 4
              }, {
                label: '本月', value: 5
              }, {
                label: '上月', value: 6
              }].map(item => <span id={item.value} className={`mx-1 operationBtn dateSelectBtn ${+_dateFlag === item.value && 'selected'}`}>{item.label}</span>)

                 }
              </div>
              <div className="operationBtn findAndCollapse mx-1" onClick={onSearchClick}>查询</div>
              <div className="operationBtn cancel mx-1" onClick={() => cancel()}>取消</div>
              <div className="flex-grow-1 d-flex justify-content-end pe-4">
                <div className="operationBtn findAndCollapse" onClick={() => setHide(!_hide)}>{!_hide ? '收起' : '展开'}</div>
              </div>

            </div>
          </div>
          {!_hide && (
          <>
            <div className="d-flex">
              <div>用户类型</div>
              <div>
                <Select
                  popper-class="select-popper"
                  value={memberType}
                  onChange={value => setMemType(value)}
                >
                  {
                    [{
                      label: '会员', value: 1
                    }, {
                      label: '代理', value: 2
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
                </Select>

              </div>
            </div>
          </>
          )}
        </div>
      </div>
      <div className="depositWithdrawReportTableWrapper p-3">
        <div className="mx-0 bg-white">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={
            [{
              label: '日期',
            }, {
              label: '存款人数',
            }, {
              label: '存款笔数',
            }, {
              label: '存款',
            }, {
              label: '代理转账',
            }, {
              label: '取款人数',
            }, {
              label: '取款笔数',
            }, {
              label: '取款',
            }, {
              label: (
                <div className="combineTitleCell">
                  <div className="h-50">手续费</div>
                  <div className="d-flex h-50">
                    <div>所有</div>
                    <div>非第三方</div>
                    <div>第三方</div>
                    <div>取款</div>
                  </div>
                </div>
              ),
            }]
          }
            tableHeaderStyle={['', '', '', '', '', '', '', '', 'p-0']}
            tableData={depositWithdrawReport ? depositWithdrawReport.reportList : []}
            tableBodyRowStyle={['', '', '', '', '', '', '', '', 'p-0']}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.date).format('YYYY-MM-DD')}</Label>),
              data => <Label className="d-flex justify-content-center">{data.numOfDeposit}</Label>,
              data => (<Label className="d-flex justify-content-center">{data.depositCount}</Label>),
              data => (<Label className={`d-flex justify-content-center ${parseFloat(data.depositAmount) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.depositAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.agentTransfer}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.numOfWithdraw}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.withdrawCount}</Label>),
              data => (<Label className={`d-flex justify-content-center ${parseFloat(data.withdrawAmount) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.withdrawAmount}</Label>),
              data => (
                <Label className="d-flex justify-content-center combineBodyRowCell">
                  <div>{data.fee}</div>
                  <div>{data.nonThirdPartyFee}</div>
                  <div>{data.thirdPartyFee}</div>
                  <div>{data.others}</div>
                </Label>
              ),
            ]
          }
            tableFooter={[
              [{
                value: '本页总和',
                colspan: 1,
                className: 'pe-1 total tableSum'
              },
              {
                value: (depositWithdrawReport && depositWithdrawReport.total.numOfDeposit) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (depositWithdrawReport && depositWithdrawReport.total.depositCount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (depositWithdrawReport && depositWithdrawReport.total.depositAmount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (depositWithdrawReport && depositWithdrawReport.total.agentTransfer) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (depositWithdrawReport && depositWithdrawReport.total.numOfWithdraw) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (depositWithdrawReport && depositWithdrawReport.total.withdrawCount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (depositWithdrawReport && depositWithdrawReport.total.withdrawAmount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                render: () => {
                  if (depositWithdrawReport) {
                    return (
                      <Label className="d-flex justify-content-center combineBodyRowCell">
                        <div>{depositWithdrawReport.total.fee}</div>
                        <div>{depositWithdrawReport.total.nonThirdPartyFee}</div>
                        <div>{depositWithdrawReport.total.thirdPartyFee}</div>
                        <div>{depositWithdrawReport.total.others}</div>
                      </Label>
                    );
                  }

                  return 0;
                },
                colspan: 1,
                className: 'tableSum noPadding'
              }],
              [{
                value: '所有总和',
                colspan: 1,
                className: ' pe-2 total tableSum'
              },
              {
                value: (depositWithdrawReport && depositWithdrawReport.total.totalNumOfDeposit) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (depositWithdrawReport && depositWithdrawReport.total.totalDepositCount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (depositWithdrawReport && depositWithdrawReport.total.totalDepositAmount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (depositWithdrawReport && depositWithdrawReport.total.totalAgentTransfer) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (depositWithdrawReport && depositWithdrawReport.total.totalNumOfWithdraw) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (depositWithdrawReport && depositWithdrawReport.total.totalWithdrawCount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (depositWithdrawReport && depositWithdrawReport.total.totalWithdrawAmount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                render: () => {
                  if (depositWithdrawReport) {
                    return (
                      <Label className="d-flex justify-content-center combineBodyRowCell">
                        <div>{depositWithdrawReport.total.totalFee}</div>
                        <div>{depositWithdrawReport.total.totalNonThirdPartyFee}</div>
                        <div>{depositWithdrawReport.total.totalThirdPartyFee}</div>
                        <div>{depositWithdrawReport.total.totalOthers}</div>
                      </Label>
                    );
                  }
                  return 0;
                },
                colspan: 1,
                className: 'tableSum noPadding'
              }]
            ]}
          />
        </div>
      </div>
      <div className=" d-flex justify-content-center w-full relative">
        <Pagination totalRecord={(depositWithdrawReport && depositWithdrawReport?.totalRecords) || 0} onClick={onPaginationClick} isQuery={false} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositWithdrawReport);