import React from 'react';

import { connect } from 'react-redux';

import ModalAlert from '../../../components/ModalAlert';
import SideMenu from '../../../components/SideMenu';
import './styles.scss';

const AuthContent = ({ children, menuList }) => (
  <main className="authContent">
    <div className="d-flex h-100 overflow-hidden">
      <SideMenu menuList={menuList} />
      {children}
    </div>
    <ModalAlert />
  </main>
);

export default connect(null)(AuthContent);