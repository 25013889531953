import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { Radio, Select, Tabs } from 'element-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AgentDataTable from '../../AgentDataTable';
import mainGames from '../../../constants/QuickSetVariable/Games';

import { appActions, companyActions } from '../../../redux/actions';
import Skeleton from '../../Skeleton';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  oddsDiffData: state.agentReducers.oddsDiffData,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getOddsDifferent: (lotType, callback) => dispatch(companyActions.getOddsDifferent(lotType, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveOddsDifference: (obj, callback) => dispatch(companyActions.saveOddsDifference(obj, callback)),
});

const DefaultOddsDiff = ({
  getOddsDifferent,
  oddsDiffData,
  updateModalAlert,
  saveOddsDifference,
  activeLottery,
  userData
}) => {
  const { t } = useTranslation();
  const [_platetInput, setPlateInput] = useState('');
  const [_selectedLotType, setSelectedLotType] = useState(1);
  const [_header, setHeader] = useState([]);
  const [_firstheader, setfirstHeader] = useState([]);
  const [_uniqueString, setUniqueString] = useState([]);
  const [_content, setContent] = useState([]);
  const [_selectedTab, setSelectedTab] = useState('');
  const [_allLotList, setAllLotList] = useState([]);
  const [_lotList, setLotList] = useState([]);
  const [_activeLotteryCode, setActiveLotteryCode] = useState();
  const [_tempData, setTempData] = useState([]);
  const [_userGames, setUserGames] = useState([]);
  const [_rowSpanCol, setRowSpanCol] = useState([0]);
  const [_selectedField, setSelectedField] = useState();
  const [_selectedValue, setSelectedValue] = useState(0);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [_isUpdate, setIsUpdate] = useState(false);
  const [_quickSetData, setQuickSetData] = useState([]);
  const [_qsHeader, setQsHeader] = useState([]);
  const [_tempQuickSetContent, setQuickSetContent] = useState([]);
  let bufferData = [];
  let quickSetBufferData = [];

  useEffect(() => {
    if (oddsDiffData && oddsDiffData?.memberGame) {
      if (_lotList.length === 0) {
        setAllLotList(oddsDiffData?.lotteryList);
        loadData(_selectedLotType);
      }
      const list = [...oddsDiffData?.oddsList];
      oddsDiffData?.oddsList.forEach((curr, myInd) => {
        const sameList = oddsDiffData?.oddsList.filter(x => x.settingCode === curr.settingCode && x.lotteryCode === curr.lotteryCode);
        const index = Number(sameList?.findIndex(item => item.id === curr.id));
        if (sameList.length >= 1 && index !== -1) {
          if (index === 0) {
            list[myInd].rowSpan = sameList.length;
          } else {
            list[myInd].rowSpan = 'nil';
            list[myInd].orderBy = sameList[0].orderBy + (sameList[index].orderBy * 0.01);
          }
        }
        list[myInd].templateCode = oddsDiffData?.lotteryList?.filter(x => x.code === curr.lotteryCode)[0]?.templateCode;
        list[myInd].code = curr.settingCode;
        list[myInd].diffA = curr.oddsA - curr.diffOddsA;
        list[myInd].diffB = curr.oddsB - curr.diffOddsB;
        list[myInd].diffC = curr.oddsC - curr.diffOddsC;
        list[myInd].diffD = curr.oddsD - curr.diffOddsD;
      });
      bufferData = [...list];
      setTempData([...list]);
      setIsQueryingDetail(false);
      const tempHead = [];
      const tempContent = [];
      const tempFirstHead = [];
      const tempQuickSetContent = [];
      const tempQuickSetHeader = [];
      const tempRowSpan = [0];
      tempContent.push(editDataContent[0]);
      tempContent.push(editDataContent[1]);
      tempFirstHead.push(firstfixHeader[0]);
      tempQuickSetHeader.push({
        label: '项目名称',
        rowSpan: 2,
      });
      tempQuickSetContent.push(rebateQuickSetData[0]);
      // add according to user's games
      setUserGames(oddsDiffData?.memberGame);
      Array.from(oddsDiffData?.memberGame).forEach((d, index) => {
        tempRowSpan.push(3 + index + index);
        tempFirstHead.push({
          label: `${d + t('PLATE')}`,
          colSpan: 2
        });
        tempHead.push(fixHeader[0]);
        tempHead.push(fixHeader[1]);
        tempQuickSetContent.push(rebateQuickSetData[1 + index + index]);
        tempQuickSetContent.push(rebateQuickSetData[2 + index + index]);
        tempContent.push(editDataContent[2 + index + index]);
        tempContent.push(editDataContent[3 + index + index]);
      });
      setRowSpanCol(tempRowSpan);
      tempContent.push(editDataContent[10]);
      tempContent.push(editDataContent[11]);
      tempContent.push(editDataContent[12]);
      tempFirstHead.push(firstfixHeader[1]);
      tempFirstHead.push(firstfixHeader[2]);
      tempFirstHead.push(firstfixHeader[3]);
      tempQuickSetContent.push(rebateQuickSetData[9]);
      tempQuickSetContent.push(rebateQuickSetData[10]);
      tempQuickSetContent.push(rebateQuickSetData[11]);
      setHeader(tempHead);
      setContent(tempContent);
      setfirstHeader(tempFirstHead);
      setQsHeader([...tempQuickSetHeader, ...tempFirstHead.slice(1, tempFirstHead.length - 1), ...[{
        label: '修改',
        rowSpan: 2
      }]]);
      setQuickSetContent(tempQuickSetContent);
      // to calculate the higest value data among the list
      const tempData = [];
      mainGames.Games.forEach(each => {
        const games = mainGames.Games.filter(x => x.value === each.value)[0];
        games.data.forEach(d => {
          const filterList = (oddsDiffData?.oddsList.filter(x => d?.subCode?.includes(x?.code)
              && (each.value === 2 ? (x?.templateCode ? x?.templateCode.includes('HK6') : false) : (x?.templateCode ? !x?.templateCode.includes('HK6') : false))));
          tempData.push({
            projectName: d.label,
            aReturn: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.aReturn < b.aReturn) ? 1 : -1))[0]?.aReturn,
            bReturn: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.bReturn < b.bReturn) ? 1 : -1))[0]?.bReturn,
            cReturn: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.cReturn < b.cReturn) ? 1 : -1))[0]?.cReturn,
            dReturn: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.dReturn < b.dReturn) ? 1 : -1))[0]?.dReturn,
            diffOddsA: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.diffOddsA < b.diffOddsA) ? 1 : -1))[0]?.diffOddsA,
            diffOddsB: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.diffOddsB < b.diffOddsB) ? 1 : -1))[0]?.diffOddsB,
            diffOddsC: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.diffOddsC < b.diffOddsC) ? 1 : -1))[0]?.diffOddsC,
            diffOddsD: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.diffOddsD < b.diffOddsD) ? 1 : -1))[0]?.diffOddsD,
            projectLimit: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.projectLimit < b.projectLimit) ? 1 : -1))[0]?.projectLimit,
            itemLimit: filterList.length === 0 ? 0 : filterList.sort((a, b) => ((a.itemLimit < b.itemLimit) ? 1 : -1))[0]?.itemLimit,
            subCode: d.subCode,
            templates: games.code,
            ind: tempData.length,
            lotType: each.value,
          });
        });
      });
      setQuickSetData(tempData);
      quickSetBufferData = tempData;
    }
  }, [oddsDiffData]);

  useEffect(() => {
    setIsQueryingDetail(true);
    loadData(_selectedLotType);
    if (_tempData) {
      getOddsDifferent(_activeLotteryCode || activeLottery?.code);
    }
    setSelectedTab(mainGames.Games.filter(x => x.value === _selectedLotType)[0].code[0]);
  }, [_selectedLotType]);

  useEffect(() => {
    setActiveLotteryCode(_lotList?.filter(x => x.templateCode === _selectedTab)[0]?.code);
  }, [_selectedTab]);

  useEffect(() => {
    setIsQueryingDetail(true);
    getOddsDifferent(_activeLotteryCode || activeLottery?.code);
  }, [_activeLotteryCode]);


  useEffect(() => {
    if (bufferData) {
      bufferData = _tempData;
    }
  }, [_tempData]);

  useEffect(() => {
    if (quickSetBufferData) {
      quickSetBufferData = _quickSetData;
    }
  }, [_quickSetData]);
  const loadData = lotType => {
    setUniqueString(mainGames.Games.filter(x => x.value === _selectedLotType)[0].name);
    if (oddsDiffData || _allLotList) {
      const tempList = oddsDiffData?.lotteryList || _allLotList;
      let list = [];
      if (lotType === 2) {
        list = tempList.filter(x => x.templateCode === 'HK6' && x.templateCode !== 'F31X7' && x.templateCode !== 'F36X7');
      } else if (lotType === 3) {
        list = tempList.filter(x => x.templateCode === 'F31X7' || x.templateCode === 'F36X7');
      } else if (lotType === 1) {
        list = tempList.filter(x => (x?.templateCode ? (x?.templateCode !== 'HK6' && x.templateCode !== 'F31X7' && x.templateCode !== 'F36X7') : true));
      }
      setLotList(list);
      if (!_isUpdate) {
        setActiveLotteryCode(list.filter(x => x.templateCode === _selectedTab)[0]?.code);
      }
    }
    setIsUpdate(false);
  };

  const onValueChange = (event, data, field) => {
    let newItems = [];
    newItems = [...bufferData];

    if (bufferData && newItems && event.target.value) {
      const index = Number(bufferData?.findIndex(item => item.id === data.id));
      if (index !== -1) {
        newItems[index][field] = Number(event.target.value);
        newItems = addHighLightField(newItems, field, index);
        newItems[index].isChange = true;
        if (field.includes('Return')) {
          bufferData.forEach((curr, myInd) => {
            if (curr.lotteryCode === data.lotteryCode && curr.settingCode === data.settingCode && field.includes('Return')) {
              newItems[myInd][field] = Number(event.target.value);
              newItems[myInd].isChange = true;
            }
          });
        }
        setTempData(newItems);
      }
    }
  };

  const saveButton = () => {
    const res = _tempData.filter(item => item?.isChange === true && item?.templateCode);
    if (res.length > 0) {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const obj = {
        orgId: userData?.id,
        setting: res,
        memberType: 2,
        lotteryType: _activeLotteryCode
      };
      saveOddsDifference(obj, setIsUpdate);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '没有变更'
        }
      });
    }
  };

  const resetDefault = () => {
    getOddsDifferent(_activeLotteryCode);
  };

  const setAllPlate = () => {
    if (_selectedField && (_selectedValue || _platetInput)) {
      const newProjects = [..._tempData];
      const field = _selectedField;
      _tempData.forEach((curr, myInd) => {
        const optVal = Math.abs(Number(_platetInput)) ? Math.abs(Number(_platetInput)) : curr[field];
        const after = optVal + Number(_selectedValue);
        newProjects[myInd][field] = +parseFloat(after).toFixed(3);
        newProjects[myInd].isChange = true;
        addHighLightField(newProjects, field, myInd);
      });
      setTempData(newProjects);
      setPlateInput('');
    }
  };
  const editQS = (e, index, field) => {
    let newItems = [];
    newItems = [...quickSetBufferData];
    const valToChange = Number(e.target.value);
    if (index !== -1 && newItems.length > 0) {
      newItems[index][field] = valToChange;
      newItems = addHighLightField(newItems, field, index);
      setQuickSetData(newItems);
    }
  };
  const editAllDataFromQS = (subCode, index, templateCode) => {
    const newProjects = [...bufferData];
    newProjects?.forEach((curr, myInd) => {
      if (subCode?.includes(curr.code) && templateCode.includes(curr.templateCode)) {
        newProjects[myInd].aReturn = Number(quickSetBufferData[index].aReturn);
        newProjects[myInd].bReturn = Number(quickSetBufferData[index].bReturn);
        newProjects[myInd].cReturn = Number(quickSetBufferData[index].cReturn);
        newProjects[myInd].dReturn = Number(quickSetBufferData[index].dReturn);
        newProjects[myInd].diffOddsA = Number(quickSetBufferData[index].diffOddsA);
        newProjects[myInd].diffOddsB = Number(quickSetBufferData[index].diffOddsB);
        newProjects[myInd].diffOddsC = Number(quickSetBufferData[index].diffOddsC);
        newProjects[myInd].diffOddsD = Number(quickSetBufferData[index].diffOddsD);
        newProjects[myInd].itemLimit = Number(quickSetBufferData[index].itemLimit);
        newProjects[myInd].projectLimit = Number(quickSetBufferData[index].projectLimit);
        newProjects[myInd].isChangeA = true;
        newProjects[myInd].isChangeB = true;
        newProjects[myInd].isChangeC = true;
        newProjects[myInd].isChangeD = true;
        newProjects[myInd].isChangeRA = true;
        newProjects[myInd].isChangeRB = true;
        newProjects[myInd].isChangeRC = true;
        newProjects[myInd].isChangeRD = true;
        newProjects[myInd].isChangeIL = true;
        newProjects[myInd].isChangePL = true;
        newProjects[myInd].isChange = true;
      }
    });
    setTempData(newProjects);
  };

  const rebateQuickSetData = [
    data => (<Label className="d-flex justify-content-center">{data?.projectName}</Label>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeA && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'diffOddsA')} value={data?.diffOddsA} /></div>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeRA && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'aReturn')} value={data?.aReturn} /></div>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeB && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'diffOddsB')} value={data?.diffOddsB} /></div>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeRB && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'bReturn')} value={data?.bReturn} /></div>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeC && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'diffOddsC')} value={data?.diffOddsC} /></div>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeRC && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'cReturn')} value={data?.cReturn} /></div>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeD && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'diffOddsD')} value={data?.diffOddsD} /></div>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeRD && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'dReturn')} value={data?.dReturn} /></div>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangePL && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'projectLimit')} value={data?.projectLimit} /></div>),
    data => (<div className="align-items-center justify-content-center"><Input type="Number" className={`smallInput ${data?.isChangeIL && 'isChange'}`} onChange={e => editQS(e, data?.ind, 'itemLimit')} value={data?.itemLimit} /></div>),
    data => (<Button className="buttonControl edit" onClick={() => editAllDataFromQS(data?.subCode, data?.ind, data?.templates)}>{t('EDIT')}</Button>),
  ];

  const firstfixHeader = [{
    label: '项目名称',
    rowSpan: 2,
    colSpan: 2,
  },
  {
    label: '注单限额',
    rowSpan: 2,
  },
  {
    label: '单期限额',
    rowSpan: 2,
  },
  {
    label: '赔率预览',
    rowSpan: 2,
  }];

  const fixHeader = [{
    label: '赔率/赔率',
  },
  {
    label: '退水(%)',
  },
  {
    label: '注单限额'
  },
  {
    label: '单期限额'
  },
  {
    label: '赔率预览'
  }];

  function addHighLightField(item, field, index) {
    switch (field) {
      case 'diffOddsA':
        item[index].isChangeA = true;
        break;
      case 'diffOddsB':
        item[index].isChangeB = true;
        break;
      case 'diffOddsC':
        item[index].isChangeC = true;
        break;
      case 'diffOddsD':
        item[index].isChangeD = true;
        break;
      case 'aReturn':
        item[index].isChangeRA = true;
        break;
      case 'bReturn':
        item[index].isChangeRB = true;
        break;
      case 'cReturn':
        item[index].isChangeRC = true;
        break;
      case 'dReturn':
        item[index].isChangeRD = true;
        break;
      case 'projectLimit':
        item[index].isChangePL = true;
        break;
      case 'itemLimit':
        item[index].isChangeIL = true;
        break;
      default: break;
    }
    return item;
  }

  const editDataContent = [
    data => (data?.rowSpan !== 'nil' ? <Label className="d-flex justify-content-center">{data?.name}</Label> : undefined),
    data => (<Label className="d-flex justify-content-center">{data?.subName}</Label>),
    data => (
      <div className="p-0 m-0 d-flex align-items-center justify-content-center">
        <Input className={`lotteryName m-0 smallInput ${data?.isChangeA && 'isChange'}`} name={data?.diffOddsA} onChange={e => onValueChange(e, data, 'diffOddsA')} type="Number" value={data?.diffOddsA} />
        <div className="atTag px-1">
          @&nbsp;
          <Label className="installment">{Math.min((Number(data?.diffOddsA) + Number(data?.diffA)).toFixed(4))}</Label>
        </div>
      </div>
    ),
    data => (data?.rowSpan !== 'nil' ? (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isChangeRA && 'isChange'}`} name={data?.aReturn} onChange={e => onValueChange(e, data, 'aReturn')} type="Number" value={data?.aReturn} />
      </div>
    ) : undefined
    ),
    data => (
      <div className="d-flex align-items-center justify-content-center">
        <Input className={`lotteryName m-0 ml-1 smallInput ${data?.isChangeB && 'isChange'}`} name={data?.diffOddsB} onChange={e => onValueChange(e, data, 'diffOddsB')} type="Number" value={data?.diffOddsB} />
        <div className="atTag px-1">
          @&nbsp;
          <Label className="installment">{Math.min((Number(data?.diffOddsB) + Number(data?.diffB)).toFixed(4))}</Label>
        </div>
      </div>
    ),
    data => (data?.rowSpan !== 'nil' ? (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isChangeRB && 'isChange'}`} name={data?.bReturn} onChange={e => onValueChange(e, data, 'bReturn')} type="Number" value={data?.bReturn} />
      </div>
    ) : undefined
    ),
    data => (
      <div className="d-flex align-items-center justify-content-center">
        <Input className={`lotteryName m-0 smallInput ${data?.isChangeC && 'isChange'}`} name={data?.diffOddsC} onChange={e => onValueChange(e, data, 'diffOddsC')} type="Number" value={data?.diffOddsC} />
        <div className="atTag px-1">
          @&nbsp;
          <Label className="installment">{Math.min((Number(data?.diffOddsC) + Number(data?.diffC)).toFixed(4))}</Label>
        </div>
      </div>
    ),
    data => (data?.rowSpan !== 'nil' ? (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isChangeRC && 'isChange'}`} name={data?.cReturn} onChange={e => onValueChange(e, data, 'cReturn')} type="Number" value={data?.cReturn} />
      </div>
    ) : undefined
    ),
    data => (
      <div className="d-flex align-items-center justify-content-center">
        <Input className={`lotteryName m-0 smallInput ${data?.isChangeD && 'isChange'}`} name={data?.diffOddsD} onChange={e => onValueChange(e, data, 'diffOddsD')} type="Number" value={data?.diffOddsD} />
        <div className="atTag px-1">
          @&nbsp;
          <Label className="installment">{Math.min((Number(data?.diffOddsD) + Number(data?.diffD)).toFixed(4))}</Label>
        </div>
      </div>
    ),
    data => (data?.rowSpan !== 'nil' ? (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isChangeRD && 'isChange'}`} name={data?.dReturn} onChange={e => onValueChange(e, data, 'dReturn')} type="Number" value={data?.dReturn} />
      </div>
    ) : undefined
    ),
    data => (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isChangePL && 'isChange'}`} name={data?.projectLimit} onChange={e => onValueChange(e, data, 'projectLimit')} type="Number" value={data?.projectLimit} />
      </div>
    ),
    data => (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isChangeIL && 'isChange'}`} name={data?.itemLimit} onChange={e => onValueChange(e, data, 'itemLimit')} type="Number" value={data?.itemLimit} />
      </div>
    ),
    data => (
      <Label
        className="positiveValue urlNoUnderline"
        onClick={() => {
          updateModalAlert({
            type: 'previewOddDiff',
            visible: true,
            data: {
              thisData: data,
              memberGame: oddsDiffData?.memberGame
            }
          });
        }}
      >
        {t('PREVIEW')}
      </Label>
    )];

  return (
    <Col className="DefaultOddsDiffWrapper p-0 m-0">
      <Row className="m-0 p-0 my-2">
        <Col className="col-2 px-1 m-0 lotteryMenuWrapper align-items-center">
          {
            _allLotList.length <= 0 ? (
              <Skeleton isAnimated />
            ) : (
              <div
                className="px-2 d-flex radioButtonGroup"
              >
                {
                  _allLotList && mainGames.Games.map(item => (
                    <Radio
                      key={`games_${item?.displayName}`}
                      value={item?.value}
                      type="radio"
                      name="rdGames"
                      checked={Number(_selectedLotType) === Number(item.value)}
                      onChange={value => setSelectedLotType(value)}
                    >
                      {item?.displayName}
                    </Radio>
                  ))
                }
              </div>
            )
          }
        </Col>
        <Col className="p-1 m-0 filterWrapper">
          <Col className="d-flex align-items-center">
            <Label className="justify-content-center p-1">赚取退水：</Label>
            <Select
              value={_selectedField}
              placeholder="请选择"
              onChange={item => setSelectedField(item)}
            >
              {
                _userGames && Array.from(_userGames).map(d => (
                  <Select.Option key={`returnSelection_${d}`} label={`${d}${t('PLATE')}`} value={`${d.toLowerCase()}Return`} />
                ))
              }
              <Select.Option label="注单限额" value="itemLimit" />
              <Select.Option label="单期限额" value="projectLimit" />
              {
                _userGames && Array.from(_userGames).map(d => (
                  <Select.Option key={`oddsSelection_${d}`} label={`${t('ODDS')}${d}`} value={`diffOdds${d}`} />
                ))
              }
            </Select>
            <Select
              className="small mx-1"
              value={_selectedValue}
              placeholder="请选择"
              onChange={item => setSelectedValue(item)}
            >
              <Select.Option label="1" value="1" />
              <Select.Option label="0.5" value="0.5" />
              <Select.Option label="0.1" value="0.1" />
              <Select.Option label="0.05" value="0.05" />
              <Select.Option label="0.01" alue="0.01" />
              <Select.Option label="0" value="0" />
              <Select.Option label="-0.01" value="-0.01" />
              <Select.Option label="-0.05" value="-0.05" />
              <Select.Option label="-0.1" value="-0.1" />
              <Select.Option label="-0.5" alue="-0.5" />
              <Select.Option label="-1" value="-1" />
            </Select>
            <Label className="p-2">统一值：</Label>
            <Input className="mx-2" type="number" value={_platetInput} onChange={({ target: { value: text } }) => setPlateInput(text)} />
            <Button className="button generate mx-1" onClick={() => setAllPlate()}>{t('CONFIRM')}</Button>
            <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
            <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
          </Col>
        </Col>
      </Row>
      <Row className="bigWrapper m-0 my-2 p-2">
        {
          _allLotList.length <= 0 ? (
            <AgentDataTable
              isQuerying
              tableHeaderTitle={fixHeader || []}
              tableData={[1, 2, 3, 4, 5]}
              tableBodyRowProperty={[1, 2, 3, 4]}
              tableBodyRowStyle={
                ['backgroundGrey']
              }
            />
          ) : (
            <AgentDataTable
              isQuerying={_isQueryingDetail}
              tableHeaderTitle={_header || []}
              tableData={_quickSetData.filter(y => y?.lotType === _selectedLotType) || []}
              tableBodyRowProperty={_tempQuickSetContent || []}
              tableBodyRowStyle={
                ['backgroundGrey rowSpan', '', '', 'rowSpan', '', 'rowSpan', '', 'rowSpan', '', 'rowSpan']
              }
              rowSpanHeader={_qsHeader}
            />
          )
        }
      </Row>
      {_allLotList.length <= 0 ? (
        <>
          <Row className="p-2 m-0 mt-2 border">
            <Skeleton isAnimated />
          </Row>
          <Row className="p-2 m-0 drawBox">
            <Skeleton isAnimated />
          </Row>
        </>
      ) : (
        <Row className="m-0 p-0 my-2">
          <Tabs className="m-0 p-0" activeName={_selectedTab} onTabClick={tab => setSelectedTab(tab.props.name || '')}>
            {
              _uniqueString && _uniqueString.map(item => _lotList.filter(y => y?.templateCode === item.value).length > 0 && (
                <Tabs.Pane key={`maintype_${item.label}`} label={item.label} name={item.value}>
                  <Row className="py-2 m-0 drawBox hide">
                    <Col className="d-flex flex-wrap m-0 px-2 radioButtonGroup">
                      <div
                        className="flex-wrap p-0 d-flex radioButtonGroup fixsize"
                      >
                        {_lotList.filter((x, index) => index < 2 && x.templateCode === item.value).map(x => (
                          <Radio
                            key={`buttonSubCode_${x.code}_${x.lotteryName}`}
                            value={item?.value}
                            type="radio"
                            name="rdGames"
                            className="p-0"
                            checked={_activeLotteryCode === x.code}
                            onChange={() => setActiveLotteryCode(x.code)}
                          >
                            {x.lotteryName}
                          </Radio>
                        ))}
                        <Input
                          id={`chkConsolidate_${item.value}`}
                          type="checkbox"
                          className="m-1 p-1"
                        />
                        <Label
                          for={`chkConsolidate_${item.value}`}
                          className="p-0"
                        >
                          统一修改
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="p-2" />
                  {
                    _tempData && _tempData.filter(x => x.lotteryCode === _activeLotteryCode) && (
                      <Row className="m-0 p-0">
                        <AgentDataTable
                          isQuerying={_isQueryingDetail}
                          tableHeaderTitle={_header || []}
                          tableData={(_isQueryingDetail ? [1, 2, 3] : (_tempData.sort((a, b) => a.orderBy - b.orderBy) || []))}
                          tableBodyRowProperty={_content}
                          rowSpanHeader={_firstheader}
                          tableBodyRowStyle={
                            ['backgroundGrey rowSpan', 'backgroundGrey', '', 'rowSpan', '', 'rowSpan', '', 'rowSpan', '', 'rowSpan']
                          }
                          rowSpanCol={_rowSpanCol || []}
                        />
                      </Row>
                    )
                  }
                </Tabs.Pane>
              ))
            }
            <Tabs.Pane className="none" label="" name="" />
          </Tabs>
        </Row>
      )}

      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultOddsDiff);