import React, { useEffect, useState } from 'react';

import { Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Icon from '../../Icon';
import Pagination from '../../Content/Pagination';
import './styles.scss';

const mapStateToProps = state => ({
  exchangeRateListData: state.systemReducers.exchangeRateListData,
});

const mapDispatchToProps = dispatch => ({
  getExRateList: (obj, callback) => dispatch(systemActions.getExRateList(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  toggleExrateIsAuto: (obj, callback) => dispatch(systemActions.toggleExrateIsAuto(obj, callback)),
  toggleExrateIsDel: (obj, callback) => dispatch(systemActions.toggleExrateIsDel(obj, callback)),
});

const ExchangeRateSetting = ({
  callback = () => {},
  exchangeRateListData,
  getExRateList,
  data,
  toggleExrateIsDel,
  toggleExrateIsAuto,
  updateModalAlert
}) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const [_totalRecord, setTotalRecord] = useState(0);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);

  useEffect(() => {
    if (exchangeRateListData) {
      setInfo(exchangeRateListData?.exRateList);
      setTotalRecord(exchangeRateListData?.totalRecords);
      setIsQueryingDetail(false);
    }
  }, [exchangeRateListData]);

  useEffect(() => {
    setIsQueryingDetail(true);
    getExRateList({
      page: _page,
      rows: _rows,
    }, setIsQueryingDetail);
  }, []);

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    // Page number and {row number will follow the pagination component instead
    getExRateList({
      page: pageNum,
      rows: _rows,
    }, setIsQueryingDetail);
    setPage(pageNum);
    setRows(rows);
  };

  const afterSuccessCallBack = () => {
    getExRateList({
      page: _page,
      rows: _rows,
    }, setIsQueryingDetail);
  };

  const deleteClick = id => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      showCancelButton: true,
      data: {
        message: '确认删除',
        onConfirmationClick: () => toggleExrateIsDel(id, afterSuccessCallBack)
      },
    });
  };

  const toggleStatus = id => {
    toggleExrateIsAuto(id, afterSuccessCallBack);
  };


  const tableHeader = [{
    label: t('CURRENCY_CODE'),
  }, {
    label: t('CURRENCY_NAME'),
  }, {
    label: t('WITHDRAW_RATE'),
  }, {
    label: t('DEPOSIT_RATE'),
  }, {
    label: t('AUTO_RENEW_EX_RATE'),
  }, {
    label: t('AUTO_RENEW_INFO'),
  }, {
    label: t('DEPOSIT_FLOAT_RATE'),
  }, {
    label: t('WITHDRAW_FLOAT_RATE'),
  }, {
    label: t('EXCHANGE_RATE_REMARK'),
  }, {
    label: t('RENEW_TIME'),
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{data?.currencyCode}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.currencyName}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.depositRate}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.withdrawRate}</Label>),
    data => (<Label onClick={() => toggleStatus(data?.id)} className="urlNoUnderline positiveValue">{data?.isAutoRenew === 1 ? t('NO') : t('YES')}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.autoInfo}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.depositFloatRate}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.withdrawFloatRate}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.exRateRemark}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.createDate && moment.unix(data?.createDate).format('YYYY-MM-DD HH:mm:ss')}</Label>),
    data => (
      <div className="d-flex justify-content-center">
        <div className="button handle mx-1" onClick={() => callback('exRateAddEdit', data, true)}>{t('COMPILE')}</div>
        <div className="button find mx-1" onClick={() => deleteClick(data?.id)}>{t('DELETE')}</div>
      </div>
    ),
  ];

  return (
    <div className="ExchangeRateSettingWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <div className="p-0 m-0 mb-2">
          <div className="p-1 mb-2 filterWrapper d-flex align-items-center w-100">
            <div className="d-flex align-items-center">
              <div
                className="button add justify-content-center"
                onClick={() => callback('exRateAddEdit', data, false)}
              >
                <Icon
                  libName="fa"
                  value={{
                    name: 'FaPlusCircle',
                    className: 'mb-1 mr-1'
                  }}
                />
                <Label className="p-0 px-1">{t('ADD_SETTING')}</Label>
              </div>
            </div>
          </div>
        </div>
        <Row className="bigWrapper m-0 my-1 p-3">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeader}
            tableData={_info || (_isQueryingDetail ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
            tableBodyRowStyle={[]}
          />
        </Row>
        <Row className="justify-center w-full relative">
          <Pagination totalRecord={_totalRecord || 0} onClick={onPaginationClick} isQuery={false} />
        </Row>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeRateSetting);