import { Amount, BuHuo, DisplayLabel, InTimeSheetTable, OddsControl, Profit } from '../../../../components';

const TMZX1 = [
  {
    componentType: InTimeSheetTable,
    data: {
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'TMA2',
          displayName: '02',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMA3',
          displayName: '03',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA4',
          displayName: '04',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA5',
          displayName: '05',
          className: 'hk6Ball ball_green',
        }, {
          code: 'TMA6',
          displayName: '06',
          className: 'hk6Ball ball_green',
        }, {
          code: 'TMA7',
          displayName: '07',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMA8',
          displayName: '08',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMA9',
          displayName: '09',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA10',
          displayName: '10',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA11',
          displayName: '11',
          className: 'hk6Ball ball_green',
        }, {
          code: 'TMA12',
          displayName: '12',
          className: 'hk6Ball ball_red',

        }, {
          code: 'TMA13',
          displayName: '13',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMA14',
          displayName: '14',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA15',
          displayName: '15',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA16',
          displayName: '16',
          className: 'hk6Ball ball_green',
        }, {
          code: 'TMA17',
          displayName: '17',
          className: 'hk6Ball ball_green',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'TMA18',
          displayName: '18',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMA19',
          displayName: '19',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMA20',
          displayName: '20',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA21',
          displayName: '21',
          className: 'hk6Ball ball_green',
        }, {
          code: 'TMA22',
          displayName: '22',
          className: 'hk6Ball ball_green',
        }, {
          code: 'TMA23',
          displayName: '23',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMA24',
          displayName: '24',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMA25',
          displayName: '25',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA26',
          displayName: '26',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA27',
          displayName: '27',
          className: 'hk6Ball ball_green',
        }, {
          code: 'TMA28',
          displayName: '28',
          className: 'hk6Ball ball_red',

        }, {
          code: 'TMA29',
          displayName: '29',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMA30',
          displayName: '30',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA31',
          displayName: '31',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA32',
          displayName: '32',
          className: 'hk6Ball ball_green',
        }, {
          code: 'TMA33',
          displayName: '33',
          className: 'hk6Ball ball_green',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'TMA34',
          displayName: '34',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMA35',
          displayName: '35',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMA36',
          displayName: '36',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA37',
          displayName: '37',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA38',
          displayName: '38',
          className: 'hk6Ball ball_green',
        }, {
          code: 'TMA39',
          displayName: '39',
          className: 'hk6Ball ball_green',
        }, {
          code: 'TMA40',
          displayName: '40',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMA41',
          displayName: '41',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA42',
          displayName: '42',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA43',
          displayName: '43',
          className: 'hk6Ball ball_green',
        }, {
          code: 'TMA44',
          displayName: '44',
          className: 'hk6Ball ball_green',

        }, {
          code: 'TMA45',
          displayName: '45',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMA46',
          displayName: '46',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMA47',
          displayName: '47',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA48',
          displayName: '48',
          className: 'hk6Ball ball_blue',
        }, {
          code: 'TMA49',
          displayName: '49',
          className: 'hk6Ball ball_green',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 14,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'TMA1',
          displayName: '01',
          className: 'hk6Ball ball_red',
        }, {
          code: 'TMDSDX50',
          displayName: '特单',
        }, {
          code: 'TMDSDX51',
          displayName: '特双',
        }, {
          code: 'TMDSDX52',
          displayName: '特大',
        }, {
          code: 'TMDSDX53',
          displayName: '特小',
        }, {
          code: 'TMDSDX54',
          displayName: '特合单',
        }, {
          code: 'TMDSDX55',
          displayName: '特合双',
        }, {
          code: 'TMDSDX56',
          displayName: '特合大',
        }, {
          code: 'TMDSDX57',
          displayName: '特合小',
        }, {
          code: 'TMDSDX58',
          displayName: '特尾大',
        }, {
          code: 'TMDSDX59',
          displayName: '特尾小',
        }, {
          code: 'TMSB60',
          displayName: '红波',
          className: 'hk6_sebo redbo',
        }, {
          code: 'TMSB61',
          displayName: '蓝波',
          className: 'hk6_sebo bluebo',
        }, {
          code: 'TMSB62',
          displayName: '绿波',
          className: 'hk6_sebo greenbo',
        },
      ]
    },
  },
];

export default TMZX1;