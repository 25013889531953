import { Amount, BuHuo, DisplayLabel, InTimeSheetTable, OddsControl, Profit } from '../../../../components';

const D5MSetting = [
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '第三名',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '金额', '盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '第三名总注额',
        code: 'count_B3CH'
      }],
      list: [
        {
          code: 'B3CH7',
          displayName: '1',
          className: 'pk10Square square_1',
        }, {
          code: 'B3CH8',
          displayName: '2',
          className: 'pk10Square square_2',
        }, {
          code: 'B3CH9',
          displayName: '3',
          className: 'pk10Square square_3',
        }, {
          code: 'B3CH10',
          displayName: '4',
          className: 'pk10Square square_4',
        }, {
          code: 'B3CH11',
          displayName: '5',
          className: 'pk10Square square_5',
        }, {
          code: 'B3CH12',
          displayName: '6',
          className: 'pk10Square square_6',
        }, {
          code: 'B3CH13',
          displayName: '7',
          className: 'pk10Square square_7',
        }, {
          code: 'B3CH14',
          displayName: '8',
          className: 'pk10Square square_8',
        }, {
          code: 'B3CH15',
          displayName: '9',
          className: 'pk10Square square_9',
        }, {
          code: 'B3CH16',
          displayName: '10',
          className: 'pk10Square square_10',
        }, {
          code: 'B3LM1',
          displayName: '大',
        }, {
          code: 'B3LM2',
          displayName: '小',
        }, {
          code: 'B3LM3',
          displayName: '单',
        }, {
          code: 'B3LM4',
          displayName: '双',
        }, {
          code: 'B3LM5',
          displayName: '龙',
        }, {
          code: 'B3LM6',
          displayName: '虎',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '第四名',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '金额', '盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '第四名总注额',
        code: 'count_B4CH'
      }],
      list: [
        {
          code: 'B4CH7',
          displayName: '1',
          className: 'pk10Square square_1',
        }, {
          code: 'B4CH8',
          displayName: '2',
          className: 'pk10Square square_2',
        }, {
          code: 'B4CH9',
          displayName: '3',
          className: 'pk10Square square_3',
        }, {
          code: 'B4CH10',
          displayName: '4',
          className: 'pk10Square square_4',
        }, {
          code: 'B4CH11',
          displayName: '5',
          className: 'pk10Square square_5',
        }, {
          code: 'B4CH12',
          displayName: '6',
          className: 'pk10Square square_6',
        }, {
          code: 'B4CH13',
          displayName: '7',
          className: 'pk10Square square_7',
        }, {
          code: 'B4CH14',
          displayName: '8',
          className: 'pk10Square square_8',
        }, {
          code: 'B4CH15',
          displayName: '9',
          className: 'pk10Square square_9',
        }, {
          code: 'B4CH16',
          displayName: '10',
          className: 'pk10Square square_10',
        }, {
          code: 'B4LM1',
          displayName: '大',
        }, {
          code: 'B4LM2',
          displayName: '小',
        }, {
          code: 'B4LM3',
          displayName: '单',
        }, {
          code: 'B4LM4',
          displayName: '双',
        }, {
          code: 'B4LM5',
          displayName: '龙',
        }, {
          code: 'B4LM6',
          displayName: '虎',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '第五名',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '金额', '盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '第五名总注额',
        code: 'count_B5CH'
      }],
      list: [
        {
          code: 'B5CH7',
          displayName: '1',
          className: 'pk10Square square_1',
        }, {
          code: 'B5CH8',
          displayName: '2',
          className: 'pk10Square square_2',
        }, {
          code: 'B5CH9',
          displayName: '3',
          className: 'pk10Square square_3',
        }, {
          code: 'B5CH10',
          displayName: '4',
          className: 'pk10Square square_4',
        }, {
          code: 'B5CH11',
          displayName: '5',
          className: 'pk10Square square_5',
        }, {
          code: 'B5CH12',
          displayName: '6',
          className: 'pk10Square square_6',
        }, {
          code: 'B5CH13',
          displayName: '7',
          className: 'pk10Square square_7',
        }, {
          code: 'B5CH14',
          displayName: '8',
          className: 'pk10Square square_8',
        }, {
          code: 'B5CH15',
          displayName: '9',
          className: 'pk10Square square_9',
        }, {
          code: 'B5CH16',
          displayName: '10',
          className: 'pk10Square square_10',
        }, {
          code: 'B5LM1',
          displayName: '大',
        }, {
          code: 'B5LM2',
          displayName: '小',
        }, {
          code: 'B5LM3',
          displayName: '单',
        }, {
          code: 'B5LM4',
          displayName: '双',
        }, {
          code: 'B5LM5',
          displayName: '龙',
        }, {
          code: 'B5LM6',
          displayName: '虎',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '第六名',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '金额', '盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '第六名总注额',
        code: 'count_B6CH'
      }],
      list: [
        {
          code: 'B6CH7',
          displayName: '1',
          className: 'pk10Square square_1',
        }, {
          code: 'B6CH8',
          displayName: '2',
          className: 'pk10Square square_2',
        }, {
          code: 'B6CH9',
          displayName: '3',
          className: 'pk10Square square_3',
        }, {
          code: 'B6CH10',
          displayName: '4',
          className: 'pk10Square square_4',
        }, {
          code: 'B6CH11',
          displayName: '5',
          className: 'pk10Square square_5',
        }, {
          code: 'B6CH12',
          displayName: '6',
          className: 'pk10Square square_6',
        }, {
          code: 'B6CH13',
          displayName: '7',
          className: 'pk10Square square_7',
        }, {
          code: 'B6CH14',
          displayName: '8',
          className: 'pk10Square square_8',
        }, {
          code: 'B6CH15',
          displayName: '9',
          className: 'pk10Square square_9',
        }, {
          code: 'B6CH16',
          displayName: '10',
          className: 'pk10Square square_10',
        }, {
          code: 'B6LM1',
          displayName: '大',
        }, {
          code: 'B6LM2',
          displayName: '小',
        }, {
          code: 'B6LM3',
          displayName: '单',
        }, {
          code: 'B6LM4',
          displayName: '双',
        },
      ]
    },
  },
];

export default D5MSetting;