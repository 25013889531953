import React, { useEffect, useState } from 'react';

import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, cashActions } from '../../../redux/actions';
import { AgentDataTable } from '../..';
import './styles.scss';

const mapStateToProps = state => ({
  banks: state.cashReducers.banks,
});

const mapDispatchToProps = dispatch => ({
  GetBankCards: (obj, callback) => dispatch(cashActions.GetBankCards(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  DelBankCard: (id, obj) => dispatch(cashActions.DelBankCard(id, obj)),
});

const BankCard = ({ GetBankCards, banks, data, updateModalAlert, DelBankCard }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);

  const [_loadLoginLogs, setloadLoginLogs] = useState();

  useEffect(() => {
    setloadLoginLogs(banks);
  }, [banks]);

  useEffect(() => {
    if (data) {
      const obj = {
        memberId: data.orgId || data?.memberId, page: 1, rows: 15
      };
      setIsQuerying(true);
      GetBankCards(obj, setIsQuerying);
    }
  }, []);

  return (
    <div className="bankCardWrapper">
      <div className="mx-0 bg-white mb-3  border-1 bankCardtableWapper p-3">
        <AgentDataTable
          isQuerying={_isQuerying}
          tableHeaderTitle={
            [{
              label: '所属银行',
            }, {
              label: '账号号码',
            }, {
              label: '账号开户名',
            }, {
              label: '账号开户地址'
            }, {
              label: '状态',
            }, {
              label: t('OPERATION'),
            }]
          }
          tableHeaderStyle={
            ['smallCol']
          }
          tableData={_loadLoginLogs ? _loadLoginLogs.data : []}
          tableBodyRowProperty={
            [
              item => (<Label className="d-flex justify-content-center noWrapStyle">{item.bankName}</Label>),
              item => (<Label className="d-flex justify-content-center">{item.bankNumber}</Label>),
              item => (<Label className="d-flex justify-content-center">{item.bankUName}</Label>),
              item => (<Label className="d-flex justify-content-center">{item.bankAddress}</Label>),
              item => (<Label className="d-flex justify-content-center">{item.isDel === 0 ? '正常' : ''}</Label>),
              item => (
                <Label
                  className="d-flex justify-content-center operationBtn w-25 m-auto"
                  onClick={() => {
                    updateModalAlert({
                      type: 'loading',
                      visible: true
                    }); DelBankCard(item.id, data);
                  }}
                >
                  删除
                </Label>
              )
            ]
          }
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BankCard);