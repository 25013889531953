import React, { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, reportActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Icon from '../../Icon';

import './styles.scss';

const mapStateToProps = state => ({
  memberSummationDetailReport: state.reportReducers.memberSummationDetailReport,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getMemberSummationDetailReport: (obj, callback) => dispatch(reportActions.getMemberSummationDetailReport(obj, callback)),
});

const WinLostDetailModal = ({ updateModalAlert, data, getMemberSummationDetailReport, memberSummationDetailReport }) => {
  const { t } = useTranslation();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);


  useEffect(() => {
    setIsQueryingDetail(true);
    const obj = {
      beginDate: moment(data.beginDate).format('YYYY-MM-DD'), endDate: moment(data.endDate).format('YYYY-MM-DD'), loginName: data.loginName
    };
    getMemberSummationDetailReport(obj, () => setIsQueryingDetail(false));
  }, []);


  return (
    <div className="winLostDetailModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex justify-content-center align-items-center'}`}
          xs={{
            size: 4, offset: 4
          }}
        >
          输赢详情
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <Icon
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            libName="io"
            value={{
              name: 'IoClose',
              className: 'h4 mb-0 float-right closeIcon',
            }}
          />
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center">
        <Row className="modalContent d-flex p-2 justify-content-center">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={[{
              label: '游戏类型',
            }, {
              label: '有效投注',
            }, {
              label: '输赢',
            }, {
              label: '本级结果',
            }, {
              label: '操作',
            }]}
            tableData={memberSummationDetailReport || []}
            tableBodyRowProperty={[
              data => (<Label className="d-flex justify-content-center">{data.lotteryName}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.validAmount}</Label>),
              data => (<Label className={`d-flex justify-content-center ${parseFloat(data.winLost) > 0 ? 'text-primary' : 'text-danger'}`}>{data.winLost}</Label>),
              data => (<Label className={`d-flex justify-content-center ${parseFloat(data.totalResult) > 0 ? 'text-primary' : 'text-danger'}`}>{data.totalResult}</Label>),
              obj => (<Label className="d-flex justify-content-center"><div onClick={() => data.func(obj.lotteryCode, obj.lotteryName)} className="operationBtn serchAndDetail">详情</div></Label>),
            ]}
          />
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onConfirmationClick) data.onConfirmationClick();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WinLostDetailModal);