import React, { useEffect, useState } from 'react';

import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../..';
import operationLogActions from '../../../redux/actions/operationLogActions';
import './styles.scss';


const mapStateToProps = state => ({
  handlerLog: state.operationLogReducers.handlerLog,
});

const mapDispatchToProps = dispatch => ({
  GetHandlerLog: (obj, callback) => dispatch(operationLogActions.GetHandlerLog(obj, callback)),
});

const HandlerLog = ({ GetHandlerLog, handlerLog, data }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);

  const [_loadLoginLogs, setloadLoginLogs] = useState();

  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(true);
    GetHandlerLog({
      orgId: data?.orgId || data?.memberId, page: pageNum, rows
    }, setIsQuerying);
    setPage(pageNum);
    setRows(rows);
  };

  useEffect(() => {
    setloadLoginLogs(handlerLog);
  }, [handlerLog]);

  useEffect(() => {
    if (data) {
      const obj = {
        orgId: data.orgId || data?.memberId, page: _page, rows: _rows
      };
      setIsQuerying(true);
      GetHandlerLog(obj, setIsQuerying);
    }
  }, []);

  return (
    <div className="handlerLogWrapper">
      <div className="mx-0 bg-white my-3  border-1">
        <AgentDataTable
          isQuerying={_isQuerying}
          tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: '操作内容',
            }, {
              label: '操作前旧值',
            }, {
              label: '操作后新值'
            }, {
              label: '操作时间',
            }, {
              label: '操作人',
            }, {
              label: '被改账号'
            }]
          }
          tableHeaderStyle={
            ['smallCol']
          }
          tableData={_loadLoginLogs ? _loadLoginLogs.data : []}
          tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.rowNo}</Label>),
              data => (<Label className="d-flex justify-content-center noWrapStyle">{data.operation}</Label>),
              data => (<Label className="d-flex oldValueStyle">{data.oldValue}</Label>),
              data => (<Label className="d-flex newValueStyle">{data.newValue}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.operationDate).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (<Label className="d-flex justify-content-center noWrapStyle">{data.loginId}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.remark}</Label>),
            ]
          }
        />
      </div>
      <div className="justify-center w-full relative">
        <Pagination totalRecord={_loadLoginLogs ? _loadLoginLogs?.sum?.totalRecords : 0} onClick={(pageNum, rows) => onPaginationClick(pageNum, rows)} isQuery={false} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HandlerLog);