import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  editExchangeRateData: state.systemReducers.editExchangeRateData,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getEditExRate: (id, callback) => dispatch(systemActions.getEditExRate(id, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveExrate: (obj, callback) => dispatch(systemActions.saveExrate(obj, callback)),
});

const ExRateSettingAddEdit = ({ callback = {}, isEdit, data, getEditExRate, updateModalAlert, editExchangeRateData, saveExrate }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);

  useEffect(() => {
    if (isEdit) {
      getEditExRate({
        id: data?.id || ''
      });
    }
  }, [data]);

  useEffect(() => {
    if (!isEdit) {
      setInfo([]);
    } else if (editExchangeRateData && JSON.stringify(editExchangeRateData) !== JSON.stringify(_info)) {
      setInfo(editExchangeRateData);
    }
  }, [editExchangeRateData]);


  const saveButton = () => {
    if (_info?.isChange) {
      const updateObj = _info;
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      saveExrate(updateObj, callback);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '无变动'
        }
      });
    }
  };

  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    newItems.isChange = true;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    if (!isEdit) {
      callback();
    } else {
      getEditExRate({
        id: data?.id || ''
      });
    }
  };

  const status = [
    {
      label: '是',
      value: '0',
    },
    {
      label: '否',
      value: '1',
    }
  ];

  return (
    <div className="ExRateSettingAddEditWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="m-0 my-1 p-0">
          <table className="p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="titleHeader" colSpan={2}>
                  {isEdit === true ? '编辑全局提款设置' : '新增全局提款设置'}
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('CURRENCY_CODE')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.currencyCode || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'currencyCode')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('CURRENCY_NAME')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.currencyName?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'currencyName')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('WITHDRAW_RATE')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.withdrawRate?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'withdrawRate')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('WITHDRAW_FLOAT_RATE')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.withdrawFloatRate?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'withdrawFloatRate')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('DEPOSIT_RATE')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.depositRate?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'depositRate')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('DEPOSIT_FLOAT_RATE')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.depositFloatRate?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'depositFloatRate')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('AUTO_RENEW_EX_RATE')}</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      status.map(d => (
                        <div key={`rdTypeGroup_${d.value}`}>
                          <Input
                            id={`rdType_${d.value}`}
                            type="radio"
                            name="rdType"
                            value={_info?.isAutoRenew}
                            checked={_info?.isAutoRenew === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'isAutoRenew')}
                          />
                          <Label
                            for={`rdType_${d.value}`}
                            className={_info?.isAutoRenew === Number(d.value) ? 'active mx-2 smallLabel' : 'mx-2 smallLabel'}
                          >
                            {d?.label}
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">{t('EXCHANGE_RATE_REMARK')}</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.autoInfo || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'autoInfo')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">备注</td>
                <td>
                  <span>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.exRateRemark}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'exRateRemark')}
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExRateSettingAddEdit);