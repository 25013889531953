import { StatusCodes } from 'http-status-codes';

import { SET_WARN_SETTING } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setBogusWarning = val => ({
  type: SET_WARN_SETTING,
  value: val
});


const GetBogusWarning = lotteryType => dispatch => {
  apiService.get(`/BogusBetWarning/GetBogusWarning/${lotteryType}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(setBogusWarning(data.result));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const DelWarnSetting = (id, uid, lotteryType) => dispatch => {
  apiService.post('/BogusBetWarning/DelWarnSetting', {
    id, uid, lotteryType
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        return dispatch(GetBogusWarning(lotteryType));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    Promise.reject(error);
  });
};

const SaveWarnSetting = obj => dispatch => {
  apiService.post('/BogusBetWarning/SaveWarnSetting', {
    ...obj
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        return dispatch(GetBogusWarning(obj.lotteryType));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    Promise.reject(error);
  });
};

const EditWarnSetting = (obj, callback = () => {}) => dispatch => {
  apiService.post('/BogusBetWarning/EditWarnSetting', {
    ...obj
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        callback();
        dispatch(appActions.updateModalAlert({
          visible: false
        }));
        return dispatch(GetBogusWarning(obj.lotteryType));
      }
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    Promise.reject(error);
  });
};

export default {
  GetBogusWarning,
  DelWarnSetting,
  SaveWarnSetting,
  EditWarnSetting,
};