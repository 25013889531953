import React, { useEffect, useState } from 'react';

import { Input, Radio } from 'element-react';
import { connect } from 'react-redux';

import { appActions, cashActions, transactionActions, withdrawalActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  withdrawalInfo: state.withdrawalReducers.withdrawalInfo,
  memberInfo: state.cashReducers.memberInfo,
});

const mapDispatchToProps = dispatch => ({
  GetInfo: () => dispatch(withdrawalActions.GetInfo()),
  Save: obj => dispatch(withdrawalActions.Save(obj)),
  SaveUserTransfer: obj => dispatch(transactionActions.SaveUserTransfer(obj)),
  GetCashMemberInfo: () => dispatch(cashActions.GetCashMemberInfo()),
  SaveCashMemberSetting: (obj, callback) => dispatch(cashActions.SaveCashMemberSetting(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  SendBindPhoneCode: callback => dispatch(cashActions.SendBindPhoneCode(callback)),
  CheckBindPhoneCode: (obj, callback) => dispatch(cashActions.CheckBindPhoneCode(obj, callback)),
  SendPhoneVerificationCode: (obj, callback) => dispatch(cashActions.SendPhoneVerificationCode(obj, callback)),
  CheckPhoneRemoveCode: (obj, callback) => dispatch(cashActions.CheckPhoneRemoveCode(obj, callback)),

  SendEmailVerificationCode: callback => dispatch(cashActions.SendEmailVerificationCode(callback)),
  CheckEmailVerificationCode: (obj, callback) => dispatch(cashActions.CheckEmailVerificationCode(obj, callback)),
  SendEmailRemoveCode: (obj, callback) => dispatch(cashActions.SendEmailRemoveCode(obj, callback)),
  CheckEmailRemoveCode: (obj, callback) => dispatch(cashActions.CheckEmailRemoveCode(obj, callback)),
  SetWithdrawPW: (obj, callback) => dispatch(cashActions.SetWithdrawPW(obj, callback)),
});

const SafetySetting = ({ memberInfo, GetCashMemberInfo, SaveCashMemberSetting, updateModalAlert, SetWithdrawPW, SendBindPhoneCode, SendPhoneVerificationCode, CheckBindPhoneCode, CheckPhoneRemoveCode, SendEmailVerificationCode, CheckEmailVerificationCode, SendEmailRemoveCode, CheckEmailRemoveCode }) => {
  const [name, setName] = useState();
  const [sex, setSex] = useState('男');

  useEffect(() => {
    GetCashMemberInfo();
  }, []);

  useEffect(() => {
    if (memberInfo) {
      setName(memberInfo.familyName);
      setSex(memberInfo.sex);
    }
  }, [memberInfo]);

  const onClickSave = () => {
    const _obj = {
      name,
      sex,
    };
    SaveCashMemberSetting(_obj, () => GetCashMemberInfo());
  };

  const clickToOpen = (modalType, data = '') => {
    if (modalType === 'phone') {
      updateModalAlert({
        type: 'safePhone',
        visible: true,
        data: {
          phoneNum: data,
          onConfirmationClick: _data => {
            if (data === '') {
              CheckBindPhoneCode(_data.verifCode, () => GetCashMemberInfo());
            } else {
              CheckPhoneRemoveCode(_data.verifCode, () => GetCashMemberInfo());
            }
          },
          onSendCodeClick: _data => {
            if (data === '') {
              SendBindPhoneCode(_data.PhoneNum, () => GetCashMemberInfo());
            } else {
              SendPhoneVerificationCode(() => GetCashMemberInfo());
            }
          }
        }
      });
    }
    if (modalType === 'email') {
      updateModalAlert({
        type: 'email',
        visible: true,
        data: {
          safeEmail: data,
          onConfirmationClick: _data => {
            if (data === '') {
              CheckEmailVerificationCode(_data.verifCode, () => GetCashMemberInfo());
            } else {
              CheckEmailRemoveCode(_data.verifCode, () => GetCashMemberInfo());
            }
          },
          onSendCodeClick: _data => {
            if (data === '') {
              SendEmailVerificationCode(_data.mail, () => GetCashMemberInfo());
            } else {
              SendEmailRemoveCode(() => GetCashMemberInfo());
            }
          }
        }
      });
    }
    if (modalType === 'safepass') {
      updateModalAlert({
        type: 'safePassword',
        visible: true,
        data: {
          safePassword: data,
          onConfirmationClick: _data => {
            SetWithdrawPW({
              ..._data, action: data === '' ? 'set' : 'change'
            }, () => GetCashMemberInfo());
          }
        }
      });
    }
  };

  const cancel = () => {
    setName('');
    setSex('男');
  };

  return (
    <div className="SafetySettingWrapper">
      <div className="orderInformationTable">
        <div className="evenRowStyle">
          <div>账户余额</div>
          <div>
            {memberInfo && memberInfo.moneyLimit}
          </div>
        </div>
        <div className="evenRowStyle">
          <div>安全手机</div>
          <div>
            <Input disabled value={memberInfo && memberInfo.safePhoneCut} />
            <div className="operationBtn reset ms-3" onClick={() => clickToOpen('phone', memberInfo?.safePhone || '')}>{memberInfo && memberInfo.safePhoneCut === '' ? '绑定' : '更换'}</div>
          </div>
        </div>
        <div className="evenRowStyle">
          <div>安全邮箱</div>
          <div>
            <Input disabled value={memberInfo && memberInfo.safeEmailCut} />
            <div className="operationBtn reset ms-3" onClick={() => clickToOpen('email', memberInfo?.safeEmail || '')}>{memberInfo && memberInfo.safeEmailCut === '' ? '绑定' : '更换'}</div>
          </div>
        </div>
        <div className="evenRowStyle">
          <div>姓名</div>
          <div>
            <Input
              value={name}
              onChange={text => setName(text)}
            />
          </div>
        </div>
        <div className="evenRowStyle">
          <div>提款密码</div>
          <div>
            <Input disabled type="password" value={memberInfo && memberInfo.safePass} />
            <div className="operationBtn reset ms-3" onClick={() => clickToOpen('safepass', memberInfo?.safePass || '')}>{(memberInfo && memberInfo?.safePass) ? '更换' : '设置'}</div>
          </div>
        </div>
        <div className="evenRowStyle">
          <div>性别</div>
          <div>
            <Radio value="男" checked={sex === '男'} onChange={code => setSex(code)}>男</Radio>
            <Radio value="女" checked={sex === '女'} onChange={code => setSex(code)}>女</Radio>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center py-3">
        <div className="operationBtn" onClick={() => onClickSave()}>确认</div>
        <div className="operationBtn cancel ms-3" onClick={() => cancel()}>取消</div>
      </div>

    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SafetySetting);