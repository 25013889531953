import React, { useEffect, useState } from 'react';

import { Input } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Pagination } from '../../components';
import { onlineStatisticsActions } from '../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  onlineUserList: state.onlineStatisticsReducers.onlineUserList,
  onlineLevelSum: state.onlineStatisticsReducers.onlineLevelSum,
});

const mapDispatchToProps = dispatch => ({
  getOnlineUserList: obj => dispatch(onlineStatisticsActions.getOnlineUserList(obj)),
  getOnlineLevelSum: () => dispatch(onlineStatisticsActions.getOnlineLevelSum()),
  kickOut: (userType, userId, queryData) => dispatch(onlineStatisticsActions.kickOut(userType, userId, queryData)),
});

const OnlineStatistics = ({ onlineUserList, onlineLevelSum, getOnlineUserList, getOnlineLevelSum, kickOut }) => {
  const { t } = useTranslation();
  const [_onlineUser, setOnlineUser] = useState();
  const [_onlineLevelSum, setOnlineLevelSum] = useState();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_levelValue, setLevelValue] = useState('-1');
  const [_loginId, setLoginId] = useState('');
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);

  const getUserList = (levelValue = '-1', loginId = '', page = 1, rows = 15) => {
    setLevelValue(levelValue);
    getOnlineUserList({
      levelValue, loginId, page, rows
    });
  };

  const kickOff = (userType, userId) => {
    setPage(1);
    setRows(15);
    setIsQuerying(false);
    kickOut(userType, userId, {
      levelValue: _levelValue, loginId: '', page: 1, rows: 15
    });
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(false);
    getUserList(
      _levelValue, '', pageNum, rows
    );
    setPage(pageNum);
    setRows(rows);
  };

  useEffect(() => {
    getUserList('-1', '', _page, _rows);
    getOnlineLevelSum();
  }, []);

  useEffect(() => {
    setPage(1);
    setRows(15);
  }, [_levelValue]);

  useEffect(() => {
    if (onlineLevelSum && onlineLevelSum?.data) {
      const obj = {};
      let count = 0;
      onlineLevelSum.data.forEach(item => {
        obj[item.levelValue] = item.sumCount;
        count += item.sumCount;
      });

      setOnlineLevelSum([{
        ...obj, count
      }]);
    }
  }, [onlineLevelSum]);

  useEffect(() => {
    if (onlineUserList) {
      setOnlineUser(onlineUserList);
    }
  }, [onlineUserList]);

  return (
    <div className="onlineStatisticsWrapper p-3">
      <CommonHeader headerName={t('ONLINE_STATISTICS')} />
      <div className="searchBox my-3">
        <div>{t('LOGIN_NAME')}</div>
        <Input
          className="inputStyle"
          value={_loginId}
          onChange={e => { setLoginId(e.target.value.trim()); }}
        />
        <div className="operationBtn" onClick={() => getUserList('-1', _loginId)}>{t('FIND')}</div>
      </div>
      <div className="mx-0 mb-3 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={false}
          tableHeaderTitle={
            [{
              label: t('ALL'),
            }, {
              label: t('TOTAL_BALANCE'),
            }, {
              label: t('TOP_COMPANY'),
            }, {
              label: t('LEVEL2_AGENT'),
            }, {
              label: t('LEVEL3_AGENT'),
            }, {
              label: t('LEVEL4_AGENT'),
            }, {
              label: t('LEVEL5_AGENT'),
            }, {
              label: t('LEVEL6_AGENT'),
            }, {
              label: t('LEVEL7_AGENT'),
            }, {
              label: t('LEVEL8_AGENT'),
            }, {
              label: t('LEVEL9_AGENT'),
            }, {
              label: t('LEVEL10_AGENT'),
            }, {
              label: t('MEMBER'),
            }]
          }
          tableHeaderStyle={
            ['smallCol']
          }
          tableData={_onlineLevelSum || []}
          tableBodyRowProperty={
            [
              data => (<div className="d-flex justify-content-center url" onClick={() => getUserList('-1')}>{data.count}</div>),
              () => (<div className="d-flex justify-content-center">{_onlineUser ? _onlineUser?.sum?.totalBalance : 0}</div>),
              data => (<div className="d-flex justify-content-center url" onClick={() => getUserList('1')}>{data['1'] || 0}</div>),
              data => (<div className="d-flex justify-content-center url" onClick={() => getUserList('2')}>{data['2'] || 0}</div>),
              data => (<div className="d-flex justify-content-center url" onClick={() => getUserList('3')}>{data['3'] || 0}</div>),
              data => (<div className="d-flex justify-content-center url" onClick={() => getUserList('4')}>{data['4'] || 0}</div>),
              data => (<div className="d-flex justify-content-center url" onClick={() => getUserList('5')}>{data['5'] || 0}</div>),
              data => (<div className="d-flex justify-content-center url" onClick={() => getUserList('6')}>{data['6'] || 0}</div>),
              data => (<div className="d-flex justify-content-center url" onClick={() => getUserList('7')}>{data['7'] || 0}</div>),
              data => (<div className="d-flex justify-content-center url" onClick={() => getUserList('8')}>{data['8'] || 0}</div>),
              data => <div className="d-flex justify-content-center url" onClick={() => getUserList('9')}>{data['9'] || 0}</div>,
              data => (<div className="d-flex justify-content-center url" onClick={() => getUserList('10')}>{data['10'] || 0}</div>),
              data => <div className="d-flex justify-content-center url" onClick={() => getUserList('11')}>{data['11'] || 0}</div>,
            ]
          }
        />
      </div>
      <div className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQuerying}
          tableHeaderTitle={
            [{
              label: t('LEVEL'),
            }, {
              label: t('LOGIN_NAME'),
            }, {
              label: t('NAME'),
            }, {
              label: t('CLIENT_PLATFORM'),
            }, {
              label: t('ACCOUNT_BALANCE'),
            }, {
              label: t('PAN_TYPE'),
            }, {
              label: 'IP',
            }, {
              label: t('ADDRESS'),
            }, {
              label: t('LINE'),
            }, {
              label: t('LAST_ACTIVITY_TIME'),
            }, {
              label: t('OPERATION'),
            }]
          }
          tableHeaderStyle={
            ['smallCol']
          }
          tableData={_onlineUser ? _onlineUser?.data : []}
          tableBodyRowProperty={
            [
              data => (<div className="d-flex justify-content-center">{data.levelName}</div>),
              data => (<div className="d-flex justify-content-center">{data.loginId}</div>),
              data => (<div className="d-flex justify-content-center">{data.familyName}</div>),
              data => (<div className="d-flex justify-content-center">{data.client}</div>),
              data => (<div className="d-flex justify-content-center">{data.balance}</div>),
              data => (<div className="d-flex justify-content-center">{data.oddsType}</div>),
              data => (<div className="d-flex justify-content-center">{data.loginIP}</div>),
              data => (<div className="d-flex justify-content-center">{data.loginIPAddress}</div>),
              data => (<div className="d-flex justify-content-center">{data.line}</div>),
              data => (<div className="d-flex justify-content-center">{moment.unix(data.loginTime).format('YYYY-MM-DD HH:mm:ss')}</div>),
              data => <div className="d-flex justify-content-center operationBtn w-50 m-auto" onClick={() => kickOff(data.userType, data.userId)}>踢出</div>
            ]
          }
        />
      </div>

      <div className="justify-center w-full relative">
        <Pagination totalRecord={_onlineUser ? _onlineUser?.sum?.totalRecord : 0} onClick={(pageNum, rows) => onPaginationClick(pageNum, rows)} isQuery={_isQuerying} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlineStatistics);