import React from 'react';

import * as Ai from 'react-icons/ai';
import * as Bi from 'react-icons/bi';
import * as Bs from 'react-icons/bs';
import * as Fa from 'react-icons/fa';
import * as Fi from 'react-icons/fi';
import * as Hi from 'react-icons/hi';
import * as Im from 'react-icons/im';
import * as Io from 'react-icons/io5';
import * as Md from 'react-icons/md';
import * as Ri from 'react-icons/ri';
import * as Si from 'react-icons/si';

const Icon = ({ libName, value, onClick }) => {
  let Icon;
  switch (libName) {
    case 'ai':
      Icon = Ai[value.name];
      break;
    case 'bi':
      Icon = Bi[value.name];
      break;
    case 'bs':
      Icon = Bs[value.name];
      break;
    case 'fi':
      Icon = Fi[value.name];
      break;
    case 'fa':
      Icon = Fa[value.name];
      break;
    case 'hi':
      Icon = Hi[value.name];
      break;
    case 'io':
      Icon = Io[value.name];
      break;
    case 'im':
      Icon = Im[value.name];
      break;
    case 'md':
      Icon = Md[value.name];
      break;
    case 'ri':
      Icon = Ri[value.name];
      break;
    case 'si':
      Icon = Si[value.name];
      break;
    default:
      break;
  }
  return <Icon color={value.color} size={value.size} onClick={onClick} className={value.className} />;
};

export default Icon;