import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { Loading } from 'element-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { agentActions, appActions, companyActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Icon from '../../Icon';

import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  upOrgsCopyData: state.companyReducers.upOrgsCopyData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getCopyUpOrgs: (id, lotType) => dispatch(companyActions.getCopyUpOrgs(id, lotType)),
  copyOddsDiff: obj => dispatch(agentActions.copyOddsDiff(obj)),
});

const CopyOddsDiffModal = ({
  updateModalAlert,
  data,
  getCopyUpOrgs,
  upOrgsCopyData,
  copyOddsDiff
}) => {
  const { t } = useTranslation();
  const [_detailItem, setDetailItem] = useState();
  const [_selectedField, setSelectedField] = useState();
  const [_selectedName, setSelectedName] = useState();
  const [_uniqueString, setUniqueString] = useState([]);
  const [_warningText, setWarningText] = useState([]);
  const [_loading, setIsLoading] = useState(false);

  useEffect(() => {
    // Default search when first load
    data && data?.orgId && getCopyUpOrgs(data?.orgId, data.lotType);
    if (data?.lotteryList) {
      setDetailItem(data?.lotteryList);
      const mykey = 'categoryName';
      setUniqueString([...new Map(data?.lotteryList.sort((a, b) => a.categoryCode - b.categoryCode).map(item => [item[mykey], item])).values()]);
    }
  }, [data]);


  const saveButton = () => {
    const values = Array
      .from(document.querySelectorAll('[name^="main_"]:checked'))
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);
    if (values?.length > 0 && _selectedField) {
      setIsLoading(true);
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const obj = {
        fromOrg: _selectedField,
        toOrg: data?.orgId,
        lotteryTypes: values,
        lotteryType: data.lotType
      };
      copyOddsDiff(obj);
      setWarningText('');
    } else {
      setWarningText('请选择代理以及彩种');
    }
  };

  const mainCheckBoxClick = (id, catCode) => {
    const cb = document.querySelector(id);
    if (cb) {
      const aInputs = document.getElementsByName(`main_${catCode}`);
      for (let i = 0, n = aInputs?.length || 0; i < n; i++) {
        aInputs[i].checked = cb?.checked;
      }
    }
  };


  const tableHeader = [{
    label: '彩种选择',
    colSpan: 2
  }];

  const tableContent = [
    data => (
      <div className="d-flex justify-content-end m-1 p-1">
        <Label className="px-2">{data?.categoryName}</Label>
        <Input
          id={`main_${data.categoryCode}`}
          type="checkbox"
          className="m-1 p-1 align-items-center"
          onClick={() => mainCheckBoxClick(`#main_${data.categoryCode}`, data?.categoryCode)}
        />
        <Label className="">所有</Label>
      </div>
    ),
    data => (
      <div className="flex-wrap p-0 m-0 d-flex">
        {
          _detailItem.filter(x => x.categoryCode === data.categoryCode).map(d => (
            <div key={`returnSelection_${d?.code}`} className="p-1">
              <Input
                id={`chkConsolidate_${d.code}`}
                type="checkbox"
                name={`main_${data.categoryCode}`}
                value={d.code}
                className="m-1 p-1 justify-content-center align-items-center"
              />
              {d.lotteryName}
            </div>
          ))
        }
      </div>
    ),
  ];

  /* When the user clicks on the button,
toggle between hiding and showing the dropdown content */
  const myFunction = () => {
    document.getElementById('copyOddsDiffDDL').classList.toggle('show');
  };

  const filterFunction = text => {
    const filter = text.toUpperCase();
    const div = document.getElementById('copyOddsDiffDDL');
    const a = div.getElementsByTagName('p');
    for (let i = 0; i < a.length; i++) {
      const txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = '';
      } else {
        a[i].style.display = 'none';
      }
    }
  };

  return (
    <div className="copyOddsDiffModalWrapper">
      {
        _loading && (
          <div className="black loadingwrapper justify-content-center">
            <Loading />
          </div>
        )
      }
      <Row className="modalHeader">
        <Col
          className={`${'d-flex justify-content-center align-items-center'}`}
          xs={{
            size: 4, offset: 4
          }}
        >
          退水赔率差复制
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <Icon
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            libName="io"
            value={{
              name: 'IoClose',
              className: 'h4 mb-0 float-right closeIcon',
            }}
          />
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center">
        <Row className="p-2 my-2 align-items-left">
          <Col>
            <Label className="justify-content-center">数据来源：</Label>
            <div className="dropdown">
              <div onClick={() => myFunction()} className="dropbtn d-flex">
                <Label className="wlimit">{_selectedName || '请选择' }</Label>
                <div className="p-0 m-0">
                  <Icon
                    libName="fa"
                    value={{
                      name: 'FaCaretDown',
                    }}
                  />
                </div>
              </div>
              <div id="copyOddsDiffDDL" className="dropdown-content">
                <Input type="text" className="toStick" placeholder={`${t('SEARCH')}..`} id="myInput" onChange={({ target: { value: text } }) => filterFunction(text)} />
                <div className="tooverflow">
                  {
                        upOrgsCopyData && upOrgsCopyData.map(d => (
                          <p
                            key={`returnSelection_${d?.organizationId}`}
                            onClick={() => {
                              setSelectedName(`从 ${d?.loginId} 复制`);
                              setSelectedField(d?.organizationId);
                              document.getElementById('copyOddsDiffDDL').classList.toggle('show');
                            }}
                            className={_selectedField === d?.organizationId && 'hightlight'}
                          >
                            {`从 ${d?.loginId} 复制`}
                          </p>
                        ))
                        }
                </div>
              </div>
            </div>
            <Label className="px-2 text-danger">{_warningText}</Label>
          </Col>
        </Row>
        <Row className="tableWrapper d-flex p-2 justify-content-center">
          <AgentDataTable
            rowSpanHeader={tableHeader}
            tableHeaderTitle={[]}
            tableData={_uniqueString}
            tableBodyRowProperty={tableContent}
            tableBodyRowStyle={['backgroundGrey', 'align-content-start']}
          />
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => saveButton()}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
          }}
        >
          {(data && data.confirmText) || t('CANCEL')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyOddsDiffModal);