import React, { useEffect, useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { Input, Select } from 'element-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import Icon from '../../Icon';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  bankCards: state.systemReducers.bankCards
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  GetBankCardList: (obj, callback) => dispatch(systemActions.GetBankCardList(obj, callback)),
});

const BankCardModal = ({ updateModalAlert, data, GetBankCardList, bankCards }) => {
  const { t } = useTranslation();

  const [safePass, setsafePass] = useState('');
  const [bankCodeExt, setbankCodeExt] = useState('');
  const [bankNo, setbankNo] = useState();
  const [address, setaddress] = useState('');
  const [bankCode, setbankCode] = useState('');
  const [bankItem, setBankItem] = useState();

  const addNewCard = () => {
    if (data?.type === 'new') {
      const _obj = {
        safePass,
        bankCodeExt,
        bankNo,
        address,
        bankCode,
      };
      data.onConfirmationClick(_obj);
    }
  };

  useEffect(() => {
    if (bankItem) {
      setbankCodeExt(bankItem.type);
      setbankCode(bankItem.id);
    }
  }, [bankItem]);

  useEffect(() => {
    GetBankCardList({
      status: 0
    });
  }, []);

  return (
    <div className="BankCardModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className="d-flex align-items-center"
        >
          银行卡/数字钱包
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <Icon
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
            libName="io"
            value={{
              name: 'IoClose',
              className: 'h4 mb-0 float-right closeIcon',
            }}
          />
        </Col>
      </Row>
      {
        data?.type === 'new'
          ? (
            <div className="modalContent d-flex justify-content-center mx-0 p-2 ">
              <div className="orderInformationTable">
                <div className="evenRowStyle">
                  <div>真实姓名</div>
                  <div>{data?.bankUName}</div>
                </div>
                <div className="evenRowStyle">
                  <div>所属银行</div>
                  <div>
                    <Select
                      popper-class="select-popper"
                      value={bankItem}
                      onChange={value => setBankItem(value)}
                    >
                      {
                    bankCards && bankCards.cardList.map(item => (
                      <Select.Option key={`UserBetStatus_${item.id}`} label={item.name} value={item} />
                    ))
                }
                    </Select>
                  </div>
                </div>
                <div className="evenRowStyle">
                  <div>
                    {bankItem && bankItem?.type === 'DIGITAL'
                      ? '钱包地址'
                      : '银行卡号'}
                  </div>
                  <div>
                    <Input
                      value={bankNo}
                      onChange={text => {
                        if (bankItem?.type === 'DIGITAL') {
                          return setbankNo(text.trim());
                        }
                        if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                          return setbankNo(text === '' ? 0 : parseInt(text, 10));
                        }
                      }}
                    />
                  </div>
                </div>
                {bankItem && bankItem?.type === 'DIGITAL' ? ''
                  : (
                    <div className="evenRowStyle">
                      <div>开户地址</div>
                      <div>
                        <Input type="text" value={address} onChange={text => setaddress(text.trim())} />
                      </div>
                    </div>
                  )}
                <div className="evenRowStyle">
                  <div>提款密码</div>
                  <div>
                    <Input value={safePass} type="password" onChange={text => setsafePass(text.trim())} />
                  </div>
                </div>
              </div>
            </div>
          )
          : (
            <div className="modalContent d-flex justify-content-center mx-0 p-2 ">
              <div className="orderInformationTable">
                <div className="evenRowStyle">
                  <div>真实姓名</div>
                  <div>
                    {data?.bankUName}
                  </div>
                </div>
                <div className="evenRowStyle">
                  <div>所属银行</div>
                  <div>
                    <Input disabled value={data?.bankName} />
                  </div>
                </div>
                <div className="evenRowStyle">
                  <div>银行卡号</div>
                  <div>
                    <Input disabled value={data?.bankNumber} />
                  </div>
                </div>
                <div className="evenRowStyle">
                  <div>开户地址</div>
                  <div>
                    <Input disabled value={data?.bankAddress} />
                  </div>
                </div>
              </div>
            </div>
          )
      }
      <Row className="modalFooter d-flex align-items-center ">
        <Button
          className="confirmButton my-3"
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            addNewCard();
          }}
        >
          {t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BankCardModal);