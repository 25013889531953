import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { Radio, Select, Tabs } from 'element-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AgentDataTable from '../../AgentDataTable';
import mainGames from '../../../constants/QuickSetVariable/Games';

import Icon from '../../Icon';

import { agentActions, appActions, companyActions } from '../../../redux/actions';
import Skeleton from '../../Skeleton';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  oddsDiffData: state.agentReducers.oddsDiffData,
  userData: state.memberReducers.userData,
  oddsDiffUpOrgsData: state.companyReducers.oddsDiffUpOrgsData,
});

const mapDispatchToProps = dispatch => ({
  getOddsDifferent: (orgId, lotType, callback) => dispatch(agentActions.getOddsDifferent(orgId, lotType, callback)),
  getOddsDiffUpOrgs: (orgId, lotType, callback) => dispatch(companyActions.getOddsDiffUpOrgs(orgId, lotType, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveOddsDifference: (obj, callback) => dispatch(agentActions.saveOddsDifference(obj, callback)),
  resetOddsDiffData: () => dispatch(agentActions.resetOddsDiffData()),
});

const OddsDiff = ({
  getOddsDifferent,
  data,
  userData,
  oddsDiffData,
  updateModalAlert,
  saveOddsDifference,
  resetOddsDiffData,
  getOddsDiffUpOrgs,
  oddsDiffUpOrgsData
}) => {
  const { t } = useTranslation();
  const [_platetInput, setPlateInput] = useState('');
  const [_selectedLotType, setSelectedLotType] = useState(1);
  const [_header, setHeader] = useState([]);
  const [_firstheader, setfirstHeader] = useState([]);
  const [_uniqueString, setUniqueString] = useState([]);
  const [_content, setContent] = useState([]);
  const [_selectedSearchOption, setSearchOption] = useState(1);
  const [_selectedTab, setSelectedTab] = useState('');
  const [_allLotList, setAllLotList] = useState([]);
  const [_lotList, setLotList] = useState([]);
  const [_activeLotteryCode, setActiveLotteryCode] = useState();
  const [_tempData, setTempData] = useState([]);
  const [_userGames, setUserGames] = useState([]);
  const [_rowSpanCol, setRowSpanCol] = useState([0]);
  const [_selectedField, setSelectedField] = useState();
  const [_selectedValue, setSelectedValue] = useState(0);
  const [_selectedCompany, setSelectedCompany] = useState();
  const [_companyId, setCompanyId] = useState();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [_isUpdate, setIsUpdate] = useState(false);
  const [_oddsUpOrgs, setOddsUpOrgs] = useState([]);
  const [_data, setData] = useState();
  let bufferData = [];

  useEffect(() => {
    // Default search when first load
    if (data?.orgId) {
      getOddsDiffUpOrgs(userData?.id, 2);
      setSelectedCompany(data?.isChild === 1 ? data?.upOrgId : data?.orgId);
      setCompanyId(`${data?.loginId} (分公司)`);
    }
    setData(data);
  }, [data]);

  useEffect(() => {
    if (_selectedCompany) {
      setIsQueryingDetail(true);
      resetOddsDiffData();
      if (_selectedCompany || data?.orgId) {
        getOddsDifferent(_selectedCompany || data?.orgId, _selectedLotType - 1);
      }
      setIsUpdate(false);
    }
  }, [_selectedCompany]);

  useEffect(() => {
    setOddsUpOrgs(oddsDiffUpOrgsData);
  }, [oddsDiffUpOrgsData]);

  useEffect(() => {
    let isMounted = true;
    fetchData();
    return () => {
      resetOddsDiffData();
      isMounted = false;
    };
    function fetchData() {
      if (oddsDiffData && oddsDiffData?.memberGame && isMounted) {
        if (oddsDiffData?.lotteryList) {
          const selLot = oddsDiffData?.lotteryList?.filter(x => x.templateCode === 'HK6' && x.templateCode !== 'F31X7' && x.templateCode !== 'F36X7').length > 0 ? 2
            : (oddsDiffData?.lotteryList?.filter(x => x.templateCode === 'F31X7' || x.templateCode === 'F36X7').length > 0 ? 3 : 1);
          if (JSON.stringify(oddsDiffData?.lotteryList) !== JSON.stringify(_allLotList)) {
            if (selLot !== _selectedLotType && oddsDiffData?.lotteryList[0]?.code !== _allLotList[0]?.code) {
              setSelectedLotType(selLot);
            }
            setAllLotList(oddsDiffData?.lotteryList);
          }
        }
        const list = [...oddsDiffData?.oddsList];
        oddsDiffData?.oddsList.forEach((curr, myInd) => {
          const sameList = oddsDiffData?.oddsList.filter(x => x.settingCode === curr.settingCode && x.lotteryCode === curr.lotteryCode);
          const index = Number(sameList?.findIndex(item => item.id === curr.id));
          if (sameList.length >= 1 && index !== -1) {
            if (index === 0) {
              list[myInd].rowSpan = sameList.length;
            } else {
              list[myInd].rowSpan = 'nil';
              list[myInd].orderBy = sameList[0].orderBy + (sameList[index].orderBy * 0.01);
            }
          }
          list[myInd].templateCode = oddsDiffData?.lotteryList?.filter(x => x.code === curr.lotteryCode)[0]?.templateCode;
          list[myInd].code = curr.settingCode;
          list[myInd].dOddsA = curr.oddsA;
          list[myInd].dOddsB = curr.oddsB;
          list[myInd].dOddsC = curr.oddsC;
          list[myInd].dOddsD = curr.oddsD;
        });
        bufferData = [...list];
        setTempData([...list]);
        setIsQueryingDetail(false);
        const tempHead = [];
        const tempContent = [];
        const tempFirstHead = [];
        const tempRowSpan = [0];
        tempContent.push(editDataContent[0]);
        tempContent.push(editDataContent[1]);
        tempFirstHead.push(firstfixHeader[0]);
        // add according to user's games
        setUserGames(oddsDiffData?.memberGame);
        Array.from(oddsDiffData?.memberGame).forEach((d, index) => {
          tempRowSpan.push(3 + index + index);
          tempFirstHead.push({
            label: `${d + t('PLATE')}`,
            colSpan: 2
          });
          tempHead.push(fixHeader[0]);
          tempHead.push(fixHeader[1]);
          tempContent.push(editDataContent[getIndex(d)]);
          tempContent.push(editDataContent[getIndex(d) + 1]);
        });
        setRowSpanCol(tempRowSpan);
        tempContent.push(editDataContent[10]);
        tempContent.push(editDataContent[11]);
        tempContent.push(editDataContent[12]);
        tempFirstHead.push(firstfixHeader[1]);
        tempFirstHead.push(firstfixHeader[2]);
        tempFirstHead.push(firstfixHeader[3]);
        setHeader(tempHead);
        setContent(tempContent);
        setfirstHeader(tempFirstHead);
      }
    }
  }, [oddsDiffData]);

  useEffect(() => {
    setIsQueryingDetail(true);
    if (_tempData) {
      loadData(_selectedLotType, true);
      if (data?.orgId) {
        getOddsDifferent(_selectedCompany || data?.orgId, _selectedLotType - 1);
      }
    }
  }, [_selectedLotType]);

  useEffect(() => {
    setActiveLotteryCode(_lotList?.filter(x => x.templateCode === _selectedTab)[0]?.code);
  }, [_selectedTab]);

  useEffect(() => {
    if (bufferData) {
      bufferData = _tempData;
    }
  }, [_tempData]);

  useEffect(() => {
    loadData(_selectedLotType);
  }, [_allLotList, _selectedLotType]);


  const loadData = (lotType, fromLotChange) => {
    setUniqueString(mainGames.Games.filter(x => x.value === _selectedLotType)[0].name);
    if (!_isUpdate || fromLotChange) {
      setSelectedTab(mainGames.Games.filter(x => x.value === _selectedLotType)[0].code[0]);
    }
    if (oddsDiffData || _allLotList) {
      const tempList = oddsDiffData?.lotteryList || _allLotList;
      let list = [];
      if (lotType === 2) {
        list = tempList.filter(x => x.templateCode === 'HK6' && x.templateCode !== 'F31X7' && x.templateCode !== 'F36X7');
      } else if (lotType === 3) {
        list = tempList.filter(x => x.templateCode === 'F31X7' || x.templateCode === 'F36X7');
      } else if (lotType === 1) {
        list = tempList.filter(x => (x?.templateCode ? (x?.templateCode !== 'HK6' && x.templateCode !== 'F31X7' && x.templateCode !== 'F36X7') : true));
      }
      setLotList(list);
      if (!_isUpdate || fromLotChange) {
        setActiveLotteryCode(list.filter(x => x.templateCode === _selectedTab)[0]?.code);
      }
    }
    setIsUpdate(false);
  };

  const onValueChange = (event, data, field) => {
    let newItems = [];
    newItems = [...bufferData];
    const cb = document.querySelector(`#chkConsolidate_${data?.templateCode}`);
    if (bufferData && cb?.checked && newItems) {
      bufferData.forEach((curr, myInd) => {
        if (curr.oddsCode === data.oddsCode && data?.templateCode === curr?.templateCode) {
          newItems[myInd][field] = Number(event.target.value);
          newItems[myInd].isChange = true;
          newItems = addHighLightField(newItems, field, myInd);
          newItems = addConHighLightField(newItems, field, myInd);
        } else if (curr.settingCode === data.settingCode && field.includes('Return') && data?.templateCode === curr?.templateCode) {
          newItems[myInd][field] = Number(event.target.value);
          newItems[myInd].isChange = true;
        }
      });
      bufferData = [...newItems];
      setTempData(newItems);
    } else if (bufferData && newItems && Number(event.target.value)) {
      const index = Number(bufferData?.findIndex(item => item.id === data.id));
      if (index !== -1) {
        newItems[index][field] = Number(event.target.value);
        newItems = addHighLightField(newItems, field, index);
        newItems[index].isChange = true;
        if (field.includes('Return')) {
          bufferData.forEach((curr, myInd) => {
            if (curr.lotteryCode === data.lotteryCode && curr.settingCode === data.settingCode && field.includes('Return')) {
              newItems[myInd][field] = Number(event.target.value);
              newItems[myInd].isChange = true;
            }
          });
        }
        setTempData(newItems);
      }
    }
  };

  const saveButton = () => {
    const res = _tempData.filter(item => item?.isChange === true && item?.templateCode);
    if (res.length > 0) {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const obj = {
        orgId: (_selectedCompany || data?.orgId),
        setting: res,
        memberType: data?.orgId ? 2 : 1,
        downline: _selectedSearchOption,
        lotteryType: _selectedLotType
      };
      saveOddsDifference(obj, setIsUpdate);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '没有变更'
        }
      });
    }
  };

  const resetDefault = () => {
    if (data?.orgId) {
      getOddsDifferent(data?.orgId, _selectedLotType - 1);
    }
  };

  const setAllPlate = () => {
    if (_selectedField && (_selectedValue || _platetInput)) {
      const newProjects = [..._tempData];
      const field = _selectedField;
      _tempData.forEach((curr, myInd) => {
        const optVal = Math.abs(Number(_platetInput)) ? Math.abs(Number(_platetInput)) : curr[field];
        const after = optVal + Number(_selectedValue);
        if (after > 0) {
          newProjects[myInd][field] = +parseFloat(after).toFixed(3);
          newProjects[myInd].isChange = true;
          addHighLightField(newProjects, field, myInd);
        }
      });
      setTempData(newProjects);
      setPlateInput('');
    }
  };

  const firstfixHeader = [{
    label: '项目名称',
    rowSpan: 2,
    colSpan: 2,
  },
  {
    label: '注单限额',
    rowSpan: 2,
  },
  {
    label: '单期限额',
    rowSpan: 2,
  },
  {
    label: '赔率预览',
    rowSpan: 2,
  }];

  const fixHeader = [{
    label: '赔率/赔率',
  },
  {
    label: '退水(%)',
  },
  {
    label: '注单限额'
  },
  {
    label: '单期限额'
  },
  {
    label: '赔率预览'
  }];


  const rdSearchOptions = [{
    label: '不增加',
    value: 1,
  },
  {
    label: '只代理',
    value: 2,
  },
  {
    label: '代理和会员',
    value: 3,
  }];

  function addHighLightField(item, field, index) {
    switch (field) {
      case 'oddsA':
        item[index].isChangeA = true;
        item[index].isConsolidateChangeA = false;
        break;
      case 'oddsB':
        item[index].isChangeB = true;
        item[index].isConsolidateChangeB = false;
        break;
      case 'oddsC':
        item[index].isChangeC = true;
        item[index].isConsolidateChangeC = false;
        break;
      case 'oddsD':
        item[index].isChangeD = true;
        item[index].isConsolidateChangeD = false;
        break;
      case 'aReturn':
        item[index].isChangeRA = true;
        item[index].isConsolidateChangeRA = false;
        break;
      case 'bReturn':
        item[index].isChangeRB = true;
        item[index].isConsolidateChangeRB = false;
        break;
      case 'cReturn':
        item[index].isChangeRC = true;
        item[index].isConsolidateChangeRC = false;
        break;
      case 'dReturn':
        item[index].isChangeRD = true;
        item[index].isConsolidateChangeRD = false;
        break;
      case 'projectLimit':
        item[index].isChangePL = true;
        item[index].isConsolidateChangePL = false;
        break;
      case 'itemLimit':
        item[index].isChangeIL = true;
        item[index].isConsolidateChangeIL = false;
        break;
      default: break;
    }
    return item;
  }
  function getIndex(game) {
    switch (game) {
      case 'A':
        return 2;
      case 'B':
        return 4;
      case 'C':
        return 6;
      case 'D':
        return 8;
      default: break;
    }
  }

  function addConHighLightField(item, field, index) {
    switch (field) {
      case 'oddsA':
        item[index].isConsolidateChangeA = true;
        break;
      case 'oddsB':
        item[index].isConsolidateChangeB = true;
        break;
      case 'oddsC':
        item[index].isConsolidateChangeC = true;
        break;
      case 'oddsD':
        item[index].isConsolidateChangeD = true;
        break;
      case 'aReturn':
        item[index].isConsolidateChangeRA = true;
        break;
      case 'bReturn':
        item[index].isConsolidateChangeRB = true;
        break;
      case 'cReturn':
        item[index].isConsolidateChangeRC = true;
        break;
      case 'dReturn':
        item[index].isConsolidateChangeRD = true;
        break;
      case 'projectLimit':
        item[index].isConsolidateChangePL = true;
        break;
      case 'itemLimit':
        item[index].isConsolidateChangeIL = true;
        break;
      default: break;
    }
    return item;
  }

  const editDataContent = [
    data => (data?.rowSpan !== 'nil' ? <Label className="d-flex justify-content-center">{data?.name}</Label> : undefined),
    data => (<Label className="d-flex justify-content-center">{data?.subName}</Label>),
    data => (
      <div className="p-0 m-0 d-flex align-items-center justify-content-center">
        <Input className={`lotteryName m-0 smallInput ${data?.isConsolidateChangeA ? 'isConsolidateChange' : (data?.isChangeA && 'isChange')}`} name={data?.oddsA} onChange={e => onValueChange(e, data, 'oddsA')} type="Number" value={data?.oddsA} />
        <div className="atTag px-1">
          @&nbsp;
          <Label className="installment">{data?.dOddsA}</Label>
        </div>
      </div>
    ),
    data => (data?.rowSpan !== 'nil' ? (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isConsolidateChangeRA ? 'isConsolidateChange' : (data?.isChangeRA && 'isChange')}`} name={data?.aReturn} onChange={e => onValueChange(e, data, 'aReturn')} type="Number" value={data?.aReturn} />
      </div>
    ) : undefined
    ),
    data => (
      <div className="d-flex align-items-center justify-content-center">
        <Input className={`lotteryName m-0 ml-1 smallInput ${data?.isConsolidateChangeB ? 'isConsolidateChange' : (data?.isChangeB && 'isChange')}`} name={data?.oddsB} onChange={e => onValueChange(e, data, 'oddsB')} type="Number" value={data?.oddsB} />
        <div className="atTag px-1">
          @&nbsp;
          <Label className="installment">{data?.dOddsB}</Label>
        </div>
      </div>
    ),
    data => (data?.rowSpan !== 'nil' ? (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isConsolidateChangeRB ? 'isConsolidateChange' : (data?.isChangeRB && 'isChange')}`} name={data?.bReturn} onChange={e => onValueChange(e, data, 'bReturn')} type="Number" value={data?.bReturn} />
      </div>
    ) : undefined
    ),
    data => (
      <div className="d-flex align-items-center justify-content-center">
        <Input className={`lotteryName m-0 smallInput ${data?.isConsolidateChangeC ? 'isConsolidateChange' : (data?.isChangeC && 'isChange')}`} name={data?.oddsC} onChange={e => onValueChange(e, data, 'oddsC')} type="Number" value={data?.oddsC} />
        <div className="atTag px-1">
          @&nbsp;
          <Label className="installment">{data?.dOddsC}</Label>
        </div>
      </div>
    ),
    data => (data?.rowSpan !== 'nil' ? (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isConsolidateChangeRC ? 'isConsolidateChange' : (data?.isChangeRC && 'isChange')}`} name={data?.cReturn} onChange={e => onValueChange(e, data, 'cReturn')} type="Number" value={data?.cReturn} />
      </div>
    ) : undefined
    ),
    data => (
      <div className="d-flex align-items-center justify-content-center">
        <Input className={`lotteryName m-0 smallInput ${data?.isConsolidateChangeD ? 'isConsolidateChange' : (data?.isChangeD && 'isChange')}`} name={data?.oddsD} onChange={e => onValueChange(e, data, 'oddsD')} type="Number" value={data?.oddsD} />
        <div className="atTag px-1">
          @&nbsp;
          <Label className="installment">{data?.dOddsD}</Label>
        </div>
      </div>
    ),
    data => (data?.rowSpan !== 'nil' ? (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isConsolidateChangeRD ? 'isConsolidateChange' : (data?.isChangeRD && 'isChange')}`} name={data?.dReturn} onChange={e => onValueChange(e, data, 'dReturn')} type="Number" value={data?.dReturn} />
      </div>
    ) : undefined
    ),
    data => (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isConsolidateChangeIL ? 'isConsolidateChange' : (data?.isChangeIL && 'isChange')}`} name={data?.itemLimit} onChange={e => onValueChange(e, data, 'itemLimit')} type="Number" value={data?.itemLimit} />
      </div>
    ),
    data => (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isConsolidateChangePL ? 'isConsolidateChange' : (data?.isChangePL && 'isChange')}`} name={data?.projectLimit} onChange={e => onValueChange(e, data, 'projectLimit')} type="Number" value={data?.projectLimit} />
      </div>
    ),
    data => (
      <Label
        className="positiveValue urlNoUnderline"
        onClick={() => {
          updateModalAlert({
            type: 'previewOddDiff',
            visible: true,
            data: {
              thisData: data,
              memberGame: oddsDiffData?.memberGame
            }
          });
        }}
      >
        {t('PREVIEW')}
      </Label>
    )];

  /* When the user clicks on the button,
toggle between hiding and showing the dropdown content */
  const myFunction = () => {
    document.getElementById('myDropdown').classList.toggle('show');
  };

  const filterFunction = text => {
    const filter = text.toUpperCase();
    const div = document.getElementById('myDropdown');
    const a = div.getElementsByTagName('p');
    for (let i = 0; i < a.length; i++) {
      const txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = '';
      } else {
        a[i].style.display = 'none';
      }
    }
  };

  return (
    <Col className="OddsDiffWrapper p-0 m-0">
      <Row className="m-0 p-0 my-2">
        <Col className="col-2 px-1 m-0 lotteryMenuWrapper align-items-center">
          {
            _allLotList.length <= 0 ? (
              <Skeleton isAnimated />
            ) : (
              <div
                className="px-2 d-flex radioButtonGroup"
              >
                {
                  _allLotList && mainGames.Games.map(item => _allLotList && _allLotList.filter(x => item.code.includes(x.templateCode)).length > 0 && (
                    <Radio
                      key={`games_${item?.displayName}`}
                      value={item?.value}
                      type="radio"
                      name="rdGames"
                      checked={Number(_selectedLotType) === Number(item.value)}
                      onChange={value => setSelectedLotType(value)}
                    >
                      {item?.displayName}
                    </Radio>
                  ))
                }
              </div>
            )
          }
        </Col>
        <Col className="p-1 m-0 filterWrapper">
          <Col className="d-flex align-items-center">
            <Label className="justify-content-center p-1">赚取退水：</Label>
            <Select
              value={_selectedField}
              placeholder="请选择"
              onChange={item => setSelectedField(item)}
            >
              {
                _userGames && Array.from(_userGames).map(d => (
                  <Select.Option key={`returnSelection_${d}`} label={`${d}${t('PLATE')}`} value={`${d.toLowerCase()}Return`} />
                ))
              }
              <Select.Option label="注单限额" value="itemLimit" />
              <Select.Option label="单期限额" value="projectLimit" />
              {
                _userGames && Array.from(_userGames).map(d => (
                  <Select.Option key={`oddsSelection_${d}`} label={`${t('ODDS')}${d}`} value={`odds${d}`} />
                ))
              }
            </Select>
            <Select
              className="small mx-1"
              value={_selectedValue}
              placeholder="请选择"
              onChange={item => setSelectedValue(item)}
            >
              <Select.Option label="1" value="1" />
              <Select.Option label="0.5" value="0.5" />
              <Select.Option label="0.1" value="0.1" />
              <Select.Option label="0.05" value="0.05" />
              <Select.Option label="0.01" alue="0.01" />
              <Select.Option label="0" value="0" />
              <Select.Option label="-0.01" value="-0.01" />
              <Select.Option label="-0.05" value="-0.05" />
              <Select.Option label="-0.1" value="-0.1" />
              <Select.Option label="-0.5" alue="-0.5" />
              <Select.Option label="-1" value="-1" />
            </Select>
            <Label className="p-2">统一值：</Label>
            <Input className="mx-2" type="number" value={_platetInput} onChange={({ target: { value: text } }) => setPlateInput(text)} />
            <Button className="button generate mx-1" onClick={() => setAllPlate()}>{t('CONFIRM')}</Button>
            <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
            <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
            <Button
              className="button copy mx-1"
              onClick={() => {
                updateModalAlert({
                  type: 'copyOddsDiff',
                  visible: true,
                  data: {
                    lotteryList: _allLotList,
                    orgId: data?.orgId,
                    lotType: _selectedCompany === userData?.id ? 1 : 2,
                  }
                });
              }}
            >
              {t('COPY')}
            </Button>
            <div className="justify-content-center marginLeft5 p-1">
              <Label>保存时下线是否等量增加：</Label>
              <div
                className="d-flex py-1 rdSearchOptionsGroup"
              >
                {
                  rdSearchOptions.map(item => (
                    <div key={`rdSearchOptionsGroup_${item.value}`}>
                      <Input
                        id={`rdSearchOptions_${item.value}`}
                        type="radio"
                        name="rdSearchOptions"
                        value={item.value}
                        checked={_selectedSearchOption === item.value}
                        onChange={() => setSearchOption(item.value)}
                        className="mr-2"
                      />
                      <Label
                        for={`rdSearchOptions_${item.value}`}
                        className={`${_selectedSearchOption === item.value ? 'active' : ''} px-2`}
                      >
                        {item.label}
                      </Label>
                    </div>
                  ))
                }
              </div>
            </div>
            {
            _data && (_data?.level === 2 || _data?.level === 1) && (
              <Col className="d-flex px-3 align-items-center justify-content-end">
                <div className="dropdown">
                  <div onClick={() => myFunction()} className="dropbtn d-flex">
                    <Label className="wlimit">{_companyId || `${userData?.loginId} (公司)`}</Label>
                    <div className="p-0 m-0">
                      <Icon
                        libName="fa"
                        value={{
                          name: 'FaCaretDown',
                        }}
                      />
                    </div>
                  </div>
                  <div id="myDropdown" className="dropdown-content">
                    <Input type="text" className="toStick" placeholder={`${t('SEARCH')}..`} id="myInput" onChange={({ target: { value: text } }) => filterFunction(text)} />
                    <div
                      onClick={() => {
                        setCompanyId(`${userData?.loginId} (公司)`);
                        setSelectedCompany(userData?.id);
                        document.getElementById('myDropdown').classList.toggle('show');
                      }}
                      className={_selectedCompany === userData?.id ? 'hightlight p-2' : 'p-2'}
                    >
                      {`${userData?.loginId} (公司)`}
                    </div>
                    <div className="tooverflow">
                      {
                      _oddsUpOrgs && _oddsUpOrgs.map(d => (
                        <p
                          key={`returnSelection_${d?.organizationId}`}
                          onClick={() => {
                            setCompanyId(d?.name);
                            setSelectedCompany(d?.organizationId);
                            document.getElementById('myDropdown').classList.toggle('show');
                          }}
                          className={_selectedCompany === d?.organizationId && 'hightlight'}
                        >
                          {d?.name}
                        </p>
                      ))
                      }
                    </div>
                  </div>
                </div>
              </Col>
            )
          }
          </Col>
        </Col>
      </Row>
      {_allLotList.length <= 0 ? (
        <>
          <Row className="p-2 m-0 mt-2 border">
            <Skeleton isAnimated />
          </Row>
          <Row className="p-2 m-0 drawBox">
            <Skeleton isAnimated />
          </Row>
        </>
      ) : (
        <Row className="m-0 p-0 my-2">
          <Tabs className="m-0 p-0" activeName={_selectedTab} onTabClick={tab => setSelectedTab(tab.props.name || '')}>
            {
              _uniqueString && _uniqueString.map(item => _lotList.filter(y => y?.templateCode === item.value).length > 0 && (
                <Tabs.Pane key={`maintype_${item.label}`} label={item.label} name={item.value}>
                  <Row className="py-2 m-0 drawBox">
                    <Col className="d-flex m-0 p-0 radioButtonGroup mx-2 px-1">
                      <div
                        className="px-1 d-flex radioButtonGroup"
                      >
                        {_lotList.filter(x => x.templateCode === item.value).map(x => (
                          <Radio
                            key={`buttonSubCode_${x.code}_${x.lotteryName}`}
                            value={item?.value}
                            type="radio"
                            name="rdGames"
                            className="px-2"
                            checked={_activeLotteryCode === x.code}
                            onChange={() => setActiveLotteryCode(x.code)}
                          >
                            {x.lotteryName}
                          </Radio>
                        ))}
                      </div>
                      <Input
                        id={`chkConsolidate_${item.value}`}
                        type="checkbox"
                        className="m-1 p-1"
                      />
                      <Label
                        for={`chkConsolidate_${item.value}`}
                        className="p-0"
                      >
                        统一修改
                      </Label>
                    </Col>
                  </Row>
                  <Row className="p-2" />
                  {
                    _tempData && _tempData.filter(x => x.lotteryCode === _activeLotteryCode) && _selectedTab === item.value && (
                      <Row className="m-0 p-0">
                        <AgentDataTable
                          isQuerying={_isQueryingDetail}
                          tableHeaderTitle={_header || []}
                          tableData={(_isQueryingDetail ? [1, 2, 3] : (_tempData.filter(x => x.lotteryCode === _activeLotteryCode).sort((a, b) => a.orderBy - b.orderBy) || []))}
                          tableBodyRowProperty={_content}
                          rowSpanHeader={_firstheader}
                          tableBodyRowStyle={
                            ['backgroundGrey rowSpan', 'backgroundGrey', '', 'rowSpan', '', 'rowSpan', '', 'rowSpan', '', 'rowSpan']
                          }
                          rowSpanCol={_rowSpanCol || []}
                        />
                      </Row>
                    )
                  }
                </Tabs.Pane>
              ))
            }
            <Tabs.Pane className="none" label="" name="" />
          </Tabs>
        </Row>
      )}

      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OddsDiff);