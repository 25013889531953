import { StatusCodes } from 'http-status-codes';

import { SET_ONLINE_LEVEL_SUM, SET_ONLINE_USER_COUNT, SET_ONLINE_USER_LIST } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';


const setOnlineUserList = list => ({
  type: SET_ONLINE_USER_LIST,
  value: list
});

const setOnlineLevelSum = list => ({
  type: SET_ONLINE_LEVEL_SUM,
  value: list
});

const setOnlineUserCount = data => ({
  type: SET_ONLINE_USER_COUNT,
  value: data
});

const getOnlineUserList = queryData => dispatch => {
  const params = new URLSearchParams(queryData);
  apiService.get(`OnlineStatistics/GetOnlineUserList?${params}`)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setOnlineUserList(data.result));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const getOnlineLevelSum = () => dispatch => {
  apiService.get('OnlineStatistics/GetOnlineLevelSum')
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setOnlineLevelSum(data.result));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const kickOut = (userType, userId, queryData) => dispatch => {
  apiService.post('OnlineStatistics/KickOut', {
    userType, userId
  })
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 2026) {
          dispatch(getOnlineLevelSum());
          return dispatch(getOnlineUserList(queryData));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
      Promise.reject(error);
    });
};

const GetOnlineUserCount = () => dispatch => {
  apiService.get('OnlineStatistics/GetOnlineUserCount')
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setOnlineUserCount(data.result));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};


export default {
  getOnlineUserList,
  getOnlineLevelSum,
  kickOut,
  GetOnlineUserCount,
};