import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { Select } from 'element-react';
import { useTranslation } from 'react-i18next';

import { agentActions, appActions, memberActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  editMemDetailData: state.memberReducers.editMemDetailData,
  userData: state.memberReducers.userData,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
});

const mapDispatchToProps = dispatch => ({
  getAddMemberInfo: (obj, callback) => dispatch(memberActions.GetAddMemberInfo(obj, callback)),
  getMemberDetail: (obj, callback) => dispatch(memberActions.GetMemberDetail(obj, callback)),
  saveMember: (obj, callback) => dispatch(memberActions.SaveMember(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  isValidName: (userId, callback) => dispatch(agentActions.isValidName(userId, callback)),
  isExistLoginId: (userId, callback) => dispatch(agentActions.isExistLoginId(userId, callback)),
});

const MemberEdit = ({ callback = () => { }, getMemberDetail, data, userData, editMemDetailData, saveMember, updateModalAlert }) => {
  const { t } = useTranslation();
  const [_password, setPassword] = useState();
  const [_passwordMsg, setPasswordMsg] = useState('');
  const [_memInfo, setMemInfo] = useState([]);
  const [_cashMemInfo, setCashMemInfo] = useState([]);
  const [_isEditable, setIsEditable] = useState(false);
  const [_gameType, setGameType] = useState('AB');


  useEffect(() => {
    setCashMemInfo([]);
    setMemInfo([]);
    if (data) {
      getMemberDetail(data?.orgId || data?.memberId);
    }
  }, [data]);

  useEffect(() => {
    let isMounted = true;
    fetchData();
    return () => { isMounted = false; };
    function fetchData() {
      if (editMemDetailData && isMounted) {
        setMemInfo(editMemDetailData?.memInfo);
        setCashMemInfo(editMemDetailData?.cashMemInfo || []);
        setGameType(editMemDetailData?.gameType);
        setIsEditable(editMemDetailData?.memberEditable);
      }
    }
  }, [editMemDetailData?.memInfo]);


  const saveButton = () => {
    if (_memInfo?.isChange || _cashMemInfo?.isChange) {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      _memInfo.name = _memInfo?.loginId || '';
      const obj = {
        memInfo: _memInfo,
        cashMemInfo: _cashMemInfo,
        isupdate: true,
      };
      saveMember(obj, callback);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '没有任何改动'
        }
      });
    }
  };

  const resetDefault = () => {
    getMemberDetail(data.orgId || data?.memberId);
  };

  const onMemInfoValueChange = (val, field) => {
    const newItems = _memInfo;
    newItems[field] = val;
    newItems.isChange = true;
    setMemInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const AccountStatus = [
    {
      label: '启用',
      value: '1',
    },
    {
      label: '暂停',
      value: '2',
    },
    {
      label: '停用',
      value: '0',
    },
  ];

  const Gender = [
    {
      label: '男',
      value: '男',
    },
    {
      label: '女',
      value: '女',
    },
  ];

  const onCashMemInfoValueChange = (val, field) => {
    const newItems = _cashMemInfo;
    newItems[field] = val;
    newItems.isChange = true;
    setCashMemInfo(newItems => ({
      ...newItems, field: val
    }));
  };


  const DepositStatus = [
    {
      label: '允许',
      value: '0',
    },
    {
      label: '禁止',
      value: '1',
    },
  ];

  const handlePasswordOnBlur = text => {
    setPassword(text);
    onMemInfoValueChange(text, 'password');
    if (text?.length > 5 && text?.length < 21 && text.match(/^[0-9a-zA-Z]+$/)) {
      if (/[0-9]/.test(text) && /[a-z]/.test(text) && /[A-Z]/.test(text)) {
        setPasswordMsg({
          state: 1,
          msg: '密码可用'
        });
      } else {
        setPasswordMsg({
          state: 99,
          msg: '密码必须由字母数字组成（区分大小写）！'
        });
      }
    } else {
      setPasswordMsg({
        state: 99,
        msg: '密码必须由字母数字组成，6-20位（区分大小写）！'
      });
    }
  };


  return (
    <div className="memberAddEditWrapper">
      <div className="mx-0 bg-white my-3  border-1">
        <Row className="titleWrapper m-0 my-2 p-2">
          账户资料
        </Row>
        <Row className="bigWrapper m-0 my-3 p-3">
          <>
            <Col className="m-0 p-0">
              <table className="agentDataTable p-3 m-0 w-100" cellSpacing="0" cellPadding="0" border="0">
                <tbody>
                  <tr>
                    <td className={`tableHeader ${userData?.levelValue === 1 ? 'col-3' : 'col-5'}`}>上级代理</td>
                    <td className={userData?.levelValue === 1 ? 'col-3' : 'col-7'}>
                      <span>
                        <Label>{data?.upLoginId}</Label>
                      </span>
                    </td>
                    {
                      userData?.levelValue === 1
                        ? (
                          <>
                            <td className="tableHeader col-3">收货人</td>
                            <td className="col-3">
                              <Input
                                className="d-inline"
                                type="text"
                                defaultValue={_cashMemInfo?.gifuName}
                                disabled
                              />
                            </td>
                          </>
                        ) : (
                          <>
                          </>
                        )
                    }
                  </tr>
                  <tr>
                    <td className="tableHeader col-3">会员账号</td>
                    <td className="col-3">
                      <span>
                        <Input
                          className="d-inline"
                          type="text"
                          defaultValue={_memInfo?.loginId}
                          disabled
                        />
                      </span>
                    </td>
                    {
                      userData?.levelValue === 1
                        ? (
                          <>
                            <td className="tableHeader col-3">收货地址(省市区)</td>
                            <td className="col-3">
                              <Input
                                className="d-inline"
                                type="text"
                                defaultValue={_cashMemInfo?.gifAddress}
                                disabled
                              />
                            </td>
                          </>
                        ) : (
                          <>
                          </>
                        )
                    }
                  </tr>
                  <tr>
                    <td className="tableHeader col-3">登入密码</td>
                    <td className="col-3">
                      <span>
                        <Input
                          className="d-inline"
                          type="text"
                          defaultValue={_password}
                          onBlur={({ target: { value: text } }) => handlePasswordOnBlur(text)}
                        />
                      </span>
                      <Label className={`d-inline mx-2 ${_passwordMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_passwordMsg?.msg}</Label>
                    </td>
                    {
                      userData?.levelValue === 1
                        ? (
                          <>
                            <td className="tableHeader col-3">收货地址(街道)</td>
                            <td className="col-3">
                              <Input
                                className="d-inline"
                                type="text"
                                defaultValue={_cashMemInfo?.gifAddressII}
                                disabled
                              />
                            </td>
                          </>
                        ) : (
                          <>
                          </>
                        )
                    }
                  </tr>
                  <tr>
                    <td className="tableHeader col-3">帐号状态</td>
                    <td className="col-3">
                      <div
                        className="d-flex p-0 rdSearchOptionsGroup"
                      >
                        {
                          userData?.levelValue === 1 ? (
                            AccountStatus.map(d => (
                              <div key={`rdUserStatusGroup_${d.value}`}>
                                <Input
                                  id={`rdUserStatus_${d.value}`}
                                  type="radio"
                                  name="rdUserStatus"
                                  value={d.value}
                                  checked={_memInfo?.status === Number(d.value)}
                                  onChange={() => onMemInfoValueChange(Number(d.value), 'status')}
                                  className="mx-2"
                                />
                                <Label
                                  for={`rdUserStatus_${d.value}`}
                                  className={_memInfo?.status === Number(d.value) ? 'active' : ''}
                                >
                                  {d?.label}
                                </Label>
                              </div>
                            ))
                          ) : (
                            <>
                              {AccountStatus.find(x => Number(x.value) === _memInfo?.status)?.label}
                            </>
                          )
                        }
                      </div>
                    </td>
                    {
                      userData?.levelValue === 1
                        ? (
                          <>
                            <td className="tableHeader col-3">联系方式</td>
                            <td className="col-3">
                              <Input
                                className="d-inline"
                                type="text"
                                defaultValue={_cashMemInfo?.gifPhone}
                                disabled
                              />
                            </td>
                          </>
                        ) : (
                          <>
                          </>
                        )
                    }
                  </tr>
                  <tr>
                    <td className="tableHeader col-3">账户类型</td>
                    <td className="col-3">
                      {Number(_memInfo?.isTest) === 0 ? '正式' : '试玩'}
                    </td>
                    {
                      userData?.levelValue === 1
                        ? (
                          <>
                            <td className="tableHeader col-3">存款状态</td>
                            <td className="col-3">
                              <div className="d-flex p-0 rdSearchOptionsGroup">
                                {
                                  DepositStatus.map(d => (
                                    <div key={`rdDepositStatusGroup_${d.value}`}>
                                      <Input
                                        id={`rdDepositStatus_${d.value}`}
                                        type="radio"
                                        name="rdDepositStatus"
                                        value={d.value}
                                        checked={_cashMemInfo?.depositStatus === Number(d.value)}
                                        onChange={() => onCashMemInfoValueChange(Number(d.value), 'depositStatus')}
                                        className="mx-2"
                                      />
                                      <Label
                                        for={`rdDepositStatus_${d.value}`}
                                        className={_cashMemInfo?.depositStatus === Number(d.value) ? 'active' : ''}
                                      >
                                        {d?.label}
                                      </Label>
                                    </div>
                                  ))
                                }
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                          </>
                        )
                    }
                  </tr>
                  <tr>
                    <td className="tableHeader col-3">余额</td>
                    <td className="col-3">
                      {_memInfo?.MoneyLimit || 0}
                    </td>
                    {
                      userData?.levelValue === 1
                        ? (
                          <>
                            <td className="tableHeader col-3">单次最大存款金额</td>
                            <td className="col-3">
                              <Input
                                className="d-inline"
                                value={_cashMemInfo?.depositMaxAmount}
                                type="number"
                                onChange={({ target: { value: text } }) => onCashMemInfoValueChange(text, 'depositMaxAmount')}
                              />
                              <Label className="mx-1">(0则不限制)</Label>
                            </td>
                          </>
                        ) : (
                          <>
                          </>
                        )
                    }
                  </tr>
                  {
                    userData?.levelValue === 1
                    && (
                      <>
                        <tr>
                          <td className="tableHeader col-3">用户分类</td>
                          <td className="col-3">
                            <div className="d-flex p-0 rdSearchOptionsGroup">
                              <Select
                                value={_cashMemInfo?.mLevel || 1}
                                placeholder="请选择"
                                onChange={val => onCashMemInfoValueChange(Number(val), 'mLevel')}
                              >
                                {
                                      Array.from('1234567').map(d => (
                                        <Select.Option key={`setSelectedCashMlevel_${d}`} label={`${d} 类`} value={Number(d)} />
                                      ))
                                }
                              </Select>
                            </div>
                          </td>
                          <td className="tableHeader col-3">单次最小存款金额</td>
                          <td className="col-3">
                            <Input
                              className="d-inline"
                              value={_cashMemInfo?.depositMaxMin}
                              type="number"
                              onChange={({ target: { value: text } }) => onCashMemInfoValueChange(text, 'depositMaxMin')}
                            />
                            <Label className="mx-1">(0则不限制)</Label>
                          </td>
                        </tr>
                        <tr>
                          <td className="tableHeader col-3">VIP 级别</td>
                          <td className="col-3">
                            <div
                              className="d-flex p-0 rdSearchOptionsGroup"
                            >
                              <Select
                                value={_cashMemInfo?.vipLevel || 1}
                                placeholder="请选择"
                                onChange={val => onCashMemInfoValueChange(Number(val), 'vipLevel')}
                              >
                                {
                                    Array.from('123').map(d => (
                                      <Select.Option key={`setSelectedVIPGroup_${d}`} label={`VIP ${d}`} value={Number(d)} />
                                    ))
                                }
                              </Select>
                            </div>
                          </td>
                          <td className="tableHeader col-3">提款状态</td>
                          <td className="col-3">
                            <div className="d-flex p-0 rdSearchOptionsGroup">
                              {
                                DepositStatus.map(d => (
                                  <div key={`rdDrawStatusGroup_${d.value}`}>
                                    <Input
                                      id={`rdDrawStatus_${d.value}`}
                                      type="radio"
                                      name="rdDrawStatus"
                                      value={_cashMemInfo?.drawStatus}
                                      checked={_cashMemInfo?.drawStatus === Number(d.value)}
                                      onChange={() => onCashMemInfoValueChange(Number(d.value), 'drawStatus')}
                                      className="mx-2"
                                    />
                                    <Label
                                      for={`rdDrawStatus_${d.value}`}
                                      className={_cashMemInfo?.drawStatus === Number(d.value) ? 'active' : ''}
                                    >
                                      {d?.label}
                                    </Label>
                                  </div>
                                ))
                              }
                            </div>
                          </td>
                        </tr>
                      </>
                    )
                  }
                  <tr>
                    <td className="tableHeader col-3">可用盘类</td>
                    <td className="col-3">
                      <div
                        className="d-flex p-0 rdSearchOptionsGroup"
                      >
                        {
                          Array.from(_gameType || 'AB').map(d => (
                            <div key={`rdGameOptionsGroup_${d}`}>
                              <Input
                                id={`rdGameOptions_${d}`}
                                type="radio"
                                name="rdGameOptions"
                                checked={_memInfo?.gameType === d}
                                value={d}
                                onChange={() => onMemInfoValueChange(d, 'gameType')}
                                className="mx-2"
                              />
                              <Label
                                for={`rdGameOptions_${d}`}
                                className={_memInfo?.gameType === d ? 'active' : ''}
                              >
                                {d}
                                {t('PLATE')}
                              </Label>
                            </div>
                          ))
                        }
                      </div>
                    </td>
                    {
                      userData?.levelValue === 1
                        ? (
                          <>
                            <td className="tableHeader col-3">单次最大提款金额</td>
                            <td className="col-3">
                              <Input
                                className="d-inline"
                                value={_cashMemInfo?.drawMaxAmount}
                                type="number"
                                onChange={({ target: { value: text } }) => onCashMemInfoValueChange(text, 'drawMaxAmount')}
                              />
                              <Label className="mx-1">(0则不限制)</Label>
                            </td>
                          </>
                        ) : (
                          <>
                          </>
                        )
                    }
                  </tr>
                  <tr>
                    <td className="tableHeader col-3">会员姓名</td>
                    <td className="col-3">
                      <Input
                        className="d-inline"
                        type="text"
                        defaultValue={_cashMemInfo?.familyName}
                        onChange={({ target: { value: text } }) => onCashMemInfoValueChange(text, 'familyName')}
                      />
                    </td>
                    {
                      userData?.levelValue === 1
                        ? (
                          <>
                            <td className="tableHeader col-3">单次最少提款金额</td>
                            <td className="col-3">
                              <Input
                                className="d-inline"
                                value={_cashMemInfo?.drawMindAmount}
                                type="number"
                                onChange={({ target: { value: text } }) => onCashMemInfoValueChange(text, 'drawMindAmount')}
                              />
                              <Label className="mx-1">(0则不限制)</Label>
                            </td>
                          </>
                        ) : (
                          <>
                          </>
                        )
                    }
                  </tr>
                  {
                    userData?.levelValue === 1
                    && (
                      <>
                        <tr>
                          <td className="tableHeader col-3">生日</td>
                          <td className="col-3">
                            <Input
                              className="d-inline"
                              type="text"
                              defaultValue={_cashMemInfo?.birthDay ? _cashMemInfo?.birthDay.slice(0, 10) : ''}
                              disabled
                            />
                          </td>
                          <td className="tableHeader col-3">提款流水倍数</td>
                          <td className="col-3">
                            <Input
                              className="d-inline"
                              value={_cashMemInfo?.drawFlowTimes}
                              type="number"
                              onChange={({ target: { value: text } }) => onCashMemInfoValueChange(text, 'drawFlowTimes')}
                            />
                            <Label className="mx-1">(0则不限制)</Label>
                          </td>
                        </tr>
                        <tr>
                          <td className="tableHeader col-3">性别</td>
                          <td className="col-3">
                            <div
                              className="d-flex p-0 rdSearchOptionsGroup"
                            >
                              {
                                Gender.map(d => (
                                  <div key={`rdGenderGroup_${d.value}`}>
                                    <Input
                                      id={`rdGenderOptions_${d.value}`}
                                      type="radio"
                                      name="rdGenderOptions"
                                      checked={_cashMemInfo?.sex === d.value}
                                      value={d.value}
                                      onChange={() => onMemInfoValueChange(d.value, 'sex')}
                                      className="mx-2"
                                      disabled
                                    />
                                    <Label
                                      for={`rdGenderOptions__${d.value}`}
                                      className={_cashMemInfo?.sex === d.value ? 'active' : ''}
                                    >
                                      {d.label}
                                    </Label>
                                  </div>
                                ))
                              }
                            </div>
                          </td>
                          <td className="tableHeader col-3">提款流水金额</td>
                          <td className="col-3">
                            <Input
                              className="d-inline"
                              value={_cashMemInfo?.drawFlowAmount}
                              type="number"
                              onChange={({ target: { value: text } }) => onCashMemInfoValueChange(text, 'drawFlowAmount')}
                            />
                            <Label className="mx-1">(0则不限制)</Label>
                          </td>
                        </tr>
                        <tr>
                          <td className="tableHeader col-3">微信</td>
                          <td className="col-3">
                            <Input
                              className="d-inline"
                              type="text"
                              defaultValue={_cashMemInfo?.safeWeiXin}
                              disabled
                            />
                          </td>
                          <td className="tableHeader col-3">提款流水生效次数</td>
                          <td className="col-3">
                            <Input
                              className="d-inline"
                              value={_cashMemInfo?.drawFlowEffectTimes}
                              type="number"
                              onChange={({ target: { value: text } }) => onCashMemInfoValueChange(text, 'drawFlowEffectTimes')}
                            />
                            <Label className="mx-1">(0则不限制)</Label>
                          </td>
                        </tr>
                        <tr>
                          <td className="tableHeader col-3">安全手机</td>
                          <td className="col-3">
                            <Input
                              className="d-inline"
                              type="text"
                              value={_cashMemInfo?.safePhone || ''}
                              onChange={({ target: { value: text } }) => onCashMemInfoValueChange(text, 'safePhone')}
                              disabled={_isEditable || false}
                            />
                          </td>
                          <td className="tableHeader col-3">安全邮箱</td>
                          <td className="col-3">
                            <Input
                              className="d-inline"
                              type="text"
                              value={_cashMemInfo?.safeEmail || ''}
                              onChange={({ target: { value: text } }) => onCashMemInfoValueChange(text, 'safeEmail')}
                              disabled={_isEditable || false}
                            />
                          </td>
                        </tr>
                      </>
                    )
                  }
                  <tr>
                    <td className="tableHeader col-3">启用微信投注</td>
                    <td className="col-3">
                      <div
                        className="d-flex p-0 rdSearchOptionsGroup"
                      >
                        <Input
                          id="rdWxStatus_active"
                          type="radio"
                          value={1}
                          name="rdWxStatus"
                          checked={_memInfo?.wxEnabled === 1}
                          onChange={() => onMemInfoValueChange(1, 'wxEnabled')}
                          className="mx-2"
                        />
                        <Label
                          for="rdWxStatus_active"
                          className={_memInfo?.wxEnabled === 1 ? 'active' : ''}
                        >
                          {t('ACTIVE')}
                        </Label>
                        <Input
                          id="rdWxStatus_inactive"
                          type="radio"
                          value={0}
                          name="rdWxStatus"
                          checked={_memInfo?.wxEnabled === 0}
                          onChange={() => onMemInfoValueChange(0, 'wxEnabled')}
                          className="mx-2"
                        />
                        <Label
                          for="rdWxStatus_inactive"
                          className={_memInfo?.wxEnabled === 0 ? 'active' : ''}
                        >
                          {t('DISABLED')}
                        </Label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="tableHeader col-3">额度模式</td>
                    <td>
                      <Label>现金模式</Label>
                    </td>
                  </tr>
                  <tr>
                    <td className="tableHeader col-3">备注</td>
                    <td>
                      <span className="textAreaInput">
                        <Input
                          className="d-inline"
                          type="textarea"
                          value={_cashMemInfo?.remark || ''}
                          onChange={({ target: { value: text } }) => onCashMemInfoValueChange(text, 'remark')}
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </>
        </Row>
      </div>

      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberEdit);