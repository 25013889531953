/* eslint-disable indent */
import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { DateRangePicker, Popover, Select } from 'element-react';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AccountStatus, OrderBySelection, OrderSelection, UserSearchBy } from '../../../constants/SelectionItems';
import AgentDataTable from '../../AgentDataTable';
import Notifications from '../../Content/Notifications';
import Pagination from '../../Content/Pagination';

import { agentActions, appActions } from '../../../redux/actions';
import Icon from '../../Icon';

import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  agentListData: state.agentReducers.agentListData,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getAgentList: (agentId, levelValue, orderBy, order, page, rows, startDate, endDate, isChild, loginId, status, searchBy, minAmount, maxAmount) => dispatch(agentActions.getAgentList(agentId, levelValue, orderBy, order, page, rows, startDate, endDate, isChild, loginId, status, searchBy, minAmount, maxAmount)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  setAccountStatus: (obj, searchObj) => dispatch(agentActions.setAccountStatus(obj, searchObj)),
  setDefaultAgent: (obj, searchObj) => dispatch(agentActions.setDefaultAgent(obj, searchObj)),
  setDefaultVisitorAgent: (obj, searchObj) => dispatch(agentActions.setDefaultVisitorAgent(obj, searchObj)),
  deleteUser: (obj, searchObj) => dispatch(agentActions.deleteUser(obj, searchObj)),
  getLoginAs: (obj, callback) => dispatch(agentActions.getLoginAs(obj, callback)),
});

const SubAccountList = ({ getLoginAs, agentListData, getAgentList, userData, updateModalAlert, agentType, userLevel, setAccountStatus, setDefaultAgent, setDefaultVisitorAgent, deleteUser, callback = () => {} }) => {
  const { t } = useTranslation();
  const [_searchText, setSearchText] = useState('');
  const [_date, setDate] = useState([]);
  const [_detailItem, setDetailItem] = useState();
  const [_detailItemSum, setDetailItemSum] = useState();
  const [_selectedStatus, setSelectedStatus] = useState('');
  const [_activeId, setActiveId] = useState();
  const [_isUpdateStatus, setIsUpdateStatus] = useState(false);
  const [_selectedOrderBy, setSelectedOrderBy] = useState('CreateDate');
  const [_selectedOrderType, setSelectedOrderType] = useState('DESC');
  const [_selectedSearchBy, setSelectedSearchBy] = useState('loginId');
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);

  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  let dateRef;

  useEffect(() => {
    setIsQueryingDetail(true);
    // Default search when first load
    getAgentList(userData?.id || '',
      userLevel,
      'CreateDate',
      'DESC',
      _page,
      _rows,
      '',
      '',
      agentType);
  }, []);

  useEffect(() => {
    if (agentListData?.list) {
      if (_detailItem) {
        if (JSON.stringify(_detailItem) !== JSON.stringify(agentListData?.list)) {
          if (_isUpdateStatus) {
            setIsUpdateStatus(false);
            let temp = _detailItem;
            agentListData?.list.forEach(curr => {
              if (curr.isDefaultValue === 1) {
                temp = _detailItem.map(item => ({
                  ...item, isDefaultValue: 0
                }));
              }
              const index = _detailItem.findIndex(item => item.orgId === curr.orgId);
              if (index !== -1) {
                temp[index].accountStatus = curr.accountStatus;
              }
            });
            setDetailItem(temp);
          } else {
            setDetailItem(agentListData?.list);
            setDetailItemSum(agentListData.total);
          }
        }
        setIsQueryingDetail(false);
      } else if (JSON.stringify(_detailItem) !== JSON.stringify(agentListData?.list)) {
        setDetailItem(agentListData?.list);
        setDetailItemSum(agentListData.total);
      }
    }
    if (!agentListData) {
      setIsQueryingDetail(false);
    }
  }, [agentListData]);


  const onSearchClick = () => {
    setIsQueryingDetail(true);
    getAgentList(
      userData?.id || '',
      userLevel,
      _selectedOrderBy,
      _selectedOrderType,
      1,
      _rows,
      (_date?.length === 0 ? '' : moment(_date[0])?.unix()),
      (_date?.length === 0 ? '' : moment(_date[1])?.unix()),
      agentType,
      _searchText,
      _selectedStatus,
      _selectedSearchBy,
      '',
      '',
    );
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    // Page number and row number will follow the pagination component instead
    getAgentList(
      userData?.id || '',
      userLevel,
      'CreateDate',
      'DESC',
      pageNum,
      rows,
      (_date?.length === 0 ? '' : moment(_date[0]).unix()),
      (_date?.length === 0 ? '' : moment(_date[1]).unix()),
      agentType,
      _searchText,
      _selectedStatus,
      _selectedSearchBy,
      '',
      '',
    );
    setPage(pageNum);
    setRows(rows);
  };

  const updateStatus = (type, userId, status, userType, loginId) => {
    setActiveId('nil');
    setIsUpdateStatus(true);
    updateModalAlert({
      type: 'loading',
      visible: true
    });
    const obj = {
      userId,
      status,
      userType,
      orgId: userId,
    };
    const searchObj = {
      userId: userData?.id || '',
      userLevel,
      orderBy: _selectedOrderBy,
      orderType: _selectedOrderType,
      page: 1,
      rows: _rows,
      startDate: (_date?.length === 0 ? '' : moment(_date[0]).unix()),
      endDate: (_date?.length === 0 ? '' : moment(_date[1]).unix()),
      agentType,
      loginId
    };
    if (type === 'status') {
      setAccountStatus(obj, searchObj);
    } else if (type === 'default') {
      setDefaultAgent(userId, searchObj);
    } else if (type === 'defaultvisitor') {
      setDefaultVisitorAgent(obj, searchObj);
    }
  };

  const tableHeader = [{
    label: t('ONLINE'),
  }, {
    label: t('PARENT_ACCOUNT'),
  }, {
    label: t('OPERATION'),
  }, {
    label: t('SUB_ACCOUNT'),
  }, {
    label: t('CREATE_DATE'),
  }, {
    label: t('STATUS'),
  }];

  const tableContent = [
    data => (
      <>
        <Label className={`${data.online === 1 ? 'positiveValue' : 'offline'} justify-content-center smallicon`}>
          <Icon
            libName="fa"
            value={{
              name: 'FaCircle',
              className: 'ml-2'
            }}
          />
        </Label>
      </>
    ),
    data => (
      <Label>
        {data.upLoginId}
      </Label>
    ),
    data => (
      <>
        <Label className="positiveValue urlNoUnderline" onClick={() => setActiveId(data?.orgId)}>
          <Icon
            libName="fa"
            value={{
              name: 'FaEdit',
              className: 'ml-2'
            }}
          />
        </Label>
      </>
    ),
    data => (
      <Label>
        {`${data.loginName} [${data.userId}]`}
      </Label>
    ),
    data => (
      <>
        <Label className="px-1">{moment.unix(data.epochTimeStamp).format('YYYY-MM-DD HH:mm:ss')}</Label>
      </>
    ),
    data => (
      <Label>
        <Popover
          placement="top"
          width="160"
          trigger="click"
          visible={false}
          content={(
            <div className="popButton p-0">
              <Button
                className={`mx-1 ${data.accountStatus === 1 ? 'active' : 'idle'}`}
                onClick={() => { data.accountStatus === 1 ? false : updateStatus('status', data?.orgId, 1, 2, data?.loginId); }}
              >
                {t('ACTIVE')}
              </Button>
              <Button
                className={`mx-1 ${data.accountStatus === 0 ? 'active' : 'idle'}`}
                onClick={() => { data.accountStatus === 0 ? false : updateStatus('status', data?.orgId, 0, 2, data?.loginId); }}
              >
                {t('INACTIVE')}
              </Button>
              <Button
                className={`mx-1 ${data.accountStatus === 2 ? 'active' : 'idle'}`}
                onClick={() => { data.accountStatus === 2 ? false : updateStatus('status', data?.orgId, 2, 2, data?.loginId); }}
              >
                {t('PAUSE')}
              </Button>
            </div>
          )}
        >
          <Button className={`buttonURL ${data.accountStatus === 1 ? 'installment' : 'negativeValue'}`}>{data.accountStatus === 1 ? t('ACTIVE') : (data.accountStatus === 0 ? t('INACTIVE') : t('PAUSE'))}</Button>
        </Popover>
      </Label>
    ),
  ];

  const openNewWindow = data => {
    const callback = () => { window.open(`${window.location.origin}/?loginId=${data.loginId}&uid=${data.orgId}&data=${data.token}`); };

    getLoginAs({
      loginId: data.loginId, uid: data.orgId, data: data.token
    }, callback);
};

  const control = [
    data => (
      <>
        <Button className="controlButton mx-1" value={data?.orgId} onClick={() => callback(0, data)}>{t('EDIT')}</Button>
        <Button className="controlButton mx-1" value={data?.orgId} onClick={() => callback(1, data)}>{t('LOGIN_LOG')}</Button>
        <Button className="controlButton mx-1" value={data?.orgId} onClick={() => callback(2, data)}>{t('AMENDED_LOG')}</Button>
        <Button className="controlButton mx-1" value={data?.orgId} onClick={() => callback(3, data)}>{t('HANDLED_BY_LOG')}</Button>
        {userData?.isChild === 0 && userData?.levelValue === 1 && (
          <>
            <Button className="controlButton mx-1" value={data?.orgId} onClick={() => callback(4, data)}>{t('AUDIT_SETTING')}</Button>
            <Button className="controlButton mx-1" value={data?.orgId} onClick={() => openNewWindow(data)}>{t('LOGIN')}</Button>
            <Button
              className="controlButton mx-1"
              value={data?.orgId}
              onClick={() => {
                updateModalAlert({
                  type: 'confirmation',
                  visible: true,
                  data: {
                    message: '公司下的所有会员及其注单数据都会被删除，确定删除吗？',
                    onConfirmationClick: () => deleteUser({
                      userType: 2,
                      userId: data?.orgId
                    })
                  }
                });
              }}
            >
              {t('DELETE')}
            </Button>
          </>
        )}
      </>
    ),
  ];

  return (
    <Col className="subAccountListWrapper p-3">
      <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
        <Label value={t('STATUS')} className="px-2">{t('STATUS')}</Label>
        <Select
          popper-class="select-popper"
          value={_selectedStatus}
          onChange={value => (setSelectedStatus(value))}
        >
          {
            AccountStatus.map(item => (
              <Select.Option key={`UserStatus_${item.value}`} label={item.label} value={item.value} />
            ))
          }
        </Select>
        <Label className="px-2">{t('ORDER_BY')}</Label>
        <Select
          popper-class="select-popper"
          value={_selectedOrderBy}
          onChange={value => (setSelectedOrderBy(value))}
        >
          {
            OrderBySelection.map(item => (
              <Select.Option key={`OrderBy_${item.value}`} label={item.label} value={item.value} />
            ))
          }
        </Select>
        <Select
          className="mx-1"
          popper-class="select-popper"
          value={_selectedOrderType}
          onChange={value => (setSelectedOrderType(value))}
        >
          {
            OrderSelection.map(item => (
              <Select.Option key={`OrderType_${item.value}`} label={item.label} value={item.value} />
            ))
          }
        </Select>
        <Label value={t('SEARCH')} className="px-2">{t('SEARCH')}</Label>
        <Select
          popper-class="select-popper"
          value={_selectedSearchBy}
          onChange={value => {
            setSelectedSearchBy(value);
            if (value === '-1') {
              setSearchText('');
            }
          }}
        >
          {
            UserSearchBy.map(item => (
              <Select.Option key={`CommonSearchBy${item.value}`} label={item.label} value={item.value} />
            ))
          }
        </Select>
        <Input className="mx-1" value={_searchText} onChange={({ target: { value: text } }) => setSearchText(text)} />
        <Label type="daterange" value={t('DATE')} className="px-2">{t('DATE')}</Label>
        <div>
          <DateRangePicker
            className="betQueryDatepicker"
            value={_date}
            isShowTime
            placeholder={t('START_END_DATE')}
            rangeSeparator={` ${t('TO')} `}
            onChange={_date => {
              setDate(_date);
            }}
            ref={e => dateRef = e}
            shortcuts={[{
              text: t('TODAY'),
              onClick: () => {
                setDate([moment().toDate(), moment().toDate()]);
                dateRef.togglePickerVisible();
              }
            }, {
              text: t('THIS_WEEK'),
              onClick: () => {
                setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
                dateRef.togglePickerVisible();
              }
            }, {
              text: t('PREVIOUS_WEEK'),
              onClick: () => {
                setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                dateRef.togglePickerVisible();
              }
            }, {
              text: t('PREVIOUS_2_WEEK'),
              onClick: () => {
                setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                dateRef.togglePickerVisible();
              }
            }]}
          />
        </div>
        <Button className="searchButton marginLeft5 me-2" onClick={onSearchClick}>{t('FIND')}</Button>
        <Notifications />
      </Col>
      <Col className="subMenu mb-2 flex">
        <Button
          className="addButton mx-2"
          onClick={() => callback('create_subAccount', {
        loginId: userData.loginId,
        selectedMemberId: userData.id
}, false)}
        >
          <Icon
            libName="fa"
            value={{
              name: 'FaPlusCircle',
              className: 'mx-1'
            }}
          />
          {t('ADD_SUB_AGENT')}
        </Button>
      </Col>
      <Row className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={tableHeader}
          tableData={_detailItem || []}
          tableBodyRowProperty={tableContent}
          tableControl={control}
          activeControl={_activeId}
          tableBodyRowStyle={['haveControl', 'haveControl', 'haveControl', 'haveControl', 'haveControl', 'haveControl']}
        />
      </Row>
      <Row className="justify-center w-full relative">
        <Pagination totalRecord={(_detailItemSum && _detailItemSum?.records) || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubAccountList);