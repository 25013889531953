import React, { useEffect, useState } from 'react';

import { Col, Label, Row } from 'reactstrap';
import { Select } from 'element-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../..';
import { appActions, cashActions } from '../../../redux/actions';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  thirdTopUpInfo: state.cashReducers.thirdTopUpInfo
});

const mapDispatchToProps = dispatch => ({
  GetThirdTopupInfo: (obj, callback) => dispatch(cashActions.GetThirdTopupInfo(obj, callback)),
  CopyThirdTopupInfo: (obj, callback) => dispatch(cashActions.CopyThirdTopupInfo(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const ThirdPartyTopUpManagement = ({ CopyThirdTopupInfo, updateModalAlert, thirdTopUpInfo, GetThirdTopupInfo }) => {
  const { t } = useTranslation();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [type, settype] = useState(-1);

  useEffect(() => {
    setIsQueryingDetail(true);
    const obj = {
      page,
      rows,
      type
    };
    GetThirdTopupInfo(obj, () => setIsQueryingDetail(false));
  }, []);

  const onSearchClick = () => {
    setPage(1);
    setRows(15);
    setIsQueryingDetail(true);
    const obj = {
      page: 1,
      rows: 15,
      type
    };
    GetThirdTopupInfo(obj, () => setIsQueryingDetail(false));
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    setPage(pageNum);
    setRows(rows);
    const obj = {
      page: pageNum,
      rows,
      type
    };
    GetThirdTopupInfo(obj, () => setIsQueryingDetail(false));
  };

  return (
    <div className="ThirdPartyTopUpManagementWrapper">
      <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100">
        <span className="mx-2">支付方式</span>
        <Select value={type} placeholder="请选择" onChange={code => settype(code)}>
          {
                [{
                  value: -1, label: '全部'
                }, {
                  value: 0, label: 'Web'
                }, {
                  value: 1, label: 'Qrcode'
                }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
              }
        </Select>
        <div className="operationBtn searchBtn mx-1" onClick={onSearchClick}>{t('FIND')}</div>
      </Col>
      <Row className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={
          [{
            label: '公司名称',
          }, {
            label: '识别Code',
          }, {
            label: '支付名称',
          }, {
            label: '名称备注',
          }, {
            label: '支付方式',
          }, {
            label: '最大支付金额',
          }, {
            label: '最少支付金额',
          }, {
            label: '所属平台',
          }, {
            label: '使用状态',
          }, {
            label: '状态说明',
          }, {
            label: '手动确认',
          }, {
            label: '手动审核',
          }, {
            label: '操作',
          }]
        }
          tableData={thirdTopUpInfo ? thirdTopUpInfo.payList : []}
          tableBodyRowProperty={
          [
            data => (<Label className="d-flex justify-content-center">{data.companyName}</Label>),
            data => (<Label className="d-flex justify-content-center">{data.code}</Label>),
            data => (<Label className="d-flex justify-content-center">{data.name}</Label>),
            data => (<Label className="d-flex justify-content-center">{data.description}</Label>),
            data => (<Label className="d-flex justify-content-center">{data.type}</Label>),
            data => (<Label className="d-flex justify-content-center">{data.max}</Label>),
            data => (<Label className="d-flex justify-content-center">{data.min}</Label>),
            data => (<Label className="d-flex justify-content-center">{data.platform}</Label>),
            data => (<Label className={`d-flex justify-content-center  ${data.status === 1 && 'text-danger'}`}>{data.status === 1 ? '禁用' : '启用'}</Label>),
            data => (<Label className="d-flex justify-content-center">{data.statusRemark}</Label>),
            data => (<Label className={`d-flex justify-content-center ${data.isCheck === 0 && 'text-danger'}`}>{data.isCheck === 1 ? '否' : '是'}</Label>),
            data => (<Label className={`d-flex justify-content-center ${data.isConfirm === 0 && 'text-danger'}`}>{data.isConfirm === 1 ? '否' : '是'}</Label>),
            data => (
              <Label className="d-flex justify-content-center align-items-center">
                <div
                  className="operationBtn mx-2"
                  onClick={() => updateModalAlert({
                    visible: true,
                    type: 'thirdPartyTopup',
                    data: {
                      onConfirmationClick: companyId => CopyThirdTopupInfo({
                        companyId, id: data.id
                      })
                    }
                  })}
                >
                  复制
                </div>
              </Label>
            )
          ]
        }
        />
      </Row>
      <Row className="justify-center w-full relative">
        <Pagination totalRecord={(thirdTopUpInfo && thirdTopUpInfo.totalRecords) || 0} onClick={onPaginationClick} isQuery={false} />
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyTopUpManagement);