import React, { useEffect, useState } from 'react';

import { DateRangePicker, Input, Select } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable } from '../..';
import Pagination from '../../Content/Pagination';
import { reportActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  depositWithdrawStatistic: state.reportReducers.depositWithdrawStatistic,
});

const mapDispatchToProps = dispatch => ({
  getDepositWithdrawStatistic: (obj, callback) => dispatch(reportActions.getDepositWithdrawStatistic(obj, callback)),
});

const DepositWithdrawStatistic = ({ getDepositWithdrawStatistic, depositWithdrawStatistic }) => {
  const { t } = useTranslation();
  const [_date, setDate] = useState([moment().startOf('day').toDate(), moment().toDate()]);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [memberType, setMemType] = useState(-1);
  const [_dateFlag, setDateFlag] = useState('1');
  const [_hide, setHide] = useState(false);
  const [orderType, setOrderType] = useState(0);
  const [orderBy, setOrderBy] = useState(0);
  const [dateType, setDateType] = useState(1);
  const [minAmount, setMinAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState('');

  let dateRef;

  useEffect(() => {
    const obj = {
      memberType, orderType, orderBy, dateType, page, rows, minAmount, maxAmount, beginDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')
    };
    getDepositWithdrawStatistic(obj, setIsQueryingDetail);
  }, []);

  const onSearchClick = () => {
    setPage(1);
    setRows(15);
    const obj = {
      memberType, orderType, orderBy, page: 1, rows: 15, dateType, minAmount, maxAmount, beginDate: moment(_date[0]).format('YYYY-MM-DD'), endDate: moment(_date[1]).format('YYYY-MM-DD')
    };
    setIsQueryingDetail(true);
    getDepositWithdrawStatistic(obj, setIsQueryingDetail);
  };

  const onPaginationClick = (pageNum, rows) => {
    const obj = {
      memberType, orderType, orderBy, dateType, minAmount, maxAmount, beginDate: moment(_date[0]).format('YYYY-MM-DD'), endDate: moment(_date[1]).format('YYYY-MM-DD')
    };
    setIsQueryingDetail(true);
    getDepositWithdrawStatistic(obj, setIsQueryingDetail);
    setPage(pageNum);
    setRows(rows);
  };

  const dateSelector = id => {
    setDateFlag(id);
    switch (id) {
      case '1':
        setDate([moment().toDate(), moment().toDate()]);
        break;
      case '2':
        setDate([moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]);
        break;
      case '3':
        setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
        break;
      case '4':
        setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
        break;
      case '5':
        setDate([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
        break;
      case '6':
        setDate([moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()]);
        break;
      default:
        break;
    }
  };

  const cancel = () => {
    setDate([moment().toDate(), moment().toDate()]);
    setMemType(1);
    setDateFlag('');
    setOrderType(0);
    setDateType(1);
    setOrderBy(0);
    setMinAmount('');
    setMaxAmount('');
    setPage(1);
    setRows(15);
    const obj = {
      memberType: 1, beginDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')
    };
    getDepositWithdrawStatistic(obj, setIsQueryingDetail);
  };

  return (
    <div className="depositWithdrawStatisticWrapper">
      <div className="depositWithdrawStatisticTableWrapper d-flex p-3">
        <div className="userTable w-100">
          <div className="d-flex">
            <div>日期范围</div>
            <div>
              <DateRangePicker
                className="betQueryDatepicker me-2"
                value={_date}
                isShowTime
                placeholder={t('START_END_DATE')}
                rangeSeparator={` ${t('TO')} `}
                onChange={_date => {
                  setDate(_date);
                }}
                ref={e => dateRef = e}
                shortcuts={[{
                  text: t('TODAY'),
                  onClick: () => {
                    setDate([moment().toDate(), moment().toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('THIS_WEEK'),
                  onClick: () => {
                    setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_2_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }]}
              />
              <Select
                popper-class="select-popper"
                value={dateType}
                onChange={value => setDateType(value)}
              >
                {
                    [{
                      label: '审核时间', value: 1
                    }, {
                      label: '交易时间', value: 2
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
              </Select>
              <div onClick={e => dateSelector(e.target.id)} className="d-flex mx-2">
                {
              [{
                label: '今天', value: 1
              }, {
                label: '昨天', value: 2
              }, {
                label: '本星期', value: 3
              }, {
                label: '上星期', value: 4
              }, {
                label: '本月', value: 5
              }, {
                label: '上月', value: 6
              }].map(item => <span id={item.value} className={`mx-1 operationBtn dateSelectBtn ${+_dateFlag === item.value && 'selected'}`}>{item.label}</span>)

                 }
              </div>
              <div className="operationBtn findAndCollapse mx-1" onClick={onSearchClick}>查询</div>
              <div className="operationBtn cancel mx-1" onClick={() => cancel()}>取消</div>
              <div className="flex-grow-1 d-flex justify-content-end pe-4">
                <div className="operationBtn findAndCollapse" onClick={() => setHide(!_hide)}>{!_hide ? '收起' : '展开'}</div>
              </div>

            </div>
          </div>
          {!_hide && (
          <>
            <div className="d-flex">
              <div>金额范围</div>
              <div>
                <Input className="mx-1" value={minAmount} onChange={text => { if (!Number.isNaN(parseInt(text, 10)) || text === '') { setMinAmount(text === '' ? '' : parseInt(text, 10)); } }} />
                —
                <Input className="mx-1" value={maxAmount} onChange={text => { if (!Number.isNaN(text) || text === '') { setMaxAmount(text === '' ? '' : parseInt(text, 10)); } }} />
              </div>
            </div>
            <div className="d-flex">
              <div>用户类型</div>
              <div>
                <Select
                  popper-class="select-popper"
                  value={memberType}
                  onChange={value => setMemType(value)}
                >
                  {
                    [{
                      label: '全部', value: -1
                    }, {
                      label: '会员', value: 1
                    }, {
                      label: '代理', value: 2
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
                </Select>
              </div>
            </div>
            <div className="d-flex">
              <div>用户名</div>
              <div>
                <Select
                  popper-class="select-popper"
                  value={orderType}
                  onChange={value => setOrderType(value)}
                >
                  {
                    [{
                      label: '存款', value: 0
                    }, {
                      label: '取款', value: 1
                    }, {
                      label: '盈亏', value: 2
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
                </Select>
                <Select
                  popper-class="select-popper"
                  value={orderBy}
                  onChange={value => setOrderBy(value)}
                >
                  {
                    [{
                      label: '降序', value: 0
                    }, {
                      label: '顺序', value: 1
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
                </Select>
              </div>
            </div>
          </>
          )}
        </div>
      </div>
      <div className="depositWithdrawStatisticTableWrapper p-3">
        <div className="mx-0 bg-white">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={
            [{
              label: '序号',
            }, {
              label: '用户帐号',
            }, {
              label: (
                <div className="combineTitleCell">
                  <div className="h-50">存款</div>
                  <div className="h-50">
                    <div>初次</div>
                    <div>末次</div>
                    <div>次数</div>
                    <div>存款</div>
                    <div>手续费</div>
                    <div>实际</div>
                  </div>
                </div>
              ),
            }, {
              label: (
                <div className="combineTitleCell">
                  <div className="h-50">取款</div>
                  <div className="h-50">
                    <div>初次</div>
                    <div>末次</div>
                    <div>次数</div>
                    <div>取款</div>
                    <div>手续费</div>
                    <div>实际</div>
                  </div>
                </div>
              ),
            }, {
              label: '盈亏',
            }]
          }
            tableHeaderStyle={['', '', 'p-0', 'p-0', '']}
            tableData={depositWithdrawStatistic ? depositWithdrawStatistic.reportList.map((item, index) => ({
              ...item, no: index + 1
            })) : []}
            tableBodyRowStyle={['', '', 'p-0', 'p-0', '']}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.no}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.loginName}</Label>),
              data => (
                <Label className="combineBodyRowCell tableBodyFirstCell">
                  <div>{moment.unix(data.depositFirstDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                  <div>{moment.unix(data.depositLastDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                  <div>{data.depositCount}</div>
                  <div className="numberBlue">{data.depositAmount}</div>
                  <div>{data.depositFee}</div>
                  <div className="numberBlue">{data.depositValidAmount}</div>
                </Label>
              ),
              data => (
                <Label className="combineBodyRowCell tableBodyFirstCell">
                  <div>{moment.unix(data.withdrawFirstDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                  <div>{moment.unix(data.withdrawLastDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                  <div>{data.withdrawCount}</div>
                  <div className="numberRed">{data.withdrawAmount}</div>
                  <div>{data.withdrawFee}</div>
                  <div className="numberRed">{data.withdrawValidAmount}</div>
                </Label>
              ),
              data => (<Label className={`d-flex justify-content-center ${parseFloat(data.profitLoss) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.profitLoss}</Label>)
            ]
          }
            tableFooter={[
              [
                {
                  render: () => (
                    <Label className="combineBodyRowCell tableFooterFirstCell">
                      <div>本页总和</div>
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.depositCount) || 0}</div>
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.depositAmount) || 0}</div>
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.depositFee) || 0}</div>
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.depositValidAmount) || 0}</div>
                    </Label>
                  ),
                  colspan: 3,
                  className: 'tableSum noPadding'
                },
                {
                  render: () => (
                    <Label className="combineBodyRowCell  tableBodyFirstCell">
                      <div />
                      <div />
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.withdrawCount) || 0}</div>
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.withdrawAmount) || 0}</div>
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.withdrawFee) || 0}</div>
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.withdrawValidAmount) || 0}</div>
                    </Label>
                  ),
                  colspan: 1,
                  className: 'tableSum noPadding'
                },
                {
                  value: (depositWithdrawStatistic && depositWithdrawStatistic.total.profitLoss) || 0,
                  colspan: 1,
                  className: 'tableSum'
                }],
              [
                {
                  render: () => (
                    <Label className="combineBodyRowCell tableFooterFirstCell">
                      <div>所有总和</div>
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.totalDepositCount) || 0}</div>
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.totalDepositAmount) || 0}</div>
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.totalDepositFee) || 0}</div>
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.totalDepositValidAmount) || 0}</div>
                    </Label>
                  ),
                  colspan: 3,
                  className: 'tableSum noPadding'
                },
                {
                  render: () => (
                    <Label className="combineBodyRowCell tableBodyFirstCell">
                      <div />
                      <div />
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.totalWithdrawCount) || 0}</div>
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.totalWithdrawAmount) || 0}</div>
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.totalWithdrawFee) || 0}</div>
                      <div>{(depositWithdrawStatistic && depositWithdrawStatistic.total.totalWithdrawValidAmount) || 0}</div>
                    </Label>
                  ),
                  colspan: 1,
                  className: 'tableSum noPadding'
                },
                {
                  value: (depositWithdrawStatistic && depositWithdrawStatistic.total.totalProfitLoss) || 0,
                  colspan: 1,
                  className: 'tableSum'
                }]
            ]}
          />
        </div>
      </div>

      <div className="justify-center w-full relative">
        <Pagination totalRecord={depositWithdrawStatistic ? depositWithdrawStatistic.totalAmount : 0} onClick={(pageNum, rows) => onPaginationClick(pageNum, rows)} isQuery={false} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositWithdrawStatistic);