import React, { useEffect, useState } from 'react';

import { Input, Select } from 'element-react';
import { connect } from 'react-redux';

import { memberActions, withdrawalActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  withdrawalInfo: state.withdrawalReducers.withdrawalInfo,
  userInfo: state.memberReducers.userInfo,
});

const mapDispatchToProps = dispatch => ({
  GetInfo: () => dispatch(withdrawalActions.GetInfo()),
  Save: (obj, callback) => dispatch(withdrawalActions.Save(obj, callback)),
  getMyInfo: () => dispatch(memberActions.getMyInfo()),
});

const Withdrawal = ({
  GetInfo,
  withdrawalInfo,
  userInfo,
  Save,
  getMyInfo
}) => {
  const [acAmount, setamount] = useState(0);
  const [safePass, setSafePass] = useState('');
  const [bankId, setbankId] = useState();

  useEffect(() => {
    getMyInfo();
    GetInfo();
  }, []);

  const onClickSave = () => {
    const _obj = {
      acAmount,
      safePass,
      bankId
    };
    Save(_obj, () => {
      GetInfo();
      setamount();
      setSafePass('');
      setbankId();
    });
  };

  const cancel = () => {
    setamount(0);
    setSafePass('');
    setbankId();
  };

  return (
    <div className="withdrawalWrapper">
      <div className="orderInformationTable">
        <div className="evenRowStyle">
          <div>代理账号</div>
          <div>
            {userInfo && userInfo.loginId}
          </div>
        </div>
        <div className="evenRowStyle">
          <div>帐号余额</div>
          <div>
            {userInfo && userInfo.accountBalance}
          </div>
        </div>
        <div className="evenRowStyle">
          <div>提款金额</div>
          <div>
            <Input
              type="number"
              value={acAmount}
              onChange={text => {
                setamount(Number(text));
              }}
            />
          </div>
        </div>
        <div className="evenRowStyle">
          <div>提款密码</div>
          <div>
            <Input type="password" value={safePass} onChange={text => setSafePass(text)} />
          </div>
        </div>
        <div className="evenRowStyle">
          <div>汇款银行</div>
          <div>
            <Select value={bankId} onChange={code => setbankId(code)}>
              {
                withdrawalInfo && (withdrawalInfo.bankList.map(el => <Select.Option key={el.id} label={`${el.bankName}(${el.bankNumberCut})`} value={el.id} />))
              }
            </Select>
          </div>
        </div>
        <div className="evenRowStyle">
          <div>注意</div>
          <div>
            预计到帐：0.00 USDT
            当前参考汇率：1 USDT =
            {withdrawalInfo && withdrawalInfo.currencyRate.find(item => item.currencyCode === 'USDT') && withdrawalInfo.currencyRate.find(item => item.currencyCode === 'USDT').currencyRate}
            元
            余额需满足100以上才可以提款。
          </div>
        </div>
        <div className="evenRowStyle">
          <div>温馨提示</div>
          <div>
            今日已提款0次，¥0.00。每日免费提款
            {withdrawalInfo && withdrawalInfo.cashDraw.freeTimes}
            次，超过收取
            {withdrawalInfo && withdrawalInfo.cashDraw.unFreeAmount}
            % 手续费，每日限额
            {withdrawalInfo && withdrawalInfo.cashDraw.perDayMax}
            元
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center py-3">
        <div className="operationBtn" onClick={() => onClickSave()}>确认提款</div>
        <div className="operationBtn cancel ms-3" onClick={() => cancel()}>取消</div>
      </div>

    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Withdrawal);