import React, { useEffect, useState } from 'react';

import { Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Icon from '../../Icon';
import Pagination from '../../Content/Pagination';
import './styles.scss';


const mapStateToProps = state => ({
  memberAutoClassifyData: state.systemReducers.memberAutoClassifyData,
});

const mapDispatchToProps = dispatch => ({
  getMemberAutoClassify: (userId, callback) => dispatch(systemActions.getMemberAutoClassify(userId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  deleteMemberClassification: (obj, callback) => dispatch(systemActions.deleteMemberClassification(obj, callback)),
});

const MemberAutoClassification = ({
  callback = () => {},
  memberAutoClassifyData,
  getMemberAutoClassify,
  data,
  updateModalAlert,
  deleteMemberClassification
}) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const [_totalRecord, setTotalRecord] = useState(0);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);

  useEffect(() => {
    if (memberAutoClassifyData) {
      setInfo(memberAutoClassifyData?.classList);
      setTotalRecord(memberAutoClassifyData?.totalRecords);
      setIsQueryingDetail(false);
    }
  }, [memberAutoClassifyData]);

  useEffect(() => {
    setIsQueryingDetail(true);
    getMemberAutoClassify({
      page: _page,
      rows: _rows,
    }, setIsQueryingDetail);
  }, []);

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    // Page number and {row number will follow the pagination component instead
    getMemberAutoClassify({
      page: pageNum,
      rows: _rows,
    }, setIsQueryingDetail);
    setPage(pageNum);
    setRows(rows);
  };
  const deleteClick = id => {
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      showCancelButton: true,
      data: {
        message: '确认删除',
        onConfirmationClick: () => deleteMemberClassification(id, getMemberAutoClassify({
          page: _page,
          rows: _rows,
        }))
      },
    });
  };


  const tableHeader = [{
    label: t('USER_CLASS'),
  }, {
    label: t('MIN_ACCUMULATE_VALUE'),
  }, {
    label: t('MAX_ACCUMULATE_VALUE'),
  }, {
    label: t('MAX_SINGLE_RELOAD_VALUE'),
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{`${data?.level} 类`}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.minAmount}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.maxAmount}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.singleDepositMax}</Label>),
    data => (
      <div className="d-flex justify-content-center">
        <div className="button handle mx-1" onClick={() => callback('memberClassification', data, true)}>{t('COMPILE')}</div>
        <div className="button find mx-1" onClick={() => deleteClick(data?.id)}>{t('DELETE')}</div>
      </div>
    ),
  ];

  return (
    <div className="MemberAutoClassificationWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <div className="p-0 m-0 mb-2">
          <div className="p-1 mb-2 filterWrapper d-flex align-items-center w-100">
            <div className="d-flex align-items-center">
              <div
                className="button add justify-content-center"
                onClick={() => callback('memberClassification', data, false)}
              >
                <Icon
                  libName="fa"
                  value={{
                    name: 'FaPlusCircle',
                    className: 'mb-1 mr-1'
                  }}
                />
                <Label className="p-0 px-1">新增分类</Label>
              </div>
            </div>
          </div>
        </div>
        <Row className="bigWrapper m-0 my-1 p-3">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeader}
            tableData={_info || (_isQueryingDetail ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
            tableBodyRowStyle={[]}
          />
        </Row>
        <Row className="justify-center w-full relative">
          <Pagination totalRecord={_totalRecord || 0} onClick={onPaginationClick} isQuery={false} />
        </Row>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberAutoClassification);