import { BJK8, CJ_BJK8 } from './BJK8/index';
import { CJ_11X5, type11X5 } from './11X5/index';
import { CJ_F31X7, F31X7 } from './F31X7/index';
import { CJ_F36X7, F36X7 } from './F36X7/index';
import { CJ_FANT, FANT } from './FANT/index';
import { CJ_GXK10, GXK10 } from './GXK10/index';
import { CJ_HK6, HK6 } from './HK6/index';
import { CJ_K3, K3 } from './K3/index';
import { CJ_KL10, KL10 } from './KL10/index';
import { CJ_PCDD, PCDD } from './PCDD/index';
import { CJ_PK10, PK10 } from './PK10/index';
import { CJ_SSC, SSC } from './SSC/index';

export default {
  CJ_PK10,
  CJ_11X5,
  CJ_BJK8,
  CJ_F31X7,
  CJ_F36X7,
  CJ_FANT,
  CJ_GXK10,
  CJ_HK6,
  CJ_K3,
  CJ_KL10,
  CJ_PCDD,
  CJ_SSC,
  BJK8,
  HK6,
  PK10,
  type11X5,
  F31X7,
  F36X7,
  K3,
  KL10,
  GXK10,
  SSC,
  PCDD,
  FANT,
};