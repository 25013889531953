import { HK6ShortCut, nonHK6ShortCut } from '../../SelectionItems';

const Games = [
  {
    code: ['PK10', 'BJK8', 'GXK10', 'PCDD', 'K3', '11X5', 'KL10', 'SSC', 'FANT'],
    name: [{
      label: 'PK10',
      value: 'PK10'
    }, {
      label: '快乐8',
      value: 'BJK8',
    }, {
      label: '广西快乐10',
      value: 'GXK10',
    }, {
      label: 'PC蛋蛋',
      value: 'PCDD',
    }, {
      label: '快3',
      value: 'K3',
    }, {
      label: '11选5',
      value: '11X5',
    }, {
      label: '快乐十分',
      value: 'KL10',
    }, {
      label: '时时彩',
      value: 'SSC',
    }, {
      label: '番摊',
      value: 'FANT'
    }],
    displayName: '高频彩',
    value: 1,
    items: nonHK6ShortCut,
    data: [
      {
        label: '号码类（球号、车号、正码等）',
        value: 'HM',
        subCode: ['CH', 'QH15', 'ZM', 'QH18']
      },
      {
        label: '两面类（大小、单双、龙虎、三军等）',
        value: 'LM',
        subCode: ['LM', 'LH', 'GYJHDX', 'GYJHDS', 'ZHDX', 'ZHDS', 'ZHWDX', 'LHH', 'ZHDWDX', 'QHH', 'DSH', 'SJ', 'DX', 'DS', 'JDX']
      },
      {
        label: '多项类（方位、中发白、总和过关等）',
        value: 'DuoX',
        subCode: ['FW18', 'ZFB18', 'ZHGG', 'CP', 'DP', 'FLSX15', 'SB15']
      },
      {
        label: '连码类（任选二、任选三、前二组选等）',
        value: 'LMA',
        subCode: ['LIANMA']
      },
      {
        label: '其它（冠亚和、前中后三等）',
        value: 'QiT',
        subCode: ['GYJH', 'QZH3', 'ZH1', 'ZH2', 'ZH3', 'DW2', 'DW3', 'HS2', 'HS2W', 'HS3', 'HS3W', 'ZX3', 'ZX6', 'FSZH', 'KD', 'ZLH', 'NN', 'SH', 'ZH', 'WX', 'WS', 'QS', 'DS', 'YZY', 'ZX', 'DW', 'TMBS', 'TMSB', 'HMDZ', 'FA', 'ZE', 'JI', 'SM', 'NI', 'TO', undefined]
      }
    ],
  },
  {
    code: ['HK6'],
    name: [{
      label: '六合彩',
      value: 'HK6'
    }],
    displayName: '六合彩',
    value: 2,
    items: HK6ShortCut,
    data: [
      {
        label: '号码类（特码、正码、正码特）',
        value: 'HM',
        subCode: ['TMA', 'TMB', 'ZMA', 'ZMB', 'ZMT'],
      },
      {
        label: '两面类（特码两面、总肖单双等）',
        value: 'LM',
        subCode: ['TMDSDX', 'LM', 'ZMLM', 'ZXDS']
      },
      {
        label: '多项类（尾数、平特一肖尾数、正码1-6色波）',
        value: 'DuoX',
        subCode: ['Z16SB', 'PTYX', 'TMWS', 'PTWS']
      },
      {
        label: '连码类（三中二、三全中、二全中等）',
        value: 'LMA',
        subCode: ['3Z2', '3QZ', '4QZ', '2QZ', '2ZT', 'TC']
      },
      {
        label: '其它（半半波特头尾、合肖总肖、连肖连尾等）',
        value: 'QiT',
        subCode: ['TDXDS', 'TMSB', 'TBBDX', 'TMBBB', '7SB', 'TMSX', 'TMTS', 'TMWX', 'PTWS', 'TMHX', 'ZX', 'ZENGX', '2LX', '3LX', '4LX', '5LX', '2LW', '3LW', 'SLW', '5LW', 'ZXBZ', 'TBBDS']
      }
    ],
  },
  {
    code: ['F31X7', 'F36X7'],
    name: [{
      label: '福建31选7',
      value: 'F31X7'
    }, {
      label: '福建36选7',
      value: 'F36X7'
    }],
    displayName: '低频彩',
    value: 3,
    items: nonHK6ShortCut,
    data: [
      {
        label: '号码类（特码、正码、正码特）',
        value: 'HM',
        subCode: ['TMA', 'TMB', 'ZMA', 'ZMB', 'ZMT'],
      },
      {
        label: '两面类（特码两面、总肖单双等）',
        value: 'LM',
        subCode: ['TMDSDX', 'LM', 'ZMLM', 'ZXDS']
      },
      {
        label: '多项类（尾数、平特一肖尾数、正码1-6色波）',
        value: 'DuoX',
        subCode: ['Z16SB', 'PTYX', 'TMWS', 'PTWS']
      },
      {
        label: '连码类（三中二、三全中、二全中等）',
        value: 'LMA',
        subCode: ['3Z2', '3QZ', '4QZ', '2QZ', '2ZT', 'TC']
      },
      {
        label: '其它（半半波特头尾、合肖总肖、连肖连尾等）',
        value: 'QiT',
        subCode: ['TM', 'ZTM', 'ZM', 'TMLM', 'ZMLM', 'ZFLM', 'LX', '2QZ', '1ZB', '3QZ', '2ZB', '4QZ', '3ZB', '3ZT', '4ZT', 'BZ']
      }
    ],
  },
];

export default Games;