import React, { useEffect, useRef, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Popover } from 'element-react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { HeaderMenu, Icon, Label, LotteryMenu } from '../../../components';
import { appActions, commonActions, whiteListActions } from '../../../redux/actions';
import { ReactComponent as ThemeSelector } from '../../../assets/image/themeSelector.svg';
import { ReactComponent as Tick } from '../../../assets/image/icon_tick.svg';

import oddsTypeMenu from '../../../constants/OddsManageSetting';

import './styles.scss';


const mapStateToProps = state => ({
  isHorizontal: state.appReducers.isHorizontal,
  selectedSubMenu: state.commonReducers.selectedSubMenu,
  activeLottery: state.commonReducers.activeLottery,
  userInfo: state.memberReducers.userInfo,
  userData: state.memberReducers.userData,
  onlineUserCount: state.onlineStatisticsReducers.onlineUserCount,
  orgUserCount: state.commonReducers.orgUserCount,
  themeColor: state.appReducers.themeColor,
});

const mapDispatchToProps = dispatch => ({
  toggleHorizontal: () => dispatch(appActions.toggleHorizontal()),
  updateSelectedSubMenu: val => dispatch(commonActions.updateSelectedSubMenu(val)),
  updateActiveLottery: val => dispatch(commonActions.updateActiveLottery(val)),
  getInfo: (obj, callback) => dispatch(whiteListActions.getInfo(obj, callback)),
  GetOrganizationUserCount: () => dispatch(commonActions.GetOrganizationUserCount()),
  setThemeColor: data => dispatch(appActions.setThemeColor(data)),
  setClickReloadFlag: data => dispatch(appActions.setClickReloadFlag(data)),
});

const AuthHeader = ({ setClickReloadFlag, setThemeColor, themeColor, change = () => {}, orgUserCount, GetOrganizationUserCount, getInfo, menuList = [], userData, onlineUserCount, userInfo, activeLottery, updateActiveLottery, updateSelectedSubMenu, selectedSubMenu, isHorizontal, toggleHorizontal, templateCode, lotteryType, history, selectedSubGameList, selectedSubType, updateSubType, resetItemInCart, ...props }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const location = useLocation();
  const [_showMenu, setShowMenu] = useState(false);
  const [_path, setPath] = useState();
  const _clockRef = useRef();

  useEffect(() => {
    if (!_path || _path !== location.pathname) {
      setPath(path);
    }
  }, [location]);

  useEffect(() => {
    if (activeLottery && _path === '/agent/in-time-sheet') {
      updateSelectedSubMenu(0);
    }
    if (_path === '/agent/company-management' || _path === '/agent/installment-management' || _path === '/agent/user-management' || _path === '/agent/third-party-game-management' || _path === '/agent/wallet-management' || _path === '/agent/individual-management' || _path === '/agent/report-management' || _path === '/agent/transaction-management' || _path === '/agent/system-settings' || _path === '/agent/odds-management') {
      if (selectedSubMenu !== 'IM_INSTALLMENTS_MANAGEMENT' && selectedSubMenu !== 'IM_HISTORY_RESULT_MANAGEMENT') {
        if (!selectedSubMenu) {
          const { tabId } = menuList.find(x => x.url === _path).settingsList[0];
          updateSelectedSubMenu(tabId);
        }
      }
      if (_path === '/agent/user-management') {
        GetOrganizationUserCount();
      }
    }
  }, [activeLottery?.templateCode, _path]);


  useEffect(() => {
    if (userData && userData?.levelValue === 0) {
      getInfo({
        loginId: '', page: 1, rows: 50
      });
    }
    const tick = () => {
      if (_clockRef.current) {
        _clockRef.current.innerText = moment().format('HH:mm:ss') || '00:00:00';
      }
    };
    const timerID = setInterval(() => tick(), 1000);
    return () => { clearInterval(timerID); };
  }, []);

  return (
    <div className="authHeader mx-0">
      <div className="mx-0 flex-nowrap gameResultMenuDefault">
        <div className="headerLogo d-flex justify-content-start align-items-center">
          {
            (userData && userData?.logo) && <img src={userData.logo} alt="logo" className="w-100" />
          }

        </div>
        <div className={`headerTopMenu pl-2 align-items-center d-flex ${!isHorizontal && 'horizontalPadding'}`}>
          <AnimatePresence>
            {isHorizontal && (
            <motion.div
              className="h-100"
              initial={{
                opacity: 0
              }}
              animate={{
                opacity: 1
              }}
              exit={{
                opacity: 0
              }}
            >
              <HeaderMenu menulist={menuList} history={history} {...props} />
            </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div className="headerToggleButton">
          <div className="text-white">
            <div className="headerNameAndTime">
              <div className="d-flex align-items-center">
                <Icon
                  onClick={() => {
                  }}
                  libName="fa"
                  value={{
                    name: 'FaUser',
                    className: 'text-white me-1',
                  }}
                />
                {`${userInfo && userData ? `${userInfo.levelName}${userData.isChild === 1 ? '子账号' : ''}` : ''}`}
              </div>
              <div>
                {userData ? userData.loginId : ''}
                <Icon
                  onClick={() => {
                  }}
                  libName="fa"
                  value={{
                    name: 'FaChevronDown',
                    className: 'text-white ms-1',
                  }}
                />

              </div>
              <div className="d-flex">
                <div>当前时间：</div>
                <div className="text-white" ref={_clockRef}>00:00:00</div>
              </div>
              {userData && userData?.levelValue < 2
              && (
              <div className="onlineMessageWrapper">
                <div className="onlineMessage">
                  <div>
                    <Icon
                      onClick={() => {
                      }}
                      libName="fa"
                      value={{
                        name: 'FaLaptop',
                        className: 'me-1',
                      }}
                    />
                    在线会员:
                    {onlineUserCount ? onlineUserCount.memberCount : 0}

                  </div>
                  <div>
                    <Icon
                      onClick={() => {
                      }}
                      libName="fa"
                      value={{
                        name: 'FaLaptop',
                        className: 'me-1',
                      }}
                    />
                    在线代理:
                    {onlineUserCount ? onlineUserCount.agentCount : 0}

                  </div>
                </div>
              </div>
              )}
            </div>
          </div>
          <div className="themeSelector">
            <Popover
              trigger="hover"
              popperClass="themeColorSelector"
              placement="bottom-end"
              content={(
                <div className="d-flex justify-content-between">
                  {['blue', 'red', 'green', 'yellow'].map(x => <div className={`colorSelectBox ${x}`} onClick={() => { setThemeColor(x); localStorage.setItem('themeColor', x); }}>{themeColor === x && <Tick />}</div>)}
                </div>
      )}
            >
              <div>
                <ThemeSelector />
              </div>
            </Popover>
          </div>
          <div className="switchBar border-white boder" onClick={toggleHorizontal} onKeyPress={() => { }} aria-hidden="true">
            <div className={`px-1 text-white d-flex align-items-center border-white border rounded switchBox ${isHorizontal ? 'justify-content-end' : 'justify-content-start'}`}>{isHorizontal ? '竖' : '横'}</div>
            <div className={`bg-white rounded ${isHorizontal ? 'switchLeft' : 'switchRight'}   ${isHorizontal ? 'switchToLeft' : 'switchToRight'}`} />
          </div>
        </div>
      </div>
      <div className="flex-nowrap gameList gameListOpen align-items-center mx-0">
        <Label value={`${t('CURRENT_SELECTED')}:`} />
        <div className="currentLotteryValueWrapper" onFocus={() => {}} onMouseOver={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)}>
          <Label value={activeLottery ? activeLottery.lotteryName : ''} />
          <Icon
            libName="io"
            value={{
              name: 'IoCaretForward',
              className: 'forwardIcon'
            }}
          />
          {_showMenu && <div className="headerLotteryMenuWrapper" onFocus={() => {}} onMouseOver={() => setShowMenu(true)}><LotteryMenu selectedItem={activeLottery.code} onClickItem={item => { if (path === '/agent/in-time-sheet') { updateSelectedSubMenu(0); } updateActiveLottery(item); setShowMenu(false); }} /></div>}
        </div>
        <div className="menuWrapper">
          <>
            {
              (path === '/agent/in-time-sheet' && activeLottery) && oddsTypeMenu[`CJ_${activeLottery.templateCode}`].map((item, index) => (
                <div
                  key={`menuitem_${item.displayName}`}
                  className={`mx-2 py-1 menuItem ${index === selectedSubMenu && location.pathname === '/agent/in-time-sheet' && 'selected'}`}
                  onClick={() => {
                    history.push('/agent/in-time-sheet');
                    updateSelectedSubMenu(index);
                    if (item?.midcode || item?.settingCode || item?.type) {
                      change([], true, 0, {
                        midCode: item?.midcode,
                        settingCode: item?.settingCode,
                        isLianma: item?.isLianMa || 0,
                        type: item?.type || ''
                      });
                    } else if (item?.children[0]?.midcode || item?.children[0]?.settingCode) {
                      change([], true, 0, {
                        midCode: item?.children[0]?.midcode,
                        settingCode: item?.children[0]?.settingCode,
                        isLianma: item?.isLianMa || 0
                      });
                    }
                  }}
                >
                  {item.displayName}
                </div>
              ))
            }
            {
                path === '/agent/in-time-sheet'
                  && (
                    <>
                      {menuList.find(x => x.url === path).settingsList.map(item => (
                        <div
                          key={`otherMenuItem_${item.label}`}
                          className={`px-2 py-1 menuItem  ${item.tabId === selectedSubMenu && 'selected'}`}
                          onClick={() => { setClickReloadFlag(+new Date()); updateSelectedSubMenu(item.tabId); }}
                        >
                          {t(item.label.toUpperCase())}
                        </div>
                      ))}
                    </>
                  )
            }
            {
                  (path === '/agent/company-management' || path === '/agent/installment-management' || path === '/agent/third-party-game-management' || path === '/agent/user-management' || path === '/agent/wallet-management' || path === '/agent/transaction-management' || path === '/agent/individual-management' || path === '/agent/report-management' || path === '/agent/system-settings' || path === '/agent/odds-management')
                    && (
                      <>
                        {menuList.find(x => x.url === path).settingsList.map(item => (
                          <div
                            key={`otherMenuItem_${item.label}`}
                            className={`px-2 py-1 menuItem  ${item.tabId === selectedSubMenu && 'selected'}`}
                            onClick={() => { setClickReloadFlag(+new Date()); updateSelectedSubMenu(item.tabId); }}
                          >
                            {`${t(item.label.toUpperCase())}${path === '/agent/user-management' ? orgUserCount ? `(${orgUserCount[item.id]})` : '(0)' : ''}`}
                          </div>
                        ))}
                      </>
                    )
            }
            {
                  path !== '/agent/company-management' && path !== '/agent/in-time-sheet' && path !== '/agent/installment-management' && path !== '/agent/third-party-game-management' && path !== '/agent/user-management' && path !== '/agent/wallet-management' && path !== '/agent/transaction-management' && path !== '/agent/individual-management' && path !== '/agent/report-management' && path !== '/agent/system-settings' && path !== '/agent/odds-management' && menuList.find(x => x.url === path).settingsList.map(item => (
                    <div
                      key={`otherMenuItem_${item.label}`}
                      className={`px-2 py-1 menuItem  ${location.pathname === path + item.url && 'selected'}`}
                      onClick={() => history.push(`${path}${item.url}`)}
                    >
                      {t(item.label.toUpperCase())}
                    </div>
                  ))
                }
          </>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthHeader);