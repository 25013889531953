import React, { useEffect, useState } from 'react';

import { Input, Radio, Select } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Icon, Pagination } from '../..';
import { commonActions, systemActions } from '../../../redux/actions';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  editSysServiceInfo: state.systemReducers.editSysServiceInfo,
  lotteryList: state.commonReducers.lotteryList,
  sysServiceInfo: state.systemReducers.sysServiceInfo,

});

const mapDispatchToProps = dispatch => ({
  getEditSysServiceInfo: (obj, callback) => dispatch(systemActions.EditSysServiceInfo(obj, callback)),
  SaveSysServiceInfo: (obj, callback) => dispatch(systemActions.SaveSysServiceInfo(obj, callback)),
  DeleteSysServiceInfo: (id, callback) => dispatch(systemActions.DeleteSysServiceInfo(id, callback)),
  GetSysServiceInfo: (obj, callback) => dispatch(systemActions.GetSysServiceInfo(obj, callback)),
  TestWCF: obj => dispatch(commonActions.TestWCF(obj)),
});

const SystemServiceManagement = ({ TestWCF, lotteryList = [], getEditSysServiceInfo, editSysServiceInfo, SaveSysServiceInfo, DeleteSysServiceInfo, sysServiceInfo, GetSysServiceInfo }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);

  const [serviceCode, setserviceCode] = useState('');
  const [code, setcode] = useState('');
  const [ip, setip] = useState('');
  const [lotteryType, setlotteryType] = useState();
  const [port, setport] = useState('');
  const [type, settype] = useState(1);
  const [path, setpath] = useState('');
  const [cacheTime, setcacheTime] = useState('');
  const [isDefault, setisDefault] = useState(0);
  const [status, setstatus] = useState(1);
  const [note, setnote] = useState('');

  useEffect(() => {
    GetSysServiceInfo();
    setIsQuerying(true);
    const _obj = {
      page,
      rows,
      serviceCode
    };
    GetSysServiceInfo(_obj, () => setIsQuerying(false));
  }, []);

  const saveAndEdit = () => {
    if (_pageFlag === 1 || _pageFlag === 2) {
      const obj = {
        id: _pageFlag === 2 ? editSysServiceInfo.id : '',
        code,
        ip,
        lotteryType,
        port,
        type,
        path,
        cacheTime,
        isDefault,
        status,
        note
      };
      const callback = () => {
        const _obj = {
          page,
          rows,
          serviceCode
        };
        GetSysServiceInfo(_obj, () => setPageFlag(0));
      };
      SaveSysServiceInfo(obj, callback);
    }
  };

  const Remove = id => {
    const _obj = {
      page,
      rows,
      serviceCode
    };
    DeleteSysServiceInfo(id, () => { setIsQuerying(true); GetSysServiceInfo(_obj, () => setIsQuerying(false)); });
  };


  const onSearchClick = code => {
    setIsQuerying(true);
    const _obj = {
      page: 1,
      rows: 15,
      serviceCode: code
    };
    GetSysServiceInfo(_obj, setIsQuerying);
    setserviceCode(code);
    setPage(1);
    setRows(15);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(true);
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      page: pageNum,
      rows,
      serviceCode
    };
    GetSysServiceInfo(_obj, setIsQuerying);
    (_obj, setIsQuerying);
  };

  useEffect(() => {
    if (_pageFlag === 1 || _pageFlag === 0) {
      setcode('');
      setip('');
      setlotteryType('');
      setport('');
      settype(1);
      setpath('');
      setcacheTime('');
      setisDefault(0);
      setstatus(1);
      setnote('');
    }
    if (_pageFlag === 2 && editSysServiceInfo) {
      setcode(editSysServiceInfo?.code || '');
      setip(editSysServiceInfo?.ip || '');
      setlotteryType(editSysServiceInfo?.lotteryType || '');
      setport(editSysServiceInfo?.port || '');
      settype(editSysServiceInfo?.type || '');
      setpath(editSysServiceInfo?.path || '');
      setcacheTime(editSysServiceInfo?.cacheTime || '');
      setisDefault(editSysServiceInfo.isDefault);
      setstatus(editSysServiceInfo.status);
      setnote(editSysServiceInfo?.note || '');
    }
  }, [_pageFlag, editSysServiceInfo]);

  return (
    <div className="SystemServiceManagementWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
              <Icon
                libName="md"
                value={{
                  name: 'MdArrowBack',
                  className: 'h5 mb-0 text-white'
                }}
              />
              返回
            </div>
            )}
            {`${t('SYSTEM_SERVICE_MANAGEMENT')} ${_pageFlag === 0 ? '' : _pageFlag === 1 ? '/ 新增' : '/ 编辑'}`}
          </div>
)}
      />
      {_pageFlag === 0 && (
      <>
        <div className="addNewGameBox my-3">
          <div className="operationBtn add" onClick={() => setPageFlag(1)}>
            <div className="plus_icon"><i className="el-icon-plus" /></div>
            新增
          </div>
          <div className="mx-2">
            服务类别
            <Select className="ms-2" value={serviceCode} placeholder="请选择" onChange={code => onSearchClick(code)}>
              {
                     [{
                       value: '', label: '全部'
                     }, {
                       value: 'WxChatService', label: '微信聊天服务'
                     }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                }
            </Select>
          </div>
        </div>

        <div className="mx-0 bg-white mb-3 px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: '编号',
            }, {
              label: 'IP',
            }, {
              label: '彩种',
            }, {
              label: '端口',
            }, {
              label: '类型',
            }, {
              label: '是否默认',
            }, {
              label: '缓存时间',
            }, {
              label: '状态',
            }, {
              label: '新增日期',
            }, {
              label: t('OPERATION'),
            }]
          }
            tableHeaderStyle={
            []
          }
            tableData={sysServiceInfo ? sysServiceInfo.serviceList : []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.serviceCode}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.ip}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.lotteryType}</Label>),

              data => (<Label className="d-flex justify-content-center">{data.port}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.type}</Label>),
              data => (<Label className={`d-flex justify-content-center ${data.isDefault === 0 && 'text-danger'}`}>{data.isDefault === 0 ? '否' : '是'}</Label>),

              data => (<Label className="d-flex justify-content-center">{data.cacheTime}</Label>),

              data => (<Label className={`d-flex justify-content-center ${data.svcStatus === 0 && 'text-danger'}`}>{data.svcStatus === 0 ? '禁用' : '启用'}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.createDate).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (
                <Label className="d-flex justify-content-center align-items-center">
                  <div className="operationBtn mx-2" onClick={() => { getEditSysServiceInfo(data.id, () => setPageFlag(2)); }}>编辑</div>
                  <div className="operationBtn cancel mx-2" onClick={() => { Remove(data.id); }}>删除</div>
                </Label>
              )
            ]
          }
          />
          <div className="d-flex align-items-center justify-content-center w-full relative">
            <Pagination totalRecord={sysServiceInfo ? (sysServiceInfo.totalRecords || 0) : 0} onClick={onPaginationClick} isQuery={false} />
          </div>
        </div>
      </>
      )}
      {_pageFlag !== 0 && (
        <>
          <div className="my-3 thirdPartyGamesTable">
            <div className="thirdPartyGamesTitle">{_pageFlag === 1 ? '新增系统服务管理' : '编辑系统服务管理'}</div>
            <div className="thirdPartyGamesRow">
              <div>编号 </div>
              <div>
                <Select value={code} placeholder="请选择" onChange={code => setcode(code)}>
                  {
                     [{
                       value: '', label: '全部'
                     }, {
                       value: 'WxChatService', label: '微信聊天服务'
                     }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>IP</div>
              <div><Input value={ip} onChange={text => setip(text.trim())} placeholder="请填写IP" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>彩种</div>
              <div>
                <Select value={lotteryType} placeholder="请选择" onChange={code => setlotteryType(code)}>
                  {
                     [{
                       code: '', lotteryName: '全部'
                     }].concat(lotteryList).map(el => <Select.Option key={el.code} label={el.lotteryName} value={el.code} />)
                }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>端口</div>
              <div><Input value={port} onChange={text => setport(text.trim())} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>类型</div>
              <div>
                <Radio value={1} checked={type === 1} onChange={() => settype(1)}>WCF</Radio>
                <Radio value={2} checked={type === 2} onChange={() => settype(2)}>WEB</Radio>
                {type === 1 && (
                <div
                  className="operationBtn ms-4"
                  onClick={() => TestWCF({
                    ip, port
                  })}
                >
                  测试
                </div>
                )}
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>path</div>
              <div><Input value={path} onChange={text => setpath(text.trim())} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>缓存时间</div>
              <div><Input value={cacheTime} onChange={text => setcacheTime(text.trim())} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>是否默认</div>
              <div>
                <Radio value={1} checked={isDefault === 1} onChange={() => setisDefault(1)}>是</Radio>
                <Radio value={0} checked={isDefault === 0} onChange={() => setisDefault(0)}>否</Radio>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>状态</div>
              <div>
                <Radio value={1} checked={status === 1} onChange={() => setstatus(1)}>启用</Radio>
                <Radio value={2} checked={status === 2} onChange={() => setstatus(2)}>暂停</Radio>
                <Radio value={0} checked={status === 0} onChange={() => setstatus(0)}>禁用</Radio>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>备注</div>
              <div>
                <Input
                  type="textarea"
                  autosize={{
                    minRows: 4, maxRows: 8
                  }}
                  value={note}
                  onChange={text => {
                    setnote(text);
                  }}
                  placeholder="备注或者说明"
                />
              </div>
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => saveAndEdit()}>保存</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemServiceManagement);