import { Amount, BuHuo, DisplayLabel, InTimeSheetTable, OddsControl, Profit } from '../../../../components';

const SEBO = [
  {
    componentType: InTimeSheetTable,
    data: {
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 9,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'TBBDS2',
          displayName: '红单',
        }, {
          code: 'TBBDS3',
          displayName: '红双',
        }, {
          code: 'TBBDX4',
          displayName: '红大',
        }, {
          code: 'TBBDX5',
          displayName: '红小',
        }, {
          code: 'TMBBB6',
          displayName: '红大单',
        }, {
          code: 'TMBBB7',
          displayName: '红大双',
        }, {
          code: 'TMBBB8',
          displayName: '红小单',
        }, {
          code: 'TMBBB9',
          displayName: '红小双',
        },
        {
          code: 'TBBDS11',
          displayName: '蓝单',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 9,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'TBBDS12',
          displayName: '蓝双',
        }, {
          code: 'TBBDX13',
          displayName: '蓝大',
        }, {
          code: 'TBBDX14',
          displayName: '蓝小',
        }, {
          code: 'TMBBB15',
          displayName: '蓝大单',
        }, {
          code: 'TMBBB16',
          displayName: '蓝大双',
        }, {
          code: 'TMBBB17',
          displayName: '蓝小单',
        }, {
          code: 'TMBBB18',
          displayName: '蓝小双',
        },
        {
          code: 'TBBDS20',
          displayName: '绿单',
        }, {
          code: 'TBBDS21',
          displayName: '绿双',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 9,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'TBBDX22',
          displayName: '绿大',
        }, {
          code: 'TBBDX23',
          displayName: '绿小',
        }, {
          code: 'TMBBB24',
          displayName: '绿大单',
        }, {
          code: 'TMBBB25',
          displayName: '绿大双',
        }, {
          code: 'TMBBB26',
          displayName: '绿小单',
        }, {
          code: 'TMBBB27',
          displayName: '绿小双',
        },
        {
          code: 'TMSB60',
          displayName: '红波',
          className: 'hk6_sebo redbo',
        }, {
          code: 'TMSB61',
          displayName: '蓝波',
          className: 'hk6_sebo bluebo',
        }, {
          code: 'TMSB62',
          displayName: '绿波',
          className: 'hk6_sebo greenbo',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '七色波',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 4,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: '7SB28',
          displayName: '红波',
          className: 'hk6_sebo redbo',
        }, {
          code: '7SB29',
          displayName: '蓝波',
          className: 'hk6_sebo bluebo',
        }, {
          code: '7SB30',
          displayName: '绿波',
          className: 'hk6_sebo greenbo',
        }, {
          code: '7SB31',
          displayName: '和局',
        },
      ]
    },
  },
];

export default SEBO;