import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import './styles.scss';

const mapStateToProps = state => ({
  inTimeSheetOdds: state.inTimeSheetReducers.inTimeSheetOdds,
  _selectedMainMenu: state.commonReducers.selectedSubMenu,
  activeLottery: state.commonReducers.activeLottery,
});

const InTimeSheetMenuRender = ({ setSelectedMainMenu, inTimeSheetOdds, menu, _selectedMainMenu = 0, _selectedSubMenu, setSelectedSubMenu = () => {}, setInfo = () => {}, setmidCode = () => {}, setIsQueryingDetail = () => {} }) => {
  const [_MenuOddsListObj, setMenuOddsListObj] = useState();

  useEffect(() => {
    if (inTimeSheetOdds) {
      const obj = {};
      inTimeSheetOdds.subMenuSum.forEach(element => {
        obj[element.menuId] = element;
      });
      setMenuOddsListObj(obj);
    }
  }, [inTimeSheetOdds]);

  return (
    <>
      <div className="subMenuPanel mb-1">
        {
                menu && menu.map((d, myin) => (
                  <div
                    className="menuItem"
                    onClick={() => {
                      setSelectedSubMenu(0);
                      setInfo([]);
                      setIsQueryingDetail(true);
                      setSelectedMainMenu(myin);
                      if (d.midcode || d.settingCode || d?.type) {
                        setmidCode({
                          midCode: d?.midcode,
                          settingCode: d?.settingCode,
                          isLianma: d?.isLianMa || 0,
                          type: d?.type || '',
                          displayName: d?.displayName
                        });
                      } else if (d?.children[0]?.midcode || d?.children[0]?.settingCode) {
                        setmidCode({
                          midCode: d?.children[0]?.midcode,
                          settingCode: d?.children[0]?.settingCode,
                          isLianma: d?.isLianMa || 0,
                          midSubCategory: d?.children[0]?.midSubCategory,
                          label: d?.children[0]?.label
                        });
                      }
                    }}
                    key={d?.displayName + myin.toString()}
                  >
                    <div className={`menuItemTitle ${_selectedMainMenu === myin && 'selected'}`}>{d?.displayName}</div>
                    <div className="menuAmount">{_MenuOddsListObj ? _MenuOddsListObj[d.tabId]?.amt ?? '--' : '--'}</div>
                  </div>
                ))
              }
      </div>
      {
              menu && menu[_selectedMainMenu]?.children && (
                <div className="subMenuPanel childMenuPanel">
                    {
                      menu[_selectedMainMenu].children.map((d, myin) => (
                        <div
                          key={d?.tabId + myin.toString()}
                          className={`d-flex px-2 ${_selectedSubMenu === myin ? 'active' : ''}`}
                          onClick={() => {
                            const _obj = {
                              midCode: d?.midcode,
                              settingCode: d?.settingCode,
                              isLianma: menu[_selectedMainMenu]?.isLianMa || 0,
                              midSubCategory: d?.midSubCategory,
                              label: d?.label
                            };
                            setIsQueryingDetail(true);
                            setSelectedSubMenu(myin);
                            setmidCode({
                              ..._obj
                            });
                          }}
                        >
                          {d?.label}
                          <div className="menuAmount ms-1">{_MenuOddsListObj ? _MenuOddsListObj[d.tabId]?.amt ?? '--' : '--'}</div>
                        </div>
                      ))
                    }
                </div>
              )
            }
    </>
  );
};

export default connect(mapStateToProps, null)(InTimeSheetMenuRender);