import React from 'react';

import { connect } from 'react-redux';

import Add from '../../assets/image/button_add.svg';
import Remove from '../../assets/image/button_remove.svg';
import { appActions } from '../../redux/actions';
import oddsSettingActions from '../../redux/actions/oddsSettingActions';

import './styles.scss';

const mapStateToProps = state => ({
  inTimeSheetOdds: state.inTimeSheetReducers.inTimeSheetOdds,
  inTimeSheetInfo: state.inTimeSheetReducers.inTimeSheetInfo,
  activeLottery: state.commonReducers.activeLottery,
});
const mapDispatchToProps = dispatch => ({
  SaveOdds: (obj, listObj) => dispatch(oddsSettingActions.SaveOdds(obj, listObj)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const OddsControl = ({ obj, _OddsListObj, betItemStyle, SaveOdds, activeLottery, inTimeSheetInfo, updateModalAlert }) => {
  const changeOdds = (type, oddsObj) => {
    if (inTimeSheetInfo) {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const { currentInstallmentNo } = inTimeSheetInfo.currentInstallment || {};

      const { downValue } = inTimeSheetInfo.oddsDownSettingInfo.find(x => x.code.includes(oddsObj.settingCode)) || {};
      const listObj = {
        lotterytype: activeLottery.code,
        installments: currentInstallmentNo,
        amountMode: 1,
        sortMode: 1,
      };
      if (type === 'add') {
        const temp = {
          lotteryType: activeLottery.code,
          oddsList: [{
            ...oddsObj, odds: Number.parseFloat((oddsObj.odds + downValue).toFixed(10))
          }]
        };
        SaveOdds(temp, listObj);
      } else {
        const temp = {
          lotteryType: activeLottery.code,
          oddsList: [{
            ...oddsObj, odds: Number.parseFloat((oddsObj.odds - downValue).toFixed(10))
          }]
        };
        SaveOdds(temp, listObj);
      }
    }
  };

  return (
    <div className={`${betItemStyle} oddsControlWrapper`}>
      {_OddsListObj && <img src={Add} alt="" className="button_do" onClick={e => { e.stopPropagation(); if (_OddsListObj[obj.code]?.enabled === 1) { changeOdds('add', _OddsListObj[obj.code]); } }} />}
      <div className="odd">{_OddsListObj && _OddsListObj[obj.code]?.enabled === 1 ? _OddsListObj[obj.code]?.odds : '--'}</div>
      {_OddsListObj && <img src={Remove} alt="" className="button_do" onClick={e => { e.stopPropagation(); if (_OddsListObj[obj.code]?.enabled === 1) { changeOdds('reduce', _OddsListObj[obj.code]); } }} />}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OddsControl);