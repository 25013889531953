import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import Icon from '../../Icon';

import './styles.scss';

const mapStateToProps = state => ({
  notifications: state.noticeReducers.notifications,
});

const QuickSetTable = ({ data, onClick, updateText, qsOdds, isAvailable, _midCode }) => {
  const { t } = useTranslation();
  const [ls, setLs] = useState();
  const [_selectedType, setSelectedType] = useState();
  const [_selChangeType, setChangeType] = useState('0');
  const [_selAdjustment, setAdjustment] = useState();
  const [_multiSelItems, setMultiSelItems] = useState([]);
  const [_text, setText] = useState('');

  const [_shuangMianText, setShuangMianText] = useState('');
  const [_selChangeTypeShuangMian, setChangeTypeShuangMian] = useState('0');

  useEffect(() => {
    if (data) {
      setLs(data);
    }
  }, [data]);

  useEffect(() => {
    setSelectedType();
    setChangeType('0');
    setMultiSelItems([]);
  }, [_midCode]);

  const getKeyName = (i, txt2) => `qsKey_${txt2 || ''}_${i?.toString()}`;
  const getClassName = (e, isActive) => `align-items-center m-1 justify-content-center ${e} ${isActive && 'active'}`;

  const selectType = [
    {
      label: '统一修改',
      value: '0'
    }, {
      label: '统一减',
      value: '1'
    }, {
      label: '统一加',
      value: '2'
    },
  ];

  const adjustmentValue = [
    {
      label: '0.01',
      value: '0.01'
    }, {
      label: '0.02',
      value: '0.02'
    }, {
      label: '0.1',
      value: '0.1'
    }, {
      label: '0.5',
      value: '0.5'
    }, {
      label: '1',
      value: '1'
    },
  ];

  return (
    <Col className="QsWrapper m-0 p-0">
      {
        ls && ls?.style === 1 && (
          <>
            <div className="oddHeader drawbottom">
              快速设置
              <Label className="text-danger">(传送后需按上方“修改”按钮进行保存)</Label>
            </div>
            {ls?.variables?.map((d, i) => (
              <Row key={getKeyName(i)} className="m-0 p-0 rowBorder">
                {d?.map((e, ind) => (
                  <Col key={e?.label || ind?.toString()} className="align-items-center justify-content-center p-1 myborder">
                    {
                      e?.label && (
                        <>
                          <Input
                            id={e?.label}
                            type="radio"
                            checked={e?.label === _selectedType?.label}
                            onChange={() => {
                              setSelectedType(e);
                            }}
                            className="p2"
                          />
                          <Label
                            for={e?.label}
                            className={getClassName(e?.className, _selectedType?.label === e?.label)}
                          >
                            {e?.label}
                          </Label>
                        </>
                      )
                    }
                  </Col>
                ))}
              </Row>
            ))}
          </>
        )
      }
      <Row className="d-flex flex-wrap align-items-center justify-content-center footer p-0 m-0">
        <Col className={`d-flex flex-wrap align-items-center justify-content-start p-2 ${ls?.style === 2 && ls?.variables?.length > 0 && 'col-7'}`}>
          {
            ls && ls?.style !== 2 && (
              <Label className="flex align-items-center justify-content-center mx-1">号码1~49：</Label>
            )
          }
          {
            selectType.map(x => (
              <div key={getKeyName(x.value, 'rad4qs')} className="radioButtonGroup">
                <Input
                  id={getKeyName(x.value, 'id')}
                  type="radio"
                  checked={_selChangeType === x?.value}
                  onChange={() => {
                    setChangeType(x?.value);
                  }}
                  className="p1"
                />
                <Label
                  for={getKeyName(x.value, 'id')}
                  className={getClassName('mx-2', _selChangeType === x?.value)}
                >
                  {x?.label}
                </Label>
              </div>
            ))
          }
          <Input
            className="mx-1"
            value={_text}
            type="number"
            onChange={({ target: { value: text } }) => {
              setText(text);
              updateText(text);
            }}
          />
          {
            adjustmentValue.map((x, ind) => (
              <Label onClick={() => { setAdjustment(x.value); setText(x.value); updateText(x.value); }} key={getKeyName('adjustmentKey', ind)} className={getClassName('adjustment', _selAdjustment === x.value)}>
                {x.label}
              </Label>
            ))
          }
          {
            ls && ls?.style === 2 && (
              <>
                <Button title="传送后需按上方“修改”按钮进行保存" className="button reset" onClick={() => onClick(_text, _selChangeType, _multiSelItems, ls?.variables?.length > 0)}>{t('SETTING')}</Button>
                {isAvailable && (
                <div className="bigIcon p-0 mx-2 active">
                  <Icon
                    libName="fa"
                    className="bigIcon p-0 m-0 active"
                    onClick={() => qsOdds('add', _multiSelItems, ls?.variables?.length > 0)}
                    value={{
                      name: 'FaRegPlusSquare',
                    }}
                  />
                  <Icon
                    libName="fa"
                    className="p-0 m-0"
                    onClick={() => qsOdds('minus', _multiSelItems, ls?.variables?.length > 0)}
                    value={{
                      name: 'FaRegMinusSquare',
                    }}
                  />
                </div>
                )}
              </>
            )
          }
          {
            ls && ls?.style !== 2 && (
            <>
              <Button title="传送后需按上方“修改”按钮进行保存" className="button cancel mx-2" onClick={() => onClick(_text, _selChangeType, _selectedType?.values)}>{t('SEND')}</Button>
              <div className="d-flex align-items-center justify-content-center flex-fill">
                <span className="mx-1">双面:</span>
                {
                  selectType.map(x => (
                    <div key={getKeyName(x.value, 'rad4qs')} className="radioButtonGroup">
                      <Input
                        id={getKeyName(x.value, 'id')}
                        type="radio"
                        checked={_selChangeTypeShuangMian === x?.value}
                        onChange={() => {
                          setChangeTypeShuangMian(x?.value);
                        }}
                        className="p1"
                      />
                      <Label
                        for={getKeyName(x.value, 'id')}
                        className={getClassName('mx-2', _selChangeTypeShuangMian === x?.value)}
                      >
                        {x?.label}
                      </Label>
                    </div>
                  ))
                }
                <Input
                  className="mx-1"
                  value={_shuangMianText}
                  type="number"
                  onChange={({ target: { value: text } }) => {
                    setShuangMianText(text);
                    updateText(text);
                  }}
                />
                <Button title="传送后需按上方“修改”按钮进行保存" className="button cancel mx-2" onClick={() => onClick(_shuangMianText, _selChangeTypeShuangMian, 'shuangMian')}>{t('SEND')}</Button>

              </div>
            </>
            )
          }
        </Col>
        {
          ls && ls?.style === 2 && ls?.variables?.length > 0 && (
            <Col className="align-items-end justify-content-end col-5">
              <div className="m-0 p-0 qsType2 d-flex flex-wrap">
                {ls?.variables[0].map((e, i) => (
                  <div key={e?.label || i?.toString()}>
                    {
                e?.label && (
                  <>
                    <Input
                      id={e?.label}
                      type="checkbox"
                      checked={_multiSelItems?.filter(x => x.label.includes(e.label))?.length > 0}
                      onChange={() => {
                        const test = [..._multiSelItems];
                        const index = Number(_multiSelItems.findIndex(item => item.label === e.label));
                        if (index !== -1) {
                          test.splice(index, 1);
                          setMultiSelItems(test);
                        } else {
                          test.push(e);
                          setMultiSelItems(test);
                        }
                      }}
                      className="p2 hide"
                    />
                    <Label
                      for={e?.label}
                      className={getClassName('adjustment', _multiSelItems?.filter(x => x.label === e?.label)?.length > 0)}
                    >
                      {e?.label}
                    </Label>
                  </>
                )
              }
                  </div>
                ))}
                <Label
                  className="button m-1 deselect"
                  onClick={() => {
                    const test = [..._multiSelItems];
                    if (test.length > 0) {
                      setMultiSelItems([]);
                    } else {
                      setMultiSelItems([...ls?.variables[0] ?? []]);
                    }
                  }}
                >
                  {_multiSelItems?.length > 0 ? t('CANCEL') : t('SELECT_ALL')}
                </Label>
              </div>
            </Col>
          )
        }
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, null)(QuickSetTable);