import React from 'react';

import { connect } from 'react-redux';

import { appActions, memberActions } from '../../redux/actions';
import noMatch from '../../assets/image/404.png';
import './styles.scss';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  captchaData: state.memberReducers.captchaData,
  mfaSetting: state.memberReducers.mfaSetting,
  activeLottery: state.commonReducers.activeLottery,
});

const mapDispatchToProps = dispatch => ({
  login: (username, password, captcha, mfa) => dispatch(memberActions.login(username, password, captcha, mfa)),
  getCaptcha: () => dispatch(memberActions.getCaptcha()),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const NoMatch = () => (
  <div className="noMatchWrapper">
    <img src={noMatch} alt="404" />
  </div>
);

export default connect(mapStateToProps, mapDispatchToProps)(NoMatch);