import React, { useEffect, useState } from 'react';

import { Label } from 'reactstrap';
import { connect } from 'react-redux';

import { AgentDataTable, LotteryMenu } from '../..';
import { memberActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  userInfo: state.memberReducers.userInfo,
  activeLottery: state.commonReducers.activeLottery,
});

const mapDispatchToProps = dispatch => ({
  GenMyInvitationCode: () => dispatch(memberActions.GenMyInvitationCode()),
  GenAgentInvitationCode: () => dispatch(memberActions.GenAgentInvitationCode()),
  getMyInfo: () => dispatch(memberActions.getMyInfo()),
});

const UserProfile = ({
  activeLottery,
  userInfo,
  GenMyInvitationCode,
  GenAgentInvitationCode,
  getMyInfo
}) => {
  const [_selectedLottery, setLottery] = useState(activeLottery);
  const [_showMenu, setShowMenu] = useState(false);
  const [_map, setMap] = useState(new Map());
  const [_isQuerying, setIsQuerying] = useState(false);
  const [items, setItems] = useState();
  const [zhan, setZhan] = useState();

  const labels = {
    1: '六合彩', 2: '高频彩', 3: '极速彩', 4: '番摊彩', 5: '幸运彩', 6: '幸运彩 ||'
  };

  useEffect(() => {
    getMyInfo();
  }, []);

  useEffect(() => {
    if (userInfo) {
      setIsQuerying(false);
      const mapObj = new Map();
      userInfo.zcList.forEach(item => {
        if (mapObj.has(item.lotteryType)) {
          const arr = [...mapObj.get(item.lotteryType), item];
          mapObj.set(item.lotteryType, arr);
        } else {
          mapObj.set(item.lotteryType, [item]);
        }
      });
      setMap(mapObj);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && userInfo.levelValue > 1) {
      let _str = '';
      const temp = Array(Object.keys(labels).length).fill(0).map(() => []);
      userInfo.myLotteryAndRate.forEach(item => {
        temp[item.categoryCode - 1].push(item);
      });
      temp.forEach(item => {
        if (item.length !== 0) {
          const title = labels[item[0].categoryCode];
          _str += `${title}: ${item[0].rateForDown}%  `;
        }
      });
      setZhan(_str);
    }
  }, [userInfo]);

  useEffect(() => {
    if (_map.size > 0) {
      const _temp = _map.get(_selectedLottery.code);
      setItems(_temp);
    }
  }, [_map, _selectedLottery]);

  const myFunction = () => {
    const copyText = document.getElementById('agentAdLink');
    navigator.clipboard.writeText(copyText.innerText);

    const tooltip = document.getElementById('myTooltip');
    tooltip.innerHTML = '复制成功';
  };

  const outFunc = () => {
    const tooltip = document.getElementById('myTooltip');
    tooltip.innerHTML = '复制到粘贴板';
  };

  return (
    <div className="userProfileWrapper">
      <div className="userProfileTableWrapper d-flex p-3">
        <div className="userTable w-100">
          <div>基本信息</div>
          <div className="d-flex">
            <div>账号</div>
            <div>{userInfo && userInfo.loginId}</div>
          </div>
          <div className="d-flex">
            <div>帐户余额</div>
            <div>{userInfo && userInfo.accountBalance}</div>
          </div>
          {zhan
          && (
          <div className="d-flex">
            <div>占比</div>
            <div>{zhan}</div>
          </div>
          )}
        </div>
      </div>
      { (userInfo && userInfo.levelValue > 1)
      && (
      <div className="userProfileTableWrapper d-flex p-3">
        <div className="w-50 userTable">
          <div>会员推广</div>
          <div className="d-flex">
            <div>会员推广链接</div>
            <div className="d-flex">
              <div id="agentAdLink" className="textBox">{userInfo && userInfo.memAdLink}</div>
              <div className="mytooltip">
                <div className="confirmButton copyBtn ms-2" onClick={() => myFunction()} onBlur={() => {}} onMouseOut={() => outFunc()}>
                  <span className="tooltiptext" id="myTooltip">复制到粘贴板</span>
                  复制
                </div>
              </div>
              <div className="text-danger flex-grow-1">通过此链接注册的身份为会员帐户</div>

            </div>
          </div>
          <div className="d-flex">
            <div>会员APP推广链接</div>
            <div className="d-flex">
              <div id="agentAdLink" className="textBox">{userInfo && userInfo.memAppLink}</div>
              <div className="mytooltip">
                <div className="confirmButton copyBtn ms-2" onClick={() => myFunction()} onBlur={() => {}} onMouseOut={() => outFunc()}>
                  <span className="tooltiptext" id="myTooltip">复制到粘贴板</span>
                  复制
                </div>
              </div>
              <div className="text-danger flex-grow-1">通过此链接注册的身份为会员帐户</div>

            </div>
          </div>
          <div className="d-flex">
            <div>会员推广码</div>
            <div className="d-flex">
              <div className="textBox">{userInfo && userInfo?.memAdLink && userInfo?.memAdLink.split('=')[1]}</div>
              <div className="confirmButton generateBtn ms-2" onClick={() => GenMyInvitationCode()}>生成</div>
            </div>

          </div>
        </div>
        <div className="w-50 userTable ms-3 align-self-start">
          <div>代理推广</div>
          <div className="d-flex">
            <div>代理推广链接</div>
            <div className="d-flex">
              <div id="agentAdLink" className="textBox">{userInfo && userInfo.agentAdLink}</div>
              <div className="mytooltip">
                <div className="confirmButton copyBtn ms-2" onClick={() => myFunction()} onBlur={() => {}} onMouseOut={() => outFunc()}>
                  <span className="tooltiptext" id="myTooltip">复制到粘贴板</span>
                  复制
                </div>
              </div>
              <div className="text-danger">通过此链接注册的身份为代理账户，拥有代理推广功能，可以推广下一级代理或会员</div>
            </div>
          </div>
          <div className="d-flex">
            <div>代理推广码</div>
            <div className="d-flex">
              <div className="textBox">{userInfo && userInfo?.agentAdCode}</div>
              <div className="confirmButton generateBtn ms-2" onClick={() => GenAgentInvitationCode()}>生成</div>
            </div>

          </div>
        </div>
      </div>
      )}
      <div className="menuRow">
        <div className="currentSelected" onFocus={() => {}} onMouseOver={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)}>
          {_selectedLottery.lotteryName}
          <span className="arrow_down" />
          {_showMenu && (
          <div className="userProfileMenuWrapper" onFocus={() => {}} onMouseOver={() => setShowMenu(true)}>
            <LotteryMenu
              _label={labels}
              _list={userInfo.myLotteryAndRate}
              onClickItem={item => { setLottery(item); setShowMenu(false); }}
              selectedItem={_selectedLottery.code}
            />
          </div>
          )}
        </div>
      </div>
      <div className="userProfileTableWrapper p-3">
        <div className="mx-0 bg-white">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: '玩法',
            }, {
              label: 'A盘退水',
            }, {
              label: 'B盘退水',
            }, {
              label: 'C盘退水'
            }, {
              label: 'D盘退水',
            }, {
              label: '单注金额',
            }, {
              label: '单期金额',
            }]
          }
            tableHeaderStyle={
            ['smallCol']
          }
            tableData={items || []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center noWrapStyle playStyle">{data.projectName}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.aReturn}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.bReturn}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.cReturn}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.dReturn}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.itemLimit}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.projectLimit}</Label>),
            ]
          }
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);