import { Amount, BuHuo, DisplayLabel, InTimeSheetTable, OddsControl, Profit } from '../../../../components';

const GJSetting = [
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '冠、亚军和指定',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '金额', '盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      mainColumnLength: 1,
      mainRowLength: 17,
      horizontal: true,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'GYJH1',
          displayName: '3',
        }, {
          code: 'GYJH2',
          displayName: '4',
        }, {
          code: 'GYJH3',
          displayName: '5',
        }, {
          code: 'GYJH4',
          displayName: '6',
        }, {
          code: 'GYJH5',
          displayName: '7',
        }, {
          code: 'GYJH6',
          displayName: '8',
        }, {
          code: 'GYJH7',
          displayName: '9',
        }, {
          code: 'GYJH8',
          displayName: '10',
        }, {
          code: 'GYJH9',
          displayName: '11',
        }, {
          code: 'GYJH10',
          displayName: '12',
        }, {
          code: 'GYJH11',
          displayName: '13',
        }, {
          code: 'GYJH12',
          displayName: '14',
        }, {
          code: 'GYJH13',
          displayName: '15',
        }, {
          code: 'GYJH14',
          displayName: '16',
        }, {
          code: 'GYJH15',
          displayName: '17',
        }, {
          code: 'GYJH16',
          displayName: '18',
        }, {
          code: 'GYJH17',
          displayName: '19',
        }
      ]
    },
  }, {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '冠、亚军和两面',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '金额', '盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      mainColumnLength: 1,
      mainRowLength: 4,
      horizontal: true,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '总投注额',
        code: 'count_SUM_PK10'
      }, {
        displayName: '最高亏损',
        code: 'maxLoss'
      }, {
        displayName: '最高盈利',
        code: 'maxProfit'
      }],
      list: [
        {
          code: 'GYJH18',
          displayName: '冠亚大',
        },
        {
          code: 'GYJH19',
          displayName: '冠亚小',
        },
        {
          code: 'GYJH20',
          displayName: '冠亚单',
        },
        {
          code: 'GYJH21',
          displayName: '冠亚双',
        },
      ]
    },
  }, {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '冠军',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '金额', '盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '冠军总注额',
        code: 'count_GJHM'
      }],
      list: [
        {
          code: 'GJHM7',
          displayName: '1',
          className: 'pk10Square square_1',
        }, {
          code: 'GJHM8',
          displayName: '2',
          className: 'pk10Square square_2',
        }, {
          code: 'GJHM9',
          displayName: '3',
          className: 'pk10Square square_3',
        }, {
          code: 'GJHM10',
          displayName: '4',
          className: 'pk10Square square_4',
        }, {
          code: 'GJHM11',
          displayName: '5',
          className: 'pk10Square square_5',
        }, {
          code: 'GJHM12',
          displayName: '6',
          className: 'pk10Square square_6',
        }, {
          code: 'GJHM13',
          displayName: '7',
          className: 'pk10Square square_7',
        }, {
          code: 'GJHM14',
          displayName: '8',
          className: 'pk10Square square_8',
        }, {
          code: 'GJHM15',
          displayName: '9',
          className: 'pk10Square square_9',
        }, {
          code: 'GJHM16',
          displayName: '10',
          className: 'pk10Square square_10',
        }, {
          code: 'GJLM1',
          displayName: '大',
        }, {
          code: 'GJLM2',
          displayName: '小',
        }, {
          code: 'GJLM3',
          displayName: '单',
        }, {
          code: 'GJLM4',
          displayName: '双',
        }, {
          code: 'GJLM5',
          displayName: '龙',
        }, {
          code: 'GJLM6',
          displayName: '虎',
        },
      ]
    },
  }, {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '亚军',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '金额', '盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '亚军总注额',
        code: 'count_YJHM'
      }],
      list: [
        {
          code: 'YJHM7',
          displayName: '1',
          className: 'pk10Square square_1',
        }, {
          code: 'YJHM8',
          displayName: '2',
          className: 'pk10Square square_2',
        }, {
          code: 'YJHM9',
          displayName: '3',
          className: 'pk10Square square_3',
        }, {
          code: 'YJHM10',
          displayName: '4',
          className: 'pk10Square square_4',
        }, {
          code: 'YJHM11',
          displayName: '5',
          className: 'pk10Square square_5',
        }, {
          code: 'YJHM12',
          displayName: '6',
          className: 'pk10Square square_6',
        }, {
          code: 'YJHM13',
          displayName: '7',
          className: 'pk10Square square_7',
        }, {
          code: 'YJHM14',
          displayName: '8',
          className: 'pk10Square square_8',
        }, {
          code: 'YJHM15',
          displayName: '9',
          className: 'pk10Square square_9',
        }, {
          code: 'YJHM16',
          displayName: '10',
          className: 'pk10Square square_10',
        }, {
          code: 'YJLM1',
          displayName: '大',
        }, {
          code: 'YJLM2',
          displayName: '小',
        }, {
          code: 'YJLM3',
          displayName: '单',
        }, {
          code: 'YJLM4',
          displayName: '双',
        }, {
          code: 'YJLM5',
          displayName: '龙',
        }, {
          code: 'YJLM6',
          displayName: '虎',
        },
      ]
    },
  },
];

export default GJSetting;