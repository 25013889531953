import { StatusCodes } from 'http-status-codes';

import { SET_AGENT_DOWNLINE_REPORT, SET_CLASSIFICATION_REPORT, SET_DEPOSITAND_WITH_DRAW_REPORT, SET_DEPOSITAND_WITH_DRAW_STATISTIC, SET_DEPOSITAND_WITH_DRAW_TOTAL_REPORT, SET_FINANCIAL_REPORT, SET_MEMBER_DOWNLINE_REPORT, SET_MEMBER_SUMMATION_DETAIL_REPORT, SET_MEMBER_SUMMATION_REPORT, SET_STATISTIC_REPORT, SET_WIN_LOST_REPORT } from '../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';

const setFinancialReport = data => ({
  type: SET_FINANCIAL_REPORT,
  value: data,
});

const setDepositWithdrawReport = data => ({
  type: SET_DEPOSITAND_WITH_DRAW_REPORT,
  value: data,
});

const setDepositWithdrawTotalStatistic = data => ({
  type: SET_DEPOSITAND_WITH_DRAW_TOTAL_REPORT,
  value: data,
});

const setDepositWithdrawStatistic = data => ({
  type: SET_DEPOSITAND_WITH_DRAW_STATISTIC,
  value: data,
});

const setMemberSummationReport = data => ({
  type: SET_MEMBER_SUMMATION_REPORT,
  value: data,
});

const setMemberSummationDetailReport = data => ({
  type: SET_MEMBER_SUMMATION_DETAIL_REPORT,
  value: data,
});

const setAgentDownlineReport = data => ({
  type: SET_AGENT_DOWNLINE_REPORT,
  value: data,
});

const setMemberDownlineReport = data => ({
  type: SET_MEMBER_DOWNLINE_REPORT,
  value: data,
});

const setWinLostReport = data => ({
  type: SET_WIN_LOST_REPORT,
  value: data,
});

const setClassificationReport = data => ({
  type: SET_CLASSIFICATION_REPORT,
  value: data,
});

const setStatisticReport = data => ({
  type: SET_STATISTIC_REPORT,
  value: data,
});

const getFinancialReport = (obj, callback = () => {}) => dispatch => {
  apiService.get('/Report/FinancialReport', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback(false);
      return dispatch(setFinancialReport(data.result || {}));
    }
  }).catch(error => {
    callback(false);
    dispatch(setFinancialReport());
    if (error?.response?.data) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
  });
};

const getBettingRank = (obj, callback = () => {}) => dispatch => {
  apiService.get('/Report/BettingRank', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return callback(data.result);
    }
  }).catch(error => {
    callback();
    if (error?.response?.data) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg,
          showCancelButton: false
        }
      }));
    }
  });
};

const getDepositWithdrawReport = (obj, callback = () => {}) => dispatch => {
  apiService.get('/Report/DepositWithdrawReport', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback(false);
      return dispatch(setDepositWithdrawReport(data.result || {}));
    }
  }).catch(error => {
    callback(false);
    dispatch(setDepositWithdrawReport());
    Promise.reject(error);
  });
};

const getDepositWithdrawTotalStatistic = (obj, callback = () => {}) => dispatch => {
  apiService.get('/Report/DepositWithdrawTotalStatistic', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback(false);
      return dispatch(setDepositWithdrawTotalStatistic(data.result || {}));
    }
  }).catch(error => {
    callback(false);
    dispatch(setDepositWithdrawTotalStatistic());
    Promise.reject(error);
  });
};

const getDepositWithdrawStatistic = (obj, callback = () => {}) => dispatch => {
  apiService.get('/Report/DepositWithdrawStatistic', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback(false);
      return dispatch(setDepositWithdrawStatistic(data.result || {}));
    }
  }).catch(error => {
    callback(false);
    dispatch(setDepositWithdrawStatistic());
    Promise.reject(error);
  });
};

const getMemberSummationReport = (obj, callback = () => {}) => dispatch => {
  apiService.get('/Report/MemberSummationReport', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setMemberSummationReport(data.result));
    }
  }).catch(error => {
    callback();
    dispatch(setMemberSummationReport());
    dispatch(appActions.updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: error.response.data.msg
      }
    }));
    Promise.reject(error);
  });
};

const getMemberSummationDetailReport = (obj, callback = () => {}) => dispatch => {
  apiService.get('/Report/MemberSummationDetailReport', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setMemberSummationDetailReport(data.result));
    }
  }).catch(error => {
    callback();
    dispatch(setMemberSummationDetailReport());
    Promise.reject(error);
  });
};

const getAgentDownlineReport = (obj, callback = () => {}) => dispatch => {
  apiService.get('/Report/AgentDownlineReport', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setAgentDownlineReport(data.result));
    }
  }).catch(error => {
    callback();
    dispatch(setAgentDownlineReport());
    Promise.reject(error);
  });
};

const getMemberDownlineReport = (obj, callback = () => {}) => dispatch => {
  apiService.get('/Report/MemberDownlineReport', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback();
      return dispatch(setMemberDownlineReport(data.result));
    }
  }).catch(error => {
    callback();
    dispatch(setMemberDownlineReport());
    Promise.reject(error);
  });
};

const getWinLostReport = (obj, callback = () => {}) => dispatch => {
  apiService.get('/Report/WinLostReport', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback(false);
      return dispatch(setWinLostReport(data.result));
    }
  }).catch(error => {
    callback(false);
    dispatch(setWinLostReport());
    Promise.reject(error);
  });
};

const getClassificationReport = (obj, callback = () => {}) => dispatch => {
  apiService.get('/Report/ClassificationReport', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback(false);
      return dispatch(setClassificationReport(data.result));
    }
  }).catch(error => {
    callback(false);
    dispatch(setClassificationReport());
    Promise.reject(error);
  });
};

const getStatisticReport = (obj, callback = () => {}) => dispatch => {
  apiService.get('/Report/StatisticReport', new URLSearchParams(obj)).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      callback(false);
      return dispatch(setStatisticReport(data.result));
    }
  }).catch(error => {
    callback(false);
    dispatch(setStatisticReport());
    Promise.reject(error);
  });
};

export default {
  getFinancialReport,
  getDepositWithdrawReport,
  getDepositWithdrawTotalStatistic,
  getDepositWithdrawStatistic,
  getMemberSummationReport,
  getMemberSummationDetailReport,
  getAgentDownlineReport,
  getMemberDownlineReport,
  getWinLostReport,
  getClassificationReport,
  getStatisticReport,
  getBettingRank,
};