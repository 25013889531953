/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';

import { connect } from 'react-redux';

import AccountFlow from '../../components/TransactionManagement/AccountFlow';
import AmountChange from '../../components/TransactionManagement/AmountChange';
import DepositAudit from '../../components/TransactionManagement/DepositAudit';
import GameTransferAudit from '../../components/TransactionManagement/GameTransferAudit';
import GiftSetting from '../../components/TransactionManagement/GiftSetting';
import HandleAgentRebate from '../../components/TransactionManagement/HandleAgentRebate';
import MessageCenter from '../../components/TransactionManagement/MessageCenter';
import OnlineRecord from '../../components/TransactionManagement/OnlineRecord';
import TransactionQuery from '../../components/TransactionManagement/TransactionQuery';
import UsdtWithdrawalAudit from '../../components/TransactionManagement/UsdtWithdrawalAudit';
import Vouncher from '../../components/TransactionManagement/Vouncher';
import WithdrawalAudit from '../../components/TransactionManagement/WithdrawalAudit';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
});

const TransactionManage = ({ selectedSubMenu }) => (

  <div className="transactionManagementWrapper p-3">
    {
      selectedSubMenu === 'TM_TRANSCTION_QUERY' && <TransactionQuery />
    }
    {
      selectedSubMenu === 'TM_USDT_WITHDRAWAL_AUDIT' && <UsdtWithdrawalAudit />
    }
    {
      selectedSubMenu === 'TM_ACCOUNT_DETAIL' && <AccountFlow />
    }
    {
      selectedSubMenu === 'TM_GAME_TRANSFER_AUDIT' && <GameTransferAudit />
    }
    {
      selectedSubMenu === 'TM_DEPOSIT_AUDIT' && <DepositAudit />
    }
    {
      selectedSubMenu === 'TM_WITHDRAWAL_AUDIT' && <WithdrawalAudit />
    }
    {
      selectedSubMenu === 'TM_AMOUNT_CHANGE' && <AmountChange />
    }
    {
      selectedSubMenu === 'TM_ONLINE_RECORD' && <OnlineRecord />
    }
    {
      selectedSubMenu === 'TM_MESSAGE_CENTER' && <MessageCenter />
    }
    {
      selectedSubMenu === 'TM_GIFT_SETTING' && <GiftSetting />
    }
    {
      selectedSubMenu === 'TM_VOUCHER' && <Vouncher />
    }
    {
      selectedSubMenu === 'TM_HANDLE_AGENT_RETURN' && <HandleAgentRebate />
    }

  </div>
);

export default connect(mapStateToProps, null)(TransactionManage);