import React, { useEffect, useState } from 'react';

import { Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Icon from '../../Icon';
import './styles.scss';


const mapStateToProps = state => ({
  lotteryItemsData: state.systemReducers.lotteryItemsData,
});

const mapDispatchToProps = dispatch => ({
  getLotteryItems: (userId, callback) => dispatch(systemActions.getLotteryItems(userId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateLotteryGameStatus: (obj, callback) => dispatch(systemActions.updateLotteryGameStatus(obj, callback)),
});

const LotteryManage = ({
  callback = () => {},
  lotteryItemsData,
  getLotteryItems,
  data,
  updateLotteryGameStatus,
  updateModalAlert
}) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [isQueryStatus, setQueryStatus] = useState(true);

  useEffect(() => {
    setInfo(lotteryItemsData);
    if (lotteryItemsData && JSON.stringify(lotteryItemsData) !== JSON.stringify(_info)) {
      const list = [...lotteryItemsData?.lotList];
      const parent = [...lotteryItemsData?.lotteries];
      [...lotteryItemsData?.lotteries].forEach((curr, myInd) => {
        if (parent) {
          const index = list?.findIndex(x => x.code === curr.code);
          parent[myInd].detail = list[index];
        }
      });
      setInfo(parent);
      setQueryStatus(false);
    }
  }, [lotteryItemsData]);

  useEffect(() => {
    getLotteryItems();
  }, [data]);

  const saveButton = (code, status) => {
    updateModalAlert({
      type: 'loading',
      visible: true
    });
    const obj = {
      code,
      status
    };
    updateLotteryGameStatus(obj, callback);
  };

  const tableHeader = [{
    label: `${t('LOTTERY')} code`,
  }, {
    label: t('NAME'),
  }, {
    label: t('CURRENT_INSTALLMENTS'),
  }, {
    label: t('BET_OPEN_TIME'),
  }, {
    label: t('SYSTEM_BET_OPEN_TIME'),
  }, {
    label: t('BET_CLOSE_TIME'),
  }, {
    label: t('SYSTEM_BET_CLOSE_TIME'),
  }, {
    label: t('BET_RESULT_TIME'),
  }, {
    label: t('STATUS'),
  }, {
    label: t('PLATE_TYPE'),
  }, {
    label: t('FUNCTION'),
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{data?.code}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.name}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.detail?.installment}</Label>),
    data => (
      <div className="d-flex justify-content-center">
        <Label className="d-flex justify-content-center">
          {data?.detail?.openTime && moment.unix(data?.detail?.openTime).format('YYYY-MM-DD HH:mm:ss')}
        </Label>
        {data?.detail?.openSeconds > 0 && (
        <Label className="d-flex negativeValue mx-2">
          {` [${0 - data?.detail?.openSeconds}]`}
        </Label>
        )}
      </div>
    ),
    data => (<Label className="d-flex justify-content-center">{data?.detail?.openTimeSys && moment.unix(data?.detail?.openTimeSys).format('YYYY-MM-DD HH:mm:ss')}</Label>),
    data => (
      <div className="d-flex justify-content-center">
        <Label className="d-flex justify-content-center">
          {data?.detail?.closeTime && moment.unix(data?.detail?.closeTime).format('YYYY-MM-DD HH:mm:ss')}
        </Label>
        {data?.detail?.closeSeconds > 0 && (
        <Label className="d-flex negativeValue mx-2">
          {` [${0 - data?.detail?.closeSeconds}]`}
        </Label>
        )}
      </div>
    ),
    data => (<Label className="d-flex justify-content-center">{data?.detail?.closeTimeSys && moment.unix(data?.detail?.closeTimeSys).format('YYYY-MM-DD HH:mm:ss')}</Label>),
    data => (
      <div className="d-flex justify-content-center">
        <Label className="d-flex justify-content-center">
          {data?.detail?.lotteryTime && moment.unix(data?.detail?.lotteryTime).format('YYYY-MM-DD HH:mm:ss')}
        </Label>
      </div>
    ),
    data => (<Label className="d-flex justify-content-center">{data?.detail?.status}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.detail?.pkStatus}</Label>),
    data => (
      <div className="d-flex justify-content-center">
        <div className="button handle mx-1" onClick={() => callback('lotterySetting', data)}>{t('SETTING')}</div>
        {(data?.detail?.closeStatus === 0 || !data?.detail?.closeStatus) ? <div className="button green mx-1" onClick={() => saveButton(data?.code, 1)}>{t('RECOVER')}</div>
          : <div className="button find mx-1" onClick={() => saveButton(data?.code, 0)}>{t('CLOSE_PLATE')}</div>}
      </div>
    ),
  ];

  return (
    <div className="LotteryManageWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <div className="p-2 m-0 mb-3 d-flex filterWrapper">
          <div
            className="button add"
            onClick={() => {
              updateModalAlert({
                type: 'lotteryReorder',
                visible: true,
                data: _info
              });
            }}
          >
            <Icon
              libName="fa"
              value={{
                name: 'FaSlidersH',
                className: 'm-1'
              }}
            />
            {t('SORTING')}
          </div>
        </div>
        <Row className="bigWrapper m-0 my-1 p-3">
          <AgentDataTable
            isQuerying={isQueryStatus}
            tableHeaderTitle={tableHeader}
            tableData={_info || (isQueryStatus ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
          />
        </Row>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotteryManage);