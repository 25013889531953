import { SET_ACTIVE_LOTTERY, SET_ACTIVE_MENU, SET_ALL_COMPANY_LIST, SET_LOTTERY_INSTALLMENTS, SET_LOTTERY_LIST, SET_ORG_USER_COUNT, SET_PARENT_MENU_LIST, SET_SELECTED_SUB_MENU, SET_SELECTED_SUB_SELECTION } from '../../actions/actionTypes';

const initialState = {
  lotteryList: [],
  activeLottery: undefined,
  selectedSubMenu: undefined,
  lotteryInstallments: undefined,
  selectedSubSelection: undefined,
  allCompanyList: undefined,
  parentMenuList: undefined,
  activeMenu: undefined,
  orgUserCount: undefined,
};

const commonReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOTTERY_LIST:
      return {
        ...state, lotteryList: action.value
      };
    case SET_ACTIVE_LOTTERY:
      return {
        ...state, activeLottery: action.value
      };
    case SET_SELECTED_SUB_MENU:
      return {
        ...state, selectedSubMenu: action.value
      };
    case SET_LOTTERY_INSTALLMENTS:
      return {
        ...state, lotteryInstallments: action.value
      };
    case SET_SELECTED_SUB_SELECTION:
      return {
        ...state, selectedSubSelection: action.value
      };
    case SET_ALL_COMPANY_LIST:
      return {
        ...state, allCompanyList: action.value
      };
    case SET_PARENT_MENU_LIST:
      return {
        ...state, parentMenuList: action.value
      };
    case SET_ACTIVE_MENU:
      return {
        ...state, activeMenu: action.value
      };
    case SET_ORG_USER_COUNT:
      return {
        ...state, orgUserCount: action.value
      };
    default:
      return state;
  }
};

export default commonReducers;