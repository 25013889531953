import React, { useEffect, useState } from 'react';

import { Input } from 'antd';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, commonActions, memberActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  setUserLogout: () => dispatch(memberActions.setUserLogout()),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  setUserData: data => dispatch(memberActions.setUserData(data)),
  ChangePassword: (obj, callback, showModal, existingData = {}) => dispatch(memberActions.ChangePassword(obj, callback, showModal, existingData)),
  getAvailableLottery: () => dispatch(commonActions.getAvailableLottery()),
  getMyInfo: () => dispatch(memberActions.getMyInfo()),
});

const ChangePasswordModal = ({ updateModalAlert, ChangePassword, setUserData, userData }) => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [cfmPassword, setCfmPassword] = useState('');
  const passwordValidation = /^[a-zA-Z0-9]{6,20}$/;

  const [errorMsg, setErrorMsg] = useState('');

  const onConfirmPress = () => {
    let errorMessage = '';
    if (!cfmPassword || !cfmPassword.trim()) errorMessage = t('CHANGE_PASSWORD_CONFIRM_PASSWORD_EMPTY_ERROR');
    if (cfmPassword !== newPassword) errorMessage = t('CHANGE_PASSWORD_NEW_CONFIRM_PASSWORD_MISMATCHED_ERROR');

    if (!newPassword || !newPassword.trim()) errorMessage = t('CHANGE_PASSWORD_NEW_PASSWORD_EMPTY_ERROR');
    if (!passwordValidation.test(newPassword)) errorMessage = t('CHANGE_PASSWORD_NEW_PASSWORD_UNFIT_ERROR');

    if (!oldPassword || !oldPassword.trim()) errorMessage = t('CHANGE_PASSWORD_PASSWORD_EMPTY_ERROR');
    if (!passwordValidation.test(oldPassword)) errorMessage = t('CHANGE_PASSWORD_NEW_PASSWORD_UNFIT_ERROR');
    if (errorMessage !== '') {
      return setErrorMsg(errorMessage);
    }
    return ChangePassword({
      oldPassword, newPassword, cfmPassword
    }, () => {
      setUserData({
        ...userData, checkPassword: false
      });
      updateModalAlert({
        visible: false
      });
    }, false, userData);
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler, false);
    return () => {
      document.removeEventListener('keydown', keyDownHandler, false);
    };
  }, [oldPassword, newPassword, cfmPassword]);

  function keyDownHandler(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onConfirmPress();
    }
  }
  return (
    <div className="changePasswordModalWrapper">
      <div className="modalHeader d-flex px-3">
        <div
          className="d-flex align-items-center col-6"
        >
          {t('CHANGE_PASSWORD')}
        </div>
        <div className="d-flex justify-content-end align-items-center col-6">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </div>
      </div>
      <div className="modalContent">
        <div className="loginBoxItem">
          <div>{t('SAFETY_CENTER_SAFEPASS')}</div>
          <Input type="password" value={oldPassword} placeholder={t('SAFETY_CENTER_SAFEPASS_PLACEHOLDER')} onChange={({ target: { value: text } }) => setOldPassword(text)} />
        </div>
        <div className="loginBoxItem">
          <div>{t('SAFETY_CENTER_NEW_SAFEPASS')}</div>
          <Input type="password" placeholder={t('SAFETY_CENTER_NEW_SAFEPASS_PLACEHOLDER')} value={newPassword} onChange={({ target: { value: text } }) => setNewPassword(text)} />
        </div>
        <div className="loginBoxItem">
          <div>{t('SAFETY_CENTER_CONFIRM_NEW_SAFEPASS')}</div>
          <Input type="password" placeholder={t('SAFETY_CENTER_CONFIRM_NEW_SAFEPASS_PLACEHOLDER')} value={cfmPassword} onChange={({ target: { value: text } }) => setCfmPassword(text)} />
        </div>
        <div className="errorMsg text-danger">
          {
            errorMsg
          }
        </div>
      </div>
      <div className="modalFooter d-flex align-items-center">
        <div className="loginBoxItem">
          <div className="confirmButton" onClick={() => onConfirmPress()}>{t('CONFIRM')}</div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);