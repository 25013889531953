import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';

import AutoRestockSetting from '../AutoRestockSetting';
import BetQueryDetail from '../BetQueryDetail';
import BetRevoke from '../BetRevoke';
import RestockOperationLog from '../RestockOperationLog';

import { InTimeSheetBottomMenu } from '../../components';
import InTimeSheetManageTable from '../../components/InTimeSheetManageTable';
import { inTimeSheetActions } from '../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
  userData: state.memberReducers.userData,
  activeLottery: state.commonReducers.activeLottery,
});

const mapDispatchToProps = dispatch => ({
  getInTimeSheetInfo: (lotteryCode, callback) => dispatch(inTimeSheetActions.getInTimeSheetInfo(lotteryCode, callback)),
});

const InTimeSheetManage = ({
  passData,
  selectedSubMenu,
  activeLottery,
  getInTimeSheetInfo,
}) => {
  const savedCallback = useRef();
  const [amountMode, setamountMode] = useState('1');
  const [buhuoData, setBuhuoData] = useState();
  const childRef = useRef();

  const [_isAvailable, setAvailability] = useState(false);
  const [_selectedAmount, setAmount] = useState(0);

  const getInTimeSheetInfoData = () => {
    getInTimeSheetInfo(activeLottery.code);
  };

  useEffect(() => {
    if (activeLottery) {
      getInTimeSheetInfoData();
    }
  }, [activeLottery]);


  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    const id = setInterval(tick, 20000);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    savedCallback.current = () => {
      if (activeLottery) {
        getInTimeSheetInfo(activeLottery.code);
      }
    };
  });

  const callOdds = () => {
    childRef?.current?.change();
  };

  return (
    <div className="InTimeSheetManageWrapper">
      { selectedSubMenu && (selectedSubMenu === 'IS_CHECK' || selectedSubMenu === 'IS_AUTO_RESTOCK_SETTINGS' || selectedSubMenu === 'IS_RESTOCK_RECORDS' || selectedSubMenu === 'IS_REVOKE')
        ? (
          <>
            {selectedSubMenu === 'IS_CHECK' && <BetQueryDetail />}
            {selectedSubMenu === 'IS_AUTO_RESTOCK_SETTINGS' && <AutoRestockSetting />}
            {selectedSubMenu === 'IS_RESTOCK_RECORDS' && <RestockOperationLog />}
            {selectedSubMenu === 'IS_REVOKE' && <BetRevoke />}
          </>
        )
        : (
          <>
            <InTimeSheetManageTable
              _selectedAmount={_selectedAmount}
              _isAvailable={_isAvailable}
              setAvailability={setAvailability}
              setBuhuoData={setBuhuoData}
              passData={passData}
              childRef={childRef}
              amountMode={amountMode}
              setamountMode={setamountMode}
            />
            <InTimeSheetBottomMenu _selectedAmount={_selectedAmount} setAmount={setAmount} _isAvailable={_isAvailable} buhuoData={buhuoData} callOdds={callOdds} amountMode={amountMode} />
          </>
        )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InTimeSheetManage);