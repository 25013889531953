import React, { useEffect, useState } from 'react';

import { Checkbox } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, LotteryMenu } from '../..';
import { companyActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  gamesInfo: state.companyReducers.gamesInfo,
  activeLottery: state.commonReducers.activeLottery
});

const mapDispatchToProps = dispatch => ({
  getGamesInfo: (lotteryType, callback) => dispatch(companyActions.getGamesInfo(lotteryType, callback)),
  SaveGamesInfo: (obj, callback) => dispatch(companyActions.SaveGamesInfo(obj, callback)),
});

const GameManagement = ({ activeLottery, getGamesInfo, gamesInfo, SaveGamesInfo }) => {
  const { t } = useTranslation();
  const [_showMenu, setShowMenu] = useState(false);
  const [_selectedLottery, setLottery] = useState(activeLottery);
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_game, setGames] = useState([]);

  useEffect(() => {
    setIsQuerying(true);
    getGamesInfo(_selectedLottery.code, () => setIsQuerying(false));
  }, [_selectedLottery]);

  useEffect(() => {
    if (gamesInfo) {
      setGames([].concat(gamesInfo?.gameSettingList));
    }
  }, [gamesInfo?.gameSettingList]);

  const onClickSave = () => {
    SaveGamesInfo({
      settings: _game
    }, () => {
      setIsQuerying(true);
      getGamesInfo(_selectedLottery.code, () => setIsQuerying(false));
    });
  };

  return (
    <div className="gameManagementWrapper">
      <CommonHeader headerName={t('GAME_MANAGEMENT')} />
      <div className="menuRow mb-2">
        <div className="currentSelected" onFocus={() => {}} onMouseOver={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)}>
          {_selectedLottery && (_selectedLottery.lotteryName)}
          <span className="arrow_down" />
          {_showMenu && (
          <div className="bogusBetWarningMenuWrapper" onFocus={() => {}} onMouseOver={() => setShowMenu(true)}>
            <LotteryMenu onClickItem={item => { setLottery(item); setShowMenu(false); }} selectedItem={_selectedLottery.code} />
          </div>
          )}
        </div>
        <div
          className="operationBtn"
          onClick={() => {
            const temp = _game.map(el => ({
              ...el, status: 1
            }));
            setGames(temp);
          }}
        >
          全选

        </div>
      </div>
      <div className="gamesContent">
        <div className="me-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: '项目名称',
            }, {
              label: '页面名称',
            }, {
              label: '开关',
            }]
          }
            tableHeaderStyle={
            []
          }
            tableBodyRowStyle={['addColumnColor']}
            tableData={_game ? _game.slice(0, Math.ceil(_game.length / 2)) : []}
            tableBodyRowProperty={
            [
              data => (<div className="d-flex justify-content-center">{data.projectName}</div>),
              data => (<div className="d-flex justify-content-center">{data.pageName}</div>),
              data => (
                <div className="d-flex justify-content-center">
                  <Checkbox
                    checked={data.status === 1}
                    onChange={() => {
                      const temp = _game.map(el => (el.id === data.id ? ({
                        ...el, status: data.status === 1 ? 0 : 1
                      }) : el));
                      setGames(temp);
                    }}
                  />
                </div>
              )
            ]
          }
          />
        </div>
        <div>
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: '项目名称',
            }, {
              label: '页面名称',
            }, {
              label: '开关',
            }]
          }
            tableHeaderStyle={
            []
          }
            tableBodyRowStyle={['addColumnColor']}
            tableData={_game ? _game.slice(Math.ceil(_game.length / 2)) : []}
            tableBodyRowProperty={
            [
              data => (<div className="d-flex justify-content-center">{data.projectName}</div>),
              data => (<div className="d-flex justify-content-center">{data.pageName}</div>),
              data => (
                <div className="d-flex justify-content-center">
                  <Checkbox
                    checked={data.status === 1}
                    onChange={() => {
                      const temp = _game.map(el => (el.id === data.id ? ({
                        ...el, status: data.status === 1 ? 0 : 1
                      }) : el));
                      setGames(temp);
                    }}
                  />
                </div>
              )
            ]
          }
          />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-2 pt-2 border-top">
        <div className="operationBtn me-3" onClick={() => onClickSave()}>修改</div>
        <div className="operationBtn reset" onClick={() => setGames([].concat(gamesInfo ? gamesInfo?.gameSettingList : []))}> 重置</div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GameManagement);