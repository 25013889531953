import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { agentActions, appActions, memberActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  addMemberInfoData: state.memberReducers.addMemberInfoData,
  editMemDetailData: state.memberReducers.editMemDetailData,
  userData: state.memberReducers.userData,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
});

const mapDispatchToProps = dispatch => ({
  getAddMemberInfo: (obj, callback) => dispatch(memberActions.GetAddMemberInfo(obj, callback)),
  getMemberDetail: (obj, callback) => dispatch(memberActions.GetMemberDetail(obj, callback)),
  saveMember: (obj, callback) => dispatch(memberActions.SaveMember(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  isValidName: (userId, callback) => dispatch(agentActions.isValidName(userId, callback)),
  isExistLoginId: (userId, callback) => dispatch(agentActions.isExistLoginId(userId, callback)),
  resetReturnMsgData: () => dispatch(agentActions.resetReturnMsgData()),
  resetMemDetail: () => dispatch(memberActions.resetMemDetail()),
});

const MemberAddEdit = ({ callback = () => { }, getAddMemberInfo, data, editMemDetailData, addMemberInfoData, isExistLoginId, isValidLoginIdMsg, saveMember, updateModalAlert }) => {
  const { t } = useTranslation();
  const [_password, setPassword] = useState();
  const [_loginIdReturnMsg, setLoginIdReturnMsg] = useState({});
  const [_loginId, setLoginId] = useState('');
  const [_passwordMsg, setPasswordMsg] = useState('');
  const [_selectedGameOption, setGameOption] = useState('A');
  const [_selectedWXStatus, setSelectedWXStatus] = useState(1);
  const [_selectedUserType, setSelectedUserType] = useState('1');
  const [_remark, setRemark] = useState();
  const [_memInfo, setMemInfo] = useState([]);


  useState(() => {
    if (editMemDetailData && JSON.stringify(editMemDetailData?.memInfo) !== JSON.stringify(_memInfo)) {
      setMemInfo(editMemDetailData?.memInfo);
    }
  }, [editMemDetailData]);


  useEffect(() => {
    if (data) {
      getAddMemberInfo({
        parentId: data?.orgId || '',
        isChild: data?.isChild
      });
    }
  }, [data]);

  useEffect(() => {
    setLoginIdReturnMsg(isValidLoginIdMsg);
  }, [isValidLoginIdMsg]);

  const saveButton = () => {
    const changData = () => {
      if (_loginIdReturnMsg?.state === 1 && _passwordMsg?.state === 1) {
        updateModalAlert({
          type: 'loading',
          visible: true
        });
        const obj = {
          memInfo: {
            OrganizationId: data?.orgId || '',
            LoginId: _loginId,
            Password: _password,
            Status: 1,
            WxEnabled: _selectedWXStatus,
            gameType: _selectedGameOption,
            Name: _loginId
          },
          cashMemInfo: {
            Remark: _remark,
            mLevel: _selectedUserType,
          },
          isupdate: false,
        };
        saveMember(obj, () => callback());
      } else {
        updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: '资料错误',
            showCancelButton: false
          }
        });
      }
    };
    updateModalAlert({
      type: 'confirmation',
      visible: true,
      data: {
        message: '请确认是否要修改/设置',
        onConfirmationClick: () => changData()
      }
    });
  };

  const resetDefault = () => {
    getAddMemberInfo({
      parentId: data?.orgId || '',
      isChild: data?.isChild
    });
  };

  const handleLoginIdOnBlur = text => {
    setLoginId(text);
    if (text?.length > 3) {
      isExistLoginId(text);
    } else {
      setLoginIdReturnMsg({
        state: 99,
        msg: '帐号必须由字母数字组成，4-12位!'
      });
    }
  };

  const handlePasswordOnBlur = text => {
    setPassword(text);
    if (text?.length > 5 && text?.length < 21 && text.match(/^[0-9a-zA-Z]+$/)) {
      if (/[0-9]/.test(text) && /[a-z]/.test(text) && /[A-Z]/.test(text)) {
        setPasswordMsg({
          state: 1,
          msg: '密码可用'
        });
      } else {
        setPasswordMsg({
          state: 99,
          msg: '密码必须由字母数字组成（区分大小写）！'
        });
      }
    } else {
      setPasswordMsg({
        state: 99,
        msg: '密码必须由字母数字组成，6-20位（区分大小写）！'
      });
    }
  };

  return (
    <div className="memberAddEditWrapper">
      <div className="mx-0 bg-white my-3  border-1">
        <Row className="titleWrapper m-0 my-2 p-2">
          账户资料
        </Row>
        <Row className="bigWrapper m-0 my-3 p-3">
          <Col className="col-12 m-0 p-0">
            <table className="agentDataTable p-3 m-0 w-100" cellSpacing="0" cellPadding="0" border="0">
              <tbody>
                <tr>
                  <td className="tableHeader col-5">代理账号</td>
                  <td>
                    <span>
                      <Input
                        className="d-inline"
                        type="text"
                        defaultValue={data?.loginId}
                        onBlur={({ target: { value: text } }) => handleLoginIdOnBlur(text)}
                        disabled
                      />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="tableHeader col-5">会员账号</td>
                  <td>
                    <span>
                      <Input
                        className="d-inline"
                        type="text"
                        defaultValue={_loginId}
                        onBlur={({ target: { value: text } }) => handleLoginIdOnBlur(text)}
                      />
                      <Label className={`d-inline mx-2 ${_loginIdReturnMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_loginIdReturnMsg?.msg}</Label>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="tableHeader col-5">登入密码</td>
                  <td>
                    <span>
                      <Input
                        className="d-inline"
                        type="text"
                        defaultValue={_password}
                        onBlur={({ target: { value: text } }) => handlePasswordOnBlur(text)}
                      />
                    </span>
                    <Label className={`d-inline mx-2 ${_passwordMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_passwordMsg?.msg}</Label>
                  </td>
                </tr>
                <tr>
                  <td className="tableHeader col-5">用户分类</td>
                  <td>
                    <div
                      className="d-flex p-0 rdSearchOptionsGroup"
                    >
                      {
                        Array.from('1234567').map(d => (
                          <div key={`rdUserTypeGroup_${d}`}>
                            <Input
                              id={`rdUserType_${d}`}
                              type="radio"
                              name="rdUserType"
                              value={d.value}
                              checked={_selectedUserType === d}
                              onChange={() => setSelectedUserType(d)}
                              className="mx-2"
                            />
                            <Label
                              for={`rdUserType_${d}`}
                              className={_selectedUserType === d ? 'active' : ''}
                            >
                              {d}
                              类
                            </Label>
                          </div>
                        ))
                      }
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="tableHeader col-5">可用盘类</td>
                  <td>
                    <div
                      className="d-flex p-0 rdSearchOptionsGroup"
                    >
                      {
                        Array.from(addMemberInfoData?.gameType || 'AB').map(d => (
                          <div key={`rdGameOptionsGroup_${d}`}>
                            <Input
                              id={`rdGameOptions_${d}`}
                              type="radio"
                              name="rdGameOptions"
                              value={d.value}
                              checked={_selectedGameOption === d}
                              onChange={() => setGameOption(d)}
                              className="mx-2"
                            />
                            <Label
                              for={`rdGameOptions_${d}`}
                              className={_selectedGameOption === d ? 'active' : ''}
                            >
                              {d}
                              {t('PLATE')}
                            </Label>
                          </div>
                        ))
                      }
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="tableHeader col-5">启用微信投注</td>
                  <td>
                    <div
                      className="d-flex p-0 rdSearchOptionsGroup"
                    >
                      <Input
                        id="rdWxStatus_active"
                        type="radio"
                        checked={_selectedWXStatus === 1}
                        onChange={() => setSelectedWXStatus(1)}
                        className="mx-2"
                      />
                      <Label
                        for="rdWxStatus_active"
                        className={_selectedWXStatus === 1 ? 'active' : ''}
                      >
                        {t('ACTIVE')}
                      </Label>
                      <Input
                        id="rdWxStatus_inactive"
                        type="radio"
                        checked={_selectedWXStatus === 0}
                        onChange={() => setSelectedWXStatus(0)}
                        className="mx-2"
                      />
                      <Label
                        for="rdWxStatus_inactive"
                        className={_selectedWXStatus === 0 ? 'active' : ''}
                      >
                        {t('DISABLED')}
                      </Label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="tableHeader col-5">备注</td>
                  <td>
                    <span className="textAreaInput">
                      <Input
                        className="d-inline"
                        type="text"
                        defaultValue={_remark}
                        Row="3"
                        onBlur={({ target: { value: text } }) => setRemark(text)}
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </div>

      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => { resetDefault(); callback(); }}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberAddEdit);