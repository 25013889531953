import React, { useEffect, useState } from 'react';

import { Col, Label, Row } from 'reactstrap';
import { DateRangePicker, Input, Select } from 'element-react';
import { connect } from 'react-redux';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../..';
import { thirdPartyGamesActions } from '../../../redux/actions';

import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  thirdBetHistory: state.thirdPartyGamesReducers.thirdBetHistory,
  thirdPartyList: state.thirdPartyGamesReducers.thirdPartyList,
});

const mapDispatchToProps = dispatch => ({
  GetThirdPartyGames: () => dispatch(thirdPartyGamesActions.GetThirdPartyGames()),
  GetBetHistory: (obj, callback) => dispatch(thirdPartyGamesActions.BetHistory(obj, callback)),
});

const BetHistoryQuery = ({ presetObj, thirdBetHistory, GetBetHistory, GetThirdPartyGames, thirdPartyList }) => {
  const { t } = useTranslation();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [_historyList, setHistoryList] = useState();
  const [_total, setTotal] = useState();
  const [_totalCount, setTotalCount] = useState();
  const [_date, setDate] = useState(presetObj ? [presetObj.beginDate, presetObj.endDate] : [moment().subtract(1, 'weeks').toDate(), moment().toDate()]);

  let dateRef;

  const [gameCode, setGameCode] = useState('');
  const [loginName, setloginName] = useState(presetObj ? presetObj.loginName : '');
  const [parentLoginName, setparentLoginName] = useState('');
  const [betId, setbetId] = useState('');
  const [dateType, setdateType] = useState(0);
  const [timeType, settimeType] = useState(1);
  const [isSettled, setisSettled] = useState(-1);
  const [orderBy, setorderBy] = useState(0);
  const [desc, setdesc] = useState(0);

  useEffect(() => {
    setIsQueryingDetail(true);
    GetThirdPartyGames();
    const _obj = {
      beginTime: moment(_date[0]).format('YYYY-MM-DD  HH:mm:ss'),
      endTime: moment(_date[1]).format('YYYY-MM-DD  HH:mm:ss'),
      page,
      row: rows,
    };
    GetBetHistory(_obj, setIsQueryingDetail);
  }, []);

  useEffect(() => {
    if (thirdBetHistory) {
      setHistoryList(thirdBetHistory?.betList);
      setTotal(thirdBetHistory?.total);
      setTotalCount(thirdBetHistory?.totalCount);
    }
  }, [thirdBetHistory]);


  const onSearchClick = () => {
    setIsQueryingDetail(true);
    const _obj = {
      gameCode,
      loginName,
      parentLoginName,
      betId,
      dateType,
      timeType,
      isSettled,
      orderBy,
      desc,
      page: 1,
      row: 15,
      beginTime: moment(_date[0]).format('YYYY-MM-DD  HH:mm:ss'),
      endTime: moment(_date[1]).format('YYYY-MM-DD  HH:mm:ss')
    };
    GetBetHistory(_obj, setIsQueryingDetail);
    setPage(1);
    setRows(15);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      gameCode,
      loginName,
      parentLoginName,
      betId,
      dateType,
      timeType,
      isSettled,
      orderBy,
      desc,
      page: pageNum,
      row: rows,
      beginTime: moment(_date[0]).format('YYYY-MM-DD  HH:mm:ss'),
      endTime: moment(_date[1]).format('YYYY-MM-DD  HH:mm:ss')
    };
    GetBetHistory(_obj, setIsQueryingDetail);
  };

  return (
    <Col className="betHistoryQueryWrapper">
      <div className="filterWrapper">
        <div className="leftPart">
          <div>
            <div className="mx-2">
              注单号
              <Input value={betId} onChange={text => setbetId(text.trim())} placeholder="请输入内容" />
            </div>
            <div className="mx-2">
              账号
              <Input value={loginName} onChange={text => setloginName(text.trim())} placeholder="请输入内容" />
            </div>
            <div className="mx-2">
              上级账号
              <Input value={parentLoginName} onChange={text => setparentLoginName(text.trim())} placeholder="请输入内容" />
            </div>
            <div className="mx-2">
              结算状态
              <Select value={isSettled} placeholder="请选择" onChange={code => setisSettled(code)}>
                {
                [{
                  value: -1, label: '全部'
                }, {
                  value: 0, label: '未结'
                }, {
                  value: 1, label: '已结'
                }, {
                  value: 2, label: '其他'
                }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
              }
              </Select>
            </div>
            <div className="mx-2">
              选择游戏
              <Select value={gameCode} placeholder="请选择" onChange={code => setGameCode(code)}>
                {
                  thirdPartyList && thirdPartyList.map(el => <Select.Option key={el.gameCode} label={el.gameName} value={el.gameCode} />)
                }
              </Select>
            </div>
          </div>
          <div>
            <div className="mx-2">
              时间类型
              <Select value={dateType} placeholder="请选择" onChange={code => setdateType(code)}>
                {
                [{
                  value: 0, label: '下注时间'
                }, {
                  value: 1, label: '结算时间'
                }, {
                  value: 2, label: '报表时间'
                }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
              }
              </Select>
            </div>
            <div className="mx-2">
              <Select value={timeType} placeholder="请选择" onChange={code => settimeType(code)}>
                {
                [{
                  value: 1, label: '北京时区'
                }, {
                  value: 2, label: '游戏时区'
                }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
              }
              </Select>
            </div>
            <div className="mx-2">
              <Label type="daterange" value={t('DATE')} className="px-2">{t('DATE')}</Label>
              <DateRangePicker
                className="betQueryDatepicker"
                value={_date}
                isShowTime
                placeholder={t('START_END_DATE')}
                rangeSeparator={` ${t('TO')} `}
                onChange={_date => {
                  setDate(_date);
                }}
                ref={e => dateRef = e}
                shortcuts={[{
                  text: t('TODAY'),
                  onClick: () => {
                    setDate([moment().toDate(), moment().toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('THIS_WEEK'),
                  onClick: () => {
                    setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_2_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }]}
              />
            </div>
            <div className="mx-2">
              <Select value={orderBy} placeholder="请选择" onChange={code => setorderBy(code)}>
                {
                [{
                  value: 0, label: '按注单时间'
                }, {
                  value: 1, label: '按结算时间'
                }, {
                  value: 2, label: '按下注金额'
                }, {
                  value: 3, label: ' 按输赢结果'
                }, {
                  value: 4, label: '按有效金额'
                }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
              }
              </Select>
            </div>
            <div className="mx-2">
              <Select value={desc} placeholder="请选择" onChange={code => setdesc(code)}>
                {
                [{
                  value: 0, label: '倒序'
                }, {
                  value: 1, label: ' 顺序'
                }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
              }
              </Select>
            </div>
          </div>
        </div>
        <div className="rightPart"><div className="searchButton" onClick={() => onSearchClick()}>查找</div></div>
      </div>
      <Row className="mx-0 bg-white px-0 border-1 mt-3">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: t('UP_AGENT'),
            }, {
              label: t('ACCOUNT_NAME'),
            }, {
              label: t('GAME_NAME'),
            }, {
              label: t('BET_CONTENT'),
            }, {
              label: t('PAN_TYPE'),
            }, {
              label: t('XING_MING'),
            }, {
              label: t('BEFORE_BET_BALANCE'),
            }, {
              label: t('AFTER_BET_BALANCE'),
            }, {
              label: t('BETS'),
            }, {
              label: t('AMOUNT'),
            }, {
              label: t('REBATE'),
            }, {
              label: t('WIN_LOSE'),
            }, {
              label: t('EFFECTIVE'),
            }, {
              label: t('STATUS')
            }]
          }
          tableData={_historyList ? _historyList.map((item, index) => ({
            ...item, index: index + 1
          })) : []}
          tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.index}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.parentName}</Label>),
              data => (
                <>
                  <div>
                    {`账户: ${data.loginName}`}
                  </div>
                  <div>{`游戏: ${data.thirdPartyLoginName}`}</div>
                </>
              ),
              data => (<Label className="d-flex justify-content-center">{data.gameName}</Label>),
              data => (
                <>
                  <div className="d-flex">
                    <span className="mx-1">时间:</span>
                    <div>
                      <span className="betTimeColor">{moment.unix(data.betTime).format('YYYY-MM-DD HH:mm:ss')}</span>
                      <span className="mx-2">{`(${moment.unix(data.reportTime).format('YYYY-MM-DD HH:mm:ss')})`}</span>
                    </div>
                  </div>
                  <div className="d-flex">
                    <span className="mx-1">单号:</span>
                    <span className="text-primary">{data.betId}</span>
                  </div>
                  <div className="d-flex">
                    <span className="text-nowrap mx-1">内容:</span>
                    <span className="text-start">{data.remark}</span>
                  </div>
                </>
              ),
              data => (<Label className="d-flex justify-content-center">{`${data.gameType}盘`}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.realName}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.beforeAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.afterAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.betCount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.betAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{`${data.rebateAmount} (${data.rebateValue}%)`}</Label>),
              data => (<Label className={data.winLost >= 0 ? 'positiveValue' : 'negativeValue'}>{data.winLost}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.validWinLost}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.isSettled === 0 ? '未结' : data.isSettled === 1 ? '已结' : '其他'}</Label>),

            ]
          }
          tableFooter={[[{
            value: (
              <div className="d-flex align-items-center ps-2">
                本页  注单数:
                {_total?.betCount}
                ,下注金额:
                {_total?.betAmount}
                , 退水金额:
                <div className={`mx-2 ${_total?.rebateAmount >= 0 ? 'positiveValue' : 'negativeValue'}`}>{_total?.rebateAmount}</div>
                , 输赢结果:
                <div className={`mx-2 ${_total?.winLost >= 0 ? 'positiveValue' : 'negativeValue'}`}>{_total?.winLost}</div>
                , 有效金额:
                {_total?.validWinLost}
              </div>
            ),
            colspan: 15,
            className: 'text-start total tableSum'
          }], [{
            value: (
              <div className="d-flex align-items-center ps-2">
                全部  注单数:
                {_total?.totalBetCount}
                ,下注金额:
                {_total?.totalBetAmount}
                , 退水金额:
                <div className={`mx-2 ${_total?.totalRebateAmount >= 0 ? 'positiveValue' : 'negativeValue'}`}>{_total?.totalRebateAmount}</div>
                , 输赢结果:
                <div className={`mx-2 ${_total?.totalWinLost >= 0 ? 'positiveValue' : 'negativeValue'}`}>{_total?.totalWinLost}</div>
                , 有效金额:
                {_total?.totalValidWinLost}
              </div>
            ),
            colspan: 15,
            className: 'text-start total tableSum'
          }]]}
        />
      </Row>
      <Row className="justify-center w-full relative">
        <Pagination totalRecord={_totalCount || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BetHistoryQuery);