import React from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CommonHeader, Icon } from '../..';

import AgentAdd from '../AgentAdd';
import AgentEdit from '../AgentEdit';
import MemberAdd from '../MemberAdd';
import SubAccountAddEdit from '../SubAccountAddEdit';
import 'element-theme-default';
import './styles.scss';


const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
});

const CreatePage = ({ data, selectedSubMenu, callback = () => {}, tabIndex, breadcrumb = [] }) => {
  const { t } = useTranslation();

  const renderTabItem = () => {
    const tempSelected = breadcrumb[breadcrumb.length - 1] || {};
    switch (tabIndex) {
      case 'create_member': return <MemberAdd key={data?.orgId} data={data} isEdit={false} callback={() => callback()} />;
      case 'create_subAccount': return <SubAccountAddEdit key={data.loginId} data={data} isEdit={false} callback={() => callback()} />;
      case 'create_Edit_agent': return <AgentEdit data={data} isUpdate={false} callback={() => callback()} />;// pending test
      case 'create_agent': return (
        <AgentAdd
          data={{
            orgId: tempSelected.selectedMemberId,
            levelValue: tempSelected.selectedUserLevel
          }}
          isUpdate={false}
          callback={() => callback()}
        />
      );
      default:
        break;
    }
  };

  const getMenuname = () => {
    switch (tabIndex) {
      case 'create_member': return '会员';
      case 'create_subAccount': return '子账号';
      case 'create_agent': return '代理';
      default:
        break;
    }
  };

  return (
    <div className="userManagementMenuWrapper p-3">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => callback()}>
              <Icon
                libName="md"
                value={{
                  name: 'MdArrowBack',
                  className: 'h5 mb-0 text-white'
                }}
              />
              返回
            </div>
            {data && selectedSubMenu && (`${t(selectedSubMenu.slice(3))} / 新增${getMenuname()} / ${data.loginId}`)}
          </div>
        )}
      />
      {renderTabItem()}
    </div>
  );
};

export default connect(mapStateToProps, null)(CreatePage);