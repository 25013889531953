import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import './styles.scss';


const mapStateToProps = state => ({
  openResultListData: state.systemReducers.openResultListData,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getLotteryResultSetting: (obj, callback) => dispatch(systemActions.getLotteryResultSetting(obj, callback)),
  saveLotteryResultSetting: (obj, callback) => dispatch(systemActions.saveLotteryResultSetting(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const OpenResultSetting = ({ openResultListData, getLotteryResultSetting, saveLotteryResultSetting, updateModalAlert }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [isQueryStatus, setQueryStatus] = useState(true);

  useEffect(() => {
    if (openResultListData && JSON.stringify(openResultListData) !== JSON.stringify(_info)) {
      setInfo(openResultListData);
      setQueryStatus(false);
    }
  }, [openResultListData]);

  useEffect(() => {
    // setIsQueryingDetail(true);
    setIsQueryingDetail(true);
    getLotteryResultSetting({}, setIsQueryingDetail);
  }, []);

  const textChange = (event, field) => {
    const index = Number(_info.findIndex(item => item?.drawInfo?.code === event.target.name));
    const newItems = [..._info];
    if (index !== -1) {
      newItems[index].drawInfo[field] = event.target.value;
      newItems[index].isChange = true;
    }

    setInfo(newItems);
  };

  const onSaveClick = () => {
    const result = _info.map(a => a.drawInfo);
    if (result.length > 0) {
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      saveLotteryResultSetting(result);
    } else {
      updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          message: '没有任何改动',
          showCancelButton: false
        }
      });
    }
  };

  const tableHeader = [{
    label: `${t('LOTTERY')} code`,
  }, {
    label: t('NAME'),
  }, {
    label: t('OFFICIAL_NAME'),
  }, {
    label: t('OFFICIAL_ADDRESS'),
  }, {
    label: t('REFERENCE_NAME'),
  }, {
    label: t('REFERENCE_ADDRESS'),
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{data?.drawInfo?.code}</Label>),
    data => (<Label className="d-flex justify-content-center">{data?.name}</Label>),
    data => (<div className="px-3"><Input type="text" name={data?.drawInfo?.code} onChange={e => textChange(e, 'officialName')} value={data?.drawInfo?.officialName} /></div>),
    data => (<div className="px-3"><Input type="text" name={data?.drawInfo?.code} onChange={e => textChange(e, 'officialUrl')} value={data?.drawInfo?.officialUrl} /></div>),
    data => (<div className="px-3"><Input type="text" name={data?.drawInfo?.code} onChange={e => textChange(e, 'referenceName')} value={data?.drawInfo?.referenceName} /></div>),
    data => (<div className="px-3"><Input type="text" name={data?.drawInfo?.code} onChange={e => textChange(e, 'referenceUrl')} value={data?.drawInfo?.referenceUrl} /></div>),
  ];

  return (
    <div className="OpenResultSettingWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="p-0 m-0 mb-2">
          <Col className="p-1 mb-2 filterWrapper d-flex align-items-center w-100">
            <Col />
            <Col className="align-items-center justify-content-end">
              <Button className="button add mx-1 right" onClick={() => onSaveClick()}>{t('SAVE')}</Button>
            </Col>
          </Col>
        </Row>
        <Row className="bigWrapper m-0 my-1 p-3">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={tableHeader}
            tableData={_info || (isQueryStatus ? [1, 2, 3] : [])}
            tableBodyRowProperty={tableContent}
            tableBodyRowStyle={['col-1', 'col-1', 'col-1', '', 'col-1', '']}
          />
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button add mx-1" onClick={() => onSaveClick()}>{t('SAVE')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenResultSetting);