import React, { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { Checkbox, Input } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { agentActions, appActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import './styles.scss';

const mapStateToProps = state => ({
  subAccountData: state.agentReducers.subAccountData,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getSubAccountInfo: (userId, callback) => dispatch(agentActions.getSubAccountInfo(userId, callback)),
  saveSubAgent: (obj, callback) => dispatch(agentActions.saveSubAgent(obj, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  isValidName: (userId, callback) => dispatch(agentActions.isValidName(userId, callback)),
  isExistLoginId: (userId, callback) => dispatch(agentActions.isExistLoginId(userId, callback)),
  resetReturnMsgData: () => dispatch(agentActions.resetReturnMsgData()),
  resetSubAccountInfo: () => dispatch(agentActions.resetSubAccountInfo()),
  GetAddSubAccountInfo: obj => dispatch(agentActions.GetAddSubAccountInfo(obj)),
});

const SubAccountAddEdit = ({
  callback = () => {},
  getSubAccountInfo,
  subAccountData,
  data,
  saveSubAgent,
  updateModalAlert,
  isEdit,
  isValidName,
  isExistLoginId,
  isValidNameMsg,
  isValidLoginIdMsg,
  resetReturnMsgData,
  GetAddSubAccountInfo,
  userData
}) => {
  const { t } = useTranslation();
  const [_password, setPassword] = useState('');
  const [_loginId, setLoginId] = useState('');
  const [_name, setName] = useState('');
  const [_nameReturnMsg, setNameReturnMsg] = useState({});
  const [_loginIdReturnMsg, setLoginIdReturnMsg] = useState({});
  const [_passwordMsg, setPasswordMsg] = useState('');

  const [_menu, setMenu] = useState([]);
  const [_childmenu, setchildMenu] = useState([]);
  const [_currentOrg, setCurrentOrg] = useState({});
  const [isQuery, setIsQuery] = useState(true);
  const [selectedSet, setSelectedSet] = useState(new Set());

  useEffect(() => {
    resetReturnMsgData();
    if (data) {
      if (isEdit) {
        getSubAccountInfo(data.orgId || data?.memberId);
      } else {
        const obj = {
          parentId: data?.selectedMemberId,
          isChild: userData?.loginId === data?.loginId ? userData?.isChild : 0
        };
        GetAddSubAccountInfo(obj);
      }
    }
  }, [data]);

  useEffect(() => {
    if (subAccountData) {
      const parentMenu = subAccountData?.parentMenu.filter(x => x.parentId === '-1')?.sort((a, b) => ((a.orderBy > b.orderBy) ? 1 : -1));
      const childMenu = subAccountData?.parentMenu.filter(x => x.parentId !== '-1')?.sort((a, b) => ((a.orderBy > b.orderBy) ? 1 : -1)) || [];
      setchildMenu(childMenu);
      setMenu(parentMenu);
      const tempOrgMenu = subAccountData.currentOrgMenu ? subAccountData.currentOrgMenu.map(x => x.id) : [];
      setSelectedSet(new Set(tempOrgMenu));
      if (isEdit) {
        setCurrentOrg(subAccountData.currentOrg);
        setLoginId(subAccountData?.currentOrg?.loginId);
        setName(subAccountData?.currentOrg?.name);
        setIsQuery(false);
      } else {
        setCurrentOrg(subAccountData.orgParent);
        setIsQuery(false);
      }
    }
  }, [subAccountData]);

  useEffect(() => {
    setNameReturnMsg(isValidNameMsg);
  }, [isValidNameMsg]);

  useEffect(() => {
    setLoginIdReturnMsg(isValidLoginIdMsg);
  }, [isValidLoginIdMsg]);

  const saveButton = isResetMFA => {
    const changData = () => {
      const newProj = _currentOrg;
      newProj.loginId = _loginId;
      newProj.name = _name;
      newProj.password = _password;
      if (isResetMFA) {
        newProj.validType = 0;
      }
      if (isEdit) {
        updateModalAlert({
          type: 'loading',
          visible: true
        });
        const obj = {
          subAgent: newProj,
          isUpdate: true,
          menuId: Array.from(selectedSet)
        };
        saveSubAgent(obj);
      } else {
        updateModalAlert({
          type: 'loading',
          visible: true
        });
        newProj.parentId = data?.selectedMemberId;
        const obj = {
          subAgent: newProj,
          isUpdate: false,
          menuId: Array.from(selectedSet)
        };
        saveSubAgent(obj, callback);
      }
    };

    if (_loginId === '' || (_password === '' && !isEdit) || (_password > 0 && _password < 6) || (!isEdit && _loginId > 12) || selectedSet.size === 0) {
      return updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          showCancelButton: false,
          message: _loginId === ''
            ? '请输入帐号登陆名！'
            : (_password === '' && !isEdit)
              ? '请输入帐号登陆密码！'
              : (_password > 0 && _password < 6)
                ? '密码最少6位数！'
                : (!isEdit && _loginId > 12)
                  ? '帐号不可以超过12个字符！'
                  : '请设置帐号权限！',
        }
      });
    }

    return updateModalAlert({
      type: 'confirmation',
      visible: true,
      data: {
        message: '请确认是否要修改/设置',
        onConfirmationClick: () => changData()
      }
    });
  };

  const handleNameOnBlur = text => {
    setName(text);
    if (text?.length > 3) {
      isValidName(text);
    }
  };

  const handleLoginIdOnBlur = text => {
    setLoginId(text);
    if (text?.length > 3) {
      isExistLoginId(text);
    }
  };

  const handlePasswordOnBlur = text => {
    setPassword(text);
    if (text?.length > 5 && text?.length < 21 && text.match(/^[0-9a-zA-Z]+$/)) {
      if (/[0-9]/.test(text) && /[a-z]/.test(text) && /[A-Z]/.test(text)) {
        setPasswordMsg({
          state: 1,
          msg: '密码可用'
        });
      } else {
        setPasswordMsg({
          state: 99,
          msg: '密码必须由字母数字组成（区分大小写）！'
        });
      }
    } else {
      setPasswordMsg({
        state: 99,
        msg: '密码必须由字母数字组成，6-20位（区分大小写）！'
      });
    }
  };

  const tableHeader = [{
    label: '主菜单',
  }, {
    label: (
      <div className="d-flex align-items-center">
        子菜单项
        <Checkbox
          className="mx-2 p-1"
          onChange={e => {
            const { checked } = e.target;
            let temp;
            if (checked) {
              temp = subAccountData?.parentMenu.map(x => x.id);
            } else {
              temp = [];
            }
            setSelectedSet(new Set(temp));
          }}
        >
          选择所有
        </Checkbox>
      </div>),
  }];

  const tableContent = [
    data => (
      <div className="d-flex justify-content-end no-wrap m-1 p-1">
        <Checkbox
          id={`main_${data.id}`}
          className="mx-2 px-1 align-items-center"
          value={data.id}
          checked={selectedSet.has(data.id)}
          onChange={e => {
            const { value } = e.target;
            const tempSet = new Set(selectedSet);
            if (tempSet.has(data.id)) {
              tempSet.delete(value);
            } else {
              tempSet.add(data.id);
            }
            setSelectedSet(tempSet);
          }}
        >
          {data?.menuName}
        </Checkbox>
      </div>
    ),
    data => (
      <>
        {
          _childmenu.filter(x => x.parentId === data.id).map(d => (
            <p key={`returnSelection_${d?.id}`} className="d-inline border-0 m-1">
              <Checkbox
                id={`chkConsolidate_${d.id}`}
                value={d.id}
                checked={selectedSet.has(d.id)}
                onChange={e => {
                  const { value } = e.target;
                  const tempSet = new Set(selectedSet);
                  if (tempSet.has(d.id)) {
                    tempSet.delete(value);
                  } else {
                    tempSet.add(d.id);
                  }
                  setSelectedSet(tempSet);
                }}
                className="m-1 p-1 justify-content-center align-items-center"
              >
                {d.menuName}
              </Checkbox>
            </p>
          ))
        }
      </>
    ),
  ];

  return (
    <div className="SubAccAddEditWrapper">
      <div className="mx-0 bg-white my-3  border-1">
        <Row className="titleWrapper m-0 my-2 p-2">
          账户资料
        </Row>
        <Row className="bigWrapper m-0 my-3 p-3">
          <table className="agentDataTable p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="tableHeader col-5">子帐号</td>
                <td>
                  <span>
                    <Input
                      type="text"
                      value={_loginId}
                      onChange={({ target: { value: text } }) => setLoginId(text.trim())}
                      onBlur={({ target: { value: text } }) => handleLoginIdOnBlur(text.trim())}
                      disabled={isEdit}
                    />
                    <Label className={`d-inline mx-2 ${_loginIdReturnMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_loginIdReturnMsg?.msg}</Label>
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">登入密码</td>
                <td>
                  <span>
                    <Input
                      type="text"
                      value={_password}
                      onChange={({ target: { value: text } }) => setPassword(text.trim())}
                      onBlur={({ target: { value: text } }) => handlePasswordOnBlur(text.trim())}
                    />
                  </span>
                  <Label className={`d-inline mx-2 ${_passwordMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_passwordMsg?.msg}</Label>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">子帐号名称</td>
                <td>
                  <span>
                    <Input
                      type="text"
                      value={_name}
                      onChange={({ target: { value: text } }) => setName(text.trim())}
                      onBlur={({ target: { value: text } }) => handleNameOnBlur(text.trim())}
                    />
                    <Label className={`d-inline mx-2 ${_nameReturnMsg?.state === 1 ? 'text-success' : 'text-danger'}`}>{_nameReturnMsg?.msg}</Label>
                  </span>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-5">MFA验证</td>
                <td>
                  {
                    _currentOrg?.validType === 1 ? (<Button className="button handle" onClick={() => saveButton(true)}>{t('RESET')}</Button>)
                      : (<span>未设置</span>)
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
        <Row className="titleWrapper m-0 my-2 p-2">
          权限设定
        </Row>
        <Row className="bigWrapper m-0 my-3 p-3">
          {
            isQuery ? (
              <Row>
                <AgentDataTable
                  isQuerying={isQuery}
                  tableHeaderTitle={tableHeader}
                  tableData={[1, 2, 3]}
                  tableBodyRowProperty={tableContent}
                  tableHeaderStyle={['textRight px-1', 'textLeft px-1']}
                  tableBodyRowStyle={['backgroundColored p-0 mediumSmallCol', 'textLeft flex-wrap']}
                />
              </Row>
            ) : (
              <AgentDataTable
                tableHeaderTitle={tableHeader}
                tableData={_menu}
                tableBodyRowProperty={tableContent}
                tableHeaderStyle={[' px-1', ' px-1']}
                tableBodyRowStyle={['backgroundColored p-0 mediumSmallCol', 'textLeft flex-wrap']}
              />
            )
          }
        </Row>
      </div>

      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => { callback(); }}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubAccountAddEdit);