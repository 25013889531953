import React, { useEffect, useState } from 'react';

import { DateRangePicker, Input, Select } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable } from '../..';
import { reportActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  depositWithdrawTotalStatistic: state.reportReducers.depositWithdrawTotalStatistic,
});

const mapDispatchToProps = dispatch => ({
  getDepositWithdrawTotalStatistic: (obj, callback) => dispatch(reportActions.getDepositWithdrawTotalStatistic(obj, callback)),
});

const DepositWithdrawTotalStatistic = ({ getDepositWithdrawTotalStatistic, depositWithdrawTotalStatistic }) => {
  const { t } = useTranslation();
  const [_date, setDate] = useState([moment().startOf('day').toDate(), moment().toDate()]);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [memberType, setMemType] = useState(1);
  const [_dateFlag, setDateFlag] = useState('1');
  const [_hide, setHide] = useState(false);
  const [loginName, setLoginName] = useState('');
  const [transactionType, setTransactionType] = useState(100);
  const [statsType, setStatsType] = useState(1);
  const [minAmount, setMinAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState('');

  let dateRef;

  useEffect(() => {
    const obj = {
      memberType, loginName, transactionType, statsType, minAmount, maxAmount, beginDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')
    };
    getDepositWithdrawTotalStatistic(obj, setIsQueryingDetail);
  }, []);

  const onSearchClick = () => {
    const obj = {
      memberType, loginName, transactionType, statsType, minAmount, maxAmount, beginDate: moment(_date[0]).format('YYYY-MM-DD'), endDate: moment(_date[1]).format('YYYY-MM-DD')
    };
    setIsQueryingDetail(true);
    getDepositWithdrawTotalStatistic(obj, setIsQueryingDetail);
  };

  const dateSelector = id => {
    setDateFlag(id);
    switch (id) {
      case '1':
        setDate([moment().toDate(), moment().toDate()]);
        break;
      case '2':
        setDate([moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]);
        break;
      case '3':
        setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
        break;
      case '4':
        setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
        break;
      case '5':
        setDate([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
        break;
      case '6':
        setDate([moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()]);
        break;
      default:
        break;
    }
  };

  const cancel = () => {
    setDate([moment().toDate(), moment().toDate()]);
    setMemType(1);
    setDateFlag('');
    setLoginName('');
    setTransactionType(100);
    setStatsType(1);
    setMinAmount('');
    setMaxAmount('');
    const obj = {
      memberType: 1, beginDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')
    };
    getDepositWithdrawTotalStatistic(obj, setIsQueryingDetail);
  };

  return (
    <div className="depositWithdrawTotalStatisticWrapper">
      <div className="depositWithdrawTotalStatisticTableWrapper d-flex p-3">
        <div className="userTable w-100">
          <div className="d-flex">
            <div>日期范围</div>
            <div>
              <DateRangePicker
                className="betQueryDatepicker"
                value={_date}
                isShowTime
                placeholder={t('START_END_DATE')}
                rangeSeparator={` ${t('TO')} `}
                onChange={_date => {
                  setDate(_date);
                }}
                ref={e => dateRef = e}
                shortcuts={[{
                  text: t('TODAY'),
                  onClick: () => {
                    setDate([moment().toDate(), moment().toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('THIS_WEEK'),
                  onClick: () => {
                    setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_2_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }]}
              />
              <div onClick={e => dateSelector(e.target.id)} className="d-flex mx-2">
                {
              [{
                label: '今天', value: 1
              }, {
                label: '昨天', value: 2
              }, {
                label: '本星期', value: 3
              }, {
                label: '上星期', value: 4
              }, {
                label: '本月', value: 5
              }, {
                label: '上月', value: 6
              }].map(item => <span id={item.value} className={`mx-1 operationBtn dateSelectBtn ${+_dateFlag === item.value && 'selected'}`}>{item.label}</span>)

                 }
              </div>
              <div className="operationBtn findAndCollapse mx-1" onClick={onSearchClick}>查询</div>
              <div className="operationBtn cancel mx-1" onClick={() => cancel()}>取消</div>
              <div className="flex-grow-1 d-flex justify-content-end pe-4">
                <div className="operationBtn findAndCollapse" onClick={() => setHide(!_hide)}>{!_hide ? '收起' : '展开'}</div>
              </div>

            </div>
          </div>
          {!_hide && (
          <>
            <div className="d-flex">
              <div>金额范围</div>
              <div>
                <Input className="mx-1" value={minAmount} onChange={text => { if (!Number.isNaN(text)) { setMinAmount(text === '' ? 0 : parseInt(text, 10)); } }} />
                —
                <Input className="mx-1" value={maxAmount} onChange={text => { if (!Number.isNaN(text)) { setMaxAmount(text === '' ? 0 : parseInt(text, 10)); } }} />
              </div>
            </div>
            <div className="d-flex">
              <div>交易类型</div>
              <div>
                <Select
                  popper-class="select-popper"
                  value={transactionType}
                  onChange={value => setTransactionType(value)}
                >
                  {
                    [{
                      label: '存款', value: 100
                    }, {
                      label: '提款', value: 200
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
                </Select>

              </div>
            </div>
            <div className="d-flex">
              <div>统计方式</div>
              <div>
                <Select
                  popper-class="select-popper"
                  value={statsType}
                  onChange={value => setStatsType(value)}
                >
                  {
                    [{
                      label: '按单', value: 1
                    }, {
                      label: '按天', value: 2
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
                </Select>

              </div>
            </div>
            <div className="d-flex">
              <div>用户类型</div>
              <div>
                <Select
                  popper-class="select-popper"
                  value={memberType}
                  onChange={value => setMemType(value)}
                >
                  {
                    [{
                      label: '会员', value: 1
                    }, {
                      label: '代理', value: 2
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
                </Select>
              </div>
            </div>
            <div className="d-flex">
              <div>用户名</div>
              <div>
                <Input className="mx-1" value={loginName} onChange={text => setLoginName(text)} />
              </div>
            </div>
          </>
          )}
        </div>
      </div>
      <div className="depositWithdrawTotalStatisticTableWrapper p-3">
        <div className="mx-0 bg-white">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={
            [{
              label: '账号',
            }, {
              label: '交易笔数',
            }, {
              label: '交易金额',
            }, {
              label: (
                <div className="combineTitleCell">
                  <div className="h-50">手续费</div>
                  <div className="d-flex h-50">
                    <div>所有</div>
                    <div>非第三方</div>
                    <div>第三方</div>
                  </div>
                </div>
              ),
            }]
          }
            tableHeaderStyle={['', '', '', 'p-0']}
            tableData={depositWithdrawTotalStatistic ? depositWithdrawTotalStatistic.reportList : []}
            tableBodyRowStyle={['', '', '', 'p-0']}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.loginName}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.transactionCount}</Label>),
              data => (<Label className={`d-flex justify-content-center ${parseFloat(data.transactionAmount) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.transactionAmount}</Label>),
              data => (
                <Label className="d-flex justify-content-center combineBodyRowCell">
                  <div>{data.allFee}</div>
                  <div>{data.nonThirdPartyFee}</div>
                  <div>{data.thirdPartyFee}</div>
                </Label>
              ),
            ]
          }
            tableFooter={[
              [{
                value: '本页总和',
                colspan: 1,
                className: 'pe-1 total tableSum'
              },
              {
                value: (depositWithdrawTotalStatistic && depositWithdrawTotalStatistic.total.transactionCount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (depositWithdrawTotalStatistic && depositWithdrawTotalStatistic.total.transactionAmount) || 0,
                colspan: 1,
                className: 'tableSum'
              },

              {
                render: () => {
                  if (depositWithdrawTotalStatistic) {
                    return (
                      <Label className="d-flex justify-content-center combineBodyRowCell">
                        <div>{depositWithdrawTotalStatistic.total.allFee}</div>
                        <div>{depositWithdrawTotalStatistic.total.nonThirdPartyFee}</div>
                        <div>{depositWithdrawTotalStatistic.total.thirdPartyFee}</div>
                      </Label>
                    );
                  }

                  return 0;
                },
                colspan: 1,
                className: 'tableSum noPadding'
              }],
              [{
                value: '所有总和',
                colspan: 1,
                className: ' pe-2 total tableSum'
              },
              {
                value: (depositWithdrawTotalStatistic && depositWithdrawTotalStatistic.total.totalTransactionCount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (depositWithdrawTotalStatistic && depositWithdrawTotalStatistic.total.totalTransactionAmount) || 0,
                colspan: 1,
                className: 'tableSum'
              },

              {
                render: () => {
                  if (depositWithdrawTotalStatistic) {
                    return (
                      <Label className="d-flex justify-content-center combineBodyRowCell">
                        <div>{depositWithdrawTotalStatistic.total.totalAllFee}</div>
                        <div>{depositWithdrawTotalStatistic.total.totalNonThirdPartyFee}</div>
                        <div>{depositWithdrawTotalStatistic.total.totalThirdPartyFee}</div>
                      </Label>
                    );
                  }
                  return 0;
                },
                colspan: 1,
                className: 'tableSum noPadding'
              }]
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositWithdrawTotalStatistic);