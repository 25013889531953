import { Amount, BuHuo, DisplayLabel, InTimeSheetTable, OddsControl, Profit } from '../../../../components';
import gameItemIconMap from '../../../GameItemIcon';

const ZONGXIANG = [
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '三军',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['种类', '赔率', '金额', '输赢', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number k3_category', 'column-odds w-25', 'column-amount w-25', 'column-profit w-25', 'column-buhuo w-25'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '三军总注额',
        code: 'item_SJ'
      }],
      list: [
        [
          {
            code: 'SJ11',
            img: gameItemIconMap.CJ_FISH,
            displayName: '1/鱼',
            className: 'mt-1',
          }, {
            code: 'SJ21',
            displayName: '1/鱼',
            className: '',
          }, {
            code: 'SJ31',
            displayName: '1/鱼',
            className: '',
          }
        ],
        [
          {
            code: 'SJ12',
            img: gameItemIconMap.CJ_PRAWN,
            displayName: '2/虾',
            className: 'mt-1',
          }, {
            code: 'SJ22',
            displayName: '2/虾',
            className: '',
          }, {
            code: 'SJ32',
            displayName: '2/虾',
            className: '',
          }
        ],
        [
          {
            code: 'SJ13',
            img: gameItemIconMap.CJ_PRAWN,
            displayName: '3/葫芦',
            className: 'mt-1',
          }, {
            code: 'SJ23',
            displayName: '3/葫芦',
            className: '',
          }, {
            code: 'SJ33',
            displayName: '3/葫芦',
            className: '',
          }
        ],
        [
          {
            code: 'SJ14',
            img: gameItemIconMap.CJ_COIN,
            displayName: '4/金钱',
            className: 'mt-1',
          }, {
            code: 'SJ24',
            displayName: '4/金钱',
            className: '',
          }, {
            code: 'SJ34',
            displayName: '4/金钱',
            className: '',
          }
        ],
        [
          {
            code: 'SJ15',
            img: gameItemIconMap.CJ_CRAB,
            displayName: '5/螃蟹',
            className: 'mt-1',
          }, {
            code: 'SJ25',
            displayName: '5/螃蟹',
            className: '',
          }, {
            code: 'SJ35',
            displayName: '5/螃蟹',
            className: '',
          }
        ],
        [
          {
            code: 'SJ16',
            img: gameItemIconMap.CJ_CHICKEN,
            displayName: '6/鸡',
            className: 'mt-1',
          }, {
            code: 'SJ26',
            displayName: '6/鸡',
            className: '',
          }, {
            code: 'SJ36',
            displayName: '6/鸡',
            className: '',
          }
        ]
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '大小',
      tableTitleStyle: 'text-center py-2',
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '大小总注额',
        code: 'item_LM'
      }],
      list: [
        {
          code: 'DX48',
          displayName: '大',
          className: '',
        }, {
          code: 'DX49',
          displayName: '小',
          className: '',
        }
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '全骰',
      tableTitleStyle: 'text-center py-2',
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '全骰总注额',
        code: 'item_QS'
      }],
      list: [
        {
          code: 'QS50',
          displayName: '全骰',
          className: '',
        }
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '围骰',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['种类', '赔率', '金额', '输赢', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '围骰总注额',
        code: 'item_WS'
      }],
      list: [
        {
          code: 'WS7',
          displayName: '1',
          className: '',
        }, {
          code: 'WS8',
          displayName: '2',
          className: '',
        }, {
          code: 'WS9',
          displayName: '3',
          className: '',
        }, {
          code: 'WS10',
          displayName: '4',
          className: '',
        }, {
          code: 'WS11',
          displayName: '5',
          className: '',
        }, {
          code: 'WS12',
          displayName: '6',
          className: '',
        }
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '点数',
      tableTitleStyle: 'text-center py-2',
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '点数总注额',
        code: 'item_DS'
      }],
      list: [
        {
          code: 'DS34',
          displayName: '4点',
          className: '',
        }, {
          code: 'DS35',
          displayName: '5点',
          className: '',
        }, {
          code: 'DS36',
          displayName: '6点',
          className: '',
        }, {
          code: 'DS37',
          displayName: '7点',
          className: '',
        }, {
          code: 'DS38',
          displayName: '8点',
          className: '',
        }, {
          code: 'DS39',
          displayName: '9点',
          className: '',
        },
        {
          code: 'DS40',
          displayName: '10点',
          className: '',
        }, {
          code: 'DS41',
          displayName: '11点',
          className: '',
        }, {
          code: 'DS42',
          displayName: '12点',
          className: '',
        }, {
          code: 'DS43',
          displayName: '13点',
          className: '',
        }, {
          code: 'DS44',
          displayName: '14点',
          className: '',
        }, {
          code: 'DS45',
          displayName: '15点',
          className: '',
        }, {
          code: 'DS46',
          displayName: '16点',
          className: '',
        }, {
          code: 'DS47',
          displayName: '17点',
          className: '',
        }
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '长牌',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['种类', '赔率', '金额', '输赢', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '长牌总注额',
        code: 'item_CP'
      }],
      list: [
        {
          code: 'CP13',
          displayName: '12',
          className: '',
        }, {
          code: 'CP14',
          displayName: '13',
          className: '',
        }, {
          code: 'CP15',
          displayName: '14',
          className: '',
        }, {
          code: 'CP16',
          displayName: '15',
          className: '',
        }, {
          code: 'CP17',
          displayName: '16',
          className: '',
        }, {
          code: 'CP18',
          displayName: '23',
          className: '',
        },
        {
          code: 'CP19',
          displayName: '24',
          className: '',
        }, {
          code: 'CP20',
          displayName: '25',
          className: '',
        }, {
          code: 'CP21',
          displayName: '26',
          className: '',
        }, {
          code: 'CP22',
          displayName: '34',
          className: '',
        }, {
          code: 'CP23',
          displayName: '35',
          className: '',
        }, {
          code: 'CP24',
          displayName: '36',
          className: '',
        }, {
          code: 'CP25',
          displayName: '45',
          className: '',
        }, {
          code: 'CP26',
          displayName: '46',
          className: '',
        }, {
          code: 'CP27',
          displayName: '56',
          className: '',
        }
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '短牌',
      tableTitleStyle: 'text-center py-2',
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      summary: [{
        displayName: '短牌总注额',
        code: 'item_DP'
      }],
      list: [
        {
          code: 'DP28',
          displayName: '11',
          className: '',
        }, {
          code: 'DP29',
          displayName: '22',
          className: '',
        }, {
          code: 'DP30',
          displayName: '33',
          className: '',
        }, {
          code: 'DP31',
          displayName: '44',
          className: '',
        }, {
          code: 'DP32',
          displayName: '55',
          className: '',
        }, {
          code: 'DP33',
          displayName: '66',
          className: '',
        }
      ]
    },
  }
];

export default ZONGXIANG;