import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import QRCode from 'react-google-qrcode';
import { connect } from 'react-redux';

import { Select } from 'element-react';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  editNoticeData: state.noticeReducers.editNoticeData,
  userData: state.memberReducers.userData,
  editPayAccData: state.systemReducers.editPayAccData,
});

const mapDispatchToProps = dispatch => ({
  getEditPayAccount: (id, callback) => dispatch(systemActions.getEditPayAccount(id, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  savePayAccount: (obj, callback) => dispatch(systemActions.savePayAccount(obj, callback)),
});

const PaymentAccEditAdd = ({ callback = {}, isEdit, getEditPayAccount, data, editPayAccData, updateModalAlert, savePayAccount }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_bankList, setBankList] = useState([]);
  const [_code, setCode] = useState('');
  const [_memberList, setMemberList] = useState([]);
  const [_agentList, setAgentList] = useState([]);

  useEffect(() => {
    setCode(data?.code);
    getEditPayAccount({
      id: data?.id || ''
    });
  }, [data]);

  useEffect(() => {
    if (!isEdit) {
      setInfo([]);
      setMemberList([]);
      setAgentList([]);
      setBankList(editPayAccData?.bankList);
    } else if (editPayAccData && JSON.stringify(editPayAccData?.editInfo) !== JSON.stringify(_info)) {
      setInfo(editPayAccData?.editInfo);
      setBankList(editPayAccData?.bankList);
      setMemberList(editPayAccData?.editInfo?.memberLevels?.split(','));
      setAgentList(editPayAccData?.editInfo?.agentLevels?.split(','));
    }
  }, [editPayAccData]);


  const saveButton = () => {
    const valuesMember = Array
      .from(document.querySelectorAll('[name^="chkMemberList"]:checked'))
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);
    const valuesAgent = Array
      .from(document.querySelectorAll('[name^="chkAgentList"]:checked'))
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);
    if (_info?.isChange || valuesMember?.length > 0 || valuesAgent?.length > 0) {
      const updateObj = _info;
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      updateObj.memberLevels = valuesMember;
      updateObj.agentLevels = valuesAgent;
      updateObj.clients = _info?.platform;
      updateObj.code = _code;
      savePayAccount(updateObj, callback);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '无变动'
        }
      });
    }
  };

  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    newItems.isChange = true;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    if (!isEdit) {
      callback();
    } else {
      getEditPayAccount({
        id: data?.id
      });
    }
  };

  const status = [
    {
      label: '启用',
      value: '0',
    },
    {
      label: '禁用',
      value: '1',
    }
  ];

  const platforms = [
    {
      label: '所有平台',
      value: '-1',
    },
    {
      label: '后台',
      value: '0',
    }, {
      label: '会员PC',
      value: '1',
    },
    {
      label: '会员手机版',
      value: '2',
    }
  ];
  return (
    <div className="PaymentAccEditAddWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="m-0 my-1 p-0">
          <table className="p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="titleHeader" colSpan={2}>
                  {isEdit === true ? '编辑收款账户' : '新增收款账户'}
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">开户名</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.accountName || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'accountName')}
                    />
                    <Label className="mx-1">(如果是扫码则为扫码类型，如：支付宝扫码/微信扫码/QQ扫码/银联扫码等)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">银行卡号</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.number || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'number')}
                    />
                    <Label className="mx-1">(如果是扫码则为扫码帐户)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">开户地址</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.address || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'address')}
                    />
                    <Label className="mx-1">(如果是扫码则是：二维码链接地址)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4" />
                <td>
                  <Label>二维码识别参考地址：http://www.wwei.cn/</Label>
                  <br />
                  <QRCode data={`https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=${_info?.address || ''}`} />
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">所属银行/扫码类型</td>
                <td>
                  <Select
                    value={_code}
                    placeholder="请选择"
                    onChange={val => { onInfoValueChange(val, 'code'); setCode(val); }}
                  >
                    {
                            _bankList && _bankList.map(d => (
                              <Select.Option key={`setReportDecimalPoint_${d.code}`} label={d.name} value={d.code} />
                            ))
                      }
                  </Select>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">状态</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      status.map(d => (
                        <div key={`rdTypeGroup_${d.value}`}>
                          <Input
                            id={`rdType_${d.value}`}
                            type="radio"
                            name="rdType"
                            value={_info?.status}
                            checked={_info?.status === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'status')}
                          />
                          <Label
                            for={`rdType_${d.value}`}
                            className={_info?.status === Number(d.value) ? 'active mx-2 smallLabel' : 'mx-2 smallLabel'}
                          >
                            {d?.label}
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">会员分类</td>
                <td>
                  <div className="d-flex p-0">
                    {
                       Array.from('1234567').map(d => (
                         <p key={`chkMemberList_${d}`} className="d-inline border-0 m-1">
                           <Input
                             id={`chkMemberList_${d}`}
                             type="checkbox"
                             key={`${_info?.id}_member_${d}`}
                             name="chkMemberList"
                             value={d}
                             defaultChecked={_memberList?.length > 0 && _memberList?.filter(x => x === d).length > 0}
                             className="justify-content-center align-items-center"
                           />
                           <Label className="px-1">{d}</Label>
                           <Label>类</Label>
                         </p>
                       ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">代理分类</td>
                <td>
                  <div className="d-flex p-0">
                    {
                       Array.from('1234567').map(d => (
                         <p key={`chkAgentList_${d}`} className="d-inline border-0 m-1">
                           <Input
                             id={`chkAgentList_${d}`}
                             type="checkbox"
                             key={`${_info?.id}_agent_${d}`}
                             name="chkAgentList"
                             value={d}
                             defaultChecked={_agentList?.length > 0 && _agentList?.filter(x => x === d).length > 0}
                             className="justify-content-center align-items-center"
                           />
                           <Label className="px-1">{d}</Label>
                           <Label>类</Label>
                         </p>
                       ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">实扣手续费</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      placeholder="默认值:0.00"
                      type="number"
                      value={_info?.fee?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'fee')}
                    />
                    <Label className="mx-1">% (充值手续费,不收手续费则:0)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">每日笔数</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      placeholder="默认值：0"
                      type="number"
                      value={_info?.dailyLimit?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'dailyLimit')}
                    />
                    <Label className="mx-1">(每日充值笔数,留空则为默认值:0,不做限制)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">每日限额</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.dayMaxAmount?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'dayMaxAmount')}
                    />
                    <Label className="mx-1"> (每日充值金额总数,不做限制则为:0)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">单笔最大支付金额</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.maxAmount?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'maxAmount')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">单笔最小支付金额</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="number"
                      value={_info?.minAmount?.toString() || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'minAmount')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">平台显示</td>
                <td>
                  <div>
                    <Select
                      value={_info?.platform || ''}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'platform')}
                    >
                      {
                              platforms.map(d => (
                                <Select.Option key={`setPlatformsSelect_${d.value}`} label={d.label} value={Number(d.value)} />
                              ))
                        }
                    </Select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAccEditAdd);