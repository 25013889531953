import React, { useEffect, useState } from 'react';

import { Checkbox, Input, Radio, Select } from 'element-react';
import { InputNumber } from 'antd';
import { connect } from 'react-redux';

import { agentActions, appActions, companyActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  agentAddInfo: state.agentReducers.agentAddInfo,
  oddsDiffUpOrgsData: state.companyReducers.oddsDiffUpOrgsData,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
});

const mapDispatchToProps = dispatch => ({
  GetAddAgentInfo: (getDto, callback) => dispatch(agentActions.GetAddAgentInfo(getDto, callback)),
  SaveAgentInfo: (obj, callback) => dispatch(agentActions.SaveAgentInfo(obj, callback)),
  getOddsDiffUpOrgs: orgId => dispatch(companyActions.getOddsDiffUpOrgs(orgId)),
  isValidName: (userId, callback) => dispatch(agentActions.isValidName(userId, callback)),
  isExistLoginId: (userId, callback) => dispatch(agentActions.isExistLoginId(userId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const AgentAdd = ({
  updateModalAlert,
  data,
  oddsDiffUpOrgsData,
  isExistLoginId,
  isValidName,

  getOddsDiffUpOrgs,
  GetAddAgentInfo,
  SaveAgentInfo,
  callback,
  isUpdate = false
}) => {
  const [password, setpassword] = useState('');
  const [name, setname] = useState('');
  const [loginId, setLoginId] = useState('');
  const [accountStatus, setaccountStatus] = useState(1);
  const [levelValue, setlevelValue] = useState(1);
  const [vipLevel, setvipLevel] = useState(1);
  const [gameType, setgameType] = useState('ABCD');
  const [multiOdd, setmultiOdd] = useState(1);
  const [hostDomain, sethostDomain] = useState();

  const [depositStatus, setdepositStatus] = useState(0);
  const [withdrawStatus, setwithdrawStatus] = useState(0);
  const [depositMaxAmount, setdepositMaxAmount] = useState(0);
  const [depositMaxMin, setdepositMaxMin] = useState(0);
  const [withdrawMaxAmount, setwithdrawMaxAmount] = useState(0);
  const [withdrawMinAmount, setwithdrawMinAmount] = useState(0);
  const [lotteryAssigned, setlotteryAssigned] = useState(0);
  const [selectedMap, setSelectedMap] = useState(new Map());
  const [totalLottery, setTotalLottery] = useState(new Map());
  const [orgZcList, setorgZcList] = useState();
  const [thirdPartyGamesZc, setthirdPartyGamesZc] = useState();
  const [parentLotteryAssigned, setParentLotteryAssigned] = useState();

  const [isValidLoginIdMsg, setisValidLoginIdMsg] = useState();
  const [isValidNameMsg, setisValidNameMsg] = useState();
  const [copyOddsDiffOrgId, setcopyOddsDiffOrgId] = useState(-1);


  const [agentAddInfo, setAgentAddInfo] = useState({});

  const [OrgCtrlSetting, setOrgCtrlSetting] = useState(0);
  const [OrgOddsSetting, setOrgOddsSetting] = useState(0);


  const getResponseData = data => {
    setAgentAddInfo(data);
  };

  useEffect(() => {
    if (data) {
      const getDto = {
        ParentId: data.orgId,
        IsChild: 0
      };
      GetAddAgentInfo(getDto, getResponseData);
      if (data.levelValue === 1) {
        getOddsDiffUpOrgs(data.orgId);
      }
    }
  }, []);

  useEffect(() => {
    if (agentAddInfo) {
      if (agentAddInfo?.lotteryList) {
        const mapAll = new Map();
        const mapSelected = new Map();
        const ls = [];
        const allLs = [].concat(agentAddInfo.lotteryList);
        if (agentAddInfo.upOrg.lotteryAssigned !== 0) {
          for (let i = 0; i < ls.length; i++) {
            const temp = allLs.find(x => (x.code === ls[i]));
            if (temp) {
              if (mapSelected.has(temp.categoryCode)) {
                const tempArr = mapSelected.get(temp.categoryCode);
                mapSelected.set(temp.categoryCode, tempArr.concat(temp));
              } else {
                mapSelected.set(temp.categoryCode, [].concat(temp));
              }
            }
          }
        }

        allLs.forEach(item => {
          if (mapAll.has(item.categoryCode)) {
            const temp = mapAll.get(item.categoryCode);
            mapAll.set(item.categoryCode, temp.concat(item));
          } else {
            mapAll.set(item.categoryCode, [].concat(item));
          }
        });

        const tempArr = agentAddInfo?.orgZc.map(item => ({
          ...item,
          parent: item.upAvairableRateForDown,
          staticZc: item.avairableRateForDown,
          avairableRateForDown: item?.hasZcTemp ? item?.tempAvairableRateForDown : item.avairableRateForDown
        }
        ));

        const tempThirdPartyArr = agentAddInfo?.thirdPartyGamesZc.map(item => ({
          ...item,
          zc: 0,
          thirdPartyName: item.gameName,
          thirdPartyParent: agentAddInfo.upOrg.levelValue === 1 ? 100 : item.upZc
        }));

        setorgZcList(tempArr);
        setthirdPartyGamesZc(tempThirdPartyArr);
        setTotalLottery(mapAll);
        setSelectedMap(mapSelected);
      }
      if (agentAddInfo?.upOrg) {
        const tempUpOrg = agentAddInfo.upOrg;
        setParentLotteryAssigned(tempUpOrg.lotteryAssigned);
        if (tempUpOrg?.levelValue === 1) {
          setgameType('ABCD');
        } else {
          setgameType(tempUpOrg.gameType);
        }
      }
    }
  }, [agentAddInfo]);

  const toggleAllSelection = code => {
    const temp = new Map(JSON.parse(
      JSON.stringify(Array.from(selectedMap))
    ));
    if (temp.has(code)) {
      temp.delete(code);
    } else {
      const tempItem = totalLottery.get(code);
      temp.set(code, [].concat(tempItem));
    }
    setSelectedMap(temp);
  };

  const toggleSelection = item => {
    const temp = new Map(JSON.parse(
      JSON.stringify(Array.from(selectedMap))
    ));
    if (temp.has(item.categoryCode)) {
      if (temp.get(item.categoryCode).find(x => x.code === item.code)) {
        temp.set(item.categoryCode, temp.get(item.categoryCode).filter(x => x.code !== item.code));
      } else {
        temp.set(item.categoryCode, temp.get(item.categoryCode).concat(item));
      }
    } else {
      temp.set(item.categoryCode, [].concat(item));
    }
    setSelectedMap(temp);
  };

  const saveAgentInfo = (validType = {}) => {
    const changData = () => {
      updateModalAlert({
        visible: true,
        type: 'loading',
      });
      const lotteryType = [];
      const sharesList = [];
      const thirdPartySharesList = [];
      if (selectedMap.size > 0) {
        selectedMap.forEach(item => {
          item.forEach(sub => {
            lotteryType.push(sub.code);
          });
        });
      }
      if (orgZcList) {
        orgZcList.forEach(item => {
          const obj = {};
          obj.categoryCode = item.categoryCode;
          obj.avairableRateForDown = item.avairableRateForDown;
          sharesList.push(obj);
        });
      }
      if (thirdPartyGamesZc) {
        thirdPartyGamesZc.forEach(item => {
          const obj = {};
          obj.GameCode = item.gameCode;
          obj.Zc = item.zc;
          thirdPartySharesList.push(obj);
        });
      }
      const obj = {
        isUpdate: false,
        org: {
          loginId,
          name,
          gameType,
          password,
          parentId: agentAddInfo.upOrg.organizationId,
          levelValue: agentAddInfo.upOrg.levelValue + 1,
          accountStatus,
          lotteryAssigned,
          multiOdd,
          organizationId: agentAddInfo.upOrg.organizationId,
          replenishmentType: 1,
          rateSurplus: 1,
          ReplenishmentSurplus: 1,
          zcType: 1,
          moneyLimit: agentAddInfo.upOrg.moneyLimit,
          ...validType
        },
        lotteryType,
        sharesList,
        thirdPartySharesList,
        userInfo: {
          levelValue,
          vipLevel,
          bindHost: hostDomain,

          depositStatus,
          depositMaxAmount,
          depositMaxMin,
          withdrawStatus,
          withdrawMaxAmount,
          withdrawMinAmount,
          affCode: '' // pending test
        }
      };
      SaveAgentInfo(obj, callback);
    };

    updateModalAlert({
      type: 'confirmation',
      visible: true,
      data: {
        message: '请确认是否要修改/设置',
        onConfirmationClick: () => changData()
      }
    });
  };

  return (
    <div className="AgentAddWrapper">
      <div className="sectionWrapper">
        <div className="tableTitleStyle">账户资料</div>
        <div className="d-flex">
          <div className="tableSection labelCellBackground">
            <div className="rowStyle">
              <div>上级代理</div>
              <div>{agentAddInfo ? agentAddInfo.upOrg?.loginId : ''}</div>
            </div>
            <div className="rowStyle">
              <div>代理账号</div>
              <div>
                <Input disabled={isUpdate} value={loginId} onChange={text => setLoginId(text)} onBlur={() => isExistLoginId(loginId, setisValidLoginIdMsg)} />
                {isValidLoginIdMsg && <span className={`ms-2 ${isValidLoginIdMsg.state === 1 ? 'text-success' : 'text-danger'}`}>{isValidLoginIdMsg.msg}</span>}
              </div>
            </div>
            <div className="rowStyle">
              <div>代理名称</div>
              <div>
                <Input value={name} onChange={text => setname(text.trim())} onBlur={() => isValidName(name, setisValidNameMsg)} />
                {isValidNameMsg && <span className={`ms-2 ${isValidNameMsg.state === 1 ? 'text-success' : 'text-danger'}`}>{isValidNameMsg.msg}</span>}
              </div>
            </div>
            <div className="rowStyle">
              <div>登入密码</div>
              <div><Input value={password} onChange={text => setpassword(text.trim())} /></div>
            </div>
            {agentAddInfo && agentAddInfo?.curUserLevel === 1
              ? (
                <>
                  <div className="rowStyle">
                    <div>帐号状态</div>
                    <div>
                      <Radio.Group value={accountStatus} onChange={val => setaccountStatus(val)}>
                        <Radio value={1}>启用</Radio>
                        <Radio value={2}>暂停</Radio>
                        <Radio value={0}>停用</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="rowStyle">
                    <div>用户分类</div>
                    <div>
                      <Select value={levelValue} onChange={val => setlevelValue(val)}>
                        {
                          Array.from(Array(7), (item, index) => index + 1).map(el => <Select.Option key={el} label={`${el}类`} value={el} />)
                        }
                      </Select>
                    </div>
                  </div>
                  <div className="rowStyle">
                    <div>VIP 级别</div>
                    <div>
                      <Select value={vipLevel} onChange={val => setvipLevel(val)}>
                        {
                          Array.from(Array(3), (item, index) => index + 1).map(el => <Select.Option key={el.value} label={`VIP ${el}`} value={el} />)
                        }
                      </Select>
                    </div>
                  </div>
                  <div className="rowStyle">
                    <div>允许多赔率</div>
                    <div>
                      <Radio.Group value={multiOdd} onChange={val => setmultiOdd(val)}>
                        <Radio value={1}>允许</Radio>
                        <Radio value={0}>不允许</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="rowStyle">
                    <div>退水赔率差复制</div>
                    <div>
                      <Select value={copyOddsDiffOrgId} onChange={val => setcopyOddsDiffOrgId(val)}>
                        {
                      oddsDiffUpOrgsData && [{
                        name: '系统默认', organizationId: -1
                      }].concat(oddsDiffUpOrgsData).map(el => <Select.Option key={el.value} label={`从${el.name}复制`} value={el.organizationId} />)
                    }
                      </Select>
                    </div>
                  </div>
                  <div className="rowStyle">
                    <div>推广域名</div>
                    <div>
                      <Input value={hostDomain} onChange={text => sethostDomain(text.trim())} />
                      <span className="ms-3">(不用加 http://, www 例如:baidu.com)</span>
                    </div>
                  </div>
                </>
              ) : ''}

            <div className="rowStyle">
              <div>开放盘口</div>
              <div>
                {
                  agentAddInfo && agentAddInfo?.upOrg?.levelValue === 1
                    ? (
                      <Checkbox.Group value={gameType ? gameType.split('').map(item => item) : []} onChange={val => setgameType(val.toString())}>
                        {
                          ['A', 'B', 'C', 'D'].map(item => <Checkbox label={`${item}盘`} value={item} />)
                        }
                      </Checkbox.Group>
                    )
                    : (
                      <Checkbox.Group value={gameType ? gameType.split('').map(item => item) : []} onChange={val => setgameType(val.toString())}>
                        {
                          agentAddInfo && agentAddInfo?.upOrg?.gameType.length > 0
                            ? agentAddInfo.upOrg.gameType.split('').map(item => <Checkbox label={`${item}盘`} value={item} />)
                            : ''
                        }
                      </Checkbox.Group>
                    )
                }
              </div>
            </div>

            <div className="rowStyle">
              <div>MFA验证</div>
              <div>
                {
                agentAddInfo && agentAddInfo?.editOrg?.validType === 1 ? (
                  <div
                    className="operationBtn reset"
                    onClick={() => saveAgentInfo({
                      validType: 0
                    })}
                  >
                    重置
                  </div>
                ) : '未设置'
                }
              </div>
            </div>
            { agentAddInfo && agentAddInfo.upOrg?.levelValue + 1 === 2 && agentAddInfo.info?.earnOddsSett === 1
            && (
            <div className="rowStyle">
              <div>操盘设置</div>
              <div>
                <Radio.Group value={OrgCtrlSetting} onChange={val => setOrgCtrlSetting(val)}>
                  <Radio value={0}>禁用</Radio>
                  <Radio value={1}>启用</Radio>
                </Radio.Group>
              </div>
            </div>
            )}
            { agentAddInfo && agentAddInfo.upOrg?.orgOddsSetting === 1 && agentAddInfo.info?.earnOddsSett === 1 && (
            <div className="rowStyle">
              <div>赚赔设置</div>
              <div>
                <Radio.Group value={OrgOddsSetting} onChange={val => setOrgOddsSetting(val)}>
                  <Radio value={0}>禁用</Radio>
                  <Radio value={1}>启用</Radio>
                </Radio.Group>
              </div>
            </div>
            )}
          </div>
          {((agentAddInfo && agentAddInfo?.curUserLevel === 1)) && (
          <div className="tableSection border-start-0 labelCellBackground">
            <div className="rowStyle">
              <div>存款状态</div>
              <div>
                <Radio.Group value={depositStatus} onChange={val => setdepositStatus(val)}>
                  <Radio value={0}>允许</Radio>
                  <Radio value={1}>不允许</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="rowStyle">
              <div>单次最大存款金额</div>
              <div>
                <Input
                  value={depositMaxAmount}
                  onChange={text => {
                    if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                      setdepositMaxAmount(text === '' || parseInt(text, 10) < 0 ? 0 : parseInt(text, 10));
                    }
                  }}
                />
                <span className="ms-3">(0则不限制)</span>
              </div>
            </div>
            <div className="rowStyle">
              <div>单次最小存款金额</div>
              <div>
                <Input
                  value={depositMaxMin}
                  onChange={text => {
                    if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                      setdepositMaxMin(text === '' || parseInt(text, 10) < 0 ? 0 : parseInt(text, 10));
                    }
                  }}
                />
              </div>
            </div>
            <div className="rowStyle">
              <div>提款状态</div>
              <div>
                <Radio.Group value={withdrawStatus} onChange={val => setwithdrawStatus(val)}>
                  <Radio value={0}>允许</Radio>
                  <Radio value={1}>不允许</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="rowStyle">
              <div>单次最大提款金额</div>
              <div>
                <Input
                  value={withdrawMaxAmount}
                  onChange={text => {
                    if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                      setwithdrawMaxAmount(text === '' || parseInt(text, 10) < 0 ? 0 : parseInt(text, 10));
                    }
                  }}
                />
                <span className="ms-3">(0则不限制)</span>
              </div>
            </div>
            <div className={`rowStyle ${!isUpdate && 'border-bottom'}`}>
              <div>单次最小提款金额</div>
              <div>
                <Input
                  value={withdrawMinAmount}
                  onChange={text => {
                    if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                      setwithdrawMinAmount(text === '' || parseInt(text, 10) < 0 ? 0 : parseInt(text, 10));
                    }
                  }}
                />
              </div>
            </div>
          </div>
          )}

        </div>
      </div>
      {(parentLotteryAssigned !== 0)
      && (
      <div className="sectionWrapper">
        <div className="tableTitleStyle">彩种选择</div>
        <div className="tableSection labelCellBackground">
          <div className="rowStyle">
            <div>开放彩种管理给下线</div>
            <div>
              <Radio.Group value={lotteryAssigned} onChange={val => setlotteryAssigned(val)}>
                <Radio value={1}>允许</Radio>
                <Radio value={0}>不允许</Radio>
              </Radio.Group>
            </div>
          </div>
          {Array.from(totalLottery.values()).map(item => (
            <div className="rowStyle">
              <div>
                <span className="me-3">{item[0].categoryName}</span>
                <Checkbox onChange={() => toggleAllSelection(item[0].categoryCode)} checked={(selectedMap.size > 0 && selectedMap.has(item[0].categoryCode)) ? (selectedMap.get(item[0].categoryCode).length === totalLottery.get(item[0].categoryCode).length) : false}>所有</Checkbox>
              </div>
              <div>
                {
                  item.map(ele => <Checkbox checked={selectedMap.size > 0 ? selectedMap.get(ele.categoryCode)?.find(x => x.code === ele.code) : false} onChange={() => toggleSelection(ele)} className="lotterySelection my-1">{ele.lotteryName}</Checkbox>)
                }
              </div>
            </div>
          ))}
        </div>
      </div>
      )}
      <div className="sectionWrapper">
        <div className="tableTitleStyle">占成设置</div>
        <div className="tableSection">
          <div className="rowStyle bg-grey">
            <div>类型</div>
            <div>占成</div>
          </div>
          {
            (totalLottery.size > 0 && orgZcList) && orgZcList.filter(x => x.hide === false).map((item, index) => (
              <div className="rowStyle">
                <div>
                  {item.hasBet ? '[预设] ' : ''}
                  {item.categoryName}
                </div>
                <div>
                  {
                    item?.hasZcTemp && (item?.staticZc !== item?.tempAvairableRateForDown)
                      ? (
                        <span className="mx-3">
                          {item?.staticZc}
                        </span>
                      )
                      : ''
                  }
                  <InputNumber
                    min={0}
                    max={item.upAvairableRateForDown}
                    value={item.avairableRateForDown}
                    onChange={text => {
                      if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                        const temp = [].concat(orgZcList);
                        temp[index].avairableRateForDown = parseInt(text, 10);
                        setorgZcList(temp);
                      }
                    }}
                  />
                  <span className="ms-3">
                    {`(最大${item.parent}%)`}
                  </span>
                </div>
              </div>
            ))
          }
        </div>
        {
          agentAddInfo && agentAddInfo?.haveBillList?.length > 0
            ? (
              <div className="text-danger py-2 text-center">
                当前用户已下注，【预设】占成将在明天开盘前生效。
              </div>
            )
            : ''
        }
      </div>
      {agentAddInfo && agentAddInfo.info?.outsiteEnable === 1
        ? (
          <div className="sectionWrapper">
            <div className="tableTitleStyle">第三方游戏占成设置</div>
            <div className="tableSection">
              <div className="rowStyle bg-grey">
                <div>类型</div>
                <div>占成</div>
              </div>
              {
            thirdPartyGamesZc && thirdPartyGamesZc.filter(x => x.hide === false).map((item, index) => (
              <div className="rowStyle">
                <div>{item.thirdPartyName}</div>
                <div>
                  <InputNumber
                    value={item.zc}
                    min={0}
                    max={item.thirdPartyParent}
                    onChange={text => {
                      if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                        const temp = [].concat(thirdPartyGamesZc);
                        temp[index].zc = parseInt(text, 10);
                        setthirdPartyGamesZc(temp);
                      }
                    }}
                  />
                  <span className="ms-3">
                    {`(最大${item.thirdPartyParent}%)`}
                  </span>
                </div>
              </div>
            ))
          }
            </div>
          </div>
        ) : ''}
      <div className="d-flex justify-content-center py-3">
        <div className="operationBtn" onClick={() => saveAgentInfo()}>保存</div>
        <div className="operationBtn ms-3 cancel" onClick={() => callback()}>取消</div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentAdd);