import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, cashActions } from '../../../redux/actions';
import { AgentDataTable } from '../..';
import './styles.scss';


const mapStateToProps = state => ({
  banks: state.cashReducers.banks,
  memberInfo: state.cashReducers.memberInfo,
  userInfo: state.memberReducers.userInfo,
});

const mapDispatchToProps = dispatch => ({
  GetBankCards: (obj, callback) => dispatch(cashActions.GetBankCards(obj, callback)),
  GetCashMemberInfo: () => dispatch(cashActions.GetCashMemberInfo()),
  DelBankCard: (id, obj) => dispatch(cashActions.DelBankCard(id, obj)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  SaveUserBankCard: (obj, callback) => dispatch(cashActions.SaveUserBankCard(obj, callback)),
});

const BankCardSetting = ({ GetBankCards, userInfo, banks, DelBankCard, updateModalAlert, SaveUserBankCard, GetCashMemberInfo, memberInfo }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);

  useEffect(() => {
    setIsQuerying(true);
    const _obj = {
      memberId: userInfo.orgId,
      page: 1,
      rows: 15
    };
    GetBankCards(_obj, setIsQuerying);
    GetCashMemberInfo();
  }, []);


  const delBank = id => {
    DelBankCard(id, userInfo);
  };

  const clickToOpen = data => {
    if (data) {
      updateModalAlert({
        type: 'bankCard',
        visible: true,
        data
      });
    } else {
      updateModalAlert({
        type: 'bankCard',
        visible: true,
        data:
        {
          type: 'new',
          bankUName: memberInfo.familyNameCut,
          onConfirmationClick: _data => SaveUserBankCard(_data, () => GetBankCards({
            memberId: userInfo.orgId,
            page: 1,
            rows: 15
          }, setIsQuerying)),
        }
      });
    }
  };

  return (
    <div className="BankCardSettingWrapper">
      <div className="addNewGameBox my-3">
        <div className="operationBtn add" onClick={() => clickToOpen()}>
          <div className="plus_icon"><i className="el-icon-plus" /></div>
          添加银行卡
        </div>
      </div>

      <div className="mx-0 bg-white mb-3 px-0 border-1">
        <AgentDataTable
          isQuerying={_isQuerying}
          tableHeaderTitle={
            [{
              label: '所属银行',
            }, {
              label: '帐号号码',
            }, {
              label: '帐号开户名',
            }, {
              label: '帐号开户地址',
            }, {
              label: '状态',
            }, {
              label: t('OPERATION'),
            }]
          }
          tableData={banks ? banks.data : []}
          tableBodyRowProperty={
            [
              data => (<div className="d-flex justify-content-center">{data.bankName}</div>),
              data => (<div className="d-flex justify-content-center">{data.bankNumber}</div>),
              data => (<div className="d-flex justify-content-center">{data.bankUName}</div>),
              data => (<div className="d-flex justify-content-center">{data?.bankAddress}</div>),
              data => (<div className="d-flex justify-content-center">{data.isDel !== 0 ? '已删除' : '正常'}</div>),
              data => (
                <div className="d-flex justify-content-center align-items-center">
                  <div className="operationBtn" onClick={() => { clickToOpen(data); }}>详细</div>
                  <div className="operationBtn cancel ms-3" onClick={() => { delBank(data.id); }}>删除</div>
                </div>
              )
            ]
          }
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BankCardSetting);