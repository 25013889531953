import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import Icon from '../../Icon';
import Skeleton from '../../Skeleton';
import './styles.scss';


const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  editBankSettingData: state.systemReducers.editBankSettingData,
});

const mapDispatchToProps = dispatch => ({
  getEditBankSetting: (id, callback) => dispatch(systemActions.getEditBankSetting(id, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveBankSetting: (obj, callback) => dispatch(systemActions.saveBankSetting(obj, callback)),
});

const BankSettingAddEdit = ({ callback = {}, isEdit, getEditBankSetting, data, editBankSettingData, updateModalAlert, saveBankSetting }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);
  const [_companyList, setCompanyList] = useState([]);
  const [_existingMenu, setExistingMenu] = useState([]);
  const [_isQuery, setIsQuery] = useState(true);
  const [_isNewCall, setIsNewCall] = useState(false);

  useEffect(() => {
    getEditBankSetting({
      id: data?.id || ''
    });
    setIsNewCall(true);
  }, [data]);

  useEffect(() => {
    if (editBankSettingData && JSON.stringify(editBankSettingData?.companyList) !== JSON.stringify(_companyList)) {
      setCompanyList(editBankSettingData?.companyList);
    }
    if (!isEdit) {
      setInfo([]);
      setIsQuery(false);
    } else if (_isNewCall && editBankSettingData?.bankInfo && JSON.stringify(editBankSettingData?.bankInfo) !== JSON.stringify(_info)) {
      setInfo(editBankSettingData?.bankInfo);
      setExistingMenu(editBankSettingData?.bankInfo?.compList);
      setIsQuery(false);
    }
  }, [editBankSettingData]);


  const saveButton = () => {
    const values = Array
      .from(document.querySelectorAll('[name^="mainchk_"]:checked'))
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);
    onInfoValueChange(values.toString(), 'compList');
    if (_info?.isChange || values?.length !== _companyList?.length) {
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      saveBankSetting(_info, callback);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '无变动'
        }
      });
    }
  };

  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    newItems.isChange = true;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    if (!isEdit) {
      callback();
    } else {
      getEditBankSetting({
        id: data?.id
      });
    }
  };

  const status = [
    {
      label: '启用',
      value: '0',
    },
    {
      label: '禁用',
      value: '1',
    }
  ];

  return (
    <div className="BankSettingAddEditWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="m-0 my-1 p-0">
          <table className="p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="titleHeader" colSpan={2}>
                  {isEdit === true ? '编辑提款绑定银行' : '新增提款绑定银行'}
                </td>
              </tr>
              {
                      _isQuery ? (
                        <>
                          <tr>
                            <td className="tableHeader col-4">{t('TYPE')}</td>
                            <td><Skeleton isAnimated /></td>
                          </tr>
                          <tr>
                            <td className="tableHeader col-4">{t('TYPE_NAME')}</td>
                            <td><Skeleton isAnimated /></td>
                          </tr>
                          <tr>
                            <td className="tableHeader col-4">{t('CURRENCY_CODE')}</td>
                            <td><Skeleton isAnimated /></td>
                          </tr>
                          <tr>
                            <td className="tableHeader col-4">{t('BELONGS_BANK')}</td>
                            <td><Skeleton isAnimated /></td>
                          </tr>
                          <tr>
                            <td className="tableHeader col-4">{t('BANK_CODE')}</td>
                            <td><Skeleton isAnimated /></td>
                          </tr>
                          <tr>
                            <td className="tableHeader col-4">{t('BANK_CARD_PREFIX')}</td>
                            <td><Skeleton isAnimated /></td>
                          </tr>
                          <tr>
                            <td className="tableHeader col-4">{t('DEPOSIT_RATE')}</td>
                            <td><Skeleton isAnimated /></td>
                          </tr>
                          <tr>
                            <td className="tableHeader col-4">{t('WITHDRAW_RATE')}</td>
                            <td><Skeleton isAnimated /></td>
                          </tr>
                          <tr>
                            <td className="tableHeader col-4">{t('AUTO_RENEW_EX_RATE')}</td>
                            <td><Skeleton isAnimated /></td>
                          </tr>
                          <tr>
                            <td className="tableHeader col-4">{t('STATUS')}</td>
                            <td><Skeleton isAnimated /></td>
                          </tr>
                          <tr>
                            <td className="tableHeader col-4">{t('ORDER_BY')}</td>
                            <td><Skeleton isAnimated /></td>
                          </tr>
                        </>
                      )
                        : (
                          <>
                            <tr>
                              <td className="tableHeader col-4">{t('TYPE')}</td>
                              <td>
                                <div>
                                  <Input
                                    className="d-inline col-1"
                                    type="text"
                                    value={_info?.type || ''}
                                    onChange={({ target: { value: text } }) => onInfoValueChange(text, 'type')}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="tableHeader col-4">{t('TYPE_NAME')}</td>
                              <td>
                                <div>
                                  <Input
                                    className="d-inline col-1"
                                    type="text"
                                    value={_info?.typeName || ''}
                                    onChange={({ target: { value: text } }) => onInfoValueChange(text, 'typeName')}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="tableHeader col-4">{t('CURRENCY_CODE')}</td>
                              <td>
                                <div>
                                  <Input
                                    className="d-inline col-1"
                                    type="text"
                                    value={_info?.currency || ''}
                                    onChange={({ target: { value: text } }) => onInfoValueChange(text, 'currency')}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="tableHeader col-4">{t('BELONGS_BANK')}</td>
                              <td>
                                <div>
                                  <Input
                                    className="d-inline col-1"
                                    type="text"
                                    value={_info?.name || ''}
                                    onChange={({ target: { value: text } }) => onInfoValueChange(text, 'name')}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="tableHeader col-4">{t('BANK_CODE')}</td>
                              <td>
                                <div>
                                  <Input
                                    className="d-inline col-1"
                                    type="text"
                                    value={_info?.code || ''}
                                    onChange={({ target: { value: text } }) => onInfoValueChange(text, 'code')}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="tableHeader col-4">{t('BANK_CARD_PREFIX')}</td>
                              <td>
                                <div className="textAreaInput">
                                  <Input
                                    className="d-inline"
                                    type="textarea"
                                    value={_info?.number || ''}
                                    onChange={({ target: { value: text } }) => onInfoValueChange(text, 'number')}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="tableHeader col-4">{t('DEPOSIT_RATE')}</td>
                              <td>
                                <div>
                                  <Input
                                    className="d-inline col-1"
                                    type="number"
                                    value={_info?.deposit?.toString() || ''}
                                    onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'deposit')}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="tableHeader col-4">{t('WITHDRAW_RATE')}</td>
                              <td>
                                <div>
                                  <Input
                                    className="d-inline col-1"
                                    type="number"
                                    value={_info?.withdraw?.toString() || ''}
                                    onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'withdraw')}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="tableHeader col-4">{t('AUTO_RENEW_EX_RATE')}</td>
                              <td>
                                <div className="d-flex my-1 rdSearchOptionsGroup">
                                  {
                      status.map(d => (
                        <div key={`rdAutoTypeGroup_${d.value}`}>
                          <Input
                            id={`rdAutoType_${d.value}`}
                            type="radio"
                            name="rdAutoType"
                            value={_info?.autoExchange}
                            checked={_info?.autoExchange === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'autoExchange')}
                          />
                          <Label
                            for={`rdAutoType_${d.value}`}
                            className={_info?.autoExchange === Number(d.value) ? 'active mx-2 smallLabel' : 'mx-2 smallLabel'}
                          >
                            {d?.label}
                          </Label>
                        </div>
                      ))
                    }
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="tableHeader col-4">{t('STATUS')}</td>
                              <td>
                                <div className="d-flex my-1 rdSearchOptionsGroup">
                                  {
                      status.map(d => (
                        <div key={`rdTypeGroup_${d.value}`}>
                          <Input
                            id={`rdType_${d.value}`}
                            type="radio"
                            name="rdType"
                            value={_info?.status}
                            checked={_info?.status === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'status')}
                          />
                          <Label
                            for={`rdType_${d.value}`}
                            className={_info?.status === Number(d.value) ? 'active mx-2 smallLabel' : 'mx-2 smallLabel'}
                          >
                            {d?.label}
                          </Label>
                        </div>
                      ))
                    }
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="tableHeader col-4">{t('ORDER_BY')}</td>
                              <td>
                                <div>
                                  <Input
                                    className="d-inline col-1"
                                    type="number"
                                    value={_info?.orderId?.toString() || ''}
                                    onChange={({ target: { value: text } }) => onInfoValueChange(text === '' ? '' : Number(text), 'orderId')}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="tableHeader col-4">{t('ACTIVE_COMPANY')}</td>
                              <td>
                                <div>
                                  <Button
                                    id="main_selectAll"
                                    className="button add justify-content-center marginLeft5 mb-2"
                                    onClick={() => {
                                      const aInputs = document.querySelectorAll("input[type='checkbox']");
                                      for (let i = 0, n = aInputs?.length || 0; i < n; i++) {
                                        aInputs[i].checked = true;
                                      }
                                    }}
                                  >
                                    <Icon
                                      libName="fa"
                                      value={{
                                        name: 'FaCheckCircle',
                                        className: 'mb-1 mr-1'
                                      }}
                                    />
                                    <Label className="p-0 px-1">{t('SELECT_ALL')}</Label>
                                  </Button>
                                  <Button
                                    id="main_deselectAll"
                                    className="button handle justify-content-center marginLeft5 mx-2 mb-2"
                                    onClick={() => {
                                      const aInputs = document.querySelectorAll("input[type='checkbox']");
                                      for (let i = 0, n = aInputs?.length || 0; i < n; i++) {
                                        aInputs[i].checked = false;
                                      }
                                    }}
                                  >
                                    <Label className="p-0 px-1">{t('DESELECT_ALL')}</Label>
                                  </Button>
                                  <br />
                                  <div className="inline-flex">
                                    {
                      _companyList.map(d => (
                        <div className="chkFix">
                          <p key={`returnSelection_${d?.id}`} className="inline-block border-0 m-1">
                            <Input
                              key={`chkConsolidate_${d?.id}_${data?.id}`}
                              id={`chkConsolidate_${d.id}`}
                              type="checkbox"
                              name={`mainchk_${data.id}`}
                              value={d.id}
                              defaultChecked={_existingMenu?.filter(x => x === d.id).length > 0}
                              className="m-1 p-1 justify-content-center align-items-center"
                            />
                            <Label className="px-1 fixWidth">{d.name}</Label>
                          </p>
                        </div>
                      ))
                    }
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        )
}
            </tbody>
          </table>
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BankSettingAddEdit);