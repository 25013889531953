import React, { useEffect, useState } from 'react';

import { DateRangePicker } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable } from '../..';
import { reportActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  userInfo: state.memberReducers.userInfo,
  agentDownlineReport: state.reportReducers.agentDownlineReport,
  memberDownlineReport: state.reportReducers.memberDownlineReport,
});

const mapDispatchToProps = dispatch => ({
  getAgentDownlineReport: (obj, callback) => dispatch(reportActions.getAgentDownlineReport(obj, callback)),
  getMemberDownlineReport: (obj, callback) => dispatch(reportActions.getMemberDownlineReport(obj, callback)),
});

const DownlineReport = ({ getAgentDownlineReport, getMemberDownlineReport, agentDownlineReport, memberDownlineReport, userInfo }) => {
  const { t } = useTranslation();
  const [_date, setDate] = useState([moment().startOf('day').toDate(), moment().toDate()]);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [_dateFlag, setDateFlag] = useState('1');
  const [_result, setResult] = useState();
  const [tabIndex, setTabIndex] = useState(1);

  let dateRef;

  useEffect(() => {
    if (agentDownlineReport || memberDownlineReport) {
      setResult(tabIndex === 1 ? {
        ...agentDownlineReport,
        reportList: agentDownlineReport ? agentDownlineReport.reportList.map((item, index) => ({
          ...item, no: index + 1
        })) : []
      } : {
        ...memberDownlineReport,
        reportList: memberDownlineReport ? memberDownlineReport.reportList.map((item, index) => ({
          ...item, no: index + 1
        })) : []
      });
    } else {
      setResult();
    }
  }, [agentDownlineReport, memberDownlineReport, tabIndex]);

  useEffect(() => {
    setIsQueryingDetail(true);
    const obj = {
      beginDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD'), loginName: userInfo ? userInfo.loginId : ''
    };
    getAgentDownlineReport(obj, () => setIsQueryingDetail(false));
    getMemberDownlineReport(obj, () => setIsQueryingDetail(false));
  }, []);

  const onSearchClick = () => {
    setIsQueryingDetail(true);
    const obj = {
      beginDate: moment(_date[0]).format('YYYY-MM-DD'), endDate: moment(_date[1]).format('YYYY-MM-DD'), loginName: userInfo ? userInfo.loginId : ''
    };
    getAgentDownlineReport(obj, () => setIsQueryingDetail(false));
    getMemberDownlineReport(obj, () => setIsQueryingDetail(false));
  };

  const dateSelector = id => {
    setDateFlag(id);
    switch (id) {
      case '1':
        setDate([moment().toDate(), moment().toDate()]);
        break;
      case '2':
        setDate([moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]);
        break;
      case '3':
        setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
        break;
      case '4':
        setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
        break;
      case '5':
        setDate([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
        break;
      case '6':
        setDate([moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()]);
        break;
      default:
        break;
    }
  };

  const cancel = () => {
    setDate([moment().toDate(), moment().toDate()]);
    setDateFlag('');
    const obj = {
      beginDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD'), loginName: userInfo ? userInfo.loginId : ''
    };
    getAgentDownlineReport(obj, () => setIsQueryingDetail(false));
    getMemberDownlineReport(obj, () => setIsQueryingDetail(false));
  };

  return (
    <div className="downlineReportWrapper">
      <div className="downlineReporTableWrapper d-flex p-3">
        <div className="userTable w-100">
          <div className="d-flex">
            <div>日期范围</div>
            <div>
              <DateRangePicker
                className="betQueryDatepicker"
                value={_date}
                isShowTime
                placeholder={t('START_END_DATE')}
                rangeSeparator={` ${t('TO')} `}
                onChange={_date => {
                  setDate(_date);
                }}
                ref={e => dateRef = e}
                shortcuts={[{
                  text: t('TODAY'),
                  onClick: () => {
                    setDate([moment().toDate(), moment().toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('THIS_WEEK'),
                  onClick: () => {
                    setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_2_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }]}
              />
              <div onClick={e => dateSelector(e.target.id)} className="d-flex mx-2">
                {
              [{
                label: '今天', value: 1
              }, {
                label: '昨天', value: 2
              }, {
                label: '本星期', value: 3
              }, {
                label: '上星期', value: 4
              }, {
                label: '本月', value: 5
              }, {
                label: '上月', value: 6
              }].map(item => <span id={item.value} className={`mx-1 operationBtn dateSelectBtn ${+_dateFlag === item.value && 'selected'}`}>{item.label}</span>)

                 }
              </div>
              <div className="operationBtn findAndCollapse mx-1" onClick={onSearchClick}>查询</div>
              <div className="operationBtn cancel mx-1" onClick={() => cancel()}>取消</div>
            </div>
          </div>
        </div>
      </div>
      <div className="downlineReporTableWrapper p-3">
        <div className="menuWrapper mb-2 ms-2">
          <div className={`menuItem p-2  ${tabIndex === 1 && 'selected'}`} onClick={() => setTabIndex(1)}>下线代理</div>
          <div className={`menuItem p-2  ${tabIndex === 2 && 'selected'}`} onClick={() => setTabIndex(2)}>下线会员</div>
        </div>
        <div className="mx-0 bg-white">
          <div className="headerTitle d-flex justify-content-center py-2 w-100">{tabIndex === 1 ? '下线代理明细' : '下线会员明细'}</div>
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: `${tabIndex === 1 ? '代理' : '会员'}`,
            }, {
              label: '存款',
            }, {
              label: '取款',
            }, {
              label: '红利',
            }, {
              label: '反水',
            }, {
              label: '手续费(存)',
            }, {
              label: '手续费(提)',
            }, {
              label: '人工加额',
            }, {
              label: '人工减额',
            }, {
              label: '有效金额',
            }, {
              label: '会员盈亏',
            }, {
              label: '占成',
            }, {
              label: '总盈亏',
            }]
          }
            tableData={_result ? _result.reportList : []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.no}</Label>),
              data => (<Label className="d-flex justify-content-center numberBlue">{data.loginName}</Label>),
              data => <Label className="d-flex justify-content-center numberBlue">{data.deposit}</Label>,
              data => (<Label className="d-flex justify-content-center numberBlue">{data.withdraw}</Label>),
              data => (<Label className="d-flex justify-content-center  numberBlue">{data.promos}</Label>),
              data => (<Label className="d-flex justify-content-center numberBlue">{data.rebate}</Label>),
              data => (<Label className="d-flex justify-content-center numberBlue">{data.depositFee}</Label>),
              data => (<Label className="d-flex justify-content-center numberBlue">{data.withdrawFee}</Label>),
              data => (<Label className="d-flex justify-content-center numberBlue">{data.manualTopUp}</Label>),
              data => (<Label className="d-flex justify-content-center numberBlue">{data.manualDeduct}</Label>),
              data => (<Label className={`d-flex justify-content-center ${parseFloat(data.validAmount) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.validAmount}</Label>),
              data => (<Label className={`d-flex justify-content-center ${parseFloat(data.winLost) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.winLost}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.zc}</Label>),
              data => (<Label className={`d-flex justify-content-center ${parseFloat(data.totalWinLost) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.totalWinLost}</Label>),
            ]
          }
            tableFooter={[
              [{
                value: '所有总和',
                colspan: 2,
                className: 'textRight pe-2 total tableSum'
              },
              {
                value: (_result && _result.total?.deposit) || 0,
                colspan: 1,
                className: 'tableSum numberBlue'
              },
              {
                value: (_result && _result.total?.withdraw) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (_result && _result.total?.promos) || 0,
                colspan: 1,
                className: 'tableSum  numberBlue'
              },
              {
                value: (_result && _result.total?.rebate) || 0,
                colspan: 1,
                className: 'tableSum numberBlue'
              },
              {
                value: (_result && _result.total?.depositFee) || 0,
                colspan: 1,
                className: 'tableSum numberBlue'
              },
              {
                value: (_result && _result.total?.withdrawFee) || 0,
                colspan: 1,
                className: 'tableSum numberBlue'
              },
              {
                value: (_result && _result.total?.manualTopUp) || 0,
                colspan: 1,
                className: 'tableSum numberBlue'
              },
              {
                value: (_result && _result.total?.manualDeduct) || 0,
                colspan: 1,
                className: 'tableSum numberBlue'
              },
              {
                value: (_result && _result.total?.validAmount) || 0,
                colspan: 1,
                className: `tableSum ${parseFloat(_result?.total?.validAmount || 0) < 0 ? 'numberRed' : 'numberBlue'}`
              },
              {
                value: (_result && _result.total?.winLost) || 0,
                colspan: 1,
                className: `tableSum ${parseFloat(_result?.total?.winLost || 0) < 0 ? 'numberRed' : 'numberBlue'}`
              },
              {
                value: '',
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (_result && _result.total?.totalWinLost) || 0,
                colspan: 1,
                className: `tableSum ${parseFloat(_result?.total?.totalWinLost || 0) < 0 ? 'numberRed' : 'numberBlue'}`
              }]
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DownlineReport);