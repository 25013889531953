import React, { useEffect, useState } from 'react';

import { DateRangePicker, Input, Select } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Icon, Pagination } from '../..';
import { messageActions } from '../../../redux/actions';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  message: state.messageReducers.message,
});

const mapDispatchToProps = dispatch => ({
  GetMessage: (obj, callback) => dispatch(messageActions.GetMessage(obj, callback)),
  NewMessage: (obj, callback) => dispatch(messageActions.NewMessage(obj, callback)),
  GetMessageDetail: (id, callback) => dispatch(messageActions.GetMessageDetail(id, callback)),
  DelMessage: (id, obj) => dispatch(messageActions.DelMessage(id, obj)),
});

const MessageCenter = ({ GetMessage, message, DelMessage, NewMessage, GetMessageDetail }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [toUsers, setToUsers] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [receiverType, setReceiverType] = useState(1);

  const [_date, setDate] = useState([moment().subtract(1, 'weeks').startOf('day').toDate(), moment().endOf('day').toDate()]);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [fromUName, setfromUName] = useState('');
  const [toUName, settoUName] = useState('');
  const [msgStatus, setmsgStatus] = useState(-1);
  const [msgType, setMsgType] = useState(-1);

  let dateRef;

  useEffect(() => {
    if (_pageFlag === 0) {
      setIsQuerying(true);
      const _obj = {
        page,
        rows,
        fromUName,
        toUName,
        msgType,
        msgStatus,
        startTime: moment(_date[0]).unix(),
        endTime: moment(_date[1]).unix()
      };
      GetMessage(_obj, setIsQuerying);
    } else {
      setToUsers('');
      setTitle('');
      setContent('');
      setReceiverType(1);
      setMsgType(100);
    }
  }, [_pageFlag]);

  const saveMessage = () => {
    const _obj = {
      toUsers,
      title,
      content,
      receiverType,
      msgType
    };
    NewMessage(_obj, () => setPageFlag(0));
  };

  const deleteMessage = id => {
    const _obj = {
      page: 1,
      rows: 15,
      fromUName,
      toUName,
      msgType,
      msgStatus,
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix()
    };
    DelMessage(id, _obj);
  };

  const onSearchClick = () => {
    setIsQuerying(true);
    const _obj = {
      page: 1,
      rows: 15,
      fromUName,
      toUName,
      msgType,
      msgStatus,
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix()
    };
    GetMessage(_obj, setIsQuerying);
    setPage(1);
    setRows(15);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(true);
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      page: pageNum,
      rows,
      startTime: moment(_date[0]).unix(),
      endTime: moment(_date[1]).unix()
    };
    GetMessage(_obj, setIsQuerying);
  };

  return (
    <div className="MessageCenterWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
              <Icon
                libName="md"
                value={{
                  name: 'MdArrowBack',
                  className: 'h5 mb-0 text-white'
                }}
              />
              返回
            </div>
            )}
            {`${t('MESSAGE_CENTER')} ${_pageFlag === 0 ? '' : '/ 新增消息'}`}
          </div>
)}
      />
      {_pageFlag === 0 && (
      <>
        <div className="addNewGameBox my-3">
          <div className="operationBtn add" onClick={() => setPageFlag(1)}>
            <div className="plus_icon"><i className="el-icon-plus" /></div>
            新增消息
          </div>
          <div className="mx-2">
            发送用户
            <Input className="ms-2" value={fromUName} onChange={text => setfromUName(text.trim())} placeholder="请输入内容" />
          </div>
          <div className="mx-2">
            接收用户
            <Input className="ms-2" value={toUName} onChange={text => settoUName(text.trim())} placeholder="请输入内容" />
          </div>
          <div className="mx-2">
            <Label type="daterange" value={t('DATE')} className="px-2">{t('DATE')}</Label>
            <DateRangePicker
              className="betQueryDatepicker"
              value={_date}
              isShowTime
              placeholder={t('START_END_DATE')}
              rangeSeparator={` ${t('TO')} `}
              onChange={date => {
                const _d = moment(date[1]).endOf('day').toDate();
                setDate([date[0], _d]);
              }}
              ref={e => dateRef = e}
              shortcuts={[{
                text: t('TODAY'),
                onClick: () => {
                  setDate([moment().toDate(), moment().toDate()]);
                  dateRef.togglePickerVisible();
                }
              }, {
                text: t('THIS_WEEK'),
                onClick: () => {
                  setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
                  dateRef.togglePickerVisible();
                }
              }, {
                text: t('PREVIOUS_WEEK'),
                onClick: () => {
                  setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                  dateRef.togglePickerVisible();
                }
              }, {
                text: t('PREVIOUS_2_WEEK'),
                onClick: () => {
                  setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                  dateRef.togglePickerVisible();
                }
              }]}
            />
          </div>
          <div className="mx-2">
            类型
            <Select className="ms-2" value={msgType} placeholder="请选择" onChange={code => setMsgType(code)}>
              {
                [{
                  value: -1, label: '全部消息'
                }, {
                  value: 100, label: '财务问题'
                }, {
                  value: 200, label: '技术问题'
                }, {
                  value: 300, label: '业务咨询'
                }, {
                  value: 400, label: '意见建议'
                }, {
                  value: 998, label: '系统消息'
                }, {
                  value: 999, label: '其他问题'
                }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
              }
            </Select>
          </div>
          <div className="mx-2">
            状态
            <Select className="ms-2" value={msgStatus} placeholder="请选择" onChange={code => setmsgStatus(code)}>
              {
                     [{
                       value: -1, label: '全部'
                     }, {
                       value: 0, label: '未读'
                     }, {
                       value: 1, label: '已读'
                     }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                }
            </Select>
          </div>
          <div className="operationBtn mx-2 add" onClick={() => onSearchClick()}>查询</div>
        </div>

        <div className="mx-0 bg-white mb-3 px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: '标题',
            }, {
              label: '类型',
            }, {
              label: '操作时间',
            }, {
              label: '接收用户',
            }, {
              label: '发送用户',
            }, {
              label: '状态',
            }, {
              label: t('OPERATION'),
            }]
          }
            tableData={message ? message.data : []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center text-primary detailLink" onClick={() => GetMessageDetail(data.id)}>{data.msgTitle}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.msgTypeName}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.createtime).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (
                <div className="d-flex  justify-content-center">
                  <Label className="d-flex justify-content-center">
                    {data.toUserName}
                  </Label>
                  <Label className="d-flex justify-content-center ms-1">
                    {`[${data.toUserType === 1 ? '会员' : '代理'}]`}
                  </Label>
                </div>
              ),
              data => (<Label className="d-flex justify-content-center">{data.fromUserName}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.isRead === 0 ? '未读' : '已读'}</Label>),
              data => <Label className="d-flex justify-content-center operationBtn cancel w-50 m-auto" onClick={() => { deleteMessage(data.id); }}>删除</Label>
            ]
          }
          />
        </div>
        <div className="d-flex align-items-center justify-content-center w-full relative">
          <Pagination totalRecord={message ? (message.sum.totalRecord || 0) : 0} onClick={onPaginationClick} isQuery={false} />
        </div>
      </>
      )}
      {_pageFlag === 1 && (
        <>
          <div className="my-3 thirdPartyGamesTable">
            <div className="thirdPartyGamesTitle">新增消息</div>
            <div className="thirdPartyGamesRow">
              <div>消息类型: </div>
              <div>
                <Select value={msgType} placeholder="请选择" onChange={code => setMsgType(code)}>
                  {
                [{
                  value: 100, label: '财务问题'
                }, {
                  value: 200, label: '技术问题'
                }, {
                  value: 300, label: '业务咨询'
                }, {
                  value: 400, label: '意见建议'
                }, {
                  value: 998, label: '系统消息'
                }, {
                  value: 999, label: '其他问题'
                }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
              }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>
                接收用户类型:
              </div>
              <div>
                <Select
                  value={receiverType}
                  placeholder="请选择"
                  onChange={code => {
                    setReceiverType(code);
                  }}
                >
                  {
                [{
                  value: 1, label: '会员'
                }, {
                  value: 2, label: '代理'
                }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
              }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>接收用户: </div>
              <div>
                <Input
                  type="textarea"
                  autosize={{
                    minRows: 2, maxRows: 4
                  }}
                  value={toUsers}
                  onChange={text => {
                    setToUsers(text);
                  }}
                  placeholder="多用户请用逗号隔开"
                />

              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>消息主题: </div>
              <div>
                <Input
                  className="messageTitleBox"
                  value={title}
                  onChange={text => {
                    setTitle(text);
                  }}
                  placeholder="请输入内容"
                />

              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>消息内容: </div>
              <div>
                <Input
                  type="textarea"
                  autosize={{
                    minRows: 4, maxRows: 8
                  }}
                  value={content}
                  onChange={text => {
                    setContent(text);
                  }}
                  placeholder="消息主题，最多100个字符"
                />
              </div>
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => saveMessage()}>确定</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageCenter);