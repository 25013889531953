import React, { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { Checkbox, DateRangePicker, Input } from 'element-react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Pagination } from '../..';
import { appActions, cashActions } from '../../../redux/actions';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  agentRebate: state.cashReducers.agentRebate,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  GetAgentCommission: (obj, callback) => dispatch(cashActions.GetAgentCommission(obj, callback)),
});

const HandleAgentRebate = ({ updateModalAlert, agentRebate, GetAgentCommission }) => {
  const { t } = useTranslation();
  const [_date, setDate] = useState([moment().subtract(1, 'weeks').startOf('day').toDate(), moment().endOf('day').toDate()]);

  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [signinId, setsigninId] = useState('');
  const [commAmount, setcommAmount] = useState('');
  let dateRef;
  const [_list, setList] = useState();
  const [_checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    if (_checkAll && agentRebate) {
      const _ls = agentRebate.list.map(item => item.ids);
      setList([..._ls]);
    } else {
      setList([]);
    }
  }, [_checkAll, agentRebate]);

  useEffect(() => {
    const obj = {
      startDate: moment(_date[0]).unix(),
      endDate: moment(_date[1]).unix(),
      commAmount,
      signinId,
      page,
      rows
    };
    GetAgentCommission(obj, setIsQueryingDetail);
  }, []);

  const onSearchClick = () => {
    setIsQueryingDetail(true);
    const obj = {
      startDate: moment(_date[0]).unix(),
      endDate: moment(_date[1]).unix(),
      commAmount,
      signinId,
      page,
      rows
    };
    GetAgentCommission(obj, setIsQueryingDetail);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    const obj = {
      beginTime: moment(_date[0]).format('YYYY-MM-DD  HH:mm:ss'),
      endTime: moment(_date[1]).format('YYYY-MM-DD  HH:mm:ss')
    };
    GetAgentCommission(obj, setIsQueryingDetail);
    setPage(pageNum);
    setRows(rows);
  };

  const handleRebate = id => {
    updateModalAlert({
      type: 'handleAgentRebate',
      visible: true,
      data: {
        type: 'single',
        dataSet: [...agentRebate.list],
        target: id,
        onConfirmationClick: () => {
          setCheckAll(false);
          setIsQueryingDetail(true);
          const obj = {
            startDate: moment(_date[0]).unix(),
            endDate: moment(_date[1]).unix(),
            commAmount,
            signinId,
            page,
            rows
          };
          GetAgentCommission(obj, setIsQueryingDetail);
        },
      }
    });
  };

  const handleBatchAgent = () => {
    updateModalAlert({
      type: 'handleAgentRebate',
      visible: true,
      data: {
        type: 'multi',
        dataSet: agentRebate.list,
        target: _list,
        onConfirmationClick: () => {
          setCheckAll(false);
          setIsQueryingDetail(true);
          const obj = {
            startDate: moment(_date[0]).unix(),
            endDate: moment(_date[1]).unix(),
            commAmount,
            signinId,
            page,
            rows
          };
          GetAgentCommission(obj, setIsQueryingDetail);
        },
      }
    });
  };

  return (
    <Col className="HandleAgentRebateWrapper">
      <CommonHeader headerName={t('HANDLE_AGENT_RETURN')} />
      <Col className="p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
        <span className="mx-2">账号</span>
        <Input className="mx-1" value={signinId} onChange={text => setsigninId(text)} />
        <Label type="daterange" value={t('DATE')} className="px-1">{t('DATE')}</Label>
        <DateRangePicker
          className="betQueryDatepicker mx-1"
          value={_date}
          isShowTime
          placeholder={t('START_END_DATE')}
          rangeSeparator={` ${t('TO')} `}
          onChange={_date => {
            setDate(_date);
          }}
          ref={e => dateRef = e}
          shortcuts={[{
            text: t('TODAY'),
            onClick: () => {
              setDate([moment().toDate(), moment().toDate()]);
              dateRef.togglePickerVisible();
            }
          }, {
            text: t('THIS_WEEK'),
            onClick: () => {
              setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
              dateRef.togglePickerVisible();
            }
          }, {
            text: t('PREVIOUS_WEEK'),
            onClick: () => {
              setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
              dateRef.togglePickerVisible();
            }
          }, {
            text: t('PREVIOUS_2_WEEK'),
            onClick: () => {
              setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
              dateRef.togglePickerVisible();
            }
          }]}
        />
        <span className="mx-2">赚水大于</span>
        <Input className="mx-1" value={commAmount} onChange={text => setcommAmount(text)} />
        <Button className="searchButton find mx-1" onClick={onSearchClick}>{t('FIND')}</Button>
        <Button className="searchButton handle mx-1" onClick={() => handleBatchAgent()}>操作所选</Button>
      </Col>
      <Row className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={
            [{
              label: (
                (<Checkbox onChange={text => setCheckAll(text)} checked={_checkAll}>全选</Checkbox>)
              ),
            }, {
              label: '序号',
            }, {
              label: '所属账号',
            }, {
              label: '用户类型 ',
            }, {
              label: '账号',
            }, {
              label: '余额',
            }, {
              label: '有效余额',
            }, {
              label: '赚水',
            }, {
              label: '未处理赚水(系统)',
            }, {
              label: '已处理赚水(系统)',
            }, {
              label: '反水',
            }]
          }
          tableData={agentRebate ? agentRebate.list : []}
          tableBodyRowProperty={
            [
              data => (
                <Label className="d-flex justify-content-center">
                  <Checkbox
                    onChange={() => {
                      if (_list.includes(data.ids)) {
                        setList(_list.filter(item => item !== data.ids));
                      } else {
                        setList([..._list, data.ids]);
                      }
                    }}
                    checked={_list.includes(data.ids)}
                  />
                </Label>
              ),
              data => (<Label className="d-flex justify-content-center">{data.no}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.pLoginId}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.userType}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.loginId}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.balance}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.betEffectAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.commAmountAll}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.commAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.commAmountII}</Label>),
              data => (
                <Label className="d-flex justify-content-center">
                  <div className="searchButton find" onClick={() => handleRebate(data.ids)}>操作</div>
                </Label>
              ),
            ]
          }
          tableFooter={[[{
            value: `${t('CURRENT_PAGE_TOTAL')}`,
            colspan: 5,
            className: ' total tableSum'
          },
          {
            value: (agentRebate && agentRebate.sum.balance) || 0,
            colspan: 1,
            className: 'tableSum text-center'
          },
          {
            value: (agentRebate && agentRebate.sum.betEffectAmount) || 0,
            colspan: 1,
            className: 'text-center tableSum'
          },
          {
            value: (agentRebate && agentRebate.sum.commAmountAll) || 0,
            colspan: 1,
            className: 'text-center tableSum'
          },
          {
            value: (agentRebate && agentRebate.sum.commAmount) || 0,
            colspan: 1,
            className: 'text-center tableSum'
          },
          {
            value: (agentRebate && agentRebate.sum.commAmountII) || 0,
            colspan: 1,
            className: 'text-center tableSum'
          },
          {
            colspan: 1,
            className: 'tableSum'
          }]]}
        />
      </Row>
      <Row className="justify-center w-full relative">
        <Pagination totalRecord={(agentRebate && agentRebate?.sum.totalRecord) || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HandleAgentRebate);