import { StatusCodes } from 'http-status-codes';

import { SET_CHANGE_HISTORY_LOG, SET_HANDLER_LOG, SET_OPERATION_LOG } from '../actionTypes';
import { apiService } from '../../../library';


const setOperationLog = autoRestockList => ({
  type: SET_OPERATION_LOG,
  value: autoRestockList
});

const setHandlerLog = data => ({
  type: SET_HANDLER_LOG,
  value: data
});

const setChangeHistoryLog = data => ({
  type: SET_CHANGE_HISTORY_LOG,
  value: data
});

const getOperationLogByType = (type, rows, page, from = '', to = '') => dispatch => {
  apiService.get('operationLog/getOperationLogByType',
    `opType=${type}&page=${page}&rows=${rows}&from=${from}&to=${to}`)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setOperationLog(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};


const GetHandlerLog = (obj, callback = () => {}) => dispatch => {
  apiService.get('operationLog/GetHandlerLog',
    new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(setHandlerLog(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const GetChangeHisLog = (obj, callback = () => {}) => dispatch => {
  apiService.get('operationLog/GetChangeHisLog',
    new URLSearchParams(obj))
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          callback(false);
          return dispatch(setChangeHistoryLog(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

export default {
  getOperationLogByType,
  GetHandlerLog,
  GetChangeHisLog,
};