/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';

import { connect } from 'react-redux';

import ChangePassword from '../../components/IndividualManagement/ChangePassword';
import { CommonHeader } from '../../components';
import HandlerLog from '../../components/UserManage/HandlerLog';
import HistoryTask from '../../components/IndividualManagement/HistoryTask';
import LoginLog from '../../components/UserManage/LoginLog';
import MFASetting from '../../components/IndividualManagement/MFA';
import UserProfile from '../../components/IndividualManagement/UserProfile';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
  userInfo: state.memberReducers.userInfo,
});

const IndividualManagement = ({ selectedSubMenu, userInfo }) => (

  <div className="individualManagementWrapper p-3">
    {selectedSubMenu === 'IM_USER_PROFILE' && (
    <>
      <CommonHeader
        headerName="用户资料"
      />
      <UserProfile data={userInfo} />
    </>
    )}
    {selectedSubMenu === 'IM_RECORD' && (
    <>
      <CommonHeader
        headerName="操作日记"
      />
      <HandlerLog data={userInfo} />
    </>
    )}
    {selectedSubMenu === 'IM_LOGIN_LOG' && (
    <>
      <CommonHeader
        headerName="登录日志"
      />
      <LoginLog data={userInfo} />
    </>)}
    {selectedSubMenu === 'IM_CHANGE_PASSWORD' && (
    <>
      <CommonHeader
        headerName="变更密码"
      />
      <ChangePassword />
    </>)}
    {selectedSubMenu === 'IM_HISTORY_TASK' && (
    <>
      <CommonHeader
        headerName="查看详情"
      />
      <HistoryTask />
    </>)}
    {selectedSubMenu === 'IM_MFA_SETTING' && (
    <>
      <CommonHeader
        headerName="MFA验证设置"
      />
      <MFASetting />
    </>) }
  </div>
);

export default connect(mapStateToProps, null)(IndividualManagement);