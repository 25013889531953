import React from 'react';

import { Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable } from '../..';
import Icon from '../../Icon';
import { oddsSettingActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  batchOddsList: state.oddsSettingReducers.batchOddsList,
});

const mapDispatchToProps = dispatch => ({
  updateBatchOddsList: obj => dispatch(oddsSettingActions.updateBatchOddsList(obj)),
});

const InTimeSheetTableRender = ({
  sortMode,
  onAmountClick = () => {},
  onBuhuoClick = () => {},
  inTimeSheetOdds,
  batchOddsList,
  updateBatchOddsList,
  _MenuOddsListObj,
  menu,
  _selectedMainMenu = 0,
  _isQueryingDetail,
  _info,
  _selectedSubMenu,
  _isAvailable,
  changeOdds = () => {}
}) => {
  const { t } = useTranslation();

  const tableheader = menu[_selectedMainMenu]?.type === '110'
    ? [{
      label: t('NUMBER'),
    }, {
      label: t('ODDS'),
    }, {
      label: '总项盈亏',
    }, {
      label: t('RESTOCK'),
    }, {
      label: '特码',
    }, {
      label: '单双',
    }, {
      label: '大小',
    }, {
      label: '合大小',
    }, {
      label: '尾大尾小',
    }, {
      label: '两面',
    }, {
      label: '合单合双',
    }, {
      label: '波色',
    }, {
      label: '生肖',
    }, {
      label: '六肖',
    }, {
      label: '五行',
    }, {
      label: '半波',
    }, {
      label: '头尾数',
    }]
    : [{
      label: t('NUMBER'),
    }, {
      label: t('ODDS'),
    }, {
      label: t('AMOUNT'),
    }, {
      label: t('PROFIT'),
    }, {
      label: t('RESTOCK'),
    }].slice(0, menu[_selectedMainMenu]?.isLianMa ? 4 : 5);

  const tablecontent = menu[_selectedMainMenu]?.type === '110'
    ? [
      data => (data?.rowSpan !== 'nil' ? (data?.img ? (
        <>
          <img alt={data?.displayName} src={data?.img} />
          <br />
          <Label className={data?.className}>{data.displayName}</Label>
        </>
      ) : <Label className={data?.className}>{data.displayName}</Label>) : undefined),
      data => (data?.displayName !== '' && (
      <div className={`d-flex nopadding align-items-center justify-content-center h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'}  `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
        <div className={`iconFontSize urlNoUnderline p-0 m-0 active ${!_isAvailable && 'invisible'}`} onClick={e => { e.stopPropagation(); if (data?.enabled === 1) { changeOdds('add', data); } }}>
          <Icon
            libName="fa"
            value={{
              name: 'FaRegPlusSquare',
            }}
          />
        </div>
        <Label className="mx-1 smallFixWidth negativeValue justify-content-center">
          {data?.odds ?? '--'}
        </Label>
        <div className={`iconFontSize urlNoUnderline p-0 m-0 active ${!_isAvailable && 'invisible'}`} onClick={e => { e.stopPropagation(); if (data?.enabled === 1) { changeOdds('reduce', data); } }}>
          <Icon
            libName="fa"
            className="mx-1"
            value={{
              name: 'FaRegMinusSquare',
            }}
          />
        </div>
      </div>
      )),
      data => (data?.displayName !== '' && (
      <div className={`d-flex align-items-center justify-content-center h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
        <span className="inTimeAmount" onClick={e => { if (menu[_selectedMainMenu]?.isLianMa !== 1) { onAmountClick(data); } e.stopPropagation(); }}>
          {data?.totalAmt || 0}
        </span>
      </div>
      )),
      data => (data?.displayName !== '' && (
      <Label className={`d-flex  installment justify-content-center h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <span className="buhuoDone" onClick={e => { onBuhuoClick(data); e.stopPropagation(); }}>{data?.enabled === 1 ? data.buhuoAmount : 0}</span>
          <span>{data?.enabled === 1 ? data?.buhuoedAmount : 0 }</span>
        </div>
      </Label>
      )),
      data => (data?.displayName !== '' && (
        <div className={`d-flex  align-items-center justify-content-center py-2 h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
          {data?.specNumTMAmt || 0}
        </div>
      )),
      data => (data?.displayName !== '' && (
        <div className={`d-flex  align-items-center justify-content-center py-2 h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
          {data?.singleDoubleDSAmt || 0}
        </div>
      )),
      data => (data?.displayName !== '' && (
        <div className={`d-flex  align-items-center justify-content-center py-2 h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
          {data?.bigSmallDXAmt || 0}
        </div>
      )),
      data => (data?.displayName !== '' && (
        <div className={`d-flex  align-items-center justify-content-center py-2 h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
          {data?.comBigSmallHDXAmt || 0}
        </div>
      )),
      data => (data?.displayName !== '' && (
        <div className={`d-flex  align-items-center justify-content-center py-2 h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
          {data?.lastBigSmallWSWXAmt || 0}
        </div>
      )),
      data => (data?.displayName !== '' && (
        <div className={`d-flex  align-items-center justify-content-center py-2 h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
          {data?.twoSideLMAmt || 0}
        </div>
      )),
      data => (data?.displayName !== '' && (
        <div className={`d-flex  align-items-center justify-content-center py-2 h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
          {data?.comSingleDoubleHDHSAmt || 0}
        </div>
      )),
      data => (data?.displayName !== '' && (
        <div className={`d-flex  align-items-center justify-content-center py-2 h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
          {data?.waveColorBSAmt || 0}
        </div>
      )),
      data => (data?.displayName !== '' && (
        <div className={`d-flex  align-items-center justify-content-center py-2 h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
          {data?.zodiacSXAmt || 0}
        </div>
      )),
      data => (data?.displayName !== '' && (
        <div className={`d-flex  align-items-center justify-content-center py-2 h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
          {data?.sixZodiacLXAmt || 0}
        </div>
      )),
      data => (data?.displayName !== '' && (
        <div className={`d-flex  align-items-center justify-content-center py-2 h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
          {data?.fiveRowWHAmt || 0}
        </div>
      )),
      data => (data?.displayName !== '' && (
        <div className={`d-flex  align-items-center justify-content-center py-2 h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
          {data?.halfWaveBBAmt || 0}
        </div>
      )),
      data => (data?.displayName !== '' && (
        <div className={`d-flex  align-items-center justify-content-center py-2 h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
          {data?.firstLastTWSAmt || 0}
        </div>
      )),
    ]
    : [
      data => (data?.rowSpan !== 'nil' ? (data?.img ? (
        <>
          <img alt={data?.displayName} src={data?.img} />
          <br />
          <Label className={data?.className}>{data.displayName}</Label>
        </>
      ) : <Label className={data?.className}>{data.displayName}</Label>) : undefined),
      data => (data?.displayName !== '' && (
      <div className={`d-flex nopadding align-items-center justify-content-center h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'}  `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
        <div className={`iconFontSize urlNoUnderline p-0 m-0 active ${!_isAvailable && 'invisible'}`} onClick={e => { e.stopPropagation(); if (data?.enabled === 1) { changeOdds('add', data); } }}>
          <Icon
            libName="fa"
            value={{
              name: 'FaRegPlusSquare',
            }}
          />
        </div>
        <Label className="mx-1 smallFixWidth negativeValue justify-content-center">
          {data?.odds ?? '--'}
        </Label>
        <div className={`iconFontSize urlNoUnderline p-0 m-0 active ${!_isAvailable && 'invisible'}`} onClick={e => { e.stopPropagation(); if (data?.enabled === 1) { changeOdds('reduce', data); } }}>
          <Icon
            libName="fa"
            className="mx-1"
            value={{
              name: 'FaRegMinusSquare',
            }}
          />
        </div>
      </div>
      )),
      data => (data?.displayName !== '' && (
      <div className={`d-flex align-items-center justify-content-center h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
        <span className="inTimeAmount" onClick={e => { if (menu[_selectedMainMenu]?.isLianMa !== 1) { onAmountClick(data); } e.stopPropagation(); }}>
          {data.enabled === 1 ? data?.totalAmt : 0}
        </span>
      </div>
      )),
      data => (data?.displayName !== '' && (
      <div className={`d-flex  align-items-center justify-content-center py-2 h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
        {data.enabled === 1 ? data?.winLost : 0}
      </div>
      )),
      data => (data?.displayName !== '' && (
      <Label className={`d-flex  installment justify-content-center h-100 ${(batchOddsList && batchOddsList[data.keyCode]) && 'rowselected'} `} onClick={e => { e.stopPropagation(); onClickSelection(data); }}>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <span className="buhuoDone" onClick={e => { onBuhuoClick(data); e.stopPropagation(); }}>{data?.enabled === 1 ? data.buhuoAmount : 0}</span>
          <span>{data?.enabled === 1 ? data?.buhuoedAmount : 0 }</span>
        </div>
      </Label>
      )),
    ].slice(0, menu[_selectedMainMenu]?.isLianMa ? 4 : 5);


  const onClickSelection = data => {
    if (inTimeSheetOdds && data && data?.enabled === 1 && _isAvailable) {
      const _batchOddsList = {
        ...batchOddsList
      };
      if (_batchOddsList[data.keyCode]) {
        delete _batchOddsList[data.keyCode];
      } else {
        _batchOddsList[data.keyCode] = data;
      }
      updateBatchOddsList(Object.keys(_batchOddsList).length === 0 ? undefined : _batchOddsList);
    }
  };

  const sortFunctionForIntimeSheet = type => {
    if (type === 1 || type === 2) {
      if (menu[_selectedMainMenu]?.displayName === '特码总项2') {
        if (sortMode === '1') { // 按盈亏排序
          _info.sort((a, b) => {
            if (a?.settingCode === b?.settingCode) {
              return a.totalAmt - b.totalAmt;
            }
            return true;
          });
        }
        if (sortMode === '2') { // 按金额排序
          _info.sort((a, b) => {
            if (a?.settingCode === b?.settingCode) {
              return b.specNumTMAmt - a.specNumTMAmt;
            }
            return true;
          });
        }
      } else {
        if (sortMode === '1') { // 按盈亏排序
          _info.sort((a, b) => {
            if (a?.settingCode === b?.settingCode) {
              return a.winLost - b.winLost;
            }
            return true;
          });
        }
        if (sortMode === '2') { // 按金额排序
          _info.sort((a, b) => {
            if (a?.settingCode === b?.settingCode) {
              return b.totalAmt - a.totalAmt;
            }
            return true;
          });
        }
      }
    }
    if (type === 3 || type === 4 || type === 5) {
      if (sortMode === '1') { // 按盈亏排序
        _info.sort((a, b) => {
          if (a?.mark === b?.mark) {
            return a.winLost - b.winLost;
          }
          return true;
        });
      }
      if (sortMode === '2') { // 按金额排序
        _info.sort((a, b) => {
          if (a?.mark === b?.mark) {
            return b.totalAmt - a.totalAmt;
          }
          return true;
        });
      }
    }
  };

  const countCurrIndex = (i, index) => i + (index * (menu[_selectedMainMenu]?.repeatPerRow || 0));
  const getstart = d => d * menu[_selectedMainMenu]?.displayRows;
  const getend = d => (d * menu[_selectedMainMenu]?.displayRows) + menu[_selectedMainMenu]?.displayRows;
  const getRepStart = (d, currInd) => d * menu[_selectedMainMenu]?.displayRows[currInd];
  const getRepEnd = (d, currInd) => (d * menu[_selectedMainMenu]?.displayRows[currInd]) + menu[_selectedMainMenu]?.displayRows[currInd];
  const getComb2RepTime = currInd => Math.ceil(menu[_selectedMainMenu]?.display[currInd]?.length / menu[_selectedMainMenu]?.displayRows[currInd]);
  const getRepeatTime = d => {
    if (!menu[_selectedMainMenu]?.repeatPerRow) {
      return menu[_selectedMainMenu]?.display?.length;
    }
    return ((d + 1) * menu[_selectedMainMenu]?.repeatPerRow > menu[_selectedMainMenu]?.display?.length)
      ? menu[_selectedMainMenu]?.display?.length % menu[_selectedMainMenu]?.repeatPerRow
      : menu[_selectedMainMenu]?.repeatPerRow;
  };
  const drawContent = (info, i, combineStyle, isExceed) => {
    let tableFooter = [];
    if (menu[_selectedMainMenu]?.summary && menu[_selectedMainMenu]?.summary[Number(i)] !== '' && menu[_selectedMainMenu]?.summary[Number(i)] !== undefined) {
      tableFooter = menu[_selectedMainMenu].summary[Number(i)].map((x, index) => ([{
        value: (
          <div key={`summaryItem_${index.toString()}`} className=" py-2 text-center d-flex align-items-center justify-content-center">
            {`${x.displayName} :`}
            {(inTimeSheetOdds && _MenuOddsListObj)
        && <span className="text-danger px-3">{`${x.code === 'maxLoss' || x.code === 'maxProfit' ? inTimeSheetOdds[x.code] ?? '--' : _MenuOddsListObj[x.code]?.amt ?? '--'}`}</span>}
          </div>
        ),
        colspan: 15,
        className: 'text-start total'
      }]));
    }
    return (
      <div>
        {info?.length > 0 && i?.toString() && menu[_selectedMainMenu]?.firstTableHeader
        && (
          <div className={`oddHeader ${combineStyle && i >= menu[_selectedMainMenu]?.repeatPerRow
            ? 'concatUpDown' : ''}`}
          >
            {menu[_selectedMainMenu]?.firstTableHeader[Number(i)] || ''}
          </div>
        )}
        {!isExceed && (
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={(combineStyle && i >= menu[_selectedMainMenu]?.repeatPerRow ? [] : tableheader) || []}
          tableData={_isQueryingDetail ? Array(15).fill(1) : (info || [])}
          tableBodyRowProperty={tablecontent}
          tableBodyRowStyle={(combineStyle ? ['backgroundColored rowSpan nopadding col-2', 'nopadding col-2', 'nopadding col-2', 'col-2 nopadding', 'col-2 nopadding'] : ['backgroundColored nopadding', 'nopadding', 'nopadding', 'nopadding', 'nopadding'])}
          tableHeaderStyle={[]}
          rowSpanCol={[0]}
          tableFooter={tableFooter}
        />
        )}
      </div>
    );
  };

  const drawContent2 = (info, i, combineStyle, isExceed) => (
    <>
      {info?.length > 0 && i?.toString() && menu[_selectedMainMenu]?.firstTableHeader
      && (
        <div className={`oddHeader ${combineStyle && i >= menu[_selectedMainMenu]?.repeatPerRow
          ? 'concatUpDown' : ''}`}
        >
          {menu[_selectedMainMenu]?.firstTableHeader[Number(i)] || ''}
        </div>
      )}
      {
        info?.length > 0 && i?.toString() && menu[_selectedMainMenu]?.children && menu[_selectedMainMenu]?.children[_selectedSubMenu]?.needHeader
        && (
          <div className="oddHeader">
            {menu[_selectedMainMenu]?.children[_selectedSubMenu]?.label || ''}
          </div>
        )
      }
      <Col className="d-flex">
        {!isExceed && (
        <>
          {
            [...Array(getComb2RepTime(i) || 1)].map((r, index) => (
              <AgentDataTable
                className={`col-${(12 / getComb2RepTime(i)).toString()}`}
                isQuerying={_isQueryingDetail}
                tableHeaderTitle={(combineStyle && i >= menu[_selectedMainMenu]?.repeatPerRow ? [] : tableheader) || []}
                tableData={_isQueryingDetail ? Array(15).fill(1) : (info?.slice(getRepStart(index, i), getRepEnd(index, i)) || [])}
                tableBodyRowProperty={tablecontent}
                tableBodyRowStyle={index === 0 ? ['backgroundColored nopadding col-2', 'nopadding col-2', 'nopadding col-2', 'col-2 p-0', 'col-2 p-0'] : ['backgroundColored nopadding col-2 leftNoBorder', 'nopadding col-2 leftNoBorder', 'nopadding leftNoBorder col-2', 'col-2 leftNoBorder p-0', 'col-2 leftNoBorder p-0']}
                tableHeaderStyle={index === 0 ? [] : ['leftNoBorder']}
              />
            ))
          }
        </>
        )}
      </Col>
    </>
  );

  const renderTable = () => {
    // normal data arrangement
    if (menu[_selectedMainMenu]?.isStaticRow !== 1 && menu[_selectedMainMenu]?.horizontalDataAlign !== 1) {
      sortFunctionForIntimeSheet(1);
      return (
        <Row>
          {
            [...Array(Math.ceil(Number(menu[_selectedMainMenu]?.display?.length / menu[_selectedMainMenu]?.displayRows)) || 0)].map((d, index) => (
              <Col className="content p-0 m-0" key={`displayRow_${index.toString()}}`}>
                {drawContent(_info?.slice(getstart(index), getend(index)))}
              </Col>
            ))
          }
        </Row>
      );
    }
    if (menu[_selectedMainMenu]?.horizontalDataAlign === 1) {
      sortFunctionForIntimeSheet(2);
      return (
        <Row>
          {
            [...Array(menu[_selectedMainMenu]?.horizontalSet)].map((d, i) => (
              <Col className="content p-0 m-0" key={`horizontalDataAlign_${i.toString()}}`}>
                {drawContent(_info.filter((item, index) => (index % menu[_selectedMainMenu]?.horizontalSet === i)))}
              </Col>
            ))
          }
        </Row>
      );
    }
    // arrange by col
    if (menu[_selectedMainMenu]?.isStaticRow === 1 && menu[_selectedMainMenu]?.combineStyle === 1) {
      sortFunctionForIntimeSheet(3);
      return (
        <Row key="staticRow2">
          {
            [...Array(menu[_selectedMainMenu]?.repeatPerRow || menu[_selectedMainMenu]?.display?.length)].map((d, i) => (
              <Col className="content p-0 m-0" key={`datadata_${i.toString()}}`}>
                {
                  [...Array(menu[_selectedMainMenu]?.repeatPerCol || 1)].map((d, index) => (
                    drawContent(_info.filter(item => item?.mark === (countCurrIndex(i, index)).toString()), countCurrIndex(i, index),
                      1,
                      countCurrIndex(i, index) >= menu[_selectedMainMenu]?.display?.length
                      || menu[_selectedMainMenu]?.display[countCurrIndex(i, index)]?.length === 0)
                  ))
                }
              </Col>
            ))
          }
        </Row>
      );
    }
    // nested split table style
    if (menu[_selectedMainMenu]?.isStaticRow === 1 && menu[_selectedMainMenu]?.combineStyle === 2) {
      sortFunctionForIntimeSheet(4);
      return (
        <Row key="staticRow2">
          {
            [...Array(menu[_selectedMainMenu]?.repeatPerRow || menu[_selectedMainMenu]?.display?.length)].map((d, i) => (
              <Col className={`content p-0 m-0 ${menu[_selectedMainMenu]?.tableStyle ? menu[_selectedMainMenu]?.tableStyle[i] : ''}`} key={`datadata_${i.toString()}}`}>
                {
                  [...Array(menu[_selectedMainMenu]?.repeatPerCol || 1)].map((d, index) => (
                    drawContent2(_info.filter(item => item?.mark === (countCurrIndex(i, index)).toString()), countCurrIndex(i, index),
                      1,
                      countCurrIndex(i, index) >= menu[_selectedMainMenu]?.display?.length
                      || menu[_selectedMainMenu]?.display[countCurrIndex(i, index)]?.length === 0)
                  ))
                }
              </Col>
            ))
          }
        </Row>
      );
    }
    // type 2, arrange by row
    if (menu[_selectedMainMenu]?.isStaticRow === 1) {
      sortFunctionForIntimeSheet(5);
      return (
        [...Array(menu[_selectedMainMenu]?.repeatPerRow ? Math.ceil(menu[_selectedMainMenu]?.display?.length / menu[_selectedMainMenu]?.repeatPerRow) : 1)].map((k, i) => (
          <Row key={`firststaticRow_${i.toString()}}`}>
            {
              [...Array(getRepeatTime(i))].map((r, index) => (
                <Col className="content p-0 m-0" key={`staticRow_${index.toString()}}`}>
                  {drawContent(_info.filter(x => x?.mark === (index + (i * (menu[_selectedMainMenu]?.repeatPerRow || 0))).toString()),
                    index + (i * (menu[_selectedMainMenu]?.repeatPerRow || 0)),
                    0, menu[_selectedMainMenu]?.display[index + (i * (menu[_selectedMainMenu]?.repeatPerRow || 0))]?.length === 0)}
                </Col>
              ))
            }
          </Row>
        ))
      );
    }
  };

  return (
    <>
      {menu && renderTable()}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InTimeSheetTableRender);