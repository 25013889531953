import { SET_WARN_SETTING } from '../../actions/actionTypes';

const initialState = {
  warnSetting: undefined,
};

const bogusBetReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_WARN_SETTING:
      return {
        ...state, warnSetting: action.value
      };

    default:
      return state;
  }
};

export default bogusBetReducers;