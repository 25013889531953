import React, { useEffect, useState } from 'react';

import { Input, Radio, Select } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Icon, Pagination } from '../..';
import { systemActions, whiteListActions } from '../../../redux/actions';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  lotteryAddressList: state.systemReducers.lotteryAddressList,
  editBackupIP: state.whiteListReducers.editBackupIP,
  company: state.whiteListReducers.company,
  backupIPData: state.whiteListReducers.backupIP,
});

const mapDispatchToProps = dispatch => ({
  getEditLotteryAddress: (obj, callback) => dispatch(systemActions.editLotteryAddress(obj, callback)),
  getBackupIP: (obj, callback) => dispatch(whiteListActions.getBackupIP(obj, callback)),
  getSubAccountList: (obj, callback) => dispatch(whiteListActions.getSubAccountList(obj, callback)),
  deleteBackupIP: (id, callback) => dispatch(whiteListActions.deleteBackupIP(id, callback)),
  getEditBackupIP: (id, callback) => dispatch(whiteListActions.editBackupIP(id, callback)),
  SaveBackupIP: (obj, callback) => dispatch(whiteListActions.SaveBackupIP(obj, callback)),
  SetState: (obj, callback) => dispatch(whiteListActions.SetState(obj, callback)),
});

const AgentBackUpList = ({ SetState, SaveBackupIP, deleteBackupIP, getBackupIP, backupIPData, company, editBackupIP, getEditBackupIP }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);

  const [loginId, setloginId] = useState('');
  const [companyId, setcompanyId] = useState('');
  const [backup, setbackup] = useState('');
  const [exportitem, setexportitem] = useState('');

  const [ip, setip] = useState('');
  const [backupIP, setbackupIP] = useState('');
  const [enableBackup, setenableBackup] = useState(1);
  const [enableExport, setenableExport] = useState(1);

  useEffect(() => {
    setIsQuerying(true);
    const _obj = {
      loginId,
      companyId,
      backup,
      export: exportitem,
      page,
      rows,
    };
    getBackupIP(_obj, () => setIsQuerying(false));
  }, []);

  const saveAndEdit = () => {
    if (_pageFlag === 1 || _pageFlag === 2) {
      const obj = {
        id: _pageFlag === 2 ? editBackupIP.id : '',
        loginId,
        ip,
        backupIP,
        enableBackup,
        enableExport
      };
      const callback = () => {
        const _obj = {
          loginId: '',
          companyId,
          backup,
          export: exportitem,
          page,
          rows,
        };
        setPageFlag(0);
        getBackupIP(_obj);
      };
      SaveBackupIP(obj, () => callback());
    }
  };

  const Remove = id => {
    const _obj = {
      loginId,
      companyId,
      backup,
      export: exportitem,
      page,
      rows,
    };
    deleteBackupIP(id, () => { setIsQuerying(true); getBackupIP(_obj, () => setIsQuerying(false)); });
  };

  const changeState = (id, type) => {
    const _obj = {
      loginId,
      companyId,
      backup,
      export: exportitem,
      page,
      rows,
    };
    SetState({
      id, type
    }, () => { setIsQuerying(true); getBackupIP(_obj, () => setIsQuerying(false)); });
  };


  const onSearchClick = () => {
    setIsQuerying(true);
    const _obj = {
      loginId,
      companyId,
      backup,
      export: exportitem,
      page,
      rows,
    };
    getBackupIP(_obj, () => setIsQuerying(false));
    setPage(1);
    setRows(15);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQuerying(true);
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      loginId,
      companyId,
      backup,
      export: exportitem,
      page: pageNum,
      rows,
    };
    getBackupIP(_obj, () => setIsQuerying(false));
  };

  useEffect(() => {
    if (_pageFlag === 1 || _pageFlag === 0) {
      setloginId('');
      setbackupIP('');
      setip('');
      setenableBackup(1);
      setenableExport(1);
    }
    if (_pageFlag === 2 && editBackupIP) {
      setloginId(editBackupIP.loginId);
      setbackupIP(editBackupIP?.backupIP || '');
      setip(editBackupIP?.ip || '');
      setenableBackup(editBackupIP.enableBackup);
      setenableExport(editBackupIP.enableExport);
    }
  }, [_pageFlag, editBackupIP]);

  return (
    <div className="AgentBackUpListWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
              <Icon
                libName="md"
                value={{
                  name: 'MdArrowBack',
                  className: 'h5 mb-0 text-white'
                }}
              />
              返回
            </div>
            )}
            {`${t('AGENT_BACKUP_WHITE_LIST')} ${_pageFlag === 0 ? '' : _pageFlag === 1 ? '/ 新增白名单' : '/ 编辑白名单'}`}
          </div>
)}
      />
      {_pageFlag === 0 && (
      <>
        <div className="addNewGameBox my-3">
          <div className="operationBtn add" onClick={() => setPageFlag(1)}>
            <div className="plus_icon"><i className="el-icon-plus" /></div>
            新增
          </div>
          <div className="mx-2">
            公司
            <Select className="ms-2" value={companyId} placeholder="请选择" onChange={code => setcompanyId(code)}>
              {
                     [].concat({
                       id: '', name: '全部'
                     }, company.whiteList).map(el => <Select.Option key={el.id} label={el.name} value={el.id} />)
                }
            </Select>
          </div>
          <div className="mx-2">
            备份
            <Select className="ms-2" value={backup} placeholder="请选择" onChange={code => setbackup(code)}>
              {
                     [{
                       value: '', label: '全部'
                     }, {
                       value: 0, label: '禁用'
                     }, {
                       value: 1, label: '允许'
                     }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                }
            </Select>
          </div>
          <div className="mx-2">
            导出
            <Select className="ms-2" value={exportitem} placeholder="请选择" onChange={code => setexportitem(code)}>
              {
                     [{
                       value: '', label: '全部'
                     }, {
                       value: 0, label: '禁用'
                     }, {
                       value: 1, label: '允许'
                     }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                }
            </Select>
          </div>
          <div className="mx-2">
            账号
            <Input className="ms-2" value={loginId} onChange={text => setloginId(text.trim())} />
          </div>
          <div className="operationBtn mx-2 add" onClick={() => onSearchClick()}>查询</div>
        </div>

        <div className="mx-0 bg-white mb-3 px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: '名称',
            }, {
              label: '账号',
            }, {
              label: '白名单',
            }, {
              label: '滚单白名单',
            }, {
              label: '滚单',
            }, {
              label: '导出',
            }, {
              label: '创建时间',
            }, {
              label: t('OPERATION'),
            }]
          }
            tableHeaderStyle={
            []
          }
            tableData={backupIPData ? backupIPData.backupList : []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.name}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.loginId}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.whiteList}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.backupWhiteList}</Label>),
              data => (
                <Label className="d-flex justify-content-center mousePointIn">
                  <div className={`statusBtn ${data.enableBackup === 0 ? 'text-danger' : 'text-success'}`}>
                    {data.enableBackup === 0 ? '禁用' : '允许'}
                  </div>
                  <div className="shiftBtn" onClick={() => changeState(data.organizationId, 'bak')}>
                    {data.enableBackup === 0 ? '允许' : '禁用'}
                  </div>
                </Label>
              ),
              data => (
                <Label className="d-flex justify-content-center mousePointIn">
                  <div className={`statusBtn ${data.enableExport === 0 ? 'text-danger' : 'text-success'}`}>
                    {data.enableExport === 0 ? '禁用' : '允许'}
                  </div>
                  <div className="shiftBtn" onClick={() => changeState(data.organizationId, 'exp')}>
                    {data.enableExport === 0 ? '允许' : '禁用'}
                  </div>
                </Label>
              ),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.createDate).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (
                <Label className="d-flex justify-content-center align-items-center">
                  <div className="operationBtn mx-2" onClick={() => { getEditBackupIP(data.organizationId, () => setPageFlag(2)); }}>编辑</div>
                  <div className="operationBtn cancel mx-2" onClick={() => { Remove(data.organizationId); }}>删除</div>
                </Label>
              )
            ]
          }
          />
          <div className="d-flex align-items-center justify-content-center w-full relative">
            <Pagination totalRecord={backupIPData ? (backupIPData.totalRecords || 0) : 0} onClick={onPaginationClick} isQuery={false} />
          </div>
        </div>
      </>
      )}
      {_pageFlag !== 0 && (
        <>
          <div className="my-3 thirdPartyGamesTable">
            <div className="thirdPartyGamesTitle">{_pageFlag === 1 ? '新增白名单' : '编辑白名单'}</div>
            {(_pageFlag === 2 && editBackupIP) && (
            <div className="thirdPartyGamesRow">
              <div>名称 </div>
              <div><Input disabled value={editBackupIP?.name || ''} placeholder="请输入内容" /></div>
            </div>
            )}
            <div className="thirdPartyGamesRow">
              <div>账号 </div>
              <div><Input value={loginId} disabled={_pageFlag === 2} onChange={text => setloginId(text.trim())} placeholder="请输入内容" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>登录IP白名单 </div>
              <div>
                <Input
                  type="textarea"
                  autosize={{
                    minRows: 4, maxRows: 8
                  }}
                  value={ip}
                  onChange={text => {
                    setip(text);
                  }}
                  placeholder="多个IP用逗号隔开(留空则不作登录IP白名单设置)"
                />
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>滚单IP白名单 </div>
              <div>
                <Input
                  type="textarea"
                  autosize={{
                    minRows: 4, maxRows: 8
                  }}
                  value={backupIP}
                  onChange={text => {
                    setbackupIP(text);
                  }}
                  placeholder="多个IP用逗号隔开(留空则不作登录IP白名单设置)"
                />
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>滚单权限 </div>
              <div>
                <Radio value="1" checked={enableBackup === 1} onChange={() => setenableBackup(1)}>允许</Radio>
                <Radio value="1" checked={enableBackup === 0} onChange={() => setenableBackup(0)}>禁用</Radio>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>导出权限 </div>
              <div>
                <Radio value="1" checked={enableExport === 1} onChange={() => setenableExport(1)}>允许</Radio>
                <Radio value="1" checked={enableExport === 0} onChange={() => setenableExport(0)}>禁用</Radio>
              </div>
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => saveAndEdit()}>保存</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentBackUpList);