import React, { useEffect, useState } from 'react';

import { Input, Select } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';

import { AgentDataTable } from '../..';
import Pagination from '../../Content/Pagination';
import { reportActions } from '../../../redux/actions';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  getBettingRank: (obj, callback) => dispatch(reportActions.getBettingRank(obj, callback)),
});

const RankList = ({ getBettingRank, }) => {
  const [reportType, setReportType] = useState(2);
  const [_date, setDate] = useState(`${moment().format('YYYY-MM-DD')};${moment().format('YYYY-MM-DD')}`);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(20);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [BettingRankData, setBettingRankData] = useState();

  const [dropDownList, setDropDownList] = useState([]);
  const [memberName, setMemberName] = useState('');


  const callback = data => {
    setIsQueryingDetail(false);
    const ranksList = data?.ranksList.map((x, index) => ({
      ...x, num: (index + 1) + (_page - 1) * _rows
    }));
    setBettingRankData({
      ...data, ranksList
    });
  };

  const onSearchClick = () => {
    setIsQueryingDetail(true);
    const obj = {
      beginDate: _date.split(';')[0],
      endDate: _date.split(';')[1],
      page: _page,
      rows: _rows,
      reportType,
      memberName
    };
    getBettingRank(obj, callback);
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    const obj = {
      beginDate: _date.split(';')[0],
      endDate: _date.split(';')[1],
      page: pageNum,
      rows,
      reportType,
      memberName
    };
    setPage(pageNum);
    setRows(rows);
    getBettingRank(obj, callback);
  };


  useEffect(() => {
    const count = 30;
    const tempList = [];
    if (reportType < 2) {
      for (let index = 0; index < count; index++) {
        const current = moment().subtract(index, 'days');
        tempList.push({
          label: `${current.format('YYYY年MM月DD日')} 榜单`,
          date: `${current.format('YYYY-MM-DD')};${current.format('YYYY-MM-DD')}`,
        });
      }
      setDate(tempList[0].date);
    } else {
      for (let index = 0; index < count; index++) {
        const current = moment().subtract(index, 'w');
        tempList.push({
          label: `${current.startOf('isoWeek').format('MM月DD日')}--${current.endOf('isoWeek').format('MM月DD日')} 榜单`,
          date: `${current.startOf('isoWeek').format('YYYY-MM-DD')};${current.endOf('isoWeek').format('YYYY-MM-DD')}`
        });
      }
      setDate(tempList[0].date);
    }
    setDropDownList([].concat(tempList));
  }, [reportType]);

  const reportTypeList = [
    {
      label: '日输赢榜单', value: 0, props: 'dayWinRank'
    },
    {
      label: '日流水榜单', value: 1, props: 'dayAmountRank'
    }, {
      label: '周输赢榜单', value: 2, props: 'weekWinRank'
    }, {
      label: '周流水榜单', value: 3, props: 'weekAmountRank'
    },
  ];

  return (
    <div className="RankListWrapper">
      <div className="billQueryTableWrapper p-3">
        <div className="pb-2 px-2 fw-bold fs-5">
          当前会员排名：
          <span className="text-danger">
            {
              BettingRankData ? BettingRankData.rank[`${reportTypeList.find(x => x.value === reportType).props}`] : 0
            }
          </span>
        </div>
        <div className="userTable w-100 d-flex p-2 align-items-center">
          <div className="mx-2">榜单类型</div>
          <Select className="inputFormStyle" value={reportType} onChange={value => setReportType(value)}>
            {
              reportTypeList.map(item => <Select.Option key={item.label} value={item.value} label={item.label} />)
            }
          </Select>
          <div className="mx-2">条数</div>
          <Select className="inputFormStyle" value={_rows} onChange={setRows}>
            {
              [
                {
                  label: '20', value: 20
                },
                {
                  label: '50', value: 50
                }, {
                  label: '100', value: 100
                }, {
                  label: '200', value: 200
                },
              ].map(item => <Select.Option key={item.label} value={item.value} label={item.label} />)
            }
          </Select>
          <div className="mx-2">时间</div>
          <Select className="inputFormStyle" value={_date} onChange={setDate}>
            {
              dropDownList.map(item => <Select.Option key={item.label} value={item.date} label={item.label} />)
            }
          </Select>
          <div className="mx-2">会员</div>
          <Input className="inputFormStyle" value={memberName} onChange={setMemberName} />

          <div className="operationBtn findAndCollapse mx-2" onClick={onSearchClick}>查询</div>
        </div>
      </div>
      <div className="billQueryTableWrapper p-3">
        <div className="mx-0 bg-white">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={
            [
              {
                label: '排名',
              }, {
                label: '用户名',
              }, {
                label: '盈利',
              }, {
                label: '流水',
              }, {
                label: '注单',
              }]
          }
            tableData={BettingRankData ? BettingRankData.ranksList : []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.num}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.name}</Label>),
              data => (<Label className={`d-flex justify-content-center ${parseFloat(data.winLost) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.betWinlost}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.amount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data?.fee100}</Label>),
            ]
          }
          />
        </div>
      </div>
      <div className=" d-flex justify-content-center w-full relative">
        <Pagination totalRecord={(BettingRankData && BettingRankData?.CountNum) || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(RankList);