import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { AgentList, MemberList, SubAccountList } from '../../components';

import CreatePage from '../../components/UserManage/CreatePage';
import UserManagementMenu from '../../components/UserManage/UserManagementMenu';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
  userData: state.memberReducers.userData,
});

const UserManagement = ({ selectedSubMenu, userData }) => {
  const [_menutab, setMenuTab] = useState(false);
  const [tabIndex, setTabIndex] = useState(0); // current selected tab
  const [data, setData] = useState(); // current selected account data
  const [_fromSub, setFromSub] = useState(false);
  const [_breadcurmb, setbreadcurmb] = useState([]); // current breadcurmb

  useEffect(() => {
    setMenuTab(false);
    setTabIndex(0);
    if (selectedSubMenu === 'UM_ZSZZH' || selectedSubMenu === 'UM_XJZZH') {
      setFromSub(true);
    }
  }, [selectedSubMenu]);

  const renderTabItem = () => {
    switch (selectedSubMenu) {
      case 'UM_ZSDL': return <AgentList key="UM_ZSDL" setbreadcurmb={setbreadcurmb} callback={(val, data) => { setTabIndex(val); setData(data); setMenuTab(true); }} breadcrumb={_breadcurmb} agentType={0} userLevel={(Number(userData?.levelValue) || 0) + 1} />;
      case 'UM_XJDL': return <AgentList key="UM_XJDL" setbreadcurmb={setbreadcurmb} callback={(val, data) => { setTabIndex(val); setData(data); setMenuTab(true); }} breadcrumb={_breadcurmb} agentType={4} userLevel={(Number(userData?.levelValue) || 0) - 1} />;
      case 'UM_SYDL': return <AgentList key="UM_SYDL" setbreadcurmb={setbreadcurmb} callback={(val, data) => { setTabIndex(val); setData(data); setMenuTab(true); }} breadcrumb={_breadcurmb} agentType={0} userLevel={(Number(userData?.levelValue) || 0) - 1} />;
      case 'UM_ZSHY': return <MemberList key="UM_ZSHY" callback={(val, data, breadcrumb) => { setData(data); setTabIndex(val); setMenuTab(true); setbreadcurmb(breadcrumb); }} breadcrumb={_breadcurmb} agentType={1} userLevel={(Number(userData?.levelValue) || 0) + 1} />;
      case 'UM_XJHY': return <MemberList key="UM_XJHY" callback={(val, data, breadcrumb) => { setData(data); setTabIndex(val); setMenuTab(true); setbreadcurmb(breadcrumb); }} breadcrumb={_breadcurmb} agentType={4} userLevel={(Number(userData?.levelValue) || 0) - 1} />;
      case 'UM_QBHY': return <MemberList key="UM_QBHY" callback={(val, data, breadcrumb) => { setData(data); setTabIndex(val); setMenuTab(true); setbreadcurmb(breadcrumb); }} breadcrumb={_breadcurmb} agentType={0} userLevel={(Number(userData?.levelValue) || 0) - 1} />;
      case 'UM_ZSZZH': return <SubAccountList key="UM_ZSZZH" callback={(val, data, breadcrumb) => { setData(data); setTabIndex(val); setMenuTab(true); setbreadcurmb(breadcrumb); }} breadcrumb={_breadcurmb} agentType={3} userLevel={(Number(userData?.levelValue) || 0)} />;
      case 'UM_XJZZH': return <SubAccountList key="UM_XJZZH" callback={(val, data, breadcrumb) => { setData(data); setTabIndex(val); setMenuTab(true); setbreadcurmb(breadcrumb); }} breadcrumb={_breadcurmb} agentType={5} userLevel={(Number(userData?.levelValue) || 0) - 1} />;
      default:
        break;
    }
  };

  return (
    <div className="userManagementWrapper">
      <br />
      {!_menutab
        ? renderTabItem()
        : (
          (!Number.isInteger(tabIndex) && tabIndex?.includes('create'))
            ? <CreatePage tabIndex={tabIndex} callback={() => setMenuTab(false)} setTabIndex={val => setTabIndex(val)} data={data} breadcrumb={_breadcurmb} />
            : <UserManagementMenu tabIndex={tabIndex} fromSub={_fromSub} callback={() => setMenuTab(false)} setTabIndex={val => setTabIndex(val)} data={data} breadcrumb={_breadcurmb} />
        )}
    </div>
  );
};

export default connect(mapStateToProps, null)(UserManagement);