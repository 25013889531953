import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, noticeActions, onlineStatisticsActions } from '../../../redux/actions';
import Label from '../../../components/Label';
import './styles.scss';


const mapStateToProps = state => ({
  latestAnnouncement: state.noticeReducers.latestAnnouncement,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getAnnouncement: () => dispatch(noticeActions.getAnnouncement()),
  GetOnlineUserCount: () => dispatch(onlineStatisticsActions.GetOnlineUserCount()),
  GetNotification: () => dispatch(noticeActions.GetNotification()),
});

const AuthFooter = ({ latestAnnouncement, getAnnouncement, GetNotification, GetOnlineUserCount, userData, updateModalAlert }) => {
  const [_runningNews, setRunningNews] = useState();
  const [_paoMaDengAnimation, setPaoMaDengAnimation] = useState();

  const { t } = useTranslation();

  const savedCallback = useRef();

  useEffect(() => {
    if (latestAnnouncement) {
      if (JSON.stringify(_runningNews) !== JSON.stringify(latestAnnouncement)) {
        setRunningNews(latestAnnouncement);
      }
      // if (JSON.stringify(_latestNews) !== JSON.stringify(latestNews)) {
      //   setLatestNews(latestNews);
      //   const doNotRemind = !document.cookie.includes('doNotRemind') ? undefined : JSON.parse(document.cookie
      //     .split('; ')
      //     .find(row => row.startsWith('doNotRemind='))
      //     .split('=')[1]);

      //   if (_latestNews || (!_latestNews && (doNotRemind === undefined || !doNotRemind))) {
      //     updateModalAlert({
      //       visible: true,
      //       type: 'latestAnnouncement',
      //       data: {
      //         latestNews
      //       }
      //     });
      //   }
      // }
    }
  }, [latestAnnouncement]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    const id = setInterval(tick, 10000);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    savedCallback.current = () => {
      getAnnouncement();
      GetNotification();
      if (userData && userData?.levelValue === 1) {
        GetOnlineUserCount();
      }
    };
  });

  useEffect(() => {
    setPaoMaDengAnimation('animate');
  });

  return (
    <div className="authFooter">
      <div className="paomadengWrapper">
        <span id="paomadengSpan" className={`paomadengSpan ${_paoMaDengAnimation}`}>
          {
            _runningNews && _runningNews[0]?.content
          }
        </span>
      </div>
      <div className="footerMore">
        <Label
          className="moreButton"
          value={t('MORE')}
          onClick={() => updateModalAlert({
            visible: true,
            type: 'latestAnnouncement',
          })}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthFooter);