/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';

import { DateRangePicker, Input, Select } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../..';
import CommonHeader from '../../Content/CommonHeader';
import { transactionActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  transactionFlows: state.transactionReducers.transactionFlows,
});

const mapDispatchToProps = dispatch => ({
  GetTransactionFlows: (obj, callback) => dispatch(transactionActions.GetTransactionFlows(obj, callback)),
});

const AccountFlow = ({ GetTransactionFlows, transactionFlows, hide, data, callback = () => {} }) => {
  const { t } = useTranslation();
  const [_date, setDate] = useState([!hide ? moment().subtract(1, 'weeks').startOf('day').toDate() : moment().subtract(5, 'years').startOf('day').toDate(), moment().endOf('day').toDate()]);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [mName, setLoginName] = useState(data ? data.mName : '');
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [mType, setMtype] = useState(0);

  let dateRef;

  useEffect(() => {
    const obj = {
      mName, mType, startTime: moment(_date[0]).unix(), endTime: moment(_date[1]).unix(), page, rows
    };
    GetTransactionFlows(obj, () => setIsQueryingDetail(false));
  }, []);

  const onSearchClick = () => {
    setIsQueryingDetail(true);
    const obj = {
      mName, mType, startTime: moment(_date[0]).unix(), endTime: moment(_date[1]).unix(), page: 1, rows: 15
    };
    GetTransactionFlows(obj, () => setIsQueryingDetail(false));
  };

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(true);
    const obj = {
      mName, mType, startTime: moment(_date[0]).unix(), endTime: moment(_date[1]).unix(), page: pageNum, rows
    };
    GetTransactionFlows(obj, () => setIsQueryingDetail(false));
    setPage(pageNum);
    setRows(rows);
  };

  return (
    <>
      { !hide && <CommonHeader headerName="账户流水" />}
      <div className="accountFlowWrapper">
        {!hide
        && (
        <div className="accountFlowTableWrapper">
          <div className="userTable w-100">
            <div>
              账号
            </div>
            <div className="mx-2">
              <Input value={mName} onChange={text => setLoginName(text)} />
            </div>
            <div>
              账号
            </div>
            <div className="mx-2">
              <Select
                popper-class="select-popper"
                value={mType}
                onChange={value => setMtype(value)}
              >
                {
                    [{
                      label: '全部', value: 0
                    }, {
                      label: '会员', value: 1
                    }, {
                      label: '代理', value: 2
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
              </Select>
            </div>
            <div>日期范围</div>
            <div>
              <DateRangePicker
                className="betQueryDatepicker"
                value={_date}
                isShowTime
                placeholder={t('START_END_DATE')}
                rangeSeparator={` ${t('TO')} `}
                onChange={date => {
                  const _d = moment(date[1]).endOf('day').toDate();
                  setDate([date[0], _d]);
                }}
                ref={e => dateRef = e}
                shortcuts={[{
                  text: t('TODAY'),
                  onClick: () => {
                    setDate([moment().toDate(), moment().toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('THIS_WEEK'),
                  onClick: () => {
                    setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }, {
                  text: t('PREVIOUS_2_WEEK'),
                  onClick: () => {
                    setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
                    dateRef.togglePickerVisible();
                  }
                }]}
              />
            </div>
            <div className="operationBtn serchAndDetail mx-1" onClick={onSearchClick}>查询</div>

          </div>
        </div>
        )}
        <div className="mx-0 bg-white mt-3">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={
              !hide
                ? [{
                  label: '账号',
                }, {
                  label: '交易前余额',
                }, {
                  label: '交易金额',
                }, {
                  label: '交易后余额',
                }, {
                  label: '交易描述',
                }, {
                  label: '相关订单号',
                }]
                : [{
                  label: '序号',
                }, {
                  label: '交易时间',
                }, {
                  label: '交易前余额',
                }, {
                  label: '交易金额',
                }, {
                  label: '交易后余额',
                }, {
                  label: '交易描述',
                }, {
                  label: '订单详情',
                }]
              }
            tableHeaderStyle={['noWrapStyle', 'noWrapStyle', 'noWrapStyle', 'noWrapStyle', 'noWrapStyle', 'noWrapStyle', 'noWrapStyle']}
            tableData={transactionFlows ? transactionFlows.data : []}
            tableBodyRowProperty={
              !hide
                ? [
                  data => (
                    <Label className="d-flex justify-content-center noWrapStyle">
                      {`${data.mName} [${data.mType === 1 ? '会员' : '代理'}]`}
                    </Label>
                  ),
                  data => <Label className="d-flex justify-content-center">{data.beforeAmount}</Label>,
                  data => (<Label className="d-flex justify-content-center">{data.actionAmount}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.afterAmount}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center">
                      <div dangerouslySetInnerHTML={{
                        __html: data.actionDesc
                      }}
                      />
                    </Label>
                  ),
                  data => (<Label className="d-flex justify-content-center">{data.orderID}</Label>),
                ]
                : [
                  data => <Label className="d-flex justify-content-center">{data.no}</Label>,
                  data => <Label className="d-flex justify-content-center">{data.createTime}</Label>,
                  data => <Label className="d-flex justify-content-center">{data.beforeAmount}</Label>,
                  data => (<Label className="d-flex justify-content-center">{data.actionAmount}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.afterAmount}</Label>),
                  data => (<Label className="d-flex justify-content-center">{data.actionDesc}</Label>),
                  data => (
                    <Label className="d-flex justify-content-center align-items-center">
                      <div className="operationBtn" onClick={() => callback(data)}>详情</div>
                    </Label>
                  ),
                ]
              }
          />
        </div>
        <div className="justify-center w-full relative">
          <Pagination totalRecord={transactionFlows ? transactionFlows.sum.totalRecord : 0} onClick={(pageNum, rows) => onPaginationClick(pageNum, rows)} isQuery={false} />
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountFlow);