import { SET_BELL, SET_EDIT_NOTICE_INFO, SET_NEWS_LIST, SET_NOTICES_SETTING, SET_NOTIFICATION, SET_WARNING_MSG, SET_WARNING_MSG_HISTORY } from '../../actions/actionTypes';

const initialState = {
  latestAnnouncement: undefined,
  warningMsg: undefined,
  warningMsgHistory: undefined,
  noticeSettingData: undefined,
  editNoticeData: undefined,
  notifications: undefined,
  bellMuted: false,
};

const noticeReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS_LIST:
      return {
        ...state, latestAnnouncement: action.value
      };
    case SET_WARNING_MSG:
      return {
        ...state, warningMsg: action.value
      };
    case SET_WARNING_MSG_HISTORY:
      return {
        ...state, warningMsgHistory: action.value
      };
    case SET_NOTICES_SETTING:
      return {
        ...state, noticeSettingData: action.value
      };
    case SET_EDIT_NOTICE_INFO:
      return {
        ...state, editNoticeData: action.value
      };
    case SET_NOTIFICATION:
      return {
        ...state, notifications: action.value
      };
    case SET_BELL:
      return {
        ...state, bellMuted: action.value
      };
    default:
      return state;
  }
};

export default noticeReducers;