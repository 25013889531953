import { SET_BACKUP_IP, SET_COMPANY_LIST, SET_EDIT_BACKUP_IP, SET_EDIT_WHITE, SET_SUB_ACCOUNT_LIST, SET_SUPER_USER } from '../../actions/actionTypes';

const initialState = {
  superUser: undefined,
  company: undefined,
  backupIP: undefined,
  editBackupIP: undefined,
  subAccountList: undefined,
  editWhite: undefined,
};

const whiteListReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUPER_USER:
      return {
        ...state, superUser: action.value
      };
    case SET_COMPANY_LIST:
      return {
        ...state, company: action.value
      };
    case SET_BACKUP_IP:
      return {
        ...state, backupIP: action.value
      };
    case SET_EDIT_BACKUP_IP:
      return {
        ...state, editBackupIP: action.value
      };
    case SET_SUB_ACCOUNT_LIST:
      return {
        ...state, subAccountList: action.value
      };
    case SET_EDIT_WHITE:
      return {
        ...state, editWhite: action.value
      };
    default:
      return state;
  }
};

export default whiteListReducers;