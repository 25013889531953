export const USER_LOGOUT = 'USER_LOGOUT';
export const TOGGLE_HORIZONTAL = 'TOGGLE_HORIZONTAL';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_IN_TIME_SHEET_INFO = 'SET_IN_TIME_SHEET_INFO';
export const SET_LOTTERY_LIST = 'SET_LOTTERY_LIST';
export const SET_ACTIVE_LOTTERY = 'SET_ACTIVE_LOTTERY';
export const SET_LIST_ODDS = 'SET_LIST_ODDS';
export const SET_SELECTED_SUB_MENU = 'SET_SELECTED_SUB_MENU';
export const SET_BET_QUERY_RESULT = 'SET_BET_QUERY_RESULT';
export const SET_BET_ZC_DETAIL = 'SET_BET_ZC_DETAIL';
export const SET_LOTTERY_INSTALLMENTS = 'SET_LOTTERY_INSTALLMENTS';
export const SET_MODAL_ALERT = 'SET_MODAL_ALERT';
export const SET_CAPTCHA_DATA = 'SET_CAPTCHA_DATA';
export const SET_AUTO_RESTOCK_LIST_RESULT = 'SET_AUTO_RESTOCK_LIST_RESULT';
export const SET_PRE_AMOUNT = 'SET_PRE_AMOUNT';
export const SET_BATCH_ODDS_LIST = 'SET_BATCH_ODDS_LIST';
export const SET_OPERATION_LOG = 'SET_OPERATION_LOG';
export const SET_WARN_SETTING = 'SET_WARN_SETTING';
export const SET_SELECTED_SUB_SELECTION = 'SET_SELECTED_SUB_SELECTION';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_NEWS_LIST = 'SET_NEWS_LIST';
export const SET_ONLINE_USER_LIST = 'SET_ONLINE_USER_LIST';
export const SET_ONLINE_LEVEL_SUM = 'SET_ONLINE_LEVEL_SUM';
export const SET_MEMBER_LIST = 'SET_MEMBER_LIST';
export const SET_AGENT_LIST = 'SET_AGENT_LIST';
export const SET_SHARES_DETAIL = 'SET_SHARES_DETAIL';
export const SET_THIRD_PARTY_GAMES_LIST = 'SET_THIRD_PARTY_GAMES_LIST';
export const SET_THIRD_PARTY_GAMES_EDIT = 'SET_THIRD_PARTY_GAMES_EDIT';
export const SET_BET_HISTORY_LIST = 'SET_BET_HISTORY_LIST';
export const SET_REBATE_LIST = 'SET_REBATE_LIST';
export const SET_THIRD_PARTY_LIST = 'SET_THIRD_PARTY_LIST';
export const SET_LOAD_LOGIN_LOGS = 'SET_LOAD_LOGIN_LOGS';
export const SET_HANDLER_LOG = 'SET_HANDLER_LOG';
export const SET_BANKS = 'SET_BANKS';
export const SET_TRANSFER_FLOW_DETAIL = 'SET_TRANSFER_FLOW_DETAIL';
export const SET_REBATE_SETTING = 'SET_REBATE_SETTING';
export const SET_MFA_SETTING = 'SET_MFA_SETTING';
export const SET_THIRD_PARTY_REBATE_SETTING = 'SET_THIRD_PARTY_REBATE_SETTING';
export const SET_ODDS_DIFF = 'SET_ODDS_DIFF';
export const SET_FINANCIAL_REPORT = 'SET_FINANCIAL_REPORT';
export const SET_DEPOSITAND_WITH_DRAW_REPORT = 'SET_DEPOSITAND_WITH_DRAW_REPORT';
export const SET_DEPOSITAND_WITH_DRAW_TOTAL_REPORT = 'SET_DEPOSITAND_WITH_DRAW_TOTAL_REPORT';
export const SET_DEPOSITAND_WITH_DRAW_STATISTIC = 'SET_DEPOSITAND_WITH_DRAW_STATISTIC';
export const SET_PREV_ODDS_DIFF = 'SET_PREV_ODDS_DIFF';
export const SET_LV2_ORGS = 'SET_LV2_ORGS';
export const SET_WARNING_MSG = 'SET_WARNING_MSG';
export const SET_WARNING_MSG_HISTORY = 'SET_WARNING_MSG_HISTORY';
export const SET_MEMBER_SUMMATION_REPORT = 'SET_MEMBER_SUMMATION_REPORT';
export const SET_MEMBER_SUMMATION_DETAIL_REPORT = 'SET_MEMBER_SUMMATION_DETAIL_REPORT';
export const SET_MEMBER_DOWNLINE_REPORT = 'SET_MEMBER_DOWNLINE_REPORT';
export const SET_AGENT_DOWNLINE_REPORT = 'SET_AGENT_DOWNLINE_REPORT';
export const SET_THIRD_GAMES_ACCOUNT = 'SET_THIRD_GAMES_ACCOUNT';
export const SET_THIRD_GAMES_AMOUNTS = 'SET_THIRD_GAMES_AMOUNTS';
export const UPDATE_THIRD_GAMES_AMOUNTS = 'UPDATE_THIRD_GAMES_AMOUNTS';
export const SET_WIN_LOST_NOTIFICATION = 'SET_WIN_LOST_NOTIFICATION';
export const SET_CHANGE_HISTORY_LOG = 'SET_CHANGE_HISTORY_LOG';
export const SET_CHECK_SETTING = 'SET_CHECK_SETTING';
export const SET_SUB_ACCOUNT_INFO = 'SET_SUB_ACCOUNT_INFO';
export const SET_IS_VALID_LOGINID_MSG = 'SET_IS_VALID_LOGINID_MSG';
export const SET_IS_VALID_NAME_MSG = 'SET_IS_VALID_NAME_MSG';
export const SET_WIN_LOST_REPORT = 'SET_WIN_LOST_REPORT';
export const SET_CLASSIFICATION_REPORT = 'SET_CLASSIFICATION_REPORT';
export const SET_STATISTIC_REPORT = 'SET_STATISTIC_REPORT';
export const SET_ADD_MEMBER_INFO = 'SET_ADD_MEMBER_INFO';
export const SET_EDIT_MEMBER_DETAIL = 'SET_EDIT_MEMBER_DETAIL';
export const SET_TRANSACTION_MANAGE = 'SET_TRANSACTION_MANAGE';
export const SET_TRANSACTION_FLOW = 'SET_TRANSACTION_FLOW';
export const SET_TRANSACTION_DETAIL = 'SET_TRANSACTION_DETAIL';
export const SET_BUSINESS = 'SET_BUSINESS';
export const SET_ONLINE_USER_COUNT = 'SET_ONLINE_USER_COUNT';
export const SET_SYSTEM_SETTING_INFO = 'SET_SYSTEM_SETTING_INFO';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_GIFT = 'SET_GIFT';
export const SET_PRO_CODE = 'SET_PRO_CODE';
export const SET_AGENT_REBATE = 'SET_AGENT_REBATE';
export const SET_LOTTERY_ITEMS = 'SET_LOTTERY_ITEMS';
export const SET_EDIT_LOTTERY_ITEM = 'SET_EDIT_LOTTERY_ITEM';
export const SET_COMPANY_INFO = 'SET_COMPANY_INFO';
export const SET_TOPUP_INFO = 'SET_TOPUP_INFO';
export const SET_WITHDRAWAL_INFO = 'SET_WITHDRAWAL_INFO';
export const SET_BANK_CARDS = 'SET_BANK_CARDS';
export const SET_BANK_CARDS_LIST = 'SET_BANK_CARDS_LIST';
export const SET_MEMBER_INFO = 'SET_MEMBER_INFO';
export const SET_NOTICES_SETTING = 'SET_NOTICES_SETTING';
export const SET_EDIT_NOTICE_INFO = 'SET_EDIT_NOTICE_INFO';
export const SET_MODAL_MESSAGE = 'SET_MODAL_MESSAGE';
export const SET_PAYMENT_ACCOUNT_SETTING = 'SET_PAYMENT_ACCOUNT_SETTING';
export const SET_EDIT_PAY_ACC = 'SET_EDIT_PAY_ACC';
export const SET_PANKOU_DATA = 'SET_PANKOU_DATA';
export const SET_EXTERNAL_WEB_INFO = 'SET_EXTERNAL_WEB_INFO';
export const SET_THIRD_PAY_SETTING = 'SET_THIRD_PAY_SETTING';
export const SET_EDIT_THIRD_PAY_SETTING = 'SET_EDIT_THIRD_PAY_SETTING';
export const SET_PANKOU_LOTTERY_RESULT = 'SET_PANKOU_LOTTERY_RESULT';
export const SET_MEMBER_AUTO_CLASSIFY = 'SET_MEMBER_AUTO_CLASSIFY';
export const SET_PAYMENT_OFFLINE_SETTING = 'SET_PAYMENT_OFFLINE_SETTING';
export const SET_EDIT_OFFLINE_SETTING = 'SET_EDIT_OFFLINE_SETTING';
export const SET_SMS_SETTING = 'SET_SMS_SETTING';
export const SET_EMAIL_SETTING = 'SET_EMAIL_SETTING';
export const SET_EDIT_SMS_SETTING = 'SET_EDIT_SMS_SETTING';
export const SET_EDIT_EMAIL_SETTING = 'SET_EDIT_EMAIL_SETTING';
export const SET_AGENT_EDIT_INFO = 'SET_AGENT_EDIT_INFO';
export const SET_WITHDRAW_SETTING = 'SET_WITHDRAW_SETTING';
export const SET_EDIT_WITHDRAW_SETTING = 'SET_EDIT_WITHDRAW_SETTING';
export const SET_OTP_LIST = 'SET_OTP_LIST';
export const SET_EXCHANGE_RATE_LIST = 'SET_EXCHANGE_RATE_LIST';
export const SET_EDIT_EXCHANGE_RATE = 'SET_EDIT_EXCHANGE_RATE';
export const SET_EDIT_BANK_SETTING = 'SET_EDIT_BANK_SETTING';
export const SET_OPEN_RESULT_SETTING_LIST = 'SET_OPEN_RESULT_SETTING_LIST';
export const SET_RESULT = 'SET_RESULT';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_DECREASE_ODD = 'SET_DECREASE_ODD';
export const SET_ODDS_SETTING = 'SET_ODDS_SETTING';
export const SET_SUPER_USER = 'SET_SUPER_USER';
export const SET_LOTTERY_ADDRESS_LIST = 'SET_LOTTERY_ADDRESS_LIST';
export const SET_LOTTERY_EDIT_ADDRESS = 'SET_LOTTERY_EDIT_ADDRESS';
export const SET_COMPANY_LIST = 'SET_COMPANY_LIST';
export const SET_BACKUP_IP = 'SET_BACKUP_IP';
export const SET_EDIT_BACKUP_IP = 'SET_EDIT_BACKUP_IP';
export const SET_EDIT_SYS_SERVICE_INFO = 'SET_EDIT_SYS_SERVICE_INFO';
export const SET_SYS_SERVICE_INFO = 'SET_SYS_SERVICE_INFO';
export const SET_SUB_ACCOUNT_LIST = 'SET_SUB_ACCOUNT_LIST';
export const SET_EDIT_WHITE = 'SET_EDIT_WHITE';
export const SET_SYS_CONFIG_INFO = 'SET_SYS_CONFIG_INFO';
export const SET_ALL_COMPANY_LIST = 'SET_ALL_COMPANY_LIST';
export const SET_COMPANY_EDIT_INFO = 'SET_COMPANY_EDIT_INFO';
export const SET_DOMAIN_INFO = 'SET_DOMAIN_INFO';
export const SET_DOMAIN_EDIT_INFO = 'SET_DOMAIN_EDIT_INFO';
export const SET_COMPANY_MANAGE_INFO = 'SET_COMPANY_MANAGE_INFO';
export const SET_CROSS_LOGIN = 'SET_CROSS_LOGIN';
export const SET_ROLE_INFO = 'SET_ROLE_INFO';
export const SET_MENU_INFO = 'SET_MENU_INFO';
export const SET_PARENT_MENU_LIST = 'SET_PARENT_MENU_LIST';
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';
export const SET_EDIT_ROLE_INFO = 'SET_EDIT_ROLE_INFO';
export const SET_DEFAULT_ODDS = 'SET_DEFAULT_ODDS';
export const SET_BATCH_DEFAULT_ODDS = 'SET_BATCH_DEFAULT_ODDS';
export const SET_THIRD_TOPUP_INFO = 'SET_THIRD_TOPUP_INFO';
export const SET_GAMES_INFO = 'SET_GAMES_INFO';
export const SET_ORG_USER_COUNT = 'SET_ORG_USER_COUNT';
export const SET_LOTTERY_TYPE_INFO = 'SET_LOTTERY_TYPE_INFO';
export const SET_EDIT_LOTTERY_TYPE_INFO = 'SET_EDIT_LOTTERY_TYPE_INFO';
export const SET_BELL = 'SET_BELL';
export const SET_THEME_COLOR = 'SET_THEME_COLOR';
export const SET_CLICK_RELOAD_FLAG = 'SET_CLICK_RELOAD_FLAG';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_THIRD_PARTY_WIN_LOST_AGENT_REPORT = 'SET_THIRD_PARTY_WIN_LOST_AGENT_REPORT';
export const SET_UPORG_FOR_COPY = 'SET_UPORG_FOR_COPY';