import React, { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { agentActions, appActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Icon from '../../Icon';

import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  sharesDetailData: state.agentReducers.sharesDetailData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getSharesDetail: (userType, userId) => dispatch(agentActions.getSharesDetail(userType, userId)),
  resetShareDetails: () => dispatch(agentActions.resetShareDetails()),
});

const SharesDetailsModal = ({ updateModalAlert, data, getSharesDetail, sharesDetailData, resetShareDetails }) => {
  const { t } = useTranslation();
  const [_detailItem, setDetailItem] = useState();
  const [_header, setHeader] = useState([]);
  const [_content, setContent] = useState([]);
  const [_isQuery, setIsQuery] = useState(true);

  useEffect(() => {
    resetShareDetails();
    setIsQuery(true);
    // Default search when first load
    data && data?.userId && data?.userType && getSharesDetail(data?.userType, data?.userId);
  }, [data]);

  useEffect(() => {
    setIsQuery(true);
    if (sharesDetailData) {
      setDetailItem(sharesDetailData);
      const tempHead = [];
      tempHead.push({
        label: `${t('LEVEL')}/${t('ACCOUNT')}`,
      });
      sharesDetailData[0]?.list.forEach(d => {
        tempHead.push({
          label: d?.categoryName,
        });
      });
      setHeader(tempHead);
      setContent(tableContent.slice(0, tempHead.length));
      setIsQuery(false);
    }
  }, [sharesDetailData]);

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{`${data?.levelName}/${data?.loginId}`}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.list?.length > 0 ? data?.list[0]?.shares : ''}%`}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.list?.length > 1 ? data?.list[1]?.shares : ''}%`}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.list?.length > 2 ? data?.list[2]?.shares : ''}%`}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.list?.length > 3 ? data?.list[3]?.shares : ''}%`}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.list?.length > 4 ? data?.list[4]?.shares : ''}%`}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.list?.length > 5 ? data?.list[5]?.shares : ''}%`}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.list?.length > 6 ? data?.list[6]?.shares : ''}%`}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.list?.length > 7 ? data?.list[7]?.shares : ''}%`}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.list?.length > 8 ? data?.list[8]?.shares : ''}%`}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.list?.length > 9 ? data?.list[9]?.shares : ''}%`}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.list?.length > 10 ? data?.list[10]?.shares : ''}%`}</Label>),
    data => (<Label className="d-flex justify-content-center">{`${data?.list?.length > 11 ? data?.list[11]?.shares : ''}%`}</Label>),
  ];

  return (
    <div className="sharesDetailModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex justify-content-center align-items-center'}`}
          xs={{
            size: 4, offset: 4
          }}
        >
          {t('ZC')}
          {t('DETAILS')}
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <Icon
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            libName="io"
            value={{
              name: 'IoClose',
              className: 'h4 mb-0 float-right closeIcon',
            }}
          />
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center">
        <Row className="modalContent d-flex p-2 justify-content-center">
          <AgentDataTable
            isQuerying={_isQuery}
            tableHeaderTitle={_isQuery ? [1] : _header}
            tableData={_isQuery ? [1] : _detailItem}
            tableBodyRowProperty={_isQuery ? [1] : _content}
          />
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onConfirmationClick) data.onConfirmationClick();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SharesDetailsModal);