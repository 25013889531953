import React, { useEffect, useState } from 'react';

import { Checkbox, Input, Select } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, CommonHeader, Icon } from '../..';
import { companyActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  allCompanyList: state.commonReducers.allCompanyList,
  domainInfo: state.companyReducers.domainInfo,
  domainEditInfo: state.companyReducers.domainEditInfo,
});

const mapDispatchToProps = dispatch => ({
  DeleteDomainInfo: (id, callback) => dispatch(companyActions.DeleteDomainInfo(id, callback)),
  GetDomainInfo: (id, callback) => dispatch(companyActions.GetDomainInfo(id, callback)),
  SaveDomainInfo: (obj, callback) => dispatch(companyActions.SaveDomainInfo(obj, callback)),
  getEditDomainInfo: (obj, callback) => dispatch(companyActions.getEditDomainInfo(obj, callback)),
});

const CompanyDomainSetting = ({ SaveDomainInfo, getEditDomainInfo, GetDomainInfo, domainEditInfo, domainInfo, allCompanyList, DeleteDomainInfo }) => {
  const { t } = useTranslation();
  const [_isQuerying, setIsQuerying] = useState(false);
  const [_pageFlag, setPageFlag] = useState(0);

  const [_list, setList] = useState();
  const [_checkAll, setCheckAll] = useState(false);
  const [companyId, setcompanyId] = useState(allCompanyList ? allCompanyList[0].companyId : '');
  const [id, setid] = useState('');
  const [name, setname] = useState(allCompanyList ? allCompanyList[0].name : '');
  const [defaultAdmin, setdefaultAdmin] = useState(allCompanyList ? allCompanyList[0].defaultAdmin : '');

  const [webType, setwebType] = useState(0);
  const [domain, setdomain] = useState('');
  const [hostLevel, sethostLevel] = useState(0);
  const [hostUri, sethostUri] = useState('');
  const [remark, setremark] = useState('');
  const [ios, setios] = useState('');
  const [android, setandroid] = useState('');
  const [configPortalPage, setconfigPortalPage] = useState('');
  const [configLandingPage, setconfigLandingPage] = useState('');
  const [template, settemplate] = useState('');

  useEffect(() => {
    setIsQuerying(true);
    GetDomainInfo(companyId, () => setIsQuerying(false));
  }, [companyId]);

  useEffect(() => {
    if (_pageFlag === 1 || _pageFlag === 0) {
      setid('');
      setwebType(0);
      setdomain('');
      sethostLevel(0);
      sethostUri('');
      setremark('');
      setios('');
      setandroid('');
      setconfigPortalPage('');
      setconfigLandingPage('');
      settemplate('Default');
    }
    if (_pageFlag === 2 && domainEditInfo) {
      const { domain } = domainEditInfo;
      setid(domain.id ?? '');
      setwebType(domain.webType ?? 0);
      setdomain(domain.domain ?? '');
      sethostLevel(domain.hostLevel ?? 0);
      sethostUri(domain.hostUri ?? '');
      setremark(domain.remark ?? '');
      setios(domain.ios ?? '');
      setandroid(domain?.android ?? '');
      setconfigPortalPage(domain?.configPortalPage ?? '');
      setconfigLandingPage(domain.configLandingPage ?? '');
      settemplate(domain.template ?? '');
    }
  }, [_pageFlag, domainEditInfo]);

  useEffect(() => {
    if (allCompanyList) {
      const temp = [].concat(allCompanyList).find(x => x.companyId === companyId);
      setname(temp.name);
      setdefaultAdmin(temp.defaultAdmin);
    }
  }, [companyId]);

  useEffect(() => {
    if (_checkAll && domainInfo) {
      const _ls = domainInfo.domainList.map(item => item.id);
      setList([..._ls]);
    } else {
      setList([]);
    }
  }, [_checkAll, domainInfo]);

  const saveAndEdit = () => {
    if (_pageFlag === 1 || _pageFlag === 2) {
      const obj = {
        id,
        companyId,
        webType,
        domain,
        hostLevel,
        hostUri,
        remark,
        ios,
        android,
        configPortalPage,
        configLandingPage,
        template
      };
      const callback = () => {
        setPageFlag(0);
        setIsQuerying(true);
        GetDomainInfo(companyId, () => setIsQuerying(false));
      };
      SaveDomainInfo(obj, callback);
    }
  };

  const deleteDomain = id => {
    const callback = () => {
      setIsQuerying(true);
      GetDomainInfo(companyId, () => setIsQuerying(false));
    };
    if (id) {
      DeleteDomainInfo(id, callback);
    } else {
      DeleteDomainInfo(_list.toString(), callback);
    }
  };

  return (
    <div className="CompanyDomainSettingWrapper">
      <CommonHeader
        headerName={(
          <div className="d-flex align-items-center">
            {_pageFlag !== 0 && (
            <div className="d-flex align-items-center me-2 backIcon" onClick={() => setPageFlag(0)}>
              <Icon
                libName="md"
                value={{
                  name: 'MdArrowBack',
                  className: 'h5 mb-0 text-white'
                }}
              />
              返回
            </div>
            )}
            {`${t('COMPANY_HOSTNAME_SETTING')} ${_pageFlag === 0 ? '' : _pageFlag === 1 ? `/ ${defaultAdmin} / 新增` : `/ ${defaultAdmin} / 编辑`}`}
          </div>
)}
      />
      {_pageFlag === 0 && (
      <>
        <div className="addNewGameBox my-3">
          <div className="d-flex align-items-center">
            <div
              className="operationBtn add"
              onClick={() => {
                setPageFlag(1);
              }}
            >
              <div className="plus_icon"><i className="el-icon-plus" /></div>
              新增域名
            </div>
            <div className="mx-2">
              公司域名管理
              <Select
                className="ms-2 dropDownList"
                value={companyId}
                placeholder="请选择"
                onChange={code => setcompanyId(code)}
              >
                {
                     allCompanyList && [].concat(allCompanyList).map(el => (
                       <Select.Option key={el.companyId} value={el.companyId}>
                         {`${el.name}(${el.defaultAdmin})`}
                       </Select.Option>
                     ))
                }
              </Select>
            </div>
          </div>
          <div className="operationBtn add" onClick={() => deleteDomain()}>批量删除</div>
        </div>

        <div className="mx-0 bg-white mb-3 px-0 border-1">
          <AgentDataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
            [{
              label: (
                (<Checkbox onChange={e => setCheckAll(e.target.checked)} checked={_checkAll}>全选</Checkbox>)
              ),
            }, {
              label: '公司名称',
            }, {
              label: '公司账号',
            }, {
              label: '站点类型',
            }, {
              label: '公司域名',
            }, {
              label: '推广级别',
            }, {
              label: '推广导航网址',
            }, {
              label: '备注',
            }, {
              label: '首页模板',
            }, {
              label: t('OPERATION'),
            }]
          }
            tableHeaderStyle={
            []
          }
            tableData={domainInfo ? domainInfo.domainList : []}
            tableBodyRowProperty={
            [
              data => (
                <div className="d-flex justify-content-center">
                  <Checkbox
                    onChange={() => {
                      if (_list.includes(data.id)) {
                        setList(_list.filter(item => item !== data.id));
                      } else {
                        setList([..._list, data.id]);
                      }
                    }}
                    checked={_list.includes(data.id)}
                  />
                </div>
              ),
              () => (<div className="d-flex justify-content-center">{domainInfo ? domainInfo.company.name : ''}</div>),
              () => (<div className="d-flex justify-content-center">{domainInfo ? domainInfo.company.defaultAdmin : ''}</div>),
              data => (<div className="d-flex justify-content-center">{data.webType === 1 ? '会员' : '代理'}</div>),

              data => (<div className="d-flex justify-content-center">{data.domain}</div>),
              data => (<div className="d-flex justify-content-center">{data.hostLevelName}</div>),
              data => (<div className="d-flex justify-content-center">{data.hostUri}</div>),
              data => (<div className="d-flex justify-content-center">{data.remark}</div>),
              data => (<div className="d-flex justify-content-center">{data.template}</div>),
              data => (
                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className="operationBtn mx-2"
                    onClick={() => {
                      getEditDomainInfo({
                        companyId, domainId: data.id
                      }, () => setPageFlag(2));
                    }}
                  >
                    编辑
                  </div>
                  <div className="operationBtn cancel mx-2" onClick={() => { deleteDomain(data.id); }}>删除</div>
                </div>
              )
            ]
          }
          />
        </div>
      </>
      )}
      {_pageFlag !== 0 && (
        <>
          <div className="my-3 thirdPartyGamesTable">
            <div className="thirdPartyGamesTitle">{_pageFlag === 1 ? '新增公司域名' : '编辑公司域名'}</div>
            <div className="thirdPartyGamesRow">
              <div>公司名称</div>
              <div><Input disabled value={name} /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>账号</div>
              <div><Input disabled value={defaultAdmin} /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>站点</div>
              <div>
                <Select className="dropDownList" value={webType} placeholder="请选择" onChange={code => setwebType(code)}>
                  {
                     [{
                       value: 0, label: '代理端'
                     }, {
                       value: 1, label: '会员端'
                     }].map(el => (
                       <Select.Option key={el.value} value={el.value}>
                         {el.label}
                       </Select.Option>
                     ))
                }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>域名</div>
              <div><Input value={domain} onChange={e => setdomain(e.target.value.trim())} placeholder="例如：hao123.com(不能带http://或https://,不能“/“结尾)" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>域名推广级别(仅会员域名有效)</div>
              <div>
                <Select className="dropDownList" value={hostLevel} placeholder="请选择" onChange={code => sethostLevel(code)}>
                  {
                     [{
                       value: 0, label: '共享域名（默认）'
                     }, {
                       value: 1, label: '私有域名（必填推广码，会屏蔽推广信息/合作信息）'
                     }].map(el => (
                       <Select.Option key={el.value} value={el.value}>
                         {el.label}
                       </Select.Option>
                     ))
                }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>首页模板(仅会员域名有效)</div>
              <div>
                <Select className="dropDownList" value={template} placeholder="请选择" onChange={code => settemplate(code)}>
                  {
                     [{
                       value: 'Default', label: '默认'
                     }, {
                       value: 'DSN', label: '迪士尼'
                     }, {
                       value: 'DJH', label: '点金汇'
                     }].map(el => (
                       <Select.Option key={el.value} value={el.value}>
                         {el.label}
                       </Select.Option>
                     ))
                }
                </Select>
              </div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>推广导航网址(仅会员域名有效)</div>
              <div><Input value={hostUri} onChange={e => sethostUri(e.target.value.trim())} placeholder="例如：http://hao123.com(要带http://或https://,不能“/“结尾)" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>备注说明</div>
              <div><Input value={remark} onChange={e => setremark(e.target.value.trim())} placeholder="例如：所属代理" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>iOS参数</div>
              <div><Input value={ios} onChange={e => setios(e.target.value.trim())} placeholder="例如：{liveUrl:'https://hao123.com(要带https://,不能“/“结尾)', downloadUrl:'https://hao123.com(要带https://,不能“/“结尾)', buildNumber:'1.0.0'}" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>安卓参数</div>
              <div><Input value={android} onChange={e => setandroid(e.target.value.trim())} placeholder="例如：{liveUrl:'https://hao123.com(要带https://,不能“/“结尾)', downloadUrl:'https://hao123.com(要带https://,不能“/“结尾)', buildNumber:'1.0.0'}" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>内页其他参数</div>
              <div><Input value={configPortalPage} onChange={e => setconfigPortalPage(e.target.value.trim())} placeholder="请填写IP" /></div>
            </div>
            <div className="thirdPartyGamesRow">
              <div>首页其他参数</div>
              <div><Input value={configLandingPage} onChange={e => setconfigLandingPage(e.target.value.trim())} placeholder="请填写IP" /></div>
            </div>
          </div>
          <div className="thirdPartyGamesFooter">
            <div className="operationBtn editAndSave me-3" onClick={() => saveAndEdit()}>保存</div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDomainSetting);