import { StatusCodes } from 'http-status-codes';

import { SET_AUTO_RESTOCK_LIST_RESULT, SET_MODAL_ALERT } from '../actionTypes';
import { apiService } from '../../../library';

import appActions from '../appActions';

const setBuhuoList = autoRestockList => ({
  type: SET_AUTO_RESTOCK_LIST_RESULT,
  value: autoRestockList
});

const setModalAlert = modal => ({
  type: SET_MODAL_ALERT,
  value: modal
});

const getBuhuoListResult = lotteryType => dispatch => {
  apiService.get(`autoBuhuoSetting/getBuhuoList/${lotteryType}`)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          return dispatch(setBuhuoList(data.result || {}));
        }
      }
    }).catch(error => {
      Promise.reject(error);
    });
};

const getDefaultBuhuoList = (lotteryType, calllback = () => {}) => dispatch => {
  apiService.get(`autoBuhuoSetting/DefaultBuhuoIndex/${lotteryType}`)
    .then(res => {
      const { data, status } = res || {};
      if (status === StatusCodes.OK) {
        if (data.state === 1) {
          calllback(false);
          return dispatch(setBuhuoList(data.result || {}));
        }
      }
    }).catch(error => {
      dispatch(appActions.updateModalAlert({
        visible: false
      }));
      if (error?.response?.data?.state) {
        dispatch(appActions.updateModalAlert({
          visible: true,
          type: 'confirmation',
          data: {
            message: error.response.data.msg
          }
        }));
      }
      calllback(false);
      Promise.reject(error);
    });
};

const saveBuhuoList = (lotteryType, orgId, buhuoList) => dispatch => {
  apiService.post('/autoBuhuoSetting/saveBuhuo', {
    lotteryType,
    orgId,
    buhuoList,
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(getBuhuoListResult(lotteryType));
      dispatch(setModalAlert({
        visible: false,
        type: 'loading',
        data: {
          message: data.msg,
        }
      }));
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const saveDefaultBuhuo = (lotteryType, orgId, buhuoList, calllback = () => {}) => dispatch => {
  apiService.post('/autoBuhuoSetting/SaveDefaultBuhuo', {
    lotteryType,
    orgId,
    buhuoList,
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setModalAlert({
        visible: false,
        type: 'loading',
        data: {
          message: data.msg,
        }
      }));
      calllback();
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    dispatch(appActions.updateModalAlert({
      visible: false
    }));
    if (error?.response?.data?.state) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

export default {
  getBuhuoListResult,
  saveBuhuoList,
  getDefaultBuhuoList,
  saveDefaultBuhuo,
};