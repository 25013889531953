import moment from 'moment';
import 'moment/locale/zh-cn';

const validateNumber = (text, escapeCharacter = [], skipTrim = true, returnType = 'number') => {
  if (!text) return undefined;
  let tempText = skipTrim ? text : text.trim();
  if ((Number.isNaN(Number(text)) || text.includes('.')) && !escapeCharacter.includes(text)) {
    tempText = tempText.split('').map(x => ((Number.isNaN(Number(x)) || x === '.') && !escapeCharacter.includes(x) ? '' : x)).join('');
  }
  return !Number.isNaN(Number(text)) && returnType === 'number' ? parseInt(tempText, 10) : tempText;
};

const renderResultDateCell = (data, language, unix = false) => {
  moment.locale(language === 'ZH' ? 'zh-cn' : 'en');
  const date = unix ? moment.unix(data.lotteryTime).format('YYYY-MM-DD') : moment(data.lotteryTime).format('YYYY-MM-DD');
  const time = `(${moment.weekdays(unix ? moment.unix(data.lotteryTime).days() : moment(data.lotteryTime).days())}) ${unix ? moment.unix(data.lotteryTime).format('HH:mm:ss') : moment(data.lotteryTime).format('HH:mm')}`;
  return [{
    value: date,
    className: 'd-flex align-items-center justify-content-center'
  }, {
    value: time,
    className: 'd-flex align-items-center justify-content-center'
  }];
};

const renderHeXiaoBalls = (preZodiac = '猪', currentZodiac, zodiacTotalBall = 48) => {
  const REDBALL = ['1', '2', '7', '8', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46'];
  const BLUEBALL = ['3', '4', '9', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48'];
  const GREENBALL = ['5', '6', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49'];
  const zodiacList = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'];
  const result = [];
  const startIndex = zodiacList.indexOf(preZodiac) - zodiacList.indexOf(currentZodiac) + 1;
  for (let i = startIndex; i <= zodiacTotalBall; i += 12) {
    let ballStyle = '';
    if (i > 0) {
      if (REDBALL.includes(`${i}`)) {
        ballStyle = 'circle circleRed';
      } else if (BLUEBALL.includes(`${i}`)) {
        ballStyle = 'circle circleBlue';
      } else if (GREENBALL.includes(`${i}`)) {
        ballStyle = 'circle circleGreen';
      }
      result.push({
        value: i, className: `m-auto ${ballStyle}`
      });
    }
  }
  return result;
};


export {
  validateNumber, renderResultDateCell, renderHeXiaoBalls
};