import { Amount, BuHuo, DisplayLabel, InTimeSheetTable, OddsControl, Profit, SubSelection } from '../../../../components';

const ZMT = [
  {
    componentType: SubSelection,
    data: {
      list: [
        {
          menuId: 'count_Z1T',
          menuName: '正1特',
          codeFunc: item => {
            if (Number.isNaN(Number(item.displayName))) {
              return {
                ...item, code: `ZM1${item.code}`, displayName: `${item.displayName.slice(0, 1)}1${item.displayName.slice(1)}`
              };
            }
            return {
              ...item, code: `ZMT1${item.code}`
            };
          }
        },
        {
          menuId: 'count_Z2T',
          menuName: '正2特',
          codeFunc: item => {
            if (Number.isNaN(Number(item.displayName))) {
              return {
                ...item, code: `ZM2${item.code}`, displayName: `${item.displayName.slice(0, 1)}2${item.displayName.slice(1)}`
              };
            }
            return {
              ...item, code: `ZMT2${item.code}`
            };
          }
        },
        {
          menuId: 'count_Z3T',
          menuName: '正3特',
          codeFunc: item => {
            if (Number.isNaN(Number(item.displayName))) {
              return {
                ...item, code: `ZM3${item.code}`, displayName: `${item.displayName.slice(0, 1)}3${item.displayName.slice(1)}`
              };
            }
            return {
              ...item, code: `ZMT3${item.code}`
            };
          }
        },
        {
          menuId: 'count_Z4T',
          menuName: '正4特',
          codeFunc: item => {
            if (Number.isNaN(Number(item.displayName))) {
              return {
                ...item, code: `ZM4${item.code}`, displayName: `${item.displayName.slice(0, 1)}4${item.displayName.slice(1)}`
              };
            }
            return {
              ...item, code: `ZMT4${item.code}`
            };
          }
        },
        {
          menuId: 'count_Z5T',
          menuName: '正5特',
          codeFunc: item => {
            if (Number.isNaN(Number(item.displayName))) {
              return {
                ...item, code: `ZM5${item.code}`, displayName: `${item.displayName.slice(0, 1)}5${item.displayName.slice(1)}`
              };
            }
            return {
              ...item, code: `ZMT5${item.code}`
            };
          }
        },
        {
          menuId: 'count_Z6T',
          menuName: '正6特',
          codeFunc: item => {
            if (Number.isNaN(Number(item.displayName))) {
              return {
                ...item, code: `ZM6${item.code}`, displayName: `${item.displayName.slice(0, 1)}6${item.displayName.slice(1)}`
              };
            }
            return {
              ...item, code: `ZMT6${item.code}`
            };
          }
        }
      ]
    }
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: '1',
          displayName: '01',
          className: 'hk6Ball ball_red',
        },
        {
          code: '2',
          displayName: '02',
          className: 'hk6Ball ball_red',
        }, {
          code: '3',
          displayName: '03',
          className: 'hk6Ball ball_blue',
        }, {
          code: '4',
          displayName: '04',
          className: 'hk6Ball ball_blue',
        }, {
          code: '5',
          displayName: '05',
          className: 'hk6Ball ball_green',
        }, {
          code: '6',
          displayName: '06',
          className: 'hk6Ball ball_green',
        }, {
          code: '7',
          displayName: '07',
          className: 'hk6Ball ball_red',
        }, {
          code: '8',
          displayName: '08',
          className: 'hk6Ball ball_red',
        }, {
          code: '9',
          displayName: '09',
          className: 'hk6Ball ball_blue',
        }, {
          code: '10',
          displayName: '10',
          className: 'hk6Ball ball_blue',
        }, {
          code: '11',
          displayName: '11',
          className: 'hk6Ball ball_green',
        }, {
          code: '12',
          displayName: '12',
          className: 'hk6Ball ball_red',

        }, {
          code: '13',
          displayName: '13',
          className: 'hk6Ball ball_red',
        }, {
          code: '14',
          displayName: '14',
          className: 'hk6Ball ball_blue',
        },
        {
          code: '15',
          displayName: '15',
          className: 'hk6Ball ball_blue',
        }, {
          code: '16',
          displayName: '16',
          className: 'hk6Ball ball_green',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: '17',
          displayName: '17',
          className: 'hk6Ball ball_green',
        },
        {
          code: '18',
          displayName: '18',
          className: 'hk6Ball ball_red',
        }, {
          code: '19',
          displayName: '19',
          className: 'hk6Ball ball_red',
        }, {
          code: '20',
          displayName: '20',
          className: 'hk6Ball ball_blue',
        }, {
          code: '21',
          displayName: '21',
          className: 'hk6Ball ball_green',
        }, {
          code: '22',
          displayName: '22',
          className: 'hk6Ball ball_green',
        }, {
          code: '23',
          displayName: '23',
          className: 'hk6Ball ball_red',
        }, {
          code: '24',
          displayName: '24',
          className: 'hk6Ball ball_red',
        }, {
          code: '25',
          displayName: '25',
          className: 'hk6Ball ball_blue',
        }, {
          code: '26',
          displayName: '26',
          className: 'hk6Ball ball_blue',
        }, {
          code: '27',
          displayName: '27',
          className: 'hk6Ball ball_green',
        }, {
          code: '28',
          displayName: '28',
          className: 'hk6Ball ball_red',
        },
        {
          code: '29',
          displayName: '29',
          className: 'hk6Ball ball_red',
        }, {
          code: '30',
          displayName: '30',
          className: 'hk6Ball ball_blue',
        }, {
          code: '31',
          displayName: '31',
          className: 'hk6Ball ball_blue',
        }, {
          code: '32',
          displayName: '32',
          className: 'hk6Ball ball_green',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 16,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: '33',
          displayName: '33',
          className: 'hk6Ball ball_green',
        },
        {
          code: '34',
          displayName: '34',
          className: 'hk6Ball ball_red',
        }, {
          code: '35',
          displayName: '35',
          className: 'hk6Ball ball_red',
        }, {
          code: '36',
          displayName: '36',
          className: 'hk6Ball ball_blue',
        }, {
          code: '37',
          displayName: '37',
          className: 'hk6Ball ball_blue',
        }, {
          code: '38',
          displayName: '38',
          className: 'hk6Ball ball_green',
        }, {
          code: '39',
          displayName: '39',
          className: 'hk6Ball ball_green',
        }, {
          code: '40',
          displayName: '40',
          className: 'hk6Ball ball_red',
        }, {
          code: '41',
          displayName: '41',
          className: 'hk6Ball ball_blue',
        }, {
          code: '42',
          displayName: '42',
          className: 'hk6Ball ball_blue',
        },
        {
          code: '43',
          displayName: '43',
          className: 'hk6Ball ball_green',
        }, {
          code: '44',
          displayName: '44',
          className: 'hk6Ball ball_green',

        }, {
          code: '45',
          displayName: '45',
          className: 'hk6Ball ball_red',
        }, {
          code: '46',
          displayName: '46',
          className: 'hk6Ball ball_red',
        }, {
          code: '47',
          displayName: '47',
          className: 'hk6Ball ball_blue',
        }, {
          code: '48',
          displayName: '48',
          className: 'hk6Ball ball_blue',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 14,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: '49',
          displayName: '49',
          className: 'hk6Ball ball_green',
        }, {
          code: 'LM1',
          displayName: '正-单',
        }, {
          code: 'LM2',
          displayName: '正-双',
        }, {
          code: 'LM3',
          displayName: '正-大',
        }, {
          code: 'LM4',
          displayName: '正-小',
        }, {
          code: 'LM5',
          displayName: '正-合单',
        }, {
          code: 'LM6',
          displayName: '正-合双',
        }, {
          code: 'LM7',
          displayName: '正-合大',
        }, {
          code: 'LM8',
          displayName: '正-合小',
        },
        {
          code: 'LM9',
          displayName: '正-尾大',
        }, {
          code: 'LM10',
          displayName: '正-尾小',
        },
        {
          code: 'SB11',
          displayName: '正-红波',
          className: 'hk6_sebo redbo',
        }, {
          code: 'SB12',
          displayName: '正-蓝波',
          className: 'hk6_sebo bluebo',
        }, {
          code: 'SB13',
          displayName: '正-绿波',
          className: 'hk6_sebo greenbo',
        },
      ]
    },
  },
];

export default ZMT;