import React, { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { DateRangePicker, Input, Select } from 'element-react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../..';
import { cashActions } from '../../../redux/actions';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  transferFlowsDetail: state.cashReducers.transferFlowsDetail,
});

const mapDispatchToProps = dispatch => ({
  GetTransferFlowsDetail: (obj, callback) => dispatch(cashActions.GetTransferFlowsDetail(obj, callback)),
});

const TransferFlowsDetail = ({ GetTransferFlowsDetail, transferFlowsDetail, data }) => {
  const { t } = useTranslation();
  const [_date, setDate] = useState([moment().subtract(1, 'weeks').toDate(), moment().toDate()]);
  const [_page, setPage] = useState(1);
  const [_rows, setRows] = useState(15);
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [memType, setMemType] = useState(data.level || (data?.memberId ? 1 : 2));
  const [userId, setUserId] = useState(data.userId || data?.memberId);

  let dateRef;

  useEffect(() => {
    const obj = {
      memType, userId, startDate: moment().add(-7, 'days').unix(), endDate: moment().unix(), page: _page, rows: _rows
    };
    GetTransferFlowsDetail(obj, setIsQueryingDetail);
  }, []);

  const onSearchClick = () => {
    const obj = {
      memType, userId, startDate: moment(_date[0]).unix(), endDate: moment(_date[1]).unix(), page: _page, rows: _rows
    };
    setIsQueryingDetail(true);
    GetTransferFlowsDetail(obj, setIsQueryingDetail);
  };

  const onPaginationClick = (pageNum, rows) => {
    const obj = {
      memType, userId, startDate: moment(_date[0]).unix(), endDate: moment(_date[1]).unix(), page: pageNum, rows
    };
    GetTransferFlowsDetail(obj, setIsQueryingDetail);
    setPage(pageNum);
    setRows(rows);
  };

  return (
    <Col className="transferFlowsDetailWrapper p-3">
      <div className="p-2 mb-2 filterWrapper d-flex align-items-center w-100 rounded-top">
        <div className="px-2">用户类型</div>
        <Select
          disabled
          popper-class="select-popper"
          value={memType}
          onChange={value => setMemType(value)}
        >
          {
            [{
              label: '会员', value: 1
            }, {
              label: '代理', value: 2
            }].map(item => (
              <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
            ))
          }
        </Select>
        <Label value={t('STATUS')} className="px-2">账号名称</Label>
        <Input className="mx-1" disabled value={userId} onChange={text => setUserId(text)} />
        <Label type="daterange" value={t('DATE')} className="px-2">{t('DATE')}</Label>
        <DateRangePicker
          className="betQueryDatepicker"
          value={_date}
          isShowTime
          placeholder={t('START_END_DATE')}
          rangeSeparator={` ${t('TO')} `}
          onChange={_date => {
            setDate(_date);
          }}
          ref={e => dateRef = e}
          shortcuts={[{
            text: t('TODAY'),
            onClick: () => {
              setDate([moment().toDate(), moment().toDate()]);
              dateRef.togglePickerVisible();
            }
          }, {
            text: t('THIS_WEEK'),
            onClick: () => {
              setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
              dateRef.togglePickerVisible();
            }
          }, {
            text: t('PREVIOUS_WEEK'),
            onClick: () => {
              setDate([moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
              dateRef.togglePickerVisible();
            }
          }, {
            text: t('PREVIOUS_2_WEEK'),
            onClick: () => {
              setDate([moment().subtract(2, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()]);
              dateRef.togglePickerVisible();
            }
          }]}
        />
        <Button className="searchButton mx-2" onClick={onSearchClick}>{t('FIND')}</Button>
      </div>
      <Row className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: 'ID',
            }, {
              label: '日期',
            }, {
              label: '余额',
            }, {
              label: '提款',
            }, {
              label: '存款',
            }, {
              label: '赚水/反水',
            }, {
              label: '红利',
            }, {
              label: '返还金额',
            }, {
              label: '违规投注',
            }, {
              label: '转入',
            }, {
              label: '游戏转账',
            }, {
              label: '输赢',
            }, {
              label: '处理中',
            }, {
              label: '取款手续费',
            }, {
              label: '存款手续费',
            }]
          }
          tableData={transferFlowsDetail ? transferFlowsDetail.data : []}
          tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.no}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.id}</Label>),
              data => <Label className="px-1">{data.widthDate}</Label>,
              data => (<Label className="d-flex justify-content-center">{data.balance}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.amount100}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.amount200}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.amount300}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.amount400}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.amount700}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.amount701}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.amount800}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.amount899}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.betWinlost}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.amount911}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.fee100}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.fee200}</Label>),
            ]
          }
          tableFooter={[[{
            value: `${t('CURRENT_PAGE_TOTAL')}`,
            colspan: 3,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (transferFlowsDetail && transferFlowsDetail.sum.sumPage.balance) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (transferFlowsDetail && transferFlowsDetail.sum.sumPage.amount100) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (transferFlowsDetail && transferFlowsDetail.sum.sumPage.amount200) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (transferFlowsDetail && transferFlowsDetail.sum.sumPage.amount300) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (transferFlowsDetail && transferFlowsDetail.sum.sumPage.amount400) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (transferFlowsDetail && transferFlowsDetail.sum.sumPage.amount700) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (transferFlowsDetail && transferFlowsDetail.sum.sumPage.amount701) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (transferFlowsDetail && transferFlowsDetail.sum.sumPage.amount800) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (transferFlowsDetail && transferFlowsDetail.sum.sumPage.amount899) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (transferFlowsDetail && transferFlowsDetail.sum.sumPage.betWinlost) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (transferFlowsDetail && transferFlowsDetail.sum.sumPage.amount911) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (transferFlowsDetail && transferFlowsDetail.sum.sumPage.fee100) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (transferFlowsDetail && transferFlowsDetail.sum.sumPage.fee200) || 0,
            colspan: 1,
            className: 'tableSum'
          }
          ]]}
        />
      </Row>
      <Row className="justify-center w-full relative">
        <Pagination totalRecord={(transferFlowsDetail && transferFlowsDetail?.sum?.totalRecord) || 0} onClick={onPaginationClick} isQuery={_isQueryingDetail} />
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferFlowsDetail);