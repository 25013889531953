import React, { useEffect } from 'react';

import { motion, useAnimation } from 'framer-motion';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, commonActions, memberActions } from '../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  isHorizontal: state.appReducers.isHorizontal,
  themeColor: state.appReducers.themeColor,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  logout: loginId => dispatch(memberActions.logout(loginId)),
  updateThemeColor: themeColor => dispatch(appActions.updateThemeColor(themeColor)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateSelectedSubMenu: val => dispatch(commonActions.updateSelectedSubMenu(val)),
});

const SideMenu = ({ isHorizontal, updateModalAlert, menuList, userData, logout, updateSelectedSubMenu }) => {
  const controls = useAnimation();
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  const history = useHistory();

  useEffect(() => {
    if (!isHorizontal) {
      controls.start({
        width: 52,
        transition: {
          type: 'tween',
          ease: 'easeInOut',
          duration: 1,
        }
      });
    } else {
      controls.start({
        width: 0,
        transition: {
          type: 'tween',
          ease: 'easeInOut',
          duration: 1,
        }
      });
    }
  }, [isHorizontal]);

  return (
    <motion.div
      className="sideMenuWrapper"
      initial={{
        width: !isHorizontal ? 52 : 0
      }}
      animate={controls}
    >
      {
          menuList.map(item => (
            <div
              key={`menuitem_${item.label}`}
              className={`menuItem ${path === item.url ? 'selected' : ''}`}
              onClick={() => {
                if (item.url !== '/logout') { if (window.location.pathname !== item.url) { updateSelectedSubMenu(); } history.push(item.url); } else {
                  updateModalAlert({
                    type: 'confirmation',
                    visible: true,
                    data: {
                      message: '确定要退出登录吗?',
                      onConfirmationClick: () => logout(userData.loginId),
                      showCancelButton: true
                    }
                  });
                }
              }}
            >
              <span className="">{t(item.label.toUpperCase()).slice(0, 2)}</span>
              <span className="">{t(item.label.toUpperCase()).slice(2)}</span>
            </div>
          ))
        }
    </motion.div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);