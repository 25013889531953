import React, { useEffect } from 'react';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

// Web Layout
import AuthLayout from './containers/Auth/AuthLayout';
import { authWebRoutes } from './routes';

// Web
import Login from './pages/Login';
import NoMatch from './pages/NoMatch';
import { appActions } from './redux/actions';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  themeColor: state.appReducers.themeColor,
  userInfo: state.memberReducers.userInfo,
});

const mapDispatchToProps = dispatch => ({
  setThemeColor: data => dispatch(appActions.setThemeColor(data)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const App = ({ userData, setThemeColor, themeColor, userInfo, updateModalAlert }) => {
  useEffect(() => {
    const temp = localStorage.getItem('themeColor');
    if (temp) {
      setThemeColor(temp);
    } else {
      localStorage.setItem('themeColor', themeColor);
    }
  }, [themeColor]);

  useEffect(() => {
    if (userInfo && (userInfo?.globalDrawal === 1 || userInfo?.liveDeposite === 1)) {
      updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          showCancelButton: false,
          message: (
            <div>
              {
              userInfo?.globalDrawal === 1 ? <div>公司未进行全局提款设置!</div> : ''
            }
              {
              userInfo?.liveDeposite === 1 ? <div>公司未进行收款线下设置!</div> : ''
            }
            </div>)
        }
      });
    }
  }, [userInfo]);


  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact name="Login" render={props => <Login {...props} />} />
        {
        userData && authWebRoutes.filter(ele => ([].concat(userData.userMenu).find(x => x.id === ele.id))).map(item => <Route path={`/agent${item.path}`} exact name={item.name} render={props => <AuthLayout {...props} />} />)
      }
        {
        (userData && userData.levelValue === 0) && <Route path="/agent/super-user-list" exact name="Super User List" render={props => <AuthLayout {...props} />} />
      }
        <Route
          path="*"
          name="Not Found"
          render={props => (!userData ? (
            <Redirect to={{
              pathname: '/',
            }}
            />
          ) : <NoMatch {...props} />)}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);