import { Amount, BuHuo, DisplayLabel, InTimeSheetTable, OddsControl, Profit } from '../../../../components';

const TWWUXING = [
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '特码尾数',
      tableStyle: 'hk6TouWeiWX',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 10,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'TMWS1',
          displayName: '0尾',
        }, {
          code: 'TMWS2',
          displayName: '1尾',
        }, {
          code: 'TMWS3',
          displayName: '2尾',
        }, {
          code: 'TMWS4',
          displayName: '3尾',
        }, {
          code: 'TMWS5',
          displayName: '4尾',
        }, {
          code: 'TMWS6',
          displayName: '5尾',
        }, {
          code: 'TMWS7',
          displayName: '6尾',
        }, {
          code: 'TMWS8',
          displayName: '7尾',
        }, {
          code: 'TMWS9',
          displayName: '8尾',
        }, {
          code: 'TMWS10',
          displayName: '9尾',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '特码头数',
      tableStyle: 'hk6TouWeiWX',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 5,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'TMTS11',
          displayName: '0头',
        }, {
          code: 'TMTS12',
          displayName: '1头',
        }, {
          code: 'TMTS13',
          displayName: '2头',
        }, {
          code: 'TMTS14',
          displayName: '3头',
        }, {
          code: 'TMTS15',
          displayName: '4头',
        },
      ]
    },
  },
  {
    componentType: InTimeSheetTable,
    data: {
      tableTitle: '五行',
      tableStyle: 'hk6TouWeiWX',
      tableTitleStyle: 'text-center py-2',
      tableHeadertitle: ['号码', '赔率', '总项金额', '总项盈亏', '补货'],
      tableHeadertitleStyle: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      horizontal: true,
      mainColumnLength: 1,
      mainRowLength: 5,
      betItemStyleList: ['column-number', 'column-odds', 'column-amount', 'column-profit', 'column-buhuo'],
      betItemList: [DisplayLabel, OddsControl, Amount, Profit, BuHuo],
      list: [
        {
          code: 'TMWX16',
          displayName: '金',
        }, {
          code: 'TMWX17',
          displayName: '木',
        }, {
          code: 'TMWX18',
          displayName: '水',
        }, {
          code: 'TMWX19',
          displayName: '火',
        }, {
          code: 'TMWX20',
          displayName: '土',
        },
      ]
    },
  },
];

export default TWWUXING;