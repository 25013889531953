import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { Radio } from 'element-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AgentDataTable from '../../AgentDataTable';

import { appActions, thirdPartyGamesActions } from '../../../redux/actions';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  thirdPartyRebateSetting: state.thirdPartyGamesReducers.thirdPartyRebateSetting,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  getRebateSetting: (orgId, userType) => dispatch(thirdPartyGamesActions.GetRebateSetting(orgId, userType)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveRebateSetting: obj => dispatch(thirdPartyGamesActions.SaveRebateSetting(obj)),
  resetData: () => dispatch(thirdPartyGamesActions.ResetData()),
  syncRebateSetting: obj => dispatch(thirdPartyGamesActions.SyncRebateSetting(obj)),
});

const ThirdPartyRebate = ({ getRebateSetting, data, thirdPartyRebateSetting, updateModalAlert, saveRebateSetting, resetData, syncRebateSetting }) => {
  const { t } = useTranslation();
  const [_platetInput, setPlateInput] = useState('');
  const [_selectedSearchOption, setSearchOption] = useState(1);
  const [_tempData, setTempData] = useState([]);
  let bufferData = [];

  useEffect(() => {
    // Default search when first load
    getRebateSetting(data?.orgId, data?.orgId ? 2 : 1);
  }, []);

  useEffect(() => {
    let isMounted = true;
    fetchData();
    return () => {
      resetData();
      isMounted = false;
    };
    function fetchData() {
      if (thirdPartyRebateSetting && thirdPartyRebateSetting?.rebateList && isMounted) {
        const list = [...new Map(thirdPartyRebateSetting?.rebateList.map(item => [JSON.stringify(item), item])).values()];
        const parent = [...new Map(thirdPartyRebateSetting?.parentRebateList.map(item => [JSON.stringify(item), item])).values()];
        [...new Map(thirdPartyRebateSetting?.rebateList.map(item => [JSON.stringify(item), item])).values()].forEach((curr, myInd) => {
          if (parent) {
            const index = parent?.findIndex(x => x.gameCode === curr.gameCode);
            list[myInd].parentSetting = parent[index];
          }
          list[myInd].gameName = thirdPartyRebateSetting?.thirdPartyGames?.filter(x => x?.gameCode === curr?.gameCode)[0]?.gameName;
        });

        const obj = [...new Map(list.map(item => [JSON.stringify(item), item])).values()];
        bufferData = obj;
        setTempData([...obj]);
      }
    }
  }, [thirdPartyRebateSetting]);


  useEffect(() => {
    if (bufferData) {
      bufferData = _tempData;
    }
  }, [_tempData]);

  const onValueChange = (event, data, field, maxValue) => {
    let newItems = [];
    newItems = [...bufferData];
    const valToChange = Number(event.target.value) > maxValue ? maxValue : Number(event.target.value);
    const index = Number(bufferData?.findIndex(item => item.id === data.id));
    if (index !== -1) {
      newItems[index][field] = valToChange;
      newItems = addHighLightField(newItems, field, index);
      newItems[index].isChange = true;
      setTempData(newItems);
    }
  };


  const saveButton = () => {
    const res = _tempData.filter(item => item?.isChange === true);
    if (res.length > 0) {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const obj = {
        id: data?.orgId,
        settingList: res,
        userType: data?.orgId ? 2 : 1,
        saveType: _selectedSearchOption,
      };
      saveRebateSetting(obj);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '没有变更'
        }
      });
    }
  };

  const syncButton = thisId => {
    const res = _tempData.filter(item => item?.id === thisId);
    if (res.length > 0) {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const obj = {
        id: data?.orgId,
        settingList: res,
        userType: data?.orgId ? 2 : 1,
        saveType: _selectedSearchOption,
      };
      syncRebateSetting(obj);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '没有变更'
        }
      });
    }
  };

  const resetDefault = () => {
    getRebateSetting(data?.orgId, data?.orgId ? 2 : 1);
  };

  const setAllPlate = () => {
    const newProjects = [..._tempData];
    const field = 'aReturn';
    _tempData.forEach((curr, myInd) => {
      if (curr.parentSetting) {
        const after = Number(curr?.parentSetting[field]) - Math.abs(Number(_platetInput));
        newProjects[myInd][field] = after > 0 ? (Number.isInteger(after) ? after : after.toFixed(1)) : 0;
        newProjects[myInd].isChange = true;
        addHighLightField(newProjects, field, myInd);
      }
    });
    setTempData(newProjects);
  };

  const header = [{
    label: '项目名称'
  },
  {
    label: `A${t('PLATE')}%`
  },
  {
    label: `B${t('PLATE')}%`
  },
  {
    label: `C${t('PLATE')}%`
  },
  {
    label: `D${t('PLATE')}%`
  }];

  const rdSearchOptions = [{
    label: '不增加',
    value: 1,
  },
  {
    label: '只代理',
    value: 2,
  },
  {
    label: '代理和会员',
    value: 3,
  }];

  function addHighLightField(item, field, index) {
    switch (field) {
      case 'aReturn':
        item[index].isChangeA = true;
        break;
      case 'bReturn':
        item[index].isChangeB = true;
        break;
      case 'cReturn':
        item[index].isChangeC = true;
        break;
      case 'dReturn':
        item[index].isChangeD = true;
        break;
      case 'projectLimit':
        item[index].isChangeE = true;
        break;
      case 'itemLimit':
        item[index].isChangeF = true;
        break;
      default: break;
    }
    return item;
  }

  const content = [
    data => (<Label className="d-flex justify-content-center">{data.gameName}</Label>),
    data => (
      <div className="center-block align-items-center justify-content-center">
        <Input className={`lotteryName smallInput ${data?.isChangeA && 'isChange'}`} name={data?.code} onChange={e => onValueChange(e, data, 'aReturn', data?.parentSetting && data?.parentSetting.aReturn)} type="Number" value={data?.aReturn} />
        {data?.parentSetting ? (
          <Label>{`(${data?.parentSetting.aReturn})`}</Label>
        ) : <Label>(0)</Label>}
        {data?.levelValue !== 1 && data?.parentSetting && (
          <Label className="lotteryName negativeValue">
            {`您当前
            ${data?.gameName}
            退水为
            ${data?.parentSetting.aReturn}
            %，您下级
            ${data?.gameName}
            退水为
            ${data?.aReturn}
            %，下级投注10000元，您预期可获得收益10000 * (
            ${data?.parentSetting.aReturn}% - ${data?.aReturn}%) = ${(10000 * ((Number(data?.parentSetting.aReturn) - Number(data?.aReturn)) / 100)).toFixed(2)}元`}
          </Label>
        )}
      </div>
    ),
    data => (
      <div className="center-block align-items-center justify-content-center">
        {data?.parentSetting && data.bReturn !== 0 && (
          <>
            <Input className={`lotteryName smallInput ${data?.isChangeB && 'isChange'}`} name={data?.code} onChange={e => onValueChange(e, data, 'bReturn', data?.parentSetting && data?.parentSetting.bReturn)} type="Number" value={data?.bReturn} />
            {data?.parentSetting ? (
              <Label>{`(${data?.parentSetting.bReturn})`}</Label>
            ) : <Label>(0)</Label>}
          </>
        )}
      </div>
    ),
    data => (
      <div className="center-block align-items-center justify-content-center">
        {data?.parentSetting && data.cReturn !== 0 && (
          <>
            <Input className={`lotteryName smallInput ${data?.isChangeC && 'isChange'}`} name={data?.code} onChange={e => onValueChange(e, data, 'cReturn', data?.parentSetting && data?.parentSetting.cReturn)} type="Number" value={data?.cReturn} />
            {data?.parentSetting ? (
              <Label>{`(${data?.parentSetting.cReturn})`}</Label>
            ) : <Label>(0)</Label>}
          </>
        )}
      </div>
    ),
    data => (
      <div className="center-block align-items-center justify-content-center">
        {data?.parentSetting && data.dReturn !== 0 && (
          <>
            <Input className={`lotteryName smallInput ${data?.isChangeD && 'isChange'}`} name={data?.code} onChange={e => onValueChange(e, data, 'dReturn', data?.parentSetting && data?.parentSetting.dReturn)} type="Number" value={data?.dReturn} />
            {data?.parentSetting ? (
              <Label>{`(${data?.parentSetting.dReturn})`}</Label>
            ) : <Label>(0)</Label>}
          </>
        )}
      </div>
    )
  ];

  return (
    <Col className="ThirdPartyRebateWrapper p-0 m-0">
      <Row className="m-0 p-0 my-2">
        <Col className="p-1 px-2 m-0 filterWrapper">
          <div className="d-flex">
            <Label className="justify-content-center p-1">赚取退水：</Label>
            <Input className="mx-2" type="number" value={_platetInput} onChange={({ target: { value: text } }) => setPlateInput(text)} />
            <Button className="button generate mx-1" onClick={() => setAllPlate()}>{t('CONFIRM')}</Button>
            <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
            <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
            <Label className="justify-content-center marginLeft5 p-1">保存时下线是否等量增加：</Label>
            <div
              className="d-flex px-2 py-1 rdSearchOptionsGroup"
            >
              {
                rdSearchOptions.map(item => (
                  <div key={`rdSearchOptionsGroup_${item.value}`}>
                    <Input
                      id={`rdSearchOptions_${item.value}`}
                      type="radio"
                      name="rdSearchOptions"
                      value={item.value}
                      checked={_selectedSearchOption === item.value}
                      onChange={() => setSearchOption(item.value)}
                      className="mx-2"
                    />
                    <Label
                      for={`rdSearchOptions_${item.value}`}
                      className={_selectedSearchOption === item.value ? 'active' : ''}
                    >
                      {item.label}
                    </Label>
                  </div>
                ))
              }
            </div>
          </div>
        </Col>
      </Row>
      {
        _tempData && _tempData.map(item => (
          <div key={`games_${item?.gameCode}`}>
            <Row className="m-0 p-0 my-2">
              <Col className="col-1 px-1 m-0 lotteryMenu">
                <div
                  className="px-2 d-flex radiobuttongroup"
                >
                  <Radio
                    key={`games_${item?.gameCode}`}
                    value={item?.gameCode}
                    type="radio"
                    name="rdGames"
                    checked
                  >
                    {item?.gameName}
                  </Radio>
                </div>
              </Col>
              <Col className="p-1 m-0">
                <Button className="button handle mx-1" onClick={() => syncButton(item?.id)}>等量同步下线</Button>
              </Col>
            </Row>
            <Row className="bigWrapper m-0 my-2 p-2">
              <Col className="p-1">
                <AgentDataTable
                  tableHeaderTitle={header}
                  tableData={_tempData.filter(y => y?.id === item.id) || []}
                  tableBodyRowProperty={content}
                  tableBodyRowStyle={
                    ['backgroundGrey']
                  }
                  tableHeaderStyle={
                    ['col-2', 'col-2', 'col-2', 'col-2', 'col-2']
                  }
                />
              </Col>
            </Row>
          </div>
        ))
      }
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyRebate);