import React, { useEffect, useState } from 'react';

import { Checkbox, Input, Radio, Select } from 'element-react';
import { connect } from 'react-redux';

import { agentActions, appActions, companyActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  agentEditInfo: state.agentReducers.agentEditInfo,
  oddsDiffUpOrgsData: state.companyReducers.oddsDiffUpOrgsData,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
});

const mapDispatchToProps = dispatch => ({
  GetEditAgentInfo: (id, callback) => dispatch(agentActions.GetEditAgentInfo(id, callback)),
  SaveAgentInfo: (obj, callback) => dispatch(agentActions.SaveAgentInfo(obj, callback)),
  getOddsDiffUpOrgs: orgId => dispatch(companyActions.getOddsDiffUpOrgs(orgId)),
  isValidName: (userId, callback) => dispatch(agentActions.isValidName(userId, callback)),
  isExistLoginId: (userId, callback) => dispatch(agentActions.isExistLoginId(userId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const AgentEdit = ({ updateModalAlert, data, oddsDiffUpOrgsData, isExistLoginId, isValidName, agentEditInfo, getOddsDiffUpOrgs, GetEditAgentInfo, SaveAgentInfo, callback, isUpdate = true }) => {
  const [password, setpassword] = useState('');
  const [name, setname] = useState();
  const [loginId, setLoginId] = useState();
  const [accountStatus, setaccountStatus] = useState(1);
  const [levelValue, setlevelValue] = useState();
  const [vipLevel, setvipLevel] = useState();
  const [gameType, setgameType] = useState();
  const [multiOdd, setmultiOdd] = useState();
  const [hostDomain, sethostDomain] = useState();
  const [familyName, setfamilyName] = useState();
  const [email, setemail] = useState();
  const [phone, setphone] = useState();
  const [depositStatus, setdepositStatus] = useState();
  const [withdrawStatus, setwithdrawStatus] = useState();
  const [depositMaxAmount, setdepositMaxAmount] = useState();
  const [depositMaxMin, setdepositMaxMin] = useState();
  const [withdrawMaxAmount, setwithdrawMaxAmount] = useState();
  const [withdrawMinAmount, setwithdrawMinAmount] = useState();
  const [lotteryAssigned, setlotteryAssigned] = useState();
  const [selectedMap, setSelectedMap] = useState(new Map());
  const [totalLottery, setTotalLottery] = useState(new Map());
  const [orgZcList, setorgZcList] = useState();
  const [thirdPartyGamesZc, setthirdPartyGamesZc] = useState();
  const [parentLotteryAssigned, setParentLotteryAssigned] = useState();

  const [isValidLoginIdMsg, setisValidLoginIdMsg] = useState();
  const [isValidNameMsg, setisValidNameMsg] = useState();
  const [copyOddsDiffOrgId, setcopyOddsDiffOrgId] = useState(-1);

  useEffect(() => {
    if (data) {
      GetEditAgentInfo(data.orgId);
      if (isUpdate === false && data.levelValue === 1) {
        getOddsDiffUpOrgs(data.orgId);
      }
    }
  }, []);

  useEffect(() => {
    if (agentEditInfo) {
      if (agentEditInfo?.lotteryList && agentEditInfo?.upLotteryList) {
        const mapAll = new Map();
        const mapSelected = new Map();
        const ls = [].concat(agentEditInfo.lotteryList);
        const allLs = [].concat(agentEditInfo?.upLotteryList);
        if (agentEditInfo.orgParent.lotteryAssigned !== 0 && isUpdate) {
          for (let i = 0; i < ls.length; i++) {
            const temp = allLs.find(x => (x.code === ls[i]));
            if (temp) {
              if (mapSelected.has(temp.categoryCode)) {
                const tempArr = mapSelected.get(temp.categoryCode);
                mapSelected.set(temp.categoryCode, tempArr.concat(temp));
              } else {
                mapSelected.set(temp.categoryCode, [].concat(temp));
              }
            }
          }
        }

        allLs.forEach(item => {
          if (mapAll.has(item.categoryCode)) {
            const temp = mapAll.get(item.categoryCode);
            mapAll.set(item.categoryCode, temp.concat(item));
          } else {
            mapAll.set(item.categoryCode, [].concat(item));
          }
        });

        const tempArr = agentEditInfo?.orgZcList.filter(x => mapAll.has(x.categoryCode)).map(item => {
          if (isUpdate === true) {
            const temp = agentEditInfo?.parentOrgZcList.find(x => x.categoryCode === item.categoryCode);
            return ({
              ...item, parent: temp.avairableRateForDown
            }
            );
          }
          return ({
            ...item, avairableRateForDown: 0, parent: item.avairableRateForDown
          }
          );
        });

        const tempThirdPartyArr = agentEditInfo?.thirdPartyGamesZc.filter(item => agentEditInfo?.thirdPartyGames.find(x => x.gameCode === item.gameCode)).map(item => {
          const name = agentEditInfo?.thirdPartyGames.find(x => x.gameCode === item.gameCode)?.gameName;
          if (isUpdate === true) {
            const temp = agentEditInfo?.upThirdPartyGamesZc.find(x => x.gameCode === item.gameCode);
            return ({
              ...item, thirdPartyName: name, thirdPartyParent: temp.zc
            }
            );
          }
          return ({
            ...item, zc: 0, thirdPartyName: name, thirdPartyParent: item.zc
          });
        });

        setorgZcList(tempArr);
        setthirdPartyGamesZc(tempThirdPartyArr);
        setTotalLottery(mapAll);
        setSelectedMap(mapSelected);
      }
      if (agentEditInfo?.orgParent) {
        const tempOrgParent = agentEditInfo.orgParent;
        if (isUpdate === true) {
          setParentLotteryAssigned(tempOrgParent.lotteryAssigned);
        } else {
          setParentLotteryAssigned(tempOrgParent.lotteryAssigned);
        }
      }
      if (agentEditInfo?.editOrg) {
        const tempEditOrg = agentEditInfo.editOrg;
        setname(isUpdate ? tempEditOrg?.name : '');
        setLoginId(isUpdate ? tempEditOrg?.loginId : '');
        setaccountStatus(tempEditOrg?.accountStatus ?? 1);
        setgameType(tempEditOrg?.gameType);
        setmultiOdd(tempEditOrg?.multiOdd);
        setlotteryAssigned(tempEditOrg?.lotteryAssigned);
      }
      if (agentEditInfo?.cashMember) {
        const tempCashMember = agentEditInfo.cashMember;
        setlevelValue(tempCashMember?.levelValue);
        setvipLevel(tempCashMember?.vipLevel);
        sethostDomain(isUpdate ? tempCashMember?.hostDomain : '');
        setfamilyName(tempCashMember?.familyName);
        setemail(tempCashMember?.email);
        setphone(tempCashMember?.phone);
        setdepositStatus(isUpdate ? tempCashMember?.depositStatus : 0);
        setwithdrawStatus(isUpdate ? tempCashMember?.withdrawStatus : 0);

        setdepositMaxAmount(isUpdate ? tempCashMember?.depositMaxAmount : 0);
        setdepositMaxMin(isUpdate ? tempCashMember?.depositMaxMin : 0);
        setwithdrawMaxAmount(isUpdate ? tempCashMember?.withdrawMaxAmount : 0);
        setwithdrawMinAmount(isUpdate ? tempCashMember?.withdrawMinAmount : 0);
      }
    }
  }, [agentEditInfo]);

  const toggleAllSelection = code => {
    const temp = new Map(JSON.parse(
      JSON.stringify(Array.from(selectedMap))
    ));
    if (temp.has(code)) {
      temp.delete(code);
    } else {
      const tempItem = totalLottery.get(code);
      temp.set(code, [].concat(tempItem));
    }
    setSelectedMap(temp);
  };

  const toggleSelection = item => {
    const temp = new Map(JSON.parse(
      JSON.stringify(Array.from(selectedMap))
    ));
    if (temp.has(item.categoryCode)) {
      if (temp.get(item.categoryCode).find(x => x.code === item.code)) {
        temp.set(item.categoryCode, temp.get(item.categoryCode).filter(x => x.code !== item.code));
      } else {
        temp.set(item.categoryCode, temp.get(item.categoryCode).concat(item));
      }
    } else {
      temp.set(item.categoryCode, [].concat(item));
    }
    setSelectedMap(temp);
  };

  const saveAgentInfo = (validType = {}) => {
    const changData = () => {
      updateModalAlert({
        visible: true,
        type: 'loading',
      });
      const lotteryType = [];
      const sharesList = [];
      const thirdPartySharesList = [];
      if (selectedMap.size > 0) {
        selectedMap.forEach(item => {
          item.forEach(sub => {
            lotteryType.push(sub.code);
          });
        });
      }
      if (orgZcList) {
        orgZcList.forEach(item => {
          const obj = {};
          obj.categoryCode = item.categoryCode;
          obj.avairableRateForDown = item.avairableRateForDown;
          sharesList.push(obj);
        });
      }
      if (thirdPartyGamesZc) {
        thirdPartyGamesZc.forEach(item => {
          const obj = {};
          obj.GameCode = item.gameCode;
          obj.Zc = item.zc;
          thirdPartySharesList.push(obj);
        });
      }
      const obj = {
        isUpdate,
        org: {
          ...agentEditInfo.editOrg,
          password,
          name,
          loginId,
          accountStatus,
          lotteryAssigned,
          gameType,
          multiOdd,
          levelValue: isUpdate ? agentEditInfo.editOrg.levelValue : agentEditInfo.editOrg.levelValue + 1,
          parentId: isUpdate ? agentEditInfo.editOrg.parentId : agentEditInfo.editOrg.organizationId,
          organizationId: isUpdate ? agentEditInfo.editOrg.organizationId : null,
          replenishmentType: 1,
          rateSurplus: 1,
          zcType: 1,
          moneyLimit: 0,
          ...validType
        },
        userInfo: {
          ...agentEditInfo.cashMember,
          levelValue,
          vipLevel,
          bindHost: hostDomain,
          familyName: isUpdate ? familyName : '',
          safePhone: isUpdate ? phone : '',
          safeEmail: isUpdate ? email : '',
          depositStatus,
          depositMaxAmount,
          depositMaxMin,
          withdrawStatus,
          withdrawMaxAmount,
          withdrawMinAmount
        },
        lotteryType,
        sharesList,
        thirdPartySharesList
      };
      SaveAgentInfo(obj, callback);
    };

    updateModalAlert({
      type: 'confirmation',
      visible: true,
      data: {
        message: '请确认是否要修改/设置',
        onConfirmationClick: () => changData()
      }
    });
  };

  return (
    <div className="agentEditWrapper">
      <div className="sectionWrapper">
        <div className="tableTitleStyle">账户资料</div>
        <div className="d-flex">
          <div className="tableSection labelCellBackground">
            <div className="rowStyle">
              <div>上级代理</div>
              <div>{agentEditInfo && (isUpdate ? agentEditInfo?.orgParent?.loginId : agentEditInfo?.editOrg?.loginId)}</div>
            </div>
            <div className="rowStyle">
              <div>代理账号</div>
              <div>
                <Input disabled={isUpdate} value={loginId} onChange={text => setLoginId(text)} onBlur={() => isExistLoginId(loginId, setisValidLoginIdMsg)} />
                {isValidLoginIdMsg && <span className={`ms-2 ${isValidLoginIdMsg.state === 1 ? 'text-success' : 'text-danger'}`}>{isValidLoginIdMsg.msg}</span>}
              </div>
            </div>
            <div className="rowStyle">
              <div>代理名称</div>
              <div>
                <Input value={name} onChange={text => setname(text.trim())} onBlur={() => isValidName(name, setisValidNameMsg)} />
                {isValidNameMsg && <span className={`ms-2 ${isValidNameMsg.state === 1 ? 'text-success' : 'text-danger'}`}>{isValidNameMsg.msg}</span>}
              </div>
            </div>
            <div className="rowStyle">
              <div>登入密码</div>
              <div><Input value={password} onChange={text => setpassword(text.trim())} /></div>
            </div>
            <div className="rowStyle">
              <div>帐号状态</div>
              <div>
                <Radio.Group value={accountStatus} onChange={val => setaccountStatus(val)}>
                  <Radio value={1}>启用</Radio>
                  <Radio value={2}>暂停</Radio>
                  <Radio value={0}>停用</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="rowStyle">
              <div>用户类型</div>
              <div>
                <Select value={levelValue} onChange={val => setlevelValue(val)}>
                  {
                  [{
                    label: '1类', value: 1
                  }, {
                    label: '2类', value: 2
                  }, {
                    label: '3类', value: 3
                  }, {
                    label: '4类', value: 4
                  }, {
                    label: '5类', value: 5
                  }, {
                    label: '6类', value: 6
                  }, {
                    label: '7类', value: 7
                  }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                }
                </Select>
              </div>
            </div>
            <div className="rowStyle">
              <div>VIP 级别</div>
              <div>
                <Select value={vipLevel} onChange={val => setvipLevel(val)}>
                  {
                  [{
                    label: 'VIP 1', value: 1
                  }, {
                    label: 'VIP 2', value: 2
                  }, {
                    label: 'VIP 3', value: 3
                  }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                }
                </Select>
              </div>
            </div>
            {isUpdate && (
            <div className="rowStyle">
              <div>余额</div>
              <div>
                {agentEditInfo && agentEditInfo?.editOrg?.balance.toFixed(1)}
              </div>
            </div>
            )}
            <div className="rowStyle">
              <div>开放盘口</div>
              <div>
                <Checkbox.Group value={gameType ? gameType.split('').map(item => item) : []} onChange={val => setgameType(val.toString())}>
                  <Checkbox label="A盘" value="A" />
                  <Checkbox label="B盘" value="B" />
                  <Checkbox label="C盘" value="C" />
                  <Checkbox label="D盘" value="D" />
                </Checkbox.Group>
              </div>
            </div>
            {((isUpdate === false && agentEditInfo?.editOrg?.levelValue === 1) || isUpdate)
            && (
            <div className="rowStyle">
              <div>允许多赔率</div>
              <div>
                <Radio.Group value={multiOdd} onChange={val => setmultiOdd(val)}>
                  <Radio value={1}>允许</Radio>
                  <Radio value={0}>不允许</Radio>
                </Radio.Group>
              </div>
            </div>
            )}
            {(agentEditInfo?.editOrg?.levelValue === 1)
            && (
            <div className="rowStyle">
              <div>退水赔率差复制</div>
              <div>
                <Select value={copyOddsDiffOrgId} onChange={val => setcopyOddsDiffOrgId(val)}>
                  {
                      oddsDiffUpOrgsData && [{
                        name: '系统默认', organizationId: -1
                      }].concat(oddsDiffUpOrgsData).map(el => <Select.Option key={el.value} label={`从${el.name}复制`} value={el.organizationId} />)
                    }
                </Select>
              </div>
            </div>
            )}
            <div className="rowStyle">
              <div>推广域名</div>
              <div>
                <Input value={hostDomain} onChange={text => sethostDomain(text.trim())} />
                <span className="ms-3">(不用加 http://, www 例如:baidu.com)</span>
              </div>
            </div>
            {isUpdate && (
            <>
              <div className="rowStyle">
                <div>推广链接</div>
                <div><Input value={agentEditInfo && agentEditInfo?.adLink} disabled /></div>
              </div>
              <div className="rowStyle">
                <div>APP推广链接</div>
                <div><Input value={agentEditInfo && agentEditInfo?.appLink} disabled /></div>
              </div>
              <div className="rowStyle">
                <div>推广码</div>
                <div><Input value={agentEditInfo && agentEditInfo?.cashMember?.affCode} disabled /></div>
              </div>
            </>
            )}
            <div className="rowStyle">
              <div>MFA验证</div>
              <div>
                {
                agentEditInfo && agentEditInfo?.editOrg?.validType === 1 ? (
                  <div
                    className="operationBtn reset"
                    onClick={() => saveAgentInfo({
                      validType: 0
                    })}
                  >
                    重置
                  </div>
                ) : '未设置'
                }
              </div>
            </div>
          </div>
          {((agentEditInfo && agentEditInfo?.curUserLevel === 1) || isUpdate === false) && (
          <div className="tableSection border-start-0 labelCellBackground">
            {isUpdate && (
            <>
              <div className="rowStyle">
                <div>姓名</div>
                <div>
                  <Input value={familyName} onChange={text => setfamilyName(text)} />
                </div>
              </div>
              <div className="rowStyle">
                <div>生日</div>
                <div>
                  <Input disabled />
                </div>
              </div>
              <div className="rowStyle">
                <div>微信</div>
                <div>
                  <Input disabled />
                </div>
              </div>
              <div className="rowStyle">
                <div>安全手机</div>
                <div>
                  <Input value={phone} onChange={text => setphone(text)} />
                </div>
              </div>
              <div className="rowStyle">
                <div>安全邮箱</div>
                <div>
                  <Input value={email} onChange={text => setemail(text)} />
                </div>
              </div>
              <div className="rowStyle">
                <div>收货人</div>
                <div>
                  <Input disabled />
                </div>
              </div>
              <div className="rowStyle">
                <div>收货地址（省市区）</div>
                <div>
                  <Input disabled />
                </div>
              </div>
              <div className="rowStyle">
                <div>收货地址（街道）</div>
                <div>
                  <Input disabled />
                </div>
              </div>
              <div className="rowStyle">
                <div>联系方式</div>
                <div>
                  <Input disabled />
                </div>
              </div>
            </>
            )}
            <div className="rowStyle">
              <div>存款状态</div>
              <div>
                <Radio.Group value={depositStatus} onChange={val => setdepositStatus(val)}>
                  <Radio value={0}>允许</Radio>
                  <Radio value={1}>不允许</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="rowStyle">
              <div>单次最大存款金额</div>
              <div>
                <Input
                  value={depositMaxAmount}
                  onChange={text => {
                    if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                      setdepositMaxAmount(text === '' || parseInt(text, 10) < 0 ? 0 : parseInt(text, 10));
                    }
                  }}
                />
                <span className="ms-3">(0则不限制)</span>
              </div>
            </div>
            <div className="rowStyle">
              <div>单次最小存款金额</div>
              <div>
                <Input
                  value={depositMaxMin}
                  onChange={text => {
                    if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                      setdepositMaxMin(text === '' || parseInt(text, 10) < 0 ? 0 : parseInt(text, 10));
                    }
                  }}
                />
              </div>
            </div>
            <div className="rowStyle">
              <div>提款状态</div>
              <div>
                <Radio.Group value={withdrawStatus} onChange={val => setwithdrawStatus(val)}>
                  <Radio value={0}>允许</Radio>
                  <Radio value={1}>不允许</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="rowStyle">
              <div>单次最大提款金额</div>
              <div>
                <Input
                  value={withdrawMaxAmount}
                  onChange={text => {
                    if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                      setwithdrawMaxAmount(text === '' || parseInt(text, 10) < 0 ? 0 : parseInt(text, 10));
                    }
                  }}
                />
                <span className="ms-3">(0则不限制)</span>
              </div>
            </div>
            <div className={`rowStyle ${!isUpdate && 'border-bottom'}`}>
              <div>单次最小提款金额</div>
              <div>
                <Input
                  value={withdrawMinAmount}
                  onChange={text => {
                    if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                      setwithdrawMinAmount(text === '' || parseInt(text, 10) < 0 ? 0 : parseInt(text, 10));
                    }
                  }}
                />
              </div>
            </div>
          </div>
          )}

        </div>
      </div>
      {(parentLotteryAssigned !== 0)
      && (
      <div className="sectionWrapper">
        <div className="tableTitleStyle">彩种选择</div>
        <div className="tableSection labelCellBackground">
          <div className="rowStyle">
            <div>开放彩种管理给下线</div>
            <div>
              <Radio.Group value={lotteryAssigned} onChange={val => setlotteryAssigned(val)}>
                <Radio value={1}>允许</Radio>
                <Radio value={0}>不允许</Radio>
              </Radio.Group>
            </div>
          </div>
          {Array.from(totalLottery.values()).map(item => (
            <div className="rowStyle">
              <div>
                <span className="me-3">{item[0].categoryName}</span>
                <Checkbox onChange={() => toggleAllSelection(item[0].categoryCode)} checked={(selectedMap.size > 0 && selectedMap.has(item[0].categoryCode)) ? (selectedMap.get(item[0].categoryCode).length === totalLottery.get(item[0].categoryCode).length) : false}>所有</Checkbox>
              </div>
              <div>
                {
                  item.map(ele => <Checkbox checked={selectedMap.size > 0 ? selectedMap.get(ele.categoryCode)?.find(x => x.code === ele.code) : false} onChange={() => toggleSelection(ele)} className="lotterySelection my-1">{ele.lotteryName}</Checkbox>)
                }
              </div>
            </div>
          ))}
        </div>
      </div>
      )}
      <div className="sectionWrapper">
        <div className="tableTitleStyle">占成设置</div>
        <div className="tableSection">
          <div className="rowStyle bg-grey">
            <div>类型</div>
            <div>占成</div>
          </div>
          {
            (totalLottery.size > 0 && orgZcList) && orgZcList.map((item, index) => (
              <div className="rowStyle">
                <div>{totalLottery.get(item.categoryCode)[0].categoryName}</div>
                <div>
                  <Input
                    value={item.avairableRateForDown}
                    onChange={text => {
                      if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                        const temp = [].concat(orgZcList);
                        temp[index].avairableRateForDown = (text === '' || parseInt(text, 10) < 0) ? '' : parseInt(text, 10) > item.parent ? temp[index].avairableRateForDown : parseInt(text, 10);
                        setorgZcList(temp);
                      }
                    }}
                  />
                  <span className="ms-3">
                    {`(最大${item.parent}%)`}
                  </span>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div className="sectionWrapper">
        <div className="tableTitleStyle">占成设置</div>
        <div className="tableSection">
          <div className="rowStyle bg-grey">
            <div>类型</div>
            <div>占成</div>
          </div>
          {
            thirdPartyGamesZc && thirdPartyGamesZc.map((item, index) => (
              <div className="rowStyle">
                <div>{item.thirdPartyName}</div>
                <div>
                  <Input
                    value={item.zc}
                    onChange={text => {
                      if (!Number.isNaN(parseInt(text, 10)) || text === '') {
                        const temp = [].concat(thirdPartyGamesZc);
                        temp[index].zc = (text === '' || parseInt(text, 10) < 0) ? '' : parseInt(text, 10) > item.thirdPartyParent ? temp[index].zc : parseInt(text, 10);
                        setthirdPartyGamesZc(temp);
                      }
                    }}
                  />
                  <span className="ms-3">
                    {`(最大${item.thirdPartyParent}%)`}
                  </span>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div className="d-flex justify-content-center py-3">
        <div className="operationBtn" onClick={() => saveAgentInfo()}>保存</div>
        <div className="operationBtn ms-3 cancel" onClick={() => callback()}>取消</div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentEdit);