import React, { useEffect, useState } from 'react';

import { Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AgentDataTable, Pagination } from '../..';
import { thirdPartyGamesActions } from '../../../redux/actions';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  thirdBetHistory: state.thirdPartyGamesReducers.thirdBetHistory,
});

const mapDispatchToProps = dispatch => ({
  GetBetHistory: (obj, callback) => dispatch(thirdPartyGamesActions.BetHistory(obj, callback)),
});

const GameBet = ({ thirdBetHistory, GetBetHistory, data }) => {
  const { t } = useTranslation();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const _date = [moment().subtract(5, 'years').startOf('day').toDate(), moment().endOf('day').toDate()];
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);

  useEffect(() => {
    if (data) {
      setIsQueryingDetail(true);
      const _obj = {
        beginTime: moment(_date[0]).format('YYYY-MM-DD  HH:mm:ss'),
        endTime: moment(_date[1]).format('YYYY-MM-DD  HH:mm:ss'),
        page,
        row: rows,
        loginName: data?.mName
      };
      GetBetHistory(_obj, setIsQueryingDetail);
    }
  }, []);

  const onPaginationClick = (pageNum, rows) => {
    setIsQueryingDetail(false);
    setPage(pageNum);
    setRows(rows);
    const _obj = {
      page: pageNum,
      row: rows,
      beginTime: moment(_date[0]).format('YYYY-MM-DD  HH:mm:ss'),
      endTime: moment(_date[1]).format('YYYY-MM-DD  HH:mm:ss'),
      loginName: data?.mName
    };
    GetBetHistory(_obj, setIsQueryingDetail);
  };

  return (
    <Col className="GameBetWrapper">
      <Row className="mx-0 bg-white px-0 border-1">
        <AgentDataTable
          isQuerying={_isQueryingDetail}
          tableHeaderTitle={
            [{
              label: t('ORDER_NUM'),
            }, {
              label: '下注时间',
            }, {
              label: '游戏名称',
            }, {
              label: '注单数',
            }, {
              label: '下注金额',
            }, {
              label: '有效金额',
            }, {
              label: '下注内容',
            }, {
              label: '输赢',
            }]
          }
          tableHeaderStyle={['headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle', 'headerStyle']}
          tableData={thirdBetHistory ? thirdBetHistory.betList.map((item, index) => ({
            ...item, no: index + 1
          })) : []}
          tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.no}</Label>),
              data => (<Label className="d-flex justify-content-center">{moment.unix(data.betTime).format('YYYY-MM-DD HH:mm:ss')}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.gameName}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.betCount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.betAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.validWinLost}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.remark}</Label>),
              data => (<Label className="d-flex justify-content-center">{data?.winLost}</Label>),
            ]
          }
          tableFooter={[[{
            value: `${t('CURRENT_PAGE_TOTAL')}`,
            colspan: 3,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (thirdBetHistory && thirdBetHistory.total.betCount) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (thirdBetHistory && thirdBetHistory.total.betAmount) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (thirdBetHistory && thirdBetHistory.total.validWinLost) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (thirdBetHistory && thirdBetHistory.total.winLost) || 0,
            colspan: 1,
            className: 'tableSum'
          }], [{
            value: `${t('TOTAL_COUNT')}`,
            colspan: 3,
            className: 'textRight pr-1 total tableSum'
          },
          {
            value: (thirdBetHistory && thirdBetHistory.total.totalBetCount) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (thirdBetHistory && thirdBetHistory.total.totalBetAmount) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (thirdBetHistory && thirdBetHistory.total.totalValidWinLost) || 0,
            colspan: 1,
            className: 'tableSum'
          },
          {
            colspan: 1,
            className: 'tableSum'
          },
          {
            value: (thirdBetHistory && thirdBetHistory.total.totalWinLost) || 0,
            colspan: 1,
            className: 'tableSum'
          }]]}
        />
      </Row>
      <Row className="justify-center w-full relative">
        <Pagination totalRecord={thirdBetHistory ? (thirdBetHistory?.totalCount || 0) : 0} onClick={onPaginationClick} isQuery={false} />
      </Row>
    </Col>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GameBet);