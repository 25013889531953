/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BankCardSetting from '../../components/WalletManagement/BankCardSetting';
import { CommonHeader } from '../../components';
import Deposit from '../../components/WalletManagement/Deposit';
import SafetySetting from '../../components/WalletManagement/SafetySetting';
import TransactionHistory from '../../components/WalletManagement/TransactionHistory';
import Transfer from '../../components/WalletManagement/Transfer';
import Withdrawal from '../../components/WalletManagement/Withdrawal';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
  userInfo: state.memberReducers.userInfo,
});

const WalletManagement = ({ selectedSubMenu }) => {
  const { t } = useTranslation();
  return (

    <div className="walletManagementWrapper p-3">
      {selectedSubMenu === 'WM_DEPOSIT' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <Deposit />
      </>
      )}
      {selectedSubMenu === 'WM_WITHDRAWAL' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <Withdrawal />
      </>
      )}
      {selectedSubMenu === 'WM_SAFETY_SETTING' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <SafetySetting />
      </>)}
      {selectedSubMenu === 'WM_TRANSFER' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <Transfer />
      </>)}
      {selectedSubMenu === 'WM_CARD_SETTING' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <BankCardSetting />
      </>)}
      {selectedSubMenu === 'WM_TRANSCTION_HISTORY' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <TransactionHistory />
      </>) }
    </div>
  );
};

export default connect(mapStateToProps, null)(WalletManagement);