import React, { useEffect, useState } from 'react';

import { Input, Select } from 'element-react';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, cashActions } from '../../../redux/actions';
import { AgentDataTable } from '../..';
import CommonHeader from '../../Content/CommonHeader';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  GetBusiness: (loginIds, type, callback) => dispatch(cashActions.GetBusiness(loginIds, type, callback)),
  SaveBusiness: obj => dispatch(cashActions.SaveBusiness(obj)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const AmountChange = ({ GetBusiness, SaveBusiness, updateModalAlert }) => {
  const { t } = useTranslation();
  const [_isQueryingDetail, setIsQueryingDetail] = useState(false);
  const [actionType, setMemType] = useState(-1);

  const [type, setType] = useState(1);
  const [loginIds, setloginIds] = useState('');
  const [remark, setremark] = useState('');
  const [batchValue, setBatchValue] = useState(0);
  const [_business, setbusiness] = useState([]);

  const onSearchClick = () => {
    if (!loginIds) {
      return updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          showCancelButton: false,
          message: '请填写用户账号！'
        }
      });
    }
    setIsQueryingDetail(true);
    GetBusiness(loginIds, type, data => {
      setIsQueryingDetail(false);
      const _temp = data.map((item, index) => ({
        ...item, no: index + 1, mid: item.userId, amount: ''
      }));
      setbusiness([..._temp]);
    });
  };

  const onSaveClick = () => {
    const amountFlag = _business.find(x => (x.amount === '' || x.amount === 0));
    if (!remark || !loginIds || actionType === -1 || amountFlag) {
      return updateModalAlert({
        type: 'confirmation',
        visible: true,
        data: {
          showCancelButton: false,
          message: !remark
            ? '请填写加额备注！'
            : !loginIds
              ? '请填写用户账号并检查用户名！'
              : actionType === -1
                ? '请选择加额类型！'
                : '请填写金额',
        }
      });
    }
    const changData = () => {
      updateModalAlert({
        visible: true,
        type: 'loading',
      });

      SaveBusiness({
        data: _business,
        remark,
        actionType,
        type,
        loginIds
      });
    };
    return updateModalAlert({
      type: 'confirmation',
      visible: true,
      data: {
        message: '请确认是否要修改/设置',
        onConfirmationClick: () => changData()
      }
    });
  };

  useEffect(() => {
    if (_business) {
      const _temp = _business.map((item, index) => ({
        ...item, no: index + 1, mid: item.userId, amount: batchValue
      }));
      setbusiness([..._temp]);
    }
  }, [batchValue]);

  return (
    <div className="amountChangeWrapper">
      <CommonHeader headerName={t('AMOUNT_CHANGE')} />
      <div className="depositWithdrawStatisticTableWrapper d-flex p-3">
        <div className="userTable w-100">
          <>
            <div className="d-flex">
              <div>加额类型</div>
              <div>
                <Select
                  popper-class="select-popper"
                  value={actionType}
                  onChange={value => setMemType(value)}
                >
                  {
                    [{
                      label: '请选择类型', value: ''
                    }, {
                      value: 300, label: '红利'
                    }, {
                      value: 400, label: '代理赚水'
                    }, {
                      value: 401, label: '会员反水'
                    }, {
                      value: 600, label: '活动优惠'
                    }, {
                      value: 700, label: '人工加额'
                    }, {
                      value: 701, label: '人工减额'
                    }, {
                      value: 800, label: '代理转账'
                    }, {
                      value: 899, label: '游戏转账'
                    }, {
                      value: 910, label: '解锁金额'
                    }, {
                      value: 911, label: '锁定金额'
                    }, {
                      value: 997, label: '试玩加额'
                    }, {
                      value: 998, label: '财务加额'
                    }, {
                      value: 999, label: '财务扣额'
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
                </Select>
              </div>
            </div>
            <div className="d-flex">
              <div>账户类型</div>
              <div>
                <Select
                  popper-class="select-popper"
                  value={type}
                  onChange={value => setType(value)}
                >
                  {
                    [{
                      label: '会员', value: 1
                    }, {
                      label: '代理', value: 2
                    }].map(item => (
                      <Select.Option key={`UserBetStatus_${item.value}`} label={item.label} value={item.value} />
                    ))
                }
                </Select>
              </div>
            </div>
            <div className="d-flex">
              <div>加额备注</div>
              <div>
                <Input
                  className="mx-1 my-2 w-50"
                  value={remark}
                  type="textarea"
                  placeholder="最多400个字符"
                  autosize={{
                    minRows: 2, maxRows: 4
                  }}
                  onChange={text => setremark(text)}
                />
              </div>
            </div>
            <div className="d-flex">
              <div>用户账号</div>
              <div>
                <Input
                  className="mx-1 my-2 w-50"
                  value={loginIds}
                  type="textarea"
                  placeholder="多个账号用逗号隔开，最多同时可操作20个会员"
                  autosize={{
                    minRows: 2, maxRows: 4
                  }}
                  onChange={text => setloginIds(text)}
                />
              </div>
            </div>
          </>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="operationBtn cancel" onClick={() => onSearchClick()}>检查用户名</div>
        <div className="ms-3 operationBtn findAndCollapse" onClick={() => onSaveClick()}>完成并保存</div>
      </div>
      <div className="depositWithdrawStatisticTableWrapper p-3">
        <div className="mx-0 bg-white">
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={
            [{
              label: '序号',
            }, {
              label: '用户帐号【类型】',
            }, {
              label: '账号余额',
            }, {
              label: '冻结金额',
            }, {
              label: '用户状态',
            }, {
              label: (
                <div className="d-flex align-items-center justify-content-center">
                  加/减金额
                  <Input
                    value={batchValue}
                    onChange={text => {
                      if (!Number.isNaN(text)) {
                        setBatchValue(text === '' ? 0 : parseInt(text, 10));
                      }
                    }}
                  />
                </div>
              ),
            }]
          }
            tableData={_business || []}
            tableBodyRowProperty={
            [
              data => (<Label className="d-flex justify-content-center">{data.no}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.loginId}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.balance}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.lockAmount}</Label>),
              data => (<Label className="d-flex justify-content-center">{data.useState}</Label>),
              data => (
                <Label className="d-flex justify-content-center">
                  <Input
                    value={data.amount}
                    onChange={text => {
                      if (!Number.isNaN(text)) {
                        const temp = _business.map(el => (el.mid === data.mid ? ({
                          ...el, amount: text === '' ? 0 : parseInt(text, 10)
                        }) : el));
                        setbusiness(temp);
                      }
                    }}
                  />
                </Label>
              ),
            ]
          }
          />
        </div>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(AmountChange);