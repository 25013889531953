import { Input, Select } from 'element-react';
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CommonHeader, LotteryMenu } from '../../components';
import { appActions, bogusBetActions } from '../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  activeLottery: state.commonReducers.activeLottery,
  warnSetting: state.bogusBetReducers.warnSetting,
  userInfo: state.memberReducers.userInfo,
});

const mapDispatchToProps = dispatch => ({
  GetBogusWarning: lotteryType => dispatch(bogusBetActions.GetBogusWarning(lotteryType)),
  SaveWarnSetting: obj => dispatch(bogusBetActions.SaveWarnSetting(obj)),
  EditWarnSetting: (obj, callback) => dispatch(bogusBetActions.EditWarnSetting(obj, callback)),
  DelWarnSetting: (id, uid, lotteryType) => dispatch(bogusBetActions.DelWarnSetting(id, uid, lotteryType)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const BogusBetWarning = ({ GetBogusWarning, SaveWarnSetting, activeLottery, userInfo, warnSetting, DelWarnSetting, EditWarnSetting, updateModalAlert }) => {
  const [_selectedLottery, setLottery] = useState(activeLottery);
  const [_showMenu, setShowMenu] = useState(false);
  const [_selectedSettingCode, setSettingCode] = useState('');
  const [_inUse, setInUse] = useState(0);
  const [_lianHao, setLianHao] = useState(0);
  const [_lianHaoAmt, setLianHaoAmt] = useState(0);
  const [_singleAmt, setSingleAmt] = useState(0);
  const [_settingOddsInfo, setSettingOddsInfo] = useState([]);
  const [_list, setList] = useState();
  const { t } = useTranslation();


  useEffect(() => {
    if (warnSetting) {
      const temp = warnSetting.settingOddsInfo.filter(x => !(warnSetting.bogusBetWarnList.find(y => y.settingcode === x.code)));
      setSettingOddsInfo(temp);
      setSettingCode('');
      setInUse(0);
      setLianHao(0);
      setLianHaoAmt(0);
      setSingleAmt(0);
      if (!_list) {
        const temp = warnSetting.bogusBetWarnList.map(item => ({
          ...item, edit: false
        }));
        setList(temp);
      } else {
        const temp = warnSetting.bogusBetWarnList.map(item => ({
          ...item, edit: _list.find(data => data.id === item.id) ? _list.find(data => data.id === item.id)?.edit : false
        }));
        setList(temp);
      }
    }
  }, [warnSetting]);


  useEffect(() => {
    GetBogusWarning(_selectedLottery.code);
    setSettingCode('');
    setInUse(0);
    setLianHaoAmt(0);
    setLianHao(0);
    setSingleAmt(0);
  }, [_selectedLottery]);

  const addNewItem = () => {
    if (_selectedSettingCode !== '') {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const obj = {
        keyCode: _selectedSettingCode,
        lotteryType: _selectedLottery.code,
        betCount: _lianHao,
        totalAmount: _lianHaoAmt,
        singleAmount: _singleAmt,
        limitBet: _inUse,
        uid: userInfo.orgId
      };
      SaveWarnSetting(obj);
    }
  };


  const generateDropDown = (projectName, code) => {
    let temp = [];
    temp = _settingOddsInfo.map(el => <Select.Option label={el.projectName} value={el.code} />);
    return temp.concat(<Select.Option label={projectName} value={code} />);
  };

  return (
    <div className="bogusBetWarningWrapper">
      <CommonHeader headerName={t('LHYJSZ')} />
      <div className="menuRow">
        <div className="currentSelected" onFocus={() => {}} onMouseOver={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)}>
          {_selectedLottery.lotteryName}
          <span className="arrow_down" />
          {_showMenu && (
          <div className="bogusBetWarningMenuWrapper" onFocus={() => {}} onMouseOver={() => setShowMenu(true)}>
            <LotteryMenu onClickItem={item => { setLottery(item); setShowMenu(false); }} selectedItem={_selectedLottery.code} />
          </div>
          )}
        </div>
      </div>
      <div className="bogusBetWarningTableWrapper">
        <div className="bogusBetWarningTable">
          <div className="bogusBetWarningTableHeader">
            <div>{t('ORDER_NUM')}</div>
            <div>{t('TYPE')}</div>
            <div>连号数</div>
            <div>连号金额</div>
            <div>单注限额</div>
            <div>连号限注</div>
            <div>{t('OPERATION')}</div>
          </div>
          {_list && _list.map((item, index) => (
            <div key={`row_item_${index.toString()}`} className="bogusBetWarningTableSettingRow">
              <div>{index + 1}</div>
              <div>
                {item.edit ? (
                  <Select
                    value={item.settingcode}
                    placeholder="请选择"
                    onChange={code => {
                      const temp = _list.map(el => (el.id === item.id ? ({
                        ...el, settingcode: code
                      }) : el));
                      setList(temp);
                    }}
                  >
                    {
                      generateDropDown(item.projname, item.settingcode)
                    }
                  </Select>
                ) : item.projname}

              </div>
              <div>
                {item.edit ? (
                  <Input
                    className="betWarningInput"
                    value={item.betcount}
                    onChange={text => {
                      if (!Number.isNaN(text)) {
                        const temp = _list.map(el => (el.id === item.id ? ({
                          ...el, betcount: text === '' ? 0 : parseInt(text, 10)
                        }) : el));
                        setList(temp);
                      }
                    }}
                    placeholder="请输入内容"
                  />
                ) : item.betcount}

              </div>
              <div>
                {item.edit ? (
                  <Input
                    className="betWarningInput"
                    value={item.totalamount}
                    onChange={text => {
                      if (!Number.isNaN(text)) {
                        const temp = _list.map(el => (el.id === item.id ? ({
                          ...el, totalamount: text === '' ? 0 : parseInt(text, 10)
                        }) : el));
                        setList(temp);
                      }
                    }}
                    placeholder="请输入内容"
                  />
                ) : item.totalamount}

              </div>
              <div>
                {item.edit ? (
                  <Input
                    className="betWarningInput"
                    value={item.singleamount}
                    onChange={text => {
                      if (!Number.isNaN(text)) {
                        const temp = _list.map(el => (el.id === item.id ? ({
                          ...el, singleamount: text === '' ? 0 : parseInt(text, 10)
                        }) : el));
                        setList(temp);
                      }
                    }}
                    placeholder="请输入内容"
                  />
                ) : item.singleamount}

              </div>
              <div>
                {item.edit ? (
                  <Select
                    value={item.limitbet}
                    placeholder="请选择"
                    onChange={code => {
                      const temp = _list.map(el => (el.id === item.id ? ({
                        ...el, limitbet: code
                      }) : el));
                      setList(temp);
                    }}
                  >
                    {
                [{
                  label: '启用', value: 1
                }, {
                  label: '不启用', value: 0
                }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
                    }
                  </Select>
                ) : item.limitbet ? '启用' : '不启用'}

              </div>
              <div className="d-flex justify-content-center align-items-center">
                {item.edit ? (
                  <div
                    className="operationBtn editAndSave"
                    onClick={() => {
                      updateModalAlert({
                        type: 'loading',
                        visible: true
                      });

                      const temp = _list.find(x => x.id === item.id);

                      const obj = {
                        keyCode: temp.settingcode,
                        lotteryType: _selectedLottery.code,
                        betCount: temp.betcount,
                        totalAmount: temp.totalamount,
                        singleAmount: temp.singleamount,
                        limitBet: temp.limitbet,
                        uid: userInfo.orgId,
                        id: temp.id
                      };

                      const callback = () => {
                        const temp = _list.map(el => (el.id === item.id ? ({
                          ...el, edit: false
                        }) : el));
                        setList(temp);
                      };

                      EditWarnSetting(obj, callback);
                    }}
                  >
                    保存
                  </div>
                ) : (
                  <div
                    className="operationBtn editAndSave"
                    onClick={() => {
                      const temp = _list.map(el => (el.id === item.id ? ({
                        ...el, edit: true
                      }) : el));
                      setList(temp);
                    }}
                  >
                    修改
                  </div>
                )}
                <div
                  className="operationBtn remove ms-3"
                  onClick={() => {
                    updateModalAlert({
                      type: 'loading',
                      visible: true
                    }); DelWarnSetting(item.id, item.organizationid, _selectedLottery.code);
                  }}
                >
                  删除
                </div>
              </div>
            </div>
          ))}
          <div className="bogusBetWarningTableSettingAddRow">
            <div />
            <div>
              <Select value={_selectedSettingCode} placeholder="请选择" onChange={item => { setSettingCode(item); }}>
                {
                _settingOddsInfo.map(el => <Select.Option key={el.code} label={el.projectName} value={el.code} />)
              }
              </Select>
            </div>
            <div>
              <Input
                className="betWarningInput"
                value={_lianHao}
                onChange={text => {
                  if (!Number.isNaN(text)) {
                    setLianHao(text === '' ? 0 : parseInt(text, 10));
                  }
                }}
                placeholder="请输入内容"
              />
            </div>
            <div>
              <Input
                className="betWarningInput"
                value={_lianHaoAmt}
                onChange={text => {
                  if (!Number.isNaN(text)) {
                    setLianHaoAmt(text === '' ? 0 : parseInt(text, 10));
                  }
                }}
                placeholder="请输入内容"
              />

            </div>
            <div>
              <Input
                className="betWarningInput"
                value={_singleAmt}
                onChange={text => {
                  if (!Number.isNaN(text)) {
                    setSingleAmt(text === '' ? 0 : parseInt(text, 10));
                  }
                }}
                placeholder="请输入内容"
              />

            </div>
            <div>
              <Select value={_inUse} placeholder="请选择" onChange={item => setInUse(item)}>
                {
          [{
            label: '启用', value: 1
          }, {
            label: '不启用', value: 0
          }].map(el => <Select.Option key={el.value} label={el.label} value={el.value} />)
              }
              </Select>
            </div>
            <div><div className="operationBtn add" onClick={() => addNewItem()}>添加</div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BogusBetWarning);