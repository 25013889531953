import React, { useEffect, useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { Input } from 'element-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Icon from '../../Icon';
import { appActions } from '../../../redux/actions';
import 'element-theme-default';
import './styles.scss';

const mapStateToProps = state => ({
  modalMessage: state.appReducers.modalMessage
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateModalMessage: data => dispatch(appActions.updateModalMessage(data)),
});

const SafePassModal = ({ updateModalAlert, data, modalMessage, updateModalMessage }) => {
  const { t } = useTranslation();

  const [oldSafePass, setoldSafePass] = useState('');
  const [newSafePass, setnewSafePass] = useState('');
  const [confirmNewPass, setconfirmNewPass] = useState('');

  const checkCode = () => {
    const _obj = {
      oldSafePass,
      newSafePass,
      confirmNewPass,
      verifyCode: ''
    };
    data.onConfirmationClick(_obj);
  };
  useEffect(() => () => updateModalMessage(), []);

  return (
    <div className="SafePassModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className="d-flex align-items-center"
        >
          {data?.safePassword === '' ? '设置密码' : '更换密码'}
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <Icon
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
            libName="io"
            value={{
              name: 'IoClose',
              className: 'h4 mb-0 float-right closeIcon',
            }}
          />
        </Col>
      </Row>

      <div className="modalContent d-flex justify-content-center mx-0 p-2 ">
        <div className="orderInformationTable">
          {data.safePassword !== ''
          && (
          <div className="evenRowStyle">
            <div>旧密码</div>
            <div>
              <Input
                type="password"
                value={oldSafePass}
                onChange={text => setoldSafePass(text.trim())}
              />
            </div>
          </div>
          )}
          <div className="evenRowStyle">
            <div>新密码</div>
            <div>
              <Input
                type="password"
                value={newSafePass}
                onChange={text => setnewSafePass(text.trim())}
              />
            </div>
          </div>
          <div className="evenRowStyle">
            <div>确认新密码</div>
            <div>
              <Input
                type="password"
                value={confirmNewPass}
                onChange={text => setconfirmNewPass(text.trim())}
              />
            </div>
          </div>

          {modalMessage && <div className="d-flex justify-content-center align-items-center fw-bold text-danger">{modalMessage}</div>}
        </div>
      </div>


      <Row className="modalFooter d-flex align-items-center ">
        <Button
          className="confirmButton my-3"
          onClick={() => {
            checkCode();
          }}
        >
          {t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SafePassModal);