import React from 'react';

import { connect } from 'react-redux';

import Notifications from '../Notifications';

import './styles.scss';

const CommonHeader = ({ headerName, rightLocationItem }) => (
  <div className="CommonHeaderWrapper p-2 mb-2 d-flex align-items-center w-100 rounded-top">
    <div>
      <div className="px-2">
        {headerName}
      </div>
    </div>
    {rightLocationItem && rightLocationItem()}
    <Notifications />
  </div>
);

export default connect(null)(CommonHeader);