import React from 'react';

import { connect } from 'react-redux';

import './styles.scss';

const mapStateToProps = state => ({
  inTimeSheetOdds: state.inTimeSheetReducers.inTimeSheetOdds,
});

const Amount = ({ obj, _OddsListObj, betItemStyle }) => (
  <div className={`${betItemStyle} amountWrapper `}>
    {_OddsListObj && _OddsListObj[obj.code]?.enabled === 1 ? _OddsListObj[obj.code]?.totalAmt : 0}
  </div>
);

export default connect(mapStateToProps)(Amount);