import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';

import { AgentDataTable } from '../../..';
import BetQueryDetail from '../../../../views/BetQueryDetail';
import ReportCommonSelection from '../../../ReportCommonSelection';
import { reportActions } from '../../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  winLostReport: state.reportReducers.winLostReport,
  lotteryList: state.commonReducers.lotteryList,
});

const mapDispatchToProps = dispatch => ({
  getDepositWithdrawTotalStatistic: (obj, callback) => dispatch(reportActions.getDepositWithdrawTotalStatistic(obj, callback)),
});

const LotteryReport = ({ winLostReport, _specialLoginName, setSpecialLoginName, lotteryList }) => {
  const [_isQueryingDetail, setIsQueryingDetail] = useState(true);
  const [list, setList] = useState();
  const [total, setTotal] = useState();
  const [tabIndex, setTabIndex] = useState('');

  const [_date, setDate] = useState([moment().startOf('day').toDate(), moment().toDate()]);

  const ChildRef = useRef();

  useEffect(() => {
    if (winLostReport && winLostReport.reportList) {
      if (tabIndex === '') {
        setList(winLostReport.reportList.total);
        setTotal(winLostReport.reportList);
      } else {
        setList(winLostReport.reportList[`${tabIndex.toLowerCase()}`]);
        setTotal(winLostReport.reportList[`${tabIndex.toLowerCase()}_total`]);
      }
    }
  }, [winLostReport, tabIndex]);

  return (
    <div className="LotteryReportWrapper">
      <ReportCommonSelection setSpecialLoginName={setSpecialLoginName} _isShow={!_specialLoginName} _setDate={setDate} callback={data => setIsQueryingDetail(data)} onRef={ChildRef} />
      <div className={`winLostReportTableWrapper p-3 ${_specialLoginName && 'd-none'}`}>
        <div className="menuWrapper mb-2 ms-2">
          <div className={`menuItem p-2  ${tabIndex === '' && 'selected'}`} onClick={() => setTabIndex('')}>合计</div>
          {
              winLostReport && winLostReport.reportList.lotterys.map(item => <div className={`menuItem p-2  ${tabIndex === item && 'selected'}`} onClick={() => setTabIndex(item)}>{lotteryList.find(x => x.code === String(item).toUpperCase()).lotteryName}</div>)
            }
        </div>
        <div className="mx-0 bg-white">
          <div className="headerTitle d-flex justify-content-center py-2 w-100">合计</div>
          <AgentDataTable
            isQuerying={_isQueryingDetail}
            tableHeaderTitle={
              [{
                label: '账号',
              }, {
                label: '名称',
              }, {
                label: '余额',
              }, {
                label: '笔数',
              }, {
                label: '下注金额',
              }, {
                label: '有效金额',
              }, {
                label: '手续费',
              }, {
                label: (
                  <div className="combineTitleCell">
                    <div className="h-50">会员输赢</div>
                    <div className="d-flex h-50">
                      <div>输赢</div>
                      <div>退水</div>
                      <div>盈亏结果</div>
                    </div>
                  </div>
                ),
              }, {
                label: (
                  <div className="combineTitleCell">
                    <div className="h-50">总公司 输赢</div>
                    <div className="d-flex h-50">
                      <div>应收下线</div>
                      <div>占成</div>
                      <div>实占金额</div>
                      <div>实占结果</div>
                      <div>实占退水</div>
                      <div>赚水</div>
                      <div>赚赔</div>
                      <div>盈亏结果</div>
                    </div>
                  </div>
                ),
              }, {
                label: '上交货量',
              }, {
                label: '上级交收',
              }]
            }
            tableHeaderStyle={['', '', '', '', '', '', '', 'p-0', 'p-0', '', '']}
            tableData={list || []}
            tableBodyRowStyle={['', '', '', '', '', '', '', 'p-0', 'p-0', '', '']}
            tableBodyRowProperty={
              [
                data => (
                  <div
                    className="d-flex justify-content-center numberBlue detailLink"
                    onClick={() => {
                      if (data.level === 11) {
                        setSpecialLoginName(data.loginName);
                      } else {
                        ChildRef.current.onSearchClick(data.level, data.orgId, 1);
                      }
                    }}
                  >
                    {data.orgName}
                  </div>
                ),
                data => (<div className="d-flex justify-content-center">{data.loginName}</div>),
                data => (<div className="d-flex justify-content-center">{data.balance}</div>),
                data => (<div className="d-flex justify-content-center">{data.count}</div>),
                data => (<div className="d-flex justify-content-center numberBlue">{data.amount}</div>),
                data => (<div className="d-flex justify-content-center">{data.validAmount}</div>),
                data => (<div className="d-flex justify-content-center">{data.fee}</div>),
                data => (
                  <div className="d-flex justify-content-center combineBodyRowCell">
                    <div>{data.winLost}</div>
                    <div>{data.rebate}</div>
                    <div>{data.totalWinLost}</div>
                  </div>
                ),
                data => (
                  <div className="d-flex justify-content-center combineBodyRowCell">
                    <div className={`${parseFloat(data.receiveable) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.receiveable}</div>
                    <div>{data.zc}</div>
                    <div>{data.zcAmount}</div>
                    <div>{data.zcWinLost}</div>
                    <div>{data.zcRebate}</div>
                    <div>{data.earnAmount}</div>
                    <div>{data.earnOdds}</div>
                    <div className={`${parseFloat(data.totalResult) < 0 ? 'numberRed' : 'numberBlue'}`}>{data.totalResult}</div>
                  </div>
                ),
                data => (<div className="d-flex justify-content-center">{data.uplineAmount}</div>),
                data => (<div className="d-flex justify-content-center numberBlue">{data.uplineResult}</div>),
              ]
            }
            tableFooter={[
              [{
                value: `合计：${list ? list.length : 0}行`,
                colspan: 3,
                className: 'pe-1 total tableSum'
              },
              {
                value: (total && total.count) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (total && total.amount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (total && total.validAmount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (total && total.fee) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                render: () => {
                  if (total) {
                    return (
                      <div className="d-flex justify-content-center combineBodyRowCell">
                        <div>{total.winLostAmount}</div>
                        <div>{total.rebate}</div>
                        <div>{total.totalWinLost}</div>
                      </div>
                    );
                  }

                  return 0;
                },
                colspan: 1,
                className: 'tableSum noPadding'
              },
              {
                render: () => {
                  if (total) {
                    return (
                      <div className="d-flex justify-content-center combineBodyRowCell">
                        <div className={`${parseFloat(total.receiveable) < 0 ? 'numberRed' : 'numberBlue'}`}>{total.receiveable}</div>
                        <div>{total.amount}</div>
                        <div>{total.zcAmount}</div>
                        <div>{total.zcWinLost}</div>
                        <div>{total.zcRebate}</div>
                        <div>{total.earnAmount}</div>
                        <div>{total.earnOdds}</div>
                        <div className={`${parseFloat(total.totalResult) < 0 ? 'numberRed' : 'numberBlue'}`}>{total.totalResult}</div>
                      </div>
                    );
                  }

                  return 0;
                },
                colspan: 1,
                className: 'tableSum noPadding'
              },
              {
                value: (total && total.uplineAmount) || 0,
                colspan: 1,
                className: 'tableSum'
              },
              {
                value: (total && total.uplineResult) || 0,
                colspan: 1,
                className: 'tableSum numberBlue'
              }]
            ]}
          />
        </div>
      </div>
      {
        _specialLoginName
        && (
        <div className="mt-2">
          <BetQueryDetail presetObj={{
            loginName: _specialLoginName, lotteryName: lotteryList.find(x => x.code === String(tabIndex).toUpperCase())?.lotteryName || '全部', beginDate: _date[0], endDate: _date[1], lotteryCode: tabIndex
          }}
          />
        </div>
        )
      }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotteryReport);