import React, { useEffect, useState } from 'react';

import { Button, Col, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { agentActions, appActions } from '../../../redux/actions';
import AgentDataTable from '../../AgentDataTable';
import Icon from '../../Icon';

import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  prevOddDiffData: state.agentReducers.prevOddDiffData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getPreviewOddsDifference: id => dispatch(agentActions.getPreviewOddsDifference(id)),
});

const PreviewOddDiffModal = ({ updateModalAlert, data, getPreviewOddsDifference, prevOddDiffData }) => {
  const { t } = useTranslation();
  const [_detailItem, setDetailItem] = useState();
  const [_header, setHeader] = useState([]);
  const [_content, setContent] = useState([]);

  useEffect(() => {
    // Default search when first load
    data && data?.thisData && data?.thisData.id && getPreviewOddsDifference(data?.thisData?.id);
  }, [data]);

  useEffect(() => {
    if (prevOddDiffData) {
      setDetailItem(prevOddDiffData);
    }
    // Hide most of the detail for low level agent
    if (prevOddDiffData && Number(prevOddDiffData.levelValue) !== 1) {
      const tempHead = [];
      const tempContent = [];
      tempHead.push(tableHeader[0]);
      tempContent.push(tableContent[0]);
      Array.from(data?.memberGame || 'A').forEach((d, index) => {
        tempHead.push({
          label: `${d + t('PLATE') + t('REBATE')}`,
        });
        tempHead.push({
          label: `${d + t('PLATE') + t('ODDS')}`,
        });
        tempContent.push(tableContent[1 + index + index]);
        tempContent.push(tableContent[2 + index + index]);
      });
      setHeader(tempHead);
      setContent(tempContent);
    } else {
      setHeader(tableHeader);
      setContent(tableContent);
    }
  }, [prevOddDiffData]);

  const tableHeader = [{
    label: t('NUMBER'),
  }];

  const tableContent = [
    data => (<Label className="d-flex justify-content-center">{`${data?.midType} ${data?.displayName}`}</Label>),
    () => (<Label className="d-flex justify-content-center">{data?.thisData?.aReturn}</Label>),
    () => (<Label className="d-flex justify-content-center installment">{data?.thisData?.oddsA}</Label>),
    () => (<Label className="d-flex justify-content-center">{data?.thisData?.bReturn}</Label>),
    () => (<Label className="d-flex justify-content-center installment">{data?.thisData?.oddsB}</Label>),
    () => (<Label className="d-flex justify-content-center">{data?.thisData?.cReturn}</Label>),
    () => (<Label className="d-flex justify-content-center installment">{data?.thisData?.oddsC}</Label>),
    () => (<Label className="d-flex justify-content-center">{data?.thisData?.dReturn}</Label>),
    () => (<Label className="d-flex justify-content-center installment">{data?.thisData?.oddsD}</Label>),
  ];

  return (
    <div className="prevOddDiffModalWrapperWeb">
      <Row className="modalHeader">
        <Col
          className={`${'d-flex justify-content-center align-items-center'}`}
          xs={{
            size: 4, offset: 4
          }}
        >
          {t('ZC')}
          {t('DETAILS')}
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <Icon
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
            libName="io"
            value={{
              name: 'IoClose',
              className: 'h4 mb-0 float-right closeIcon',
            }}
          />
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center">
        <Row className="p-2 justify-content-center">
          {
            data && cancelAnimationFrame && data?.thisData?.id && (
              <>
                <Row className="border d-flex p-2 m-0 justify-content-center">
                  当前输入:&nbsp;&nbsp;
                  {data?.memberGame.includes('A') && (
                    <>
                      &nbsp;&nbsp;&nbsp;A盘退水：&nbsp;
                      {data?.thisData?.aReturn}
                    </>
                  )}
                  {data?.memberGame.includes('B') && (
                    <>
                      &nbsp;&nbsp;&nbsp;B盘退水：&nbsp;
                      {data?.thisData?.bReturn}
                    </>
                  )}
                  {data?.memberGame.includes('C') && (
                    <>
                      &nbsp;&nbsp;&nbsp;C盘退水：&nbsp;
                      {data?.thisData?.cReturn}
                    </>
                  )}
                  {data?.memberGame.includes('D') && (
                    <>
                      &nbsp;&nbsp;&nbsp;D盘退水：&nbsp;
                      {data?.thisData?.dReturn}
                    </>
                  )}
                </Row>
                <Row className="drawhalfbox d-flex p-2 m-0 justify-content-center">
                  {data?.memberGame.includes('A') && (
                    <>
                      A盘赔率差：&nbsp;
                      {data?.thisData?.oddsA}
                    </>
                  )}
                  {data?.memberGame.includes('B') && (
                    <>
                      &nbsp;&nbsp;&nbsp;B盘赔率差：&nbsp;
                      {data?.thisData?.oddsB}
                    </>
                  )}
                  {data?.memberGame.includes('C') && (
                    <>
                      &nbsp;&nbsp;&nbsp;C盘赔率差：&nbsp;
                      {data?.thisData?.oddsC}
                    </>
                  )}
                  {data?.memberGame.includes('D') && (
                    <>
                      &nbsp;&nbsp;&nbsp;D盘赔率差：&nbsp;
                      {data?.thisData?.oddsD}
                    </>
                  )}
                  &nbsp;&nbsp;预览情况如下
                </Row>
              </>
            )
          }
        </Row>
        <Row className="tableWrapper d-flex p-2 justify-content-center">
          <AgentDataTable
            tableHeaderTitle={_header}
            tableData={_detailItem}
            tableBodyRowProperty={_content}
          />
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-2 ${'me-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onConfirmationClick) data.onConfirmationClick();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewOddDiffModal);