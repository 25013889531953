import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { Select } from 'element-react';
import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  editLotteryItemData: state.systemReducers.editLotteryItemData,
  userData: state.memberReducers.userData,
  isValidNameMsg: state.agentReducers.isValidNameMsg,
  isValidLoginIdMsg: state.agentReducers.isValidLoginIdMsg,
});

const mapDispatchToProps = dispatch => ({
  getEditLotteryItem: (userId, callback) => dispatch(systemActions.getEditLotteryItem(userId, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveLotteryItem: (obj, callback) => dispatch(systemActions.saveLotteryItem(obj, callback)),
});

const LotterySetting = ({ callback = {}, getEditLotteryItem, data, editLotteryItemData, updateModalAlert, saveLotteryItem }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);

  useEffect(() => {
    getEditLotteryItem({
      code: data?.code
    });
  }, [data]);

  useEffect(() => {
    if (editLotteryItemData && JSON.stringify(editLotteryItemData) !== JSON.stringify(_info)) {
      setInfo(editLotteryItemData);
    }
  }, [editLotteryItemData]);

  const saveButton = () => {
    if (_info?.isChange) {
      updateModalAlert({
        type: 'loading',
        visible: true
      });
      const obj = _info;
      saveLotteryItem(obj, callback);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '无账户资料'
        }
      });
    }
  };


  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    newItems.isChange = true;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    getEditLotteryItem({
      code: data?.code
    });
  };

  const Status = [
    {
      label: '启用',
      value: '1',
    },
    {
      label: '暂停',
      value: '0',
    }
  ];
  const PlateStatus = [
    {
      label: '正常',
      value: '1',
    },
    {
      label: '关盘',
      value: '0',
    }
  ];
  const HeadStatus = [
    {
      label: '默认',
      value: '0',
    },
    {
      label: '热门',
      value: '1',
    },
    {
      label: '新彩',
      value: '2',
    }
  ];

  return (
    <div className="LotterySettingWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="m-0 my-1 p-0">
          <table className="p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="titleHeader" colSpan={2}>
                  个人彩种资料
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">彩种名称</td>
                <td>
                  <Label>{data?.name}</Label>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">状态</td>
                <td>
                  <div
                    className="d-flex p-0 smallInput"
                  >
                    <Select
                      value={_info?.status}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'status')}
                    >
                      {
                           Status.map(d => (
                             <Select.Option key={`setStatus_${d.value}`} label={d.label} value={Number(d.value)} />
                           ))
                      }
                    </Select>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">全局盘口</td>
                <td>
                  <div
                    className="d-flex p-0 smallInput"
                  >
                    <Select
                      value={_info?.closeStatus}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'closeStatus')}
                    >
                      {
                           PlateStatus.map(d => (
                             <Select.Option key={`setSelectedcloseStatus_${d.value}`} label={d.label} value={Number(d.value)} />
                           ))
                      }
                    </Select>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">提前开盘时间</td>
                <td>
                  <div className="d-flex">
                    <Input
                      type="number"
                      value={_info?.beforeOpenTime}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'beforeOpenTime')}
                    />
                    <Label className="mx-2 justify-content-center p-1 align-item-center">(秒)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">提前封盘时间</td>
                <td>
                  <div className="d-flex">
                    <Input
                      type="number"
                      value={_info?.beforeCloseTime}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'beforeCloseTime')}
                    />
                    <Label className="mx-2 justify-content-center p-1 align-item-center">(秒)</Label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">热门/新</td>
                <td>
                  <div
                    className="d-flex p-0 smallInput"
                  >
                    <Select
                      value={_info?.popular}
                      placeholder="请选择"
                      onChange={val => onInfoValueChange(Number(val), 'popular')}
                    >
                      {
                           HeadStatus.map(d => (
                             <Select.Option key={`setAutBuhuo_${d.value}`} label={d.label} value={Number(d.value)} />
                           ))
                      }
                    </Select>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">官网名称</td>
                <td>
                  <Input
                    type="text"
                    value={_info?.officialName || ''}
                    onChange={({ target: { value: text } }) => onInfoValueChange(text, 'officialName')}
                  />
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">官网链接</td>
                <td>
                  <Input
                    type="text"
                    value={_info?.officialUrl || ''}
                    onChange={({ target: { value: text } }) => onInfoValueChange(text, 'officialUrl')}
                  />
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">开奖网名称</td>
                <td>
                  <Input
                    type="text"
                    value={_info?.webName || ''}
                    onChange={({ target: { value: text } }) => onInfoValueChange(text, 'webName')}
                  />
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">开奖网链接</td>
                <td>
                  <Input
                    type="text"
                    value={_info?.webUrl || ''}
                    onChange={({ target: { value: text } }) => onInfoValueChange(text, 'webUrl')}
                  />
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">规则备注</td>
                <td>
                  <Input
                    type="text"
                    value={_info?.rules || ''}
                    onChange={({ target: { value: text } }) => onInfoValueChange(text, 'rules')}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LotterySetting);