/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BillQuery from '../../components/ReportManagement/BillQuery';
import ClassificationReport from '../../components/ReportManagement/ClassificationReport';
import { CommonHeader } from '../../components';
import DepositWithdrawReport from '../../components/ReportManagement/DepositWithdrawReport';
import DepositWithdrawStatistic from '../../components/ReportManagement/DepositWithdrawStatistic';
import DepositWithdrawTotalStatistic from '../../components/ReportManagement/DepositWithdrawTotalStatistic';
import DownlineReport from '../../components/ReportManagement/DownlineReport';
import FinancialReport from '../../components/ReportManagement/FinancialReport';
import InOutSummationReport from '../../components/ReportManagement/InOutSummationReport';
import MemberSummationReport from '../../components/ReportManagement/MemberSummationReport';
import RankList from '../../components/ReportManagement/RankList';
import WinLostReport from '../../components/ReportManagement/WinLostReport';
import './styles.scss';

const mapStateToProps = state => ({
  selectedSubMenu: state.commonReducers.selectedSubMenu,
});

const ReportManagement = ({ selectedSubMenu }) => {
  const { t } = useTranslation();
  return (

    <div className="reportManagementWrapper p-3">
      {selectedSubMenu === 'RM_WIN_LOSE_REPORT' && (
        <WinLostReport />
      )}
      {selectedSubMenu === 'RM_CATEGORY_REPORT' && (
        <ClassificationReport />
      )}
      {selectedSubMenu === 'RM_BILL_QUERY' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <BillQuery />
      </>)}
      {selectedSubMenu === 'RM_FINANCE_REPORT' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <FinancialReport />
      </>)}
      {selectedSubMenu === 'RM_DEPOSIT_WITHDRAWAL_REPORT' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <DepositWithdrawReport />
      </>)}

      {selectedSubMenu === 'RM_UNDERLING_DETAIL' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <DownlineReport />
      </>)}
      {selectedSubMenu === 'RM_IN_OUT_STATISTICS' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <DepositWithdrawStatistic />
      </>)}
      {selectedSubMenu === 'RM_BETTING_TOP_LIST' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <RankList />
      </>)}
      {selectedSubMenu === 'RM_MEMBER_STATISTICS_REPORT' && (
      <>
        <MemberSummationReport />
      </>)}
      {selectedSubMenu === 'RM_IN_OUT_STATISTICS_REPORT' && (
        <InOutSummationReport />
      )}

      {selectedSubMenu === 'RM_DEPOSIT_WITHDRAWAL_STATISTICS_REPORT' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <DepositWithdrawTotalStatistic />
      </>)}
      {selectedSubMenu === 'RM_MEMBER_GAME_REPORT' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <FinancialReport />
      </>)}
      {selectedSubMenu === 'RM_COMPANY_GAME_REPORT' && (
      <>
        <CommonHeader
          headerName={selectedSubMenu ? t(selectedSubMenu.slice(3)) : ''}
        />
        <BillQuery />
      </>)}

    </div>
  );
};

export default connect(mapStateToProps, null)(ReportManagement);