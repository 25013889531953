import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { appActions, systemActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  editNoticeData: state.noticeReducers.editNoticeData,
  userData: state.memberReducers.userData,
  editPayAccData: state.systemReducers.editPayAccData,
});

const mapDispatchToProps = dispatch => ({
  getEditPayAccount: (id, callback) => dispatch(systemActions.getEditPayAccount(id, callback)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveExternalWeb: (obj, callback) => dispatch(systemActions.saveExternalWeb(obj, callback)),
});

const ExternalWebAddEdit = ({ callback = {}, isEdit, data, updateModalAlert, saveExternalWeb }) => {
  const { t } = useTranslation();
  const [_info, setInfo] = useState([]);

  useEffect(() => {
    if (!isEdit) {
      setInfo([]);
    } else if (data && JSON.stringify(data) !== JSON.stringify(_info)) {
      setInfo(data);
    }
  }, [data]);


  const saveButton = () => {
    if (_info?.isChange) {
      const updateObj = _info;
      updateModalAlert({
        type: 'loading',
        visible: true,
      });
      if (!isEdit) {
        updateObj.isUpdate = 0;
      } else {
        updateObj.isUpdate = 1;
      }
      saveExternalWeb(updateObj, callback);
    } else {
      updateModalAlert({
        visible: true,
        type: 'confirmation',
        data: {
          message: '无变动'
        }
      });
    }
  };

  const onInfoValueChange = (val, field) => {
    const newItems = _info;
    newItems[field] = val;
    newItems.isChange = true;
    setInfo(newItems => ({
      ...newItems, field: val
    }));
  };

  const resetDefault = () => {
    if (!isEdit) {
      callback();
    } else {
      setInfo(data);
    }
  };

  const status = [
    {
      label: '启用',
      value: '0',
    },
    {
      label: '禁用',
      value: '1',
    }
  ];

  return (
    <div className="ExternalWebAddEditWrapper">
      <div className="mx-0 bg-white my-2  border-1">
        <Row className="m-0 my-1 p-0">
          <table className="p-3 m-0" cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="titleHeader" colSpan={2}>
                  {isEdit === true ? '编辑外补网站' : '新增外补网站'}
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">网站名称</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.name || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'name')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">网站地址</td>
                <td>
                  <div>
                    <Input
                      className="d-inline col-1"
                      type="text"
                      value={_info?.address || ''}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'address')}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-4">状态</td>
                <td>
                  <div className="d-flex my-1 rdSearchOptionsGroup">
                    {
                      status.map(d => (
                        <div key={`rdTypeGroup_${d.value}`}>
                          <Input
                            id={`rdType_${d.value}`}
                            type="radio"
                            name="rdType"
                            value={_info?.status}
                            checked={_info?.status === Number(d.value)}
                            onChange={() => onInfoValueChange(Number(d.value), 'status')}
                          />
                          <Label
                            for={`rdType_${d.value}`}
                            className={_info?.status === Number(d.value) ? 'active mx-2 smallLabel' : 'mx-2 smallLabel'}
                          >
                            {d?.label}
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="tableHeader col-2">备注</td>
                <td>
                  <span className="textAreaInput">
                    <Input
                      className="d-inline col-1"
                      type="textarea"
                      value={_info?.remark}
                      onChange={({ target: { value: text } }) => onInfoValueChange(text, 'remark')}
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
      <Row className="mb-5 my-2 p-2">
        <Col className="d-flex justify-content-center py-2 border-top">
          <Button className="button handle mx-1" onClick={() => saveButton()}>{t('SAVE')}</Button>
          <Button className="button find mx-1" onClick={() => resetDefault()}>{t('CANCEL')}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalWebAddEdit);